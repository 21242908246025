import { createAction, props, union } from '@ngrx/store';
import { EnvironmentFeaturesConfiguration } from '@app-shared/models/configuration.model';

const CleanReducers = createAction('[Page Metadata] Clean all reducers');

const GetAppFeatures = createAction('[Page Metadata] Request app features from S3 via Node.js');

const SetAppVersion = createAction(
  '[Page Metadata] Set app version from environment',
  props<{ version: string }>(),
);

const SetAppFeatures = createAction(
  '[Page Metadata] Set app features from S3',
  props<{ features: EnvironmentFeaturesConfiguration }>(),
);

const SetDefaultAppFeatures = createAction(
  '[Page Metadata] Set app features from local configuration',
  props<{ features: EnvironmentFeaturesConfiguration }>(),
);

const SetTitle = createAction(
  '[Page Metadata] Set page title',
  props<{ title: string; params?: string }>(),
);

const actions = {
  CleanReducers,
  GetAppFeatures,
  SetAppVersion,
  SetAppFeatures,
  SetDefaultAppFeatures,
  SetTitle,
};

const pageMetadataActions = union(actions);

export type MetadataActionsType = typeof pageMetadataActions;

export const MetadataActions = actions;
