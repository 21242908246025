import { RecruiterAccesses, TeammateUserType } from '@app-shared/models';

// eslint-disable-next-line no-shadow
export enum PermissionPanelState {
  NONE,
  ALL,
  PARTIAL,
}

export interface RecruiterAccessItem {
  name: string;
  title: string;
  subtitle?: string;
  tooltip?: string;
  titleTooltip?: string;
  subPermissions?: RecruiterAccessItem[];
}

export const recruiterAccesses: RecruiterAccessItem[] = [
  {
    name: 'accessToCandidates',
    title: 'add-new-entity.new-recruiter.form.access-to-candidates',
    tooltip: 'add-new-entity.new-recruiter.form.access-to-candidates.tooltip',
    subPermissions: [
      {
        name: 'viewPortalProfiles',
        subtitle: 'add-new-entity.new-recruiter.form.view-all-profiles.subtitle',
        title: 'add-new-entity.new-recruiter.form.view-all-profiles',
        tooltip: 'add-new-entity.new-recruiter.form.view-all-profiles.tooltip',
      },
      {
        name: 'viewProfilesComments',
        subtitle: 'add-new-entity.new-recruiter.form.profiles-comments.subtitle',
        title: 'add-new-entity.new-recruiter.form.profiles-comments',
        tooltip: 'add-new-entity.new-recruiter.form.profiles-comments.tooltip',
      },
      {
        name: 'deleteComments',
        title: 'add-new-entity.new-recruiter.form.delete-comments',
        tooltip: 'add-new-entity.new-recruiter.form.delete-comments.tooltip',
      },
      {
        name: 'viewProfilesFiles',
        subtitle: 'add-new-entity.new-recruiter.form.profiles-files.subtitle',
        title: 'add-new-entity.new-recruiter.form.profiles-files',
        tooltip: 'add-new-entity.new-recruiter.form.profiles-files.tooltip',
      },
      {
        name: 'viewProfilesNotifications',
        subtitle: 'add-new-entity.new-recruiter.form.profiles-notifications.subtitle',
        title: 'add-new-entity.new-recruiter.form.profiles-notifications',
        tooltip: 'add-new-entity.new-recruiter.form.profiles-notifications.tooltip',
      },
      {
        name: 'viewProfilesShortlists',
        subtitle: 'add-new-entity.new-recruiter.form.profiles-shortlists.subtitle',
        title: 'add-new-entity.new-recruiter.form.profiles-shortlists',
        tooltip: 'add-new-entity.new-recruiter.form.profiles-shortlists.tooltip',
      },
      {
        name: 'showProfileContactDetails',
        title: 'add-new-entity.new-recruiter.form.profile-contacts',
        titleTooltip: 'add-new-entity.new-recruiter.form.profile-contacts-title',
        // tooltip: 'add-new-entity.new-recruiter.form.profile-contacts.tooltip',
      },
      {
        name: 'showProfileSocialLinks',
        title: 'add-new-entity.new-recruiter.form.profile-socials',
        titleTooltip: 'add-new-entity.new-recruiter.form.profile-socials-title',
        // tooltip: 'add-new-entity.new-recruiter.form.profile-socials.tooltip',
      },
      {
        name: 'showProfileSecondName',
        title: 'add-new-entity.new-recruiter.form.profile-second-name',
        titleTooltip: 'add-new-entity.new-recruiter.form.profile-second-name-title',
        // tooltip: 'add-new-entity.new-recruiter.form.profile-second-name.tooltip',
      },
      {
        name: 'showProfileCustomFields',
        title: 'add-new-entity.new-recruiter.form.profile-custom-fields',
        titleTooltip: 'add-new-entity.new-recruiter.form.profile-custom-fields-title',
        // tooltip: 'add-new-entity.new-recruiter.form.profile-custom-fields.tooltip',
      },
      {
        name: 'showCandidateStatusCustomFields',
        title: 'add-new-entity.new-recruiter.form.profile-status-custom-fields',
        titleTooltip: 'add-new-entity.new-recruiter.form.profile-status-custom-fields-title',
        // tooltip: 'add-new-entity.new-recruiter.form.profile-status-custom-fields.tooltip',
      },
      {
        name: 'showSalary',
        title: 'add-new-entity.new-recruiter.form.vacancy-salary',
        tooltip: 'add-new-entity.new-recruiter.form.vacancy-salary.tooltip',
      },
    ],
  },
  {
    name: 'accessToVacancies',
    title: 'add-new-entity.new-recruiter.form.access-to-vacancies',
    tooltip: 'add-new-entity.new-recruiter.form.access-to-vacancies.tooltip',
    subPermissions: [
      {
        name: 'viewPortalProjects',
        subtitle: 'add-new-entity.new-recruiter.form.view-all-projects.subtitle',
        title: 'add-new-entity.new-recruiter.form.view-all-projects',
        tooltip: 'add-new-entity.new-recruiter.form.view-all-projects.tooltip',
      },
      {
        name: 'deleteVacancy',
        subtitle: 'add-new-entity.new-recruiter.form.delete-vacancy.subtitle',
        title: 'add-new-entity.new-recruiter.form.delete-vacancy',
        tooltip: 'add-new-entity.new-recruiter.form.delete-vacancy.tooltip',
      },
      {
        name: 'editVacancyCloseDate',
        subtitle: 'add-new-entity.new-recruiter.form.edit-vacancy-close-date.subtitle',
        title: 'add-new-entity.new-recruiter.form.edit-vacancy-close-date',
        tooltip: 'add-new-entity.new-recruiter.form.edit-vacancy-close-date.tooltip',
      },
      {
        name: 'editVacancyInformation',
        subtitle: 'add-new-entity.new-recruiter.form.edit-vacancy.subtitle',
        title: 'add-new-entity.new-recruiter.form.edit-vacancy',
        tooltip: 'add-new-entity.new-recruiter.form.edit-vacancy.tooltip',
      },
      {
        name: 'vacancyStaticTab',
        title: 'add-new-entity.new-recruiter.form.vacancy-static',
        tooltip: 'add-new-entity.new-recruiter.form.vacancy-static.tooltip',
      },
      {
        name: 'viewVacancyComments',
        title: 'add-new-entity.new-recruiter.form.vacancy-comments',
        tooltip: 'add-new-entity.new-recruiter.form.vacancy-comments.tooltip',
      },
      {
        name: 'showVacancyCustomFields',
        title: 'add-new-entity.new-recruiter.form.vacancy-custom-fields',
        titleTooltip: 'add-new-entity.new-recruiter.form.vacancy-custom-fields-title',
        // tooltip: 'add-new-entity.new-recruiter.form.vacancy-custom-fields.tooltip',
      },
    ],
  },
  {
    name: 'clientsPage',
    title: 'add-new-entity.new-recruiter.form.clients-page',
    tooltip: 'add-new-entity.new-recruiter.form.clients-page.tooltip',
    subPermissions: [
      {
        name: 'viewPortalClients',
        subtitle: 'add-new-entity.new-recruiter.form.view-all-clients.subtitle',
        title: 'add-new-entity.new-recruiter.form.view-all-clients',
        tooltip: 'add-new-entity.new-recruiter.form.view-all-clients.tooltip',
      },
      {
        name: 'showClientsContactsDetails',
        title: 'add-new-entity.new-recruiter.form.clients-contacts',
        titleTooltip: 'add-new-entity.new-recruiter.form.clients-contacts-title',
        // tooltip: 'add-new-entity.new-recruiter.form.clients-contacts.tooltip',
      },
      {
        name: 'showClientsCustomFields',
        title: 'add-new-entity.new-recruiter.form.clients-custom-fields',
        titleTooltip: 'add-new-entity.new-recruiter.form.clients-custom-fields-title',
        // tooltip: 'add-new-entity.new-recruiter.form.clients-custom-fields.tooltip',
      },
    ],
  },
  {
    name: 'accessToOthers',
    title: 'add-new-entity.new-recruiter.form.other-accesses',
    subPermissions: [
      {
        name: 'searchConsolePage',
        title: 'add-new-entity.new-recruiter.form.search-console',
        tooltip: 'add-new-entity.new-recruiter.form.search-console.tooltip',
      },
      {
        name: 'vacancyNotificationsTab',
        title: 'add-new-entity.new-recruiter.form.vacancy-notifications',
        tooltip: 'add-new-entity.new-recruiter.form.vacancy-notifications.tooltip',
      },
      {
        name: 'dashboardPage',
        title: 'add-new-entity.new-recruiter.form.dashboard-page',
        tooltip: 'add-new-entity.new-recruiter.form.dashboard-page.tooltip',
      },
      {
        name: 'teammatesPage',
        title: 'add-new-entity.new-recruiter.form.teammates-page',
        tooltip: 'add-new-entity.new-recruiter.form.teammates-page.tooltip',
      },
    ],
  },
];

export const possibleTeammateAccesses: (keyof RecruiterAccesses)[] = [
  'viewPortalProfiles',
  'viewPortalClients',
  'viewPortalProjects',
  'clientsPage',
  'dashboardPage',
  'deleteComments',
  'deleteVacancy',
  'editVacancyCloseDate',
  'editVacancyInformation',
  'searchConsolePage',
  'teammatesPage',
  'vacancyNotificationsTab',
  'vacancyStaticTab',
  'viewProfilesComments',
  'viewProfilesFiles',
  'viewProfilesNotifications',
  'viewProfilesShortlists',
  'viewVacancyComments',
  'showCandidateStatusCustomFields',
  'showClientsContactsDetails',
  'showClientsCustomFields',
  'showProfileContactDetails',
  'showProfileCustomFields',
  'showProfileSecondName',
  'showProfileSocialLinks',
  'showSalary',
  'showVacancyCustomFields',
];

export const teammateUserTypes: TeammateUserType[] = [
  {
    accesses: {
      accessToCandidates: true,
      accessToVacancies: true,
      accessToOthers: true,
      viewPortalProfiles: true,
      viewPortalClients: true,
      viewPortalProjects: true,
      clientsPage: true,
      dashboardPage: true,
      deleteComments: true,
      deleteVacancy: true,
      editVacancyCloseDate: true,
      editVacancyInformation: true,
      searchConsolePage: true,
      teammatesPage: true,
      vacancyNotificationsTab: true,
      vacancyStaticTab: true,
      viewProfilesComments: true,
      viewProfilesFiles: true,
      viewProfilesNotifications: true,
      viewProfilesShortlists: true,
      viewVacancyComments: true,
      showCandidateStatusCustomFields: true,
      showClientsContactsDetails: true,
      showClientsCustomFields: true,
      showProfileContactDetails: true,
      showProfileCustomFields: true,
      showProfileSecondName: true,
      showProfileSocialLinks: true,
      showSalary: true,
      showVacancyCustomFields: true,
    },
    key: 'full',
  },
  {
    accesses: {
      accessToCandidates: true,
      accessToVacancies: true,
      accessToOthers: true,
      viewPortalProfiles: true,
      viewPortalClients: true,
      viewPortalProjects: true,
      clientsPage: true,
      dashboardPage: false,
      deleteComments: true,
      deleteVacancy: false,
      editVacancyCloseDate: true,
      editVacancyInformation: true,
      searchConsolePage: true,
      teammatesPage: false,
      vacancyNotificationsTab: true,
      vacancyStaticTab: true,
      viewProfilesComments: true,
      viewProfilesFiles: true,
      viewProfilesNotifications: true,
      viewProfilesShortlists: true,
      viewVacancyComments: true,
      showCandidateStatusCustomFields: true,
      showClientsContactsDetails: true,
      showClientsCustomFields: true,
      showProfileContactDetails: true,
      showProfileCustomFields: true,
      showProfileSecondName: true,
      showProfileSocialLinks: true,
      showSalary: true,
      showVacancyCustomFields: true,
    },
    key: 'recruiter',
  },
  {
    accesses: {
      accessToCandidates: true,
      accessToVacancies: true,
      accessToOthers: true,
      viewPortalProfiles: false,
      viewPortalClients: false,
      viewPortalProjects: false,
      clientsPage: true,
      dashboardPage: false,
      deleteComments: true,
      deleteVacancy: false,
      editVacancyCloseDate: false,
      editVacancyInformation: true,
      searchConsolePage: true,
      teammatesPage: false,
      vacancyNotificationsTab: true,
      vacancyStaticTab: true,
      viewProfilesComments: true,
      viewProfilesFiles: false,
      viewProfilesNotifications: false,
      viewProfilesShortlists: true,
      viewVacancyComments: true,
      showCandidateStatusCustomFields: true,
      showClientsContactsDetails: true,
      showClientsCustomFields: true,
      showProfileContactDetails: true,
      showProfileCustomFields: true,
      showProfileSecondName: true,
      showProfileSocialLinks: true,
      showSalary: false,
      showVacancyCustomFields: false,
    },
    key: 'sourcer',
  },
  {
    accesses: {
      accessToCandidates: false,
      accessToVacancies: true,
      accessToOthers: false,
      viewPortalProfiles: false,
      viewPortalClients: false,
      deleteComments: false,
      deleteVacancy: false,
      viewPortalProjects: false,
      clientsPage: false,
      dashboardPage: false,
      editVacancyCloseDate: false,
      editVacancyInformation: false,
      searchConsolePage: false,
      teammatesPage: false,
      vacancyNotificationsTab: false,
      vacancyStaticTab: false,
      viewProfilesComments: false,
      viewProfilesFiles: false,
      viewProfilesNotifications: false,
      viewProfilesShortlists: false,
      viewVacancyComments: false,
      showCandidateStatusCustomFields: false,
      showClientsContactsDetails: false,
      showClientsCustomFields: false,
      showProfileContactDetails: false,
      showProfileCustomFields: false,
      showProfileSecondName: false,
      showProfileSocialLinks: false,
      showSalary: false,
      showVacancyCustomFields: false,
    },
    default: true,
    key: 'custom',
  },
];
