import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { UpdateTokensAction } from '@app-core/auth/actions/auth.system.actions';
import { LogoutAction } from '@app-core/auth/actions/auth.user.actions';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { AuthResponse } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getTokens } from '@app-shared/reducers/auth/auth.reducer';
import { AuthService } from '@app-shared/services/auth/auth.service';
import { Action, select, Store } from '@ngrx/store';
import { has, identity, ifElse, is, join, pathOr, pipe, test } from 'ramda';
import { Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';

const HttpErrorStatus = {
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  SERVER_ERROR: 500,
};

export const networkErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  let unauthorizedErrorsAmount = 0;

  const store$ = inject(Store<State>);
  const authService = inject(AuthService);

  const isExtensionRequest = test(/chrome-extension/g, req.url);
  if (isExtensionRequest) {
    return next(req);
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const getErrorMessage = (def = 'shared.interceptors.network-error.something-went-wrong') =>
        pipe(
          pathOr(def, ['error', 'message']),
          ifElse(is(Array), join(', '), identity),
        )(error) as string;

      let notification: Action;
      switch (error.status) {
        case HttpErrorStatus.UNAUTHORIZED: {
          unauthorizedErrorsAmount++;
          return store$.pipe(
            select(getTokens),
            first(),
            mergeMap(({ refresh_token }) => {
              if (unauthorizedErrorsAmount < 5) {
                return authService.refreshToken(refresh_token);
              }
              return throwError(() => '');
            }),
            mergeMap((tokens: AuthResponse) => {
              const hasRefreshToken = has('refresh_token', tokens);
              if (hasRefreshToken) {
                store$.dispatch(UpdateTokensAction({ tokens }));
              }

              const requestWithNewToken: HttpRequest<unknown> = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${tokens.token}`,
                },
              });

              return next(requestWithNewToken);
            }),
            catchError((err: HttpErrorResponse) => {
              notification = InternalNotificationActions.WarningNotificationAction({
                message: getErrorMessage('shared.interceptors.network-error.unauthorized'),
              });
              store$.dispatch(notification);
              store$.dispatch(LogoutAction());
              return throwError(() => err);
            }),
          );
        }
        case HttpErrorStatus.FORBIDDEN: {
          notification = InternalNotificationActions.WarningNotificationAction({
            message: getErrorMessage('shared.interceptors.network-error.forbidden'),
          });
          break;
        }
        case HttpErrorStatus.NOT_FOUND: {
          notification = InternalNotificationActions.WarningNotificationAction({
            message: getErrorMessage('shared.interceptors.network-error.not-found'),
          });
          break;
        }
        case HttpErrorStatus.PAYMENT_REQUIRED: {
          notification = InternalNotificationActions.WarningNotificationAction({
            message: getErrorMessage('shared.interceptors.network-error.payment-required'),
          });
          break;
        }
        case HttpErrorStatus.CONFLICT:
        case HttpErrorStatus.UNPROCESSABLE_ENTITY: {
          notification = InternalNotificationActions.WarningNotificationAction({
            message: getErrorMessage(),
          });
          break;
        }
        case HttpErrorStatus.SERVER_ERROR:
        default: {
          notification = InternalNotificationActions.ErrorNotificationAction({
            message: getErrorMessage(),
          });
        }
      }

      store$.dispatch(notification);
      return throwError(() => error);
    }),
  );
};
