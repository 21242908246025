import {
  ExternalCounts,
  PaginationParams,
  Profile,
  ProfileExternalSearchSources,
  ProfilesSearchParams,
  ProfilesSources,
  ShortList,
  ShortListCreateResponse,
  ShortListUpdateResponse,
  SortParams,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

export const ChangeSimpleCardViewStateFromSearchConsoleComplete = createAction(
  '[Search Console | Short List] Simple card view state successfully changed',
  props<{ viewState: boolean }>(),
);
export const ChangeSimpleCardViewStateFromSearchConsoleFailed = createAction(
  '[Search Console | Short List] Error happened during changing simple card view state',
  props<{ viewState: boolean }>(),
);
export const CleanSelectionStateAction = createAction(
  '[Search Console] Clean selection state collection on profiles list',
);
export const CreateShortListFailedAction = createAction(
  '[Search Console | Short List] Error happened during short list creation',
);
export const CreateShortListSuccessAction = createAction(
  '[Search Console | Short List] Short list was successfully created',
  props<{ newBid: ShortListCreateResponse; profileId: string }>(),
);
export const ExternalSearchSuccessAction = createAction(
  '[Search Console Effects] External profiles were successfully requested',
  props<{ profiles: Profile[]; totalItems: number }>(),
);
export const ExternalSearchFailedAction = createAction(
  '[Search Console Effects] Error happened during requesting external profiles',
);
export const GetCountsWithNewParamsAction = createAction(
  '[Search Console Effects] Get new counts with update search params',
);
export const GetExternalProfilesAction = createAction(
  '[Search Console Init Resolver | Search Console Effects] Request external profiles',
);
export const GetProfilesAction = createAction(
  '[Search Console Init Resolver] Request initial profiles',
);
export const GetXRayLinksAction = createAction(
  '[Search Console Init Resolver] Request initial x-ray links',
);
export const GetProfilesCountAction = createAction(
  '[Search Console Init Resolver] Request counts for profiles',
);
export const GetProfilesWithNewParamsAction = createAction(
  '[Search Console Effects] Request new profiles with update search params',
);
export const GetProfilesWithVacancyIdAction = createAction(
  '[Vacancy Effects] Request new profiles with selected vacancy id',
);
export const GetLastSearchByVacancyAction = createAction(
  '[Search Console Effects] Get last search by vacancy after open page',
);
export const LastSearchByVacancyCompleteAction = createAction(
  '[Search Console] Last search by selected vacancy completed',
  props<{ links: string[] }>(),
);
export const LoadInternalProfilesAction = createAction(
  '[Search Console Effects] Request internal profiles with params',
  props<{ source: ProfilesSources; params: ProfilesSearchParams }>(),
);
export const LinkSuggestionFailedAction = createAction(
  '[Search Console Effects] Error happened during linking suggested profiles',
);
export const LinkSuggestionSuccessAction = createAction(
  '[Search Console Effects] Suggested profiles were successfully linked',
);
export const DeleteProfilesFailedAction = createAction(
  '[Search Console] Error happened during deleting selected profiles',
);
export const DeleteProfilesSuccessAction = createAction(
  '[Search Console] Selected profiles successfully deleted',
);
export const MergeMultipleProfilesFailedAction = createAction(
  '[Search Console] Error happened during merging profile duplicates from the card',
);
export const MergeMultipleProfilesSuccessAction = createAction(
  '[Search Console] All profile duplicates from the card were successfully merged',
);
export const MergeTwoProfilesFailedAction = createAction(
  '[Search Console] Error happened during merging selected profiles',
);
export const MergeTwoProfilesSuccessAction = createAction(
  '[Search Console] Selected profiles successfully merged',
);
export const NavigateToNewQueryParamsAction = createAction(
  '[Search Console Effects] Update query params in navigation bar',
);
export const OpenSearchWithProfileContacts = createAction(
  '[Search Console Effects] Open search console with profile contacts',
  props<{ searchParams: ProfilesSearchParams }>(),
);
export const RefreshProfilesListAfterAddToVacancyAction = createAction(
  '[Profiles Details Effects] Refresh profiles list after adding profile to vacancy',
);
export const RefreshProfilesAfterClosingSidepanelAction = createAction(
  '[Profiles Details Effects] Refresh profiles list after closing the sidepanel',
);
export const RefreshProfilesListAfterEditTalentAction = createAction(
  '[New Entity Effects] Refresh profiles list after editing existed talent',
);
export const RefreshProfilesListAfterDeleteTalentAction = createAction(
  '[New Entity Effects] Refresh profiles list after deleting existed talent',
);
export const RefreshProfilesListAfterDeleteCandidatesAction = createAction(
  '[Search Console] Refresh profiles list after deleting existed candidates',
);
export const RefreshProfilesListAfterMergeDuplicatesAction = createAction(
  '[Profiles Editing Effects] Refresh profiles list after merging duplicates from the card',
);
export const RefreshProfilesListAfterMergeTwoProfilesAction = createAction(
  '[Profiles Editing Effects] Refresh profiles list after merging selected profiles',
  props<{ primaryDeveloper: string; duplicateDeveloper: string }>(),
);
export const RefreshCountsInProfilesListAfterMergeProfilesAction = createAction(
  '[Profiles Editing Effects] Refresh counts in profiles list after merging selected profiles',
  props<{ tab: string }>(),
);
export const RemoveFromShortListInProfilesListFailedAction = createAction(
  '[Search Console] Error happened during removing from short list on profiles list page',
);
export const RemoveFromShortListInProfilesListSuccessAction = createAction(
  '[Search Console] Profile had removed from short list',
  props<{ devId: string; bidId: number }>(),
);
export const ReturnInitialStateAction = createAction(
  '[Search Console Cleaner Guard] Return profiles list store to initial state',
);
export const RequestExternalCountsSuccessAction = createAction(
  '[Search Console] External counts were successfully requested',
  props<{ externalCounts: ExternalCounts }>(),
);
export const RequestExternalCountsFailedAction = createAction(
  '[Search Console] Error happened during requesting external counts',
);
export const ResendShortListForSecurityCheckFailedAction = createAction(
  '[Search Console] Error happened during resending short list for security check',
);
export const ResendShortListForSecurityCheckSuccessAction = createAction(
  '[Search Console] Short list was successfully resent for security check',
);
export const SetInitialStateAction = createAction(
  '[Search Console Init Resolver] Set initial state based on query parameters',
  props<{
    paginationParams?: PaginationParams;
    source: ProfilesSources;
    searchParams: ProfilesSearchParams;
    sortParams: SortParams;
    externalSearchSource: ProfileExternalSearchSources;
  }>(),
);
export const SetSearchParamsAction = createAction(
  '[Search Console X-Ray Resolver] Set search params',
  props<{ params: ProfilesSearchParams }>(),
);
export const ToggleProfilesLoadingAction = createAction(
  '[Search Console] Toggle loading state',
  props<{ loadingState: boolean }>(),
);
export const UpdateLastSearchByVacancyAction = createAction(
  '[Search Console] Update last search by vacancy after request profiles',
  props<{ id: number }>(),
);
export const UpdateSearchModelFromVacancyAction = createAction(
  '[Vacancy Selector] Update search model on profiles list page with new params',
  props<{ searchParams: Partial<ProfilesSearchParams> }>(),
);
export const UpdateShortListInProfilesListFailedAction = createAction(
  '[Search Console] Error happened during updating short list on profiles list page',
  props<{ shortList: Partial<ShortList> }>(),
);
export const UpdateShortListInProfilesListSuccessAction = createAction(
  '[Search Console] Short list in profile successfully updated',
  props<{ shortList: ShortListUpdateResponse }>(),
);
export const UpdateSourceAction = createAction(
  '[Search Console Effects] Update source',
  props<{ source: ProfilesSources }>(),
);

const actions = {
  ChangeSimpleCardViewStateFromSearchConsoleComplete,
  ChangeSimpleCardViewStateFromSearchConsoleFailed,
  CleanSelectionStateAction,
  CreateShortListFailedAction,
  CreateShortListSuccessAction,
  DeleteProfilesFailedAction,
  DeleteProfilesSuccessAction,
  ExternalSearchSuccessAction,
  ExternalSearchFailedAction,
  GetCountsWithNewParamsAction,
  GetExternalProfilesAction,
  GetProfilesAction,
  GetXRayLinksAction,
  GetProfilesCountAction,
  GetProfilesWithNewParamsAction,
  GetProfilesWithVacancyIdAction,
  GetLastSearchByVacancyAction,
  LastSearchByVacancyCompleteAction,
  LoadInternalProfilesAction,
  LinkSuggestionFailedAction,
  LinkSuggestionSuccessAction,
  MergeMultipleProfilesFailedAction,
  MergeMultipleProfilesSuccessAction,
  MergeTwoProfilesFailedAction,
  MergeTwoProfilesSuccessAction,
  NavigateToNewQueryParamsAction,
  OpenSearchWithProfileContacts,
  RefreshProfilesListAfterAddToVacancyAction,
  RefreshProfilesAfterClosingSidepanelAction,
  RefreshProfilesListAfterEditTalentAction,
  RefreshProfilesListAfterDeleteCandidatesAction,
  RefreshProfilesListAfterDeleteTalentAction,
  RefreshProfilesListAfterMergeDuplicatesAction,
  RefreshProfilesListAfterMergeTwoProfilesAction,
  RefreshCountsInProfilesListAfterMergeProfilesAction,
  RemoveFromShortListInProfilesListFailedAction,
  RemoveFromShortListInProfilesListSuccessAction,
  ResendShortListForSecurityCheckFailedAction,
  ResendShortListForSecurityCheckSuccessAction,
  ReturnInitialStateAction,
  RequestExternalCountsSuccessAction,
  RequestExternalCountsFailedAction,
  SetInitialStateAction,
  SetSearchParamsAction,
  ToggleProfilesLoadingAction,
  UpdateLastSearchByVacancyAction,
  UpdateSearchModelFromVacancyAction,
  UpdateShortListInProfilesListFailedAction,
  UpdateShortListInProfilesListSuccessAction,
  UpdateSourceAction,
};

const searchConsoleSystemActions = union(actions);

export type SearchConsoleSystemActionsType = typeof searchConsoleSystemActions;

export const SearchConsoleSystemActions = actions;
