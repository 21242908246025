import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { CV, FileType, Profile, UnaryOperator } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { length, pathOr, pipe, propOr, sum } from 'ramda';

@Component({
  imports: [TranslatePipe, TranslateDirective, MatTooltip],
  selector: 'profile-counts-panel',
  templateUrl: './profile-counts-panel.component.html',
  styleUrls: ['./profile-counts-panel.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileCountsPanelComponent {
  @Input()
  public profile: Profile;
  @Output()
  public openFilesModal = new EventEmitter<FileType>();
  @Output()
  public openSidepanel = new EventEmitter<string[]>();
  @Output()
  public showWarning = new EventEmitter<string>();

  public get notifiedCount(): number {
    return pathOr(0, ['letterCounts', 'received'], this.profile);
  }
  public get openedCount(): number {
    return pathOr(0, ['letterCounts', 'opened'], this.profile);
  }
  public get clickedCount(): number {
    return pathOr(0, ['letterCounts', 'clicked'], this.profile);
  }
  public get longListCount(): number {
    return pathOr(0, ['bidCounts', 'longList'], this.profile);
  }
  public get shortListCount(): number {
    return pathOr(0, ['bidCounts', 'shortList'], this.profile);
  }
  public get listsCount(): number {
    return sum([
      pathOr(0, ['bidCounts', 'longList'], this.profile),
      pathOr(0, ['bidCounts', 'shortList'], this.profile),
    ]);
  }
  public get confirmedCount(): number {
    return pathOr(0, ['bidCounts', 'confirmed'], this.profile);
  }
  public get notProcessed(): boolean {
    return this.appliesCount > 0;
  }
  public get appliesCount(): number {
    return pathOr(0, ['bidCounts', 'applies'], this.profile);
  }
  public getCount(prop: string): number {
    return pipe(propOr([], prop) as UnaryOperator<Profile, CV[]>, length)(this.profile);
  }
  public get filesCount(): number {
    return this.getCount('cvs') + this.getCount('voices');
  }
  public get haveNotified(): boolean {
    return this.notifiedCount > 0 || this.openedCount > 0 || this.clickedCount > 0;
  }
  public openFileModal(type: 'cv' | 'voice') {
    if (this.getCount(`${type}s`) > 0) {
      this.openFilesModal.emit(type);
    } else {
      const file = type === 'cv' ? 'cvs' : 'vrs';
      this.showWarning.emit(`profile-preview.profile-counts-panel.warning.${file}`);
    }
  }
}
