import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor as Interceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateTokensAction } from '@app-core/auth/actions/auth.system.actions';
import { LogoutAction } from '@app-core/auth/actions/auth.user.actions';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { AuthResponse } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getTokens } from '@app-shared/reducers/auth/auth.reducer';
import { AuthService } from '@app-shared/services/auth/auth.service';
import { Action, Store, select } from '@ngrx/store';
import { has, identity, ifElse, is, join, pathOr, pipe, test } from 'ramda';
import { Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NetworkErrorInterceptor implements Interceptor {
  private readonly UNAUTHORIZED = 401;
  private readonly PAYMENT_REQUIRED = 402;
  private readonly FORBIDDEN = 403;
  private readonly NOT_FOUND = 404;
  private readonly CONFLICT = 409;
  private readonly UNPROCESSABLE_ENTITY = 422;
  private readonly SERVER_ERROR = 500;
  private unauthorizedErrorsAmount = 0;

  constructor(
    private readonly store$: Store<State>,
    private readonly authService: AuthService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isExtensionRequest = test(/chrome-extension/g, request.url);
    if (isExtensionRequest) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const getErrorMessage = (def = 'shared.interceptors.network-error.something-went-wrong') =>
          pipe(
            pathOr(def, ['error', 'message']),
            ifElse(is(Array), join(', '), identity),
          )(error) as string;

        let notification: Action;
        switch (error.status) {
          case this.UNAUTHORIZED: {
            this.unauthorizedErrorsAmount++;
            return this.store$.pipe(
              select(getTokens),
              first(),
              mergeMap(({ refresh_token }) => {
                if (this.unauthorizedErrorsAmount < 5) {
                  return this.authService.refreshToken(refresh_token);
                }
                return throwError(() => '');
              }),
              mergeMap((tokens: AuthResponse) => {
                const hasRefreshToken = has('refresh_token', tokens);
                if (hasRefreshToken) {
                  this.store$.dispatch(UpdateTokensAction({ tokens }));
                }

                const requestWithNewToken: HttpRequest<unknown> = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${tokens.token}`,
                  },
                });

                return next.handle(requestWithNewToken);
              }),
              catchError((err: HttpErrorResponse) => {
                notification = InternalNotificationActions.WarningNotificationAction({
                  message: getErrorMessage('shared.interceptors.network-error.unauthorized'),
                });
                this.store$.dispatch(notification);
                this.store$.dispatch(LogoutAction());
                return throwError(() => err);
              }),
            );
          }
          case this.FORBIDDEN: {
            notification = InternalNotificationActions.WarningNotificationAction({
              message: getErrorMessage('shared.interceptors.network-error.forbidden'),
            });
            break;
          }
          case this.NOT_FOUND: {
            notification = InternalNotificationActions.WarningNotificationAction({
              message: getErrorMessage('shared.interceptors.network-error.not-found'),
            });
            break;
          }
          case this.PAYMENT_REQUIRED: {
            notification = InternalNotificationActions.WarningNotificationAction({
              message: getErrorMessage('shared.interceptors.network-error.payment-required'),
            });
            break;
          }
          case this.CONFLICT:
          case this.UNPROCESSABLE_ENTITY: {
            notification = InternalNotificationActions.WarningNotificationAction({
              message: getErrorMessage(),
            });
            break;
          }
          case this.SERVER_ERROR:
          default: {
            notification = InternalNotificationActions.ErrorNotificationAction({
              message: getErrorMessage(),
            });
          }
        }

        this.store$.dispatch(notification);
        return throwError(() => error);
      }),
    );
  }
}
