import { ClientFull, ClientPayload } from '@app-shared/models';

export const mockedClientDetails: ClientFull = {
  authors: [{ id: 296 }],
  city: 'Cherkasy',
  clientType: 1,
  color: '#9fdef0',
  country: 'Ukraine',
  createdAt: '2019-07-04T00:44:34+03:00',
  cvSendCandidates: 0,
  email: 'Email80281390@example.com',
  customFields: [],
  fieldValues: [],
  firstName: 'FNrrhej22',
  fullName: 'FNrrhej22 LNpkgec',
  hiredCandidates: 0,
  id: 937,
  interviewCandidates: 0,
  lastName: 'LNpkgec',
  location: 'Ukraine, Cherkasy',
  phone: '234',
  skype: 'Skype2190281435',
  teamName: 'Autotestukfqn',
  vacanciesCount: 0,
};
export const mockedClientPayload: Omit<ClientPayload, 'id'> = {
  city: null,
  clientType: 1,
  country: null,
  createUser: true,
  email: 'john.smith@mail.com',
  firstName: 'John',
  fieldValues: [],
  lastName: 'Smith',
  phone: '+1445557894',
  skype: 'john.smith.72',
  teamName: 'John Smith Co',
};
