import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { FloatLabelType, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import {
  AllowedOptionType,
  AutocompleteItem,
  DictionaryItem,
  Entity,
  UnaryOperator,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { FallbackSrcWidthDirective } from '@tsp-directives';
import { CastToRecruiterPipe, CastToVacancyPipe, GetInitialsPipe } from '@tsp-pipes';
import { any, find, has, isEmpty, isNil, not, pipe, propEq, props, reject, trim } from 'ramda';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  imports: [
    CastToRecruiterPipe,
    CastToVacancyPipe,
    DatePipe,
    FallbackSrcWidthDirective,
    GetInitialsPipe,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSuffix,
    MatTooltip,
    NgClass,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchSelectComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchSelectComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  @Input()
  public elementDisabled = false;
  @Input()
  public placeholder: string;
  @Input()
  public label: string;
  @Input()
  public labelType: FloatLabelType = 'auto';
  @Input()
  public customClass = '';
  @Input()
  public buttonClass = '';
  @Input()
  public customClassModifier = '';
  @Input()
  public searchFromTheBeginning = true;
  @Input()
  public allowClear: boolean;
  @Input()
  public clearOnFocus: boolean;
  @Input()
  public options: AllowedOptionType[] = [];
  @Input()
  public showAdditionalInfo = false;
  @Input()
  public showId: boolean;
  @Input()
  public withImage = false;
  @Output()
  public optionSelected = new EventEmitter<number>();
  @Output()
  public onSearch = new EventEmitter<string>();
  @ViewChild('input', { static: true })
  public input: ElementRef;
  public searchInputControl = new UntypedFormControl('');
  public isInputFocused: boolean;
  public isSelectionJustChanged: boolean;
  public ngControl: NgControl;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly injector: Injector) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public change: (val: unknown) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: () => void = () => {};

  public ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);

    if (this.elementDisabled) {
      this.searchInputControl.disable();
    }
    this.searchInputControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((value: string) => {
        if (value !== null) {
          this.onSearch.emit(value);
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.ngControl) {
      const currentInputValue: string = this.ngControl.value;
      if (currentInputValue) {
        this.updateTextControlValue(currentInputValue);
      }
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get customButtonClass(): string {
    return this.buttonClass ? `${this.buttonClass}` : '';
  }
  public get customInputClass(): string {
    return this.customClass ? `${this.customClass}-input` : '';
  }
  public get customSelectClass(): string {
    return this.customClass ? `${this.customClass}-select` : '';
  }
  public get customOptionClass(): string {
    return this.customClass ? `${this.customClass}-option` : '';
  }
  public get customModifierClass(): string {
    return this.customClassModifier
      ? `c-tsp-mat-select-panel ${this.customClassModifier}`
      : 'c-tsp-mat-select-panel';
  }
  public get inputPlaceholder(): string {
    return this.placeholder ? this.placeholder : 'Any';
  }
  public get inputValue(): string | null {
    return this.ngControl.value as string | null;
  }
  public get matFormClass(): string {
    return this.customClass ? `${this.customClass} ${this.customClassModifier || ''}` : '';
  }
  public getOptionId(option: AllowedOptionType) {
    return this.showId ? `(${option.id})` : '';
  }

  public clearInput(e: MouseEvent) {
    e.stopPropagation();
    this.setValue(null);
    this.searchInputControl.setValue('');
    if (this.input?.nativeElement) {
      (this.input.nativeElement as HTMLInputElement).focus();
    }
    this.optionSelected.emit(null);
  }

  public onBlur() {
    setTimeout(() => (this.isInputFocused = false), 100);
  }
  public onFocus() {
    this.isInputFocused = true;
    // Do not set to null if it is programmatically focused after selection change
    if (this.clearOnFocus && !this.isSelectionJustChanged) {
      this.setValue(null);
      this.searchInputControl.setValue('');
    }
    this.isSelectionJustChanged = false;
  }

  public onSelectionChange(option: DictionaryItem) {
    this.isSelectionJustChanged = true;
    this.setValue(option);
    this.optionSelected.emit(option.id);
  }

  /**
   * Methods bellow allow to behave
   * this component as a form control component
   */

  public setValue(option: DictionaryItem) {
    const value = option ? option.id : null;
    this.change(value);
    this.onTouched();
    this.updateTextControlValue(value);
    if (this.clearOnFocus && option) {
      if (this.input?.nativeElement) {
        (this.input.nativeElement as HTMLInputElement).blur();
      }
    }
  }

  public registerOnChange(fn) {
    this.change = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  public entityHasName(
    entity: Entity & {
      color?: string;
      firstName?: string;
      lastName?: string;
      teamName?: string;
    },
  ) {
    return pipe(
      props(['firstName', 'lastName']) as UnaryOperator<
        Entity & {
          color?: string;
          firstName?: string;
          lastName?: string;
          teamName?: string;
        },
        string[]
      >,
      reject(isNil),
      any(pipe(trim, isEmpty, not)),
    )(entity);
  }

  public writeValue(value: number | string) {
    if (value) {
      this.updateTextControlValue(value);
    } else {
      this.searchInputControl.setValue(null);
    }
  }

  private updateTextControlValue(value: number | string) {
    if (!this.options) {
      return;
    }
    const option = find(
      propEq(value, 'id') as UnaryOperator<DictionaryItem | AutocompleteItem, boolean>,
      this.options,
    );

    if (option && has('name', option)) {
      this.searchInputControl.setValue(option.name, { onlySelf: true, emitEvent: false });
    }
  }
}
