/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ProfileDetailsSystemActions } from '@app-core/profile-details/actions/profile-details.system.actions';
import { ProfileDetailsUserActions } from '@app-core/profile-details/actions/profile-details.user.actions';
import { getFullDeveloperInfo as getFullDeveloperInfoFromDetailsPage } from '@app-core/profile-details/reducers/profile-details.reducer';
import { getCurrentUser } from '@app-core/root/reducer/root.reducer';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { NotifyTrackersActions } from '@app-shared/actions/notify-trackers.actions';
import { formFileData } from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import {
  CommentResponse,
  DictionariesEnum,
  DictionaryShortList,
  FilePayload,
  FullProfile,
  HistoryParams,
  HistoryScope,
  ShortListCreateResponse,
  ShortListUpdateResponse,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getShortListStatuses } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { getSelectedVacancyDetails } from '@app-shared/reducers/vacancy/vacancy.reducer';
import { HistoryEventsService } from '@app-shared/services/history-events/history-events.service';
import { NewEntityService } from '@app-shared/services/new-entity/new-entity.service';
import { ProfileService } from '@app-shared/services/profile/profile.service';
import { ShortListService } from '@app-shared/services/short-list/short-list.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { VacancySelectorSystemActions } from '@tsp-components/vacancy-selector';
import { DateTime } from 'luxon';
import { anyPass, find, isEmpty, isNil, pipe, prop, propEq, propOr, split, test } from 'ramda';
import { of } from 'rxjs';
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ProfileModificationActions } from './profile-modification.actions';

@Injectable()
export class ProfileModificationEffects {
  public refreshProfileDelay = 500;

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<State>,
    private readonly historyService: HistoryEventsService,
    private readonly profileService: ProfileService,
    private readonly newEntityService: NewEntityService,
    private readonly shortListService: ShortListService,
    private readonly translateService: TranslateService,
  ) {}

  public addCommentToProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.AddCommentFromDetailsAction),
      withLatestFrom(this.store$),
      map(([action, state]) => ({
        ...action,
        currentUser: getCurrentUser(state),
      })),
      map(({ devId, comment, bidId, currentUser }) => ({
        target: anyPass([isNil, isEmpty])(bidId) ? 'profile' : 'bid',
        id: bidId || devId,
        currentUser,
        comment,
      })),
      exhaustMap(({ target, comment, id, currentUser }) =>
        this.newEntityService.createComment(target, { id, comment }).pipe(
          mergeMap((item: CommentResponse) => {
            const [firstName, lastName] = split(' ', currentUser.name);

            const shouldRefreshTagsAction = test(/#\p{L}+/giu, comment)
              ? [
                  ProfilesApiActions.RefreshTagsDictionaryAction({
                    dictionaries: [DictionariesEnum.tags],
                  }),
                ]
              : [];

            const bidData =
              target === 'bid'
                ? {
                    bid: item.bid,
                  }
                : {};

            return [
              ...shouldRefreshTagsAction,
              ProfileModificationActions.AddCommentSuccessfulAction({
                comment: {
                  addedBy: {
                    firstName,
                    lastName,
                    id: item.addedById,
                    image: prop('image', currentUser),
                  },
                  comment,
                  ...bidData,
                  createdAt: DateTime.local().toUTC().toISO(),
                  id: item.id,
                },
              }),
              NotifyTrackersActions.TrackAddCommentToProfileAction({
                category: 'Profile',
                action: 'add_comments',
                value: 1,
              }),
              InternalNotificationActions.SuccessNotificationAction({
                message: 'profile-common.effects.comment-added',
              }),
            ];
          }),
          catchError(() => of(ProfileModificationActions.AddCommentFailedAction())),
        ),
      ),
    ),
  );

  public addToShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.AddToShortListFromDetailsAction),
      withLatestFrom(this.store$),
      map(([{ shortList }, state]) => ({
        shortList,
        selectedVacancy: getSelectedVacancyDetails(state),
        shortListStatuses: getShortListStatuses(state),
      })),
      map(({ shortList, selectedVacancy, shortListStatuses }) => ({
        shortList: { ...shortList, project: selectedVacancy },
        isLongList: pipe(
          find(propEq(shortList.status.id, 'id')) as UnaryOperator<
            DictionaryShortList[],
            DictionaryShortList
          >,
          propEq('long_list', 'code'),
        )(shortListStatuses),
        source: 'profile_page',
        shortListStatuses,
      })),
      switchMap(({ shortList, source, isLongList, shortListStatuses }) =>
        this.shortListService.createShortList(shortList, source).pipe(
          mergeMap((newBid: ShortListCreateResponse) => {
            const listType = isLongList ? 'long' : 'short';
            const message = this.translateService.instant(
              `profile-common.effects.profile-added-${listType}`,
              { id: shortList.project.id, name: shortList.project.name },
            );
            return [
              ProfileModificationActions.CreateShortListFromProfileSuccessAction({
                shortList: newBid,
                statuses: shortListStatuses,
              }),
              VacancySelectorSystemActions.UpdateVacancyDataAction(),
              NotifyTrackersActions.TrackCreateShortListAction({
                category: 'ShortList',
                action: 'addTo',
                value: 7,
                label: '',
              }),
              InternalNotificationActions.SuccessNotificationAction({
                message,
              }),
            ];
          }),
          catchError(() => of(ProfileModificationActions.CreateShortListFromProfileFailedAction())),
        ),
      ),
    ),
  );

  public deleteCommentFromProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.DeleteCommentFromDetailsAction),
      exhaustMap(({ id }) =>
        this.profileService.deleteEntity('comment', id).pipe(
          map(() => ProfileModificationActions.DeleteCommentSuccessfulAction({ id })),
          catchError(() => of(ProfileModificationActions.DeleteCommentFailedAction())),
        ),
      ),
    ),
  );

  public deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.DeleteFileFromDetailsAction),
      exhaustMap(({ devId, id, fileType }) =>
        this.profileService.deleteEntity('file', id).pipe(
          map(() =>
            ProfileModificationActions.DeleteFileSuccessAction({ devId, fileType, fileId: id }),
          ),
          catchError(() => of(ProfileModificationActions.DeleteFileFailedAction())),
        ),
      ),
    ),
  );

  public deleteHistoryEventFromProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.DeleteHistoryEventAction),
      exhaustMap(({ event }) =>
        this.historyService.deleteEvent(event).pipe(
          mergeMap(() => {
            return [
              ProfileDetailsSystemActions.DeleteHistoryEventSuccessfulAction(),
              InternalNotificationActions.SuccessNotificationAction({
                message: 'profile-details.effects.history-event-deleted',
              }),
            ];
          }),
          catchError(() => of(ProfileDetailsSystemActions.DeleteHistoryEventFailedAction())),
        ),
      ),
    ),
  );

  public getProfileHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.GetProfileHistoryAction),
      map(({ query, id, actionSource }) => ({
        params: {
          ...query,
          scopeEntityId: id,
          scope: HistoryScope.PROFILE,
        },
        actionSource,
      })),
      exhaustMap(
        ({
          params,
          actionSource,
        }: {
          params: HistoryParams;
          actionSource: 'sidepanel' | 'details';
        }) =>
          this.historyService.getEntityHistory(params).pipe(
            map((events) =>
              ProfileModificationActions.GetProfileHistorySuccessAction({ events, actionSource }),
            ),
            catchError(() => of(ProfileModificationActions.GetProfileHistoryFailedAction())),
          ),
      ),
    ),
  );

  public matchProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.MatchProfileDataAction),
      exhaustMap(({ isMatch, id, publicProfileId }) =>
        this.profileService.matchProfile(isMatch, id, publicProfileId).pipe(
          mergeMap((data) => [
            ProfileModificationActions.MatchProfileDataSuccessAction({ data, id }),
            InternalNotificationActions.SuccessNotificationAction({
              message: `shared.profile-modifications.effects.profile-${
                data.isMatch ? 'valid' : 'invalid'
              }`,
            }),
          ]),
          catchError(() => of(ProfileModificationActions.MatchProfileDataFailedAction())),
        ),
      ),
    ),
  );

  public mergeDuplicates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.MergeProfileDuplicatesAction),
      exhaustMap(({ primary, duplicates }) =>
        this.profileService.mergeProfileDuplicates({ primary, duplicates }).pipe(
          mergeMap(() => [
            ProfileModificationActions.MergeProfileDuplicatesSuccessAction({
              id: primary,
            }),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'shared.profile-modifications.effects.profile-merged',
            }),
          ]),
          catchError(() => of(ProfileModificationActions.MergeProfileDuplicatesFailedAction())),
        ),
      ),
    ),
  );

  public refreshProfileDetailsWithDelay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.CreateShortListFromProfileSuccessAction),
      withLatestFrom(this.store$),
      map(([, state]) => ({
        developer: getFullDeveloperInfoFromDetailsPage(state),
      })),
      filter(({ developer }: { developer: FullProfile }) => !propOr(false, 'isPrivate', developer)),
      tap(() => {
        const action = ProfileDetailsSystemActions.ToggleProfileDetailsLoadingStateAction({
          isProfileLoading: true,
        });
        this.store$.dispatch(action);
      }),
      delay(this.refreshProfileDelay),
      map(() => ProfileDetailsSystemActions.RefreshProfileDetailsAfterAddingToLongListAction()),
    ),
  );

  public removeFromShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileDetailsUserActions.RemoveFromShortListInDetailsActivitiesAction,
        ProfileDetailsUserActions.RemoveFromShortListInDetailsSidepanelAction,
      ),
      switchMap(({ id }) =>
        this.shortListService.removeShortList(id).pipe(
          mergeMap(() => [
            ProfileModificationActions.RemoveFromShortListSuccessAction({ id }),
            VacancySelectorSystemActions.UpdateVacancyDataAction(),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'profile-common.effects.profile-updated',
            }),
          ]),
          catchError(() => of(ProfileModificationActions.RemoveFromShortListFailedAction())),
        ),
      ),
    ),
  );

  public resendShortListForSecurityCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.ResendShortlistForSecurityCheckAction),
      switchMap(({ id }) =>
        this.shortListService.resendShortListForSecurityCheck(id).pipe(
          mergeMap(() => [
            ProfileModificationActions.ResendShortListForSecurityCheckSuccessAction(),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'profile-common.effects.profile-security-check-resend',
            }),
          ]),
          catchError(() =>
            of(ProfileModificationActions.ResendShortlistForSecurityCheckFailedAction()),
          ),
        ),
      ),
    ),
  );

  public updateShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.UpdateShortListInDetailsActivitiesAction),
      switchMap(({ previousShortList, shortList }) =>
        this.shortListService.updateBid(shortList.id, shortList).pipe(
          mergeMap((updatedBid: ShortListUpdateResponse) => {
            return [
              ProfileModificationActions.UpdateShortListSuccessAction({
                shortList: updatedBid,
              }),
              VacancySelectorSystemActions.UpdateVacancyDataAction(),
              InternalNotificationActions.SuccessNotificationAction({
                message: 'profile-common.effects.profile-updated',
              }),
            ];
          }),
          catchError(() =>
            of(
              ProfileModificationActions.UpdateShortListFailedAction({
                shortList: previousShortList,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.UpdateTagsAction),
      exhaustMap(({ data, devId, currentTab }) =>
        this.profileService.updateProfile(devId, data, 'tags').pipe(
          mergeMap((profile: FullProfile) => [
            ProfileModificationActions.UpdateTagsSuccessAction({ data: profile.tags }),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'profile-common.effects.tags-updated',
            }),
            NotifyTrackersActions.TrackUpdateProfileTagsAction({
              category: 'Profile',
              action: `edit_tags`,
              value: 1,
              label: currentTab,
            }),
          ]),
          catchError(() => of(ProfileModificationActions.UpdateTagsFailedAction())),
        ),
      ),
    ),
  );

  public uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.UploadFileFromDetailsAction),
      map(({ id, file, fileType }) => {
        const formData = formFileData(file, '0', fileType, id);
        return { formData, id, fileType };
      }),
      mergeMap(({ formData, id, fileType }) =>
        this.newEntityService.uploadFile<FilePayload>(formData).pipe(
          map((file) => ProfileModificationActions.UploadFileSuccessAction({ file, id, fileType })),
          catchError(() => of(ProfileModificationActions.UploadFileFailedAction())),
        ),
      ),
    ),
  );
}
