import { Pipe, PipeTransform } from '@angular/core';
import { UnaryOperator } from '@app-shared/models';
import { Duration, DurationObjectUnits } from 'luxon';
import {
  always,
  dropLast,
  head,
  ifElse,
  join,
  map,
  pipe,
  reverse,
  toPairs,
  toUpper,
  trim,
  when,
} from 'ramda';

@Pipe({
  name: 'dateInterval',
  standalone: true,
})
export class DateIntervalPipe implements PipeTransform {
  public transform(interval: number, type = 'full'): string {
    const durationType = Math.abs(interval) > 1000 ? 'milliseconds' : 'months';
    const duration = Duration.fromObject({ [durationType]: Math.abs(interval) })
      .shiftTo('years', 'month')
      .toObject();

    return pipe(
      toPairs as UnaryOperator<DurationObjectUnits, [string, number][]>,
      map(reverse as unknown as UnaryOperator<[string, number], [number, string]>) as UnaryOperator<
        [string, number][],
        [number, string][]
      >,
      map((arr) => {
        const [val, label] = arr;
        if (val === 0) {
          return '';
        }
        const cutEnding = val === 1;
        const separator = type === 'short' ? '' : ' ';
        const newLabel = ifElse(
          always(type === 'short'),
          pipe(head, toUpper),
          when(() => cutEnding, dropLast(1)),
        )(label);
        return join(separator, [Math.floor(val), newLabel]);
      }),
      join(' '),
      trim,
    )(duration);
  }
}
