import {
  Comment,
  Contact,
  CV,
  DictionaryItem,
  DirectoryCountry,
  DirectoryCustomFieldResponse,
  Entity,
  Locales,
  NotificationLetter,
  ProfileNotificationEntry,
  ProfileProject,
  ShortList,
  SimpleProject,
  Skill,
  SubscriptionPackagesEnum,
  UserOptions,
  VacanciesListQuickFilters,
} from '.';

// eslint-disable-next-line no-shadow
export enum profileIncludedSources {
  'Manually' = 'shared.models.profile.profile-included-sources.manually',
  'My Talents' = 'shared.models.profile.profile-included-sources.my-talents',
  'Public Talents' = 'shared.models.profile.profile-included-sources.public-talents',
}

// eslint-disable-next-line no-shadow
export enum TalentFormTabs {
  INITIAL = 'initial',
  DETAILS = 'details',
  ADDITIONAL = 'additional',
  SOCIALS = 'socials',
}

export const externalSources: { code: ProfileExternalSearchSources; name: string }[] = [
  {
    code: 'github',
    name: 'GitHub',
  },
  {
    code: 'rabotaua',
    name: 'Rabota.ua',
  },
];

export type ProfileExternalSearchSources = 'github' | 'rabotaua';

export type ProfileExternalDataSources =
  | 'djinni'
  | 'facebook'
  | 'grc'
  | 'github'
  | 'gitlab'
  | 'hh'
  | 'head_hunter'
  | 'linkedin'
  | 'other'
  | 'rabota_ua'
  | 'stackoverflow'
  | 'twitter'
  | 'upwork'
  | 'work_ua'
  | 'xing';

export interface ExternalCounts {
  github?: number;
  rabotaua?: number;
}

export interface UserAdditionalInfo {
  intercomId: string;
  intercomHash: string;
  metricApplied: number;
  metricConfirmed: number;
  metricLiExtension: boolean;
  metricLiProfiles: number;
  metricProjectsAdded: number;
  metricTurbohiringProfiles: number;
  portalTrialEnd: string;
  portalAvailableTo: string;
  portalName: string;
  portalStatus: number;
  vacanciesCount: number;
}

export interface UserAllowedSearchPages {
  candidates: boolean;
  profiles: boolean;
}

export interface TutorialProgress {
  engaging: {
    createMessageTemplate: boolean;
    contactFromPlugin: boolean;
    contactFromTSP: boolean;
  };
  initialization: {
    createVacancy: boolean;
    publicVacancy: boolean;
  };
  managing: {
    pipelines: boolean;
    kanban: boolean;
    hire: boolean;
  };
  sourcing: {
    findContactsViaPlugin: boolean;
    useRecommendations: boolean;
    addCandidateToDatabase: boolean;
    useSearchConsole: boolean;
    addCandidateToVacancy: boolean;
    saveSearchParameters: boolean;
  };
}

export interface CurrentUser {
  id?: number;
  additionalInfo: UserAdditionalInfo;
  availableOptions: Partial<UserOptions>;
  availablePages: UserAllowedSearchPages;
  allowedExternalDataReports: boolean;
  allowedPushNotification: boolean;
  customFields: DirectoryCustomFieldResponse[];
  email: string;
  image: string;
  isPaid: boolean;
  lastVacancy: number | null;
  locale: Locales;
  name: string;
  panelState: boolean;
  phone?: string;
  portalId: number;
  portalName?: string;
  roles: string[];
  simpleCardViewState: boolean;
  skype?: string;
  showTutorial?: boolean;
  subscription?: UserSubscription;
  supportUrl: string | null;
  vacancyUrl: string;
  vacancyQuickFiltersSelection: VacanciesListQuickFilters;
}
export type PackageAccess =
  | 'clients'
  | 'dashboard'
  | 'directories'
  | 'notifications'
  | 'reports'
  | 'teammates'
  | 'candidates'
  | 'external-profiles'
  | 'profile'
  | 'profiles'
  | 'vacancies'
  | 'vacancy'
  | 'settings';

export type PackageAccesses = {
  [key in SubscriptionPackagesEnum]: PackageAccess[];
};
export interface UserSubscription {
  active: boolean;
  availableTo: string;
  canActivateTrial: boolean;
  isTrial: boolean;
  name: SubscriptionPackagesEnum;
  trialEnd: string;
}
export interface ParsedCvData {
  dateOfBirth: Date;
  emails: string[];
  firstName: string[];
  lastName: string[];
  location: string[];
  links: string[];
  phones: string[];
  skills: number[];
  skypes: string[];
  specialities: number[];
  telegrams: string[];
  textContent: string;
  urlToCV: string;
}

export interface AnalyzedAiCvData {
  allowFullResponse: boolean;
  birthDate: string;
  cvMimetype: string;
  currency: string;
  emails: string[];
  firstName: string;
  lastName: string;
  middleName: string;
  phones: string[];
  skypes: string[];
  telegrams: string[];
  salary: number;
  socialLinks: string[];
  suggestedCities: DictionaryItem[];
  suggestedEnglishLevels: DictionaryItem[];
  suggestedLevels: DictionaryItem[];
  suggestedMainSkills: DictionaryItem[];
  suggestedHardSkills: DictionaryItem[];
  suggestedSoftSkills: DictionaryItem[];
  suggestedSpeciality: DictionaryItem[];
  suggestions: Array<[string, number]>;
  summary: string;
  textContent: string;
  urlToCV: string;
}

export interface StringTypedValue {
  meta: number[];
  value: string;
  type: string;
}
export interface ElasticPublicCertificate {
  authority: string;
  certificationUrl: string;
  finishDate: Date | string;
  licenseNumber: string;
  meta: number;
  name: string;
  startDate: Date | string;
}
export interface ElasticPublicEducation {
  description: string;
  finishDate: Date | string;
  meta: number;
  schoolName: string;
  speciality: string;
  startDate: Date | string;
  type: string;
}
export interface ElasticPublicProject {
  readonly companyName: string;
  readonly dateFrom: Date | string;
  readonly dateTo: Date | string;
  readonly description: string;
  readonly meta: number;
  readonly position: string;
  readonly skills: ElasticPublicSkill[];
}
export interface ElasticPublicMeta {
  readonly id: number;
  readonly source: string;
  readonly updatedAt: Date | string;
}
export interface ElasticPublicSkill {
  readonly id: number;
  readonly meta: number[];
  readonly weight: number;
}

export interface MatchProfileResponse {
  id: number;
  publicProfileId: string;
  isMatch: boolean;
  createdAt: string;
}
export interface PublicSourceData {
  id: string;
  source: ProfileExternalDataSources;
  birthday: string;
  cityId: number;
  countryId: number;
  certificates: ElasticPublicCertificate[];
  education: ElasticPublicEducation[];
  location: string;
  matched: boolean | null;
  name: string;
  position: string;
  projects: SimpleProject[];
  skills: ElasticPublicSkill[];
  speciality: number;
  summary: string;
  tags: DictionaryItem[];
  updatedAt: string;
}

export interface ProfileDuplicate extends Pick<Profile, 'createdAt' | 'id' | 'image' | 'name'> {
  firstName: string;
  lastName: string;
}

export interface ProfileDuplicateExtendedData extends ProfileDuplicate {
  commentsCount: number;
  comments: Comment[];
  filesCount: number;
  files: CV[];
  matchedContacts: ProfileContactEntry[];
  notifications: ProfileNotificationEntry[];
  notificationCounts: number;
  processingCount: number;
  processingList: ShortList[];
}

export interface Profile {
  applies?: ShortList;
  availableContacts: string[];
  birthday: string | null;
  bidCounts: {
    applies?: number;
    confirmed?: number;
    hired?: number;
    longList: number;
    shortList: number;
  };
  candidatesData?: Profile[];
  cityId?: number;
  countryId?: number;
  commentsCount: number;
  color: string;
  createdAt: string;
  currency: string | null;
  cvs?: CV[];
  otherFiles?: CV[];
  cvsLength?: number;
  duplicates: Profile[];
  duplicatesCount: number;
  englishLevel?: number;
  experience: number;
  externalContacts: string[];
  externalProjects: ProfileProject[];
  fieldValues?: DirectoryCustomFieldResponse[];
  externalData?: PublicSourceData[];
  githubInfo: {
    company_name?: string;
    followers?: number;
    following?: number;
    gists?: number;
    hireable?: string;
    repositories?: number;
    summary?: string;
    title?: string;
  };
  id: string;
  image: string;
  insideInformation?: string;
  isExternalProfile: boolean;
  isPrivate: boolean;
  isPublicProfiles: boolean;
  lastComment?: Comment;
  lastPositionExperience: number;
  letterCounts: {
    received: number;
    opened: number;
    clicked: number;
  };
  linkedinInfo: {
    company_name?: string;
    headline?: string;
    position?: string;
    summary?: string;
    title?: string;
  };
  location: string | null;
  mainSkill: Skill | null;
  middleName?: string;
  name: string;
  position?: string;
  professionalLevel: number;
  projects: SimpleProject[];
  rate?: number;
  rateCard?: number;
  salaryComment?: string;
  skills: Skill[];
  softSkills?: Skill[];
  source: DictionaryItem;
  speciality: number | null;
  suggestionsCount: number;
  suggestedSkills?: Skill[];
  stackoverflowInfo: {
    bronze_badges?: number;
    gold_badges?: number;
    rate?: number;
    silver_badges?: number;
    summary?: string;
    title?: string;
  };
  summary: string | null;
  tags?: DictionaryItem[];
  unsubscribeDate?: string;
  updatedAt?: string;
  voices?: CV[];
  voicesLength?: number;
  viewedByRecruiter?: {
    [key: number]: {
      date: string;
      source: string;
      vacancy: number;
    };
  };
}
export enum ProfileContactRequestSource {
  EDIT = 'editDialog',
  PAGE = 'profilePage',
  SIDEPANEL = 'sidePanel',
  CANDIDATES = 'candidates',
  SEARCH_CONSOLE = 'searchConsole',
}

export enum ProfileDetailsRequestSource {
  PROFILE = 'profilePage',
  EDIT = 'editDialog',
  SIDEPANEL = 'sidePanel',
}

export interface ProfileContactEntry {
  id?: number;
  value: string;
  main: boolean;
  linked?: boolean;
  public?: boolean;
  unsubscribed: string | null;
}

export interface ProfileSocialLink {
  id: number;
  linked: boolean;
  public: boolean;
  type: string;
  url: string;
}

export interface ProfileContacts {
  mainContacts: {
    emails: ProfileContactEntry[];
    skypes: ProfileContactEntry[];
    phones: ProfileContactEntry[];
    telegrams: ProfileContactEntry[];
  };
  socialLinks: Array<Entity & ContactLink & { public?: boolean }>;
}
export interface ProfileContactData {
  contacts: ProfileContacts;
  isPaid: boolean;
  requestLeft: number;
}

export type ContactsType = 'emails' | 'phones' | 'skypes' | 'telegrams';

export interface ProfileRequest {
  birthday: string;
  city: number;
  country: number;
  currency: string;
  emails: Contact[];
  english: number | string;
  fieldValues: DirectoryCustomFieldResponse[];
  firstName: string;
  id: string;
  image: string;
  insideInformation: string;
  lastName: string;
  level: number;
  mainSkill: number;
  middleName?: string;
  phones: Contact[];
  profileSummary: string;
  rate: number;
  rateCard: number;
  salaryComment: string;
  skills: number[];
  skypes: Contact[];
  socialLinks: string[];
  softSkills: number[];
  sourceId: number;
  speciality: number;
  telegrams: Contact[];
}
export interface SuggestedSkill extends Entity {
  weight: number;
  soft: boolean;
}

export interface XRayLink {
  range: string;
  source: string;
  type: string;
  url: string;
  withSynonyms: boolean;
}

export interface ContactLink {
  type: string;
  url: string;
}

export interface FullProfile extends Profile {
  addedBy?: Partial<SimpleUser>;
  bookedBy?: {
    id: number;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    image: string;
    customFields?: DirectoryCustomFieldResponse[];
  };
  bookedDate?: string;
  city?: DictionaryItem;
  country?: DirectoryCountry;
  comments?: Comment[];
  firstName?: string;
  duplicates: FullProfile[];
  insideInformation?: string;
  lastName?: string;
  matchedContacts: Record<string, string[]>;
  notificationLetter?: NotificationLetter[];
  notifications: ProfileNotificationEntry[];
  notificationCounts: number;
  previousCities?: DictionaryItem[];
  processingCount?: number;
  processingList: ShortList[];
  talentFiles: CV[];
}
export interface SimpleRecruiter extends SimpleUser {
  email?: string;
  firstLogin?: string;
  firstLoginAt?: string;
  image?: string;
  lastLogin?: string;
  name: string;
  skype?: string;
  telephone?: string;
  webSite?: string;
}
export interface SimpleUser extends Entity {
  color?: string;
  firstName?: string;
  imageThumbnail?: string;
  lastName?: string;
  shortName?: string;
}
export interface Recruiter extends SimpleUser {
  email?: string;
  firstLogin?: string;
  firstLoginAt?: string;
  image?: string;
  lastLogin?: string;
  name?: string;
  shortName?: string;
  skype?: string;
  telephone?: string;
}

export interface TotalCounts {
  candidates?: number;
  profiles?: number;
}

export interface ProfileSuggestion {
  firstName: string;
  id: string;
  image?: string;
  lastName: string;
  company?: string;
}
