import {
  CurrentUser,
  ExternalCredentials,
  Locale,
  Locales,
  MailTemplateTypes,
  NotificationChannel,
  Profile,
  ProfileContactData,
  ProfileContacts,
  ProfilesSources,
  ShortList,
  ShortListSource,
  SpaceEntity,
  TutorialProgress,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const ChangeLocalizationFailedAction = createAction(
  '[Root Module] Error happened during saving new localization',
);
const AddNewSourceFailedAction = createAction(
  '[Root Module] Error happened during adding short list source',
);
const AddNewSourceSuccessAction = createAction(
  '[Root Module] New short list source successfully saved',
  props<{ source: ShortListSource }>(),
);
const ChangeLocalizationSuccessAction = createAction(
  '[Root Module] New user localization successfully saved',
  props<{ locale: Locales }>(),
);
const CloseConfirmationPopupAction = createAction('[Root Module] Close confirmation dialog popup');
const GetAvailableLocalesAction = createAction(
  '[Available Locales Resolver] Get available locales',
);
const GetAvailableLocalesFailedAction = createAction(
  '[Root Module] Error happened during requesting available locales',
);
const GetAvailableLocalesSuccessAction = createAction(
  '[Root Module] Available locales saved to store',
  props<{ locales: Locale[] }>(),
);
const GetNewVacanciesCountAction = createAction(
  '[Root Module | New Vacancies Count Resolver] Request new vacancies count',
);
const GetNewVacanciesCountFailedAction = createAction(
  '[Root Module | New Vacancies Count Resolver] Error happened during requesting new vacancies count',
);
const GetNewVacanciesCountSuccessAction = createAction(
  '[Root Module | New Vacancies Count Resolver] New vacancies count retrieved successfully',
  props<{ count: number }>(),
);
const GetChromePluginVersionAction = createAction('[Root Module] Request extension version');
const GetChromePluginVersionSuccessAction = createAction(
  '[Root Module] Save extension version to store',
  props<{ version: string }>(),
);
const GetProfileContactsFailedAction = createAction(
  '[Root Module] Error happened during retrieving profile contacts',
);
const GetProfileContactsSuccessAction = createAction(
  '[Root Module] Profile contacts successfully retrieved',
  props<{
    shouldOpenPopup: boolean;
    data: ProfileContactData;
    profileName: string;
    clickedContact: string;
  }>(),
);
const GetTutorialProgressAction = createAction(
  '[Start Dashboard Module] Get user tutorial progress',
);
const GetTutorialProgressFailedAction = createAction(
  '[Start Dashboard Module] Error happened during getting user tutorial progress',
);
const GetTutorialProgressSuccessAction = createAction(
  '[Start Dashboard Module] User tutorial progress successfully received',
  props<{ progress: TutorialProgress }>(),
);
const GetUserAction = createAction('[Root Module | User Guard] Get logged user data');
const GetUserFailedAction = createAction(
  '[Root Module | User Guard] Request for logged user data failed',
  props<{ message?: string }>(),
);
const GetUserSuccessAction = createAction(
  '[Root Module | User Guard] Active user data stored to state',
  props<{ user: CurrentUser }>(),
);
const InitFirebaseNotificationAppAction = createAction(
  '[Root Module] Initialize Firebase Cloud Messaging app for internal notifications',
);
const InitFirebaseNotificationAppFailedAction = createAction(
  '[Root Module] Firebase Cloud Messaging app were not initialized',
);
const InitFirebaseNotificationAppSuccessAction = createAction(
  '[Root Module] Successfully initialize Firebase Cloud Messaging app',
  props<{ token: string }>(),
);
const OpenConfirmationDialogForClosePopupAction = createAction(
  '[Root Module] Confirm closing the popup',
);
const OpenConfirmationDialogForRemoveBidAction = createAction(
  '[Root Module] Confirm removing bid',
  props<{ target: string; id: number; devId?: string }>(),
);
const PresetVacancyAction = createAction(
  '[Root Module | Selected Vacancy Resolver] Preset vacancy selector with saved data',
  props<{ id: number | null }>(),
);
const RequestNotificationTemplatesAction = createAction(
  '[Root Module | Root Profiles Effect] Request notification templates with normalized data',
  props<{
    source: ProfilesSources;
    selectedVacancy: number;
    ids: string[];
    notificationType: NotificationChannel;
    shortList?: ShortList;
    templateType: MailTemplateTypes;
  }>(),
);
const RequestNotificationTemplatesFailedAction = createAction(
  '[Root Module | Profiles API] Error during requesting templates from server',
);
const RequestVacanciesAddressListAction = createAction(
  '[Root Module] Request vacancies address list',
);
const RequestVacanciesAddressListSuccessAction = createAction(
  '[Root Module] Vacancies address list successfully received',
  props<{ addressList: string[] }>(),
);
const RequestVacanciesAddressListFailedAction = createAction(
  '[Root Module] Request vacancies address list failed',
);
const RevokeFirebaseNotificationPermissionFailedAction = createAction(
  '[Root Module] Error happened during revoking notification token',
);
const RevokeFirebaseNotificationPermissionSuccessAction = createAction(
  '[Root Module] Notification permissions revoked successfully',
);
const SetDataForNewTalentDialogAction = createAction(
  '[Root Dialog Effects] Set pre-filled data for new talent dialog',
  props<{ profile: Profile; contacts: ProfileContacts; vacancyId: number | null }>(),
);
const SetUserLocalizationAction = createAction(
  '[User Guard] Set user localization from user profile',
  props<{ locale: Locales }>(),
);
const ShowFirebaseNotificationAction = createAction(
  '[Root Module] Show notification received from Firebase Cloud Messaging',
  props<{ message: string; notification: Notification }>(),
);

const GetExternalCredentialsFailedAction = createAction(
  '[Root Module] Error happened during receiving user external credentials',
);
const GetExternalCredentialsSuccessAction = createAction(
  '[Root Module] User external credentials successfully received',
  props<{ payload: ExternalCredentials }>(),
);

const SaveExternalCredentialsFailedAction = createAction(
  '[Root Module] Error happened during saving user external credentials',
);
const SaveExternalCredentialsSuccessAction = createAction(
  '[Root Module] User external credentials successfully saved',
  props<{ payload: ExternalCredentials }>(),
);

const UpdateExternalCredentialsFailedAction = createAction(
  '[Root Module] Error happened during updating user external credentials',
);
const UpdateExternalCredentialsSuccessAction = createAction(
  '[Root Module] User external credentials successfully updated',
  props<{ payload: ExternalCredentials }>(),
);

const UnsubscribeEmailFailedAction = createAction(
  '[Root Module | Root Dialogs Effects] Error happened during unsubscribing emails',
);
const UnsubscribeEmailSuccessAction = createAction(
  '[Root Module | Root Dialogs Effects] Emails successfully unsubscribed',
);
const UpdateUserSpaceFailedAction = createAction(
  '[Root Module | User Effects] Error happened during updating space',
);
const UpdateUserSpaceSuccessAction = createAction(
  '[Root Module | User Effects] Space successfully updated',
  props<{ refreshToken: null; spaceId: number }>(),
);
const GetSpacesAction = createAction('[Root Module | Recruiters API] Try to get spaces');
const GetSpacesFailedAction = createAction(
  '[Root Module | Recruiters API] Error happened during requesting spaces',
);
const GetSpacesSuccessAction = createAction(
  '[Root Module | Recruiters API] Spaces successful saved to store',
  props<{ spaces: SpaceEntity[] }>(),
);
const UnselectVacancyAfterDeletingAction = createAction(
  '[Root Module] Unselect vacancy for recruiter after deleting',
  props<{ vacancyId: number }>(),
);

const actions = {
  AddNewSourceFailedAction,
  AddNewSourceSuccessAction,
  ChangeLocalizationFailedAction,
  ChangeLocalizationSuccessAction,
  CloseConfirmationPopupAction,
  GetAvailableLocalesAction,
  GetAvailableLocalesFailedAction,
  GetAvailableLocalesSuccessAction,
  GetChromePluginVersionAction,
  GetChromePluginVersionSuccessAction,
  GetExternalCredentialsFailedAction,
  GetExternalCredentialsSuccessAction,
  GetNewVacanciesCountAction,
  GetNewVacanciesCountFailedAction,
  GetNewVacanciesCountSuccessAction,
  GetProfileContactsFailedAction,
  GetProfileContactsSuccessAction,
  GetSpacesAction,
  GetSpacesFailedAction,
  GetSpacesSuccessAction,
  GetTutorialProgressAction,
  GetTutorialProgressFailedAction,
  GetTutorialProgressSuccessAction,
  GetUserAction,
  GetUserFailedAction,
  GetUserSuccessAction,
  InitFirebaseNotificationAppAction,
  InitFirebaseNotificationAppFailedAction,
  InitFirebaseNotificationAppSuccessAction,
  OpenConfirmationDialogForClosePopupAction,
  OpenConfirmationDialogForRemoveBidAction,
  PresetVacancyAction,
  RequestNotificationTemplatesAction,
  RequestNotificationTemplatesFailedAction,
  RequestVacanciesAddressListAction,
  RequestVacanciesAddressListFailedAction,
  RequestVacanciesAddressListSuccessAction,
  RevokeFirebaseNotificationPermissionFailedAction,
  RevokeFirebaseNotificationPermissionSuccessAction,
  SaveExternalCredentialsFailedAction,
  SaveExternalCredentialsSuccessAction,
  SetDataForNewTalentDialogAction,
  SetUserLocalizationAction,
  ShowFirebaseNotificationAction,
  UnselectVacancyAfterDeletingAction,
  UnsubscribeEmailFailedAction,
  UnsubscribeEmailSuccessAction,
  UpdateExternalCredentialsFailedAction,
  UpdateExternalCredentialsSuccessAction,
  UpdateUserSpaceFailedAction,
  UpdateUserSpaceSuccessAction,
};

const rootSystemActions = union(actions);

export type RootSystemActionsType = typeof rootSystemActions;

export const RootSystemActions = actions;
