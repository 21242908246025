// eslint deprecation/deprecation: 0
import { FlatTreeControl } from '@angular/cdk/tree';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  MatTree,
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodePadding,
} from '@angular/material/tree';
import { State } from '@app-shared/reducers';
import { select, Store } from '@ngrx/store';
import { SpinnerComponent } from '@tsp-components/spinner';
import { Observable } from 'rxjs';
import { KernelActions } from '../../actions/kernel.actions';
import { KernelDepartmentStructure } from '../../models/kernel.model';
import {
  getKernelDataLoadingState,
  getKernelOrganizationStructure,
} from '../../selectors/kernel.selectors';
import { KernelOrganizationUnitComponent } from '../kernel-organization-unit/kernel-organization-unit.component';

@Component({
  imports: [
    AsyncPipe,
    KernelOrganizationUnitComponent,
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatTreeNodePadding,
    SpinnerComponent,
  ],
  selector: 'kernel-organization-structure',
  templateUrl: './kernel-organization-structure.component.html',
  styleUrls: ['./kernel-organization-structure.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class KernelOrganizationStructureComponent implements OnInit {
  public isLoading$?: Observable<boolean>;
  public treeControl = new FlatTreeControl<KernelDepartmentStructure>(
    (node) => node.level,
    (node) => node.hasChildren,
  );

  private readonly _transformer = (node: KernelDepartmentStructure) => node;
  public treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.hasChildren,
    (node) => node.children,
  );
  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private readonly store$: Store<State>) {}

  public ngOnInit(): void {
    this.isLoading$ = this.store$.pipe(select(getKernelDataLoadingState));

    this.store$
      .pipe(select(getKernelOrganizationStructure))
      .subscribe((data) => (this.dataSource.data = data));
  }

  public changeAssignments(department: KernelDepartmentStructure): void {
    this.store$.dispatch(KernelActions.OpenDepartmentAssignmentDialogAction({ department }));
  }
}
