/* eslint-disable no-shadow */
export type TagSelectorTheme =
  | 'dark-theme'
  | 'keyword-theme'
  | 'light-theme'
  | 'skill-theme'
  | 'tag-theme'
  | 'popup-theme';

export const enum SelectionStatesEnum {
  None = 'none',
  Partial = 'partial',
  All = 'all',
}

export const SourcesForShortList = {
  candidates: 'my_talents',
  profiles: 'public_talents',
  'external-profiles': 'public_talents',
  'x-ray-links': 'public_talents',
};
