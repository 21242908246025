import { NgClass, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ProfileProject, SimpleProject, UnaryOperator } from '@app-shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { HighlightSearchWordsDirective } from '@tsp-directives';
import { anyPass, gte, isEmpty, isNil, length, pipe, propOr, slice, unless } from 'ramda';

@Component({
  imports: [HighlightSearchWordsDirective, MatTooltip, NgClass, SlicePipe, TranslateModule],
  selector: 'profile-work-history',
  templateUrl: './profile-work-history.component.html',
  styleUrls: ['./profile-work-history.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileWorkHistoryComponent {
  @Input()
  public projects: SimpleProject[] = [];
  @Input()
  public externalProjects: ProfileProject[] = [];
  @Input()
  public searchParamsForHighlight: { [key: string]: string[] };
  @Input()
  public set showMoreButtonForWorkHistory(value: boolean) {
    this.showMoreButton = value;
    this.showAllProjects = !value;
  }
  public showAllProjects = false;
  public showMoreButton = true;

  public get toggleTooltip(): string {
    return `profile-preview.profile-work-history.${
      this.showAllProjects ? 'less.tooltip' : 'more.tooltip'
    }`;
  }
  public get renderButtonProjects(): boolean {
    return this.projects ? this.projects.length > 2 : false;
  }

  public getProjectExperience(project: ProfileProject | SimpleProject) {
    return project.experience ? `(${project.experience})` : '';
  }
  public onShowProjects() {
    this.showAllProjects = !this.showAllProjects;
  }
  public getProjectPosition(project: ProfileProject | SimpleProject): string {
    return pipe(
      propOr('', 'position'),
      unless(
        anyPass([isNil, isEmpty, pipe(length, gte(30)), () => this.showAllProjects]),
        pipe(
          slice(0, 30) as UnaryOperator<string, string>,
          (position: string): string => `${position}...`,
        ),
      ),
    )(project) as string;
  }
}
