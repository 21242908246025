import { createAction, props } from '@ngrx/store';
import { CustomForm } from '@app-shared/models';

export const GetFormConfigsAction = createAction(
  '[Form configs Resolver] Request form configs from server',
);
export const GetFormConfigsFailedAction = createAction(
  '[Form configs Resolver] Request for form configs list failed',
);
export const GetFormConfigsSuccessAction = createAction(
  '[Form configs Resolver] Form configs stored to state',
  props<{ configs: CustomForm[] }>(),
);
