/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '@app-environment/environment';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { SystemService } from '@app-shared/services/system/system.service';

@Injectable()
export class AppMetadataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly systemService: SystemService,
  ) {}

  public getAppConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataActions.GetAppFeatures),
      switchMap(() =>
        this.systemService.getAppConfiguration().pipe(
          map((features) => MetadataActions.SetAppFeatures({ features })),
          catchError(() =>
            of(
              MetadataActions.SetDefaultAppFeatures({
                features: environment.defaultFeatureConfiguration,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
