import { includes } from 'ramda';
import { isKernelEnv } from '@app-shared/reducers/router/router.reducer';

const kernelSuccessCloseStatuses = [16, 17];
const kernelUnsuccessCloseStatuses = [5];

export const additionalVacancyDataByStatus = (
  status: number,
): { successClosedDate?: string; unsuccessClosedDate?: string } => {
  if (!isKernelEnv) {
    return {};
  }

  const dateNow = new Date().toJSON();
  switch (true) {
    case includes(status, kernelSuccessCloseStatuses):
      return { successClosedDate: dateNow };
    case includes(status, kernelUnsuccessCloseStatuses):
      return { unsuccessClosedDate: dateNow };
    default:
      return {};
  }
};
