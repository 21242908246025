import { Pipe, PipeTransform } from '@angular/core';
import { AllowedOptionType, Vacancy } from '@app-shared/models';

@Pipe({
  name: 'castToVacancy',
  standalone: true,
})
export class CastToVacancyPipe implements PipeTransform {
  transform(value: AllowedOptionType): Vacancy {
    return value as Vacancy;
  }
}
