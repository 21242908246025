import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatTooltip } from '@angular/material/tooltip';
import { getProfileDataSourceName } from '@app-shared/functions/utilities/utilities';
import { ProfileExternalDataSources } from '@app-shared/models';
import { TranslatePipe } from '@ngx-translate/core';
import { CapitalizeFirstLetterPipe } from '@tsp-pipes';
import { isNil } from 'ramda';

@Component({
  imports: [
    CapitalizeFirstLetterPipe,
    MatButtonToggle,
    MatButtonToggleGroup,
    MatIconButton,
    MatTooltip,
    TranslatePipe,
  ],
  selector: 'profile-sources-toggle',
  templateUrl: './sources-toggle.component.html',
  styleUrls: ['./sources-toggle.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SourcesToggleComponent {
  @Input()
  public profileSources: { source: ProfileExternalDataSources; matched: boolean }[];
  @Input()
  public selectedSourceIndex: number;
  @Input()
  public showControls = false;
  @Input()
  public showMyData = false;
  @Output()
  public match = new EventEmitter<{ isMatch: boolean; index: number }>();
  @Output()
  public toggle = new EventEmitter<number | 'my-data'>();
  public moreButtonState = false;
  public getProfileSource = getProfileDataSourceName;

  public matchAction(event: MouseEvent, isMatch: boolean, index: number) {
    event.stopPropagation();
    this.match.emit({ isMatch, index });
  }
  public isProfileDataNotMatched(isMatched: boolean | null): boolean {
    return isNil(isMatched);
  }

  public trackByIndex(i: number) {
    return i;
  }
}
