<mat-form-field
  [floatLabel]="floatLabel"
  class="c-tsp-mat-form-field"
  [ngClass]="'c-tsp-mat-form-field--' + (label ? 'with-label' : 'filter')"
>
  <mat-label>{{ label | translate }}</mat-label>
  <mat-select
    [formControl]="control"
    panelClass="c-tsp-mat-select-panel"
    [placeholder]="placeholder | translate"
    (selectionChange)="valueChange()"
    multiple
  >
    <app-mat-select-search
      [formControl]="filterInput"
      [previousSelectedValues]="control.value"
      [placeholderLabel]="placeholderLabel | translate"
    >
    </app-mat-select-search>
    @if (useTrigger) {
      <mat-select-trigger class="multiple-select-search__trigger-text" translate>
        {{ triggerText | translate }}
      </mat-select-trigger>
    }
    <div class="multiple-select-search__option-wrapper">
      @for (option of displayedOptions; track option) {
        <mat-option
          class="c-tsp-mat-multiple-select__option"
          [value]="option.id"
          [disabled]="isOptionDisabled(option)"
        >
          @if (option | castToRecruiter; as optionRecruiter) {
            <div class="multiple-select-search__option">
              @if (withImage) {
                <div
                  [ngClass]="{
                    'multiple-select-search__image': optionRecruiter.image,
                    'multiple-select-search__image-none': !optionRecruiter.image,
                  }"
                >
                  @if (optionRecruiter.image) {
                    <img
                      class="multiple-select-search__image"
                      [src]="optionRecruiter.image"
                      alt="Image"
                    />
                  } @else {
                    <span class="multiple-select-search__initials">
                      {{ optionRecruiter | getInitials }}
                    </span>
                  }
                </div>
              }
              <span class="multiple-select-search__option-value">{{ displayedName(option) }}</span>
            </div>
          }
        </mat-option>
      }
    </div>
  </mat-select>
  @if (controlHasErrorType('required')) {
    <mat-error class="multiple-select-search__error-text">
      <span
        [innerHTML]="'add-new-entity.new-vacancy.form.assigned-recruiter.required' | translate"
      ></span>
    </mat-error>
  }
</mat-form-field>
