import { CdkAutoSizeVirtualScroll } from '@angular/cdk-experimental/scrolling';
import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import {
  getSpaceId,
  isUserOptionAllowed,
  userIsRecruiterOrAdmin,
} from '@app-core/root/reducer/root.reducer';
import { RouterActions } from '@app-shared/actions/router.actions';
import { ProfileModificationActions } from '@app-shared/effects/profile-modification/profile-modification.actions';
import { SelectionStatesEnum } from '@app-shared/enums/selection-states.enum';
import {
  AllDictionaries,
  CalendarPayload,
  CalendarTarget,
  DictionaryShortList,
  DictionaryShortListCodes,
  DirectoryFieldType,
  Locales,
  MailTemplateTypes,
  NotificationChannel,
  PaginationParams,
  Profile,
  ProfileContactRequestSource,
  ShortList,
  ShortListPayload,
  SortOptions,
  SortParams,
  TalentFormTabs,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getTrelloButtonState,
  portalAllowedToShowInsideInfo,
} from '@app-shared/reducers/app-feature/app-feature.reducer';
import {
  getSelectedIds,
  getSelectionLoadingState,
} from '@app-shared/reducers/selection/selection.reducer';
import { ShortListModificationService } from '@app-shared/services/short-list/short-list-modification.service';
import { Store, select } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { ProfilePreviewComponent } from '@tsp-components/profile-preview';
import {
  always,
  filter,
  find,
  head,
  identity,
  ifElse,
  includes,
  is,
  isEmpty,
  isNil,
  mergeRight,
  objOf,
  path,
  pathEq,
  pipe,
  prop,
  propEq,
  split,
} from 'ramda';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProfilesListControlBarComponent } from '../control-bar/profiles-list-control-bar.component';
import { ProfilesListActions } from './../profiles-list.actions';

@Component({
  imports: [
    AsyncPipe,
    CdkAutoSizeVirtualScroll,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    MatCheckbox,
    MatPaginator,
    MatProgressSpinner,
    MatTooltip,
    NgClass,
    NgStyle,
    ProfilePreviewComponent,
    ProfilesListControlBarComponent,

    TranslatePipe,
  ],
  selector: 'app-profiles-list',
  templateUrl: './profiles-list.component.html',
  styleUrls: ['./profiles-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfilesListComponent implements OnInit, OnDestroy {
  @Input()
  public statusesCustomFields: DirectoryFieldType[];
  @Input()
  public isActiveSearchParams: boolean;
  @Input()
  public isKanban = false;
  @Input()
  public profiles?: Profile[];
  @Input()
  public dictionaries?: Partial<AllDictionaries>;
  @Input()
  public activeTab?: string;
  @Input()
  public simpleCardViewState = false;
  @Input()
  public activeSkillsFilters: number[] = [];
  @Input()
  public isProfilesLoading = false;
  @Input()
  public selectedVacancyId?: number;
  @Input()
  public allowSelection = true;
  @Input()
  public activeSortParams: SortParams;
  @Input()
  public availableSortings: SortOptions[];
  @Input()
  public showPagination = true;
  @Input()
  public totalCount?: number;
  @Input()
  public paginationParams?: PaginationParams;
  @Input()
  public searchParamsForHighlight: { [key: string]: string[] };
  @Input()
  public shortListStatuses: DictionaryShortList[] = [];
  @Input()
  public userLocale: Locales;

  @Output() public addToCompany: EventEmitter<{ vacancyId: number | null; profile: Profile }> =
    new EventEmitter();
  @Output() public addToList: EventEmitter<{
    shortList: Partial<ShortListPayload>;
    profile: Profile;
  }> = new EventEmitter();
  @Output() public addToListById = new EventEmitter<string>();
  @Output() public changeSimpleCardViewState: EventEmitter<boolean> = new EventEmitter();
  @Output() public linkSuggestion: EventEmitter<string[]> = new EventEmitter();
  @Output() public searchMatchedCandidates: EventEmitter<string> = new EventEmitter();
  @Output() public mergeDuplicates: EventEmitter<{ primary: string; duplicates: string[] }> =
    new EventEmitter();
  @Output() public openCalendarDialog: EventEmitter<{
    data: CalendarPayload;
    target: CalendarTarget;
    event?: string;
  }> = new EventEmitter();
  @Output() public openNotificationModal: EventEmitter<{
    id: string;
    notificationType: NotificationChannel;
    templateType: MailTemplateTypes;
    shortList: ShortList;
  }> = new EventEmitter();
  @Output() public openLinkedinNotification: EventEmitter<{
    contacts: string[];
    id: number;
  }> = new EventEmitter();
  @Output() public pasteKeywordToSearch: EventEmitter<{
    action: 'add' | 'exclude';
    keyword: string;
  }> = new EventEmitter();
  @Output() public removeFromList: EventEmitter<{ id: number; devId: string }> = new EventEmitter();
  @Output() public resendShortlistForSecurityCheck: EventEmitter<number> = new EventEmitter();
  @Output() public selectAllProfiles: EventEmitter<void> = new EventEmitter();
  @Output() public selectBidStatus: EventEmitter<number> = new EventEmitter();
  @Output() public updatePagination: EventEmitter<PaginationParams> = new EventEmitter();
  @Output() public updateShortList: EventEmitter<{
    previousShortList: Partial<ShortList>;
    shortList: Partial<ShortList>;
  }> = new EventEmitter();
  @Output() public updateSorting: EventEmitter<SortParams> = new EventEmitter();
  public showEmptyStatuses = false;

  public loadingProfilesIds$: Observable<boolean>;
  public portalAllowedToShowInsideInfo$: Observable<boolean>;
  public showTrelloButton$: Observable<boolean>;
  public userIsRecruiterOrAdmin$: Observable<boolean>;
  public selectAllState: SelectionStatesEnum;
  public selectedProfilesIds: string[] = [];

  public hasAccessToContactDetails: boolean;
  public hasAccessToCustomFields: boolean;
  public hasAccessToProfileComments: boolean;
  public hasAccessToSalary: boolean;
  public hasAccessToSecondName: boolean;
  public hasAccessToSocialLinks: boolean;
  public hasAccessToStatusCustomFields: boolean;

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly store$: Store<State>,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly shortListModificationService: ShortListModificationService,
  ) {}

  public ngOnInit(): void {
    this.portalAllowedToShowInsideInfo$ = this.store$.pipe(
      select(getSpaceId),
      switchMap((portalId) => this.store$.pipe(select(portalAllowedToShowInsideInfo(portalId)))),
      shareReplay(),
    );
    this.loadingProfilesIds$ = this.store$.pipe(select(getSelectionLoadingState));
    this.showTrelloButton$ = this.store$.pipe(select(getTrelloButtonState), shareReplay());
    this.userIsRecruiterOrAdmin$ = this.store$.pipe(select(userIsRecruiterOrAdmin), shareReplay());
    this.store$
      .pipe(
        select(getSelectedIds),
        tap((ids) => {
          this.selectedProfilesIds = ids;
        }),
        map((ids) => {
          switch (true) {
            case isEmpty(ids):
              return SelectionStatesEnum.None;
            case ids.length === this.totalCount:
            case ids.length >= 1000:
              return SelectionStatesEnum.All;
            default:
              return SelectionStatesEnum.Partial;
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state) => {
        this.selectAllState = state;
      });
    combineLatest([
      this.store$.pipe(select(isUserOptionAllowed('showCandidateStatusCustomFields'))),
      this.store$.pipe(select(isUserOptionAllowed('showProfileContactDetails'))),
      this.store$.pipe(select(isUserOptionAllowed('showProfileCustomFields'))),
      this.store$.pipe(select(isUserOptionAllowed('viewProfilesComments'))),
      this.store$.pipe(select(isUserOptionAllowed('showSalary'))),
      this.store$.pipe(select(isUserOptionAllowed('showProfileSecondName'))),
      this.store$.pipe(select(isUserOptionAllowed('showProfileSocialLinks'))),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ([
          statusCustomFields,
          contactDetails,
          customFields,
          profileComments,
          salary,
          secondName,
          socialLinks,
        ]) => {
          this.hasAccessToContactDetails = contactDetails;
          this.hasAccessToCustomFields = customFields;
          this.hasAccessToProfileComments = profileComments;
          this.hasAccessToSalary = salary;
          this.hasAccessToSecondName = secondName;
          this.hasAccessToSocialLinks = socialLinks;
          this.hasAccessToStatusCustomFields = statusCustomFields;
        },
      );
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get isMobileDevice() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
  public get isDesktopDevice() {
    return this.breakpointObserver.isMatched('(min-width: 1025px)');
  }
  public get selectAllButtonActive(): boolean {
    return !!this.totalCount && this.totalCount > 0;
  }
  public get allowedToSelectAll(): boolean {
    return this.totalCount <= 1000;
  }
  public isProfileSelected(id: string | number): boolean {
    const profileId = is(Number, id) ? id.toString() : id;
    return includes(profileId, this.selectedProfilesIds);
  }

  public isStatusNotSelectable(code: DictionaryShortListCodes) {
    return includes(code, ['ai', 'applies']);
  }

  public dropElement(event: CdkDragDrop<Profile[]>) {
    const statusId: number = path(
      ['container', 'element', 'nativeElement', 'parentElement', 'attributes', 'value', 'value'],
      event,
    );
    const shortListStatus = find(
      propEq(+statusId, 'id'),
      this.shortListStatuses,
    ) as DictionaryShortList;

    if (
      event.previousContainer === event.container ||
      !event.isPointerOverContainer ||
      pipe(prop('code'), (code: DictionaryShortListCodes) => this.isStatusNotSelectable(code))(
        shortListStatus,
      )
    ) {
      return;
    } else {
      const profile: Profile = event.item.data;
      const profileShortList: ShortList = prop('applies', profile);

      const shortList = { id: profileShortList.id, status: shortListStatus };
      this.shortListModificationService
        .updateShortList({ shortList, profile, vacancyId: this.selectedVacancyId })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: ShortList | boolean) => {
          if (result) {
            this.onUpdateShortList({
              previousShortList: profileShortList,
              shortList: result as ShortList,
            });
          }
        });
    }
  }

  public getStatusProfiles(statusId: number): Profile[] {
    return filter(pathEq(statusId, ['applies', 'status', 'id']))(this.profiles);
  }

  public matchProfileData({
    id,
    isMatch,
    publicProfileId,
  }: {
    id?: string;
    isMatch: boolean;
    publicProfileId?: string;
  }) {
    this.store$.dispatch(
      ProfileModificationActions.MatchProfileDataAction({ isMatch, id, publicProfileId }),
    );
  }

  public onAddToList(shortList: Partial<ShortListPayload>, profile: Profile) {
    this.addToList.emit({ shortList, profile });
  }
  public onEditProfile(profile: Profile, template = TalentFormTabs.INITIAL) {
    this.store$.dispatch(
      RouterActions.OpenEditPopupAction({
        path: ['popup', 'edit', profile.id, 'talent', template],
      }),
    );
  }
  public onOpenCalendarFromShortList(
    {
      payload,
      target,
      event,
    }: {
      payload: Partial<CalendarPayload>;
      target: CalendarTarget;
      event?: string;
    },
    profile: Profile,
  ) {
    const data = pipe(
      path(['contacts', 'emails', 0, 'email']),
      ifElse(isNil, () => ({}), objOf('email')),
      mergeRight({
        profileName: profile.name,
        devId: profile.id,
      }),
      mergeRight(payload),
    )(profile) as CalendarPayload;

    this.openCalendarDialog.emit({ target, data, event });
  }
  public onOpenContact(clickedContact: string, profile: Profile) {
    const profileName = ifElse(
      always(this.hasAccessToSecondName),
      identity,
      pipe(split(' '), head),
    )(profile.name) as string;
    const source =
      this.activeTab === 'profiles'
        ? ProfileContactRequestSource.SEARCH_CONSOLE
        : ProfileContactRequestSource.CANDIDATES;
    this.store$.dispatch(
      ProfilesListActions.GetProfileContactsAction({
        id: profile.id,
        source,
        profileName,
        clickedContact,
      }),
    );
  }

  public onOpenProfilePage(profile: Profile) {
    this.store$.dispatch(RouterActions.NavigateToProfilePageAction({ id: profile.id }));
  }
  public onOpenSidepanel(type: string[], profile: Profile) {
    const action = RouterActions.OpenProfileDetailsSidepanelAction({
      devId: profile.id,
      target: type,
    });

    this.store$.dispatch(action);
  }
  public onPaginationChanged({ pageIndex, pageSize }: PageEvent) {
    this.updatePagination.emit({
      pageSize,
      pageNumber: pageIndex + 1,
    });
    setTimeout(() => {
      const firstCard = window.document.getElementsByClassName('c-profiles-list__card')[0];
      const parent = firstCard?.parentElement?.parentElement?.parentElement;
      parent?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 500);
  }
  public onRemoveFromShortList(id: number, profile: Profile) {
    const devId = profile.id;

    this.removeFromList.emit({ id, devId });
  }
  public onToggleProfileSelection(toggleState: boolean, id: string | number) {
    this.store$.dispatch(
      ProfilesListActions.ToggleProfileSelectionStateAction({ id: id.toString(), toggleState }),
    );
  }
  public onTrelloClick(profile: Profile) {
    const url = `${this.userLocale}/profile/${profile.id}/details`;
    this.store$.dispatch(RouterActions.OpenTrelloAction({ name: profile.name, url }));
  }
  public onUpdateShortList({
    previousShortList,
    shortList,
  }: {
    previousShortList: Partial<ShortList>;
    shortList: Partial<ShortList>;
  }) {
    this.updateShortList.emit({ previousShortList, shortList });
  }
  public toggleSelectAll() {
    const shouldUnselect =
      this.selectAllButtonActive &&
      includes(this.selectAllState, [SelectionStatesEnum.All, SelectionStatesEnum.Partial]);

    if (shouldUnselect) {
      this.store$.dispatch(ProfilesListActions.UnselectAllAction());
    } else if (this.selectAllButtonActive) {
      this.selectAllProfiles.emit();
    }
  }
  public trackById(_index: number, profile: Profile | DictionaryShortList) {
    return profile.id;
  }
}
