import { createAction, props, union } from '@ngrx/store';

import { FullProfile, Skill } from '@app-shared/models';

const BookProfileFromDetailsFailedAction = createAction(
  '[Profile Details Page] Error happened during unbooking profile',
);
const UnbookProfileFromDetailsFailedAction = createAction(
  '[Profile Details Page] Error happened during booking profile',
);
const CleanProfileDetailsAction = createAction(
  '[Profile Cleaner Guard] Clean all related to profile details data',
);
const DeleteHistoryEventSuccessfulAction = createAction(
  '[Profile Details Page] History event successfully deleted',
);
const DeleteHistoryEventFailedAction = createAction(
  '[Profile Details Page] Error happened during deleting history events',
);
const LinkSuggestionFailedAction = createAction(
  '[Profile Details Page] Error happened during linking suggested profiles',
);
const LinkSuggestionSuccessAction = createAction(
  '[Profile Details Page] Suggested profiles were successfully linked',
);
const RefreshProfileDetailsAfterEditingAction = createAction(
  '[Profile Details Page] Refresh profile details data after successfully editing',
);
const RefreshProfileDetailsAfterBookingAction = createAction(
  '[Profile Details Page] Refresh profile details data after successfully booking/unbooking',
);
const RefreshProfileDetailsAfterAddingToLongListAction = createAction(
  '[Profile Details Page] Refresh profile details data after adding to long list public profile',
);
const RequestProfileDetailsFromResolverAction = createAction(
  '[Profile Details Resolver] Request full profile details',
  props<{ id: string }>(),
);
const RequestProfileDetailsFailedAction = createAction(
  '[Profile Details | Profiles API] Error happened during requesting profile details',
);
const RequestProfileDetailsSuccessAction = createAction(
  '[Profile Details | Profiles API] Profile details saved to store',
  props<{ profile: FullProfile }>(),
);
const SetInitialStateAction = createAction(
  '[Profile Details] Set profile details initial state to store',
  props<{ tab: string }>(),
);
const ToggleProfileDetailsLoadingStateAction = createAction(
  '[Profile Details] Toggle loading state for profile details',
  props<{ isProfileLoading: boolean }>(),
);
const UpdateSkillsInDetailsAction = createAction(
  '[Profile Details Effects] Update skills in profile details',
  props<{ data: Skill[] }>(),
);
const UpdateSoftSkillsInDetailsAction = createAction(
  '[Profile Details Effects] Update softSkills in profile details',
  props<{ data: Skill[] }>(),
);

const actions = {
  BookProfileFromDetailsFailedAction,
  UnbookProfileFromDetailsFailedAction,
  CleanProfileDetailsAction,
  DeleteHistoryEventSuccessfulAction,
  DeleteHistoryEventFailedAction,
  LinkSuggestionFailedAction,
  LinkSuggestionSuccessAction,
  RefreshProfileDetailsAfterBookingAction,
  RefreshProfileDetailsAfterEditingAction,
  RefreshProfileDetailsAfterAddingToLongListAction,
  RequestProfileDetailsFromResolverAction,
  RequestProfileDetailsFailedAction,
  RequestProfileDetailsSuccessAction,
  SetInitialStateAction,
  ToggleProfileDetailsLoadingStateAction,
  UpdateSkillsInDetailsAction,
  UpdateSoftSkillsInDetailsAction,
};
const profileDetailsSystemActions = union(actions);

export type ProfileDetailsSystemActionsType = typeof profileDetailsSystemActions;

export const ProfileDetailsSystemActions = actions;
