<div class="c-sources-toggle__wrapper">
  <mat-button-toggle-group
    class="c-sources-toggle"
    [value]="selectedSourceIndex"
    [hideSingleSelectionIndicator]="true"
    [hideMultipleSelectionIndicator]="true"
    (change)="toggle.emit($event.value)"
  >
    @if (showMyData) {
      <mat-button-toggle class="c-sources-toggle__item" [disableRipple]="true" value="my-data">
        My Data
      </mat-button-toggle>
    }
    @for (
      profileSource of profileSources;
      track profileSource.source;
      let i = $index;
      let first = $first;
      let last = $last
    ) {
      <mat-button-toggle [disableRipple]="true" class="c-sources-toggle__item" [value]="i">
        @if (showControls && isProfileDataNotMatched(profileSource.matched)) {
          <div class="c-sources-toggle__controls">
            <button
              mat-icon-button
              [matTooltip]="'shared.sources-toggle.vote-up' | translate"
              matTooltipPosition="above"
              class="c-sources-toggle__controls-item"
              (click)="matchAction($event, true, i)"
            >
              <i class="fas fa-thumbs-up" aria-hidden="true"> </i>
            </button>
            <button
              mat-icon-button
              [matTooltip]="'shared.sources-toggle.vote-down' | translate"
              matTooltipPosition="above"
              class="c-sources-toggle__controls-item"
              (click)="matchAction($event, false, i)"
            >
              <i class="fas fa-thumbs-down" aria-hidden="true"> </i>
            </button>
          </div>
        }
        {{ getProfileSource(profileSource.source) | capitalizeFirstLetter }}
      </mat-button-toggle>
    }
  </mat-button-toggle-group>
</div>
