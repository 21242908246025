import { createReducer, on } from '@ngrx/store';
import { mergeLeft } from 'ramda';
import { KernelActions } from '../actions/kernel.actions';
import { KernelDepartmentStructure } from '../models/kernel.model';

export const kernelFeatureKey = 'kernel';

export interface KernelState {
  isLoading: boolean;
  relatedDepartmentInfo?: { departmentId: string; email: string };
  structure: KernelDepartmentStructure[];
}

export const initialState: KernelState = {
  isLoading: false,
  structure: null,
};

export const reducer = createReducer(
  initialState,

  on(
    KernelActions.RequestKernelOrganizationStructureFromResolverAction,
    KernelActions.SaveDepartmentAssignmentAction,
    KernelActions.SaveDepartmentAssignmentSuccessAction,
    mergeLeft({ isLoading: true }),
  ),
  on(
    KernelActions.RequestKernelOrganizationStructureFailedAction,
    KernelActions.SaveDepartmentAssignmentFailedAction,
    mergeLeft({ isLoading: false }),
  ),
  on(KernelActions.RequestKernelOrganizationStructureSuccessAction, (state, { structure }) =>
    mergeLeft({ isLoading: false, structure }, state),
  ),
  on(KernelActions.RequestRelatedDepartmentInfoSuccessAction, (state, { info }) =>
    mergeLeft({ relatedDepartmentInfo: info }, state),
  ),
);
