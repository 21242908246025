import { Pipe, PipeTransform } from '@angular/core';
import { AllowedOptionType, SimpleRecruiter } from '@app-shared/models';

@Pipe({
  name: 'castToRecruiter',
  standalone: true,
})
export class CastToRecruiterPipe implements PipeTransform {
  transform(value: AllowedOptionType): SimpleRecruiter {
    return value as SimpleRecruiter;
  }
}
