<div class="c-suggested-params">
  @if (multipleSuggestions) {
    <div class="c-suggested-params__suggestion">
      <button
        class="c-suggested-params__suggestion-remove"
        mat-icon-button
        [matTooltip]="'add-new-entity.suggested-params.remove.tooltip' | translate"
        (click)="removeSuggestion.emit()"
      >
        <i
          class="far fa-times-circle c-suggested-params__suggestion-remove-icon"
          aria-hidden="true"
        ></i>
      </button>
      <span class="c-suggested-params__suggestion-label" translate
        >add-new-entity.suggested-params.multiple.label</span
      >
      @for (element of parameter | castToNumericArray; track element) {
        <span
          class="c-suggested-params__suggestion-value c-suggested-params__suggestion-value--tag"
          (click)="selectSuggestedItem.emit(element)"
        >
          {{ element | convertIdToName: dictionary }}
        </span>
      }
    </div>
  } @else {
    <div class="c-suggested-params__suggestion">
      <button
        class="c-suggested-params__suggestion-remove"
        mat-icon-button
        [matTooltip]="'add-new-entity.suggested-params.remove.tooltip' | translate"
        (click)="removeSuggestion.emit()"
      >
        <i
          class="far fa-times-circle c-suggested-params__suggestion-remove-icon"
          aria-hidden="true"
        ></i>
      </button>
      <span class="c-suggested-params__suggestion-label" translate
        >add-new-entity.suggested-params.multiple.label</span
      >
      <button
        class="btn btn-link c-suggested-params__suggestion-value"
        (click)="onSelectSuggestion()"
      >
        {{ parameter | castToNumber | convertIdToName: dictionary }}
      </button>
    </div>
  }
</div>
