import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthPayload,
  AuthRegistration,
  AuthResponse,
  AuthTokenValidityState,
  InvitationTokenStatus,
  Locales,
  PasswordRestorePayload,
  SocialAuthTypes,
  SubscriptionPackagesEnum,
} from '@app-shared/models';
import { anyPass, isEmpty, isNil, mergeRight, objOf, pipe, when } from 'ramda';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly http: HttpClient) {}

  public authorizeUser(data: AuthPayload): Observable<AuthResponse> {
    return this.http.post<AuthResponse>('/login', data, {
      headers: {
        'I-Auth-Request': 'true',
      },
    });
  }
  public registrationUser(data: AuthRegistration): Observable<AuthResponse> {
    return this.http.post<AuthResponse>('/registration', data, {
      headers: {
        'I-Auth-Request': 'true',
      },
    });
  }
  public refreshToken(refreshToken: string): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(
      '/refresh-token',
      { refresh_token: refreshToken },
      {
        headers: {
          'I-Auth-Request': 'true',
        },
      },
    );
  }
  public resetPassword(data: { email: string }): Observable<Record<string, unknown>> {
    return this.http.post<Record<string, unknown>>('/reset-password', data, {
      headers: { 'I-Auth-Request': 'true' },
    });
  }
  public passwordRestore(data: PasswordRestorePayload): Observable<PasswordRestorePayload> {
    return this.http.post<PasswordRestorePayload>('/restore-password', data, {
      headers: { 'I-Auth-Request': 'true' },
    });
  }
  public loginWithSocialToken(
    type: SocialAuthTypes,
    token: string,
    subscriptionPlan: SubscriptionPackagesEnum,
    locale: Locales,
  ): Observable<AuthResponse> {
    const payload = pipe(
      when(anyPass([isNil, isEmpty]), () => SubscriptionPackagesEnum.team),
      objOf('subscriptionPlan'),
      mergeRight({ token, role: 'portal_admin', locale }),
    )(subscriptionPlan);

    return this.http.post<AuthResponse>(`/login/${type}`, payload, {
      headers: { 'I-Auth-Request': 'true' },
    });
  }

  public checkInvitationToken(
    token: string,
    email: string,
  ): Observable<{ status: InvitationTokenStatus }> {
    return this.http.post<{ status: InvitationTokenStatus }>(
      '/check-invitation-token',
      { token, email },
      { headers: { 'I-Auth-Request': 'true' } },
    );
  }

  public validateUserTokens(data: {
    token: string;
    refreshToken: string;
  }): Observable<{ state: AuthTokenValidityState }> {
    return this.http.post<{ state: AuthTokenValidityState }>('/validate-tokens', data, {
      headers: { 'I-Auth-Request': 'true' },
    });
  }
}
