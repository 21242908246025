import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEditableItem, DirectorySkill } from '@app-shared/models';

@Pipe({
  name: 'castToSkill',
  standalone: true,
})
export class CastToSkillPipe implements PipeTransform {
  public transform(value: DictionaryEditableItem): DirectorySkill {
    return value as DirectorySkill;
  }
}
