export { vacanciesAddressListResolver } from './address-list/address-list.resolve';
export { appFeaturesResolver } from './app-features/app-features.resolve';
export { appVersionResolver } from './app-version/app-version.resolve';
export { authTokenResolver } from './auth-token/auth-token.resolve';
export { candidateStatusesCustomFieldsResolver } from './candidate-statuses-custom-fields/candidate-statuses-custom-fields.resolve';
export { cleanAuthResolver } from './clean-auth/clean-auth.resolve';
export { datadogMonitorResolver } from './datadog-monitor/datadog-monitor.resolve';
export { departmentsResolver } from './departments/departments.resolve';
export { dictionariesResolver } from './dictionaries/dictionaries.resolve';
export { formConfigsResolver } from './form-configs/form-configs.resolve';
export { genericResolver } from './generic-resolver/generic-resolver.resolver';
export { genericSimpleCleanerGuard } from './generic-resolver/generic-simple-cleaner.guard';
export { genericSimpleResolver } from './generic-resolver/generic-simple-resolver.resolver';
export { metadataResolver } from './metadata/metadata.resolve';
export { recruitersResolver } from './recruiters/recruiters.resolve';
export { rejectReasonsResolver } from './reject-reasons/reject-reasons.resolve';
export { shortListSourcesResolver } from './short-list-sources/short-list-sources.resolve';
export { shortListStatusesResolver } from './short-list-statuses/short-list-statuses.resolve';
export { vacanciesSimpleListResolver } from './vacancies-simple-list/vacancies-simple-list.resolve';
