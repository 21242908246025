import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { mockedProfileSuggestions } from '@app-shared/mocks';
import {
  AllDictionaries,
  BinaryOperator,
  CalendarPayload,
  CalendarTarget,
  Contact,
  DictionaryItem,
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  ElasticPublicEducation,
  Entity,
  FileType,
  Locales,
  MailTemplateTypes,
  NotificationChannel,
  Profile,
  ProfileExternalDataSources,
  ProfileProject,
  ProfileSuggestion,
  ProfilesSources,
  PublicSourceData,
  ShortList,
  ShortListPayload,
  SimpleProject,
  Skill,
  TalentFormTabs,
  UnaryOperator,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { PreviewCustomFieldsComponent } from '@tsp-components/preview-custom-fields';
import { ProfileAvailableContactsComponent } from '@tsp-components/profile-available-contacts';
import { ProfileFilesDialogComponent } from '@tsp-components/profile-files-dialog';
import { ProfilePersonalInfoComponent } from '@tsp-components/profile-personal-info';
import {
  ProfileShortListComponent,
  ShortListInfoComponent,
} from '@tsp-components/profile-short-list';
import { ProfileSkillsComponent } from '@tsp-components/profile-skills';
import { ProfileTagsComponent } from '@tsp-components/profile-tags';
import { ProfileCandidatesComponent } from '@tsp-components/public-profile-candidates';
import { TrelloButtonComponent } from '@tsp-components/trello-button';
import { TextSelectDirective } from '@tsp-directives';
import { CastToProfilePipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import {
  anyPass,
  concat,
  converge,
  equals,
  filter,
  findIndex,
  flatten,
  isEmpty,
  isNil,
  length,
  map,
  mergeRight,
  not,
  objOf,
  omit,
  path,
  pathOr,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
  values,
  when,
} from 'ramda';
import { ProfileCountsPanelComponent } from '../profile-counts-panel/profile-counts-panel.component';
import { ProfileExtendedProfessionalInfoComponent } from '../profile-extended-professional-info/profile-extended-professional-info.component';
import { ProfileLastCommentComponent } from '../profile-last-comment/profile-last-comment.component';
import { ProfilePreviewControlsComponent } from '../profile-preview-controls/profile-preview-controls.component';
import { ProfileWorkHistoryComponent } from '../profile-work-history/profile-work-history.component';
import { SourcesToggleComponent } from '../sources-toggle/sources-toggle.component';

@Component({
  imports: [
    CastToProfilePipe,
    MatButton,
    MatIconButton,
    MatTooltip,
    NgClass,
    PreviewCustomFieldsComponent,
    ProfileAvailableContactsComponent,
    ProfileCandidatesComponent,
    ProfileCountsPanelComponent,
    ProfileExtendedProfessionalInfoComponent,
    ProfileLastCommentComponent,
    ProfilePersonalInfoComponent,
    ProfilePreviewControlsComponent,
    ProfileShortListComponent,
    ProfileSkillsComponent,
    ProfileTagsComponent,
    ProfileWorkHistoryComponent,
    RouterLink,
    ShortListInfoComponent,
    SourcesToggleComponent,
    TextSelectDirective,
    TranslatePipe,
    TranslateDirective,
    TrelloButtonComponent,
  ],
  selector: 'profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition('* <=> *', [
        style({
          opacity: 0.1,
        }),
        animate(
          '500ms',
          style({
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
  standalone: true,
})
export class ProfilePreviewComponent {
  @Input()
  public activeTab: string;
  @Input()
  public hasAccessToContacts: boolean;
  @Input()
  public hasAccessToCustomFields: boolean;
  @Input()
  public hasAccessToSalary: boolean;
  @Input()
  public hasAccessToStatusCustomFields: boolean;
  @Input()
  public hasAccessToProfileComments: boolean;
  @Input()
  public hasAccessToSecondName: boolean;
  @Input()
  public isActiveSearchParams: boolean;
  @Input()
  public statusesCustomFields: DirectoryFieldType[];
  @Input()
  public dictionaries: Partial<AllDictionaries>;
  @Input()
  public portalAllowedToShowInsideInfo: boolean;
  @Input()
  public set profileData(data: Profile) {
    this.profile = data;
    if (this.shouldUseMyDataInfo) {
      this.changeProfileDataSource('my-data');
      return;
    }
    const index = findIndex(propEq('linkedin', 'source'), this.externalDataSources);
    this.changeProfileDataSource(index >= 0 ? index : 0);
  }
  @Input()
  public profileSuggestions: ProfileSuggestion[] = mockedProfileSuggestions;
  @Input()
  public canBeSelected = true;
  @Input()
  public isMobileDevice = false;
  @Input()
  public showApplies = false;
  @Input()
  public vacancyId: number | null;
  @Input()
  public isSidepanel: boolean;
  @Input()
  public activeSkillsFilters: number[] = [];
  @Input()
  public isProfileLoading = false;
  @Input()
  public isProfileSelected = false;
  @Input()
  public isExtendedView = false;
  @Input()
  public searchParamsForHighlight: { [key: string]: string[] };
  @Input()
  public showPublicProfileControls = false;
  @Input()
  public showMoreButtonForWorkHistory = true;
  @Input()
  public showTrelloButton: boolean;
  @Input()
  public simpleCardViewState = false;
  @Input()
  public userLocale: Locales;
  @Input()
  public userIsRecruiterOrAdmin: boolean;
  @Output()
  public addToCompany = new EventEmitter<{ vacancyId: number | null; profile: Profile }>();
  @Output()
  public addToList = new EventEmitter<Partial<ShortListPayload>>();
  @Output()
  public addToListById = new EventEmitter<string>();
  @Output()
  public editProfile = new EventEmitter<TalentFormTabs>();
  @Output()
  public matchProfileData = new EventEmitter<{
    isMatch: boolean;
    id?: string;
    publicProfileId?: string;
  }>();
  @Output()
  public linkSuggestion = new EventEmitter<string[]>();
  @Output()
  public searchMatchedCandidates = new EventEmitter<string>();
  @Output()
  public openContacts = new EventEmitter<string>();
  @Output()
  public onOpenNotificationModal = new EventEmitter<{
    id: string;
    notificationType: NotificationChannel;
    templateType: MailTemplateTypes;
    shortList: ShortList;
  }>();
  @Output()
  public openCalendar = new EventEmitter<{
    payload: Partial<CalendarPayload>;
    target: CalendarTarget;
    event?: string;
  }>();
  @Output()
  public openProfile = new EventEmitter<void>();
  @Output()
  public openSidepanel = new EventEmitter<string[]>();
  @Output()
  public pasteKeywordToSearch = new EventEmitter<{
    action: 'add' | 'exclude';
    keyword: string;
  }>();
  @Output()
  public removeFromShortList = new EventEmitter<number>();
  @Output()
  public resendShortlistForSecurityCheck = new EventEmitter<number>();
  @Output()
  public showWarning = new EventEmitter<string>();
  @Output()
  public toggleSelection = new EventEmitter<boolean>();
  @Output()
  public trelloClick = new EventEmitter<void>();
  @Output()
  public updateShortList = new EventEmitter<{
    previousShortList: Partial<ShortList>;
    shortList: Partial<ShortList>;
  }>();
  @Output()
  public mergeDuplicates = new EventEmitter<string[]>();
  public profile: Profile;
  public selectedProfileDataIndex: number;
  public selectedProfileSourceData: PublicSourceData;
  public showInsideInfo = false;
  public showDetailedInfo = false;

  constructor(private readonly matDialog: MatDialog) {}

  public get applies(): ShortList | null {
    return prop('applies', this.profile);
  }
  public get allowedToShowInsideInfo(): boolean {
    return this.portalAllowedToShowInsideInfo && !!this.insideInfo;
  }
  public get availableContacts(): string[] {
    return converge(concat as BinaryOperator<string[], string[], string[]>, [
      propOr([], 'availableContacts'),
      propOr([], 'externalContacts'),
    ])(this.profile);
  }
  public get commentsLength(): number | null {
    return (
      prop('commentsCount', this.profile) ||
      pipe(propOr([], 'comments'), length as UnaryOperator<Comment[], number>)(this.profile)
    );
  }
  public get duplicatesIds(): string[] {
    return this.profile.duplicates.map(prop('id'));
  }
  public get externalDataSources(): { source: ProfileExternalDataSources; matched: boolean }[] {
    return pipe(
      propOr([], 'externalData'),
      map(pick(['source', 'matched'])) as UnaryOperator<
        PublicSourceData[],
        { source: ProfileExternalDataSources; matched: boolean }[]
      >,
    )(this.profile);
  }
  public get hasDuplicates(): boolean {
    return this.profile.duplicatesCount > 0;
  }
  public get isExternalSearchPage(): boolean {
    return this.activeTab === ProfilesSources.EXTERNAL.toString();
  }
  public get insideInfo(): string {
    return this.profile.insideInformation;
  }
  public get profileCustomFields(): DirectoryCustomFieldResponse[] {
    return prop('fieldValues', this.profile);
  }
  public get publicProfileCandidates(): Profile[] {
    return prop('candidatesData', this.profile);
  }
  public get publicProfileCandidateIsApplied(): boolean {
    return length(this.publicProfileCandidates) === 1 && !!this.publicProfileCandidates[0].applies;
  }
  public get showPublicProfileCandidates(): boolean {
    return length(this.publicProfileCandidates) > 0 && this.activeTab === 'profiles';
  }
  public get statusCustomFields(): DirectoryCustomFieldResponse[] {
    return pathOr([], ['applies', 'fieldValues'], this.profile) as DirectoryCustomFieldResponse[];
  }
  public get hasWorkHistory(): boolean {
    return pipe(
      pick(['projects', 'externalProjects']) as UnaryOperator<
        Profile,
        { externalProjects: ProfileProject[]; projects: SimpleProject[] }
      >,
      values,
      flatten,
      reject(anyPass([isNil, isEmpty])),
      isEmpty,
      not,
    )(this.selectedProfileData);
  }
  public get hasEducation(): boolean {
    return !anyPass([isEmpty, isNil])(this.allProfileEducation);
  }
  public get isInternalProfile(): boolean {
    return propOr(false, 'isPrivate', this.profile);
  }
  public get isReviewed(): boolean {
    return pipe(prop('viewedByRecruiter'), isNil, not)(this.profile);
  }
  public get lastComment(): string | null {
    return (pathOr(null, ['lastComment', 'comment'], this.profile) ||
      pathOr(null, ['comments', 0, 'comment'], this.profile)) as string | null;
  }
  public get lastReviewDate(): string {
    return path(['viewedByRecruiter', 'date'], this.profile);
  }
  public get profileEmails(): Contact[] {
    return pathOr([], ['contacts', 'emails'], this.profile) as Contact[];
  }
  public get profileLinkedIns(): Contact[] {
    return pathOr([], ['contacts', 'linkedin'], this.profile) as Contact[];
  }
  public get profileSkypes(): Contact[] {
    return pathOr([], ['contacts', 'skypes'], this.profile) as Contact[];
  }
  public get profileHasEmail(): boolean {
    return pipe(filter(equals('email')), isEmpty, not)(this.availableContacts);
  }
  public get showComment(): boolean {
    return this.hasAccessToProfileComments && this.isInternalProfile;
  }
  public get isProfilesSearchPage() {
    return this.activeTab !== 'vacancy';
  }
  public get profileProjects(): SimpleProject[] | null {
    return prop('projects', this.selectedProfileData);
  }
  public get profileSkills(): Skill[] {
    return propOr([], 'skills', this.profile);
  }
  public get profileSuggestedSkills(): number {
    return pipe(
      propOr([], 'suggestedSkills') as UnaryOperator<Profile, Entity[]>,
      length,
    )(this.profile);
  }
  public get profileTags(): DictionaryItem[] {
    return propOr([], 'tags', this.selectedProfileData);
  }
  public get profileExternalProjects(): ProfileProject[] | null {
    return prop('externalProjects', this.profile);
  }
  public get allProfileProjects(): SimpleProject[] {
    return concat(this.profileProjects, (this.profileExternalProjects as SimpleProject[]) ?? []);
  }
  public get allProfileEducation(): ElasticPublicEducation[] {
    return this.selectedProfileSourceData.education;
  }
  public get renderShowDetailsButton(): boolean {
    return this.profileSkills.length > 5 || this.profileTags.length > 4;
  }
  public get selectedProfileData(): Profile {
    return mergeRight(this.profile, {
      ...omit(['id', 'skills'], this.selectedProfileSourceData),
      source: this.profile.source,
    }) as Profile;
  }
  public get showApplyShortInfo(): boolean {
    return this.applies
      ? pipe(
          pick(['createdAt', 'author', 'source', 'salary', 'comment', 'contacts', 'rejectReason']),
          reject(anyPass([isNil, isEmpty])),
          isEmpty,
          not,
        )(this.applies)
      : false;
  }
  public get isContactButtonDisabled(): boolean {
    return !this.profileHasEmail || !this.vacancyId;
  }
  public get isProfileDataNotMatched(): boolean {
    return pipe(propOr(null, 'matched'), isNil)(this.profile);
  }
  public get contactButtonTooltip(): string {
    const key = 'profile-preview.profile-preview.';
    switch (true) {
      case !this.vacancyId:
        return `${key}select-vacancy`;
      case !this.profileHasEmail:
        return `${key}profile-unavailable`;
      default:
        return `${key}contact`;
    }
  }
  public get shouldUseMyDataInfo(): boolean {
    return this.profile.isPrivate;
  }
  public changeProfileDataSource(index: number | 'my-data') {
    if (index === 'my-data') {
      this.selectedProfileSourceData = this.profile as unknown as PublicSourceData;
    }
    this.selectedProfileDataIndex = index as number;
    this.selectedProfileSourceData = path(['externalData', index], this.profile);
  }
  public getDuplicateStatus(profile: Profile): 'primary' | 'duplicate' {
    return DateTime.fromISO(profile.createdAt).diff(DateTime.fromISO(this.profile.createdAt))
      .milliseconds > 0
      ? 'duplicate'
      : 'primary';
  }
  public getToggleTooltip(state: boolean): string {
    return `profile-preview.profile-preview.skills.${state ? 'less.tooltip' : 'more.tooltip'}`;
  }
  public onLinkSuggestion(id: string) {
    this.linkSuggestion.emit([id, this.profile.id]);
  }
  public onMatchProfileData({ isMatch, index }: { isMatch: boolean; index: number }) {
    const publicProfileId = pipe(path(['externalData', index, 'id']))(this.profile) as string;
    this.matchProfileData.emit({ isMatch, publicProfileId, id: this.profile.id });
  }
  public openFilesModal(type: FileType) {
    const data = {
      dialogType: `${type}s`,
      profile: this.profile,
    };
    const cvDialogData = {
      panelClass: ['c-dialog', 'c-dialog--cv-dialog'],
      width: 'calc(100vw - 100px)',
      height: 'calc(100vh - 100px)',
      maxWidth: '100%',
    };
    const dialogData = pipe(
      objOf('data'),
      when(() => type === 'cv', mergeRight(cvDialogData)),
    )(data);

    this.matDialog.open(ProfileFilesDialogComponent, dialogData);
  }
  public onSearchMatchedCandidates() {
    this.searchMatchedCandidates.emit(this.profile.id);
  }
  public onShowDetailedInfo() {
    this.showDetailedInfo = !this.showDetailedInfo;
  }
  public openNotificationModal({
    type,
    shortList,
  }: {
    type: MailTemplateTypes;
    shortList: ShortList;
  }) {
    const id = this.profile.id;

    this.onOpenNotificationModal.emit({
      id,
      notificationType: 'email',
      templateType: type,
      shortList,
    });
  }
}
