import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RootUserActions } from '@app-core/root/actions/root.user.actions';
import { ShortListSourcePayload } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { Store } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';

interface SourceForm {
  code: FormControl<string>;
  name: FormControl<string>;
}

@Component({
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    NgClass,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'short-list-add-new-source',
  templateUrl: './add-new-source.component.html',
  styleUrls: ['./add-new-source.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AddNewSourceComponent implements OnInit {
  public newSourceForm: FormGroup<SourceForm>;
  @Output()
  public onClose = new EventEmitter<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store$: Store<State>,
  ) {}

  public ngOnInit(): void {
    this.newSourceForm = this.formBuilder.group<SourceForm>({
      code: new FormControl<string>(null, Validators.required),
      name: new FormControl<string>(null, Validators.required),
    });

    this.newSourceForm.get('name').valueChanges.subscribe((val: string) => {
      const code = this.newSourceForm.get('code');
      if (!code.pristine) {
        return;
      }
      const value = (val || '').toLowerCase().replace(/\s/g, '_');
      code.patchValue(value);
    });
  }

  public createSource() {
    const formValue = this.newSourceForm.value as ShortListSourcePayload;
    this.store$.dispatch(RootUserActions.AddNewShortListSourceAction(formValue));
    this.close();
  }

  public close() {
    this.onClose.emit();
    this.newSourceForm.reset();
  }
}
