import { createAction, props, union } from '@ngrx/store';

const AddCommentAction = createAction(
  '[Vacancy Profiles Sidepanel] Add the comment for the vacancy',
  props<{ comment: string; id: number }>(),
);
const DeleteCommentAction = createAction(
  '[Vacancy Profiles Sidepanel] Try to delete the comment from the vacancy',
  props<{ id: number }>(),
);
const ClosePanelAction = createAction('[Vacancy Profiles Sidepanel] Close and clean side panel');
const ChangeTabAction = createAction(
  '[Vacancy Profiles Sidepanel] Change tab of side panel',
  props<{ tab: string; id: number }>(),
);

const actions = {
  AddCommentAction,
  DeleteCommentAction,
  ClosePanelAction,
  ChangeTabAction,
};

const vacancySidepanelUserActions = union(actions);

export type VacancySidepanelUserActionsType = typeof vacancySidepanelUserActions;

export const VacancySidepanelUserActions = actions;
