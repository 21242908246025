<form class="c-tsp-mat-form"
    [formGroup]="form"
    novalidate>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always">
      <mat-label translate>profile-short-list.short-list-edit-dialog.interview.label</mat-label>
      <input class="c-tsp-mat-form-field-input"
          matInput
          formControlName="interviewAt"
          readonly="true"
          [owlDateTimeTrigger]="dateTime"
          [owlDateTime]="dateTime"
          [placeholder]="'profile-short-list.short-list-dialog.interview-at' | translate">
      <owl-date-time [startAt]="interviewStartTime"
          [stepMinute]="5"
          [firstDayOfWeek]="1"
          #dateTime>
      </owl-date-time>
    </mat-form-field>
  </div>
</form>
