import { createAction, props, union } from '@ngrx/store';
import { DirectoryFieldType } from '@app-shared/models';

const GetClientCustomFieldsCompleteAction = createAction(
  '[Custom Fields] Client custom fields saved to store',
  props<{ clientCustomFields: DirectoryFieldType[] }>(),
);
const GetClientCustomFieldsFailedAction = createAction(
  '[Custom Fields] Request for client custom fields failed',
);
const GetProfileCustomFieldsCompleteAction = createAction(
  '[Custom Fields] Profile custom fields saved to store',
  props<{ profileCustomFields: DirectoryFieldType[] }>(),
);
const GetProfileCustomFieldsFailedAction = createAction(
  '[Custom Fields] Request for profile custom fields failed',
);
const GetProjectCustomFieldsCompleteAction = createAction(
  '[Custom Fields] Vacancy custom fields saved to store',
  props<{ projectCustomFields: DirectoryFieldType[] }>(),
);
const GetProjectCustomFieldsFailedAction = createAction(
  '[Custom Fields] Request for vacancy custom fields failed',
);
const GetStatusesCustomFieldsFailedAction = createAction(
  '[Custom Fields] Request for candidate statuses custom fields failed',
);
const GetStatusesCustomFieldsCompleteAction = createAction(
  '[Custom Fields] Candidate statuses custom fields successfully received',
  props<{ statusesCustomFields: DirectoryFieldType[] }>(),
);
const GetRecruiterCustomFieldsFailedAction = createAction(
  '[Custom Fields] Request for recruiter custom fields failed',
);
const GetRecruiterCustomFieldsCompleteAction = createAction(
  '[Custom Fields] Recruiter custom fields successfully received',
  props<{ recruiterCustomFields: DirectoryFieldType[] }>(),
);

const actions = {
  GetClientCustomFieldsCompleteAction,
  GetClientCustomFieldsFailedAction,
  GetProfileCustomFieldsCompleteAction,
  GetProfileCustomFieldsFailedAction,
  GetProjectCustomFieldsCompleteAction,
  GetProjectCustomFieldsFailedAction,
  GetStatusesCustomFieldsCompleteAction,
  GetStatusesCustomFieldsFailedAction,
  GetRecruiterCustomFieldsFailedAction,
  GetRecruiterCustomFieldsCompleteAction,
};

const customFieldsSystemActions = union(actions);

export type CustomFieldsSystemActionsType = typeof customFieldsSystemActions;

export const CustomFieldsSystemActions = actions;
