import { Pipe, PipeTransform } from '@angular/core';
import { find, isNil, none, pipe, prop, propEq } from 'ramda';

import { DictionaryItem, DictionarySkill, UnaryOperator } from '@app-shared/models';

@Pipe({
  name: 'convertIdToName',
  standalone: true,
})
export class ConvertIdToNamePipe implements PipeTransform {
  public transform(value: number, dictionary?: DictionaryItem[] | DictionarySkill[]): string {
    if (none(isNil)([value, dictionary])) {
      return pipe(
        find(propEq(value, 'id')) as UnaryOperator<DictionaryItem[], DictionaryItem>,
        prop('name'),
      )(dictionary);
    }
    return '';
  }
}
