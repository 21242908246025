import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { SortOptions, SortParams, UnaryOperator } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { anyPass, find, isEmpty, isNil, none, propEq } from 'ramda';

@Component({
  imports: [
    MatButtonToggle,
    MatButtonToggleGroup,
    MatFormField,
    MatOption,
    MatSelect,
    NgClass,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-profiles-list-control-bar',
  templateUrl: './profiles-list-control-bar.component.html',
  styleUrls: ['./profiles-list-control-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfilesListControlBarComponent implements OnInit, OnChanges {
  @Input()
  public sortingEnabled: boolean;
  @Input()
  public sliderEnabled: boolean;
  @Input()
  public sortOptions: SortOptions[];
  @Input()
  public sortParam: SortParams;
  @Input()
  public simpleCardViewState: boolean;
  @Output()
  public onSortingChanged = new EventEmitter<SortParams>();
  @Output()
  public onChangeSimpleCardViewState = new EventEmitter<boolean>();

  public sortingControl: FormControl<SortParams>;

  public ngOnInit(): void {
    this.sortingControl = new FormControl<SortParams>(null);
    this.updateSortControl();
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (this.sortingControl && (changes.sortParam || changes.sortOptions)) {
      this.updateSortControl();
    }
  }

  public changeSorting() {
    const value: SortParams = this.sortingControl.value;
    this.onSortingChanged.emit(value);
  }
  public changeSlider() {
    this.onChangeSimpleCardViewState.emit(!this.simpleCardViewState);
  }
  private updateSortControl() {
    if (none(anyPass([isNil, isEmpty]))([this.sortParam, this.sortOptions])) {
      const selectedOption = find(
        propEq(this.sortParam, 'value') as UnaryOperator<SortOptions, boolean>,
        this.sortOptions,
      );
      if (isNil(selectedOption)) {
        return;
      }
      this.sortingControl.setValue(selectedOption.value);
    } else {
      this.sortingControl.reset();
    }
  }
}
