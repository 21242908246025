import { createAction, props, union } from '@ngrx/store';

import {
  ClientPayload,
  DictionariesEnum,
  DictionaryDepartment,
  DictionaryItem,
  DirectoryFieldType,
  ProfileRequest,
  RecruiterPayload,
  TalentFormTabs,
  VacancyCreatePayload,
  VacancyEditPayload,
  VacancySuggestedParams,
} from '@app-shared/models';

const AnalyzeTitleAndDescription = createAction(
  '[Create New Vacancy Popup] Check vacancy title and description for dictionaries',
  props<{ title: string; description?: string }>(),
);
const CheckProfileExistenceAction = createAction(
  '[Create New Talent Popup] Check, if profile already exist in database',
  props<{ devId?: string; keyword: string; source: string }>(),
);
const ChangeEditTalentDialogTabAction = createAction(
  '[Create New Talent Popup] Change edit talent dialog template',
  props<{ template: TalentFormTabs; devId?: string; isEditing: boolean }>(),
);
const CreateNewCityAction = createAction(
  '[Create New City Popup] Try to save new city',
  props<{ googlePlaceId: string; name: string }>(),
);
const CreateNewClientAction = createAction(
  '[Create New Client Popup] Try to save new client',
  props<{ client: Omit<ClientPayload, 'id'> }>(),
);
const CreateNewDirectoryItemAction = createAction(
  '[Create New Directory] Try to save new directory item or custom field',
  props<{
    data: Partial<DirectoryFieldType> | Partial<DictionaryItem> | Partial<DictionaryDepartment>;
    name: DictionariesEnum;
  }>(),
);
const CreateNewTalentAction = createAction(
  '[Create New Talent Popup] Try to save new talent',
  props<{
    talent: Partial<ProfileRequest>;
    navigateToCreated: boolean;
    vacancy?: DictionaryItem;
    status?: number;
    cvMimetype: string | null;
    textContent: string | null;
    urlToCv: string | null;
  }>(),
);
const CreateNewRecruiterAction = createAction(
  '[Create New Recruiter Popup] Try to save new recruiter',
  props<{ recruiter: Partial<RecruiterPayload> }>(),
);
const CreateNewVacancyAction = createAction(
  '[Create New Vacancy Popup] Try to save new vacancy',
  props<{ data: VacancyCreatePayload; goToSearch?: boolean }>(),
);
const DeleteClientAction = createAction(
  '[Edit Client Popup] Try to delete client',
  props<{ id: number }>(),
);
const DeleteDirectoryItemAction = createAction(
  '[Edit Directory Popup] Try to delete directory or custom field',
  props<{ id: number | string; name: DictionariesEnum }>(),
);
const DeleteProfileFromPopupAction = createAction(
  '[Edit Talent Popup] Try to delete profile from popup',
  props<{ id: string }>(),
);
const DeleteVacancyFromPopupAction = createAction(
  '[Edit Vacancy Popup] Try to delete vacancy',
  props<{ id: number }>(),
);
const GetShortListBoardByIdAction = createAction(
  '[Create New Vacancy Popup] Request short list board by id',
  props<{ id: number }>(),
);
const SelectSuggestedSkillAction = createAction(
  '[Create New Profile Popup] Modify suggested property',
  props<{ id: number }>(),
);
const SelectSuggestedSpecialityAction = createAction(
  '[Create New Profile Popup] Modify suggested property',
  props<{ id: number }>(),
);
const ModifyVacancySuggestionAction = createAction(
  '[Create New Vacancy Popup] Modify suggested property',
  props<{ name: keyof VacancySuggestedParams; value: number[] | null }>(),
);
const UpdateTalentAction = createAction(
  '[Edit Talent Popup] Try to save modified talent',
  props<{ profile: Partial<ProfileRequest> }>(),
);
const UpdateVacancyAction = createAction(
  '[Edit Vacancy Popup] Try to save modified vacancy',
  props<{ vacancy: VacancyEditPayload }>(),
);
const UpdateClientAction = createAction(
  '[Edit Client Popup] Try to save modified client',
  props<{ client: ClientPayload }>(),
);
const UpdateDirectoryItemAction = createAction(
  '[Edit Directory Popup] Try to save modified directory item or custom field',
  props<{
    name: DictionariesEnum;
    data: Partial<DictionaryItem> | DirectoryFieldType | DictionaryDepartment;
  }>(),
);
const UpdateRecruiterAction = createAction(
  '[Edit Recruiter Popup] Try to save modified recruiter',
  props<{ recruiter: Partial<RecruiterPayload> }>(),
);
const UploadCvFromPopupAction = createAction(
  '[Create New Talent Popup] Upload new CV',
  props<{ file: File }>(),
);

const actions = {
  AnalyzeTitleAndDescription,
  ChangeEditTalentDialogTabAction,
  CheckProfileExistenceAction,
  CreateNewCityAction,
  CreateNewClientAction,
  CreateNewDirectoryItemAction,
  CreateNewRecruiterAction,
  CreateNewTalentAction,
  CreateNewVacancyAction,
  DeleteClientAction,
  DeleteDirectoryItemAction,
  DeleteProfileFromPopupAction,
  DeleteVacancyFromPopupAction,
  GetShortListBoardByIdAction,
  ModifyVacancySuggestionAction,
  SelectSuggestedSkillAction,
  SelectSuggestedSpecialityAction,
  UpdateClientAction,
  UpdateDirectoryItemAction,
  UpdateRecruiterAction,
  UpdateTalentAction,
  UpdateVacancyAction,
  UploadCvFromPopupAction,
};

const addNewEntityUserActions = union(actions);

export type AddNewEntityUserActionsType = typeof addNewEntityUserActions;

export const AddNewEntityUserActions = actions;
