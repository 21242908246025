import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { RootUserActions } from '@app-core/root/actions/root.user.actions';
import {
  ChangeSimpleCardViewStateFromSearchConsoleComplete,
  ChangeSimpleCardViewStateFromSearchConsoleFailed,
} from '@app-core/search-console/actions/search-console.system.actions';
import { getSource } from '@app-core/search-console/reducers/search-console.reducer';
import { VacanciesListSystemActions } from '@app-core/vacancies-list/actions/vacancies-list.system.actions';
import { VacanciesListUserActions } from '@app-core/vacancies-list/actions/vacancies-list.user.actions';
import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';
import { VacancyProfilesUserActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.user.actions';
import { environment } from '@app-environment/environment';
import { splitName } from '@app-shared/functions/utilities/utilities';
import {
  CurrentUser,
  Locale,
  Locales,
  Metadata,
  PackageAccess,
  Profile,
  ProfileContacts,
  ProfilesSources,
  SpaceEntity,
  SubscriptionPackagesEnum,
  TutorialProgress,
  UnaryOperator,
  UserAdditionalInfo,
  UserAllowedSearchPages,
  UserOptions,
} from '@app-shared/models';
import { getActiveModule } from '@app-shared/reducers/router/router.reducer';
import { GetMetadataSuccessAction } from '@app-shared/resolvers/metadata/metadata-resolve.actions';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  VacancySelectorSystemActions,
  VacancySelectorUserActions,
} from '@tsp-components/vacancy-selector';
import { DateTime } from 'luxon';
import {
  find,
  identity,
  intersection,
  isEmpty,
  lensPath,
  memoizeWith,
  mergeLeft,
  mergeRight,
  not,
  omit,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  set,
} from 'ramda';
import { managerUserRoles, userPackageAccesses } from './root.config';

export interface RootState {
  additionalInfo?: UserAdditionalInfo;
  availableOptions?: UserOptions;
  availablePages?: UserAllowedSearchPages;
  chromePluginVersion?: string;
  data: Omit<
    CurrentUser,
    'additionalInfo' | 'availableOptions' | 'availablePages' | 'mailHeader' | 'mailFooter' | 'roles'
  >;
  isLoading: boolean;
  locales?: Locale[];
  metadata: Metadata | null;
  newEntityData?: { profile: Profile; contacts: ProfileContacts; vacancyId: number | null };
  newVacanciesCount?: number;
  spaces?: SpaceEntity[];
  pushNotificationToken: string | null;
  tutorialProgress?: TutorialProgress;
  userRoles?: string[];
}

export const initialState: RootState = {
  data: null,
  isLoading: false,
  metadata: null,
  pushNotificationToken: null,
};

const reducer = createReducer(
  initialState,
  on(RootSystemActions.ChangeLocalizationSuccessAction, (state, { locale }) =>
    pipe(
      set(lensPath(['data', 'locale']), locale),
      mergeLeft({ isLoading: false } as Partial<RootState>) as UnaryOperator<
        Partial<RootState>,
        RootState
      >,
    )(state),
  ),
  on(
    ChangeSimpleCardViewStateFromSearchConsoleComplete,
    ChangeSimpleCardViewStateFromSearchConsoleFailed,
    (state, { viewState }) => set(lensPath(['data', 'simpleCardViewState']), viewState, state),
  ),
  on(RootSystemActions.GetAvailableLocalesSuccessAction, (state, { locales }) =>
    mergeRight(state, { locales }),
  ),
  on(GetMetadataSuccessAction, (state, { data }) => mergeRight(state, { metadata: data })),
  on(RootSystemActions.GetSpacesSuccessAction, (state, { spaces }) =>
    mergeRight(state, { spaces }),
  ),
  on(RootSystemActions.GetChromePluginVersionSuccessAction, (state, { version }) =>
    mergeRight(state, { chromePluginVersion: version }),
  ),
  on(RootSystemActions.GetUserSuccessAction, (state, { user }) => {
    const availablePages = prop('availablePages', user);
    const additionalInfo = prop('additionalInfo', user);
    const availableOptions = pipe(
      prop('availableOptions') as UnaryOperator<CurrentUser, UserOptions>,
    )(user);
    const userRoles = prop('roles', user);
    const data = omit(['additionalInfo', 'availableOptions', 'availablePages', 'roles'], user);
    return mergeRight(state, {
      additionalInfo,
      availableOptions,
      availablePages,
      data,
      userRoles,
      isLoading: false,
    } as Partial<RootState>) as RootState;
  }),

  on(RootSystemActions.InitFirebaseNotificationAppSuccessAction, (state, { token }) =>
    mergeRight(state, { pushNotificationToken: token }),
  ),
  on(RootSystemActions.RevokeFirebaseNotificationPermissionSuccessAction, (state) =>
    pipe(
      set(lensPath(['data', 'allowedPushNotification']), false),
      mergeLeft({ pushNotificationToken: null }) as UnaryOperator<RootState, RootState>,
    )(state),
  ),
  on(AddNewEntitySystemActions.CleanNewEntityStateAction, (state) =>
    mergeRight(state, { newEntityData: null }),
  ),
  on(
    RootSystemActions.SetDataForNewTalentDialogAction,
    (state, { contacts, profile, vacancyId }) => {
      const name = splitName(profile.name, 'firstName', 'lastName');
      const remappedProfile = { ...profile, ...name };
      return mergeRight(state, {
        newEntityData: { profile: remappedProfile, contacts, vacancyId },
      });
    },
  ),
  on(
    VacanciesListUserActions.SaveQuickFilterAction,
    VacanciesListSystemActions.SetQuickFilterFromResolverAction,
    (state, { filter }) => set(lensPath(['data', 'vacancyQuickFiltersSelection']), filter, state),
  ),
  on(
    VacancySelectorUserActions.SelectVacancyAction,
    VacancySelectorSystemActions.SaveSelectedVacancyToRecruiterProfileAction,
    (state, { id }) => set(lensPath(['data', 'lastVacancy']), id, state),
  ),
  on(
    RootSystemActions.GetTutorialProgressAction,
    RootUserActions.ChangeLocalizationAction,
    RootUserActions.UpdateUserSpaceAction,
    mergeLeft({ isLoading: true }) as UnaryOperator<RootState, RootState>,
  ),
  on(
    RootSystemActions.GetTutorialProgressFailedAction,
    RootSystemActions.ChangeLocalizationFailedAction,
    RootSystemActions.UpdateUserSpaceFailedAction,
    mergeLeft({ isLoading: false }) as UnaryOperator<RootState, RootState>,
  ),
  on(RootSystemActions.GetTutorialProgressSuccessAction, (state, { progress }) =>
    mergeRight(state, { tutorialProgress: progress, isLoading: false }),
  ),
  on(RootSystemActions.GetNewVacanciesCountSuccessAction, (state, { count }) =>
    mergeRight(state, { newVacanciesCount: count }),
  ),
  on(
    VacancyProfilesSystemActions.ChangeSimpleCardViewStateFailedAction,
    VacancyProfilesUserActions.ChangeSimpleCardViewStateAction,
    (state, { status }) => {
      const newData = mergeRight(state.data, {
        simpleCardViewState: status,
      });
      return mergeRight(state, { data: newData });
    },
  ),
  on(
    RootUserActions.LogOutAction,
    RootSystemActions.UpdateUserSpaceSuccessAction,
    () => initialState,
  ),
);

export function rootReducer(baseState: RootState, action: Action): RootState {
  return reducer(baseState, action);
}

export const selectRootStore = createFeatureSelector<RootState>('currentUser');
export const getCurrentUser = createSelector(selectRootStore, (store) => store?.data);
export const getSpaceId = createSelector(getCurrentUser, (user) => user?.portalId);
export const getSpaceName = createSelector(getCurrentUser, (user) => user?.portalName);
export const getUserId = createSelector(getCurrentUser, (user) => user?.id);
export const getUserName = createSelector(getCurrentUser, (user) => user?.name);
export const getSimpleCardViewState = createSelector(
  getCurrentUser,
  (user) => user?.simpleCardViewState,
);
export const getVacancyQuickFilterSelection = createSelector(
  getCurrentUser,
  (user) => user?.vacancyQuickFiltersSelection,
);

export const getAllowedPages = createSelector(
  selectRootStore,
  propOr({}, 'availablePages') as UnaryOperator<RootState, UserAllowedSearchPages>,
);
export const getIsPageAllowed = memoizeWith(identity, (tab: keyof UserAllowedSearchPages) =>
  createSelector(getAllowedPages, (availablePages: UserAllowedSearchPages): boolean =>
    propOr(false, tab, availablePages),
  ),
);
export const getAvailableOptions = createSelector(
  selectRootStore,
  (user) => user?.availableOptions,
);
export const getChromePluginVersion = createSelector(
  selectRootStore,
  (store) => store?.chromePluginVersion,
);
export const getUserRoles = createSelector(
  selectRootStore,
  propOr([], 'userRoles') as UnaryOperator<RootState, string[]>,
);
export const getUserIsAdmin = createSelector(
  getUserRoles,
  pipe(intersection(managerUserRoles), isEmpty, not),
);
export const getPublicVacancyUrl = createSelector(getCurrentUser, (user) => user?.vacancyUrl);
export const getUserEmail = createSelector(getCurrentUser, (user) => user?.email);
export const getAdditionalInfo = createSelector(selectRootStore, (store) => store?.additionalInfo);
export const getKernelUserRole = createSelector(
  getCurrentUser,
  (user) =>
    (user.customFields.find((cf) => cf.type.name.includes('доступ'))?.value as string) ||
    'standard',
);
export const isUserKernelAdmin = createSelector(
  getKernelUserRole,
  (role) => role.includes('system_admin') || role.includes('platform_admin'),
);

export const getSubscriptionInfo = createSelector(getCurrentUser, (user) => user?.subscription);
export const getSubscriptionName = createSelector(
  getSubscriptionInfo,
  (info) => info?.name || SubscriptionPackagesEnum.plugin,
);
export const getSubscriptionActiveTo = createSelector(
  getSubscriptionInfo,
  (subscription) => subscription?.availableTo,
);
export const getIsSubscriptionExpired = createSelector(getSubscriptionActiveTo, (activeTo) =>
  activeTo ? DateTime.fromISO(activeTo).diffNow('hours').hours <= 0 : false,
);
export const getIsTrialPeriodActive = createSelector(
  getSubscriptionInfo,
  (subscription) => subscription?.isTrial,
);
export const getIsTeamSubscription = createSelector(
  getSubscriptionInfo,
  (subscription) => subscription?.name === SubscriptionPackagesEnum.team,
);
export const isUserCanActivateTrial = createSelector(
  getSubscriptionInfo,
  (subscription) => subscription?.canActivateTrial,
);
export const getUserPackageAccesses = createSelector(getSubscriptionName, (name): PackageAccess[] =>
  propOr([], name, userPackageAccesses),
);
export const getUserStateLoading = createSelector(selectRootStore, (store) => store?.isLoading);
export const getUserLocalization = createSelector(getCurrentUser, (user) => user?.locale || 'uk');
export const getTutorialProgress = createSelector(
  selectRootStore,
  (store) => store?.tutorialProgress,
);
export const getAvailableFilters = createSelector(
  selectRootStore,
  getSource,
  (metadataState: RootState, source: ProfilesSources): string[] =>
    pathOr([], ['metadata', 'availableFilters', source], metadataState) as string[],
);
export const getAvailableSorting = createSelector(
  selectRootStore,
  getActiveModule,
  getSource,
  (metadataState: RootState, activeModule: string, source: ProfilesSources): string[] => {
    let target: string;
    switch (activeModule) {
      case 'profile':
        target = 'profile';
        break;
      case 'vacancy':
        target = 'vacancy';
        break;
      case 'vacancies':
        target = 'vacanciesList';
        break;
      default:
        target = source;
    }
    return pathOr([], ['metadata', 'availableSortings', target], metadataState) as string[];
  },
);
export const getAvailableLocales = createSelector(selectRootStore, (store) => store?.locales);
export const getUserSelectedVacancy = createSelector(getCurrentUser, (user) => user?.lastVacancy);
export const getLocaleId = memoizeWith(identity, (code: Locales) =>
  createSelector(getAvailableLocales, (locales: Locale[]) =>
    pipe(
      find(propEq(code, 'code')) as UnaryOperator<Locale[], Locale>,
      prop('id') as UnaryOperator<Locale, number>,
    )(locales),
  ),
);
export const getSpaces = createSelector(selectRootStore, (store) => store?.spaces);
export const isUserOptionAllowed = memoizeWith(identity, (property: keyof UserOptions) =>
  createSelector(
    getAvailableOptions,
    getUserRoles,
    (userOptions: UserOptions | null, userRoles: string[] | null): boolean => {
      if (userRoles?.includes('ROLE_ADMIN')) {
        return true;
      }

      return !!userOptions?.[property];
    },
  ),
);
export const allowedPushNotification = createSelector(
  getCurrentUser,
  (user) => user?.allowedPushNotification,
);
export const pushNotificationToken = createSelector(
  selectRootStore,
  (store) => store?.pushNotificationToken,
);
export const isSearchBySecurityIdAllowed = createSelector(
  getAvailableOptions,
  (options) => options?.searchBySecurityId || false,
);
export const getNewVacanciesCount = createSelector(
  selectRootStore,
  (store) => store?.newVacanciesCount,
);
export const getPreDefinedNewEntityData = createSelector(
  selectRootStore,
  (store) => store?.newEntityData,
);
/*
export const userIsRecruiterOrAdmin = createSelector(
  getUserRoles,
  pipe(intersection(['ROLE_RECRUITER', 'ROLE_PORTAL_ADMIN', 'ROLE_ADMIN']), isEmpty, not),
);
*/
export const userIsRecruiterOrAdmin = createSelector(
  getUserRoles,
  (roles) => !roles.includes('ROLE_PORTAL_CLIENT'),
);
export const portalSupportUrl = createSelector(
  getCurrentUser,
  (user) => user?.supportUrl || environment.supportUrl,
);
