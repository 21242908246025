import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HistoryEvent, HistoryList, HistoryParams } from '@app-shared/models';
import { isNil, reject } from 'ramda';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryEventsService {
  constructor(private readonly http: HttpClient) {}

  public deleteEvent(event: HistoryEvent): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>('/history/delete-item', {
      id: event.id,
      createdAt: event.createdAt,
    });
  }

  public getEntityHistory(params: HistoryParams): Observable<HistoryList> {
    const filters = reject(isNil, { ...params });

    return this.http.get<HistoryList>('/history', {
      params: HttpHelperService.serializeParams(filters),
    });
  }
}
