import {
  EntitiesList,
  PaginationParams,
  SearchParams,
  SortParams,
  VacanciesListQuickFilters,
  Vacancy,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const ChangeVacancySimpleCardViewStateComplete = createAction(
  '[Vacancies List Effect] Simple card view state successfully changed',
  props<{ viewState: boolean }>(),
);
const ChangeVacancySimpleCardViewStateFailed = createAction(
  '[Vacancies List Effect] Error happened during changing simple card view state',
  props<{ viewState: boolean }>(),
);
const LoadVacanciesListAction = createAction(
  '[Vacancies List Init] Request vacancies list on start',
);
const RefreshVacanciesListAction = createAction('[Vacancies List Init] Refresh vacancies list');
const RequestVacanciesListSuccessAction = createAction(
  '[Vacancies Effect | Vacancies API] Detailed vacancies list saved to store',
  props<{ vacancies: EntitiesList<Vacancy> }>(),
);
const RequestVacanciesListFailedAction = createAction(
  '[Vacancies Effect | Vacancies API] Request detailed vacancies list failed',
);
const ReturnInitialStateAction = createAction(
  '[Vacancies List Cleaner Guard] Return vacancies list store to initial state',
);
const SaveQuickFilterFailedAction = createAction(
  '[Vacancies Effect | User API] Error happened during saving quick filter',
);
const SaveQuickFilterSuccessAction = createAction(
  '[Vacancies Effect | User API] Selected quick filter saved to MongoDB',
);
const SetInitialStateAction = createAction(
  '[Vacancies List Resolver] Set initial state from query params',
  props<{
    searchParams: SearchParams;
    sortParams: SortParams;
    paginationParams: PaginationParams;
  }>(),
);
const SetQuickFilterFromResolverAction = createAction(
  '[Vacancies List Resolver] Set new quick filter value based on query parameters',
  props<{ filter: VacanciesListQuickFilters }>(),
);

const actions = {
  ChangeVacancySimpleCardViewStateComplete,
  ChangeVacancySimpleCardViewStateFailed,
  LoadVacanciesListAction,
  RefreshVacanciesListAction,
  RequestVacanciesListSuccessAction,
  RequestVacanciesListFailedAction,
  ReturnInitialStateAction,
  SaveQuickFilterFailedAction,
  SaveQuickFilterSuccessAction,
  SetInitialStateAction,
  SetQuickFilterFromResolverAction,
};

const vacanciesListSystemActions = union(actions);

export type VacanciesListSystemActionsType = typeof vacanciesListSystemActions;

export const VacanciesListSystemActions = actions;
