import { createAction, union } from '@ngrx/store';

const GetClientCustomFieldsAction = createAction(
  '[Custom Fields] Request custom fields for client',
);
const GetProfileCustomFieldsAction = createAction(
  '[Custom Fields] Request custom fields for profile',
);
const GetProjectCustomFieldsAction = createAction(
  '[Custom Fields] Request custom fields for vacancy',
);
const GetRecruiterCustomFieldsAction = createAction(
  '[Custom Fields] Request custom fields for recruiter',
);

const actions = {
  GetClientCustomFieldsAction,
  GetProfileCustomFieldsAction,
  GetProjectCustomFieldsAction,
  GetRecruiterCustomFieldsAction,
};

const customFieldsUserActions = union(actions);

export type CustomFieldsUserActionsType = typeof customFieldsUserActions;

export const CustomFieldsUserActions = actions;
