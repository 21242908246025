import { NgClass, NgStyle, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { Locales, Profile } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { FallbackSrcWidthDirective } from '@tsp-directives';
import { GetInitialsPipe } from '@tsp-pipes';
import { isNil } from 'ramda';

@Component({
  imports: [
    FallbackSrcWidthDirective,
    GetInitialsPipe,
    MatButton,
    MatTooltip,
    NgClass,
    NgStyle,
    RouterLink,
    SlicePipe,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'profile-candidates',
  templateUrl: './public-profile-candidates.component.html',
  styleUrls: ['./public-profile-candidates.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileCandidatesComponent {
  @Input()
  public candidates: Profile[];
  @Input()
  public vacancyId: number;
  @Input()
  public userLocale: Locales;
  @Output()
  public addToList = new EventEmitter<string>();
  @Output()
  public searchMatchedCandidates = new EventEmitter<void>();

  public isVisible = false;
  public showAll = false;

  public isCandidateOnVacancy(candidate: Profile) {
    return !isNil(candidate.applies);
  }

  public trackById(_index: number, element: Profile) {
    return element.id;
  }
}
