import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { equals, last, pipe, split, test } from 'ramda';
import { Store } from '@ngrx/store';

import { State } from '@app-shared/reducers';
import { RouterActions } from '@app-shared/actions/router.actions';

@Injectable({
  providedIn: 'root',
})
export class RedirectInterceptorService {
  constructor(private readonly store$: Store<State>) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap((res: HttpResponse<unknown>) => {
        if (!equals(req.urlWithParams, res.url) && test(/v2\/profiles\/details/, res.url)) {
          const id = pipe(split('/'), last)(res.url) as string;
          const navigationAction = RouterActions.NavigateToProfilePageAction({ id });
          this.store$.dispatch(navigationAction);
        }
      }),
    );
  }
}
