import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { SearchConsoleSystemActions } from '@app-core/search-console/actions/search-console.system.actions';
import { SearchConsoleUserActions } from '@app-core/search-console/actions/search-console.user.actions';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { ProfileModificationActions } from '@app-shared/effects/profile-modification/profile-modification.actions';
import {
  matchPublicProfileInList,
  removeShortListFromProfile,
  removeShortListFromPublicProfileCandidates,
  setCreatedShortListToProfile,
  updateApplyInProfilePreview,
} from '@app-shared/functions/profile-list-modifications/profile-list-modifications';
import { convertStringToNumeric } from '@app-shared/functions/utilities/utilities';
import {
  ComplicatedRequestObject,
  ExternalCounts,
  PaginationParams,
  Profile,
  ProfileExternalSearchSources,
  ProfilesSearchParams,
  ProfilesSources,
  SortParams,
  Tab,
  TotalCounts,
  UnaryOperator,
  XRayLink,
  externalSources,
} from '@app-shared/models';
import { DictionariesState } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { VacancySelectorUserActions } from '@tsp-components/vacancy-selector';
import { parse } from 'qs';
import {
  always,
  anyPass,
  filter,
  flatten,
  has,
  ifElse,
  is,
  isEmpty,
  isNil,
  last,
  map,
  mapObjIndexed,
  mergeDeepRight,
  mergeLeft,
  mergeRight,
  of,
  omit,
  pipe,
  pluck,
  prop,
  reject,
  replace,
  split,
  unless,
} from 'ramda';

export const defaultPaginationParams = {
  pageNumber: 1,
  pageSize: 10,
};

export interface SearchConsoleState {
  countBySource?: TotalCounts;
  errorMessage?: string;
  externalCounts?: ExternalCounts;
  externalSearchSource: ProfileExternalSearchSources;
  isCountsLoading: boolean;
  isProfilesLoading: boolean;
  lastSearchByVacancy?: string[];
  paginationParams?: PaginationParams;
  profiles?: Profile[];
  searchParams?: ProfilesSearchParams;
  selectedIds?: number[] | string[];
  sortParams?: SortParams;
  source?: ProfilesSources;
  totalCount?: number;
  vacancy?: number;
  visibleTabs?: Tab[];
  xRayLinks?: XRayLink[];
}

export const initialState: SearchConsoleState = {
  errorMessage: 'Please specify search parameters',
  externalSearchSource: externalSources[0].code,
  isCountsLoading: false,
  isProfilesLoading: false,
  paginationParams: defaultPaginationParams,
};

const reducer = createReducer(
  initialState,
  on(
    ProfileModificationActions.MatchProfileDataAction,
    SearchConsoleUserActions.AddToShortListAction,
    SearchConsoleUserActions.DeleteProfilesAction,
    SearchConsoleUserActions.LinkSuggestionAction,
    SearchConsoleUserActions.MergeMultipleProfilesAction,
    SearchConsoleUserActions.MergeTwoProfilesAction,
    SearchConsoleUserActions.RemoveFromShortListInProfilesListAction,
    SearchConsoleUserActions.ResendShortlistForSecurityCheckAction,
    SearchConsoleUserActions.UpdateShortListInProfilesListAction,
    SearchConsoleSystemActions.GetExternalProfilesAction,
    SearchConsoleSystemActions.LoadInternalProfilesAction,
    mergeLeft({ isProfilesLoading: true } as Partial<SearchConsoleState>) as UnaryOperator<
      SearchConsoleState,
      SearchConsoleState
    >,
  ),
  on(
    ProfileModificationActions.MatchProfileDataFailedAction,
    SearchConsoleSystemActions.CreateShortListFailedAction,
    SearchConsoleSystemActions.DeleteProfilesFailedAction,
    SearchConsoleSystemActions.DeleteProfilesSuccessAction,
    SearchConsoleSystemActions.ExternalSearchFailedAction,
    SearchConsoleSystemActions.LinkSuggestionFailedAction,
    SearchConsoleSystemActions.LinkSuggestionSuccessAction,
    SearchConsoleSystemActions.MergeMultipleProfilesSuccessAction,
    SearchConsoleSystemActions.MergeTwoProfilesSuccessAction,
    SearchConsoleSystemActions.MergeMultipleProfilesFailedAction,
    SearchConsoleSystemActions.MergeTwoProfilesFailedAction,
    SearchConsoleSystemActions.RemoveFromShortListInProfilesListFailedAction,
    SearchConsoleSystemActions.ResendShortListForSecurityCheckFailedAction,
    SearchConsoleSystemActions.ResendShortListForSecurityCheckSuccessAction,
    ProfilesApiActions.RequestProfilesFailedAction,
    mergeLeft({ isProfilesLoading: false } as Partial<SearchConsoleState>) as UnaryOperator<
      SearchConsoleState,
      SearchConsoleState
    >,
  ),
  on(
    SearchConsoleUserActions.CleanSearchParamsAction,
    mergeLeft({
      paginationParams: defaultPaginationParams,
      searchParams: null,
    }) as UnaryOperator<SearchConsoleState, SearchConsoleState>,
  ),
  on(MetadataActions.CleanReducers, () => initialState),
  on(SearchConsoleSystemActions.CreateShortListSuccessAction, (state, { profileId, newBid }) => {
    const profiles = state.profiles;
    if (anyPass([isNil, isEmpty])(profiles)) {
      return state;
    }
    const newProfiles = setCreatedShortListToProfile(profileId, newBid, profiles);
    return mergeRight(state, {
      isProfilesLoading: false,
      profiles: newProfiles,
    });
  }),
  on(SearchConsoleSystemActions.ExternalSearchSuccessAction, (state, { profiles, totalItems }) =>
    mergeRight(state, { profiles, totalCount: totalItems, isProfilesLoading: false }),
  ),
  on(
    SearchConsoleSystemActions.GetCountsWithNewParamsAction,
    SearchConsoleSystemActions.GetProfilesCountAction,
    mergeLeft({ isCountsLoading: true }) as UnaryOperator<SearchConsoleState, SearchConsoleState>,
  ),
  on(SearchConsoleSystemActions.LastSearchByVacancyCompleteAction, (state, { links }) =>
    mergeRight(state, {
      lastSearchByVacancy: links,
    }),
  ),
  on(
    RootSystemActions.PresetVacancyAction,
    VacancySelectorUserActions.SelectVacancyAction,
    (state, { id }) => mergeRight(state, { vacancy: id }),
  ),
  on(
    SearchConsoleSystemActions.RefreshCountsInProfilesListAfterMergeProfilesAction,
    (state, { tab }) => {
      let newCounts: TotalCounts;
      if (state.countBySource) {
        newCounts = mergeRight(state.countBySource, {
          [tab]: state.countBySource[tab] - 1,
        }) as TotalCounts;
      }
      return mergeRight(state, {
        countBySource: newCounts,
      });
    },
  ),
  on(
    SearchConsoleSystemActions.RemoveFromShortListInProfilesListSuccessAction,
    (state, { devId, bidId }) => {
      const profiles = state.profiles;
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles =
        state.source === ProfilesSources.PROFILES
          ? removeShortListFromPublicProfileCandidates(bidId, profiles)
          : removeShortListFromProfile(devId, profiles);
      return mergeRight(state, {
        isProfilesLoading: false,
        profiles: newProfiles,
      }) as SearchConsoleState;
    },
  ),
  on(
    SearchConsoleSystemActions.ReturnInitialStateAction,
    mergeLeft({
      errorMessage: initialState.errorMessage,
      searchParams: null,
      source: null,
      profiles: null,
      totalCount: 0,
    } as Partial<SearchConsoleState>) as UnaryOperator<SearchConsoleState, SearchConsoleState>,
  ),
  on(
    SearchConsoleSystemActions.RequestExternalCountsFailedAction,
    mergeLeft({
      isCountsLoading: false,
    }) as UnaryOperator<SearchConsoleState, SearchConsoleState>,
  ),
  on(SearchConsoleSystemActions.RequestExternalCountsSuccessAction, (state, { externalCounts }) =>
    mergeRight(state, {
      externalCounts,
      isCountsLoading: false,
    }),
  ),
  on(SearchConsoleSystemActions.GetXRayLinksAction, (state) =>
    mergeRight(state, { isProfilesLoading: true }),
  ),
  on(ProfilesApiActions.RequestXRaySuccessAction, (state, { links }) =>
    mergeRight(state, { xRayLinks: links, isProfilesLoading: false }),
  ),
  on(ProfilesApiActions.RequestXRayFailedAction, (state) =>
    mergeRight(state, { isProfilesLoading: false }),
  ),
  on(ProfilesApiActions.RequestProfilesSuccessAction, (state, { items, totalCount }) =>
    mergeRight(state, {
      externalCounts: null,
      profiles: items,
      totalCount,
      isProfilesLoading: false,
    }),
  ),
  on(ProfilesApiActions.RequestProfilesCountsSuccessAction, (state, { counts }) =>
    mergeRight(state, {
      countBySource: counts,
      isCountsLoading: false,
    }),
  ),
  on(
    ProfilesApiActions.RequestProfilesCountsFailedAction,
    mergeLeft({ isCountsLoading: false }) as UnaryOperator<SearchConsoleState, SearchConsoleState>,
  ),
  on(ProfileModificationActions.MatchProfileDataSuccessAction, (state, { data, id }) => {
    const profiles = state.profiles;
    if (anyPass([isNil, isEmpty])(profiles)) {
      return mergeRight(state, { isProfilesLoading: false });
    }
    const newProfiles = matchPublicProfileInList(data, profiles, id);
    return mergeRight(state, { isProfilesLoading: false, profiles: newProfiles });
  }),

  on(
    SearchConsoleSystemActions.SetInitialStateAction,
    (state, { paginationParams, searchParams, sortParams, source, externalSearchSource }) =>
      mergeRight(state, {
        paginationParams,
        searchParams,
        sortParams,
        source,
        externalSearchSource,
      }) as SearchConsoleState,
  ),
  on(SearchConsoleSystemActions.SetSearchParamsAction, (state, { params }) =>
    mergeRight(state, {
      searchParams: params,
    }),
  ),
  on(SearchConsoleSystemActions.ToggleProfilesLoadingAction, (state, { loadingState }) =>
    mergeRight(state, {
      isProfilesLoading: loadingState,
    }),
  ),
  on(SearchConsoleUserActions.UpdatePaginationParamsAction, (state, { paginationParams }) =>
    mergeRight(state, { paginationParams }),
  ),
  on(SearchConsoleSystemActions.UpdateSearchModelFromVacancyAction, (state, { searchParams }) =>
    mergeRight(state, {
      source: ProfilesSources.PROFILES,
      searchParams,
      paginationParams: defaultPaginationParams,
    }),
  ),
  on(SearchConsoleUserActions.ChangeExternalSearchSource, (state, { externalSearchSource }) => {
    return mergeRight(state, { externalSearchSource });
  }),
  on(SearchConsoleUserActions.UpdateSearchParamsAction, (state, { searchParams }) => {
    const source = state.source || ProfilesSources.CANDIDATES;
    return mergeRight(state, {
      source,
      searchParams,
      paginationParams: defaultPaginationParams,
    });
  }),
  on(SearchConsoleSystemActions.OpenSearchWithProfileContacts, (state, { searchParams }) =>
    mergeRight(state, {
      source: ProfilesSources.CANDIDATES,
      searchParams,
      paginationParams: defaultPaginationParams,
    }),
  ),
  on(
    SearchConsoleSystemActions.UpdateShortListInProfilesListSuccessAction,
    (state, { shortList }) => {
      const profiles = state.profiles;
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles = updateApplyInProfilePreview(shortList, profiles, true);
      return mergeRight(state, {
        isProfilesLoading: false,
        profiles: newProfiles,
      });
    },
  ),
  on(
    SearchConsoleSystemActions.UpdateShortListInProfilesListFailedAction,
    (state, { shortList }) => {
      const profiles = state.profiles;
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles = updateApplyInProfilePreview(shortList, profiles, false);
      return mergeRight(state, {
        isProfilesLoading: false,
        profiles: newProfiles,
      });
    },
  ),
  on(SearchConsoleUserActions.UpdateSortingParamsAction, (state, { sortParams }) =>
    mergeRight(state, {
      sortParams,
      paginationParams: defaultPaginationParams,
    }),
  ),
  on(SearchConsoleSystemActions.UpdateSourceAction, (state, { source }) =>
    mergeRight(state, { source }),
  ),
);

export function searchConsoleReducer(
  baseState: SearchConsoleState,
  action: Action,
): SearchConsoleState {
  return reducer(baseState, action);
}

export const selectDictionaryStore = createFeatureSelector<DictionariesState>('dictionaries');
export const selectSearchConsoleStore = createFeatureSelector<SearchConsoleState>('searchConsole');
export const getProfilesAndCount = createSelector(
  selectSearchConsoleStore,
  (store: SearchConsoleState) => ({
    profiles: prop('profiles', store) || [],
    totalCount: prop('totalCount', store) || 0,
  }),
);
export const getExternalCounts = createSelector(
  selectSearchConsoleStore,
  (store) => store?.externalCounts,
);
export const getProfilesCounts = createSelector(
  selectSearchConsoleStore,
  (store) => store?.countBySource,
);
export const getCountsLoading = createSelector(
  selectSearchConsoleStore,
  (store) => store?.isCountsLoading,
);
export const getExternalSearchSource = createSelector(
  selectSearchConsoleStore,
  (store) => store?.externalSearchSource,
);
export const getLastSearchByVacancyUnParse = createSelector(
  selectSearchConsoleStore,
  (store) => store?.lastSearchByVacancy,
);
export const getLastSearchByVacancy = createSelector(
  selectSearchConsoleStore,
  pipe(
    prop('lastSearchByVacancy') as UnaryOperator<SearchConsoleState, string[] | null>,
    unless(
      isNil,
      pipe(
        map(pipe(replace(/[+]/g, '%2B'), split('?'), last, parse)) as UnaryOperator<
          string[],
          ProfilesSearchParams[]
        >,
        reject(
          pipe(
            omit([
              'sortField',
              'sortDirection',
              'pageNumber',
              'pageSize',
              'vacancy',
            ]) as UnaryOperator<ProfilesSearchParams, ProfilesSearchParams>,
            isEmpty as UnaryOperator<ProfilesSearchParams, boolean>,
          ) as UnaryOperator<ProfilesSearchParams, boolean>,
        ) as UnaryOperator<ProfilesSearchParams[], ProfilesSearchParams[]>,
        map(
          mapObjIndexed((value: string | string[] | ComplicatedRequestObject[], key: string) => {
            if (key === 'complicatedRequest') {
              return map(
                (complicatedSearchObject: ComplicatedRequestObject): ComplicatedRequestObject => {
                  if (
                    anyPass([
                      has('skills'),
                      has('skillExceptions'),
                      has('tags'),
                      has('tagExceptions'),
                    ])(complicatedSearchObject)
                  ) {
                    return pipe(
                      omit(['target']) as UnaryOperator<
                        ComplicatedRequestObject,
                        Pick<ComplicatedRequestObject, 'skills' | 'tags'>
                      >,
                      mapObjIndexed(
                        map(convertStringToNumeric) as UnaryOperator<unknown, number[]>,
                      ) as UnaryOperator<
                        Pick<ComplicatedRequestObject, 'skills' | 'tags'>,
                        Pick<ComplicatedRequestObject, 'skills' | 'tags'>
                      >,
                      mergeDeepRight(complicatedSearchObject) as UnaryOperator<
                        Pick<ComplicatedRequestObject, 'skills' | 'tags'>,
                        ComplicatedRequestObject
                      >,
                    )(complicatedSearchObject);
                  }
                  return complicatedSearchObject;
                },
                value as ComplicatedRequestObject[],
              );
            }

            return is(Array, value)
              ? map(convertStringToNumeric, value as string[])
              : convertStringToNumeric(value);
          }) as UnaryOperator<ProfilesSearchParams, ProfilesSearchParams>,
        ),
      ),
    ) as UnaryOperator<string[] | null, ProfilesSearchParams[] | null>,
  ),
);
export const getProfilesLoading = createSelector(
  selectSearchConsoleStore,
  (store) => store?.isProfilesLoading,
);
export const getSearchParameters = createSelector(
  selectSearchConsoleStore,
  (store) => store?.searchParams,
);
export const getXRayLinks = createSelector(selectSearchConsoleStore, (store) => store?.xRayLinks);

export const getSkillsFilter = createSelector(
  getSearchParameters,
  ifElse(
    isNil,
    always([]),
    ifElse(
      pipe(prop('complicatedRequest'), isNil),
      pipe(prop('skills'), unless(is(Array), of(Array))),
      pipe(
        prop('complicatedRequest'),
        filter(has('skills')),
        unless(isEmpty, pipe(pluck('skills'), flatten)),
      ),
    ),
  ) as UnaryOperator<ProfilesSearchParams | null, number[]>,
);

export const getSource = createSelector(selectSearchConsoleStore, (store) => store?.source);

export const getPaginationState = createSelector(
  selectSearchConsoleStore,
  (store) => store?.paginationParams,
);
export const getSortingState = createSelector(
  selectSearchConsoleStore,
  (store) => store?.sortParams || {},
);
export const getErrorMessage = createSelector(
  selectSearchConsoleStore,
  (store) => store?.errorMessage,
);
export const getSelectedVacancyIdForSearch = createSelector(
  selectSearchConsoleStore,
  (store) => store?.vacancy,
);
