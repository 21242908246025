<div mat-dialog-content>
  <h4>
    {{
      'shared.dialogs.profile-' + data.dialogType + '.title'
        | translate: { name: data.profile.name }
    }}
  </h4>
  <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="changeTab($event)">
    @for (file of files; track trackById($index, file)) {
      <mat-tab labelClass="c-profile-files-dialog__tab">
        <ng-template mat-tab-label>
          <span class="c-profile-files-dialog__tab-label">
            <i class="far fa-file-alt icon" aria-hidden="true"></i>
            <strong>{{ file.filePath | getFileName }}</strong>
            <br />
            {{ file.createdAt | date }}
          </span>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
  @if (selectedFile?.filePath; as url) {
    <app-file-preview [fileType]="data.dialogType" [iframeHeight]="iframeHeight" [url]="url">
    </app-file-preview>
  }

  @if (selectedFile) {
    <div class="c-profile-files-dialog__actions">
      <a
        [href]="selectedFile.filePath"
        target="_blank"
        download
        mat-raised-button
        color="primary"
        class="btn btn-download"
      >
        <span translate>shared.dialogs.profile-cvs.download.link</span>
      </a>
      <a [href]="filePreviewUrl" target="_blank" mat-raised-button color="accent" class="btn">
        <span translate>shared.dialogs.profile-cvs.open.link</span>
      </a>
    </div>
  }
</div>
