import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEditableItem, MailTemplate } from '@app-shared/models';

@Pipe({
  name: 'castToNotificationTemplate',
  standalone: true,
})
export class CastToNotificationTemplatePipe implements PipeTransform {
  public transform(value: DictionaryEditableItem): MailTemplate {
    return value as MailTemplate;
  }
}
