import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { KernelActions } from '@app-integration/kernel/actions/kernel.actions';
import { KernelEmployee } from '@app-integration/kernel/models/kernel.model';
import { getRelatedDepartmentInfo } from '@app-integration/kernel/selectors/kernel.selectors';
import { KernelService } from '@app-integration/kernel/services/kernel.service';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import {
  CV,
  DictionaryDepartment,
  ShortListDialogData,
  ShortListDialogForm,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getDepartments } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { MultipleSearchComponent } from '@tsp-components/multiple-select-search';
import { GetInitialsPipe } from '@tsp-pipes';
import {
  always,
  anyPass,
  eqBy,
  equals,
  includes,
  innerJoin,
  is,
  isEmpty,
  isNil,
  none,
  of,
  pipe,
  prop,
  reject,
  filter as rfilter,
  unionWith,
  uniqBy,
  unless,
  when,
  without,
} from 'ramda';
import { Observable, Subject, debounce, filter, interval, map, switchMap, takeUntil } from 'rxjs';

@Component({
  imports: [
    AsyncPipe,
    GetInitialsPipe,
    MatFormField,
    MatIconButton,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MatTooltip,
    MultipleSearchComponent,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  // providers: [KernelService],
  selector: 'short-list-dialog-managerial',
  templateUrl: './short-list-dialog-managerial.component.html',
  styleUrls: ['./short-list-dialog-managerial.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogManagerialComponent implements OnInit, OnDestroy {
  @Input()
  public data: ShortListDialogData;
  @Input()
  public form: FormGroup<Partial<ShortListDialogForm>>;
  public departments$?: Observable<DictionaryDepartment[]>;
  public managers: KernelEmployee[];
  public selectedManagers: KernelEmployee[] = [];
  public cvs: CV[];
  public fileUploading = false;

  public readonly searchValueControl = new FormControl<string>('');
  public readonly searchValue$ = new Subject<string>();
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly kernelService: KernelService,
    private readonly store$: Store<State>,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.store$.dispatch(
      KernelActions.RequestRelatedDepartmentInfoAction({
        vacancyId: this.data.vacancyId,
      }),
    );

    this.form
      .get('department')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (isNil(value)) {
          this.form.get('emails').disable();
          return;
        }

        this.form.get('emails').enable();
        this.searchValue$.next(this.searchValueControl.value);
      });

    this.form
      .get('emails')
      .valueChanges.pipe(
        map((value: string | string[]) => unless(is(Array), of(Array))(value) as string[]),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((emails) => this.setSelectedManagers(emails));

    this.departments$ = this.store$.pipe(select(getDepartments));

    this.searchValue$
      .pipe(
        debounce(() => interval(500)),
        filter(() => isNotNullOrUndefined(this.selectedSubdivision)),
        switchMap((keyword) =>
          this.kernelService
            .getManagers(this.selectedSubdivision, keyword)
            .pipe(
              map(
                when(anyPass([isNil, isEmpty]), always([])) as UnaryOperator<
                  KernelEmployee[],
                  KernelEmployee[]
                >,
              ),
            ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((managers) => {
        if (isEmpty(managers)) {
          this.processEmptyManagers();
        }
        this.managers = managers;
        const emails = this.form.get('emails').value;

        this.setSelectedManagers(emails);
        this.changeDetector.markForCheck();
      });

    this.store$
      .pipe(
        select(getRelatedDepartmentInfo),
        filter(
          (info) => !!info && none(anyPass([isNil, isEmpty]))([info.departmentId, info.email]),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((info) => {
        this.form.get('emails').enable();
        this.setSelectedManagers([info.email]);
        this.searchValueControl.setValue(info.email);
        this.form.get('department').patchValue(info.departmentId);
      });
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get selectedSubdivision(): string {
    return this.form.get('department').value;
  }
  public onSearch(keyword: string) {
    this.searchValue$.next(keyword);
  }
  public removeManager(email: string) {
    const emails = without([email], this.form.get('emails').value);
    this.setSelectedManagers(emails);
  }
  public setSelectedManagers(emails: string[]) {
    this.selectedManagers = pipe(
      innerJoin(
        (manager: KernelEmployee, email: string) => equals(email, prop('email', manager)),
        this.managers || [],
      ),
      reject(anyPass([isNil, isEmpty])),
      (managers) => unionWith(eqBy(prop('email')), managers, this.selectedManagers),
      uniqBy(prop('id')),
      rfilter((manager: KernelEmployee) => includes(manager.email, emails)),
    )(emails);
    this.form.get('emails').patchValue(emails, { emitEvent: false, onlySelf: true });
  }
  public trackById(_index: number, element: KernelEmployee | DictionaryDepartment) {
    return element.id;
  }
  private processEmptyManagers() {
    this.store$.dispatch(
      InternalNotificationActions.InfoNotificationAction({
        message: 'profile-short-list.short-list-dialog.manager.empty-search',
      }),
    );
    this.searchValue$.next('');
  }
}
