import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTab, MatTabChangeEvent, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { CV, Profile, UnaryOperator } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { GetFileNamePipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import { findIndex, includes, last, pipe, prop, propEq, sort, split, toLower } from 'ramda';
import { Subject, fromEvent } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { FilePreviewComponent } from '../file-preview/file-preview.component';

@Component({
  imports: [
    DatePipe,
    FilePreviewComponent,
    GetFileNamePipe,
    MatAnchor,
    MatTab,
    MatTabGroup,
    MatTabLabel,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'profile-files-dialog',
  templateUrl: './profile-files-dialog.component.html',
  styleUrls: ['./profile-files-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class ProfileFilesDialogComponent implements OnInit, OnDestroy {
  public files?: CV[];
  public selectedIndex?: number;
  public selectedFile?: CV;
  public iframeHeight?: number;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      profile: Profile;
      dialogType: 'cvs' | 'voices' | 'otherFiles';
      selectedFile?: number;
    },
  ) {}

  public ngOnInit(): void {
    this.files = pipe(
      prop(this.data.dialogType),
      sort(
        (a: CV, b: CV) =>
          DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis(),
      ),
    )(this.data.profile);

    this.selectedIndex = this.data.selectedFile
      ? findIndex(propEq(this.data.selectedFile, 'id'), this.files)
      : 0;

    this.selectedFile = this.files[this.selectedIndex];

    this.setResizeSubscription();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public selectFile(file: CV) {
    this.selectedFile = null;

    setTimeout(() => {
      this.selectedFile = file;
    });
  }

  public changeTab(e: MatTabChangeEvent) {
    if (!this.files) {
      return;
    }

    this.selectFile(this.files[e.index]);
  }

  public get filePreviewUrl(): string {
    if (!this.selectedFile) {
      return '';
    }

    const fileExtension: string = pipe(
      split('.'),
      last as UnaryOperator<string[], string>,
      toLower,
    )(this.selectedFile.filePath);

    if (
      this.data.dialogType === 'voices' ||
      ['jpg', 'jpeg', 'png'].includes(fileExtension) ||
      fileExtension === 'pdf'
    ) {
      return this.selectedFile.filePath;
    }
    const officeExtensions = [
      '.doc',
      '.docm',
      '.docx',
      '.docx',
      '.dot',
      '.dotm',
      '.dotx',
      '.odt',
      '.ppt',
      '.pptm',
      '.pptx',
      '.odp',
      '.ods',
      '.rtf',
      '.txt',
      '.xls',
      '.xlsm',
      '.xlsx',
    ];

    return includes(fileExtension, officeExtensions)
      ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
          this.selectedFile.filePath,
        )}`
      : `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
          this.selectedFile.filePath,
        )}`;
  }
  public isFileCanBePreviewed(file: CV): boolean {
    const fileExtension: string = pipe(
      split('.'),
      last as UnaryOperator<string[], string>,
    )(file.filePath);

    return ['docx', 'pdf', 'rtf', 'txt'].includes(fileExtension);
  }
  public trackById(_index: number, element: CV) {
    return element.id;
  }
  private setResizeSubscription() {
    const container = document.querySelector('mat-dialog-container');
    let originWindowHeight = 0;

    fromEvent(window, 'resize')
      .pipe(
        startWith(new Event('resize')),
        map(() => window.innerHeight),
        filter((newHeight) => originWindowHeight !== newHeight),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((newHeight) => {
        originWindowHeight = newHeight;
        this.iframeHeight = container.clientHeight - 144;
      });
  }
}
