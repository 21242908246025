import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import {
  checkUnresolvedDictionaries,
  getModuleRelatedDictionaries,
} from '@app-shared/functions/missed-dictionary-calculation/missed-dictionary-calculation';
import { AllDictionaries } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  isDictionariesLoading,
  selectModuleRelatedDictionaries,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Store, select } from '@ngrx/store';
import { equals, isEmpty } from 'ramda';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { RequestBunchDictionariesAction } from './dictionaries-resolve.actions';

export const dictionariesResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
): Observable<boolean> => {
  const store$: Store<State> = inject(Store<State>);

  const dictionaryType: string = route.data.dictionaryResolver;

  const requestedDictionariesNames = getModuleRelatedDictionaries(dictionaryType);

  return combineLatest([
    store$.pipe(select(selectModuleRelatedDictionaries(requestedDictionariesNames))),
    store$.pipe(select(isDictionariesLoading)),
  ]).pipe(
    distinctUntilChanged(([prevDictionaries], [currentDictionaries]) =>
      equals(prevDictionaries, currentDictionaries),
    ),
    tap(([data, loadingState]: [Partial<AllDictionaries>, boolean]) => {
      const requestedDictionaries = checkUnresolvedDictionaries(data, dictionaryType);
      if (!isEmpty(requestedDictionaries) && !loadingState) {
        store$.dispatch(
          RequestBunchDictionariesAction({
            dictionaries: requestedDictionaries,
          }),
        );
      }
    }),
    map(() => true),
    take(1),
  );
};
