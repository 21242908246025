import { createAction, props, union } from '@ngrx/store';

const SearchProfilesFromVacancyAction = createAction(
  '[Vacancy Selector] Search profiles using params from vacancy',
);
const SelectVacancyAction = createAction(
  '[Vacancy Selector] Select vacancy',
  props<{ id: number }>(),
);

const actions = {
  SearchProfilesFromVacancyAction,
  SelectVacancyAction,
};

const vacancySelectorUserActions = union(actions);

export type VacancySelectorUserActionsType = typeof vacancySelectorUserActions;

export const VacancySelectorUserActions = actions;
