import { ComplicatedRequestTarget, DictionaryItem } from '.';

export type AutocompleteGroupName =
  | 'skills'
  | 'skillsExceptions'
  | 'cities'
  | 'countries'
  | 'keywords'
  | 'keywordsExceptions'
  | 'tags'
  | 'tagsExceptions'
  | 'specialities'
  | 'specialitiesExceptions'
  | 'sources'
  | 'sourcesExceptions';

export type AutocompletedDirectory =
  | 'skills'
  | 'cities'
  | 'countries'
  | 'keywords'
  | 'tags'
  | 'specialities'
  | 'sources';

export interface AutocompleteItem {
  dictionary: AutocompleteGroupName;
  id: number | string;
  name: string;
  score?: number;
  synonyms?: DictionaryItem[];
}

export interface AutocompleteGroup {
  groupName: AutocompleteGroupName;
  items: AutocompleteItem[];
  target?: ComplicatedRequestTarget;
}
