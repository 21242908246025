import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { KernelCustomFieldSelectorComponent } from '@app-integration/kernel/components/kernel-custom-field-selector/kernel-custom-field-selector.component';
import { DictionaryItem } from '@app-shared/models';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { TranslatePipe } from '@ngx-translate/core';
import { RichEditorComponent } from '@tsp-components/rich-editor';
import { TagInputComponent } from '@tsp-components/tag-input';
import { DateTime } from 'luxon';

@Component({
  imports: [
    CdkTextareaAutosize,
    KernelCustomFieldSelectorComponent,
    MatCheckbox,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    MatTooltip,
    NgClass,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    RichEditorComponent,
    TagInputComponent,
    TranslatePipe,
  ],
  selector: 'app-custom-field-control',
  templateUrl: './custom-field-control.component.html',
  styleUrls: ['./custom-field-control.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomFieldControlComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CustomFieldControlComponent implements ControlValueAccessor {
  @Input() public controlId: number;
  @Input() public controlName: string;
  @Input() public controlType: string;
  @Input() public customCssClass: string;
  @Input() public description: string;
  @Input() public fieldType: 'selector' | 'richEditor' | 'textarea';
  @Input() public isReadonly?: boolean;
  @Input() public set isRequired(required: boolean) {
    if (required) {
      this.internalControl.setValidators([Validators.required]);
    }
  }
  @Input() public dictionary: DictionaryItem[];
  @Output() public securityGroupChanged = new EventEmitter<
    DictionaryItem & { eStaffId?: string }
  >();
  public internalControl = new FormControl<string | string[]>('');

  public get inputType() {
    return this.controlType === 'int' ? 'number' : this.controlType;
  }

  public get controlKeywords(): string[] {
    return this.internalControl.value as string[];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: (val: unknown) => void = () => {};

  public changeDateValue(date: Date) {
    const modifiedDate = DateTime.fromJSDate(date)
      .toUTC()
      .set({ second: 0, millisecond: 0 })
      .toISO();
    this.onChange(modifiedDate);
  }

  public clearValue() {
    this.internalControl.setValue(null);
    this.onChange(null);
  }
  public changeValue(event: MatCheckboxChange) {
    const value = event.checked;

    this.onChange(value);
  }
  public updateControl({ keywords }: { keywords?: string[] }) {
    this.onChange(keywords);
  }

  public changeInputValue(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.onChange(value);
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched() {}

  public writeValue(value: string) {
    const modifiedValue =
      this.controlType === 'date'
        ? DateTime.fromISO(value).toISO({ suppressMilliseconds: true })
        : value;
    this.internalControl.setValue(modifiedValue);
  }
}
