import { VacanciesListUserActions } from '@app-core/vacancies-list/actions/vacancies-list.user.actions';
import { VacancySidepanelSystemActions } from '@app-core/vacancy-side-panel/actions/vacancy-sidepanel.system.actions';
import { VacancySidepanelUserActions } from '@app-core/vacancy-side-panel/actions/vacancy-sidepanel.user.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { UnaryOperator, VacancyComment, VacancyDetails } from '@app-shared/models';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { concat, mergeLeft, mergeRight, objOf, pathOr, pipe, propEq, reject } from 'ramda';

export interface VacancySidepanelState {
  vacancyId: number;
  currentTab: string;
  fullInfo?: VacancyDetails;
  isLoading: boolean;
  isSidepanelOpened: boolean;
}

export const initialState: VacancySidepanelState = {
  vacancyId: null,
  isLoading: false,
  isSidepanelOpened: true,
  currentTab: '',
};

const reducer = createReducer(
  initialState,
  on(MetadataActions.CleanReducers, () => initialState),
  on(VacanciesListUserActions.OpenVacancyDetailsSidepanelAction, (state, { vacancyId }) =>
    mergeRight(state, { isLoading: true, isSidepanelOpened: true, vacancyId }),
  ),
  on(
    VacancySidepanelUserActions.AddCommentAction,
    VacancySidepanelUserActions.DeleteCommentAction,
    mergeLeft({ isLoading: true }) as UnaryOperator<VacancySidepanelState, VacancySidepanelState>,
  ),
  on(
    VacancySidepanelSystemActions.AddCommentFailedAction,
    VacancySidepanelSystemActions.DeleteCommentFailedAction,
    VacancySidepanelSystemActions.RequestVacancyDetailsFailedAction,
    mergeLeft({ isLoading: false }) as UnaryOperator<VacancySidepanelState, VacancySidepanelState>,
  ),
  on(
    VacancySidepanelSystemActions.SetInitialTabAction,
    VacancySidepanelUserActions.ChangeTabAction,
    (state, { tab }) => mergeRight(state, { currentTab: tab }),
  ),
  on(
    VacancySidepanelUserActions.ClosePanelAction,
    mergeLeft({
      currentTab: 'comments',
      fullInfo: null,
      isSidepanelOpened: false,
      vacancyId: null,
    }) as UnaryOperator<VacancySidepanelState, VacancySidepanelState>,
  ),
  on(VacancySidepanelSystemActions.RequestVacancyDetailsAction, (state, { id }) =>
    mergeRight(state, { isLoading: true, isSidepanelOpened: true, vacancyId: id }),
  ),
  on(VacancySidepanelSystemActions.AddCommentSuccessfulAction, (state, { comment }) =>
    pipe(
      pathOr([], ['fullInfo', 'comments']),
      concat([comment]),
      objOf('comments'),
      mergeRight(state.fullInfo) as UnaryOperator<{ comments: VacancyComment[] }, VacancyDetails>,
      objOf('fullInfo'),
      mergeRight({ isLoading: false }),
      mergeRight(state),
    )(state),
  ),
  on(VacancySidepanelSystemActions.DeleteCommentSuccessfulAction, (state, { id }) => {
    const comments = pipe(pathOr([], ['fullInfo', 'comments']), reject(propEq(id, 'id')))(state);
    const fullInfo = mergeRight(state.fullInfo, { comments }) as VacancyDetails;
    return mergeRight(state, { isLoading: false, fullInfo });
  }),
  on(VacancySidepanelSystemActions.RequestVacancyDetailsSuccessAction, (state, { vacancy }) =>
    mergeRight(state, { fullInfo: vacancy, isLoading: false, vacancyId: vacancy.id }),
  ),
);

export function vacancySidePanelReducer(
  baseState: VacancySidepanelState,
  action: Action,
): VacancySidepanelState {
  return reducer(baseState, action);
}

export const selectVacancySidepanelStore =
  createFeatureSelector<VacancySidepanelState>('vacancySidepanel');
export const getVacancyPanelState = createSelector(
  selectVacancySidepanelStore,
  (store) => store?.isSidepanelOpened,
);
export const getCurrentVacancyId = createSelector(
  selectVacancySidepanelStore,
  (store) => store?.vacancyId,
);
export const getVacancyCurrentTab = createSelector(
  selectVacancySidepanelStore,
  (store) => store?.currentTab,
);
export const getFullVacancyInfo = createSelector(
  selectVacancySidepanelStore,
  (store) => store?.fullInfo || null,
);
export const getLoadingState = createSelector(
  selectVacancySidepanelStore,
  (store) => store?.isLoading,
);
