<div class="c-profile-counts-panel">
  @if (filesCount) {
    <div class="c-profile-counts-panel__element">
      <div class="c-profile-counts-panel__element-label">
        <i
          class="fas fa-paperclip c-profile-counts-panel__element-label-icon"
          aria-hidden="true"
        ></i>
        {{ filesCount }}
      </div>
      <div class="c-profile-counts-panel__element-info-container">
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
          (click)="openFileModal('cv')"
        >
          <h4 class="c-profile-counts-panel__count">{{ getCount('cvs') }}</h4>
          <span
            [matTooltip]="'profile-preview.profile-counts-panel.labels.cvs.tooltip' | translate"
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.cvs</span
          >
        </div>
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
          (click)="openFileModal('voice')"
        >
          <h4 class="c-profile-counts-panel__count">{{ getCount('voices') }}</h4>
          <span
            [matTooltip]="'profile-preview.profile-counts-panel.labels.vrs.tooltip' | translate"
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.vrs</span
          >
        </div>
      </div>
    </div>
  }
  @if (notifiedCount) {
    <div class="c-profile-counts-panel__element">
      <div class="c-profile-counts-panel__element-label">
        <span class="c-profile-counts-panel__element-label-text" translate
          >profile-preview.profile-counts-panel.labels.touches</span
        >
        {{ notifiedCount }}
      </div>
      <div class="c-profile-counts-panel__element-info-container">
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
          (click)="openSidepanel.emit(['notified'])"
        >
          <h4 class="c-profile-counts-panel__count">{{ notifiedCount }}</h4>
          <span
            [matTooltip]="'profile-preview.profile-counts-panel.labels.notified' | translate"
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.notified</span
          >
        </div>
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
          (click)="openSidepanel.emit(['notified'])"
        >
          <h4 class="c-profile-counts-panel__count">{{ openedCount }}</h4>
          <span
            [matTooltip]="'profile-preview.profile-counts-panel.labels.opened' | translate"
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.opened</span
          >
        </div>
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
          (click)="openSidepanel.emit(['notified'])"
        >
          <h4 class="c-profile-counts-panel__count">{{ clickedCount }}</h4>
          <span
            [matTooltip]="'profile-preview.profile-counts-panel.labels.clicks' | translate"
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.clicks</span
          >
        </div>
      </div>
    </div>
  }
  @if (listsCount && !notProcessed) {
    <div class="c-profile-counts-panel__element">
      <div class="c-profile-counts-panel__element-label">
        <span class="c-profile-counts-panel__element-label-text" translate>
          profile-preview.profile-counts-panel.labels.processed
        </span>
        {{ listsCount }}
      </div>
      <div
        class="c-profile-counts-panel__element-info-container"
        (click)="openSidepanel.emit(['activity', 'processing'])"
      >
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
        >
          <h4 class="c-profile-counts-panel__count">{{ longListCount }}</h4>
          <span
            [matTooltip]="
              'profile-preview.profile-counts-panel.labels.long-list.tooltip' | translate
            "
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.long-list</span
          >
        </div>
        <div
          class="c-profile-counts-panel__element-info c-profile-counts-panel__element-info--clickable"
        >
          <h4 class="c-profile-counts-panel__count">{{ shortListCount }}</h4>
          <span
            [matTooltip]="'profile-preview.profile-counts-panel.labels.processing' | translate"
            class="c-profile-counts-panel__count-label"
            translate
            >profile-preview.profile-counts-panel.labels.processing</span
          >
        </div>
      </div>
    </div>
  }
  @if (notProcessed) {
    <div
      class="c-profile-counts-panel__element c-profile-counts-panel__element--not-applies"
      (click)="openSidepanel.emit(['activity', 'processing'])"
    >
      <div class="c-profile-counts-panel__element-label">
        <span class="c-profile-counts-panel__element-label-text" translate
          >profile-preview.profile-counts-panel.labels.not-processed
        </span>
        {{ appliesCount }}
      </div>
    </div>
  }
</div>
