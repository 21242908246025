import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslatePipe } from '@ngx-translate/core';
import { CastToStringPipe, FormatDateTimePipe } from '@tsp-pipes';

@Component({
  imports: [
    CastToStringPipe,
    FormatDateTimePipe,
    MatCheckbox,
    MatIconButton,
    MatTooltip,
    TranslatePipe,
  ],
  selector: 'profile-preview-controls',
  templateUrl: './profile-preview-controls.component.html',
  styleUrls: ['./profile-preview-controls.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfilePreviewControlsComponent {
  @Input()
  public activeTab: string;
  @Input()
  public canBeSelected: boolean;
  @Input()
  public contactButtonTooltip: string;
  @Input()
  public isActiveSearchParams: boolean;
  @Input()
  public isContactButtonDisabled: boolean;
  @Input()
  public isExternalSearchPage: boolean;
  @Input()
  public isInternalProfile: boolean;
  @Input()
  public isProfileSelected: boolean;
  @Input()
  public isProfilesSearchPage: boolean;
  @Input()
  public isReviewed: boolean;
  @Input()
  public lastReviewDate: string;
  @Input()
  public profileName: string;
  @Input()
  public profileId: string;
  @Input()
  public showComment: boolean;
  @Input()
  public showControls = true;
  @Output()
  public editProfile = new EventEmitter<void>();
  @Output()
  public openNotificationModal = new EventEmitter<void>();
  @Output()
  public toggleSelection = new EventEmitter<boolean>();

  public getButtonTooltip(button: string) {
    if (!this.isInternalProfile) {
      return 'profile-preview.profile-preview.add-to-database';
    }
    const key = `profile-preview.profile-preview.${button}`;

    switch (button) {
      case 'comment':
        return key;
      case 'edit':
      case 'view':
      default:
        return `${key}-details.profile.tooltip`;
    }
  }
}
