<div class="c-profile-work-history">
  <div
    class="c-profile-work-history__title"
    [matTooltip]="'profile-preview.profile-work-history.title' | translate"
  >
    <i class="fas fa-briefcase" aria-hidden="true"></i>
  </div>
  <div class="c-profile-work-history__row">
    <div
      class="c-profile-work-history__projects"
      [ngClass]="{ 'c-profile-work-history__projects--show-all': showAllProjects }"
    >
      @for (project of externalProjects; track project; let i = $index) {
        @if ((showAllProjects || i === 0) && (project.title || project.description)) {
          <div class="c-profile-work-history__project">
            <span
              class="c-profile-work-history__project-time-range--title"
              appHighlightSearch
              [searchParams]="searchParamsForHighlight"
              [innerText]="project.title ? project.title : (project.description | slice: 0 : 50)"
            ></span>
            @if (project.description) {
              <div class="c-profile-work-history__info-data">
                <span class="c-profile-work-history__info-label">
                  <i class="fas fa-info-circle" aria-hidden="true"></i>
                </span>
                <div class="c-profile-work-history__info--full-info">
                  <span
                    class="c-profile-work-history__text"
                    appHighlightSearch
                    [searchParams]="searchParamsForHighlight"
                    [innerText]="project.description"
                  ></span>
                </div>
              </div>
            }
          </div>
        }
      }
      @for (project of projects; track project; let i = $index) {
        @if (showAllProjects || i < 2) {
          <div class="c-profile-work-history__project">
            <span
              class="c-profile-work-history__project-position"
              [ngClass]="{ 'c-profile-work-history__project-position--short': !showAllProjects }"
              appHighlightSearch
              [searchParams]="searchParamsForHighlight"
              [innerText]="getProjectPosition(project)"
            >
            </span>
            <div class="c-profile-work-history__project-company-and-time-range">
              {{ (project.company ? 'profile-preview.profile-work-history.at' : '') | translate }}
              @if (project.company) {
                <span class="c-profile-work-history__project-company">
                  {{ project.company }}
                </span>
              }
              <span class="c-profile-work-history__project-time-range"
                >{{ project.date }} {{ getProjectExperience(project) }}</span
              >
            </div>
          </div>
        }
      }
    </div>
    @if (renderButtonProjects && showMoreButton) {
      <button
        class="btn c-profile-work-history__button"
        [ngClass]="{ 'c-profile-work-history__button--activated': showAllProjects }"
        (click)="onShowProjects()"
      >
        {{ toggleTooltip | translate }}
      </button>
    }
  </div>
</div>
