<mat-form-field class="c-tag-input" [ngClass]="innerThemeValue" floatLabel="auto">
  <mat-chip-grid #tagsList class="c-tag-input__tags-container" aria-label="Tags selection">
    @for (tag of selectedTags; track trackById($index, tag)) {
      <mat-chip class="c-tag-input__tag" [removable]="true" (removed)="removeTag(tag)">
        {{ getTagName(tag) }}
        <button matChipRemove class="c-tag-input__delete-icon" aria-hidden="true">&times;</button>
      </mat-chip>
    }
    <input
      class="c-tag-input__input-control"
      [placeholder]="secondaryPlaceholder | translate"
      #tagInput
      [formControl]="tagControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="tagsList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (keydown)="onInputKeyPress($event)"
      (matChipInputTokenEnd)="addTag($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
    @for (item of dictionaryItems$ | async; track trackById($index, item)) {
      <mat-option
        class="c-tag-input__option"
        [value]="item"
        [innerHTML]="item | showSynonyms | autocompleteItemHighlight: tagControl.value"
      >
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
