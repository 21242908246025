import { FormControl } from '@angular/forms';
import { isEmpty, isNil } from 'ramda';

export function lettersValidator(control: FormControl<string>) {
  const value: string = control.value;
  const letterExp = /[^\p{L}\p{M}\p{Pc}\p{Pd}\p{Z}\p{C}\p{Sk}.',/]/giu;

  return isNil(value) || isEmpty(value) || isEmpty(value.trim()) || !letterExp.test(value)
    ? null
    : { lettersOnly: { value } };
}
