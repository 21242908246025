import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [NgClass, MatTooltip, TranslateModule],
  selector: 'short-list-send-letter-button',
  templateUrl: './send-letter-button.component.html',
  styleUrls: ['./send-letter-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SendLetterButtonComponent {
  @Input()
  public hasEmail: boolean;
  @Output()
  public sendLetter = new EventEmitter<void>();
}
