import { Routes, UrlSegment } from '@angular/router';
import {
  clientAccessCheckerGuard,
  isLoggedGuard,
  moduleCleanerDeactivateGuard,
  notLoggedGuard,
} from '@app-shared/guards';
import { cleanAuthResolver } from '@app-shared/resolvers';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { head, includes } from 'ramda';
import { newEntityEffects, newEntityReducer } from './core/add-new-entity-popup';
import { ProxyRouteComponent } from './proxy-route.component';

export function localeMatcher(segments: UrlSegment[]): { consumed: UrlSegment[] } | null {
  const allowedLocales = ['uk', 'ru', 'en'];
  const segment = head(segments);
  if (!segment) {
    return null;
  }

  return includes(segment.path, allowedLocales) ? { consumed: [segment] } : null;
}

export const routes: Routes = [
  {
    path: 'popup',
    outlet: 'extras',
    canActivate: [clientAccessCheckerGuard, isLoggedGuard],
    component: ProxyRouteComponent,
    children: [
      {
        path: 'add-new',
        data: {
          mode: 'CREATE',
        },
        loadChildren: () => import('./core/add-new-entity-popup').then((m) => m.routes),
      },
      {
        path: 'edit/:id',
        data: {
          mode: 'EDIT',
        },
        loadChildren: () => import('./core/add-new-entity-popup').then((m) => m.routes),
      },
      {
        path: 'clone/:id',
        data: {
          mode: 'CLONE',
        },
        loadChildren: () => import('./core/add-new-entity-popup').then((m) => m.routes),
      },
    ],
    canDeactivate: [moduleCleanerDeactivateGuard],
    providers: [
      provideState({ name: 'newEntity', reducer: newEntityReducer }),
      provideEffects(newEntityEffects),
    ],
  },
  {
    path: 'logout',
    resolve: [cleanAuthResolver],
    loadChildren: () => import('./core/auth').then((m) => m.routes),
  },
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: '/uk/login',
  },
  {
    path: 'unsubscribe/:token',
    loadChildren: () => import('./core/subscription').then((m) => m.routes),
  },
  {
    matcher: localeMatcher,
    children: [
      {
        path: 'login',
        canActivate: [notLoggedGuard],
        loadChildren: () => import('./core/auth').then((m) => m.routes),
      },
      {
        path: 'registration',
        canActivate: [notLoggedGuard],
        loadChildren: () => import('./core/auth').then((m) => m.routes),
      },
      {
        path: 'plugin-tutorial',
        canActivate: [isLoggedGuard],
        loadChildren: () => import('./core/auth').then((m) => m.routes),
      },
      {
        path: 'success-subscription',
        loadChildren: () => import('./core/success-subscription').then((m) => m.routes),
      },
      {
        path: '',
        canActivate: [isLoggedGuard],
        loadChildren: () => import('./core/root').then((m) => m.routes),
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/uk/login',
  },
  {
    path: '**',
    loadChildren: () => import('./core/page-not-found').then((m) => m.routes),
  },
];
