import { DirectoryCustomFieldResponse, Entity } from '.';

export interface Credentials {
  login: string;
  password: string;
}

export interface RecruiterInfo {
  email: string;
  fieldValues?: DirectoryCustomFieldResponse[];
  firstLogin: string;
  firstName: string;
  fullName: string;
  id: number;
  image?: string;
  lastLogin: string;
  lastName?: string;
  profileSummary?: string;
  recruiterAccess?: RecruiterAccesses;
  recruiterDepartmentAccess: { id: string; name: string }[];
  skype?: string;
  subordinates: RecruiterInfo[];
  telephone?: string;
  webSite: string | null;

  // rabotaUa?: Credentials;
  // workUa?: Credentials;
}

export interface RecruiterPayload
  extends Omit<
    RecruiterInfo,
    'firstLogin' | 'lastLogin' | 'recruiterDepartmentAccess' | 'subordinates'
  > {
  recruiterDepartment: string[];
  subordinates: number[];
}

export interface RecruiterAccesses {
  id?: number;
  viewPortalProfiles?: boolean;
  viewPortalClients?: boolean;
  viewPortalProjects?: boolean;
  deleteComments?: boolean;
  deleteVacancy?: boolean;
  dashboardPage?: boolean;
  editVacancyCloseDate?: boolean;
  editVacancyInformation?: boolean;
  clientsPage?: boolean;
  searchConsolePage?: boolean;
  teammatesPage?: boolean;
  vacancyNotificationsTab?: boolean;
  vacancyStaticTab?: boolean;
  viewProfilesComments?: boolean;
  viewProfilesFiles?: boolean;
  viewProfilesNotifications?: boolean;
  viewProfilesShortlists?: boolean;
  viewVacancyComments?: boolean;
  showProfileContactDetails?: boolean;
  showProfileSocialLinks?: boolean;
  showProfileSecondName?: boolean;
  showProfileCustomFields?: boolean;
  showCandidateStatusCustomFields?: boolean;
  showSalary?: boolean;
  showVacancyCustomFields?: boolean;
  showClientsContactsDetails?: boolean;
  showClientsCustomFields?: boolean;
}

export type NotificationRuleChannel = 'push' | 'email';
export enum NotificationRuleCode {
  CREATE_VACANCY = 'create_vacancy',
  CHANGE_VACANCY_ASSIGNMENT = 'change_vacancy_assignment',
  CHANGE_VACANCY_TEAM = 'change_vacancy_recruitment_team',
}
export enum NotificationRuleFilter {
  BID_STATUS = 'bidStatus',
  RECRUITER = 'recruiter',
  VACANCY = 'vacancy',
  VACANCY_STATUS = 'vacancyStatus',
}

export interface RecruiterNotificationRule extends Entity {
  recruiterId?: number;
  portalId?: number;
  ruleId: number;
  channel: NotificationRuleChannel;
  filterBy: NotificationRuleFilter;
  filterValue: string;
}

export interface RecruiterNotificationsResponse {
  items: RecruiterNotificationRule[];
  meta: RecruiterNotificationRulesMeta;
}
export interface RecruiterNotificationRulesMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface DefaultNotificationRule extends Entity {
  name: string;
  code: NotificationRuleCode;
  sendgridTemplateId: string;
  availableFilters: NotificationRuleFilter[];
}

export interface RecruiterNotificationRulePayload {
  recruiterId: number;
  portalId: number;
  ruleId: number;
  channel: NotificationRuleChannel;
  filterBy: NotificationRuleFilter;
  filterValue: string;
}
export interface ExternalCredentials {
  id?: number;
  breezyLogin?: string;
  breezyPassword?: string;
  breezyCompanyId?: string;
  expandiApiKey?: string;
  expandiApiSecret?: string;
}

export interface TeammateUserType {
  accesses: RecruiterAccesses & { [key: string]: boolean };
  default?: boolean;
  key: 'full' | 'recruiter' | 'sourcer' | 'custom' | 'client' | 'view';
}
