<h2 mat-dialog-title class="c-tsp-popup__title" translate>
  profile-short-list.short-list-edit-dialog.title
</h2>
<mat-dialog-content>
  <form class="c-tsp-mat-form" [formGroup]="bidForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      @if (showDatepicker) {
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>profile-short-list.short-list-edit-dialog.interview.label</mat-label>
          <input
            class="c-tsp-mat-form-field-input"
            matInput
            formControlName="interviewAt"
            readonly="true"
            [owlDateTimeTrigger]="dateTime"
            [owlDateTime]="dateTime"
            [placeholder]="'profile-short-list.short-list-edit-dialog.date.placeholder' | translate"
          />
          <button
            matSuffix
            mat-icon-button
            [matTooltip]="'profile-short-list.short-list-edit-dialog.reset' | translate"
            (click)="clearInput($event, 'interviewAt')"
          >
            <i class="fas fa-times" aria-hidden="true"> </i>
          </button>
          <owl-date-time
            [startAt]="interviewStartTime"
            [stepMinute]="5"
            [firstDayOfWeek]="1"
            #dateTime
          >
          </owl-date-time>
        </mat-form-field>
      }
    </div>
    @if (selectedStatusCode === 'not_accepted') {
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>profile-short-list.short-list-edit-dialog.reason</mat-label>
          <mat-select
            formControlName="reasonRejectId"
            [placeholder]="
              'profile-short-list.short-list-reject-dialog.reason.placeholder' | translate
            "
          >
            @for (reason of data.rejectReasons; track reason) {
              <mat-option [value]="reason.id" [title]="reason.name">
                {{ reason.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    <div class="c-tsp-mat-form-field__row c-bid-popup__source">
      <app-autocomplete-select
        [dictionary]="shortListSources$ | async"
        [control]="bidForm | getFormControl: 'source_id'"
        [searchFromBeginning]="false"
        class="c-bid-popup__source-selector"
        customClass="c-tsp-mat-form-field-autocomplete c-tsp-mat-form-field--with-label c-tsp-mat-form-field"
        [placeholder]="'profile-short-list.short-list-edit-dialog.source.placeholder' | translate"
        label="profile-short-list.short-list-edit-dialog.source.label"
        labelType="always"
      >
      </app-autocomplete-select>
      <button
        class="c-bid-popup__source-add-button"
        mat-button
        [matTooltip]="'profile-short-list.short-list-edit-dialog.source.add' | translate"
        (click)="showAddSourceBlock = !showAddSourceBlock"
      >
        <i class="fas fa-plus" aria-hidden="true"></i>
      </button>
      <short-list-add-new-source
        class="c-bid-popup__source-add-popup"
        [ngClass]="{ 'c-bid-popup__source-add-popup--visible': showAddSourceBlock }"
        (onClose)="showAddSourceBlock = !showAddSourceBlock"
      >
      </short-list-add-new-source>
    </div>
    @if (data.hasAccessToSalary) {
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>profile-short-list.short-list-edit-dialog.salary.label</mat-label>
          <span matPrefix class="c-bid-popup__cost-value">$ &nbsp;</span>
          <input
            matInput
            class="c-tsp-mat-form-field-input"
            formControlName="salary"
            [placeholder]="
              'profile-short-list.short-list-edit-dialog.salary.placeholder' | translate
            "
          />
          @if (controlHasErrorType('salary', 'numberOnly')) {
            <mat-error>
              <span translate>profile-short-list.short-list-edit-dialog.numbers-only</span>
            </mat-error>
          }
        </mat-form-field>
      </div>
    }
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>profile-short-list.short-list-edit-dialog.comment.label</mat-label>
        <textarea
          matInput
          formControlName="comment"
          [placeholder]="'profile-short-list.short-list-edit-dialog.comment.label' | translate"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        >
        </textarea>
      </mat-form-field>
    </div>
    @if (data.hasAccessToStatusCustomFields && data.statusesCustomFields?.length) {
      <div class="c-tsp-mat-form-field__row c-bid-popup__row">
        <app-custom-fields-group
          class="c-bid-popup__group"
          [fieldControls]="data.statusesCustomFields"
          formControlName="fieldValues"
        >
        </app-custom-fields-group>
      </div>
    }
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>profile-short-list.short-list-edit-dialog.cv-sent.label</mat-label>
        <input
          class="c-tsp-mat-form-field-input"
          matInput
          formControlName="cvSentDate"
          readonly="true"
          [owlDateTimeTrigger]="cvSentDate"
          [owlDateTime]="cvSentDate"
          [placeholder]="'profile-short-list.short-list-edit-dialog.date.placeholder' | translate"
        />
        <owl-date-time #cvSentDate class="c-tsp-date-range-selector__dialog" pickerType="calendar">
        </owl-date-time>
      </mat-form-field>
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>profile-short-list.short-list-edit-dialog.hired-at.label</mat-label>
        <input
          class="c-tsp-mat-form-field-input"
          matInput
          formControlName="hiredAt"
          readonly="true"
          [owlDateTimeTrigger]="hiredAt"
          [owlDateTime]="hiredAt"
          [placeholder]="'profile-short-list.short-list-edit-dialog.date.placeholder' | translate"
        />
        <owl-date-time #hiredAt class="c-tsp-date-range-selector__dialog" pickerType="calendar">
        </owl-date-time>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>profile-short-list.short-list-edit-dialog.rejected.label</mat-label>
        <input
          class="c-tsp-mat-form-field-input"
          matInput
          formControlName="rejectedDate"
          readonly="true"
          [owlDateTimeTrigger]="rejectedDate"
          [owlDateTime]="rejectedDate"
          [placeholder]="'profile-short-list.short-list-edit-dialog.date.placeholder' | translate"
        />
        <owl-date-time
          #rejectedDate
          class="c-tsp-date-range-selector__dialog"
          pickerType="calendar"
        >
        </owl-date-time>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form">
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
    (click)="closePopup()"
  >
    <span translate>profile-short-list.short-list-edit-dialog.close.button</span>
  </button>
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
    [ngClass]="{ 'c-tsp-mat-popup-form__button--disabled': bidForm.invalid }"
    [disabled]="bidForm.invalid"
    (click)="saveBid()"
  >
    <span translate>profile-short-list.short-list-edit-dialog.button.save</span>
  </button>
</mat-dialog-actions>
