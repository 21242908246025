<div class="c-profiles-list-control-bar c-tsp-mat-list-control-bar-form c-tsp-mat-form-all">
  <div class="c-profiles-list-control-bar__left-column">
    <div class="c-profiles-list-control-bar__buttons-container">
      <ng-content></ng-content>
    </div>
  </div>
  @if (sortingEnabled) {
    <div class="text-right c-profiles-list-control-bar__right-column">
      <div class="c-profiles-list-control-bar__view">
        <span translate>search-console.control-bar.view</span>
        @if (sliderEnabled) {
          <mat-button-toggle-group
            [hideSingleSelectionIndicator]="true"
            [hideMultipleSelectionIndicator]="true"
            class="c-profiles-list-control-bar__slide-wrapper"
            (change)="changeSlider()"
          >
            <mat-button-toggle
              [ngClass]="{ 'c-profiles-list-control-bar__slide--active': !simpleCardViewState }"
            >
              <i class="fas fa-address-card" aria-hidden="true"></i>
            </mat-button-toggle>
            <mat-button-toggle
              [ngClass]="{ 'c-profiles-list-control-bar__slide--active': simpleCardViewState }"
            >
              <i class="fas fa-server" aria-hidden="true"></i>
            </mat-button-toggle>
          </mat-button-toggle-group>
        }
      </div>
      @if (sortOptions?.length !== 0) {
        <div class="c-profiles-list-control-bar__control">
          <span translate>search-console.sorting.label</span>
          <mat-form-field class="c-tsp-mat-form-field c-tsp-mat-form-field--filter">
            <mat-select
              [formControl]="sortingControl"
              placeholder=""
              (selectionChange)="changeSorting()"
              panelClass="c-tsp-mat-select-panel-default"
            >
              @for (option of sortOptions; track option) {
                <mat-option class="c-tsp-mat-form-field-option" [value]="option.value">
                  {{ option.name | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
    </div>
  }
</div>
