import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { FloatLabelType, MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import {
  AllowedOptionType,
  ClientFull,
  DictionaryItem,
  SimpleRecruiter,
  UnaryOperator,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { CastToRecruiterPipe, GetInitialsPipe } from '@tsp-pipes';
import {
  anyPass,
  equals,
  filter,
  includes,
  propSatisfies,
  replace,
  sortBy,
  test,
  unless,
} from 'ramda';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MultipleSearchComponent } from '../mat-select-search/mat-select-search.component';

@Component({
  imports: [
    CastToRecruiterPipe,
    GetInitialsPipe,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MultipleSearchComponent,
    NgClass,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-multiple-select-search',
  styleUrls: ['./multiple-select-search.component.less'],
  templateUrl: 'multiple-select-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MultipleSelectSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public set options(data: AllowedOptionType[]) {
    if (!data) {
      return;
    }

    if (!equals(data, this.defaultOptions)) {
      this.displayedOptions = [...data];
    }
    this.defaultOptions = data;
  }
  @Input()
  public control: UntypedFormControl;
  @Input()
  public disabledOptionsIds: number[] = [];
  @Input()
  public floatLabel: FloatLabelType = 'always';
  @Input()
  public label = 'shared.multiple-select-search.label';
  @Input()
  public placeholder = 'shared.multiple-select-search.placeholder';
  @Input()
  public placeholderLabel = 'shared.multiple-select-search.placeholder.label';
  @Input()
  public type = 'clients';
  @Input()
  public triggerText = '';
  @Input()
  public useTrigger = false;
  @Input()
  public withImage = false;
  @Output()
  public filtersChanged: EventEmitter<void> = new EventEmitter();
  public displayedOptions: AllowedOptionType[];
  public defaultOptions: AllowedOptionType[] = [];
  public filterInput: UntypedFormControl = new UntypedFormControl();
  public delayTimeout = 250;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  public ngOnChanges() {
    this.sortOptions();
  }

  public ngOnInit(): void {
    this.filterInput.valueChanges
      .pipe(debounceTime(this.delayTimeout), takeUntil(this.ngUnsubscribe))
      .subscribe((value: string) => {
        this.filterOptions(value);
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public displayedName(option: AllowedOptionType): string {
    if (this.type === 'clients') {
      const client = option as ClientFull;
      const teamName = client.teamName ? ` (${client.teamName})` : '';
      return `${client.firstName || ''} ${client.lastName || ''}${teamName}`;
    } else if (this.type === 'recruiters') {
      const recruiter = option as SimpleRecruiter;
      return `${recruiter.firstName || ''} ${recruiter.lastName || ''}`;
    } else {
      return `${(option as DictionaryItem).name}`;
    }
  }

  public isOptionDisabled(option: AllowedOptionType): boolean {
    return includes(option.id, this.disabledOptionsIds);
  }

  public controlHasErrorType(errorName: string): boolean {
    if (!this.control) {
      return false;
    }
    return (
      this.control.touched &&
      (this.control.errors ? (this.control.errors[errorName] as boolean) : false)
    );
  }

  public sortOptions() {
    if (this.control?.value && this.displayedOptions) {
      this.displayedOptions = sortBy(
        (option) => !includes(option.id, this.control.value as (string | number)[]),
        this.displayedOptions,
      );
    }
  }

  public valueChange() {
    this.sortOptions();
    this.filtersChanged.emit();
  }
  private filterOptions(value: string) {
    if (!this.defaultOptions) {
      return;
    }
    const escapedValue = replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&', value);
    const searchRegExp = new RegExp(escapedValue, 'gi');
    let currentFilter = propSatisfies(test(searchRegExp), 'name');

    if (this.type === 'clients') {
      currentFilter = anyPass([
        propSatisfies(test(searchRegExp), 'firstName'),
        propSatisfies(test(searchRegExp), 'lastName'),
        propSatisfies(test(searchRegExp), 'teamName'),
      ]);
    }
    if (this.type === 'recruiters') {
      currentFilter = anyPass([
        propSatisfies(test(searchRegExp), 'firstName'),
        propSatisfies(test(searchRegExp), 'lastName'),
      ]);
    }

    this.displayedOptions = unless(
      () => !value,
      filter(currentFilter as UnaryOperator<AllowedOptionType, boolean>) as UnaryOperator<
        AllowedOptionType[],
        AllowedOptionType[]
      >,
    )(this.defaultOptions);
    this.sortOptions();
  }
}
