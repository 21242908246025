import { FormControl } from '@angular/forms';
import { isEmpty, isNil, test } from 'ramda';

export function emailMatchValidator(control: FormControl<string>) {
  const value: string = control.value;

  return isNil(value) ||
    isEmpty(value) ||
    isEmpty(value.trim()) ||
    test(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g, value)
    ? null
    : { emailMatch: { value: control.value } };
}
