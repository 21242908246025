import { Directive, Output, HostListener, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { UserClick } from '@app-shared/models';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appHandleUserClick]',
  standalone: true,
})
export class HandleUserClickDirective implements OnInit, OnDestroy {
  @Output()
  public userClick = new EventEmitter<UserClick>();
  public click = new Subject<void>();
  public clickCount = 0;
  public ngUnsubscribe = new Subject<void>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    event.stopPropagation();
    this.click.next();
  }

  public ngOnInit(): void {
    this.click
      .pipe(
        tap(() => this.clickCount++),
        debounceTime(200),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        const event = this.clickCount < 2 ? 'single' : 'double';
        this.userClick.emit(event);
        this.clickCount = 0;
      });
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
