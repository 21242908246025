export const interceptorRouteRegexps = [
  {
    name: 'dictionaries',
    regexp: /(^\/dictionaries|^\/v2\/dictionaries|^\/candidate-statuses|^\/reasons-reject)/,
  },
  {
    name: 'metadata',
    regexp: /(^\/metadata|^\/currentUser)/,
  },
  {
    name: 'profiles',
    regexp: /^\/profiles/,
  },
  {
    name: 'recruiters',
    regexp: /^\/recruiters/,
  },
  {
    name: 'vacancies',
    regexp: /^\/my-vacancies/,
  },
  {
    name: 'vacanciesList',
    regexp: /^\/vacancies/,
  },
  {
    name: 'turbohiring',
    regexp: /^\/?id/,
  },
];

export const interceptorInternalHeaders = [
  'I-Force-Refresh',
  'I-File-Uploading',
  'I-Auth-Request',
  'I-Anonymous-Request',
  'i-force-refresh',
  'i-file-uploading',
  'i-auth-request',
  'i-anonymous-request',
];
