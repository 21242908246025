import { createAction, props, union } from '@ngrx/store';

import { FullProfile, Profile } from '@app-shared/models';

const CloseSidepanelAfterUpdateSearchModelAction = createAction(
  '[Vacancy Selector] Close sidepanel after updating search model on profiles list page',
);
const RefreshSidepanelProfileAfterAddingToLongListAction = createAction(
  '[Profile Sidepanel] Refresh profile data in sidepanel after adding to long list public profile',
);
const RefreshSidepanelProfileAfterEditingAction = createAction(
  '[Profile Sidepanel] Refresh profile data in sidepanel after successfully editing',
);
const RequestProfileDetailsFromSidepanelAction = createAction(
  '[Profile Sidepanel Guard] Request profile data for sidepanel',
  props<{ id: string }>(),
);
const RequestProfileDetailsFailedAction = createAction(
  '[Profile Sidepanel | Profiles API] Error happened during requesting profile details for sidepanel',
);
const RequestProfileDetailsSuccessAction = createAction(
  '[Profile Sidepanel | Profiles API] Profile details for sidepanel saved to store',
  props<{ profile: FullProfile; isRefresh: boolean }>(),
);
const RequestProfileShortInfoAction = createAction(
  '[Profile Short Info Resolver] Search profile short info in profiles store',
  props<{ id: string }>(),
);
const ResolveProfileShortInfoAction = createAction(
  '[Profile Short Info Resolver] Save profile short info into profile sidepanel store',
  props<{ profile: Profile | FullProfile }>(),
);
const SetActivitiesTabAction = createAction(
  '[Profile Sidepanel Activities Tab Resolver] Set sub tab in Activities tab in the sidepanel',
  props<{ tab: string }>(),
);
const SetRelatedTabAction = createAction(
  '[Profile Sidepanel Related Tab Resolver] Set sub tab in Related tab in the sidepanel',
  props<{ tab: string }>(),
);
const SetSidepanelStateAction = createAction(
  '[Profile Sidepanel Initial Resolver] Set sidepanel state and preset tab',
  props<{ tab: string }>(),
);
const ToggleSidepanelLoadingStateAction = createAction(
  '[Profile Sidepanel] Toggle loading state for profile sidepanel',
  props<{ isLoading: boolean }>(),
);

const actions = {
  CloseSidepanelAfterUpdateSearchModelAction,
  RefreshSidepanelProfileAfterAddingToLongListAction,
  RefreshSidepanelProfileAfterEditingAction,
  RequestProfileDetailsFromSidepanelAction,
  RequestProfileDetailsFailedAction,
  RequestProfileDetailsSuccessAction,
  RequestProfileShortInfoAction,
  ResolveProfileShortInfoAction,
  SetActivitiesTabAction,
  SetRelatedTabAction,
  SetSidepanelStateAction,
  ToggleSidepanelLoadingStateAction,
};

const profileSidepanelSystemActions = union(actions);

export type ProfileSidepanelSystemActionsType = typeof profileSidepanelSystemActions;

export const ProfileSidepanelSystemActions = actions;
