import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { RouterActions } from '@app-shared/actions/router.actions';
import { splitName } from '@app-shared/functions/utilities/utilities';
import {
  AllDictionaries,
  DictionaryItem,
  FullProfile,
  Locales,
  Profile,
  ProfilesSources,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { Store } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { FallbackSrcWidthDirective, HighlightSearchWordsDirective } from '@tsp-directives';
import {
  CastToProfilePipe,
  ConvertIdToNamePipe,
  FormatDateTimePipe,
  GetInitialsPipe,
  PluralizeTextPipe,
} from '@tsp-pipes';
import { DateTime, Duration } from 'luxon';
import {
  includes,
  isEmpty,
  isNil,
  join,
  lte,
  negate,
  objOf,
  path,
  pipe,
  pluck,
  prop,
  propOr,
  unless,
} from 'ramda';

@Component({
  imports: [
    CastToProfilePipe,
    ConvertIdToNamePipe,
    FallbackSrcWidthDirective,
    FormatDateTimePipe,
    GetInitialsPipe,
    HighlightSearchWordsDirective,
    MatBadge,
    MatTooltip,
    NgClass,
    NgStyle,
    PluralizeTextPipe,
    RouterLink,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'profile-personal-info',
  templateUrl: './profile-personal-info.component.html',
  styleUrls: ['./profile-personal-info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfilePersonalInfoComponent {
  @Input()
  public dictionaries: Partial<AllDictionaries> = {};
  @Input()
  public hasAccessToSecondName: boolean;
  @Input()
  public isExternalSearchPage?: boolean;
  @Input()
  public profile: Profile;
  @Input()
  public activeTab: string;
  @Input()
  public showControls = false;
  @Input()
  public showLocation = true;
  @Input()
  public searchParamsForHighlight: { [key: string]: string[] };
  @Input()
  public userLocale: Locales;
  @Output()
  public onAvatarClick = new EventEmitter();

  constructor(private readonly store$: Store<State>) {}

  public get externalProfileLink(): string {
    return `/${this.userLocale}/profile/${this.profile.id}/details`;
  }
  public get hasEnglishLevel(): boolean {
    return !isNil(this.englishLevel);
  }
  public get englishLevel(): number | null {
    return prop('englishLevel', this.profile);
  }
  public get englishLevels(): DictionaryItem[] {
    return propOr([], 'englishLevels', this.dictionaries);
  }
  public get isExternalProfileLinkShown() {
    return (
      !includes(this.activeTab, ['profilePage']) &&
      !this.isExternalSearchPage &&
      this.profile.isPrivate
    );
  }
  public get isLongName(): boolean {
    return pipe(propOr('', 'name'), (str: string) => str.length, lte(50))(this.profile);
  }
  public get isProfileClickable() {
    return !includes(this.activeTab, [ProfilesSources.EXTERNAL, ProfilesSources.PROFILES]);
  }
  public get personAge(): number {
    return this.profile.birthday
      ? negate(DateTime.fromISO(this.profile.birthday).diffNow(['year', 'month']).get('year'))
      : null;
  }
  public get workExperience(): Partial<{ years: number; months: number }> {
    return Duration.fromObject({ months: this.profile.experience })
      .shiftTo('years', 'months')
      .toObject();
  }
  public get previousCities(): string[] {
    return pipe(
      propOr([], 'previousCities') as UnaryOperator<Profile | FullProfile, DictionaryItem[]>,
      unless(isEmpty, pluck('name')) as UnaryOperator<DictionaryItem[] | null, string[]>,
    )(this.profile);
  }
  public get previousCitiesNames(): { cities: string } {
    return pipe(join(', '), objOf('cities'))(this.previousCities);
  }
  public get previousCitiesTooltip(): string {
    return this.previousCities
      ? 'profiles-common.profile-personal-info.cities.past'
      : 'profiles-common.profile-personal-info.cities.empty';
  }
  public get profileSource(): number {
    return path(['source', 'id'], this.profile);
  }
  public get sources(): DictionaryItem[] {
    return propOr([], 'sources', this.dictionaries);
  }

  public avatarClick() {
    if (this.isExternalSearchPage || !this.profile.isPrivate) {
      return;
    }
    this.onAvatarClick.emit();
  }

  public editProfile() {
    this.store$.dispatch(
      RouterActions.OpenEditPopupAction({
        path: ['popup', 'edit', this.profile.id, 'talent'],
      }),
    );
  }

  public isStringLong(str: string): boolean {
    return str.length > 15;
  }

  public get profileColor(): string {
    if (this.profile.image) {
      return '#fff';
    }
    return this.profile.color;
  }

  public get profileName(): { firstName: string; lastName: string } {
    return splitName(this.profile.name || '', 'firstName', 'lastName') as {
      firstName: string;
      lastName: string;
    };
  }

  public get profilePosition(): string {
    return this.profile.position;
  }
}
