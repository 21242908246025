import { Pipe, PipeTransform } from '@angular/core';
import { append, curry, join, map, of, pipe, prepend, replace, split, test, when } from 'ramda';

@Pipe({
  name: 'autocompleteItemHighlight',
  standalone: true,
})
export class AutocompleteItemHighlightPipe implements PipeTransform {
  public transform(convertedNames: string[], query: string): string {
    if (!query) {
      return convertedNames.join('');
    }

    return pipe(map(curry(this.checkElement.bind(this))(query)), join(''))(convertedNames);
  }

  private checkElement(query: string, element: string): string {
    const keyword = replace(/^!/, '', query);

    const [openTag, rawText, closeTag] = split(/(>.*<)/, element);

    const openSpan = `${openTag}>`;
    const closeSpan = `<${closeTag}`;
    const text = rawText.slice(1, -1);
    const regex = pipe(
      replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'),
      (value) => new RegExp(value, 'ig'),
    )(keyword);

    return pipe(
      replace(/incl/, '####'),
      when(test(regex), replace(regex, '<span class="autocomplete-item-highlight">$&</span>')),
      replace(/####/, 'incl'),
      of(Array),
      append(closeSpan),
      prepend(openSpan),
      join(''),
    )(text);
  }
}
