import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntitiesList,
  SearchParams,
  Vacancy,
  VacancyActivities,
  VacancyCreatePayload,
  VacancyDetails,
  VacancyDictionary,
  VacancyEditPayload,
  VacancySimpleUpdatePayload,
  VacancySimpleUpdateType,
  VacancySuggestedParams,
} from '@app-shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDownloadService } from '../file-download/file-download.service';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({ providedIn: 'root' })
export class VacancyService {
  constructor(
    private readonly http: HttpClient,
    private readonly fileService: FileDownloadService,
  ) {}

  public getSimpleVacancies(keyword?: string): Observable<VacancyDictionary[]> {
    return this.http.get<VacancyDictionary[]>('/vacancies/my', {
      params: HttpHelperService.serializeParams({ keyword }),
    });
  }

  public getVacancies(params: SearchParams): Observable<EntitiesList<Vacancy>> {
    return this.http.get<EntitiesList<Vacancy>>('/vacancies', {
      params: HttpHelperService.serializeParams(params),
    });
  }

  public getNewVacanciesCount(): Observable<number> {
    return this.http.get<{ count: number }>('/vacancies/new-count').pipe(map(({ count }) => count));
  }

  public getVacancy(id: number | string): Observable<VacancyDetails> {
    return this.http.get<VacancyDetails>(`/vacancy/${id}`);
  }

  public getActivitiesOfVacancy(
    id: number,
    params: Partial<{ startDate: string; endDate: string }> = {},
  ): Observable<VacancyActivities> {
    return this.http.get<VacancyActivities>(`/vacancy/statistic/${id}`, { params });
  }

  public saveNewVacancy(data: VacancyCreatePayload): Observable<VacancyDetails> {
    return this.http.post<VacancyDetails>('/vacancy', data);
  }

  public partialUpdateVacancy(
    payload: VacancySimpleUpdatePayload,
    type: VacancySimpleUpdateType,
  ): Observable<VacancyDetails> {
    return this.http.put<VacancyDetails>(`/vacancy/${payload.id}/${type}`, payload);
  }

  public updateVacancy(
    payload: VacancyEditPayload | VacancySimpleUpdatePayload,
  ): Observable<VacancyDetails> {
    return this.http.put<VacancyDetails>(`/vacancy/${payload.id}`, payload);
  }

  public deleteVacancy(id: number): Observable<string[]> {
    return this.http.delete<string[]>(`/vacancy/${id}`);
  }

  public exportVacancyData(projectId: number, query: SearchParams): Observable<{ result: true }> {
    return this.http.post(`/candidates/${projectId}/export`, query, { responseType: 'blob' }).pipe(
      map((file) => {
        const fileName = `candidates-${projectId}.xlsx`;

        this.fileService.saveFile(file, fileName);

        return { result: true };
      }),
    );
  }

  public getLastSearchByVacancy(id: number): Observable<string[]> {
    return this.http.get<string[]>(`/recruiter/last-search-queries-by-vacancy/${id}`);
  }

  public analyzeVacancyTitleAndDescription(
    title: string,
    description?: string,
  ): Observable<VacancySuggestedParams> {
    return this.http.post<VacancySuggestedParams>('/vacancy/prepare-suggestions', {
      title,
      description,
    });
  }
}
