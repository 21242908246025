<div class="c-profile-preview-controls">
  @if (canBeSelected) {
    <mat-checkbox
      class="c-tsp-mat-checkbox c-profile-preview-controls__selector"
      [checked]="isProfileSelected"
      (change)="toggleSelection.emit(!isProfileSelected)"
    >
    </mat-checkbox>
  }
  <div class="c-profile-preview-controls__panel">
    @if (isReviewed) {
      <span
        [matTooltip]="lastReviewDate | formatDateTime: 'medium' | castToString"
        matTooltipPosition="right"
      >
        <i class="fas fa-eye" aria-hidden="true"> </i>
      </span>
    }
    @if (showControls && isInternalProfile) {
      <div [matTooltip]="getButtonTooltip('edit') | translate" matTooltipPosition="right">
        <button mat-icon-button (click)="editProfile.emit()">
          <i class="fas fa-user-edit" aria-hidden="true"> </i>
        </button>
      </div>
    }
    @if (showControls && !isExternalSearchPage) {
      <div [matTooltip]="contactButtonTooltip | translate" matTooltipPosition="right">
        <button
          mat-icon-button
          [disabled]="isContactButtonDisabled"
          (click)="openNotificationModal.emit()"
        >
          <i class="fas fa-paper-plane" aria-hidden="true"> </i>
        </button>
      </div>
    }
  </div>
</div>
