import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appFallbackSrc]',
  standalone: true,
})
export class FallbackSrcWidthDirective implements OnDestroy {
  @Input('appFallbackSrc')
  public imageSrc: string;
  private readonly element: HTMLElement;
  private readonly eventType: string = 'error';

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.element.addEventListener(this.eventType, this.onError.bind(this));
  }

  public ngOnDestroy(): void {
    this.removeEvents();
  }
  private onError() {
    this.removeEvents();
    this.setImage(this.imageSrc);
  }

  private setImage(src: string) {
    this.element.setAttribute('src', src);
  }

  private removeEvents() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.element.removeEventListener(this.eventType, this.onError.bind(this));
  }
}
