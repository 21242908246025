import {
  DashboardData,
  DashboardNotificationStatisticEntity,
  DateRange,
  MailTemplateTypes,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const ExportNotificationStatisticSuccessAction = createAction(
  '[Dashboard] Export notification statistic successfully',
);
const ExportNotificationStatisticErrorAction = createAction(
  '[Dashboard] Error happened during exporting notification statistic',
);

const GetDashboardDataAction = createAction('[Dashboard] Request dashboard data');
const GetDashboardDataSuccessAction = createAction(
  '[Dashboard] Dashboard data successfully received',
  props<{ response: DashboardData }>(),
);
const GetDashboardDataErrorAction = createAction(
  '[Dashboard] Error happened during requesting dashboard data',
);
const GetNotificationStatisticAction = createAction(
  '[Dashboard Resolver] Get notification statistic per recruiter',
  props<{ range: DateRange; notificationType: MailTemplateTypes }>(),
);
const GetNotificationStatisticSuccessAction = createAction(
  '[Dashboard] Notification statistic for requested type and period successfully received',
  props<{ response: DashboardNotificationStatisticEntity[] }>(),
);
const GetNotificationStatisticErrorAction = createAction(
  '[Dashboard] Error happened during requesting notification statistic',
);
const ReturnDashboardInitialStateAction = createAction(
  '[Dashboard Cleaner Guard] Return dashboard store to initial state',
);
const SetDashboardInitialStateAction = createAction(
  '[Dashboard Resolver] Set initial state from query params',
  props<{ range: DateRange }>(),
);

const actions = {
  GetDashboardDataAction,
  GetDashboardDataSuccessAction,
  GetDashboardDataErrorAction,
  GetNotificationStatisticAction,
  GetNotificationStatisticSuccessAction,
  GetNotificationStatisticErrorAction,
  ExportNotificationStatisticSuccessAction,
  ExportNotificationStatisticErrorAction,
  ReturnDashboardInitialStateAction,
  SetDashboardInitialStateAction,
};

const dashboardSystemActions = union(actions);

export type DashboardSystemActionsType = typeof dashboardSystemActions;

export const DashboardSystemActions = actions;
