import { inject } from '@angular/core';
import { CanDeactivateFn, Router } from '@angular/router';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { DashboardSystemActions } from '@app-core/dashboard/actions/dashboard.system.actions';
import { ProfileSidepanelUserActions } from '@app-core/profile-side-panel/actions/profile-sidepanel.user.actions';
import { ReturnInitialStateAction as ProfilesListReturnStateAction } from '@app-core/search-console/actions/search-console.system.actions';
import { VacanciesListSystemActions } from '@app-core/vacancies-list/actions/vacancies-list.system.actions';
import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';
import { State } from '@app-shared/reducers';
import { Store } from '@ngrx/store';
import { equals, head, isEmpty, isNil, nth, pipe, reject, split, test, unless } from 'ramda';
import { Observable, of } from 'rxjs';

export const moduleCleanerDeactivateGuard: CanDeactivateFn<boolean> = (): Observable<boolean> => {
  const store$: Store<State> = inject(Store<State>);
  const router: Router = inject(Router);

  const previousUrl = router.getCurrentNavigation().previousNavigation.finalUrl.toString();
  const currentUrl = router.getCurrentNavigation().finalUrl.toString();

  const getModule = (url: string) =>
    unless(
      isNil,
      pipe(split('('), head, split('?'), head, split('/'), reject(isEmpty), nth(1)),
    )(url);

  const isModuleChanged = (regExp: RegExp, previous?: string, current?: string): boolean =>
    test(regExp, previous || getModule(previousUrl)) &&
    !test(regExp, current || getModule(currentUrl));

  switch (true) {
    case isModuleChanged(/dashboard/g):
      store$.dispatch(DashboardSystemActions.ReturnDashboardInitialStateAction());
      break;
    case isModuleChanged(/extras:profile\/details/g, previousUrl, currentUrl):
      store$.dispatch(ProfileSidepanelUserActions.CleanStateAction());
      break;
    case isModuleChanged(/extras:popup\/(add-new|edit)/g, previousUrl, currentUrl):
      store$.dispatch(AddNewEntitySystemActions.CleanNewEntityStateAction());
      break;
    case isModuleChanged(/vacancies/g):
      store$.dispatch(VacanciesListSystemActions.ReturnInitialStateAction());
      break;
    case test(/profiles|candidates/g, getModule(previousUrl)) &&
      !equals(getModule(previousUrl), getModule(currentUrl)):
      store$.dispatch(ProfilesListReturnStateAction());
      break;
    case isModuleChanged(/vacancy/g):
      store$.dispatch(VacancyProfilesSystemActions.ReturnInitialStateAction());
      break;
    default:
      break;
  }
  return of(true);
};
