import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import uk from '@angular/common/locales/uk';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { VAPID_KEY } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PostLoginLobbyModule } from './core/post-login-lobby/post-login-lobby.module';
import { ProxyRouteComponent } from './proxy-route.component';
import {
  AppMetadataEffects,
  AuthEffects,
  InternalNotificationEffects,
  RouterEffects,
  ScriptInjectionEffects,
} from './shared/effects';
import { InterceptorsModule } from './shared/interceptors/interceptors.module';
import { AppVersionData, appVersionToken } from './shared/models';
import { State, reducers } from './shared/reducers';
import {
  appFeaturesResolver,
  appVersionResolver,
  authTokenResolver,
  datadogMonitorResolver,
} from './shared/resolvers';
import { CustomRouterStateSerializer } from './shared/serializers/router-state/custom-router-state-serializer';

const appFeaturesFactory = (store: Store<State>) => () => {
  return appFeaturesResolver(store);
};

const appVersionFactory = (store: Store<State>, appVersion: AppVersionData) => () =>
  appVersionResolver(store, appVersion);

const authStateFactory = (store: Store<State>) => () => authTokenResolver(store);

const datadogMonitorFactory = (store: Store<State>) => () => datadogMonitorResolver(store);

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

const appProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: appVersionFactory,
    deps: [Store<State>, appVersionToken],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appFeaturesFactory,
    deps: [Store<State>],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: datadogMonitorFactory,
    deps: [Store<State>],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: authStateFactory,
    deps: [Store<State>],
    multi: true,
  },
  {
    provide: RouterStateSerializer,
    useClass: CustomRouterStateSerializer,
  },
  { provide: VAPID_KEY, useValue: environment.firebaseWebPushKey },
  { provide: LOCALE_ID, useValue: 'uk' },
];

registerLocaleData(uk);

@NgModule({
  declarations: [AppComponent, ProxyRouteComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PostLoginLobbyModule,
    TranslateModule.forRoot({
      defaultLanguage: 'uk',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    InterceptorsModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
    EffectsModule.forRoot([
      AppMetadataEffects,
      AuthEffects,
      InternalNotificationEffects,
      RouterEffects,
      ScriptInjectionEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    ToastrModule.forRoot({
      enableHtml: true,
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr c-notification',
    }),
  ],
  providers: [...appProviders, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
