@if (entityForm) {
  <form class="c-tsp-mat-form c-directory-mail-attachment" [formGroup]="entityForm" novalidate>
    <div
      class="c-directory-mail-attachment-form"
      [ngClass]="{ 'c-directory-mail-attachment-form--no-file': !linkToFile }"
    >
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-directory.form.name.label</mat-label>
          <input
            matInput
            formControlName="name"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-directory.form.name.placeholder' | translate"
          />
          <mat-error class="c-directory-mail-attachment-form__error">
            <span
              class="c-directory-mail-attachment-form__error-text"
              [innerHTML]="'add-new-entity.new-directory.form.name.required' | translate"
            >
            </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="c-directory-mail-attachment-form__url">
        @if (linkToFile) {
          <div class="c-directory-mail-attachment-form__url-link">
            <a [href]="linkToFile" target="_blank" download>
              <i
                class="fas fa-file-arrow-down c-directory-mail-attachment-form__url-link-icon"
                aria-hidden="true"
              ></i>
              <span class="c-directory-mail-attachment-form__url-link-name">{{ fileName }}</span>
            </a>
            <button
              mat-icon-button
              class="c-directory-mail-attachment-form__url-link-button"
              (click)="onRemoveFile()"
            >
              <i
                class="fas fa-times c-directory-mail-attachment-form__url-link-button-icon"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        }
        @if (!linkToFile) {
          <div class="c-directory-mail-attachment-form__url-dropzone">
            <dropzone
              [config]="dropzoneConfig"
              [message]="'add-new-entity.new-directory.form.dropzone.message' | translate"
              (addedFile)="onFileAdded($event)"
            >
            </dropzone>
          </div>
        }
        <div class="c-tsp-mat-form-field__row c-directory-mail-attachment-form__url-control">
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-directory.form.url.label</mat-label>
            <input
              matInput
              formControlName="url"
              readonly="true"
              class="c-tsp-mat-form-field-input"
              [placeholder]="'add-new-entity.new-directory.form.url.placeholder' | translate"
            />
          </mat-form-field>
        </div>
        @if (urlIsInvalid) {
          <mat-error
            class="c-directory-mail-attachment-form__error c-directory-mail-attachment-form__error--url"
          >
            <span
              class="c-directory-mail-attachment-form__error-text"
              [innerHTML]="'add-new-entity.new-directory.form.url.required' | translate"
            >
            </span>
          </mat-error>
        }
      </div>
      <div class="c-tsp-mat-form-field__row c-tsp-popup__description">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
          <label class="c-tsp-popup-multiple-select__label" translate>
            add-new-entity.new-directory.form.description.label
          </label>
          <app-rich-editor formControlName="description" [isRequired]="true"> </app-rich-editor>
          @if (controlHasErrorType('description', 'required')) {
            <mat-error class="c-new-directory-dialog__error-text">
              <span
                [innerHTML]="'add-new-entity.new-directory.form.description.required' | translate"
              ></span>
            </mat-error>
          }
        </div>
      </div>
    </div>
    @if (linkToFile) {
      <div class="c-directory-mail-attachment__preview">
        <app-file-preview fileType="testing-tasks" [showRefreshButton]="false" [url]="linkToFile">
        </app-file-preview>
      </div>
    }
  </form>
}
