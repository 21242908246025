import { Entity, Locales, SubscriptionPackagesEnum } from '.';

// eslint-disable-next-line no-shadow
export enum AuthForms {
  LOGIN = 'login',
  PACKAGES = 'packages',
  RESET_PASSWORD = 'reset-password',
  REGISTRATION = 'registration',
  RESTORE_PASSWORD = 'password-restore',
  INVITE_RECRUITER = 'invite-recruiter',
}

export interface AuthPayload {
  email: string;
  password: string;
  saveCredentials: boolean;
}

export interface AuthRegistration {
  subscriptionPlan?: SubscriptionPackagesEnum;
  user: {
    gdpr: string;
    first_name: string;
    last_name: string;
    email: string;
    skype: string;
    password: string;
  };
  portal: {
    name: string;
    status: Entity;
  };
  role: string;
  locale: Locales;
}

export interface AuthResponse {
  token: string;
  refresh_token: string;
  useSessionStorage?: boolean;
}

export interface AccessToken {
  exp: number;
  iat: number;
  id: number;
  roles: string[];
}

export interface NewcomerData {
  email?: string | null;
  fadeBackground?: boolean;
  fullName?: string | null;
  isFromPlugin?: boolean;
  phone?: string | null;
}

export interface PlanFeatures {
  name: string;
  description: string;
}

export interface PasswordRestorePayload {
  token: string;
  password: string;
}

export type RegistrationRefererType = 'price' | 'plugin_install';

export type SocialAuthTypes = 'google' | 'linkedin' | 'github';

export enum InvitationTokenStatus {
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum AuthTokenValidityState {
  VALID = 'valid',
  EXPIRED = 'expired',
  INVALID = 'invalid',
}
