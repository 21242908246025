<mat-form-field
  class="c-short-list-status-selector c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
>
  <mat-select
    [formControl]="statusSelector"
    panelClass="c-tsp-mat-select-panel"
    [placeholder]="'profile-short-list.short-list-status-selector.change.placeholder' | translate"
    (selectionChange)="changeStatus.emit($event.value)"
  >
    <mat-select-trigger>
      @if (statusSelector.value) {
        <div class="c-short-list-status-selector__preview">
          @if (isStatusProtected) {
            <span [matTooltip]="'profile-short-list.profile-short-list.locked' | translate">
              <i class="fas fa-lock c-short-list-status-selector__locker" aria-hidden="true"></i>
            </span>
          } @else {
            <span
              class="c-short-list-status-selector__color"
              [ngStyle]="{ 'background-color': selectedStatusColor }"
            >
            </span>
          }
          <span class="c-short-list-status-selector__name">
            {{ selectedStatusName | translateDictionaryName: 'bidStatus' | async }}
          </span>
        </div>
      } @else {
        <span translate>profile-short-list.short-list-status-selector.change.label</span>
      }
    </mat-select-trigger>
    @for (status of statusList; track trackById($index, status)) {
      @if (status.name | translateDictionaryName: 'bidStatus' | async; as statusName) {
        <mat-option
          class="c-tsp-mat-form-field-option"
          [value]="status.id"
          [matTooltip]="statusName.length > 25 ? statusName : ''"
          [disabled]="isStatusNotSelectable(status.id)"
        >
          <span
            class="c-short-list-status-selector__color"
            [ngStyle]="{ 'background-color': status.color }"
          >
          </span>
          {{ statusName }}
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>
