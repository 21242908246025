import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CustomFieldsGroupComponent } from '@app-core/custom-fields/custom-fields-group/custom-fields-group.component';
import {
  DirectoryFieldType,
  ShortListDialogData,
  ShortListDialogForm,
  ShortListFormValue,
} from '@app-shared/models';
import { Subject, takeUntil } from 'rxjs';

@Component({
  imports: [ReactiveFormsModule, CustomFieldsGroupComponent],
  selector: 'short-list-dialog-custom-fields',
  templateUrl: './short-list-dialog-custom-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogCustomFieldsComponent implements OnInit, OnDestroy {
  @Input()
  public data: ShortListDialogData;
  @Input()
  public filteredCustomFields: DirectoryFieldType[];
  @Output()
  public formChanged = new EventEmitter<ShortListFormValue>();
  public form: FormGroup<Pick<ShortListDialogForm, 'fieldValues'>>;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder) {}
  public ngOnInit(): void {
    this.form = this.formBuilder.group<Pick<ShortListDialogForm, 'fieldValues'>>({
      fieldValues: new FormControl(this.data.fieldValues || []),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.formChanged.emit(value));
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
