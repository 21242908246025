@if (entityForm) {
  <form class="c-tsp-mat-form" [formGroup]="entityForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.city.enName.label</mat-label>
        <input
          matInput
          formControlName="enName"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.city.enName.placeholder' | translate"
        />
        <mat-error>
          <span
            [innerHTML]="'add-new-entity.new-directory.form.city.enName.required' | translate"
          ></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.city.ukName.label</mat-label>
        <input
          matInput
          formControlName="ukName"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.city.ukName.placeholder' | translate"
        />
        <mat-error>
          <span
            [innerHTML]="'add-new-entity.new-directory.form.city.ukName.required' | translate"
          ></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.city.ruName.label</mat-label>
        <input
          matInput
          formControlName="ruName"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.city.ruName.placeholder' | translate"
        />
      </mat-form-field>
    </div>
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate>add-new-entity.new-directory.form.city.country.label</mat-label>
      <mat-select
        [placeholder]="'add-new-entity.new-directory.form.city.country.placeholder' | translate"
        formControlName="countryId"
        [required]="true"
      >
        @for (country of countries; track country) {
          <mat-option class="c-tsp-mat-form-field-option" [value]="country.id">
            {{ country.name }}
          </mat-option>
        }
      </mat-select>
      <mat-error>
        <span
          [innerHTML]="'add-new-entity.new-directory.form.city.country.required' | translate"
        ></span>
      </mat-error>
    </mat-form-field>
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate>add-new-entity.new-directory.form.city.region.label</mat-label>
      <mat-select
        [placeholder]="'add-new-entity.new-directory.form.city.region.placeholder' | translate"
        formControlName="regionId"
      >
        @for (region of filteredRegions; track region) {
          <mat-option class="c-tsp-mat-form-field-option" [value]="region.id">
            {{ region.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate>add-new-entity.new-directory.form.city.synonyms.label</mat-label>
      <mat-chip-grid
        #synonymsChipGrid
        formControlName="synonyms"
        separatorKeyCodes="[ENTER, COMMA]"
      >
        @for (keyword of synonyms; track keyword) {
          <mat-chip-row (removed)="removeSynonym(keyword)">
            {{ keyword }}
            <button matChipRemove>
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
          </mat-chip-row>
        }
      </mat-chip-grid>
      <input
        [placeholder]="'add-new-entity.new-directory.form.city.synonyms.placeholder' | translate"
        [matChipInputFor]="synonymsChipGrid"
        (matChipInputTokenEnd)="addSynonym($event)"
      />
    </mat-form-field>
  </form>
}
