import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { DirectoryFieldType, UnaryOperator } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  anyPass,
  append,
  includes,
  isEmpty,
  isNil,
  pick,
  pipe,
  pluck,
  prop,
  propSatisfies,
  reject,
  uniqBy,
  without,
} from 'ramda';

@Component({
  imports: [
    MatButton,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgxIntlTelInputModule,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-directory-custom-fields',
  templateUrl: './directory-custom-fields.component.html',
  styleUrls: ['./directory-custom-fields.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DirectoryCustomFieldsComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryCustomFieldsComponent implements OnInit, ControlValueAccessor {
  @Input() public statusesCustomFields: DirectoryFieldType[];
  @Input() public currentTypeField: { name: string; title: string }[];
  @Output() public fieldExistsWarning = new EventEmitter<string>();
  public form: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: (val: unknown) => void = () => {};

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched() {}

  public ngOnInit() {
    this.form = this.formBuilder.group({
      customFields: [[]],
      name: [null],
      selectedField: [[]],
      type: ['text'],
      showInPanel: true,
    });
  }

  public get customFields(): DirectoryFieldType[] {
    return this.form?.get('customFields').value as DirectoryFieldType[];
  }

  public writeValue(value: DirectoryFieldType[]) {
    this.form.get('customFields').setValue(value);
  }
  public addField(existedField?: DirectoryFieldType): void {
    const field =
      existedField ||
      (pick(['name', 'type', 'showInPanel'], this.form.value) as DirectoryFieldType);
    const alreadyExists = pipe(
      pluck('name') as UnaryOperator<DirectoryFieldType[], string[]>,
      includes(field.name),
    )(this.customFields || []);

    if (alreadyExists) {
      return this.fieldExistsWarning.emit('add-new-entity.new-directory.field-exists');
    }

    const fields = pipe(
      append(field),
      reject(anyPass([propSatisfies(anyPass([isNil, isEmpty]), 'name'), isNil])) as UnaryOperator<
        DirectoryFieldType[],
        DirectoryFieldType[]
      >,
      uniqBy(prop('name')),
    )(this.customFields);
    this.form.get('name').reset();
    this.writeValue(fields);
    this.onChange(fields);
  }
  public removeField(field: DirectoryFieldType) {
    const fields = without([field], this.customFields);
    this.writeValue(fields);
    this.onChange(fields);
  }
}
