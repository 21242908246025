import {
  ExternalCredentials,
  Locales,
  NotificationChannel,
  NotificationRequestThroughNode,
  ProfilesSources,
  ShortListSourcePayload,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddNewShortListSourceAction = createAction(
  '[Root Module] Try to add new source',
  props<ShortListSourcePayload>(),
);
const AskFirebaseNotificationPermissionAction = createAction(
  '[Root Module] Ask for notification permissions',
);
const ChangeLocalizationAction = createAction(
  '[Root Module] Change localization',
  props<{ locale: Locales }>(),
);
const LogOutAction = createAction('[Root Module] Log Out');
const RevokeFirebaseNotificationPermissionAction = createAction(
  '[Root Module] Revoke notification permissions',
  props<{ token: string }>(),
);

const SendNotificationsThroughNodeAction = createAction(
  '[Root Module | Notification Popup] Sending notification to recipients using Node API',
  props<{
    data: NotificationRequestThroughNode;
    notificationType: NotificationChannel;
    source: ProfilesSources;
  }>(),
);
const SendLinkedinNotificationsAction = createAction(
  '[Root Module | Notification Popup] Open LinkedIn links with notification data',
  props<{ template: string; contacts: string[] }>(),
);
const UnsubscribeEmailAction = createAction(
  '[Root Module | Contacts Popup] Unsubscribe email from contacts popup',
  props<{ payload: { emails: string[]; unsubscribePeriodInMonth: number; comment?: string } }>(),
);

const SaveExternalCredentialsAction = createAction(
  '[Root Module] Try to save user external credentials',
  props<{ payload: ExternalCredentials }>(),
);
const UpdateExternalCredentialsAction = createAction(
  '[Root Module] Try to update user external credentials',
  props<{ payload: ExternalCredentials }>(),
);

const UpdateUserSpaceAction = createAction(
  '[Root Module] Try to update user space',
  props<{ id: number }>(),
);

const actions = {
  AddNewShortListSourceAction,
  AskFirebaseNotificationPermissionAction,
  ChangeLocalizationAction,
  LogOutAction,
  RevokeFirebaseNotificationPermissionAction,
  SaveExternalCredentialsAction,
  SendLinkedinNotificationsAction,
  SendNotificationsThroughNodeAction,
  UnsubscribeEmailAction,
  UpdateExternalCredentialsAction,
  UpdateUserSpaceAction,
};

const rootUserActions = union(actions);

export type RootUserActionsType = typeof rootUserActions;

export const RootUserActions = actions;
