import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(private readonly windowService: WindowRefService) {}

  public saveFile(file: Blob, fileName: string): void {
    const doc = this.windowService.nativeDocument;

    if (!doc) {
      return;
    }

    const a = doc.createElement('a');
    a.id = 'temporary-download-link';
    a.href = URL.createObjectURL(file);
    a.download = fileName;

    const body = doc.getElementsByTagName('body')[0];
    body.append(a);
    a.click();
    body.removeChild(a);
  }
}
