import { Pipe, PipeTransform } from '@angular/core';
import { ClientFull } from '@app-shared/models';
import { find, isNil, join, pipe, propEq, props } from 'ramda';

@Pipe({
  name: 'convertIdToFullName',
  standalone: true,
})
export class ConvertIdToFullNamePipe implements PipeTransform {
  public transform(value: number, clients?: ClientFull[]): string {
    if (!isNil(value)) {
      return pipe(find(propEq(value, 'id')), props(['firstName', 'lastName']), join(' '))(clients);
    }
    return '';
  }
}
