import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { CalendarTarget, ShortList } from '@app-shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormatDateTimePipe } from '@tsp-pipes';
import { SendLetterButtonComponent } from '../send-letter-button/send-letter-button.component';

@Component({
  imports: [FormatDateTimePipe, MatTooltip, NgClass, SendLetterButtonComponent, TranslateModule],
  selector: 'short-list-interview-info',
  templateUrl: './short-list-interview-info.component.html',
  styleUrls: ['./short-list-interview-info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListInterviewInfoComponent {
  @Input()
  public hasEmail: boolean;
  @Input()
  public interviewAt: string;
  @Input()
  public shortList: ShortList;
  @Output()
  public changeInterviewDate: EventEmitter<void> = new EventEmitter();
  @Output()
  public openExternalCalendar: EventEmitter<CalendarTarget> = new EventEmitter();
  @Output()
  public sendLetter: EventEmitter<void> = new EventEmitter();

  public calendarLinks: { type: CalendarTarget; className: string; name: string }[] = [
    {
      type: 'google',
      className: 'fab fa-google',
      name: 'Google Calendar',
    },
    {
      type: 'office365',
      className: 'fab fa-windows',
      name: 'Office 365',
    },
    {
      type: 'outlook',
      className: 'far fa-calendar-plus',
      name: 'Outlook',
    },
  ];
}
