import {
  CalendarPayload,
  CalendarTarget,
  MailTemplateTypes,
  NotificationChannel,
  PaginationParams,
  Profile,
  ProfileExternalSearchSources,
  ProfilesSearchParams,
  ProfilesSources,
  ShortList,
  ShortListPayload,
  SortParams,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddToShortListAction = createAction(
  '[Search Console] Add profile to short list',
  props<{ shortList: Partial<ShortListPayload>; shouldRefresh?: boolean }>(),
);
const ChangeExternalSearchSource = createAction(
  '[Search Console] Change external search source',
  props<{ externalSearchSource: ProfileExternalSearchSources }>(),
);
const ChangeSimpleCardViewState = createAction(
  '[Search Console] Change simple card view',
  props<{ viewState: boolean }>(),
);
const CleanSearchParamsAction = createAction('[Search Console Filters] Clean filter params');

const CreateProfileWithDataAction = createAction(
  '[Search Console] Open create profile dialog with pre-filled data',
  props<{ data: { profile: Profile; vacancyId: number | null } }>(),
);
const DeleteProfilesAction = createAction('[Search Console] Delete selected profiles');

const LinkSuggestionAction = createAction(
  '[Search Console] Link suggested profile with existed profile',
  props<{ ids: string[] }>(),
);
const MergeTwoProfilesAction = createAction('[Search Console] Merge selected profiles');
const MergeMultipleProfilesAction = createAction(
  '[Search Console] Merge profile duplicates',
  props<{ primary: string; duplicates: string[] }>(),
);
const NavigateToProfilesModule = createAction(
  '[Search Console] Navigate to another search modules',
  props<{ source: ProfilesSources }>(),
);
const OpenShortListCalendarFromProfilesListAction = createAction(
  '[Search Console] Open calendar page for setting interview event from profiles list page',
  props<{ target: CalendarTarget; data: CalendarPayload; event?: string }>(),
);
const OpenSubscriptionPlansDialogFromProfilesListAction = createAction(
  '[Search Console | Control Panel] Open popup for select subscription plan',
);
const RemoveComplicatedSkillsFilterAction = createAction(
  '[Search Console] Remove complicated skill filters and unlock simple skills input',
);
const RemoveFromShortListInProfilesListAction = createAction(
  '[Search Console] Remove profile from short list on profiles list page',
  props<{ id: number; devId: string }>(),
);
const ResendShortlistForSecurityCheckAction = createAction(
  '[Search Console] Resend shortlist for security check',
  props<{ id: number }>(),
);
const SearchMatchedCandidatesByContactsAction = createAction(
  '[Search Console] Open search with profile contacts by profile id',
  props<{ id: string }>(),
);
const SelectAllProfilesOnSearchConsoleAction = createAction('[Search Console] Select all profiles');
const SentNotificationsAction = createAction(
  '[Search Console] Sent notification to profiles',
  props<{
    id?: string;
    notificationType: NotificationChannel;
    templateType: MailTemplateTypes;
    shortList: ShortList;
  }>(),
);
const SendSingleNotificationAction = createAction(
  '[Search Console] Sent notification to single profile',
  props<{ id: number }>(),
);
const UpdatePaginationParamsAction = createAction(
  '[Search Console] Update pagination parameters',
  props<{ paginationParams: PaginationParams }>(),
);
const UpdateSearchParamsAction = createAction(
  '[Search Console Filters] Set new search params from filters',
  props<{ searchParams: ProfilesSearchParams }>(),
);
const UpdateShortListInProfilesListAction = createAction(
  '[Search Console] Update existed short list from profiles list page',
  props<{ previousShortList: Partial<ShortList>; shortList: Partial<ShortList> }>(),
);
const UpdateSortingParamsAction = createAction(
  '[Search Console] Update sorting parameters',
  props<{ sortParams: SortParams }>(),
);

const actions = {
  AddToShortListAction,
  ChangeExternalSearchSource,
  ChangeSimpleCardViewState,
  CleanSearchParamsAction,
  CreateProfileWithDataAction,
  DeleteProfilesAction,
  LinkSuggestionAction,
  MergeTwoProfilesAction,
  MergeMultipleProfilesAction,
  NavigateToProfilesModule,
  OpenShortListCalendarFromProfilesListAction,
  OpenSubscriptionPlansDialogFromProfilesListAction,
  RemoveComplicatedSkillsFilterAction,
  RemoveFromShortListInProfilesListAction,
  ResendShortlistForSecurityCheckAction,
  SearchMatchedCandidatesByContactsAction,
  SelectAllProfilesOnSearchConsoleAction,
  SentNotificationsAction,
  SendSingleNotificationAction,
  UpdatePaginationParamsAction,
  UpdateSearchParamsAction,
  UpdateShortListInProfilesListAction,
  UpdateSortingParamsAction,
};

const searchConsoleUserActions = union(actions);

export type SearchConsoleUserActionsType = typeof searchConsoleUserActions;

export const SearchConsoleUserActions = actions;
