<div
  class="c-profile-preview"
  appTextSelect
  [textSelectionDisabled]="activeTab === 'vacancy'"
  (pasteKeywordToSearch)="pasteKeywordToSearch.emit($event)"
  [ngClass]="{
    'c-profile-preview--is-sidepanel': isSidepanel,
    'c-profile-preview--reviewed': isReviewed,
    'c-profile-preview--loading': isProfileLoading,
    'c-profile-preview--simple-view': simpleCardViewState || isMobileDevice,
    'c-profile-preview--has-duplicates': hasDuplicates,
    'c-profile-preview--with-toggles': externalDataSources.length,
  }"
>
  <div class="c-profile-preview__selection-controls-wrapper">
    <div class="c-profile-preview__selection-controls">
      <button class="c-profile-preview__selection-controls-item--add" mat-button>
        <span translate>profile-preview.profile-preview.add-to-search.button</span>
      </button>
      <button class="c-profile-preview__selection-controls-item--exclude" mat-button>
        <span translate>profile-preview.profile-preview.exclude-from-search.button</span>
      </button>
    </div>
  </div>
  <profile-preview-controls
    class="c-profile-preview__controls"
    [ngClass]="{ 'c-profile-preview__controls--is-sidepanel': isSidepanel }"
    [activeTab]="activeTab"
    [canBeSelected]="canBeSelected"
    [contactButtonTooltip]="contactButtonTooltip"
    [isActiveSearchParams]="isActiveSearchParams"
    [isContactButtonDisabled]="isContactButtonDisabled"
    [isExternalSearchPage]="isExternalSearchPage"
    [isInternalProfile]="isInternalProfile"
    [isProfileSelected]="isProfileSelected"
    [isProfilesSearchPage]="isProfilesSearchPage"
    [isReviewed]="isReviewed"
    [lastReviewDate]="lastReviewDate"
    [profileName]="selectedProfileData.name"
    [profileId]="selectedProfileData.id"
    [showComment]="showComment"
    [showControls]="userIsRecruiterOrAdmin"
    (editProfile)="editProfile.emit()"
    (openNotificationModal)="openNotificationModal({ type: 'notification', shortList: applies })"
    (toggleSelection)="toggleSelection.emit($event)"
  >
  </profile-preview-controls>
  <div
    class="c-profile-preview__personal"
    [ngClass]="{ 'c-profile-preview__personal--is-sidepanel': isSidepanel }"
  >
    <profile-personal-info
      [@fadeInOut]="selectedProfileDataIndex"
      class="c-profile-preview__personal-info"
      [activeTab]="activeTab"
      [dictionaries]="dictionaries"
      [hasAccessToSecondName]="hasAccessToSecondName"
      [isExternalSearchPage]="isExternalSearchPage"
      [profile]="selectedProfileData"
      [showLocation]="false"
      [searchParamsForHighlight]="searchParamsForHighlight"
      [userLocale]="userLocale"
      (onAvatarClick)="openProfile.emit()"
    >
    </profile-personal-info>
    <div class="c-profile-preview__personal-contacts-wrapper">
      @if (hasAccessToContacts) {
        <profile-available-contacts
          class="c-profile-preview__personal-contacts"
          [availableContacts]="availableContacts"
          [contactsVisibilityLimit]="simpleCardViewState ? 6 : 4"
          (showContactDialog)="openContacts.emit($event)"
          (showEditProfileDialog)="editProfile.emit()"
        >
        </profile-available-contacts>
      }
    </div>
  </div>
  @if (externalDataSources.length) {
    <profile-sources-toggle
      class="c-profile-preview__sources-toggle"
      [showControls]="activeTab !== 'profiles' && activeTab !== 'details'"
      [showMyData]="shouldUseMyDataInfo"
      [profileSources]="externalDataSources"
      [selectedSourceIndex]="selectedProfileDataIndex"
      (match)="onMatchProfileData($event)"
      (toggle)="changeProfileDataSource($event)"
    >
    </profile-sources-toggle>
  }

  @if (isExtendedView) {
    <profile-extended-professional-info
      class="c-profile-preview__professional-info"
      [allProfileProjects]="allProfileProjects"
      [allProfileEducation]="allProfileEducation"
      [profileSummary]="profile.summary"
    >
    </profile-extended-professional-info>
  } @else {
    <div
      class="c-profile-preview__professional-info"
      [ngClass]="{
        'c-profile-preview__professional-info--full': !showApplies,
        'c-profile-preview__professional-info--is-sidepanel': isSidepanel,
      }"
    >
      @if (profile.location) {
        <div class="c-profile-preview__location">
          <i
            class="fa-solid fa-location-dot c-profile-preview__location-icon"
            aria-hidden="true"
          ></i>
          {{ profile.location }}
        </div>
      }
      @if (hasWorkHistory) {
        <div class="c-profile-preview__professional-info-item">
          <profile-work-history
            [projects]="profileProjects"
            [@fadeInOut]="selectedProfileDataIndex"
            [externalProjects]="profileExternalProjects"
            [searchParamsForHighlight]="searchParamsForHighlight"
            [showMoreButtonForWorkHistory]="showMoreButtonForWorkHistory"
          >
          </profile-work-history>
        </div>
      }
      <div
        class="c-profile-preview__professional-info-item c-profile-preview__professional-info--skills"
      >
        @if (profileSkills.length) {
          <div class="c-profile-preview__professional-info-row">
            <div class="c-profile-preview__professional-info-skills">
              <span
                class="c-profile-preview__professional-info-title"
                [matTooltip]="'profile-preview.profile-preview.skills.title' | translate"
              >
                <i class="fas fa-trophy" aria-hidden="true"></i>
              </span>
              <profile-skills
                class="c-profile-preview__professional-info-skills--wrapper"
                [skillsDictionary]="dictionaries?.skills"
                [profile]="profile | castToProfile"
                [showMainSkill]="true"
                [showDetailedInfo]="showDetailedInfo"
                [showExpandButton]="false"
                [activeSkillsFilters]="activeSkillsFilters"
                [showSuggested]="true"
                [suggestedSkills]="profileSuggestedSkills"
                (editProfile)="editProfile.emit($event)"
              >
              </profile-skills>
            </div>
            @if (renderShowDetailsButton) {
              <button
                class="btn c-profile-preview__professional-info-button"
                [ngClass]="{
                  'c-profile-preview__professional-info-button--activated': showDetailedInfo,
                }"
                (click)="onShowDetailedInfo()"
              >
                {{ getToggleTooltip(showDetailedInfo) | translate }}
              </button>
            }
          </div>
        }
        @if (profileTags.length) {
          <profile-tags
            [dictionary]="dictionaries?.tags"
            [isEditable]="false"
            [entityData]="selectedProfileData"
            [showExpandButton]="false"
            [showDetailedInfo]="showDetailedInfo"
          >
          </profile-tags>
        }
      </div>
      @if (allowedToShowInsideInfo) {
        <div class="c-profile-preview__professional-info-additional-wrapper">
          <span class="c-profile-preview__professional-info-additional-title" translate
            >profile-preview.profile-preview.inside-info</span
          >
          <span
            class="c-profile-preview__professional-info-additional"
            [ngClass]="{ 'c-profile-preview__professional-info-additional--full': showInsideInfo }"
          >
            {{ insideInfo }}
          </span>
          @if (insideInfo?.length > 18) {
            <button
              class="btn c-profile-preview__professional-info-button"
              [matTooltip]="getToggleTooltip(showInsideInfo) | translate"
              [ngClass]="{
                'c-profile-preview__professional-info-button--activated': showInsideInfo,
              }"
              (click)="showInsideInfo = !showInsideInfo"
            >
              @if (showInsideInfo) {
                <span>
                  <i class="fas fa-caret-up" aria-hidden="true"> </i>
                </span>
              }
              @if (!showInsideInfo) {
                <span>
                  <i class="fas fa-ellipsis-h" aria-hidden="true"> </i>
                </span>
              }
            </button>
          }
        </div>
      }
      @if (showComment) {
        <profile-last-comment
          class="c-profile-preview__comments"
          [lastComment]="lastComment"
          [commentsLength]="commentsLength"
          [actionOpenSidePanel]="true"
          (onCommentClick)="openSidepanel.emit(['activity'])"
        >
        </profile-last-comment>
      }
    </div>
  }
  @if (hasDuplicates) {
    <div class="c-profile-preview__duplicate">
      <h5 class="c-profile-preview__duplicate-title" translate>
        profile-preview.profile-preview.manage-duplicates.title
      </h5>
      @for (duplicate of profile.duplicates; track duplicate) {
        <a
          class="c-profile-preview__duplicate-item"
          [ngClass]="'c-profile-preview__duplicate-item--' + getDuplicateStatus(duplicate)"
          [matTooltip]="
            'profile-preview.profile-preview.manage-duplicates.' + getDuplicateStatus(duplicate)
              | translate
          "
          [routerLink]="['/', userLocale, 'profile', duplicate.id, 'details']"
        >
          <i
            class="fa-solid fa-right-from-bracket c-profile-preview__duplicate-item-icon"
            aria-hidden="true"
          >
          </i>
          <span class="c-profile-preview__duplicate-item-name">
            {{ duplicate.name }}
          </span>
        </a>
      }
      <button
        class="c-profile-preview__duplicate-manage"
        mat-button
        (click)="mergeDuplicates.emit(duplicatesIds)"
      >
        <span translate>profile-preview.profile-preview.manage-duplicates.button</span>
      </button>
    </div>
  }
  @if (!isExternalSearchPage && !hasDuplicates) {
    <div class="c-profile-preview__actions">
      @if (showPublicProfileCandidates) {
        @if (!publicProfileCandidateIsApplied) {
          <profile-candidates
            class="c-profile-preview__public-profile-candidates"
            [candidates]="publicProfileCandidates"
            [userLocale]="userLocale"
            [vacancyId]="vacancyId"
            (addToList)="addToListById.emit($event)"
            (searchMatchedCandidates)="onSearchMatchedCandidates()"
          >
          </profile-candidates>
        }
        @if (publicProfileCandidateIsApplied) {
          <div class="c-profile-preview__short-list">
            @if (publicProfileCandidates[0]; as publicProfileCandidate) {
              <profile-short-list
                [shortList]="publicProfileCandidate.applies"
                [showInfo]="false"
                [statusesCustomFields]="statusesCustomFields"
                [profile]="publicProfileCandidate"
                (addToList)="addToList.emit($event)"
                (addToCompany)="addToCompany.emit({ profile: publicProfileCandidate, vacancyId })"
                (openCalendar)="openCalendar.emit($event)"
                (removeFromShortList)="removeFromShortList.emit($event)"
                (resendShortlistForSecurityCheck)="resendShortlistForSecurityCheck.emit($event)"
                (sendLetter)="openNotificationModal($event)"
                (updateShortList)="updateShortList.emit($event)"
              >
              </profile-short-list>
            }
          </div>
        }
      } @else {
        @if (!isInternalProfile) {
          <button
            class="c-profile-preview__add-to-portal"
            mat-button
            (click)="addToCompany.emit({ profile: selectedProfileData, vacancyId: null })"
          >
            <span translate>profile-preview.profile-preview.add-to-portal.button</span>
          </button>
        }
        @if (showApplies) {
          <div class="c-profile-preview__short-list">
            <profile-short-list
              [shortList]="applies"
              [disableActions]="!userIsRecruiterOrAdmin"
              [showInfo]="false"
              [statusesCustomFields]="statusesCustomFields"
              [profile]="profile | castToProfile"
              (addToList)="addToList.emit($event)"
              (addToCompany)="addToCompany.emit({ profile: selectedProfileData, vacancyId })"
              (openCalendar)="openCalendar.emit($event)"
              (removeFromShortList)="removeFromShortList.emit($event)"
              (resendShortlistForSecurityCheck)="resendShortlistForSecurityCheck.emit($event)"
              (sendLetter)="openNotificationModal($event)"
              (updateShortList)="updateShortList.emit($event)"
            >
            </profile-short-list>
          </div>
        }
        @if (showTrelloButton) {
          <app-trello-button
            class="c-profile-preview__trello"
            [showOnlyIcon]="true"
            (onClick)="trelloClick.emit()"
          >
          </app-trello-button>
        }
        @if (showApplyShortInfo) {
          <profile-short-list-info
            [hasAccessToCustomFields]="hasAccessToCustomFields"
            [hasAccessToSalary]="hasAccessToSalary"
            class="c-profile-preview__short-list-info"
            [shortList]="applies"
          >
          </profile-short-list-info>
        }
        <profile-counts-panel
          class="c-profile-preview__personal-counts"
          [ngClass]="{ 'c-profile-preview__personal-counts--is-sidepanel': isSidepanel }"
          [profile]="profile"
          (openFilesModal)="openFilesModal($event)"
          (openSidepanel)="openSidepanel.emit($event)"
          (showWarning)="showWarning.emit($event)"
        >
        </profile-counts-panel>
      }
    </div>
  }
  @if (showPublicProfileControls && isProfileDataNotMatched) {
    <div class="c-profile-preview__public-actions">
      <button
        mat-icon-button
        [matTooltip]="'shared.sources-toggle.vote-up' | translate"
        class="c-profile-preview__public-actions-item"
        (click)="matchProfileData.emit({ isMatch: true })"
      >
        <i class="fas fa-thumbs-up" aria-hidden="true"> </i>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'shared.sources-toggle.vote-down' | translate"
        class="c-profile-preview__public-actions-item"
        (click)="matchProfileData.emit({ isMatch: false })"
      >
        <i class="fas fa-thumbs-down" aria-hidden="true"> </i>
      </button>
    </div>
  }
  @if (profileCustomFields?.length && hasAccessToCustomFields) {
    <app-preview-custom-fields
      class="c-profile-preview__custom-fields"
      [customFields]="profileCustomFields"
      [vacancyId]="vacancyId"
      (openCalendar)="openCalendar.emit($event)"
    >
    </app-preview-custom-fields>
  }
  @if (statusCustomFields?.length && hasAccessToStatusCustomFields) {
    <app-preview-custom-fields
      class="c-profile-preview__custom-fields c-profile-preview__custom-fields--status"
      [customFields]="statusCustomFields"
      [vacancyId]="vacancyId"
      (openCalendar)="openCalendar.emit($event)"
    >
    </app-preview-custom-fields>
  }
</div>
