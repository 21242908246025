<div class="c-add-new-source">
  <form class="c-tsp-mat-form"
      [formGroup]="newSourceForm"
      novalidate>
    <h5 class="c-tsp-popup__title"
        translate>profile-short-list.short-list-edit-dialog.source.title</h5>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always">
        <mat-label translate>profile-short-list.short-list-edit-dialog.source-name.label</mat-label>
        <input matInput
            class="c-tsp-mat-form-field-input"
            formControlName="name"
            [placeholder]="'profile-short-list.short-list-edit-dialog.source-name.placeholder' | translate">
      </mat-form-field>
      <mat-form-field class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always">
        <mat-label translate>profile-short-list.short-list-edit-dialog.source-code.label</mat-label>
        <input matInput
            class="c-tsp-mat-form-field-input"
            formControlName="code"
            [placeholder]="'profile-short-list.short-list-edit-dialog.source-code.placeholder' | translate">
      </mat-form-field>
    </div>
  </form>
  <div class="c-add-new-source__controls">
    <button mat-button
        class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
        (click)="close()">
      <span translate>profile-short-list.short-list-edit-dialog.close.button</span>
    </button>
    <button mat-button
        class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
        [ngClass]="{'c-tsp-mat-popup-form__button--disabled': newSourceForm.invalid}"
        [disabled]="newSourceForm.invalid"
        (click)="createSource()">
      <span translate>profile-short-list.short-list-edit-dialog.source.create</span>
    </button>
  </div>
</div>
