<div class="c-profile-short-list-info">
  <div class="c-profile-short-list-info__wrapper">
    <p class="c-profile-short-list-info__text">
      @if (createdBy) {
        <span translate>profile-short-list.short-list-info.labels.added</span>
      }
      <span class="c-profile-short-list-info__text-value">{{ createdBy }}</span>
      @if (addedToStatusAt) {
        <span>
          <span translate>profile-short-list.short-list-info.on_date</span>
          <span
            class="c-profile-short-list-info__text-value"
            [matTooltip]="addedToStatusAt | formatDateTime: 'medium' | castToString"
          >
            {{ addedToStatusAt | formatDateTime: 'short' }}
          </span>
        </span>
      }
    </p>
    @if (sourceName) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.source</span>
        <span class="c-profile-short-list-info__text-value">{{ sourceName }}</span>
      </p>
    }
    <p class="c-profile-short-list-info__text">
      @if (updatedBy) {
        <span translate>profile-short-list.short-list-info.labels.updated</span>
      }
      <span class="c-profile-short-list-info__text-value">{{ updatedBy }}</span>
      @if (updatedStatusAt) {
        <span>
          <span translate>profile-short-list.short-list-info.on_date</span>
          <span
            class="c-profile-short-list-info__text-value"
            [matTooltip]="updatedStatusAt | formatDateTime: 'medium' | castToString"
          >
            {{ updatedStatusAt | formatDateTime: 'short' }}
          </span>
        </span>
      }
    </p>
    @if (shortList.salary && hasAccessToSalary) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.salary</span>
        <span class="c-profile-short-list-info__text-value">${{ shortList.salary }}</span>
      </p>
    }
    @if (shortList.comment) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.comment</span>
        <span class="c-profile-short-list-info__text-value" [matTooltip]="shortList.comment">{{
          shortList.comment
        }}</span>
      </p>
    }
    @if (shortList.contacts) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.contacts</span>
        <span class="c-profile-short-list-info__text-value">{{ shortList.contacts }}</span>
      </p>
    }
    @if (reasonRejectName) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.reject</span>
        <span class="c-profile-short-list-info__text-value">{{ reasonRejectName }}</span>
      </p>
    }
    @if (shortList.interviewAt) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.interview</span>
        <span class="c-profile-short-list-info__text-value">{{
          shortList.interviewAt | formatDateTime: 'medium'
        }}</span>
      </p>
    }
    @if (projectClientName) {
      <p class="c-profile-short-list-info__text">
        <span translate>profile-short-list.short-list-info.labels.client</span>
        <span class="c-profile-short-list-info__text-value">{{ projectClientName }}</span>
      </p>
    }
  </div>
  @if (hasAccessToCustomFields && shortList.fieldValues?.length) {
    <app-preview-custom-fields
      class="c-profile-short-list-info__wrapper"
      [customFields]="shortList.fieldValues"
      [vacancyId]="vacancyId"
      (openCalendar)="openCalendar.emit($event)"
    >
    </app-preview-custom-fields>
  }
</div>
