import { VacancyComment, VacancyDetails } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddCommentSuccessfulAction = createAction(
  '[Vacancy Profiles Sidepanel] Successfully added the comment to the vacancy',
  props<{ comment: VacancyComment; vacancyId: number }>(),
);
const AddCommentFailedAction = createAction(
  '[Vacancy Profiles Sidepanel] Failed to add the comment to the vacancy',
);
const DeleteCommentSuccessfulAction = createAction(
  '[Vacancy Profiles Sidepanel] Successfully deleted the comment from the vacancy',
  props<{ id: number; vacancyInfo: VacancyDetails }>(),
);
const DeleteCommentFailedAction = createAction(
  '[Vacancy Profiles Sidepanel] Deleting the comment for the vacancy had failed',
);
const RequestVacancyDetailsAction = createAction(
  '[Vacancy Profiles Sidepanel] Request vacancy details',
  props<{ id: number }>(),
);
const RequestVacancyDetailsSuccessAction = createAction(
  '[Vacancy Profiles Sidepanel | Vacancy API] Vacancy details saved to store',
  props<{ vacancy: VacancyDetails }>(),
);
const RequestVacancyDetailsFailedAction = createAction(
  '[Vacancy Sidepanel | Vacancy API] Error happened during requesting vacancy details',
);
const SetInitialTabAction = createAction(
  '[Vacancy Profiles Sidepanel] Set tab of side panel',
  props<{ tab: string }>(),
);

const actions = {
  AddCommentSuccessfulAction,
  AddCommentFailedAction,
  DeleteCommentSuccessfulAction,
  DeleteCommentFailedAction,
  RequestVacancyDetailsAction,
  RequestVacancyDetailsSuccessAction,
  RequestVacancyDetailsFailedAction,
  SetInitialTabAction,
};

const vacancySidepanelSystemActions = union(actions);

export type VacancySidepanelSystemActionsType = typeof vacancySidepanelSystemActions;

export const VacancySidepanelSystemActions = actions;
