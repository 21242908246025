import {
  DictionariesEnum,
  ProfilesSources,
  SortParams,
  Tab,
  UnaryOperator,
} from '@app-shared/models';
import { find, pipe, prop, propEq } from 'ramda';

export const profileTabs: Tab[] = [
  {
    path: ProfilesSources.EXTERNAL,
    label: 'search-console.navigation.tabs.external-profiles',
    defaultSorting: 'updatedAt-desc',
  },
  {
    path: ProfilesSources.CANDIDATES,
    label: 'search-console.navigation.tabs.candidates',
    defaultSorting: 'updatedAt-desc',
  },
  {
    path: ProfilesSources.PROFILES,
    label: 'search-console.navigation.tabs.profiles',
    defaultSorting: 'updatedAt-desc',
  },
];

export const profileSortOptions = [
  {
    value: { sortField: 'weight', sortDirection: 'desc' },
    name: 'search-console.sorting.options.skill-desc',
    shortCode: 'weight-desc',
  },
  {
    value: { sortField: 'createdAt', sortDirection: 'asc' },
    name: 'search-console.sorting.options.createdAt-asc',
    shortCode: 'createdAt-asc',
  },
  {
    value: { sortField: 'createdAt', sortDirection: 'desc' },
    name: 'search-console.sorting.options.createdAt-desc',
    shortCode: 'createdAt-desc',
  },
  {
    value: { sortField: 'lastName', sortDirection: 'desc' },
    name: 'search-console.sorting.options.lastName-desc',
    shortCode: 'lastName-desc',
  },
  {
    value: { sortField: 'updatedAt', sortDirection: 'desc' },
    name: 'search-console.sorting.options.updatedAt-desc',
    shortCode: 'updatedAt-desc',
  },
];

export const profileFilterCheckboxes = [
  {
    controlName: 'excludeProcessedCandidates',
    className: 'fas fa-handshake-slash',
    title: 'search-console.side-filters.checkboxes.not-processed',
  },
  {
    controlName: 'excludeWatchedCandidates',
    className: 'fas fa-eye-slash',
    title: 'search-console.side-filters.checkboxes.not-watched',
  },
  {
    controlName: 'hasEmail',
    className: 'fas fa-envelope',
    title: 'search-console.side-filters.checkboxes.has-email',
  },
  {
    controlName: 'hasSkype',
    className: 'fab fa-skype',
    title: 'search-console.side-filters.checkboxes.has-skype',
  },
  {
    controlName: 'hasPhone',
    className: 'fas fa-phone',
    title: 'search-console.side-filters.checkboxes.has-phone',
  },
  {
    controlName: 'hasCV',
    className: 'far fa-file-alt',
    title: 'search-console.side-filters.checkboxes.has-cv',
  },
  {
    controlName: 'hadContract',
    className: 'fas fa-thumbs-up',
    title: 'search-console.side-filters.checkboxes.had-contract',
  },
];

export const searchSideFilters = [
  'addedBy',
  'candidateStage',
  'excludeProcessedCandidates',
  'excludeWatchedCandidates',
  'experience',
  'hadContract',
  'hasCV',
  'hasEmail',
  'hasPhone',
  'hasSkype',
  'lastPositionExperience',
];

export const searchTopFilters = ['allowSearchInForbiddenCountries', 'lookingJob', 'remoteWork'];

export const profileDictionaries: DictionariesEnum[] = [
  DictionariesEnum.cities,
  DictionariesEnum.countries,
  DictionariesEnum.englishLevels,
  DictionariesEnum.skills,
  DictionariesEnum.tags,
  DictionariesEnum.experiences,
  DictionariesEnum.specialities,
  DictionariesEnum.sources,
];

export const profileDetailsDictionaries: DictionariesEnum[] = [
  DictionariesEnum.cities,
  DictionariesEnum.countries,
  DictionariesEnum.currencies,
  DictionariesEnum.employments,
  DictionariesEnum.englishLevels,
  DictionariesEnum.experiences,
  DictionariesEnum.skills,
  DictionariesEnum.mainSkills,
  DictionariesEnum.softSkills,
  DictionariesEnum.specialities,
  DictionariesEnum.sources,
  DictionariesEnum.tags,
];

export const iconsForProjects = [
  { label: 'LinkedIn', name: 'linkedin', class: 'linkedIn' },
  { label: 'Github', name: 'github', class: 'github' },
  { label: 'Github SC', name: 'github_lite', class: 'github' },
  { label: 'My Talents', name: 'my_talents', class: 'globe' },
  { label: 'Public Talents', name: 'public_talents', class: 'globe' },
  { label: 'Stack Overflow', name: 'stackoverflow', class: 'stack-overflow' },
  { label: 'Rabota.ua', name: 'rabota_ua', class: 'rabota_ua' },
  { label: 'Work.ua', name: 'work_ua', class: 'work_ua' },
  { label: 'Linkedin SC', name: 'linkedin_lite', class: 'linkedIn' },
  { label: 'LinkedIn Google', name: 'linkedin_google', class: 'linkedIn' },
  { label: 'Manually', name: 'manually', class: 'globe' },
  { label: 'CV Parsing', name: 'cv_parsing', class: 'globe' },
  { label: 'TurboHiring', name: 'turbohiring', class: 'globe' },
  { label: 'HeadHunter', name: 'head_hunter', class: 'globe' },
  { label: 'Elance', name: 'elance', class: 'globe' },
  { label: 'Facebook', name: 'facebook', class: 'facebook' },
  { label: 'Green House', name: 'green_house', class: 'globe' },
  { label: 'Xing', name: 'xing', class: 'globe' },
  { label: 'DOU', name: 'dou', class: 'globe' },
  { label: 'Djinni', name: 'djinni', class: 'globe' },
  { label: 'Upwork', name: 'upwork', class: 'globe' },
  { label: 'Moi Krug', name: 'moi_krug', class: 'globe' },
  { label: 'Ask Ubuntu', name: 'ask_ubuntu', class: 'globe' },
  { label: 'Amazing Huring', name: 'amazing_huring', class: 'globe' },
  { label: 'Dribbble', name: 'dribbble', class: 'globe' },
  { label: 'Angel.co', name: 'angel_co', class: 'globe' },
  { label: 'GitLab', name: 'gitlab', class: 'globe' },
  { label: 'Twitter', name: 'twitter', class: 'twitter' },
  { label: 'vk.com', name: 'vk_com', class: 'globe' },
  {
    label: 'Stack Overflow SC',
    name: 'stackoverflow_lite',
    class: 'stack-overflow',
  },
];

export const getDefaultSortingForTab = (tab: ProfilesSources): SortParams => {
  const shortCode = pipe(
    find(propEq(tab, 'path') as UnaryOperator<Tab, boolean>) as UnaryOperator<Tab[], Tab>,
    prop('defaultSorting') as UnaryOperator<Tab, string>,
  )(profileTabs);
  return pipe(
    find(propEq(shortCode, 'shortCode')) as UnaryOperator<
      {
        value: SortParams;
        name: string;
        shortCode: string;
      }[],
      {
        value: SortParams;
        name: string;
        shortCode: string;
      }
    >,
    prop('value') as UnaryOperator<
      {
        value: SortParams;
        name: string;
        shortCode: string;
      },
      SortParams
    >,
  )(profileSortOptions);
};

export const keywordsForHighlight = {
  lookingJob: [
    'Open for new opportunities',
    'Open for opportunities',
    'Open for new job opportunities',
    'Open for new job',
    'Open for job',
    'Looking for',
    'Открыт к предложениям',
    'Открыт к новым предложениям',
    'В активном поиске работы',
    'В поиске работы',
    'Шукає роботу',
    'Шукаю роботу',
    'Відкритий до пропозицій',
  ],
  remoteWork: ['Удаленная работа', 'Віддалена робота', 'Remote', 'Upwork', 'Odesk', 'Freelancer'],
};
