/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import { DirectoriesSystemActions } from '@app-core/directories/actions/directories.system.actions';
import { SuccessNotificationAction } from '@app-shared/actions/internal-notification.actions';
import { DictionaryItem, DirectoryFieldType } from '@app-shared/models';
import { DictionaryService } from '@app-shared/services/dictionary/dictionary.service';

@Injectable()
export class NewEntityDirectoryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dictionaryService: DictionaryService,
  ) {}

  public createNewCity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CreateNewCityAction),
      exhaustMap(({ googlePlaceId, name }) =>
        this.dictionaryService.createNewCity(googlePlaceId, name).pipe(
          mergeMap((city) => [
            AddNewEntitySystemActions.SaveNewCitySuccessAction({ city }),
            SuccessNotificationAction({
              message: 'add-new-entity.effects.saved.city',
            }),
          ]),
          catchError(() => of(AddNewEntitySystemActions.SaveNewCityFailedAction())),
        ),
      ),
    ),
  );

  public deleteDirectoryItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.DeleteDirectoryItemAction),
      exhaustMap(({ id, name }) =>
        this.dictionaryService.deleteDictionaryItem(id, name).pipe(
          mergeMap(() => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.deleted.directory',
            }),
            AddNewEntitySystemActions.DeleteDirectoryItemSuccessAction(),
            DirectoriesSystemActions.RefreshDirectoryAction(),
          ]),
          catchError(() => of(AddNewEntitySystemActions.DeleteDirectoryItemFailedAction())),
        ),
      ),
    ),
  );
  public getDirectoryItemDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitAction),
      exhaustMap(({ id, directory }) =>
        this.dictionaryService.getDictionaryById(id, directory).pipe(
          map((item) =>
            AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitSuccessAction({
              item: item as DictionaryItem | DirectoryFieldType,
            }),
          ),
          catchError(() =>
            of(AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitFailedAction()),
          ),
        ),
      ),
    ),
  );
  public saveNewDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CreateNewDirectoryItemAction),
      exhaustMap(({ data, name }) =>
        this.dictionaryService.saveNewDictionaryItem(data, name).pipe(
          mergeMap(() => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.saved.directory',
            }),
            AddNewEntitySystemActions.SaveNewDirectoryItemSuccessAction(),
            DirectoriesSystemActions.RefreshDirectoryAction(),
          ]),
          catchError(() => of(AddNewEntitySystemActions.SaveNewDirectoryItemFailedAction())),
        ),
      ),
    ),
  );
  public updateDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.UpdateDirectoryItemAction),
      exhaustMap(({ data, name }) =>
        this.dictionaryService.updateDictionaryItem(data, name).pipe(
          mergeMap(() => [
            AddNewEntitySystemActions.UpdateDirectoryItemSuccessAction(),
            SuccessNotificationAction({
              message: 'add-new-entity.effects.updated.directory',
            }),
            DirectoriesSystemActions.RefreshDirectoryAction(),
          ]),
          catchError(() => of(AddNewEntitySystemActions.UpdateDirectoryItemFailedAction())),
        ),
      ),
    ),
  );
}
