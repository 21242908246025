import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { getUserLocalization } from '@app-core/root/reducer/root.reducer';
import { environment } from '@app-environment/environment';
import { RouterActions } from '@app-shared/actions/router.actions';
import { DictionaryItem, Locales, VacancyDetails } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getSelectedVacancyCandidatesCount,
  getSelectedVacancyDetails,
  getSelectedVacancyPositionCount,
  getVacanciesList,
} from '@app-shared/reducers/vacancy/vacancy.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AutocompleteSelectComponent } from '@tsp-components/autocomplete-select';
import { VacancySelectorUserActions } from '@tsp-components/vacancy-selector';
import {
  allPass,
  anyPass,
  flip,
  includes,
  isEmpty,
  isNil,
  not,
  pipe,
  pluck,
  prop,
  propOr,
} from 'ramda';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  imports: [
    AsyncPipe,
    AutocompleteSelectComponent,
    DatePipe,
    MatButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatTooltip,
    NgClass,
    RouterLink,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-vacancy-selector',
  templateUrl: './vacancy-selector.component.html',
  styleUrls: ['./vacancy-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class VacancySelectorComponent implements OnInit, OnDestroy {
  @Input()
  public theme: 'light-theme' | 'blue-theme' = 'blue-theme';
  @Output()
  public editVacancy: EventEmitter<boolean> = new EventEmitter();
  public candidatesCount$: Observable<{
    total: number;
    pairs: { label: string; count: number; type: string; url: string[] }[];
  }>;
  public openedPositions$: Observable<string>;
  public availableVacancies: DictionaryItem[] = [];
  public selectedVacancy: VacancyDetails;
  public vacancyControl = new UntypedFormControl('');
  public selectedVacancyName = '';

  public vacancyId: string | number;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private locale: Locales = 'uk';
  private vacancySlug: string;

  constructor(private readonly store: Store<State>) {}

  public ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(getVacanciesList)),
      this.store.pipe(select(getSelectedVacancyDetails)),
    ])
      .pipe(
        filter(([vacancies]) => !anyPass([isNil, isEmpty])(vacancies)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([vacancies = [], vacancy]) => {
        this.availableVacancies = [...vacancies];
        this.selectedVacancy = prop('id', vacancy) ? vacancy : null;
        const shouldAdd = allPass([
          pipe(isNil, not),
          pipe(prop('id'), flip(includes)(pluck('id', this.availableVacancies)), not),
        ])(vacancy);
        if (shouldAdd) {
          this.availableVacancies.push(vacancy);
        }
        this.vacancyId = propOr('', 'id', vacancy);
        this.vacancyControl.setValue(this.vacancyId, { onlySelf: true, emitEvent: false });
        this.vacancySlug = prop('slug', vacancy);
        this.selectedVacancyName = propOr('', 'name', vacancy);
      });

    this.store
      .pipe(select(getUserLocalization), takeUntil(this.ngUnsubscribe))
      .subscribe((locale) => {
        this.locale = locale;
      });

    this.candidatesCount$ = this.store.pipe(select(getSelectedVacancyCandidatesCount));
    this.openedPositions$ = this.store.pipe(select(getSelectedVacancyPositionCount));

    this.vacancyControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((selectedVacancy: number) => {
        this.vacancyId = selectedVacancy;
        this.store.dispatch(
          VacancySelectorUserActions.SelectVacancyAction({ id: selectedVacancy }),
        );
      });
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getExternalVacancyLink(path: string[]): string {
    return `${environment.angularSiteUrl}/vacancy/${this.vacancyId}/${path.join('/')}`;
  }
  public getCount(parameter: string): number {
    return propOr(0, parameter, this.selectedVacancy);
  }
  public getInternalVacancyLink(path: string[]) {
    return [
      '/',
      {
        outlets: {
          primary: [this.locale, 'vacancy', this.vacancyId, ...path],
          extras: null,
        },
      },
    ];
  }
  public searchUsingVacancyParams() {
    this.store.dispatch(VacancySelectorUserActions.SearchProfilesFromVacancyAction());
  }
  public shareVacancy() {
    this.store.dispatch(
      RouterActions.NavigateToPublicVacancyPageAction({ vacancySlug: this.vacancySlug }),
    );
  }

  public createNewItem() {
    this.store.dispatch(
      RouterActions.OpenCreatePopupAction({
        path: ['popup', 'add-new', 'vacancy'],
      }),
    );
  }
}
