import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  ComplicatedRequestObject,
  DateRange,
  DirectoryQueryParams,
  HistoryParams,
  ProfilesSearchParams,
  SearchParams,
  SearchParamValue,
} from '@app-shared/models';
import { stringify } from 'qs';
import { equals, is, join, mapObjIndexed, pipe, prop, when } from 'ramda';

@Injectable({ providedIn: 'root' })
export class HttpHelperService {
  public static serializeParams(
    params: HistoryParams | DirectoryQueryParams | DateRange | SearchParams | ProfilesSearchParams,
  ): HttpParams {
    return new HttpParams({ fromString: stringify(params, { encode: false }) });
  }
  public static convertArrayToString(params: SearchParams[]): SearchParams {
    return mapObjIndexed(when(is(Array), join(',')))(params);
  }
  public static serializeNestedParams(params: Params | HttpParams): ProfilesSearchParams {
    return pipe(
      prop('queryParams'),
      mapObjIndexed(
        (val: unknown, key: string) =>
          when(() => equals('complicatedRequest', key), JSON.stringify)(val) as
            | SearchParamValue
            | ComplicatedRequestObject[],
      ),
    )(params) as ProfilesSearchParams;
  }
}
