<h2 mat-dialog-title class="c-tsp-popup__title" translate>{{ popupTitle }}</h2>
<mat-dialog-content>
  @if (isLoading$ | async) {
    <div class="c-tsp-popup__loader">
      <app-spinner class="c-tsp-popup__spinner"> </app-spinner>
    </div>
  }
  @if (newRecruiterForm) {
    <form class="c-tsp-mat-form" [formGroup]="newRecruiterForm" novalidate>
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-recruiter.form.first-name.label</mat-label>
          <input
            matInput
            formControlName="firstName"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-recruiter.form.first-name.placeholder' | translate"
          />
          @if (controlHasErrorType('firstName', 'lettersOnly')) {
            <mat-error>
              <span translate>add-new-entity.new-recruiter.form.letters-only</span>
            </mat-error>
          }
          @if (controlHasErrorType('firstName', 'required')) {
            <mat-error>
              <span
                [innerHTML]="'add-new-entity.new-recruiter.form.first-name.required' | translate"
              ></span>
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-recruiter.form.last-name.label</mat-label>
          <input
            matInput
            formControlName="lastName"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-recruiter.form.last-name.placeholder' | translate"
          />
          @if (controlHasErrorType('lastName', 'lettersOnly')) {
            <mat-error>
              <span translate>add-new-entity.new-recruiter.form.letters-only</span>
            </mat-error>
          }
          @if (controlHasErrorType('lastName', 'required')) {
            <mat-error>
              <span
                [innerHTML]="'add-new-entity.new-recruiter.form.last-name.required' | translate"
              ></span>
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-recruiter.form.email.label</mat-label>
          <input
            matInput
            formControlName="email"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-recruiter.form.email.placeholder' | translate"
            appLimitInput
            [regExp]="regExp"
          />
          @if (controlHasErrorType('email', 'emailMatch')) {
            <mat-error>
              <span translate>add-new-entity.new-recruiter.form.email.incorrect</span>
            </mat-error>
          }
          @if (controlHasErrorType('email', 'required')) {
            <mat-error>
              <span
                [innerHTML]="'add-new-entity.new-recruiter.form.email.required' | translate"
              ></span>
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-recruiter.form.skype.label</mat-label>
          <input
            matInput
            formControlName="skype"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-recruiter.form.skype.placeholder' | translate"
          />
        </mat-form-field>
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-recruiter.form.telephone.label</mat-label>
          <input
            matInput
            formControlName="telephone"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-recruiter.form.telephone.placeholder' | translate"
          />
          <mat-error>
            <span [innerHTML]="'add-new-entity.new-recruiter.form.numbers-only' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>
      @if (departments) {
        <div class="c-new-recruiter-dialog__column">
          @if (selectedDepartments?.length) {
            <div class="c-new-recruiter-dialog__column">
              <label
                class="c-tsp-popup-multiple-select__label c-new-recruiter-dialog__departments-label"
                translate
                >add-new-entity.new-recruiter.form.selected-departments.label</label
              >
              <div class="c-new-recruiter-dialog__departments">
                @for (department of selectedDepartments; track department) {
                  <div class="c-new-recruiter-dialog__departments-tile">
                    <span class="c-new-recruiter-dialog__departments-name">
                      {{ department.name }}
                    </span>
                    <button
                      class="c-new-recruiter-dialog__departments-tile-remove"
                      mat-icon-button
                      (click)="removeSelectedEntity(department.id, 'recruiterDepartmentAccess')"
                    >
                      <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                }
              </div>
            </div>
          }
          <div class="c-tsp-mat-form-field__row">
            <app-multiple-select-search
              class="c-new-recruiter-dialog__group"
              label="add-new-entity.new-recruiter.form.departments.label"
              type="departments"
              placeholder="add-new-entity.new-recruiter.form.departments.placeholder"
              placeholderLabel="add-new-entity.new-recruiter.form.departments.label"
              [control]="newRecruiterForm | getFormControl: 'recruiterDepartmentAccess'"
              [options]="departments"
            >
            </app-multiple-select-search>
          </div>
        </div>
      }
      <div class="c-new-recruiter-dialog__column">
        <div class="c-tsp-mat-form-field__row">
          <app-multiple-select-search
            class="c-new-recruiter-dialog__group"
            label="add-new-entity.new-recruiter.form.subordinates.label"
            type="recruiters"
            placeholder="add-new-entity.new-recruiter.form.subordinates.placeholder"
            placeholderLabel="add-new-entity.new-recruiter.form.subordinates.label"
            [control]="newRecruiterForm | getFormControl: 'subordinates'"
            [options]="teammates"
          >
          </app-multiple-select-search>
        </div>
      </div>
      @if (recruiterCustomFields$ | async; as recruiterCustomFields) {
        <div class="c-tsp-mat-form-field__row">
          <app-custom-fields-group
            class="c-new-recruiter-dialog__group"
            [fieldControls]="recruiterCustomFields"
            formControlName="customFields"
          >
          </app-custom-fields-group>
        </div>
      }
      <div class="c-new-recruiter-dialog__types">
        @for (teammateType of teammateUserTypes; track teammateType) {
          <app-teammate-type
            [userType]="teammateType"
            [recruiterAccess]="newRecruiterForm.value"
            theme="dialog"
            (typeSelected)="onUserTypeSelected($event)"
          >
          </app-teammate-type>
        }
      </div>
      <div
        class="c-tsp-mat-form-field__row c-tsp-mat-form-field__row-center c-new-recruiter-dialog__row"
      >
        @for (access of recruiterAccesses; track access) {
          <mat-expansion-panel class="c-new-recruiter-dialog__panel">
            <mat-expansion-panel-header class="c-new-recruiter-dialog__panel-header">
              <mat-panel-title>
                <mat-checkbox
                  class="c-tsp-mat-checkbox"
                  [checked]="
                    getPanelState(access) === permissionPanelState.ALL ||
                    newRecruiterForm.get(access.name).value
                  "
                  [indeterminate]="getPanelState(access) === permissionPanelState.PARTIAL"
                  [formControlName]="access.name"
                  (change)="permissionPanelStateChanged($event.checked, access.subPermissions)"
                  (click)="onPanelCheckboxClick($event)"
                >
                  {{ access.title | translate }}
                  @if (access.tooltip) {
                    <img
                      src="./../../../../../assets/more-info-for-plans.svg"
                      class="c-new-recruiter-dialog__panel-image"
                      [matTooltip]="access.tooltip | translate"
                      alt="More info"
                    />
                  }
                </mat-checkbox>
              </mat-panel-title>
            </mat-expansion-panel-header>
            @for (subPermission of access.subPermissions; track subPermission) {
              @if (subPermission.subtitle) {
                <mat-radio-group
                  class="c-new-recruiter-dialog__radio-group"
                  [formControlName]="subPermission.name"
                >
                  @if (subPermission.tooltip) {
                    <img
                      src="./../../../../../assets/more-info-for-plans.svg"
                      class="c-new-recruiter-dialog__panel-image--center"
                      [matTooltip]="subPermission.tooltip | translate"
                      alt="More info"
                    />
                  }
                  <mat-radio-button class="c-tsp-mat-radio-button" [value]="true">
                    {{ subPermission.title | translate }}
                  </mat-radio-button>
                  <mat-radio-button class="c-tsp-mat-radio-button" [value]="false">
                    {{ subPermission.subtitle | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              } @else {
                <mat-checkbox
                  class="c-tsp-mat-checkbox c-new-recruiter-dialog__row-checkbox"
                  [formControlName]="subPermission.name"
                >
                  @if (subPermission.tooltip) {
                    <img
                      src="./../../../../../assets/more-info-for-plans.svg"
                      class="c-new-recruiter-dialog__panel-image--left"
                      [matTooltip]="subPermission.tooltip | translate"
                      alt="More info"
                    />
                  }
                  <span
                    [matTooltip]="
                      !subPermission.titleTooltip ? '' : (subPermission.titleTooltip | translate)
                    "
                  >
                    {{ subPermission.title | translate }}
                  </span>
                </mat-checkbox>
              }
            }
          </mat-expansion-panel>
        }
      </div>
    </form>
  }
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form">
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
    (click)="openClosePopup()"
  >
    <span translate>add-new-entity.new-recruiter.close.button</span>
  </button>
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
    [disabled]="formIsInvalid"
    (click)="submit()"
  >
    <span translate
      >add-new-entity.new-recruiter.{{ data.mode === 'EDIT' ? 'save' : 'send' }}.button</span
    >
  </button>
</mat-dialog-actions>
