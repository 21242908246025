import { createAction, props } from '@ngrx/store';
import { Metadata } from '@app-shared/models';

export const GetMetadataAction = createAction(
  '[Metadata Resolver] Request metadata from app resolver',
);
export const GetMetadataFailedAction = createAction(
  '[Metadata Resolver] Request for metadata failed',
);
export const GetMetadataSuccessAction = createAction(
  '[Metadata Resolver]  Metadata stored to state',
  props<{ data: Metadata }>(),
);
