import { CdkScrollable } from '@angular/cdk/scrolling';

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { DictionaryItem, SimpleRecruiter, UnaryOperator } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { equals, isNil, mapObjIndexed, pipe, pluck, reject } from 'ramda';
import { KernelDepartmentStructure } from '../../models/kernel.model';

interface KernelOrganizationAssignmentDialogData {
  recruiters: SimpleRecruiter[];
  department: KernelDepartmentStructure;
  securityGroups: (DictionaryItem & { eStuffId: string })[];
}

@Component({
  imports: [
    CdkScrollable,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'kernel-organization-assignment',
  templateUrl: './kernel-organization-assignment.component.html',
  styleUrls: ['./kernel-organization-assignment.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class KernelOrganizationAssignmentComponent implements OnInit {
  public departmentAssignmentForm?: FormGroup<{
    recruiters: FormControl<number[]>;
    securityGroups: FormControl<number[]>;
  }>;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: KernelOrganizationAssignmentDialogData,
    private readonly dialog: MatDialogRef<KernelOrganizationAssignmentComponent>,
    private readonly formBuilder: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.departmentAssignmentForm = this.formBuilder.group({
      recruiters: [pluck('id', this.data.department.recruiters)],
      securityGroups: [pluck('securityGroupId', this.data.department.securityGroups)],
    });
  }

  public closeDialog() {
    this.dialog.close();
  }

  public saveAssignment() {
    const initialData = {
      recruiters: pluck('id', this.data.department.recruiters),
      securityGroups: pluck('securityGroupId', this.data.department.securityGroups),
    };

    const formData = this.departmentAssignmentForm.value;

    if (equals(initialData, formData)) {
      this.dialog.close();
      return;
    }

    const changes = pipe(
      mapObjIndexed((val, key) =>
        equals(initialData[key], val) ? undefined : val,
      ) as UnaryOperator<
        Partial<{
          recruiters: number[];
          securityGroups: number[];
        }>,
        Partial<{
          recruiters: number[];
          securityGroups: number[];
        }>
      >,
      reject(isNil) as UnaryOperator<
        Partial<{
          recruiters: number[];
          securityGroups: number[];
        }>,
        Partial<{
          recruiters: number[];
          securityGroups: number[];
        }>
      >,
    )(formData);

    this.dialog.close({ ...changes, departmentId: this.data.department.id });
  }
}
