import { environment } from '@app-environment/environment';
import {
  DictionaryItem,
  DirectoryCustomFieldTypes,
  UnaryOperator,
  VacancyDetails,
} from '@app-shared/models';
import {
  find,
  head,
  isNil,
  pathEq,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  props,
  reject,
  unless,
  values,
} from 'ramda';

export const getIntegrationCustomFieldId = (name: string, type: 'vacancy' | 'shortList'): number =>
  pipe(
    pathOr([], ['integrations', 'customFields', type]),
    find(propEq(name, 'name')) as UnaryOperator<DictionaryItem[], DictionaryItem>,
    prop('id'),
  )(environment);
export const getIntegrationVacancyFieldByShortList = (id: number): number =>
  pipe(
    pathOr([], ['integrations', 'customFields', 'shortList']),
    find(propEq(id, 'id')),
    prop('vacancyFieldId') as UnaryOperator<DictionaryItem & { vacancyFieldId?: number }, number>,
  )(environment);

export const getIntegrationCustomFieldName = (id: number, type: 'vacancy' | 'shortList'): string =>
  pipe(
    pathOr([], ['integrations', 'customFields', type]),
    find(propEq(id, 'id')) as UnaryOperator<DictionaryItem[], DictionaryItem>,
    prop('name'),
  )(environment);

export const getVacancyCustomFieldValue = (
  id: number,
  vacancyDetails: VacancyDetails,
): string | null => {
  const possibleFieldTypes = values(DirectoryCustomFieldTypes);
  return pipe(
    propOr([], 'fieldValues'),
    find(pathEq(id, ['type', 'id'])),
    unless(
      isNil,
      pipe(props(possibleFieldTypes), reject(isNil), head as UnaryOperator<string[], string>),
    ),
  )(vacancyDetails) as string;
};
