import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HeaderInterceptor } from './headers/headers-interceptor.service';
import { LocalDataResolverService } from './local-data/local-data-resolver.service';
import { networkErrorRetryInterceptor } from './network-error-retry/network-error-retry.interceptor';
import { NetworkErrorInterceptor } from './network-error/network-error-interceptor.service';
import { RedirectInterceptorService } from './redirect/redirect-interceptor.service';
import { UrlInterceptorService } from './url/url-interceptor.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocalDataResolverService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([networkErrorRetryInterceptor])),
  ],
})
export class InterceptorsModule {}
