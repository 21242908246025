import {
  DictionaryItem,
  Entity,
  Locales,
  ProfilesSources,
  ShortList,
  SimpleUser,
  VacancyDetails,
} from '.';

export interface MailAttachment {
  createdAt: string;
  id: number;
  name: string;
  description?: string;
  updatedAt: string;
  url: string;
}

export interface MailTemplate extends DictionaryItem {
  mailAttachments?: MailAttachment[];
  body: string;
  bodySecondary: string | null;
  convertToPdf: boolean;
  createdAt: string;
  filename: string | null;
  subject: string;
  type: MailTemplateTypes;
  updatedAt: string;
}

export type MailTemplateTypes =
  | 'notification'
  | 'questionnaire'
  | 'export'
  | 'interview'
  | 'hired'
  | 'offer'
  | 'reject'
  | 'testing-task';

export interface NotificationAuthor {
  name: string;
  email: string | null;
  skype: string | null;
  phone: string | null;
  publicVacancyUrl: string;
}

export interface NotificationTargetEntity {
  isChecked: boolean;
  data: NotificationTargetContact;
}

export interface NotificationDialogData {
  attachments: MailAttachment[];
  allRecipients: NotificationTarget[];
  markedRecipients: Record<string, NotificationTargetEntity>;
  notificationType: NotificationChannel;
  templates: MailTemplate[];
  from: NotificationAuthor;
  shortList?: ShortList;
  vacancy: VacancyDetails;
  locale: Locales;
}

export interface NotificationDialogPayload {
  from: NotificationAuthor;
  recipients: NotificationTargetContact[][];
  searchQuery: string;
  template: Partial<NotificationRequestThroughNode['template']>;
  templateType?: string;
  vacancy: VacancyDetails;
}

export interface NotificationLetter {
  clicks: number;
  client?: SimpleUser | Array<unknown> | Record<string, never>;
  createdAt?: string;
  description?: string;
  id?: number;
  name?: string;
  opens: number;
}

export interface NotificationTargetsPayload {
  from: NotificationAuthor;
  ids: string[] | number[];
  source: ProfilesSources;
  vacancyId: number;
}

export interface NotificationTargetContact {
  firstName: string;
  lastName: string;
  contact: string;
  filtered?: boolean;
  sentAt?: string;
  sentBy?: DictionaryItem;
  unsubscribed?: boolean;
  unsubscribedUntil?: string;
}

export interface NotificationTarget {
  id: string;
  name: string;
  contacts: NotificationTargetContact[];
}

export interface NotificationRequestThroughNode {
  from: NotificationAuthor;
  recipients: NotificationTargetContact[][];
  searchQuery: string;
  source: ProfilesSources;
  templateType: string;
  template: Omit<MailTemplate, 'id' | 'createdAt' | 'updatedAt' | 'name'>;
  vacancy: DictionaryItem;
}

export interface NotificationResponse {
  id: number;
  recipients: number;
  rejected: number;
  processed: NotificationResult[];
}

export enum RecipientStatus {
  SENT,
  PROCESSED,
  DELIVERED,
  OPENED,
  CLICKED,
  DEFERRED,
  DROPPED,
  BOUNCED,
  SPAM,
  UNSUBSCRIBED,
}

interface NotificationRecipientEntity {
  firstName: string;
  lastName: string;
  contact: string;
  conversationId?: string;
  status?: RecipientStatus;
}

export interface NotificationEmailRecipient
  extends Entity,
    Omit<NotificationRecipientEntity, 'conversationId'> {
  bounced: number;
  bounceReason: string;
  clicked: number;
  deferred: number;
  deferResponse: string;
  delivered: number;
  dropped: number;
  dropReason: string;
  groupNotification: number;
  messageId: string;
  opened: number;
  processed: number;
  spamreport: number;
  unsubscribed: number;
}

export interface NotificationResult extends Entity, NotificationRecipientEntity {
  messageId?: string;
  conversationId?: string;
}

export type NotificationChannel = 'email' | 'linkedin';

export interface GroupNotificationEntry {
  body: string;
  id: number;
  createdAt: string;
  recruiter: DictionaryItem;
  channel: NotificationChannel;
  title: string;
  query: string;
  requested: number;
  filtered: number;
  excluded: number;
  conversations?: number;
}

export interface ProfileNotificationEntry {
  author: DictionaryItem;
  body: string;
  channel: NotificationChannel;
  contact: string;
  createdAt: string;
  emailData: {
    processed: number;
    delivered: number;
    bounced: number;
    deferred: number;
    dropped: number;
    opened: number;
    clicked: number;
    spamreport: number;
    unsubscribed: number;
  } | null;
  title: string;
  vacancy: DictionaryItem;
}
