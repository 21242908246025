@if (entityForm) {
  <form class="c-tsp-mat-form" [formGroup]="entityForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.name.label</mat-label>
        <input
          matInput
          formControlName="name"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.name.placeholder' | translate"
        />
        <mat-error>
          <span [innerHTML]="'add-new-entity.new-directory.form.name.required' | translate"></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.type.label</mat-label>
        <mat-select
          [placeholder]="'add-new-entity.new-directory.form.type.placeholder' | translate"
          formControlName="type"
          [required]="true"
        >
          @for (type of currentTypeField; track type) {
            <mat-option class="c-tsp-mat-form-field-option" [value]="type.name">
              {{ type.title | translate }}
            </mat-option>
          }
        </mat-select>
        <mat-error>
          <span [innerHTML]="'add-new-entity.new-directory.form.type.required' | translate"></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.custom-field-description</mat-label>
        <textarea
          matInput
          formControlName="description"
          [placeholder]="'add-new-entity.new-directory.form.custom-field-description' | translate"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        >
        </textarea>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-checkbox class="c-tsp-mat-checkbox" formControlName="showInPanel">
        <span translate>add-new-entity.new-directory.form.show</span>
      </mat-checkbox>
    </div>
  </form>
}
