import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DirectoryTag, DirectoryTagForm } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    NgxIntlTelInputModule,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-tag-form',
  templateUrl: './directory-tag-form.component.html',
  styleUrl: './directory-tag-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryTagFormComponent extends DirectoryEntityFormComponent {
  public entityForm?: FormGroup<DirectoryTagForm>;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public createForm(): FormGroup<DirectoryTagForm> {
    return this.formBuilder.group({
      name: new FormControl<string | null>(null, [Validators.required]),
    });
  }
  public get formDataForSymfony(): Omit<DirectoryTag, 'id' | 'global'> {
    const formValue = this.entityForm.value;

    return {
      name: formValue.name,
    };
  }
  public updateFormWithData(item: DirectoryTag) {
    this.entityForm.setValue({
      name: item.name,
    });
  }
}
