import { DictionaryItem, Entity, SubscriptionPackagesEnum } from '.';

export interface SpaceEntity extends DictionaryItem {
  portal_plan?: SubscriptionPackagesEnum;
  premium_contacts_request_left?: number;
}

export interface SpaceSettings extends Entity {
  allow_apply_from_public_website: boolean;
  days_to_deadline: number;
  download_profiles_from_linkedin: boolean;
  download_visited_profiles_to_portal: boolean;
  gdpr_should_sent_notification: boolean;
  get_first_connection_contact: boolean;
  log_events_between_vacancy_and_profile: boolean;
  mail_url: string;
  name: string;
  portal_plan?: string;
  search_duplicates_in_a_t_s: boolean;
  search_duplicates_in_console: boolean;
  search_duplicates_in_details: boolean;
  update_master_profile: boolean;
  update_other_portal_profile: boolean;
}
