import { Pipe, PipeTransform } from '@angular/core';
import { anyPass, isEmpty, isNil } from 'ramda';

import {
  NotificationEmailRecipient,
  ProfileNotificationEntry,
  RecipientStatus,
} from '@app-shared/models';
import { detectRecipientStatus } from '@app-shared/functions/notifications-modifications/notifications-modifications';

type AllowedConversions = 'string-status' | 'status-icon';

@Pipe({
  name: 'detectNotificationStatus',
  standalone: true,
})
export class DetectNotificationStatusPipe implements PipeTransform {
  public transform(
    letter: NotificationEmailRecipient | ProfileNotificationEntry['emailData'],
    conversionType?: AllowedConversions,
  ): string {
    if (anyPass([isNil, isEmpty])(letter)) {
      return `${RecipientStatus.SENT}`;
    }

    const status = detectRecipientStatus(letter);

    switch (conversionType) {
      case 'string-status':
        return this.convertMailStatusEnumToString(status);
      case 'status-icon':
        return this.getMailStatusIconClass(status);
      default:
        return `${status}`;
    }
  }

  private convertMailStatusEnumToString(status: RecipientStatus): string {
    switch (status) {
      case RecipientStatus.UNSUBSCRIBED:
        return 'unsubscribed';
      case RecipientStatus.SPAM:
        return 'spam';
      case RecipientStatus.BOUNCED:
        return 'bounced';
      case RecipientStatus.DROPPED:
        return 'dropped';
      case RecipientStatus.DEFERRED:
        return 'deferred';
      case RecipientStatus.CLICKED:
        return 'clicked';
      case RecipientStatus.OPENED:
        return 'opened';
      case RecipientStatus.DELIVERED:
        return 'delivered';
      case RecipientStatus.PROCESSED:
        return 'processed';
      case RecipientStatus.SENT:
      default:
        return 'sent';
    }
  }
  private getMailStatusIconClass(status: RecipientStatus): string {
    switch (status) {
      case RecipientStatus.UNSUBSCRIBED:
        return 'fas fa-ban';
      case RecipientStatus.SPAM:
        return 'fas fa-fire-alt';
      case RecipientStatus.BOUNCED:
        return 'fas fa-user-slash';
      case RecipientStatus.DROPPED:
        return 'fas fa-trash';
      case RecipientStatus.DEFERRED:
        return 'far fa-stop-circle';
      case RecipientStatus.CLICKED:
        return 'fas fa-envelope-open-text';
      case RecipientStatus.OPENED:
        return 'far fa-envelope-open';
      case RecipientStatus.DELIVERED:
        return 'far fa-envelope';
      case RecipientStatus.PROCESSED:
        return 'fas fa-mail-bulk';
      case RecipientStatus.SENT:
      default:
        return 'fas fa-paper-plane';
    }
  }
}
