<div class="c-profile-personal-info">
  <div
    class="c-profile-personal-info__image-container"
    [ngClass]="{
      'c-profile-personal-info__image-container-clear': !profile.image,
      'c-profile-personal-info__cursor': isProfileClickable,
    }"
    [ngStyle]="{ 'background-color': profileColor }"
    (click)="avatarClick()"
  >
    @if (profile.image) {
      <img
        class="c-profile-personal-info__image"
        [src]="profile.image"
        appFallbackSrc="./../../../../assets/default-profile-image.png"
        [alt]="profile.name"
      />
    }
    @if (!profile.image) {
      <span class="c-profile-personal-info__client-logo-initials">
        {{ profile | getInitials }}
      </span>
    }
  </div>
  <div class="c-profile-personal-info__main">
    <div class="c-profile-personal-info__header">
      <div class="c-profile-personal-info__name-wrapper">
        <div class="c-profile-personal-info__name-wrapper--short">
          <span
            class="c-profile-personal-info__name"
            [ngClass]="{
              'c-profile-personal-info__name--long': isLongName,
              'c-profile-personal-info__cursor': isProfileClickable,
            }"
            [matTooltip]="
              profileName.firstName + ' ' + (hasAccessToSecondName ? profileName.lastName : '')
            "
            matTooltipClass="c-profile-personal-info__name-tooltip"
            matTooltipDisabled="{{
              !isStringLong(
                profileName.firstName + ' ' + (hasAccessToSecondName ? profileName.lastName : '')
              )
            }}"
            [matTooltipShowDelay]="200"
            (click)="avatarClick()"
          >
            {{ profileName.firstName }} {{ hasAccessToSecondName ? profileName.lastName : '' }}
          </span>
        </div>
        @if (isExternalProfileLinkShown) {
          <div class="c-profile-personal-info__controls">
            <a
              class="c-profile-personal-info__name-button"
              [routerLink]="externalProfileLink"
              target="_blank"
            >
              <img src="../../../../assets/icon-share-alt-grey.svg" alt="Open profile page" />
            </a>
          </div>
        }
      </div>
      <div class="c-profile-personal-info__button-wrapper">
        @if (showControls) {
          <button
            class="c-profile-personal-info__edit-link"
            [matTooltip]="'profiles-common.profile-personal-info.controls.edit' | translate"
            (click)="editProfile()"
          >
            <i class="fas fa-user-edit" aria-hidden="true"> </i>
          </button>
        }
      </div>
    </div>
    @if ((profile | castToProfile).createdAt && activeTab === 'history') {
      <div class="c-profile-personal-info__created">
        <span translate>profiles-common.profile-personal-info.created</span>
        {{ (profile | castToProfile).createdAt | formatDateTime: 'relative' }}
      </div>
    }
    <div class="c-profile-personal-info__block">
      <span
        class="c-profile-personal-info__block-item"
        [ngClass]="{
          'c-profile-personal-info__block-item--bordered': profilePosition || hasEnglishLevel,
        }"
        [matTooltip]="'profiles-common.profile-personal-info.source' | translate"
      >
        <i class="c-profile-personal-info__block-item-icon fas fa-user-tag" aria-hidden="true"> </i>
        {{ profileSource | convertIdToName: sources }}
      </span>
      @if (profilePosition) {
        <span
          class="c-profile-personal-info__block-item"
          [ngClass]="{ 'c-profile-personal-info__block-item--bordered': hasEnglishLevel }"
          appHighlightSearch
          [searchParams]="searchParamsForHighlight"
          [innerText]="profilePosition"
        >
        </span>
      }
      @if (hasEnglishLevel) {
        <span class="c-profile-personal-info__block-item">
          {{ 'profiles-common.profile-personal-info.english' | translate }}
          {{ englishLevel | convertIdToName: englishLevels }}
        </span>
      }
    </div>
    <div class="c-profile-personal-info__content">
      @if (showLocation && profile.location) {
        <div class="c-profile-personal-info__location">
          <span>{{ profile.location }}</span>
          @if (previousCities.length) {
            <span
              class="c-profile-personal-info__location-history"
              [matBadge]="previousCities.length"
              [matBadgeHidden]="!previousCities.length"
              [matTooltip]="previousCitiesTooltip | translate: previousCitiesNames"
              matBadgeSize="small"
            >
              <i
                class="c-profile-personal-info__location-history fas fa-history"
                aria-hidden="true"
              >
              </i>
            </span>
          }
        </div>
      }
      <div class="c-profile-personal-info__date">
        @if (profile | castToProfile; as profileEntity) {
          @if (profileEntity.experience) {
            <div
              class="c-profile-personal-info__total-experience"
              [matTooltip]="'profiles-common.profile-personal-info.experience.label' | translate"
            >
              @if (workExperience.years !== 0) {
                <span>
                  {{ workExperience.years | pluralizeText: 'year' }}
                </span>
              }
              @if (workExperience.months !== 0) {
                <span>
                  {{ workExperience.months | pluralizeText: 'month' }}
                </span>
              }
            </div>
          }
          @if (profileEntity.birthday) {
            <div class="c-profile-personal-info__age">
              <span translate [translateParams]="{ age: personAge }">
                profiles-common.profile-personal-info.age
              </span>
              <span class="c-profile-personal-info__element-info">{{
                profileEntity.birthday | formatDateTime: 'short'
              }}</span>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
