import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { KernelShortListDialogFormComponent } from '@app-integration/kernel/components/kernel-short-list-dialog-form/kernel-short-list-dialog-form.component';
import { InfoNotificationAction } from '@app-shared/actions/internal-notification.actions';
import {
  DictionaryItem,
  DirectoryFieldType,
  ShortListDialogData,
  ShortListFormValue,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCurrencies,
  getDictionaryLoadingState,
  getRejectReasons,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { isKernelEnv } from '@app-shared/reducers/router/router.reducer';
import { RequestRejectReasonsAction } from '@app-shared/resolvers/dictionaries/dictionaries-resolve.actions';
import { select, Store } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { filter, includes } from 'ramda';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ShortListDialogCustomFieldsComponent } from '../short-list-dialog-custom-fields/short-list-dialog-custom-fields.component';
import { ShortListDialogInterviewComponent } from '../short-list-dialog-interview/short-list-dialog-interview.component';
import { ShortListDialogRejectComponent } from '../short-list-dialog-reject/short-list-dialog-reject.component';
import { ShortListDialogStore } from '../store/short-list-dialog.store';

@Component({
  imports: [
    AsyncPipe,
    CdkScrollable,
    KernelShortListDialogFormComponent,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatProgressSpinner,
    ShortListDialogCustomFieldsComponent,
    ShortListDialogInterviewComponent,
    ShortListDialogRejectComponent,

    TranslatePipe,
    TranslateDirective,
  ],
  providers: [ShortListDialogStore],
  templateUrl: './short-list-dialog.component.html',
  styleUrls: ['./short-list-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogComponent implements OnInit, OnDestroy {
  public currencies$?: Observable<DictionaryItem[]>;
  public isRejectReasonsLoading$?: Observable<boolean>;
  public rejectReasons$?: Observable<DictionaryItem[]>;
  public filteredCustomFields: DirectoryFieldType[];
  public isKernelEnv = isKernelEnv;
  public isFormInvalid = false;

  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<ShortListDialogComponent>,
    private readonly shortListDialogStore: ShortListDialogStore,
    private readonly store$: Store<State>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: ShortListDialogData,
  ) {}

  public ngOnInit(): void {
    this.shortListDialogStore.setDialogData(this.data);
    this.filteredCustomFields = filter(
      (field) => !includes(field.id, this.shortListDialogStore.kernelOfferCustomFieldsIds),
      this.data.customFields || [],
    );

    if (this.data.showRejectionFields) {
      this.store$.dispatch(RequestRejectReasonsAction());

      this.isRejectReasonsLoading$ = this.store$.pipe(select(getDictionaryLoadingState));
      this.rejectReasons$ = this.store$.pipe(select(getRejectReasons));
    }

    this.currencies$ = this.store$.pipe(select(getCurrencies));
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public closePopup(): void {
    this.dialogRef.close();
  }
  public formChanged(value: ShortListFormValue) {
    this.shortListDialogStore.patchFormValue(value);
  }
  public formStatusChanged(status: FormControlStatus) {
    this.isFormInvalid = status === 'INVALID';
  }
  public save(): void {
    this.shortListDialogStore.payloadData$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((payload) => {
        this.dialogRef.close(payload);
        this.processNotification();
      });
  }

  private processNotification() {
    if (this.data.showApprovalFields) {
      this.shortListDialogStore.formValue$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((formValue) => {
          const formingOffer = formValue.formingOffer;
          const message =
            'profile-short-list.short-list-dialog.message.approval-' +
            (formingOffer ? 'started' : 'not-required');
          this.store$.dispatch(InfoNotificationAction({ message }));
        });
    }
  }
}
