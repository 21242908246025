import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { getUserLocalization, userIsRecruiterOrAdmin } from '@app-core/root/reducer/root.reducer';
import { WarningNotificationAction } from '@app-shared/actions/internal-notification.actions';
import { State } from '@app-shared/reducers';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, map, take } from 'rxjs';

export const clientAccessCheckerGuard: CanActivateFn = (): Observable<UrlTree | boolean> => {
  const store$: Store<State> = inject(Store<State>);
  const router: Router = inject(Router);

  return combineLatest([
    store$.pipe(select(userIsRecruiterOrAdmin)),
    store$.pipe(select(getUserLocalization)),
  ]).pipe(
    map(([isRecruiterOrAdmin, userLocale]) => {
      if (!isRecruiterOrAdmin) {
        store$.dispatch(
          WarningNotificationAction({ message: 'shared.guards.access-checker.client.warning' }),
        );
      }
      return isRecruiterOrAdmin || router.createUrlTree([userLocale, 'start']);
    }),
    take(1),
  );
};
