import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, Input } from '@angular/core';

@Directive({
  selector: '[appScrollToContent]',
  standalone: true,
})
export class ScrollToContentDirective {
  @Input() public scrollToSelector: string;
  @HostListener('click')
  public onClick() {
    const element = this.document.querySelector(this.scrollToSelector);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}
}
