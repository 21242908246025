<div
  class="c-profile-dynamic-list__container"
  [ngClass]="{
    'c-profile-dynamic-list__container--show-all': showAllElements,
    'c-profile-dynamic-list__container--empty': isEmpty,
  }"
>
  <div class="c-profile-dynamic-list">
    <ng-container #listElement>
      @switch (type) {
        @case ('skill') {
          @for (element of elements | castSkillType; track trackById($index, element)) {
            <profile-skill-element
              [profileId]="profileId"
              [skill]="element"
              [skillName]="element.name || (element.id | convertIdToName: dictionary)"
              [showTooltip]="showSkillsTooltip"
            >
            </profile-skill-element>
          }
        }
        @case ('tag') {
          @for (element of elements | castTagType; track trackById($index, element)) {
            <div class="c-profile-dynamic-list__element-wrapper">
              <span class="c-profile-dynamic-list__element">
                #{{ element.id | convertIdToName: dictionary }}
              </span>
            </div>
          }
        }
      }
    </ng-container>
  </div>
  @if (showSuggested && suggestedSkills) {
    <button
      class="btn c-profile-dynamic-list__suggested"
      [matTooltip]="'profile-preview.profile-preview.suggested-skills' | translate"
      (click)="editDetails()"
    >
      +{{ suggestedSkills }}
    </button>
  }
  @if (showExpandButton) {
    <button
      class="btn btn-link c-profile-dynamic-list__button"
      (click)="toggleFullList(!showAllElements)"
    >
      @if (showAllElements) {
        <span>
          <i class="fas fa-caret-up" aria-hidden="true"> </i>
        </span>
      }
      @if (!showAllElements) {
        <span>
          <i class="fas fa-ellipsis-h" aria-hidden="true"> </i>
        </span>
      }
    </button>
  }
</div>
