<h2 mat-dialog-title class="c-kernel-organization-assignment__title">{{ data.department.name }}</h2>
<div mat-dialog-content>
  <form class="c-tsp-mat-form" [formGroup]="departmentAssignmentForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>teammates.structure.assignment.recruiter.label</mat-label>
        <mat-select
          formControlName="recruiters"
          [placeholder]="'teammates.structure.assignment.recruiter.placeholder' | translate"
          [multiple]="true"
        >
          @for (recruiter of data.recruiters; track recruiter) {
            <mat-option class="c-tsp-mat-form-field-option" [value]="recruiter.id">
              {{ recruiter.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>teammates.structure.assignment.securityGroup.label</mat-label>
        <mat-select
          formControlName="securityGroups"
          [placeholder]="'teammates.structure.assignment.securityGroup.placeholder' | translate"
          [multiple]="true"
        >
          @for (securityGroup of data.securityGroups; track securityGroup) {
            <mat-option class="c-tsp-mat-form-field-option" [value]="securityGroup.id">
              {{ securityGroup.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<mat-dialog-actions class="c-tsp-mat-popup-form c-tsp-mat-popup-form--advanced-search">
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
    (click)="closeDialog()"
  >
    <span translate>teammates.structure.assignment.button.cancel</span>
  </button>
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
    (click)="saveAssignment()"
  >
    <span translate>teammates.structure.assignment.button.save</span>
  </button>
</mat-dialog-actions>
