import { DictionaryShortList } from '@app-shared/models';
import { getShortListStatuses } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { genericResolver } from '../generic-resolver/generic-resolver.resolver';
import { GetShortListStatusesAction } from './short-list-statuses-resolve.actions';

export const shortListStatusesResolver = genericResolver<DictionaryShortList[]>(
  getShortListStatuses,
  GetShortListStatusesAction(),
  true,
);
