import {
  GroupNotificationEntry,
  HistoryList,
  NotificationEmailRecipient,
  PaginationParams,
  SearchParams,
  ShortList,
  ShortListCreateResponse,
  ShortListUpdateResponse,
  SortParams,
  VacancyActivities,
  VacancyComment,
  VacancyTabs,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddCommentSuccessfulAction = createAction(
  '[Vacancy Profiles] Comment successfully added',
  props<{ comment: VacancyComment }>(),
);
const AddCommentFailedAction = createAction(
  '[Vacancy Profiles] Error happened during adding comment',
);
const ChangeMultipleShortListStatusCompleteAction = createAction(
  '[Vacancy Profiles Effects] Change short list status of multiple candidates complete',
);
const ChangeMultipleShortListStatusFailedAction = createAction(
  '[Vacancy Profiles Effects] Change short list status of multiple candidates failed',
);
const ChangeSimpleCardViewStateCompleteAction = createAction(
  '[Vacancy Profiles Effects] Change simple card view state complete',
  props<{ status: boolean }>(),
);
const ChangeSimpleCardViewStateFailedAction = createAction(
  '[Vacancy Profiles Effects] Change simple card view state failed',
  props<{ status: boolean }>(),
);
const CleanProfilesListAction = createAction(
  '[Vacancy Profiles Cleaner Guard] Clean all related to profiles list data',
);
const CleanSelectionStateAction = createAction(
  '[Vacancy Profiles] Clean selection state collection on vacancy profiles',
);
const CreateShortListInVacancyPageFailedAction = createAction(
  '[Vacancy Profiles] Error happened during short list creation on ATS page',
);
const CreateShortListInVacancyPageSuccessAction = createAction(
  '[Vacancy Profiles] Short list on ATS page was successfully created',
  props<{ newBid: ShortListCreateResponse; profileId: string }>(),
);
const DeleteCommentSuccessfulAction = createAction(
  '[Vacancy Profiles] Comment successfully deleted',
  props<{ id: number }>(),
);
const DeleteCommentFailedAction = createAction(
  '[Vacancy Profiles] Error happened during deleting comment',
);
const DeleteHistoryEventSuccessfulAction = createAction(
  '[Vacancy Profiles] History event successfully deleted',
);
const DeleteHistoryEventFailedAction = createAction(
  '[Vacancy Profiles] Error happened during deleting history events',
);
const DownloadProfilesFailedAction = createAction(
  '[Vacancy Profiles] Error happened during downloading profiles in generated file',
);
const DownloadProfilesSuccessAction = createAction(
  '[Vacancy Profiles] Profiles in generated excel file successfully downloaded',
);
const GetNotificationsAction = createAction(
  '[Vacancy Profiles Resolver] Get completed notifications for active vacancy',
);
const GetNotificationsListFailedAction = createAction(
  '[Vacancy Profiles] Error happened on getting notification for vacancy',
);
const GetNotificationsListSuccessAction = createAction(
  '[Vacancy Profiles] Received list of notification for requested vacancy',
  props<{ notifications: GroupNotificationEntry[] }>(),
);
const GetNotificationRecipientsFailedAction = createAction(
  '[Vacancy Profiles] Error happened on getting notification recipients for vacancy',
);
const GetNotificationRecipientsSuccessAction = createAction(
  '[Vacancy Profiles] Email recipients for notification received',
  props<{ recipients: NotificationEmailRecipient[] }>(),
);
const GetProfilesForTabAction = createAction(
  '[Vacancy Profiles Resolver] Request profiles for specific tab',
);
const GetProfilesAfterUpdatingSearchParamsAction = createAction(
  '[Vacancy Profiles Effects] Request profiles after updating search params',
);
const GetVacancyActivitiesAction = createAction(
  '[Vacancy Profiles Activities Resolver] Get vacancy activities',
);
const GetVacancyActivitiesFailedAction = createAction(
  '[Vacancy Profiles Activities Resolver | Vacancy API] Error happened during requesting vacancy activities',
);
const GetVacancyActivitiesSuccessAction = createAction(
  '[Vacancy Profiles Activities Resolver | Vacancy API] Vacancy activities saved to the store',
  props<{ activities: VacancyActivities }>(),
);
const GetVacancyDetailsAction = createAction(
  '[Vacancy Profiles Resolver] Get vacancy details',
  props<{ vacancyId: number }>(),
);
const GetVacancyHistoryFailedAction = createAction(
  '[Vacancy Profiles | History Events API] Error happened during retrieving vacancy history events',
);
const GetVacancyHistorySuccessAction = createAction(
  '[Vacancy Profiles | History Events API] Vacancy history events saved to store',
  props<HistoryList>(),
);
const ManageProfilesToAnotherVacancySuccessAction = createAction(
  '[Vacancy Profiles] Profiles successfully managed',
);
const ManageProfilesToAnotherVacancyFailedAction = createAction(
  '[Vacancy Profiles] Error happened during copying or moving profiles to another vacancy',
);
const MergeMultipleProfilesFailedAction = createAction(
  '[Vacancy Profiles Effects] Error happened during attempt to merge profile duplicates',
);
const MergeMultipleProfilesSuccessAction = createAction(
  '[Vacancy Profiles Effects] Profile duplicates from the card successfully merged',
);
const MergeTwoProfilesFailedAction = createAction(
  '[Vacancy Profiles Effects] Error happened during attempt to merge profiles',
);
const MergeTwoProfilesSuccessAction = createAction(
  '[Vacancy Profiles Effects] Profiles successfully merged',
  props<{ primaryDeveloper: string; duplicateDeveloper: string }>(),
);
const NavigateToNewQueryParamsAction = createAction(
  '[Vacancy Profiles Effects] Update query params in filters',
);
const RefreshVacancyDetailsAfterEditingAction = createAction(
  '[New Entity Effects] Refresh vacancy details after editing',
  props<{ vacancyId: number }>(),
);
const RefreshVacancyProfilesAfterDeleteTalentAction = createAction(
  '[New Entity Effects] Refresh vacancy details after deleting profile',
);
const RefreshVacancyProfilesAfterEditTalentAction = createAction(
  '[New Entity Effects] Refresh vacancy profiles after editing existed talent',
);
const RefreshVacancyProfilesAfterClosingSidepanelAction = createAction(
  '[Vacancy Profiles Effects] Refresh profiles list after closing sidepanel',
);
const RemoveFromShortListInVacancyPageFailedAction = createAction(
  '[Vacancy Profiles Page] Error happened during removing from short list on ATS page',
);
const RemoveFromShortListInVacancyPageSuccessAction = createAction(
  '[Vacancy Profiles Page] Profile had removed from short list on ATS page',
  props<{ devId: string }>(),
);
const RemoveProfileFromVacancyProfilesByLonglistAction = createAction(
  '[Profile Editing Effects] Remove profile from vacancy profiles using long list data',
  props<{ id: number }>(),
);
export const ResendShortListForSecurityCheckFailedAction = createAction(
  '[Vacancy Profiles Effects] Error happened during resending short list for security check',
);
export const ResendShortListForSecurityCheckSuccessAction = createAction(
  '[Vacancy Profiles Effects] Short list was successfully resent for security check',
);
const ReturnInitialStateAction = createAction(
  '[Vacancies Profiles Cleaner Guard] Return vacancy profiles store to initial state',
);
const SetInitialStateAction = createAction(
  '[Vacancy Profiles Resolver] Set initial state',
  props<{
    paginationParams?: PaginationParams;
    vacancyId: number;
    searchParams: SearchParams;
    sortParams: SortParams;
    source: VacancyTabs;
  }>(),
);
const SetVacancyIdFromRouteAction = createAction(
  '[Vacancy Profiles Resolver] Set vacancy id from route',
  props<{ vacancyId: number }>(),
);
const ToggleProfilesLoadingStateAction = createAction(
  '[Vacancy Profiles Effects] Toggle profiles loading state',
);
const UpdateVacancyFailedAction = createAction(
  '[Vacancy Profiles Page] Error happened during updating vacancy on ATS page',
);
const UpdateShortListInVacancyPageFailedAction = createAction(
  '[Vacancy Profiles Page] Error happened during updating short list on ATS page',
  props<{ shortList: Partial<ShortList> }>(),
);
const UpdateShortListInVacancyPageSuccessAction = createAction(
  '[Vacancy Profiles Page] Short list fro profile on ATS page successfully updated',
  props<{ shortList: ShortListUpdateResponse }>(),
);

const actions = {
  AddCommentFailedAction,
  AddCommentSuccessfulAction,
  ChangeMultipleShortListStatusCompleteAction,
  ChangeMultipleShortListStatusFailedAction,
  ChangeSimpleCardViewStateCompleteAction,
  ChangeSimpleCardViewStateFailedAction,
  CleanProfilesListAction,
  CleanSelectionStateAction,
  CreateShortListInVacancyPageFailedAction,
  CreateShortListInVacancyPageSuccessAction,
  DeleteCommentFailedAction,
  DeleteCommentSuccessfulAction,
  DeleteHistoryEventFailedAction,
  DeleteHistoryEventSuccessfulAction,
  DownloadProfilesFailedAction,
  DownloadProfilesSuccessAction,
  GetNotificationRecipientsFailedAction,
  GetNotificationRecipientsSuccessAction,
  GetNotificationsAction,
  GetNotificationsListFailedAction,
  GetNotificationsListSuccessAction,
  GetProfilesAfterUpdatingSearchParamsAction,
  GetProfilesForTabAction,
  GetVacancyActivitiesAction,
  GetVacancyActivitiesFailedAction,
  GetVacancyActivitiesSuccessAction,
  GetVacancyDetailsAction,
  GetVacancyHistoryFailedAction,
  GetVacancyHistorySuccessAction,
  ManageProfilesToAnotherVacancyFailedAction,
  ManageProfilesToAnotherVacancySuccessAction,
  MergeMultipleProfilesFailedAction,
  MergeMultipleProfilesSuccessAction,
  MergeTwoProfilesFailedAction,
  MergeTwoProfilesSuccessAction,
  NavigateToNewQueryParamsAction,
  RefreshVacancyDetailsAfterEditingAction,
  RefreshVacancyProfilesAfterClosingSidepanelAction,
  RefreshVacancyProfilesAfterDeleteTalentAction,
  RefreshVacancyProfilesAfterEditTalentAction,
  RemoveFromShortListInVacancyPageFailedAction,
  RemoveFromShortListInVacancyPageSuccessAction,
  RemoveProfileFromVacancyProfilesByLonglistAction,
  ResendShortListForSecurityCheckFailedAction,
  ResendShortListForSecurityCheckSuccessAction,
  ReturnInitialStateAction,
  SetInitialStateAction,
  SetVacancyIdFromRouteAction,
  ToggleProfilesLoadingStateAction,
  UpdateVacancyFailedAction,
  UpdateShortListInVacancyPageFailedAction,
  UpdateShortListInVacancyPageSuccessAction,
};

const vacancyProfilesSystemActions = union(actions);

export type VacancyProfilesSystemActionsType = typeof vacancyProfilesSystemActions;

export const VacancyProfilesSystemActions = actions;
