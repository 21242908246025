import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { replace } from 'ramda';

@Directive({
  selector: '[appLimitInput]',
  standalone: true,
})
export class LimitInputDirective {
  @Input()
  public regExp: RegExp;
  private readonly element: HTMLElement;

  constructor(
    el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly formControl: NgControl,
  ) {
    this.element = el.nativeElement;
  }

  @HostListener('input', ['$event.target.value'])
  public onChange(value: string) {
    const newData = replace(this.regExp, '', value);
    this.renderer.setProperty(this.element, 'value', newData);
    this.formControl.control.setValue(newData);
  }
}
