import { Params } from '@angular/router';
import { DateRange, SearchParams } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const CleanExtraPopupNavigationAction = createAction(
  '[Router] Clean outlet navigation after closing short list popup',
);
const CleanExtraSidepanelNavigationAction = createAction(
  '[Router] Clean outlet navigation after closing sidepanel',
);
const NavigateAfterLoginAction = createAction(
  '[Router] Navigate to requested or default page after successful login',
  props<{ openInNewPage?: boolean; path: string }>(),
);
const NavigateToClientsListAction = createAction(
  '[Router] Navigate to clients list',
  props<{ params: Params }>(),
);
const NavigateToDashboardPageAction = createAction(
  '[Router] Navigate to dashboard page',
  props<{ params: DateRange }>(),
);
const NavigateToExternalUrlAction = createAction(
  '[Router] Navigate outside app',
  props<{ path: string }>(),
);
const NavigateToNotificationStatisticAction = createAction(
  '[Router] Navigate to kernel custom notification data page',
  props<{ params: DateRange; notificationType: string }>(),
);
const NavigateToNotificationTemplateCreationAction = createAction(
  '[Router] Navigate to notification templates page with opened creation dialog',
);
const NavigateToProfilePageAction = createAction(
  '[Router] Close sidepanel and navigate to profile page',
  props<{ id: number | string }>(),
);
const NavigateToPublicVacancyPageAction = createAction(
  '[Router] Open public vacancy page in new window',
  props<{ vacancyId: number }>(),
);
const NavigateToSearchConsoleAction = createAction(
  '[Router] Close sidepanel and navigate to search console',
  props<{ params: Params }>(),
);
const NavigateToSupportPage = createAction('[Router] Open support page in new window');
const NavigateToVacanciesListAction = createAction(
  '[Router] Navigate to vacancies list',
  props<{ params: Params }>(),
);
const NavigateToVacancyProfilesAction = createAction(
  '[Router] Clean vacancy store and navigate to vacancy profiles page',
  props<{ path: (number | string)[]; queryParams?: SearchParams }>(),
);
const OpenCreatePopupAction = createAction(
  '[Router] Open popup for creating new entity',
  props<{ path: (string | number)[] | string[] }>(),
);
const OpenDuplicatePopupAction = createAction(
  '[Router] Open popup for duplication existed entity',
  props<{ path: (string | number)[] | string[] }>(),
);
const OpenEditPopupAction = createAction(
  '[Router] Open popup for editing existed entity',
  props<{ path: (string | number)[] | string[] }>(),
);
const OpenProfileDetailsSidepanelAction = createAction(
  '[Router] Open sidepanel with profile details',
  props<{ devId: string | number; target: string[] }>(),
);
const OpenTrelloAction = createAction(
  '[Router] Open trello for creating task',
  props<{ name: string; url: string }>(),
);
const UpdateQueryParamsOnVacanciesListAction = createAction(
  '[Router] Update only query params on vacancies list page',
  props<{ params: Params }>(),
);

const actions = {
  CleanExtraPopupNavigationAction,
  CleanExtraSidepanelNavigationAction,
  NavigateAfterLoginAction,
  NavigateToClientsListAction,
  NavigateToDashboardPageAction,
  NavigateToExternalUrlAction,
  NavigateToNotificationStatisticAction,
  NavigateToNotificationTemplateCreationAction,
  NavigateToProfilePageAction,
  NavigateToPublicVacancyPageAction,
  NavigateToSearchConsoleAction,
  NavigateToSupportPage,
  NavigateToVacanciesListAction,
  NavigateToVacancyProfilesAction,
  OpenCreatePopupAction,
  OpenDuplicatePopupAction,
  OpenEditPopupAction,
  OpenProfileDetailsSidepanelAction,
  OpenTrelloAction,
  UpdateQueryParamsOnVacanciesListAction,
};

const routerActions = union(actions);

export type RouterActionsType = typeof routerActions;

export const RouterActions = actions;
