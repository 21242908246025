import { PackageAccesses, SubscriptionPackagesEnum } from '@app-shared/models';
import { isKernelEnv } from '@app-shared/reducers/router/router.reducer';

export const managerUserRoles = [
  'ROLE_ADMIN',
  'ROLE_RESEARCHER',
  'ROLE_SITE_ADMIN',
  'ROLE_PORTAL_ADMIN',
];

export const userPackageAccesses: PackageAccesses = {
  [SubscriptionPackagesEnum.plugin]: ['candidates', 'profile'],
  [SubscriptionPackagesEnum.professional]: [
    'candidates',
    'external-profiles',
    'profile',
    'profiles',
    'settings',
    'vacancies',
    'vacancy',
  ],
  [SubscriptionPackagesEnum.team]: [
    'clients',
    'dashboard',
    'directories',
    'notifications',
    'reports',
    'teammates',
    'candidates',
    'external-profiles',
    'profile',
    'profiles',
    'settings',
    'vacancies',
    'vacancy',
  ],
};

const defaultBodyVariables = [
  '{{first_name}}',
  '{{vacancy_url}}',
  '{{vacancy_name}}',
  '{{vacancy_description}}',
  '{{recruiter_full_name}}',
];
export const emailTemplatesBodyVariables = isKernelEnv
  ? [
      ...defaultBodyVariables,
      '{{offer_position}}',
      '{{initiator}}',
      '{{vacancy_city}}',
      '{{starting_date}}',
      '{{offer_salary}}',
      '{{motivation_system}}',
    ]
  : defaultBodyVariables;
