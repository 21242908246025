import { Pipe, PipeTransform } from '@angular/core';
import {
  add,
  isNil,
  keys,
  length,
  omit,
  pick,
  pipe,
  propOr,
  when,
  isEmpty,
  pickBy,
  allPass,
  complement,
  identity,
  reject,
  anyPass,
} from 'ramda';
import { SearchParams, ProfilesSearchParams } from '@app-shared/models';

@Pipe({
  name: 'calculateAsyncOptions',
  standalone: true,
})
export class CalculateAsyncOptionsPipe implements PipeTransform {
  public transform(
    value: SearchParams | ProfilesSearchParams,
    pipeParameter?: string,
    possibleFilters?: string[],
  ): number | SearchParams | ProfilesSearchParams {
    if (pipeParameter === 'profileFilters') {
      const getComplicatedFiltersCount = pipe(
        when(isNil, () => ({})),
        propOr([], 'complicatedRequest'),
        length,
      )(value);
      return pipe(
        when(isNil, () => ({})),
        pick(possibleFilters),
        omit(['vacancy', 'complicatedRequest']),
        keys,
        length,
        add(getComplicatedFiltersCount),
      )(value);
    } else if (pipeParameter === 'vacanciesFilters') {
      return pipe(
        when(isNil, () => ({})),
        reject(anyPass([isNil, isEmpty])),
        omit(['pageNumber', 'pageSize']),
        keys,
        length,
      )(value);
    } else if (pipeParameter === 'vacanciesProfilesFilters') {
      return pipe(
        when(isNil, () => ({})),
        pick([
          'bidSourceId',
          'bidStatusId',
          'bidAuthorId',
          'tagId',
          'keyword',
          'bidCreatedFrom',
          'hasSkype',
          'hasEmail',
          'hasPhone',
        ]),
        pickBy(allPass([complement(isNil), complement(isEmpty), identity])),
        keys,
        length,
      )(value);
    }
    return value;
  }
}
