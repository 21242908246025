import { CleanSelectionStateAction } from '@app-core/search-console/actions/search-console.system.actions';
import { SearchConsoleUserActions } from '@app-core/search-console/actions/search-console.user.actions';
import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';
import { VacancyProfilesUserActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.user.actions';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { ProfilesListActions } from '@app-shared/components/profiles-list/profiles-list.actions';
import { UnaryOperator } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { always, append, ifElse, mergeLeft, mergeRight, propOr, without } from 'ramda';

export interface SelectionState {
  collection: string[];
  isLoading: boolean;
}

export const initialState: SelectionState = {
  collection: [],
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(
    CleanSelectionStateAction,
    ProfilesListActions.UnselectAllAction,
    VacancyProfilesSystemActions.CleanSelectionStateAction,
    mergeLeft({ collection: [] } as Partial<SelectionState>) as UnaryOperator<
      SelectionState,
      SelectionState
    >,
  ),
  on(
    ProfilesApiActions.RequestProfilesIdsFailedAction,
    mergeLeft({ isLoading: false }) as UnaryOperator<SelectionState, SelectionState>,
  ),
  on(ProfilesApiActions.RequestProfilesIdsSuccessAction, (state, { ids }) =>
    mergeRight(state, {
      collection: ids,
      isLoading: false,
    }),
  ),
  on(
    SearchConsoleUserActions.SelectAllProfilesOnSearchConsoleAction,
    VacancyProfilesUserActions.SelectAllProfilesAction,
    mergeLeft({ isLoading: true }) as UnaryOperator<SelectionState, SelectionState>,
  ),
  on(ProfilesListActions.ToggleProfileSelectionStateAction, (state, { id, toggleState }) => {
    const existedIds = propOr([], 'collection', state);
    const collection = ifElse(always(toggleState), append(id), without([id]))(existedIds);

    return mergeRight(state, { collection });
  }),
);

export function selectionReducer(baseState: SelectionState, action: Action): SelectionState {
  return reducer(baseState, action);
}

export const selectSelectionStore = (state: State) => state.selection;
export const getSelectedIds = createSelector(selectSelectionStore, (s) => s.collection || []);
export const getSelectionLoadingState = createSelector(selectSelectionStore, (s) => s.isLoading);
