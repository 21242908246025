<div class="c-kernel-structure">
  @if (isLoading$ | async) {
    <div class="c-kernel-structure__loader">
      <app-spinner [diameter]="200"></app-spinner>
    </div>
  } @else {
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="c-kernel-structure__tree"
    >
      <mat-tree-node
        *matTreeNodeDef="let department"
        matTreeNodePadding
        class="c-kernel-structure__tree-leaf c-kernel-structure__tree-leaf--with-children"
      >
        <kernel-kernel-organization-unit
          [isExpanded]="treeControl.isExpanded(department)"
          [department]="department"
          (changeAssignments)="changeAssignments(department)"
          (toggleNode)="treeControl.toggle(department)"
        >
        </kernel-kernel-organization-unit>
      </mat-tree-node>
    </mat-tree>
  }
</div>
<ng-template #collapsedBranchIcon>
  <span class="c-kernel-structure__tree-leaf-icon">
    <i class="fa-solid fa-chevron-right" aria-hidden="true"> </i>
  </span>
</ng-template>
<ng-template #expandedBranchIcon>
  <span class="c-kernel-structure__tree-leaf-icon">
    <i class="fa-solid fa-chevron-down" aria-hidden="true"> </i>
  </span>
</ng-template>
