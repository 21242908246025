import { DictionariesEnum, DictionaryCandidateStage } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const UpdateCandidateStagesOrderAction = createAction(
  '[Directories Items List] Save new order for candidate stages',
  props<{ list: Pick<DictionaryCandidateStage, 'id' | 'position'>[] }>(),
);
const DeleteDirectoryItemAction = createAction(
  '[Directories Items List] Try to delete directory item from list',
  props<{ id: number; name: DictionariesEnum }>(),
);
const ReplaceDirectoryItemAction = createAction(
  '[Directories Items List] Replace directory item in used entities by another selected',
  props<{ existedItemId: number; name: DictionariesEnum; newItemId: number }>(),
);
const FilterCitiesDirectoryAction = createAction(
  '[Directories Items List] Filter cities directory by country or region',
  props<{ countryId: number | null; regionId: number | null }>(),
);

const actions = {
  DeleteDirectoryItemAction,
  ReplaceDirectoryItemAction,
  UpdateCandidateStagesOrderAction,
  FilterCitiesDirectoryAction,
};

const directoriesUserActions = union(actions);

export type DirectoriesUserActionsType = typeof directoriesUserActions;

export const DirectoriesUserActions = actions;
