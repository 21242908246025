import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { checkValueIsLink } from '@app-shared/functions/utilities/utilities';
import {
  CalendarPayload,
  CalendarTarget,
  DirectoryCustomFieldResponse,
  UnaryOperator,
} from '@app-shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { CastToStringPipe, FormatDateTimePipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import {
  always,
  anyPass,
  identity,
  ifElse,
  is,
  isEmpty,
  isNil,
  join,
  omit,
  pipe,
  prop,
  reject,
  test,
  values,
  when,
} from 'ramda';

@Component({
  imports: [CastToStringPipe, FormatDateTimePipe, MatButton, MatTooltip, NgClass, TranslateModule],
  selector: 'app-preview-custom-fields',
  templateUrl: './preview-custom-fields.component.html',
  styleUrls: ['./preview-custom-fields.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PreviewCustomFieldsComponent {
  @Input()
  public set customFields(data: DirectoryCustomFieldResponse[]) {
    this.filteredCustomFields = reject(
      pipe(omit(['id', 'type']), values, reject(anyPass([isNil, isEmpty])), isEmpty),
      data,
    );
  }
  @Input()
  public showCalendar = true;
  @Input()
  public showToggler = false;
  @Input()
  public vacancyId: number;
  @Output()
  public openCalendar = new EventEmitter<{
    payload: Partial<CalendarPayload>;
    target: CalendarTarget;
    event: string;
  }>();

  public isFieldsVisible = false;
  public filteredCustomFields: DirectoryCustomFieldResponse[];
  public calendarLinks: { type: CalendarTarget; className: string }[] = [
    {
      type: 'google',
      className: 'fab fa-google',
    },
    {
      type: 'office365',
      className: 'fab fa-windows',
    },
    {
      type: 'outlook',
      className: 'far fa-calendar-plus',
    },
  ];

  constructor(private readonly domSanitizer: DomSanitizer) {}

  public isValueALink(field: DirectoryCustomFieldResponse): boolean {
    return checkValueIsLink(field.value as string);
  }
  public isDateField(field: DirectoryCustomFieldResponse) {
    return field.type.type === 'date';
  }
  public getFieldValue(field: DirectoryCustomFieldResponse): string | number {
    const value = pipe(
      prop('value') as UnaryOperator<
        DirectoryCustomFieldResponse,
        string | string[] | number | boolean
      >,
      ifElse(
        is(Boolean),
        ifElse(identity as UnaryOperator<boolean, boolean>, always('+'), always('-')),
        identity,
      ) as UnaryOperator<string | string[] | number | boolean, string | string[] | number>,
      when(is(Array), pipe(reject(anyPass([isNil, isEmpty])), join(', '))) as UnaryOperator<
        string | string[] | number | boolean,
        string | number
      >,
    )(field);
    if (test(/<\w+>(.+|)<\/\w+>/gis, value as string)) {
      return this.domSanitizer.bypassSecurityTrustHtml(value as string) as string;
    }
    return value;
  }
  public onOpenCalendar(
    date: string[] | string | number | boolean,
    target: CalendarTarget,
    event: string,
  ): void {
    if (!is(String, date)) {
      return;
    }

    const dateFormat = target === 'office365' ? `yy-MM-dd'T'TT` : `yyyyLLdd'T'HHmmss`;

    const payload: Partial<CalendarPayload> = {
      date: {
        from: DateTime.fromISO(date).toFormat(dateFormat),
        to: DateTime.fromISO(date).plus({ hours: 1 }).toFormat(dateFormat),
      },
      vacancyId: this.vacancyId,
    };
    this.openCalendar.emit({ payload, target, event });
  }
}
