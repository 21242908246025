import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { mockedUser } from '@app-shared/mocks';
import { isNil } from 'ramda';
import { Observable, of } from 'rxjs';

const checkTarget = (url: string): unknown => {
  if (url === '/local/currentUser') {
    return mockedUser;
  }

  return null;
};

export const localDataInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const localData = checkTarget(req.url);

  if (req.method !== 'GET' || isNil(localData)) {
    return next(req);
  }

  return of(new HttpResponse({ status: 200, body: localData }));
};
