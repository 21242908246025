<h2 mat-dialog-title class="c-tsp-popup__title c-new-talent-dialog__title" translate>
  {{ popupTitle }}
</h2>
<mat-dialog-content class="c-new-talent-dialog">
  @if ((isLoading$ | async) || (isContactsLoading$ | async)) {
    <div class="c-tsp-popup__loader">
      <app-spinner class="c-tsp-popup__spinner"></app-spinner>
    </div>
  }
  @if (documentParsingTimer$ | async; as timer) {
    <div class="c-tsp-popup__loader c-new-talent-dialog__parsing-timer-container">
      <div class="c-new-talent-dialog__parsing-timer">
        <h4
          class="c-new-talent-dialog__parsing-timer-title"
          translate
          [translateParams]="{ time: expectedCvAnalyzeTime }"
        >
          add-new-entity.new-talent.parsing-timer.message
        </h4>
        <mat-progress-bar
          class="c-new-talent-dialog__parsing-timer-bar"
          mode="determinate"
          [value]="timer"
        >
        </mat-progress-bar>
      </div>
    </div>
  }
  <div class="c-new-talent-dialog__content">
    @if (showFullForm) {
      <nav [tabPanel]="tabPanel" mat-tab-nav-bar>
        @for (template of templates; track template.index) {
          @if (!isTabInvisible(template.index)) {
            <a
              mat-tab-link
              class="c-new-talent-dialog__tab"
              [active]="selectedTemplate === template.index"
              [ngClass]="{
                'c-new-talent-dialog__tab--active': selectedTemplate === template.index,
                'c-new-talent-dialog__tab--with-errors': isSectionInvalid(template.index),
              }"
              (click)="changeTemplate(template.index)"
            >
              {{ template.label | translate }}
            </a>
          }
        }
      </nav>
    }
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    @if (newTalentForm) {
      <form
        class="c-tsp-mat-form c-new-talent-dialog__form"
        [formGroup]="newTalentForm"
        novalidate
        cdkScrollable
      >
        <div [id]="'anchor-' + TalentFormTabs.INITIAL"></div>
        @if (showDropzone) {
          <div class="c-new-talent-dialog__dropzone">
            <dropzone
              [config]="dropzoneConfig"
              [message]="'add-new-entity.new-talent.dropzone.message' | translate"
              (addedFile)="onFileAdded($event)"
            >
            </dropzone>
          </div>
        }
        @if (isSimpleForm$ | async) {
          <div class="c-tsp-mat-form-field__row">
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
              floatLabel="always"
            >
              <mat-label translate>add-new-entity.new-talent.form.vacancy.label</mat-label>
              <mat-select
                formControlName="vacancy"
                [placeholder]="'add-new-entity.new-talent.form.vacancy.placeholder' | translate"
              >
                @for (vacancy of vacanciesList; track trackById($index, vacancy)) {
                  <mat-option class="c-tsp-mat-form-field-option" [value]="vacancy.id">
                    {{ vacancy.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
        <div
          class="c-tsp-mat-form c-new-talent-dialog__form-controls"
          [ngClass]="{
            'c-new-talent-dialog__form-controls--narrow': fileUrlForPreview,
          }"
        >
          @if (hasAccessToSecondName) {
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--with-button c-new-talent-dialog__form-control--third c-new-talent-dialog__form-control--surname"
              [ngClass]="{
                'c-new-talent-dialog__form-control--modified': isModified('lastName'),
              }"
              floatLabel="always"
            >
              <mat-label translate>add-new-entity.new-talent.form.last-name.label</mat-label>
              <input
                matInput
                formControlName="lastName"
                class="c-tsp-mat-form-field-input"
                [placeholder]="'add-new-entity.new-talent.form.last-name.placeholder' | translate"
              />
              @if (controlHasErrorType('lastName', 'lettersOnly')) {
                <mat-error>
                  <span translate>add-new-entity.new-talent.form.letters-only</span>
                </mat-error>
              }
              <button
                matSuffix
                mat-icon-button
                class="c-new-talent-dialog__swap-button"
                [matTooltip]="'add-new-entity.new-talent.form.swap-name' | translate"
                (click)="swapName()"
              >
                <i
                  class="fas fa-exchange-alt c-new-talent-dialog__swap-button-icon"
                  aria-hidden="true"
                ></i>
              </button>
            </mat-form-field>
          }
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third c-new-talent-dialog__form-control--first-name"
            [ngClass]="{
              'c-new-talent-dialog__form-control--modified': isModified('firstName'),
            }"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-talent.form.first-name.label</mat-label>
            <input
              matInput
              formControlName="firstName"
              class="c-tsp-mat-form-field-input"
              [placeholder]="'add-new-entity.new-talent.form.first-name.placeholder' | translate"
            />
            @if (controlHasErrorType('firstName', 'lettersOnly')) {
              <mat-error>
                <span translate>add-new-entity.new-talent.form.letters-only</span>
              </mat-error>
            }
            @if (controlHasErrorType('firstName', 'required')) {
              <mat-error>
                <span
                  [innerHTML]="'add-new-entity.new-talent.form.first-name.required' | translate"
                ></span>
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third c-new-talent-dialog__form-control--middle-name"
            [ngClass]="{
              'c-new-talent-dialog__form-control--modified': isModified('middleName'),
            }"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-talent.form.middle-name.label</mat-label>
            <input
              matInput
              formControlName="middleName"
              class="c-tsp-mat-form-field-input"
              [placeholder]="'add-new-entity.new-talent.form.middle-name.placeholder' | translate"
            />
            @if (controlHasErrorType('middleName', 'lettersOnly')) {
              <mat-error>
                <span translate>add-new-entity.new-talent.form.letters-only</span>
              </mat-error>
            }
          </mat-form-field>
          <div
            formArrayName="emails"
            class="c-tsp-mat-form c-new-talent-dialog__multi-form__block c-new-talent-dialog__form-control c-new-talent-dialog__form-control--with-button c-new-talent-dialog__form-control--half c-new-talent-dialog__form-control--emails"
          >
            <div class="c-new-talent-dialog__multi-form__block-items">
              @for (
                control of contactsFormControls('emails');
                track trackControls($index, control);
                let i = $index
              ) {
                <div [formGroupName]="i" class="c-new-talent-dialog__multi-form__block-item">
                  <mat-form-field
                    class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__multi-form c-new-talent-dialog__form-control"
                    [ngClass]="{
                      'c-new-talent-dialog__form-control--modified': isModifiedInGroup('emails', i),
                    }"
                    floatLabel="always"
                  >
                    <mat-label translate>add-new-entity.new-talent.form.email.label</mat-label>
                    <input
                      matInput
                      formControlName="email"
                      class="c-tsp-mat-form-field-input"
                      [placeholder]="'add-new-entity.new-talent.form.email.placeholder' | translate"
                      appLimitInput
                      [regExp]="regExp"
                    />
                    <div class="c-tsp-mat-form-field-input__suffix" matSuffix>
                      @if (
                        getExistedProfile('contact', 'emails', i, ['value', 'email']);
                        as existedProfile
                      ) {
                        <a
                          class="c-new-talent-dialog__form-control-matched-icons"
                          [routerLink]="[
                            '/',
                            {
                              outlets: {
                                primary: [
                                  userLocale$ | async,
                                  'profile',
                                  existedProfile.profile?.id,
                                  'details',
                                ],
                                extras: null,
                              },
                            },
                          ]"
                        >
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.exist' | translate
                            "
                          >
                            <i class="fa-solid fa-person-circle-exclamation"></i>
                          </span>
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.open'
                                | translate: { name: existedProfile.profile?.name }
                            "
                          >
                            <i class="fa-solid fa-square-up-right"></i>
                          </span>
                        </a>
                      }
                      @if (i !== 0) {
                        <button
                          mat-icon-button
                          class="c-tsp-mat-form-field__suffix-button"
                          (click)="removeContact('emails', i)"
                          [matTooltip]="'add-new-entity.new-talent.form.email.tooltip' | translate"
                        >
                          <img
                            [alt]="'add-new-entity.new-talent.form.email.alt' | translate"
                            src="../../../../assets/ion-trash-a-ionicons.svg"
                          />
                        </button>
                      }
                    </div>
                    @if (contactControlHasErrorType('email', ['emails', i], 'emailMatch')) {
                      <mat-error>
                        <span translate>add-new-entity.new-talent.form.email.incorrect</span>
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
            <button
              mat-button
              (click)="addContact('emails', 'email')"
              class="c-new-talent-dialog__add-new-button c-new-talent-dialog__form-control-button"
            >
              <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          <div
            formArrayName="phones"
            class="c-tsp-mat-form c-new-talent-dialog__multi-form__block c-new-talent-dialog__form-control c-new-talent-dialog__form-control--with-button c-new-talent-dialog__form-control--half c-new-talent-dialog__form-control--phones"
          >
            <div class="c-new-talent-dialog__multi-form__block-items">
              @for (
                control of contactsFormControls('phones');
                track trackControls($index, control);
                let i = $index
              ) {
                <div [formGroupName]="i" class="c-new-talent-dialog__multi-form__block-item">
                  <mat-form-field
                    class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__multi-form"
                    [ngClass]="{
                      'c-new-talent-dialog__form-control--modified': isModifiedInGroup('phones', i),
                    }"
                    floatLabel="always"
                  >
                    <mat-label translate>add-new-entity.new-talent.form.phone.label</mat-label>
                    <input
                      matInput
                      formControlName="phone"
                      class="c-tsp-mat-form-field-input"
                      appLimitInput
                      [regExp]="phoneRegExp"
                      [placeholder]="'add-new-entity.new-talent.form.phone.placeholder' | translate"
                    />
                    <div class="c-tsp-mat-form-field-input__suffix" matSuffix>
                      @if (
                        getExistedProfile('contact', 'phones', i, ['value', 'phone']);
                        as existedProfile
                      ) {
                        <a
                          class="c-new-talent-dialog__form-control-matched-icons"
                          [routerLink]="[
                            '/',
                            {
                              outlets: {
                                primary: [
                                  userLocale$ | async,
                                  'profile',
                                  existedProfile.profile?.id,
                                  'details',
                                ],
                                extras: null,
                              },
                            },
                          ]"
                        >
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.exist' | translate
                            "
                          >
                            <i class="fa-solid fa-person-circle-exclamation"></i>
                          </span>
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.open'
                                | translate: { name: existedProfile.profile?.name }
                            "
                          >
                            <i class="fa-solid fa-square-up-right"></i>
                          </span>
                        </a>
                      }
                      @if (i !== 0) {
                        <button
                          mat-icon-button
                          class="c-tsp-mat-form-field__suffix-button"
                          (click)="removeContact('phones', i)"
                          [matTooltip]="'add-new-entity.new-talent.form.phone.tooltip' | translate"
                        >
                          <img
                            [alt]="'add-new-entity.new-talent.form.phone.alt' | translate"
                            src="../../../../assets/ion-trash-a-ionicons.svg"
                          />
                        </button>
                      }
                    </div>

                    <mat-error>
                      <span
                        [innerHTML]="'add-new-entity.new-talent.form.numbers-only' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                </div>
              }
            </div>
            <button
              mat-button
              (click)="addContact('phones', 'phone')"
              class="c-new-talent-dialog__add-new-button c-new-talent-dialog__form-control-button"
            >
              <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          <div
            formArrayName="skypes"
            class="c-tsp-mat-form c-new-talent-dialog__multi-form__block c-new-talent-dialog__form-control c-new-talent-dialog__form-control--with-button c-new-talent-dialog__form-control--half"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('skypes'),
            }"
          >
            <div class="c-new-talent-dialog__multi-form__block-items">
              @for (
                control of contactsFormControls('skypes');
                track trackControls($index, control);
                let i = $index
              ) {
                <div [formGroupName]="i" class="c-new-talent-dialog__multi-form__block-item">
                  <mat-form-field
                    class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__multi-form"
                    [ngClass]="{
                      'c-new-talent-dialog__form-control--modified': isModifiedInGroup('skypes', i),
                    }"
                    floatLabel="always"
                  >
                    <mat-label translate>add-new-entity.new-talent.form.skype.label</mat-label>
                    <input
                      matInput
                      formControlName="skype"
                      class="c-tsp-mat-form-field-input"
                      [placeholder]="'add-new-entity.new-talent.form.skype.placeholder' | translate"
                      (change)="checkUserExistenceByContacts('skypes', 'skype', i)"
                    />
                    <div class="c-tsp-mat-form-field-input__suffix" matSuffix>
                      @if (
                        getExistedProfile('contact', 'skypes', i, ['value', 'skype']);
                        as existedProfile
                      ) {
                        <a
                          class="c-new-talent-dialog__form-control-matched-icons"
                          [routerLink]="[
                            '/',
                            {
                              outlets: {
                                primary: [
                                  userLocale$ | async,
                                  'profile',
                                  existedProfile.profile?.id,
                                  'details',
                                ],
                                extras: null,
                              },
                            },
                          ]"
                        >
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.exist' | translate
                            "
                          >
                            <i class="fa-solid fa-person-circle-exclamation"></i>
                          </span>
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.open'
                                | translate: { name: existedProfile.profile?.name }
                            "
                          >
                            <i class="fa-solid fa-square-up-right"></i>
                          </span>
                        </a>
                      }
                      @if (i !== 0) {
                        <button
                          mat-icon-button
                          class="c-tsp-mat-form-field__suffix-button"
                          (click)="removeContact('skypes', i)"
                          [matTooltip]="'add-new-entity.new-talent.form.skype.tooltip' | translate"
                        >
                          <img
                            src="../../../../assets/ion-trash-a-ionicons.svg"
                            [alt]="'add-new-entity.new-talent.form.skype.alt' | translate"
                          />
                        </button>
                      }
                    </div>
                  </mat-form-field>
                </div>
              }
            </div>
            <button
              mat-button
              (click)="addContact('skypes', 'skype')"
              class="c-new-talent-dialog__add-new-button c-new-talent-dialog__form-control-button"
            >
              <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          <div
            formArrayName="telegrams"
            class="c-tsp-mat-form c-new-talent-dialog__multi-form__block c-new-talent-dialog__form-control c-new-talent-dialog__form-control--with-button c-new-talent-dialog__form-control--half"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('telegrams'),
            }"
          >
            <div class="c-new-talent-dialog__multi-form__block-items">
              @for (
                control of contactsFormControls('telegrams');
                track trackControls($index, control);
                let i = $index
              ) {
                <div [formGroupName]="i" class="c-new-talent-dialog__multi-form__block-item">
                  <mat-form-field
                    class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__multi-form"
                    [ngClass]="{
                      'c-new-talent-dialog__form-control--modified': isModifiedInGroup(
                        'telegrams',
                        i
                      ),
                    }"
                    floatLabel="always"
                  >
                    <mat-label translate>add-new-entity.new-talent.form.telegram.label</mat-label>
                    <input
                      matInput
                      formControlName="telegram"
                      class="c-tsp-mat-form-field-input"
                      [placeholder]="
                        'add-new-entity.new-talent.form.telegram.placeholder' | translate
                      "
                      (change)="checkUserExistenceByContacts('telegrams', 'telegram', i)"
                    />
                    <div class="c-tsp-mat-form-field-input__suffix" matSuffix>
                      @if (
                        getExistedProfile('contact', 'telegrams', i, ['value', 'telegram']);
                        as existedProfile
                      ) {
                        <a
                          class="c-new-talent-dialog__form-control-matched-icons"
                          [routerLink]="[
                            '/',
                            {
                              outlets: {
                                primary: [
                                  userLocale$ | async,
                                  'profile',
                                  existedProfile.profile?.id,
                                  'details',
                                ],
                                extras: null,
                              },
                            },
                          ]"
                        >
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.exist' | translate
                            "
                          >
                            <i class="fa-solid fa-person-circle-exclamation"></i>
                          </span>
                          <span
                            [matTooltip]="
                              'add-new-entity.new-talent.matched-profile.open'
                                | translate: { name: existedProfile.profile?.name }
                            "
                          >
                            <i class="fa-solid fa-square-up-right"></i>
                          </span>
                        </a>
                      }
                      @if (i !== 0) {
                        <button
                          mat-icon-button
                          class="c-tsp-mat-form-field__suffix-button"
                          (click)="removeContact('telegrams', i)"
                          [matTooltip]="
                            'add-new-entity.new-talent.form.telegram.tooltip' | translate
                          "
                        >
                          <img src="../../../../assets/ion-trash-a-ionicons.svg" alt="Remove" />
                        </button>
                      }
                    </div>
                  </mat-form-field>
                </div>
              }
            </div>
            <button
              mat-button
              (click)="addContact('telegrams', 'telegram')"
              class="c-new-talent-dialog__add-new-button c-new-talent-dialog__form-control-button"
            >
              <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          @if (hasAccessToSocialLinks) {
            <div
              [id]="'anchor-' + TalentFormTabs.SOCIALS"
              formArrayName="socialLinks"
              class="c-tsp-mat-form c-new-talent-dialog__link-row c-new-talent-dialog__form-control c-new-talent-dialog__form-control--with-button c-new-talent-dialog__form-control--full"
              [ngClass]="{
                'c-new-talent-dialog__form-control--with-value': showControlOnTop('socialLinks'),
              }"
            >
              @if (socialLinksFormControls.length > 0) {
                <div class="c-new-talent-dialog__link-items">
                  @for (
                    control of socialLinksFormControls;
                    track trackControls($index, control);
                    let i = $index
                  ) {
                    <div class="c-new-talent-dialog__link-wrapper">
                      <div class="c-tsp-mat-form-field__row">
                        <mat-form-field
                          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline"
                          [ngClass]="{
                            'c-new-talent-dialog__form-control--modified': isModifiedInGroup(
                              'socialLinks',
                              i
                            ),
                          }"
                          floatLabel="always"
                        >
                          <mat-label translate
                            >add-new-entity.new-talent.form.social-links.label</mat-label
                          >
                          <input
                            matInput
                            formControlName="{{ i }}"
                            class="c-tsp-mat-form-field-input"
                            [placeholder]="
                              'add-new-entity.new-talent.form.social-links.placeholder' | translate
                            "
                          />
                          <div class="c-tsp-mat-form-field-input__suffix" matSuffix>
                            @if (
                              getExistedProfile('link', 'socialLinks', i, ['value']);
                              as existedProfile
                            ) {
                              <a
                                class="c-new-talent-dialog__form-control-matched-icons"
                                [routerLink]="[
                                  '/',
                                  {
                                    outlets: {
                                      primary: [
                                        userLocale$ | async,
                                        'profile',
                                        existedProfile.profile?.id,
                                        'details',
                                      ],
                                      extras: null,
                                    },
                                  },
                                ]"
                              >
                                <span
                                  [matTooltip]="
                                    'add-new-entity.new-talent.matched-profile.exist' | translate
                                  "
                                >
                                  <i class="fa-solid fa-person-circle-exclamation"></i>
                                </span>
                                <span
                                  [matTooltip]="
                                    'add-new-entity.new-talent.matched-profile.open'
                                      | translate: { name: existedProfile.profile?.name }
                                  "
                                >
                                  <i class="fa-solid fa-square-up-right"></i>
                                </span>
                              </a>
                            }
                            <button
                              mat-icon-button
                              class="c-tsp-mat-form-field__suffix-button"
                              (click)="removeSocialLink(i)"
                              [matTooltip]="
                                'add-new-entity.new-talent.form.social-links.tooltip' | translate
                              "
                            >
                              <img
                                alt="Remove Link"
                                src="../../../../assets/ion-trash-a-ionicons.svg"
                              />
                            </button>
                          </div>

                          @if (controlHasErrorType(['socialLinks', i], 'required')) {
                            <mat-error>
                              <span
                                [innerHTML]="
                                  'add-new-entity.new-talent.form.social-links.required' | translate
                                "
                              ></span>
                            </mat-error>
                          }
                        </mat-form-field>
                      </div>
                    </div>
                  }
                </div>
              }
              <button
                mat-button
                [matTooltip]="
                  'add-new-entity.new-talent.form.social-links.add-new.tooltip' | translate
                "
                (click)="addSocialLink()"
                class="c-new-talent-dialog__add-new-button c-new-talent-dialog__add-new-button-link"
                [ngClass]="{
                  'c-new-talent-dialog__add-new-button-link--full':
                    socialLinksFormControls.length === 0,
                }"
              >
                <i class="fas fa-plus" aria-hidden="true"></i>
                <span class="c-new-talent-dialog__add-new-button-link-label" translate
                  >add-new-entity.new-talent.form.social-links.add-new</span
                >
              </button>
            </div>
          }
          @if (aiSuggestionsHints?.length) {
            <div class="c-new-talent-dialog__ai-hints">
              <h4 class="c-new-talent-dialog__ai-hints-title" translate>
                add-new-entity.new-talent.analyze-ai.hints
              </h4>
              <div class="c-new-talent-dialog__ai-hints-list">
                @for (suggestion of aiSuggestionsHints; track suggestion[0]) {
                  <span
                    class="c-new-talent-dialog__ai-hints-element"
                    translate
                    [translateParams]="{ count: suggestion[1] }"
                  >
                    add-new-entity.new-talent.analyze-ai.hints.{{ suggestion[0] }}</span
                  >
                }
              </div>
              <p class="c-new-talent-dialog__ai-hints-cta" translate>
                add-new-entity.new-talent.analyze-ai.hints.cta
              </p>
            </div>
          }
          <div
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-tsp-popup-multiple-select c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('city'),
              'c-new-talent-dialog__form-control--modified': isModified('city'),
            }"
          >
            <label class="c-tsp-popup-multiple-select__label" translate
              >add-new-entity.new-talent.form.city.label</label
            >
            <input formControlName="city" class="d-none" />
            <app-google-autocomplete
              [placeholder]="'add-new-entity.new-talent.form.city.placeholder' | translate"
              [cities]="dictionaries?.cities"
              [countries]="dictionaries?.countries"
              [selectedCity]="selectedCity"
              (onSelect)="updateCity($event)"
            >
            </app-google-autocomplete>
          </div>
          <div
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-tsp-mat-date-select c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('birthday'),
              'c-new-talent-dialog__form-control--modified': isModified('birthday'),
            }"
          >
            <label class="c-tsp-popup-multiple-select__label" translate
              >add-new-entity.new-talent.form.birth.label</label
            >
            <input
              class="c-tsp-mat-input c-new-talent-dialog__input-birthday"
              formControlName="birthday"
              readonly="true"
              [owlDateTimeTrigger]="talentBirthDateFilter"
              [owlDateTime]="talentBirthDateFilter"
              [placeholder]="'add-new-entity.new-talent.form.birth.placeholder' | translate"
            />
            <owl-date-time
              #talentBirthDateFilter
              class="c-tsp-date-range-selector__dialog"
              [firstDayOfWeek]="1"
              [pickerType]="'calendar'"
            >
            </owl-date-time>
            @if (newTalentForm.value.birthday) {
              <button
                mat-icon-button
                class="c-tsp-mat-date-select__calendar-button"
                (click)="removeBirthday()"
              >
                <i class="fas fa-times c-tsp-mat-date-select__calendar-icon" aria-hidden="true"></i>
              </button>
            }
          </div>
          <app-autocomplete-select
            [dictionary]="shortListSources$ | async"
            [control]="newTalentForm | getFormControl: 'sourceId'"
            [searchFromBeginning]="false"
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('sourceId'),
              'c-new-talent-dialog__form-control--modified': isModified('sourceId'),
            }"
            customClass="c-tsp-mat-form-field-autocomplete"
            [placeholder]="
              'profile-short-list.short-list-edit-dialog.source.placeholder' | translate
            "
            label="profile-short-list.short-list-edit-dialog.source.label"
            labelType="always"
          >
          </app-autocomplete-select>
          @if (hasAccessToSalary) {
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third"
              [ngClass]="{
                'c-new-talent-dialog__form-control--with-value': showControlOnTop('currency'),
              }"
              floatLabel="always"
            >
              <mat-label translate>add-new-entity.new-talent.form.currency.label</mat-label>
              <mat-select
                formControlName="currency"
                [placeholder]="'add-new-entity.new-talent.form.currency.placeholder' | translate"
              >
                @for (currency of dictionaries?.currencies; track trackById($index, currency)) {
                  <mat-option class="c-tsp-mat-form-field-option" [value]="currency.id">
                    {{ currency.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third"
              [ngClass]="{
                'c-new-talent-dialog__form-control--with-value': showControlOnTop('rate'),
              }"
              floatLabel="always"
            >
              <mat-label translate>add-new-entity.new-talent.form.rate.label</mat-label>
              <input
                matInput
                formControlName="rate"
                class="c-tsp-mat-form-field-input"
                [placeholder]="'add-new-entity.new-talent.form.rate.placeholder' | translate"
              />
            </mat-form-field>
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--third"
              [ngClass]="{
                'c-new-talent-dialog__form-control--with-value': showControlOnTop('rateCard'),
              }"
              floatLabel="always"
            >
              <mat-label translate>add-new-entity.new-talent.form.rate-card.label</mat-label>
              <input
                matInput
                formControlName="rateCard"
                class="c-tsp-mat-form-field-input"
                [placeholder]="'add-new-entity.new-talent.form.rate-card.placeholder' | translate"
              />
            </mat-form-field>
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--full"
              [ngClass]="{
                'c-new-talent-dialog__form-control--with-value': showControlOnTop('salaryComment'),
              }"
              floatLabel="always"
            >
              <mat-label translate>add-new-entity.new-talent.form.salary-comment.label</mat-label>
              <input
                matInput
                formControlName="salaryComment"
                class="c-tsp-mat-form-field-input"
                [placeholder]="
                  'add-new-entity.new-talent.form.salary-comment.placeholder' | translate
                "
              />
            </mat-form-field>
          }
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--half"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('level'),
              'c-new-talent-dialog__form-control--modified': isModified('level'),
            }"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-talent.form.level.label</mat-label>
            <mat-select
              panelClass="c-tsp-mat-select-panel"
              formControlName="level"
              [placeholder]="'add-new-entity.new-talent.form.level.placeholder' | translate"
            >
              @for (experience of dictionaries?.experiences; track trackById($index, experience)) {
                <mat-option class="c-tsp-mat-form-field-option" [value]="experience.id">
                  {{ experience.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__form-control c-new-talent-dialog__form-control--half"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('english'),
              'c-new-talent-dialog__form-control--modified': isModified('english'),
            }"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-talent.form.english.label</mat-label>
            <mat-select
              formControlName="english"
              [placeholder]="'add-new-entity.new-talent.form.english.placeholder' | translate"
            >
              @for (level of dictionaries?.englishLevels; track trackById($index, level)) {
                <mat-option class="c-tsp-mat-form-field-option" [value]="level.id">
                  {{ level.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <div
            class="c-new-talent-dialog__form-control c-new-talent-dialog__form-control--half"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('mainSkill'),
              'c-new-talent-dialog__form-control--modified': isModified('mainSkill'),
            }"
          >
            <a
              class="c-new-talent-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-skills.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add.button' | translate }}
            </a>
            <app-autocomplete-select
              [control]="newTalentForm | getFormControl: 'mainSkill'"
              dictionaryName="mainSkills"
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline"
              customClass="c-tsp-mat-form-field-autocomplete"
              label="add-new-entity.new-talent.form.main-skill.label"
              [placeholder]="'add-new-entity.new-talent.form.main-skill.placeholder' | translate"
              labelType="always"
            >
            </app-autocomplete-select>
          </div>
          <div
            class="c-new-talent-dialog__form-control c-new-talent-dialog__form-control--half"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('speciality'),
              'c-new-talent-dialog__form-control--modified': isModified('speciality'),
            }"
          >
            <a
              class="c-new-talent-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-specialities.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add.button' | translate }}
            </a>
            <app-autocomplete-select
              [control]="newTalentForm | getFormControl: 'speciality'"
              dictionaryName="specialities"
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline"
              customClass="c-tsp-mat-form-field-autocomplete"
              label="add-new-entity.new-talent.form.speciality.label"
              [placeholder]="'add-new-entity.new-talent.form.speciality.placeholder' | translate"
              labelType="always"
            >
            </app-autocomplete-select>
          </div>
          <div
            class="c-new-talent-dialog__column c-new-talent-dialog__form-control c-new-talent-dialog__form-control--full"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('skills'),
              'c-new-talent-dialog__form-control--modified': isModified('skills'),
            }"
          >
            <div
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-tsp-popup-multiple-select"
            >
              <label class="c-tsp-popup-multiple-select__label" translate
                >add-new-entity.new-talent.form.skills.label</label
              >
              <app-tag-input
                class="c-tsp-mat-form-field-input c-new-talent-dialog__tag-input"
                [autocompleteItems]="dictionaries?.skills"
                [controlValues]="getSkills('skills')"
                tagsType="skills"
                [customPlaceholder]="
                  'add-new-entity.new-talent.form.skills.placeholder' | translate
                "
                theme="popup-theme"
                (onChange)="updateSkills($event, 'skills')"
              >
              </app-tag-input>
            </div>
            <a
              class="c-new-talent-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-skills.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add.button' | translate }}
            </a>
            @if (suggestedSkills.length && showSkillsSuggestion) {
              <ats-suggested-params
                class="c-new-talent-dialog__suggestion"
                [dictionary]="dictionaries?.skills"
                [multipleSuggestions]="true"
                [parameter]="suggestedSkills"
                (removeSuggestion)="showSkillsSuggestion = false"
                (selectSuggestedItem)="selectSuggestedSkill($event)"
              >
              </ats-suggested-params>
            }
          </div>
          <div
            class="c-new-talent-dialog__column c-new-talent-dialog__form-control c-new-talent-dialog__form-control--full"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('softSkills'),
              'c-new-talent-dialog__form-control--modified': isModified('softSkills'),
            }"
          >
            <div
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-tsp-popup-multiple-select"
            >
              <label class="c-tsp-popup-multiple-select__label" translate
                >add-new-entity.new-talent.form.soft-skills.label</label
              >
              <app-tag-input
                class="c-tsp-mat-form-field-input c-new-talent-dialog__tag-input"
                [autocompleteItems]="dictionaries?.softSkills"
                [controlValues]="getSkills('softSkills')"
                tagsType="softSkills"
                [customPlaceholder]="
                  'add-new-entity.new-talent.form.soft-skills.placeholder' | translate
                "
                theme="popup-theme"
                (onChange)="updateSkills($event, 'softSkills')"
              >
              </app-tag-input>
            </div>
            <a
              class="c-new-talent-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-skills.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add.button' | translate }}
            </a>
            @if (suggestedSoftSkills.length && showSoftSkillsSuggestion) {
              <ats-suggested-params
                class="c-new-talent-dialog__suggestion"
                [dictionary]="dictionaries?.softSkills"
                [multipleSuggestions]="true"
                [parameter]="suggestedSoftSkills"
                (removeSuggestion)="showSoftSkillsSuggestion = false"
                (selectSuggestedItem)="selectSuggestedSkill($event)"
              >
              </ats-suggested-params>
            }
          </div>
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__column c-new-talent-dialog__form-control c-new-talent-dialog__form-control--full"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value': showControlOnTop('profileSummary'),
              'c-new-talent-dialog__form-control--modified': isModified('profileSummary'),
            }"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-talent.form.profile-summary.label</mat-label>
            <textarea
              matInput
              formControlName="profileSummary"
              [placeholder]="'add-new-entity.new-talent.form.profile-summary.label' | translate"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
            >
            </textarea>
          </mat-form-field>
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-form-field--outline c-new-talent-dialog__column c-new-talent-dialog__form-control c-new-talent-dialog__form-control--full"
            [ngClass]="{
              'c-new-talent-dialog__form-control--with-value':
                showControlOnTop('insideInformation'),
            }"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-talent.form.inside-info.label</mat-label>
            <textarea
              matInput
              formControlName="insideInformation"
              [placeholder]="'add-new-entity.new-talent.form.inside-info.placeholder' | translate"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
            >
            </textarea>
          </mat-form-field>
          @if (hasAccessToCustomFields && profileCustomFields) {
            <div
              [id]="'anchor-' + TalentFormTabs.ADDITIONAL"
              class="c-tsp-mat-form-field__row c-new-talent-dialog__custom-controls"
            >
              <app-custom-fields-group
                class="c-new-talent-dialog__group"
                [customCssClass]="'c-tsp-mat-form-field--outline'"
                [fieldControls]="profileCustomFields"
                formControlName="fieldValues"
              >
              </app-custom-fields-group>
            </div>
          }
        </div>
      </form>
    }
  </div>
  @if (fileUrlForPreview) {
    <div class="c-new-talent-dialog__preview">
      @if (fileName) {
        <div class="c-new-talent-dialog__file">
          <span class="c-new-talent-dialog__file-label">
            {{ fileName }}
          </span>
          <span
            class="c-new-talent-dialog__file-icon c-new-talent-dialog__file-icon--right"
            (click)="deleteFile()"
          >
            <i class="fas fa-trash" aria-hidden="true"> </i>
          </span>
        </div>
      }
      <app-file-preview fileType="cvs" [showRefreshButton]="false" [url]="fileUrlForPreview">
      </app-file-preview>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form">
  <div class="c-new-talent-dialog__row">
    @if (data.mode === 'EDIT') {
      <button mat-button class="c-tsp-mat-popup-form__button--close" (click)="openDeletePopup()">
        <span translate>add-new-entity.new-talent.delete.button</span>
      </button>
    }
  </div>
  <div class="c-new-talent-dialog__row c-new-talent-dialog__row--right">
    @if (isFormInvalid) {
      <span class="c-tsp-mat-popup-form__message">
        <i
          class="c-tsp-mat-popup-form__message-icon fas fa-exclamation-triangle"
          aria-hidden="true"
        >
        </i>
        <span translate>add-new-entity.new-talent.save.error</span>
      </span>
    }
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
      (click)="openClosePopup()"
    >
      <span translate>add-new-entity.new-talent.close.button</span>
    </button>
    @if (data.mode === 'CREATE') {
      <button
        mat-button
        class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create-open"
        (click)="submit(true)"
      >
        <span translate>add-new-entity.new-talent.save-and-open.button</span>
      </button>
    }
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
      (click)="submit(false)"
    >
      {{
        'add-new-entity.new-talent.' + (data.mode === 'CREATE' ? 'create.button' : 'save.button')
          | translate
      }}
    </button>
  </div>
</mat-dialog-actions>
