import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SaveRequestedUrlAction } from '@app-core/auth/actions/auth.system.actions';
import { State } from '@app-shared/reducers';
import { getAuthState } from '@app-shared/reducers/auth/auth.reducer';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const isLoggedGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  routerState: RouterStateSnapshot,
): Observable<UrlTree | boolean> => {
  const store$: Store<State> = inject(Store<State>);
  const router: Router = inject(Router);

  return store$.pipe(
    select(getAuthState),
    map((authState: boolean) => {
      if (authState) {
        return authState;
      }
      store$.dispatch(SaveRequestedUrlAction({ url: routerState.url }));
      return router.parseUrl('/en/login');
    }),
  );
};
