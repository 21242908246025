import {
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  Profile,
  ShortList,
  UnaryOperator,
} from '@app-shared/models';
import {
  T,
  all,
  always,
  anyPass,
  append,
  applySpec,
  cond,
  eqBy,
  find,
  findIndex,
  has,
  ifElse,
  includes,
  intersection,
  isEmpty,
  isNil,
  not,
  omit,
  pathEq,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  reject,
  unionWith,
  update,
  values,
} from 'ramda';
import { ShortListDialogFields, ShortListDialogResult } from './short-list-modification.service';

export const FIELD_FOR_SEND_NOTIFICATION = '_k2_send_notification';
const fieldWithOnlyOneManager = '_k2_personal_data_check';
const fieldForSendingEmails = '_kernel_management_emails';
const fieldsForExtendedData = [
  '_k2_send_to_review',
  '_k2_interview_planned',
  '_k2_personal_data_check',
  '_k2_approve_final_candidate',
];
const MY_TALENTS_SOURCE_ID = 4;

export const getFieldsToShow = (attachedFieldTypes: DirectoryFieldType[]) =>
  pipe(
    pluck('name') as UnaryOperator<DirectoryFieldType[], string[]>,
    applySpec({
      showInterviewFields: includes(ShortListDialogFields.INTERVIEW),
      showRejectionFields: includes(ShortListDialogFields.REJECT),
      showReviewFields: includes(ShortListDialogFields.REVIEW),
      showApprovalFields: includes(ShortListDialogFields.APPROVE),
      showManagerField: includes(fieldForSendingEmails),
      onlyOneManager: includes(fieldWithOnlyOneManager),
    }),
  )(attachedFieldTypes);

export const getSourceId = (profile: Profile, shortListId: number): number =>
  ifElse(
    pipe(prop('applies'), anyPass([isNil, isEmpty])),
    pipe(
      propOr([], 'processingList'),
      find(propEq(shortListId, 'id')),
      pathOr(MY_TALENTS_SOURCE_ID, ['source', 'id']),
    ),
    pathOr(MY_TALENTS_SOURCE_ID, ['applies', 'source', 'id']),
  )(profile);

export const getExtendedDataForNode = (
  attachedFieldTypes: DirectoryFieldType[],
  profile: Profile,
  data?: ShortListDialogResult,
) =>
  pipe(
    pluck('name') as UnaryOperator<DirectoryFieldType[], string[]>,
    intersection(fieldsForExtendedData),
    ifElse(
      isEmpty,
      always({}),
      always({
        candidateName: profile?.name,
        candidateSecurityId: profile?.id,
        cvLink: pathOr(null, ['cvLink', '0'], data),
        recruiterComment: propOr(null, 'recruiterComment', data) as unknown as string,
      }),
    ) as UnaryOperator<
      string[],
      {
        candidateName?: string;
        candidateSecurityId?: string;
        cvLink?: string;
        recruiterComment?: string;
      }
    >,
  )(attachedFieldTypes);

export const unionFieldValues = (
  attachedFieldTypes: DirectoryFieldType[],
  data: {
    emails?: string[];
    fieldValues?: DirectoryCustomFieldResponse[];
  },
  profile: Profile,
  shortListId?: number,
): DirectoryCustomFieldResponse[] => {
  const shortList = getProfileShortList(profile, shortListId);
  const existedFieldValues: DirectoryCustomFieldResponse[] = propOr([], 'fieldValues', shortList);
  const fieldValues = getFieldValuesWithModifiedFields(
    attachedFieldTypes,
    data.emails,
    data.fieldValues,
  );

  return pipe(
    (newValues: DirectoryCustomFieldResponse[]) =>
      unionWith(
        ifElse(has('id'), eqBy(prop('id')), always(false)) as UnaryOperator<
          DirectoryCustomFieldResponse,
          boolean
        >,
        newValues,
        existedFieldValues,
      ) as unknown as DirectoryCustomFieldResponse[],
    reject(pipe(omit(['type', 'id']), values, all(anyPass([isNil, isEmpty])))),
  )(fieldValues);
};
export const getProfileShortList = (profile: Profile, shortListId: number): ShortList => {
  return cond([
    [pipe(prop('applies'), anyPass([isNil, isEmpty]), not), prop('applies')],
    [has('processingList'), pipe(prop('processingList'), find(propEq(shortListId, 'id')))],
    [T, always(null)],
  ])(profile) as ShortList;
};

// Kernel custom logic
export const getFieldValuesWithModifiedFields = (
  attachedFieldTypes: DirectoryFieldType[] = [],
  emails: string[] = [],
  fieldValues: DirectoryCustomFieldResponse[] = [],
): DirectoryCustomFieldResponse[] => {
  const fieldTypeForEmails = find(
    propEq(fieldForSendingEmails, 'name'),
    attachedFieldTypes,
  ) as DirectoryFieldType;
  if (!emails.length || !fieldTypeForEmails) {
    return fieldValues;
  }
  const existedFieldIndex = findIndex(pathEq(fieldForSendingEmails, ['type', 'name']), fieldValues);
  if (existedFieldIndex >= 0) {
    const existedField = prop(existedFieldIndex, fieldValues);
    const updatedField = { ...existedField, value: emails };
    return update(existedFieldIndex, updatedField, fieldValues);
  }

  const newField = {
    type: fieldTypeForEmails,
    value: emails,
  } as DirectoryCustomFieldResponse;
  return append(newField, fieldValues);
};
