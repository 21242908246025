export interface SubscriptionPayload {
  comment?: string;
  period?: number;
  reason?: string;
  token: string;
  action: 'SUBSCRIBE' | 'UNSUBSCRIBE';
}

export interface SubscriptionStatus {
  isUnsubscribed: boolean;
  unsubscribedUntil?: string;
}
