import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { State } from '@app-shared/reducers';
import { Store, Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

export function genericSimpleCleanerGuard(action: Action<string>): CanDeactivateFn<boolean> {
  return (): Observable<boolean> => {
    const store$: Store<State> = inject(Store<State>);

    store$.dispatch(action);

    return of(true);
  };
}
