import { Locales } from './metadata.model';

export interface DashboardVideos {
  isVisible: boolean;
  list: DashboardVideo[];
}

export interface DashboardVideo {
  image: string;
  url: string;
  title: Record<Locales, string>;
}
