import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { KernelEmployee } from '@app-integration/kernel/models/kernel.model';
import { KernelService } from '@app-integration/kernel/services/kernel.service';
import { DictionaryDepartment, UnaryOperator, VacanciesListFiltersForm } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getDepartments } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Store, select } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { MultipleSearchComponent } from '@tsp-components/multiple-select-search';
import { GetFormControlPipe } from '@tsp-pipes';
import { always, anyPass, filter, isEmpty, isNil, pipe, prop, test, when } from 'ramda';
import { Observable, Subject, debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs';

@Component({
  imports: [
    AsyncPipe,
    GetFormControlPipe,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatTooltip,
    MultipleSearchComponent,
    NgClass,
    ReactiveFormsModule,
    TranslatePipe,
  ],
  templateUrl: './kernel-vacancies-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class KernelVacanciesFiltersComponent implements OnInit, OnDestroy {
  @Input()
  public form: FormGroup<VacanciesListFiltersForm>;
  @Input()
  public showOtherFilters: boolean;
  @Output()
  public filtersChanged = new EventEmitter<void>();

  public managers$?: Observable<KernelEmployee[]>;

  public departments?: DictionaryDepartment[];
  public sortedDepartments?: DictionaryDepartment[];
  public searchValueControl = new FormControl<string>('');
  public readonly searchValue$ = new Subject<string>();

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store<State>,
    private readonly kernelService: KernelService,
  ) {}

  public ngOnInit(): void {
    this.store
      .pipe(select(getDepartments), takeUntil(this.ngUnsubscribe))
      .subscribe((departments) => {
        this.departments = departments;
        this.sortedDepartments = departments;
      });

    this.managers$ = this.searchValue$.pipe(
      startWith(''),
      debounceTime(500),
      switchMap((keyword) =>
        this.kernelService
          .getManagers(this.departmentControlValue, keyword)
          .pipe(
            map(
              when(anyPass([isNil, isEmpty]), always([])) as UnaryOperator<
                KernelEmployee[],
                KernelEmployee[]
              >,
            ),
          ),
      ),
      takeUntil(this.ngUnsubscribe),
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public get departmentControlValue(): string {
    return (this.form.get('departmentId').value as string) || '';
  }
  public get managersControlValue(): string[] {
    return this.form.get('managers').value as string[];
  }

  public sortDepartments(keyword: string) {
    const value = new RegExp(keyword, 'gi');
    this.sortedDepartments = filter(pipe(prop('name'), test(value)))(this.departments);
  }
}
