import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ClientFull,
  ClientPayload,
  ClientsList,
  PaginationParams,
  SearchParams,
} from '@app-shared/models';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private readonly http: HttpClient) {}

  public getClients(data: PaginationParams & SearchParams): Observable<ClientsList> {
    const params = HttpHelperService.serializeParams({
      count: data.pageSize.toString(),
      page: data.pageNumber.toString(),
      term: data.keywords as string[],
    });
    return this.http.get<ClientsList>('/dictionaries/clients', { params });
  }
  public getClientByDevId(id: number): Observable<ClientFull> {
    return this.http.get<ClientFull>(`/clients/${id}`);
  }
  public deleteClient(id: number): Observable<{ result: true }> {
    return this.http.delete<{ result: true }>(`/clients/${id}`);
  }
  public saveNewClient(data: Omit<ClientPayload, 'id'>): Observable<ClientFull> {
    return this.http.post<ClientFull>('/clients', data);
  }
  public updateClient(payload: ClientPayload): Observable<ClientFull> {
    return this.http.put<ClientFull>(`/clients`, payload);
  }
}
