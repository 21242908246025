import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CustomFieldsUserActions } from '@app-core/custom-fields/actions/custom-fields.user.actions';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import { DictionaryCustomFieldTypes, DirectoryFieldType } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getCustomFields } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { getCurrentPath } from '@app-shared/reducers/router/router.reducer';
import { GetStatusesCustomFieldsAction } from '@app-shared/resolvers/candidate-statuses-custom-fields/candidate-statuses-custom-fields-resolve.actions';
import { Action, Store, select } from '@ngrx/store';
import { isNil, last, pipe, split, test } from 'ramda';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

export const customFieldsResolver: ResolveFn<DirectoryFieldType[]> = (): Observable<
  DirectoryFieldType[]
> => {
  const store$: Store<State> = inject(Store<State>);

  return store$.pipe(
    select(getCurrentPath),
    filter(isNotNullOrUndefined),
    switchMap((currentPath) => {
      const currentPopup = pipe(split('('), last)(currentPath) as string;
      let customFields: DictionaryCustomFieldTypes;
      let action: Action;
      switch (true) {
        case test(/\/vacancy\)/, currentPopup):
          customFields = 'projectCustomFields';
          action = CustomFieldsUserActions.GetProjectCustomFieldsAction();
          break;
        case test(/\/client\)/, currentPopup):
          customFields = 'clientCustomFields';
          action = CustomFieldsUserActions.GetClientCustomFieldsAction();
          break;
        case test(/\/talent\//, currentPopup):
          customFields = 'profileCustomFields';
          action = CustomFieldsUserActions.GetProfileCustomFieldsAction();
          break;
        case test(/\/recruiter\)/, currentPopup):
          customFields = 'recruiterCustomFields';
          action = CustomFieldsUserActions.GetRecruiterCustomFieldsAction();
          break;
        default:
          customFields = 'statusesCustomFields';
          action = GetStatusesCustomFieldsAction();
          break;
      }
      return store$.pipe(
        select(getCustomFields(customFields)),
        tap((fields: DirectoryFieldType[]) => {
          if (isNil(fields)) {
            store$.dispatch(action);
          }
        }),
      );
    }),
    take(1),
  );
};
