import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import {
  clientAccessCheckerGuard,
  isLoggedGuard,
  moduleCleanerDeactivateGuard,
  notLoggedGuard,
} from '@app-shared/guards';
import { cleanAuthResolver } from '@app-shared/resolvers';
import { head, includes } from 'ramda';
import { ProxyRouteComponent } from './proxy-route.component';

export function localeMatcher(segments: UrlSegment[]): { consumed: UrlSegment[] } | null {
  const allowedLocales = ['uk', 'ru', 'en'];
  const segment = head(segments);
  if (!segment) {
    return null;
  }

  return includes(segment.path, allowedLocales) ? { consumed: [segment] } : null;
}

const addNewEntityModuleLoader = () =>
  import('./core/add-new-entity-popup').then((m) => m.AddNewEntityPopupModule);

const routes: Routes = [
  {
    path: 'profile',
    outlet: 'extras',
    canActivate: [isLoggedGuard],
    component: ProxyRouteComponent,
    children: [
      {
        path: 'details',
        loadChildren: () =>
          import('./core/profile-side-panel').then((m) => m.ProfileSidePanelModule),
      },
    ],
  },
  {
    path: 'vacancy',
    outlet: 'extras',
    canActivate: [isLoggedGuard],
    component: ProxyRouteComponent,
    children: [
      {
        path: 'details',
        loadChildren: () =>
          import('./core/vacancy-side-panel').then((m) => m.VacancySidePanelModule),
      },
    ],
  },
  {
    path: 'popup',
    outlet: 'extras',
    canActivate: [clientAccessCheckerGuard, isLoggedGuard],
    component: ProxyRouteComponent,
    children: [
      {
        path: 'add-new',
        data: {
          mode: 'CREATE',
        },
        loadChildren: addNewEntityModuleLoader,
      },
      {
        path: 'edit/:id',
        data: {
          mode: 'EDIT',
        },
        loadChildren: addNewEntityModuleLoader,
      },
      {
        path: 'clone/:id',
        data: {
          mode: 'CLONE',
        },
        loadChildren: addNewEntityModuleLoader,
      },
    ],
    canDeactivate: [moduleCleanerDeactivateGuard],
  },
  {
    path: 'logout',
    resolve: [cleanAuthResolver],
    loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: '/uk/login',
  },
  {
    path: 'unsubscribe/:token',
    loadChildren: () => import('./core/subscription').then((m) => m.SubscriptionModule),
  },
  {
    matcher: localeMatcher,
    children: [
      {
        path: 'login',
        canActivate: [notLoggedGuard],
        loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'registration',
        canActivate: [notLoggedGuard],
        loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'plugin-tutorial',
        canActivate: [isLoggedGuard],
        loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'success-subscription',
        loadChildren: () =>
          import('./core/success-subscription').then((m) => m.SuccessSubscriptionModule),
      },
      {
        path: '',
        canActivate: [isLoggedGuard],
        loadChildren: () => import('./core/root/root.module').then((m) => m.RootModule),
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/uk/login',
  },
  {
    path: '**',
    loadChildren: () =>
      import('./core/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
