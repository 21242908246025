import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  MatListOption,
  MatListSubheaderCssMatStyler,
  MatSelectionList,
} from '@angular/material/list';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { ALLOWED_CV_FILE_TYPES } from '@app-shared/enums/constants';
import {
  convertFileFromSymfonyToNode,
  formFileData,
} from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import { CV, FilePayload, ShortListDialogForm } from '@app-shared/models';
import { InternalNotificationsService } from '@app-shared/services/internal-notifications/internal-notifications.service';
import { NewEntityService } from '@app-shared/services/new-entity/new-entity.service';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { FilePreviewComponent } from '@tsp-components/file-preview';
import { FormatDateTimePipe } from '@tsp-pipes';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { append, last, pipe, prop, split } from 'ramda';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  imports: [
    CdkTextareaAutosize,
    DropzoneModule,
    FilePreviewComponent,
    FormatDateTimePipe,
    MatDivider,
    MatFormField,
    MatInput,
    MatLabel,
    MatListOption,
    MatListSubheaderCssMatStyler,
    MatProgressBar,
    MatSelectionList,
    MatTooltip,
    NgClass,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'short-list-dialog-review',
  templateUrl: './short-list-dialog-review.component.html',
  styleUrls: ['./short-list-dialog-review.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogReviewComponent implements OnDestroy {
  @Input()
  public cvs: CV[];
  @Input()
  public profileId: string;
  @Input()
  public form: FormGroup<Partial<ShortListDialogForm>>;
  public fileUploading = false;
  private readonly ngUnsubscribe = new Subject<void>();

  public dropzoneConfig = {
    // url to avoid error "No URL provided"
    url: 'api/cv',
    autoProcessQueue: false,
    autoQueue: false,
    acceptedFiles: ALLOWED_CV_FILE_TYPES,
  };
  public expandedElement?: CV;

  constructor(
    private readonly newEntityService: NewEntityService,
    private readonly notificationService: InternalNotificationsService,
  ) {}

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public getCvName(cv: CV): string {
    return pipe(prop('filePath'), split('/'), last)(cv) as string;
  }
  public getCvType(cv: CV): string {
    const extension = pipe(prop('filePath'), split('.'), last)(cv);
    switch (extension) {
      case 'doc':
      case 'docx':
      case 'rtf':
        return 'word';
      case 'pdf':
        return 'pdf';
      default:
        return 'alt';
    }
  }

  public hideCvPreview(): void {
    this.expandedElement = null;
  }
  public onFileAdded(file: File) {
    const formData = formFileData(file, '0', 'cv', this.profileId);

    this.fileUploading = true;

    this.newEntityService
      .uploadFile<FilePayload>(formData)
      .pipe(filter(isNotNullOrUndefined), takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          const cv = convertFileFromSymfonyToNode(result);
          this.cvs = append(cv, this.cvs);
          this.fileUploading = false;
        },
        error: (err: Error) => {
          this.notificationService.showNotification('warning', {
            message: `Unable to upload file due to ${err.message}`,
          });
          this.fileUploading = false;
        },
      });
  }
  public showCvPreview(event: MouseEvent, file: CV): void {
    event.stopPropagation();
    this.expandedElement = file;
  }
  public trackById(_index: number, element: CV) {
    return element.id;
  }
}
