import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ShortListCreateResponse,
  ShortListOperation,
  ShortListPayload,
  ShortListUpdateResponse,
} from '@app-shared/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShortListService {
  constructor(private readonly http: HttpClient) {}

  public createShortList(
    payload: Partial<ShortListPayload>,
    source: string,
  ): Observable<ShortListCreateResponse> {
    return this.http.post<ShortListCreateResponse>('/shortlist', { source, payload });
  }
  public removeShortList(shortListId: number): Observable<void> {
    return this.http.delete<void>(`/shortlist/${shortListId}`);
  }
  public resendShortListForSecurityCheck(shortListId: number): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>(
      `/custom/kernel/shortlist/${shortListId}/resend-to-security`,
      {},
    );
  }
  public updateBid(
    id: number,
    data: Partial<ShortListPayload>,
  ): Observable<ShortListUpdateResponse> {
    return this.http.put<ShortListUpdateResponse>(`/shortlist/${id}`, data);
  }
  public updateMultipleShortListStatus(
    ids: string[],
    data: Partial<ShortListPayload>,
    projectId: number,
  ): Observable<boolean> {
    return this.http.post<boolean>(`/shortlist/multiple`, { ...data, ids, projectId });
  }
  public manageProfilesToAnotherVacancy(
    action: ShortListOperation,
    payload: { profileIds: string[]; sourceId: number; targetId: number },
  ) {
    return this.http.post(`/shortlist/${action}`, payload);
  }
}
