import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { formFileData } from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import { DirectoryMailAttachmentForm, MailAttachment } from '@app-shared/models';
import { NewEntityService } from '@app-shared/services/new-entity/new-entity.service';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { FilePreviewComponent } from '@tsp-components/file-preview';
import { RichEditorComponent } from '@tsp-components/rich-editor';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { takeUntil } from 'rxjs';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

const ATTACHMENT_FILE_TYPES =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf';

@Component({
  imports: [
    DropzoneModule,
    FilePreviewComponent,
    MatError,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    NgClass,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    RichEditorComponent,
    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-mail-attachment-form',
  templateUrl: './directory-mail-attachment-form.component.html',
  styleUrl: './directory-mail-attachment-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryMailAttachmentFormComponent extends DirectoryEntityFormComponent {
  public entityForm?: FormGroup<DirectoryMailAttachmentForm>;
  public dropzoneConfig = {
    url: '/api/mail-attachment/upload',
    autoProcessQueue: false,
    acceptedFiles: ATTACHMENT_FILE_TYPES,
  };
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly fileService: NewEntityService,
  ) {
    super();
  }

  public createForm(): FormGroup<DirectoryMailAttachmentForm> {
    return this.formBuilder.group<DirectoryMailAttachmentForm>({
      name: new FormControl<string>(null, [Validators.required]),
      description: new FormControl<string>(null, [Validators.required]),
      url: new FormControl<string>(null, [Validators.required]),
    });
  }
  public get formDataForSymfony(): Omit<MailAttachment, 'id' | 'createdAt' | 'updatedAt'> {
    const formValue = this.entityForm.value;

    return {
      description: formValue.description,
      name: formValue.name,
      url: formValue.url,
    };
  }
  public get linkToFile(): string {
    return this.entityForm.get('url')?.value || null;
  }
  public get urlIsInvalid(): boolean {
    return this.entityForm.get('url')?.invalid && !this.entityForm.pristine;
  }
  public get fileName(): string {
    if (!this.linkToFile) {
      return '';
    }

    return decodeURIComponent(this.linkToFile.split('/').pop() || '');
  }
  public onRemoveFile() {
    this.entityForm.get('url')?.setValue(null);
  }
  public updateFormWithData(item: MailAttachment) {
    this.entityForm.setValue({
      description: item.description || null,
      name: item.name || null,
      url: item.url || null,
    });
  }
  public onFileAdded(file: File) {
    const safeFileName = encodeURIComponent(file.name);
    const fileToUpload = formFileData(new File([file], safeFileName, { type: file.type }), '0');
    this.fileService
      .uploadMailAttachment(fileToUpload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ url }) => {
        this.entityForm.get('url')?.setValue(url);
      });
  }
}
