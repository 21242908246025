import { Injectable } from '@angular/core';
import { kernelOfferApproveControls } from '@app-integration/kernel';
import {
  getIntegrationCustomFieldId,
  getIntegrationCustomFieldName,
  getIntegrationVacancyFieldByShortList,
} from '@app-integration/kernel/helpers';
import {
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  ShortListDialogData,
  ShortListFormValue,
  UnaryOperator,
} from '@app-shared/models';
import { ComponentStore } from '@ngrx/component-store';
import { DateTime } from 'luxon';
import {
  eqBy,
  find,
  includes,
  isNil,
  join,
  map,
  mergeRight,
  omit,
  path,
  pathEq,
  pick,
  pipe,
  prop,
  props,
  reject,
  unionWith,
} from 'ramda';

export interface ShortListDialogState {
  dialogData?: ShortListDialogData;
  formValue?: ShortListFormValue;
  vacancyFieldValues?: DirectoryCustomFieldResponse[];
}

const defaultState: ShortListDialogState = { formValue: {} };

@Injectable()
export class ShortListDialogStore extends ComponentStore<ShortListDialogState> {
  public kernelOfferCustomFieldsIds: number[] = map(
    (controlName: string) => getIntegrationCustomFieldId(controlName, 'shortList'),
    kernelOfferApproveControls,
  );
  constructor() {
    super(defaultState);
  }

  public readonly formValue$ = this.select(prop('formValue'));

  public readonly payloadData$ = this.select((state) => {
    const payload = omit(
      [
        ...kernelOfferApproveControls,
        'budgetCurrency',
        'department',
      ] as (keyof ShortListFormValue)[],
      state.formValue,
    );
    const prevFieldValues = state.dialogData.fieldValues;
    const modifiedFieldValues = this.getFieldValuesWithOfferFields(state) || [];
    const fieldValues = unionWith(eqBy(path(['type', 'id'])), modifiedFieldValues, prevFieldValues);
    return mergeRight(payload, { fieldValues }) as ShortListFormValue;
  });

  public readonly patchFormValue = this.updater((state, value: ShortListFormValue) => ({
    ...state,
    formValue: { ...state.formValue, ...value },
  }));

  public readonly setDialogData = this.updater((state, dialogData: ShortListDialogData) => ({
    ...state,
    dialogData,
  }));
  public readonly setVacancyFieldValues = this.updater(
    (state, vacancyFieldValues: DirectoryCustomFieldResponse[]) => ({
      ...state,
      vacancyFieldValues,
    }),
  );

  private getFieldValuesWithOfferFields(state: ShortListDialogState) {
    const formValue = state.formValue;
    if (formValue.candidateOfferSalary) {
      formValue.candidateOfferSalary = pipe(
        props(['candidateOfferSalary', 'budgetCurrency']) as UnaryOperator<
          ShortListFormValue,
          string[]
        >,
        join(' '),
      )(formValue);
    }
    return pipe(
      map((customField: DirectoryFieldType) => {
        const controlName = getIntegrationCustomFieldName(customField.id, 'shortList');
        if (!controlName) {
          return find(
            pathEq(customField.id, ['type', 'id']),
            formValue.fieldValues || [],
          ) as DirectoryCustomFieldResponse;
        }
        const existedField = this.getExistedFieldValueByCustomField(customField, state);
        if (existedField) {
          return this.setFormValueToExistedField(existedField, controlName, formValue);
        }

        const vacancyFieldValue = this.getVacancyFieldValueById(
          customField.id,
          state.vacancyFieldValues,
        );
        const value = formValue[controlName] || vacancyFieldValue;
        return this.createNewFieldValue(value, customField);
      }) as UnaryOperator<DirectoryFieldType[], DirectoryCustomFieldResponse[]>,
      reject(isNil) as UnaryOperator<
        DirectoryCustomFieldResponse[],
        DirectoryCustomFieldResponse[]
      >,
    )(state.dialogData?.customFields || []);
  }
  private createNewFieldValue(value: unknown, customField: DirectoryFieldType) {
    if (!value) {
      return null;
    }
    const customFieldDefinition = pick(['id', 'name', 'type'])(customField);
    const correctValue =
      customFieldDefinition.type === 'date'
        ? DateTime.fromJSDate(value as Date, { zone: 'utc' }).toISO()
        : value;

    return { value: correctValue, type: customFieldDefinition };
  }
  private setFormValueToExistedField(
    existedField: DirectoryCustomFieldResponse,
    controlName: string,
    formValue: ShortListFormValue,
  ) {
    const id: number = path(['type', 'id'], existedField);
    const value = formValue[controlName];
    return includes(id, this.kernelOfferCustomFieldsIds)
      ? { ...existedField, value }
      : existedField;
  }

  private getExistedFieldValueByCustomField(
    customField: DirectoryFieldType,
    state: ShortListDialogState,
  ): DirectoryCustomFieldResponse {
    return find(
      pathEq(customField.id, ['type', 'id']),
      state.dialogData.fieldValues || [],
    ) as DirectoryCustomFieldResponse;
  }
  private getVacancyFieldValueById(
    id: number,
    vacancyFieldValues: DirectoryCustomFieldResponse[],
  ): string {
    const vacancyFieldId = getIntegrationVacancyFieldByShortList(id);
    const fieldValue = find(pathEq(vacancyFieldId, ['type', 'id']))(
      vacancyFieldValues || [],
    ) as DirectoryCustomFieldResponse;
    return fieldValue?.value as string;
  }
}
