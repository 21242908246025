<h2 mat-dialog-title class="c-tsp-popup__title" translate>
  profile-short-list.short-list-dialog.title
</h2>
@if (isRejectReasonsLoading$ | async) {
  <div class="c-tsp-popup__loader">
    <mat-progress-spinner mode="indeterminate" class="c-tsp-popup__spinner"> </mat-progress-spinner>
  </div>
}
<mat-dialog-content class="c-short-list-dialog">
  @if (isKernelEnv) {
    <kernel-short-list-dialog-form
      [currencies]="currencies$ | async"
      [data]="data"
      (formChanged)="formChanged($event)"
      (formStatusChanged)="formStatusChanged($event)"
    >
    </kernel-short-list-dialog-form>
  }

  @if (data.showInterviewFields) {
    <short-list-dialog-interview
      [data]="data"
      (formChanged)="formChanged($event)"
      (formStatusChanged)="formStatusChanged($event)"
    >
    </short-list-dialog-interview>
  }

  @if (data.showRejectionFields) {
    <short-list-dialog-reject
      [data]="data"
      [rejectReasons]="rejectReasons$ | async"
      (formChanged)="formChanged($event)"
      (formStatusChanged)="formStatusChanged($event)"
    >
    </short-list-dialog-reject>
  }

  @if (filteredCustomFields?.length) {
    <short-list-dialog-custom-fields
      class="c-short-list-dialog__row"
      [data]="data"
      [filteredCustomFields]="filteredCustomFields"
      (formChanged)="formChanged($event)"
    >
    </short-list-dialog-custom-fields>
  }
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form">
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
    (click)="closePopup()"
  >
    <span translate>profile-short-list.short-list-edit-dialog.close.button</span>
  </button>
  <button
    mat-button
    class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
    [disabled]="isFormInvalid"
    (click)="save()"
  >
    {{
      'profile-short-list.short-list-edit-dialog.button.' +
        (data.showReviewFields ? 'send' : 'save') | translate
    }}
  </button>
</mat-dialog-actions>
