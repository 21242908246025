import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { KernelDepartmentStructure } from '../../models/kernel.model';

@Component({
  imports: [NgClass, MatIconButton],
  selector: 'kernel-kernel-organization-unit',
  templateUrl: './kernel-organization-unit.component.html',
  styleUrls: ['./kernel-organization-unit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class KernelOrganizationUnitComponent {
  @Input() public hasChildren: boolean;
  @Input() public isExpanded: boolean;
  @Input() public department: KernelDepartmentStructure;
  @Output() public changeAssignments = new EventEmitter<void>();
  @Output() public toggleNode = new EventEmitter<void>();

  public get totalEmployeesCount(): number {
    if (!this.department) {
      return 0;
    }

    return this.department.employeesCount + this.department.childrenEmployeeCount;
  }

  public get hasEmployees(): boolean {
    return this.totalEmployeesCount > 0;
  }

  public get hasAssignedRecruiters(): boolean {
    return this.department.recruiters?.length > 0;
  }

  public get hasSecurityGroups(): boolean {
    return this.department.securityGroups?.length > 0;
  }
}
