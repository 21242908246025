import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DictionaryRejectReason, DirectoryRejectReasonForm } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    NgxIntlTelInputModule,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-reject-reason-form',
  templateUrl: './directory-reject-reason-form.component.html',
  styleUrl: './directory-reject-reason-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryRejectReasonFormComponent extends DirectoryEntityFormComponent {
  public entityForm?: FormGroup<DirectoryRejectReasonForm>;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public createForm(): FormGroup<DirectoryRejectReasonForm> {
    return this.formBuilder.group<DirectoryRejectReasonForm>({
      name: new FormControl<string>(null, [Validators.required]),
      description: new FormControl<string>(null, [Validators.required]),
    });
  }
  public get formDataForSymfony(): Omit<DictionaryRejectReason, 'id' | 'shortlistCount'> {
    const formValue = this.entityForm.value;

    return {
      name: formValue.name,
      description: formValue.description,
    };
  }
  public updateFormWithData(directoryItem: DictionaryRejectReason) {
    this.entityForm.setValue({
      name: directoryItem.name,
      description: directoryItem.description,
    });
  }
}
