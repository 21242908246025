import { createAction, props } from '@ngrx/store';
import { DictionaryItem } from '@app-shared/models';

export const GetShortListSourcesAction = createAction(
  '[Short List Sources Resolver] Request short list sources',
);
export const GetShortListSourcesFailedAction = createAction(
  '[Short List Sources Resolver] Request for short list sources had failed',
);
export const GetShortListSourcesSuccessAction = createAction(
  '[Short List Sources Resolver] All short list sources stored in the state',
  props<{ sources: DictionaryItem[] }>(),
);
