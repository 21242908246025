import { createAction, props } from '@ngrx/store';
import { DictionaryShortListBoard } from '@app-shared/models';

export const GetShortListBoardsAction = createAction(
  '[Short List Boards Resolver] Request short list boards',
);
export const GetShortListBoardsFailedAction = createAction(
  '[Short List Boards Resolver] Request for short list boards had failed',
);
export const GetShortListBoardsSuccessAction = createAction(
  '[Short List Boards Resolver] Short list boards stored in the state',
  props<{ boards: DictionaryShortListBoard[] }>(),
);
