<div class="c-profile-short-list-interview-info">
  <span class="c-profile-short-list-interview-info__text" translate
    >profile-short-list.short-list-interview-info.date.title</span
  >
  <button
    class="btn btn-link c-profile-short-list-interview-info__interview-change-button"
    matTooltipPosition="above"
    [matTooltip]="'profile-short-list.short-list-interview-info.date.tooltip' | translate"
    (click)="changeInterviewDate.emit()"
  >
    {{ interviewAt | formatDateTime: 'medium' }}
    <i
      class="fas fa-pencil-alt c-profile-short-list-interview-info__interview-change-icon"
      aria-hidden="true"
    ></i>
  </button>
  <div class="c-profile-short-list-interview-info__calendar-links">
    @for (link of calendarLinks; track link) {
      <button
        class="c-profile-short-list-interview-info__calendar-link"
        [matTooltip]="link.name"
        (click)="openExternalCalendar.emit(link.type)"
      >
        <i [ngClass]="link.className" aria-hidden="true"></i>
      </button>
    }
    <short-list-send-letter-button [hasEmail]="hasEmail" (sendLetter)="sendLetter.emit()">
    </short-list-send-letter-button>
  </div>
</div>
