import { createAction, props } from '@ngrx/store';
import { SimpleRecruiter } from '@app-shared/models';

export const GetRecruitersAction = createAction(
  '[Recruiters Resolver] Request recruiters from server',
);
export const GetRecruitersFailedAction = createAction(
  '[Recruiters Resolver] Request for recruiters list failed',
);
export const GetRecruitersSuccessAction = createAction(
  '[Recruiters Resolver] Recruiters stored to state',
  props<{ recruiters: SimpleRecruiter[] }>(),
);
