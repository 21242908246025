@if (showRefreshButton) {
  <button
    class="c-file-preview-refresh"
    mat-button
    [matTooltip]="'add-new-entity.new-talent.refresh-preview.tooltip' | translate"
    [disableRipple]="true"
    (click)="setSrc()"
  >
    <i class="fa-solid fa-rotate" aria-hidden="true"></i>
  </button>
}

@if (safeSrc) {
  @switch (previewerType) {
    @case ('iframe') {
      <iframe
        #filePreviewElement
        [src]="safeSrc"
        [height]="iframeHeight"
        width="100%"
        title="Preview"
      >
      </iframe>
    }
    @case ('image') {
      <div class="c-file-preview__image-wrapper">
        <img
          id="UploadedPreview"
          class="c-file-preview__image"
          [src]="safeSrc"
          alt="Uploaded user image"
        />
      </div>
    }
    @case ('audio') {
      <audio controls>
        <source [src]="safeSrc" />
      </audio>
    }
    @default {
      <object
        #filePreviewElement
        [data]="safeSrc"
        [height]="iframeHeight"
        width="100%"
        type="application/pdf"
      >
        Candidate CV
      </object>
    }
  }
}
