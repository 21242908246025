<div class="c-custom-field-control">
  @switch (controlType) {
    @case ('bool') {
      @if (description) {
        <span class="c-custom-field-control__icon" [matTooltip]="description">
          <i class="fas fa-info-circle" aria-hidden="true"></i>
        </span>
      }
      <mat-checkbox
        class="c-tsp-mat-checkbox c-custom-field-control__checkbox"
        [formControl]="internalControl"
        (change)="changeValue($event)"
      >
        {{ controlName }}
      </mat-checkbox>
    }
    @case ('date') {
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        [ngClass]="customCssClass"
        floatLabel="always"
      >
        <mat-label>
          {{ controlName }}
          @if (description) {
            <span class="c-custom-field-control__label-icon" [matTooltip]="description">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          }
        </mat-label>
        <input
          class="c-tsp-mat-form-field-input"
          matInput
          readonly="true"
          [formControl]="internalControl"
          [owlDateTimeTrigger]="dateCustomField"
          [owlDateTime]="dateCustomField"
          [placeholder]="'add-new-entity.new-vacancy.form.select-date' | translate"
          (dateTimeChange)="changeDateValue($event.value)"
        />
        <i class="far fa-calendar-alt" matSuffix aria-hidden="true"></i>
        @if (internalControl.value) {
          <button matSuffix mat-icon-button (click)="clearValue()">
            <i class="fas fa-times" aria-hidden="true"> </i>
          </button>
        }
        <owl-date-time #dateCustomField class="c-tsp-date-range-selector__dialog"> </owl-date-time>
      </mat-form-field>
    }
    @case ('array') {
      <div
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-popup-multiple-select"
        [ngClass]="customCssClass"
      >
        <label class="c-tsp-popup-multiple-select__label">
          {{ controlName }}
          @if (description) {
            <span class="c-custom-field-control__label-icon" [matTooltip]="description">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          }
        </label>
        <app-tag-input
          class="c-tsp-mat-form-field-input c-custom-field-control__array"
          [controlValues]="{ keywords: controlKeywords }"
          tagsType="keywords"
          [customPlaceholder]="controlName"
          theme="popup-theme"
          (onChange)="updateControl($event)"
        >
        </app-tag-input>
      </div>
    }
    @case ('longtext') {
      <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label" [ngClass]="customCssClass">
        <label
          class="c-tsp-popup-multiple-select__label c-tsp-popup-multiple-select__label--description c-custom-field-control__rich-editor-label"
        >
          {{ controlName }}
          @if (description) {
            <span class="c-custom-field-control__label-icon" [matTooltip]="description">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          }
        </label>
        <div
          class="c-tsp-mat-form-field__row c-tsp-popup__description c-custom-field-control__rich-editor"
        >
          <div class="c-custom-field-control__rich-editor-wrapper">
            <app-rich-editor
              [formControl]="internalControl"
              editorConfiguration="simple"
              (contentChanged)="onChange($event)"
            >
            </app-rich-editor>
          </div>
        </div>
      </div>
    }
    @default {
      @switch (fieldType) {
        @case ('selector') {
          <kernel-custom-field-selector
            [controlId]="controlId"
            [controlName]="controlName"
            [description]="description"
            [dictionary]="dictionary"
            [internalControl]="internalControl"
            [isReadonly]="isReadonly"
            (onChange)="onChange($event)"
            (securityGroupChanged)="securityGroupChanged.emit($event)"
          >
          </kernel-custom-field-selector>
        }
        @case ('richEditor') {
          <div
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            [ngClass]="customCssClass"
          >
            <label
              class="c-tsp-popup-multiple-select__label c-tsp-popup-multiple-select__label--description c-custom-field-control__rich-editor-label"
            >
              {{ controlName }}
              @if (description) {
                <span class="c-custom-field-control__label-icon" [matTooltip]="description">
                  <i class="fas fa-info-circle" aria-hidden="true"></i>
                </span>
              }
            </label>
            <div
              class="c-tsp-mat-form-field__row c-tsp-popup__description c-custom-field-control__rich-editor"
            >
              <div class="c-custom-field-control__rich-editor-wrapper">
                <app-rich-editor
                  [formControl]="internalControl"
                  editorConfiguration="simple"
                  (contentChanged)="onChange($event)"
                >
                </app-rich-editor>
              </div>
            </div>
          </div>
        }
        @case ('textarea') {
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            [ngClass]="customCssClass"
            floatLabel="always"
          >
            <mat-label>
              {{ controlName }}
              @if (description) {
                <span class="c-custom-field-control__label-icon" [matTooltip]="description">
                  <i class="fas fa-info-circle" aria-hidden="true"></i>
                </span>
              }
            </mat-label>
            <textarea
              matInput
              [formControl]="internalControl"
              [placeholder]="controlName"
              class="c-tsp-mat-form-field-input"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="3"
              (input)="changeInputValue($event)"
            >
            </textarea>
          </mat-form-field>
        }
        @default {
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            [ngClass]="customCssClass"
            floatLabel="always"
          >
            <mat-label>
              {{ controlName }}
              @if (description) {
                <span class="c-custom-field-control__label-icon" [matTooltip]="description">
                  <i class="fas fa-info-circle" aria-hidden="true"></i>
                </span>
              }
            </mat-label>
            <input
              matInput
              [formControl]="internalControl"
              [placeholder]="controlName"
              class="c-tsp-mat-form-field-input"
              [type]="inputType"
              (input)="changeInputValue($event)"
            />
          </mat-form-field>
        }
      }
    }
  }
</div>
