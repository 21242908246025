import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '@app-shared/models';
import { isEmpty, not, path, pipe } from 'ramda';

@Pipe({
  name: 'checkEmail',
  standalone: true,
})
export class CheckEmailPipe implements PipeTransform {
  public transform(profileDetails: Profile): boolean {
    return pipe(path(['contacts', 'emails']), isEmpty, not)(profileDetails);
  }
}
