<div class="c-profile-last-comment__preview">
  @if (commentsLength) {
    <div class="c-profile-last-comment__comment-data">
      @if (commentsLength === 1) {
        <div
          class="c-profile-last-comment__comment--short"
          [ngStyle]="{ 'background-color': commentColor }"
          [innerHTML]="lastComment | safeHtml"
        ></div>
      } @else {
        <span class="c-profile-last-comment__comment-label">
          {{ commentsLength | pluralizeText: 'comment' }}
        </span>
      }
      <div
        class="c-profile-last-comment__comment--full-comment"
        [ngStyle]="{ 'background-color': commentColor }"
        (keypress)="onCommentClick.emit()"
        (click)="onCommentClick.emit()"
        [innerHTML]="lastComment | safeHtml"
      ></div>
    </div>
  } @else {
    <div
      class="c-profile-last-comment__comment-data c-profile-last-comment__comment--add-new"
      [ngClass]="{ 'c-profile-last-comment__comment--cursor': actionOpenSidePanel }"
    >
      <span
        class="c-profile-last-comment__comment-label"
        (keypress)="onCommentClick.emit()"
        (click)="onCommentClick.emit()"
      >
        <i class="far fa-plus-square" aria-hidden="true"></i>
        <span translate> profile-preview.profile-last-comment.add</span>
      </span>
    </div>
  }
</div>
