import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import {
  CalendarPayload,
  CalendarTarget,
  ShortList,
  SimpleUser,
  UnaryOperator,
} from '@app-shared/models';
import { TranslateDirective } from '@ngx-translate/core';
import { PreviewCustomFieldsComponent } from '@tsp-components/preview-custom-fields';
import { CastToStringPipe, FormatDateTimePipe } from '@tsp-pipes';
import { join, path, pathOr, pipe, props, trim } from 'ramda';

@Component({
  imports: [
    CastToStringPipe,
    FormatDateTimePipe,
    MatTooltip,
    PreviewCustomFieldsComponent,

    TranslateDirective,
  ],
  selector: 'profile-short-list-info',
  templateUrl: './short-list-info.component.html',
  styleUrls: ['./short-list-info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListInfoComponent {
  @Input()
  public hasAccessToCustomFields: boolean;
  @Input()
  public hasAccessToSalary: boolean;
  @Input()
  public shortList: ShortList;
  @Output()
  public openCalendar: EventEmitter<{
    payload: Partial<CalendarPayload>;
    target: CalendarTarget;
  }> = new EventEmitter();

  public get createdBy(): string {
    const author: SimpleUser = this.shortList.author;
    return author ? this.getName(author) : '';
  }
  public get updatedBy(): string {
    const author: SimpleUser = this.shortList.authorChangeStatus as SimpleUser;
    return author ? this.getName(author) : '';
  }
  public get addedToStatusAt(): string {
    return this.shortList.createdAt;
  }
  public get projectClientName(): string {
    return pipe(path(['project', 'client']), (u: SimpleUser) => this.getName(u))(this.shortList);
  }
  public get reasonRejectName(): string {
    return pathOr(null, ['rejectReason', 'name'], this.shortList) as string;
  }
  public get sourceName(): string {
    return pathOr('', ['source', 'name'], this.shortList) as string;
  }
  public get updatedStatusAt(): string {
    return this.shortList.statusChangedAt;
  }
  public get vacancyId(): number {
    return path(['project', 'id'], this.shortList);
  }
  private getName(user: SimpleUser): string {
    return pipe(
      props(['firstName', 'lastName']) as UnaryOperator<SimpleUser, string[]>,
      join(' '),
      trim,
    )(user);
  }
}
