import { createAction, props } from '@ngrx/store';
import { DictionaryShortList } from '@app-shared/models';

export const GetShortListStatusesAction = createAction(
  '[Short List Statuses Resolver] Request short list sources',
);
export const GetShortListStatusesFailedAction = createAction(
  '[Short List Statuses Resolver] Request for short list sources had failed',
);
export const GetShortListStatusesSuccessAction = createAction(
  '[Short List Statuses Resolver] All short list sources stored in the state',
  props<{ statuses: DictionaryShortList[] }>(),
);
