import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormControlStatus, FormGroup, Validators } from '@angular/forms';
import {
  DictionaryItem,
  ShortListDialogData,
  ShortListDialogForm,
  ShortListFormValue,
} from '@app-shared/models';
import { pathOr, pipe } from 'ramda';
import { Subject, takeUntil } from 'rxjs';
import { ShortListDialogApproveComponent } from './approve-form/short-list-dialog-approve.component';
import { ShortListDialogReviewComponent } from './cv-review-form/short-list-dialog-review.component';
import { ShortListDialogManagerialComponent } from './managerial-form/short-list-dialog-managerial.component';

@Component({
  imports: [
    ShortListDialogManagerialComponent,
    ShortListDialogApproveComponent,
    ShortListDialogReviewComponent,
  ],
  selector: 'kernel-short-list-dialog-form',
  templateUrl: './kernel-short-list-dialog-form.component.html',
  styleUrls: ['./kernel-short-list-dialog-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class KernelShortListDialogFormComponent implements OnInit, OnDestroy {
  @Input()
  public currencies: DictionaryItem[];
  @Input()
  public data: ShortListDialogData;
  @Output()
  public formChanged = new EventEmitter<ShortListFormValue>();
  @Output()
  public formStatusChanged = new EventEmitter<FormControlStatus>();
  public form: FormGroup<Partial<ShortListDialogForm>>;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    const cvLink: string = pipe(pathOr(null, ['cvs', '0', 'filePath']))(this.data);
    this.form = this.formBuilder.group<Partial<ShortListDialogForm>>({
      emails: new FormControl<string[]>([]),
      recruiterComment: new FormControl<string>(null),
      cvLink: new FormControl<string[]>([cvLink]),
      department: new FormControl<string>(null),
      budgetCurrency: new FormControl<string>(null),
      budgetExcess: new FormControl<boolean>(false),
      candidateOfferPosition: new FormControl<string>(null),
      candidateOfferSalary: new FormControl<string>(null),
      formingOffer: new FormControl<boolean>(false),
      isFixedRate: new FormControl<boolean>(false),
      motivationSystem: new FormControl<string>(null),
      offerRecruiterComment: new FormControl<string>(null),
      positionChanged: new FormControl<boolean>(false),
      workStartingAt: new FormControl<Date>(null),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.formChanged.emit(value));
    this.form.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => this.formStatusChanged.emit(status));

    if (this.data.showManagerField) {
      this.makeFieldRequired('emails');
    }
    if (this.data.showReviewFields) {
      this.makeFieldRequired('cvLink');
    }
    if (this.data.showApprovalFields) {
      this.makeFieldRequired('candidateOfferPosition');
      this.makeFieldRequired('candidateOfferSalary');
      this.makeFieldRequired('workStartingAt');
    }
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private makeFieldRequired(name: string) {
    this.form.get(name).setValidators(Validators.required);
  }
}
