import { createAction, props, union } from '@ngrx/store';

import { DictionariesEnum, EntitiesList, Profile, TotalCounts, XRayLink } from '@app-shared/models';

const CheckProfileExistenceSuccessAction = createAction(
  '[Profiles API] Profile request by keyword resolved successfully',
  props<{ contact: string; profile: Profile }>(),
);
const CheckProfileExistenceLinksSuccessAction = createAction(
  '[Profiles API] Profile request by links resolved successfully',
  props<{ link: string; profile: Profile }>(),
);
const RefreshSidepanelAfterSendingNotificationAction = createAction(
  '[Profiles API] Refresh side panel after sending notification',
  props<{ id: string }>(),
);
const RefreshTagsDictionaryAction = createAction(
  '[Root Module | Root Profiles Effect] Refresh tags dictionary after adding new tag',
  props<{ dictionaries: [DictionariesEnum.tags] }>(),
);
const RequestProfilesSuccessAction = createAction(
  '[Profiles API] Profiles saved to store',
  props<EntitiesList<Profile>>(),
);
const RequestXRaySuccessAction = createAction(
  '[Profiles API] X-ray links saved to store',
  props<{ links: XRayLink[] }>(),
);
const RequestXRayFailedAction = createAction(
  '[Profiles API] Error during requesting X-ray links from the server',
);
const RequestProfilesFailedAction = createAction(
  '[Profiles API] Error during requesting profiles from the server',
);
const RequestProfilesCountsSuccessAction = createAction(
  '[Profiles API] Counts for profiles sources saved to store',
  props<{ counts: TotalCounts }>(),
);
const RequestProfilesCountsFailedAction = createAction(
  '[Profiles API] Error during requesting profiles counts from the server',
);
const RequestProfilesIdsSuccessAction = createAction(
  '[Profiles API] Profiles ids saved to selection store',
  props<{ ids: string[] }>(),
);
const RequestProfilesIdsFailedAction = createAction(
  '[Profiles API] Error during requesting profiles ids',
);
const RequestVacancyProfilesSuccessAction = createAction(
  '[Profiles API] Profiles for active vacancy saved to store',
  props<EntitiesList<Profile>>(),
);
const RequestVacancyProfilesFailedAction = createAction(
  '[Profiles API] Error during requesting profiles for active vacancy from the server',
);

const actions = {
  CheckProfileExistenceSuccessAction,
  CheckProfileExistenceLinksSuccessAction,
  RefreshSidepanelAfterSendingNotificationAction,
  RefreshTagsDictionaryAction,
  RequestProfilesSuccessAction,
  RequestXRaySuccessAction,
  RequestXRayFailedAction,
  RequestProfilesFailedAction,
  RequestProfilesCountsSuccessAction,
  RequestProfilesCountsFailedAction,
  RequestProfilesIdsSuccessAction,
  RequestProfilesIdsFailedAction,
  RequestVacancyProfilesSuccessAction,
  RequestVacancyProfilesFailedAction,
};
const profilesApiActions = union(actions);

export type ProfilesApiActionsType = typeof profilesApiActions;

export const ProfilesApiActions = actions;
