<mat-form-field class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label" floatLabel="always">
  <mat-label>
    {{ controlName }}
    @if (description) {
      <span class="c-custom-field-control__label-icon" [matTooltip]="description">
        <i class="fas fa-info-circle" aria-hidden="true"></i>
      </span>
    }
  </mat-label>
  @if (isReadonly) {
    <input
      matInput
      class="c-tsp-mat-form-field-input"
      readonly
      [formControl]="internalControl"
      [placeholder]="controlName"
    />
  } @else {
    <mat-select
      [placeholder]="controlName"
      [formControl]="internalControl"
      (selectionChange)="selectionChange($event.value)"
    >
      @if (customFieldName === 'departmentId') {
        <mat-select-trigger>
          {{ selectedDepartmentData }}
        </mat-select-trigger>
      }
      @switch (customFieldName) {
        @case ('managers') {
          <app-mat-select-search
            placeholderLabel="profile-short-list.short-list-dialog.manager.search.label"
            [formControl]="searchManagersControl"
            [clearOnFocus]="false"
            [resetOnBlur]="false"
          >
          </app-mat-select-search>
          @for (item of managersByVacancy; track trackById($index, item)) {
            <mat-option
              [value]="item.email"
              [disabled]="!item.email"
              [matTooltip]="
                item.email
                  ? ''
                  : ('profile-short-list.short-list-dialog.manager.no-email' | translate)
              "
              class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
            >
              {{ item.fullName }}
            </mat-option>
          }
        }
        @case ('departmentId') {
          <app-mat-select-search
            class="c-kernel-custom-field-selector-department__search"
            placeholderLabel="vacancy-preview.vacancy-preview-controls.assigned-recruiter.search"
            [formControl]="searchDepartmentsControl"
          >
          </app-mat-select-search>
          @for (item of departments$ | async; track trackById($index, item)) {
            <mat-option
              [value]="item.id"
              class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
            >
              <div class="c-kernel-custom-field-selector-department">
                <span class="c-kernel-custom-field-selector-department__name">
                  {{ item.name }}
                </span>
                <span class="c-kernel-custom-field-selector-department__id"> ({{ item.id }}) </span>
                <span class="c-kernel-custom-field-selector-department__parents">
                  {{ item.parentsChain }}
                </span>
              </div>
            </mat-option>
          }
        }
        @case ('business') {
          @for (item of dictionary; track trackById($index, item)) {
            <mat-option
              [value]="item.id"
              class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
            >
              {{ item.name }}
            </mat-option>
          }
        }
        @case ('vacancyCategory') {
          @for (item of dictionary; track trackById($index, item)) {
            <mat-option
              [value]="item.id"
              class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
            >
              {{ item.name }}
            </mat-option>
          }
        }
        @default {
          @for (item of selectorItems; track trackByItem($index, item)) {
            <mat-option
              [value]="item"
              class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
            >
              {{ item }}
            </mat-option>
          }
        }
      }
    </mat-select>
  }
  <mat-error>
    <span [innerHTML]="'custom-fields.custom-fields-control.field-required' | translate"></span>
  </mat-error>
  @if (customFieldName === 'departmentId') {
    <mat-hint>
      {{ selectedDepartmentParents }}
    </mat-hint>
  }
</mat-form-field>
