import { createAction, props, union } from '@ngrx/store';

const ClearVacanciesStoreAfterEditingAction = createAction(
  '[New Entity Effects] Clear vacancies in My Vacancies store after editing',
);
const RefreshVacanciesAfterEditingAction = createAction(
  '[New Entity Effects] Refresh vacancies in My Vacancies selector after editing',
  props<{ keyword?: string }>(),
);
const SaveSelectedVacancyToRecruiterProfileAction = createAction(
  '[Vacancy Effects] Save selected vacancy to recruiter profile',
  props<{ id: number }>(),
);
const SaveSelectedVacancyToRecruiterProfileSuccessAction = createAction(
  '[Vacancy Effects] Successfully saved selected vacancy to recruiter profile',
);
const UpdateVacancyDataAction = createAction(
  '[Profile Short List Effects] Update current vacancy data',
);

const actions = {
  ClearVacanciesStoreAfterEditingAction,
  RefreshVacanciesAfterEditingAction,
  SaveSelectedVacancyToRecruiterProfileAction,
  SaveSelectedVacancyToRecruiterProfileSuccessAction,
  UpdateVacancyDataAction,
};

const vacancySelectorSystemActions = union(actions);

export type VacancySelectorSystemActionsType = typeof vacancySelectorSystemActions;

export const VacancySelectorSystemActions = actions;
