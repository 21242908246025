/* eslint-disable @typescript-eslint/member-ordering */
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { anyPass, append, isEmpty, isNil, unless } from 'ramda';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthSystemActions } from '@app-core/auth/actions/auth.system.actions';
import { AuthUserActions } from '@app-core/auth/actions/auth.user.actions';
import { SetLocalizationFromUrl } from '@app-core/auth/resolvers/localization/localization-resolver.actions';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { StartDashboardActions } from '@app-core/start-dashboard/actions/start-dashboard.actions';
import { SuccessNotificationAction } from '@app-shared/actions/internal-notification.actions';
import { RouterActions } from '@app-shared/actions/router.actions';
import { AuthForms, SubscriptionPackagesEnum } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCurrentForm,
  getLandingPageLocale,
  getPasswordToken,
  getPricePackage,
  getRefreshToken,
  getRequestedUrl,
  isUseSessionStorage,
} from '@app-shared/reducers/auth/auth.reducer';
import { AuthService } from '@app-shared/services/auth/auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly store$: Store<State>,
    private readonly translateService: TranslateService,
    @Inject(LOCAL_STORAGE) private readonly localStorage: StorageService,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: StorageService,
  ) {}

  public acceptCookies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.AcceptCookiesAction),
      tap(() => this.localStorage.set('acceptCookies', true)),
      map(() => AuthSystemActions.SetCookiesAcceptanceStateAction({ state: true })),
    ),
  );

  public getCookiesAcceptanceState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthSystemActions.GetCookiesAcceptanceStateAction),
      map(() => {
        const state = this.localStorage.get('acceptCookies');
        return AuthSystemActions.SetCookiesAcceptanceStateAction({ state: state || false });
      }),
    ),
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.LogoutAction),
      tap(() => {
        this.sessionStorage.remove('token');
        this.sessionStorage.remove('refresh_token');
        this.localStorage.remove('token');
        this.localStorage.remove('refresh_token');
      }),
      map(() => AuthUserActions.OpenLoginPageAction({})),
    ),
  );
  /*
  public logoutFromSocialServices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserActions.LogoutAction),
        switchMap(() => this.socialAuthService.authState),
        filter(isNotNullOrUndefined),
        tap(() => {
          void this.socialAuthService.signOut();
        }),
      ),
    {
      dispatch: false,
    },
  );
  */

  public proceedAfterSuccessLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthSystemActions.LoginSuccessAction,
        StartDashboardActions.NavigateToExternalPageFromStartAction,
      ),
      withLatestFrom(this.store$),
      map(([{ path }, state]) => ({
        url: path || getRequestedUrl(state),
        locale: getLandingPageLocale(state),
      })),
      map(({ url, locale }) => (anyPass([isNil, isEmpty])(url) ? `${locale}` : url)),
      map((url) => RouterActions.NavigateAfterLoginAction({ path: url })),
    ),
  );

  public refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RootSystemActions.UpdateUserSpaceSuccessAction,
        AuthSystemActions.RefreshTokenAfterLoadAction,
      ),
      withLatestFrom(this.store$),
      map(([{ refreshToken }, state]) => refreshToken ?? getRefreshToken(state)),
      switchMap((token) =>
        this.authService.refreshToken(token).pipe(
          concatMap((tokens) => [
            AuthSystemActions.RefreshTokenAfterChangeSpaceSuccessAction({ tokens }),
            AuthSystemActions.ReloadPageAfterChangeSpaceSuccessAction(),
          ]),
          catchError(() => of(AuthSystemActions.RefreshTokenAfterChangeSpaceFailedAction())),
        ),
      ),
    ),
  );

  public restorePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.TryPasswordRestoreAction),
      withLatestFrom(this.store$),
      map(([{ password, email }, state]) => ({
        form: getCurrentForm(state),
        token: getPasswordToken(state),
        password,
        email,
      })),
      exhaustMap(({ form, token, password, email }) =>
        this.authService.passwordRestore({ token, password }).pipe(
          mergeMap(() => {
            const messageAction = form === AuthForms.INVITE_RECRUITER ? 'create' : 'set-password';
            const actions: Action[] = [
              AuthSystemActions.RequestPasswordRestoreSuccessAction(),
              SuccessNotificationAction({
                message: `shared.effects.auth.${messageAction}`,
              }),
              AuthUserActions.OpenLoginPageAction({ email }),
            ];
            return unless(
              () => isNil(email),
              append(
                AuthSystemActions.TryLoginAfterPasswordRestoreAction({
                  data: { email, password, saveCredentials: true },
                }) as Action,
              ),
            )(actions);
          }),
          catchError(() => of(AuthSystemActions.RequestPasswordRestoreFailedAction())),
        ),
      ),
    ),
  );

  public restoreTokensFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthSystemActions.CheckTokenIntoStorageAction),
      map(() => {
        const token = this.sessionStorage.get('token') || this.localStorage.get('token');
        const refreshToken =
          this.sessionStorage.get('refresh_token') || this.localStorage.get('refresh_token');
        const useSessionStorage = this.sessionStorage.has('token');
        return AuthSystemActions.RestoreTokenFromStorageAction({
          tokens: { token, refresh_token: refreshToken, useSessionStorage },
        });
      }),
    ),
  );
  /*
  public restoreTokensFromStorageWithValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthSystemActions.CheckTokenIntoStorageAction),
      switchMap(() => {
        const token = this.sessionStorage.get('token') || this.localStorage.get('token');
        const refreshToken =
          this.sessionStorage.get('refresh_token') || this.localStorage.get('refresh_token');
        const useSessionStorage = this.sessionStorage.has('token');

        if (!token) {
          return of(
            AuthSystemActions.RestoreTokenFromStorageAction({
              tokens: { token, refresh_token: refreshToken },
            }),
          );
        }

        return this.authService.validateUserTokens({ token, refreshToken }).pipe(
          map(({ state }) => {
            if (state === TokenValidityState.VALID) {
              return AuthSystemActions.RestoreTokenFromStorageAction({
                tokens: { token, refresh_token: refreshToken, useSessionStorage },
              });
            }
            if (state === TokenValidityState.EXPIRED) {
              return AuthSystemActions.RefreshTokenAfterLoadAction({ refreshToken });
            }
            return AuthSystemActions.RestoreTokenFromStorageAction({
              tokens: { token: null, refresh_token: null },
            });
          }),
        );
      }),
    ),
  );
  */

  public setLocalizationFromUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SetLocalizationFromUrl),
        tap(({ locale }) => this.translateService.use(locale || 'uk')),
      ),
    { dispatch: false },
  );

  public signInWithSocialProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.LoginIntoAppWithGoogle),
      withLatestFrom(this.store$),
      map(([{ token }, state]) => ({
        token,
        locale: getLandingPageLocale(state),
      })),
      switchMap(({ token, locale }) =>
        this.authService
          .loginWithSocialToken('google', token, SubscriptionPackagesEnum.team, locale)
          .pipe(
            map((tokens) =>
              AuthSystemActions.LoginSuccessAction({
                tokens: { ...tokens, useSessionStorage: false },
              }),
            ),
            catchError((err: HttpErrorResponse) =>
              of(
                AuthSystemActions.LoginFailedAction({
                  message: (err.error as Record<string, unknown>)?.message as string,
                  status: err.status,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public tryLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.TryLoginAction, AuthSystemActions.TryLoginAfterPasswordRestoreAction),
      switchMap(({ data }) =>
        this.authService.authorizeUser(data).pipe(
          map((response) =>
            AuthSystemActions.LoginSuccessAction({
              tokens: { ...response, useSessionStorage: !data.saveCredentials },
            }),
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              AuthSystemActions.LoginFailedAction({
                message: (err.error as Record<string, unknown>).message as string,
                status: err.status,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public tryRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.TryRegistrationAction),
      withLatestFrom(this.store$),
      map(([{ data }, state]) => ({
        subscriptionPlan: data.subscriptionPlan || getPricePackage(state),
        ...data,
      })),
      switchMap((data) =>
        this.authService.registrationUser(data).pipe(
          mergeMap((response) => [
            AuthSystemActions.RegistrationSuccessAction(),
            AuthSystemActions.LoginSuccessAction({
              tokens: response,
              path: 'lobby/post-login-questions',
            }),
          ]),
          catchError((err: HttpErrorResponse) =>
            of(
              AuthSystemActions.RegistrationFailedAction({
                message: (err.error as Record<string, unknown>).message as string,
                status: err.status,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public tryResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthUserActions.TryResetPasswordAction),
      switchMap(({ email }) =>
        this.authService.resetPassword({ email }).pipe(
          map(() => AuthSystemActions.ResetPasswordSuccessAction()),
          catchError(() => of(AuthSystemActions.ResetPasswordFailedAction())),
        ),
      ),
    ),
  );

  public updateTokensInStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthSystemActions.UpdateTokensAction,
          AuthSystemActions.RefreshTokenAfterChangeSpaceSuccessAction,
          AuthSystemActions.LoginSuccessAction,
        ),
        withLatestFrom(this.store$),
        map(([{ tokens }, state]) => ({
          ...tokens,
          useSessionStorage: isUseSessionStorage(state),
        })),
        tap(({ token, refresh_token, useSessionStorage }) => {
          const storage = useSessionStorage ? this.sessionStorage : this.localStorage;
          storage.set('token', token);
          storage.set('refresh_token', refresh_token);
        }),
      ),
    { dispatch: false },
  );
}
