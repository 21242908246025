<form class="c-tsp-mat-form" [formGroup]="form" novalidate>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate>profile-short-list.short-list-dialog.subdivision.label</mat-label>
      <mat-select
        formControlName="department"
        [placeholder]="'profile-short-list.short-list-dialog.subdivision.placeholder' | translate"
      >
        @for (department of departments$ | async; track trackById($index, department)) {
          <mat-option [value]="department.id" [title]="department.name">
            {{ department.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <label
    class="c-tsp-popup-multiple-select__label c-short-list-dialog-managerial__manager-label"
    translate
    >profile-short-list.short-list-dialog.manager.label</label
  >
  <div class="c-short-list-dialog-managerial__manager">
    @for (manager of selectedManagers; track trackById($index, manager)) {
      <div class="c-short-list-dialog-managerial__manager-tiles">
        <div class="c-short-list-dialog-managerial__manager-tiles-item">
          <div class="c-short-list-dialog-managerial__manager-image">
            <span class="c-short-list-dialog-managerial__manager-initials">
              {{ manager | getInitials }}
            </span>
          </div>
          <div class="c-short-list-dialog-managerial__manager-info">
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.fullName
            }}</span>
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.subdivision.name
            }}</span>
            <span class="c-short-list-dialog-managerial__manager-info--icon">
              <i class="fas fa-arrow-right" aria-hidden="true"></i>
            </span>
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.organization
            }}</span>
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.telephone
            }}</span>
          </div>
        </div>
        <button
          class="c-short-list-dialog-managerial__manager-tiles-remove"
          mat-icon-button
          (click)="removeManager(manager.email)"
        >
          <i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </div>
    }
  </div>
  <div class="c-tsp-mat-form-field__row c-short-list-dialog-managerial__manager-add">
    <mat-select
      [placeholder]="'profile-short-list.short-list-dialog.manager.placeholder' | translate"
      formControlName="emails"
      [multiple]="!data.onlyOneManager"
    >
      <app-mat-select-search
        placeholderLabel="profile-short-list.short-list-dialog.manager.search.label"
        [clearOnFocus]="false"
        [formControl]="searchValueControl"
        [isNotFullListLoaded]="true"
        [previousSelectedValues]="form.get('emails').value"
        (changeEvent)="onSearch($event)"
      >
      </app-mat-select-search>
      <mat-select-trigger class="c-short-list-dialog-managerial__manager-add-text" translate>
        profile-short-list.short-list-dialog.manager.placeholder
      </mat-select-trigger>
      @for (manager of managers; track trackById($index, manager)) {
        <mat-option
          [value]="manager.email"
          [disabled]="!manager.email"
          [matTooltip]="
            manager.email
              ? ''
              : ('profile-short-list.short-list-dialog.manager.no-email' | translate)
          "
          class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
        >
          <div class="c-short-list-dialog-managerial__manager-image">
            <span class="c-short-list-dialog-managerial__manager-initials">
              {{ manager | getInitials }}
            </span>
          </div>
          <div class="c-short-list-dialog-managerial__manager-info">
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.fullName
            }}</span>
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.subdivision.name
            }}</span>
            <span class="c-short-list-dialog-managerial__manager-info--icon">
              <i class="fas fa-arrow-right" aria-hidden="true"></i>
            </span>
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.organization
            }}</span>
            <span class="c-short-list-dialog-managerial__manager-info-item">{{
              manager.telephone
            }}</span>
          </div>
        </mat-option>
      }
    </mat-select>
  </div>
</form>
