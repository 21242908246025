import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getUserLocalization, getUserName } from '@app-core/root/reducer/root.reducer';
import { ExtendedWindow, Locales } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { WindowRefService } from '@app-shared/services/window-ref/window-ref.service';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, shareReplay, takeUntil } from 'rxjs';

export enum TypeQuestionEnum {
  multiselect = 'multiselect',
  select = 'select',
  text = 'text',
}

interface QuestionStep {
  question: string;
  options: string[];
  type: TypeQuestionEnum;
}

@Component({
  selector: 'app-post-login-questions',
  templateUrl: './post-login-questions.component.html',
  styleUrls: ['./post-login-questions.component.less'],
})
export class PostLoginQuestionsComponent implements OnInit {
  public form = this.formBuilder.group({
    how_find_us: [null, Validators.required],
    type_activity: [null, Validators.required],
    recruiters_at_company: [null, Validators.required],
    used_before: [null, [Validators.required, Validators.minLength(3)]],
  });
  public steps: QuestionStep[] = [
    {
      question: 'how_find_us',
      options: ['google', 'friends', 'edu-materials', 'socials', 'other'],
      type: TypeQuestionEnum.select,
    },
    {
      question: 'type_activity',
      options: ['talent-acquisition', 'recruiting', 'other', 'headhunting', 'teamleading'],
      type: TypeQuestionEnum.multiselect,
    },
    {
      question: 'recruiters_at_company',
      options: ['1', '2-5', '5-10', 'more-10'],
      type: TypeQuestionEnum.select,
    },
    {
      question: 'used_before',
      options: [],
      type: TypeQuestionEnum.text,
    },
  ];
  public readonly prefixTranslateKey = 'lobby.post-login.';
  public userLocale: Locales;
  public isLastStep = false;
  public userName$: Observable<string>;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private readonly window: ExtendedWindow & typeof globalThis;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly store$: Store<State>,
    windowRef: WindowRefService,
  ) {
    this.window = windowRef.nativeWindow as ExtendedWindow & typeof globalThis;
  }

  public ngOnInit() {
    this.store$
      .pipe(select(getUserLocalization), takeUntil(this.ngUnsubscribe))
      .subscribe((locale: Locales) => (this.userLocale = locale));
    this.userName$ = this.store$.pipe(select(getUserName), shareReplay());
  }

  public checkIsLastPage(event: StepperSelectionEvent) {
    this.isLastStep = event.selectedIndex === this.steps.length;
  }

  public getTranslateKey(
    step: QuestionStep,
    propName: 'question' | 'options',
    indexOption?: number,
  ): string {
    if (propName === 'question') {
      return this.prefixTranslateKey + step.question;
    } else {
      return `${this.prefixTranslateKey}${step.question}.${step.options[indexOption]}`;
    }
  }

  public isControlValid(controlName: string): boolean {
    return this.form.get(controlName).valid;
  }

  public submit(): void {
    void this.router.navigate(['/', this.userLocale, 'start']);
  }
}
