import {
  CalendarPayload,
  CalendarTarget,
  DateRange,
  GroupNotificationEntry,
  HistoryEvent,
  MailTemplateTypes,
  NotificationChannel,
  PaginationParams,
  SearchParams,
  ShortList,
  ShortListOperation,
  ShortListPayload,
  SortParams,
  VacancyEditPayload,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddCommentAction = createAction(
  '[Vacancy Profiles] Add comment to vacancy',
  props<{ comment: string; id: number }>(),
);
const AddToShortListAction = createAction(
  '[Vacancy Profiles] Add profile to short list',
  props<{ shortList: Partial<ShortListPayload> }>(),
);
const ChangeMultipleShortListStatusAction = createAction(
  '[Vacancy Profiles] Change short list status of multiple candidates',
  props<{ data: Partial<ShortListPayload>; ids: string[]; projectId: number }>(),
);
const ChangeSimpleCardViewStateAction = createAction(
  '[Vacancy Profiles] Change simple card view state',
  props<{ status: boolean }>(),
);
const ChangeVacancyStatusAction = createAction(
  '[Vacancy Profiles] Change vacancy status from side info',
  props<{ data: { id: number; status: number } }>(),
);
const CleanNotifiedProfilesAction = createAction(
  '[Vacancy Profiles] Clean notified profiles dialog data after closing',
);
const CleanSearchParamsAction = createAction('[Vacancy Profiles] Clean form and search params');
const DeleteCommentAction = createAction(
  '[Vacancy Profiles] Delete comment from vacancy',
  props<{ id: number }>(),
);
const DeleteHistoryEventAction = createAction(
  '[Vacancy Profiles] Delete history record from vacancy',
  props<{ event: HistoryEvent }>(),
);
const DeleteVacancyAction = createAction(
  '[Vacancy Profiles] Delete vacancy',
  props<{ id: number }>(),
);
const DownloadProfilesAction = createAction(
  '[Vacancy Profiles] Download candidates into Excel file',
);
const EditActiveVacancyAction = createAction(
  '[Vacancy Profiles] Open popup for editing current vacancy',
);
const GetProfilesForNotifiedDialogAction = createAction(
  '[Vacancy Profiles | Notified Dialog] Request profiles for notified profiles dialog',
  props<{ notification: GroupNotificationEntry }>(),
);
const GetNotificationRecipientsAction = createAction(
  '[Vacancy Profiles] Get profiles for notification recipients dialog',
  props<{ id: number }>(),
);
const GetVacancyHistoryAction = createAction(
  '[Vacancy Profiles History Tab] Request vacancy history events',
  props<{ params: DateRange }>(),
);
const GetVacancyHistoryOnInitAction = createAction(
  '[Vacancy Profiles History Tab] Initial request for vacancy history events on tab open',
);
const ManageProfilesToAnotherVacancyAction = createAction(
  '[Vacancy Profiles] Manage profiles to another vacancy',
  props<{
    profileIds: string[];
    action: ShortListOperation;
    sourceVacancyId: number;
    targetVacancyId: number;
  }>(),
);
const MergeMultipleProfilesAction = createAction(
  '[Vacancy Profiles] Merge profile duplicates from the card',
  props<{ primary: string; duplicates: string[] }>(),
);
const MergeTwoProfilesAction = createAction('[Vacancy Profiles] Merge selected profiles');

const NavigateToProfilesFromVacancyAction = createAction(
  '[Vacancy Profiles] Navigate to profiles search from vacancy profiles',
  props<{ id?: number; searchParams: SearchParams }>(),
);
const OpenShortListCalendarAction = createAction(
  '[Vacancy Profiles] Open calendar page for setting interview event from vacancy page',
  props<{ target: CalendarTarget; data: CalendarPayload; event?: string }>(),
);
const RemoveFromShortListAction = createAction(
  '[Vacancy Profiles Page] Remove profile from short list on ATS page',
  props<{ id: number; devId: string }>(),
);
const ResendShortlistForSecurityCheckAction = createAction(
  '[Vacancy Profiles Page] Resend short list for security check',
  props<{ id: number }>(),
);
const SelectAllProfilesAction = createAction('[Vacancy Profiles Page] Select all profiles');
const SendNotificationAction = createAction(
  '[Vacancy Profiles Page] Send notification',
  props<{
    id?: string;
    notificationType: NotificationChannel;
    templateType: MailTemplateTypes;
    shortList?: ShortList;
  }>(),
);
const ToggleInfoPanelAction = createAction(
  '[Vacancy Profiles Page] Toggle left info panel',
  props<{ isOpen: boolean }>(),
);
const UpdatePaginationParamsAction = createAction(
  '[Vacancy Profiles] Update pagination parameters',
  props<{ params: PaginationParams }>(),
);
const UpdateSearchParamsAction = createAction(
  '[Vacancy Profiles] Update search parameters',
  props<{ params: SearchParams }>(),
);
const UpdateShortListAction = createAction(
  '[Vacancy Profiles Page] Update existed short list from ATS page',
  props<{ previousShortList: Partial<ShortListPayload>; shortList: Partial<ShortListPayload> }>(),
);
const UpdateSortingParamsAction = createAction(
  '[Vacancy Profiles] Update sorting parameters',
  props<{ params: SortParams }>(),
);
const UpdateStatisticSearchParamsAction = createAction(
  '[Vacancy Profiles] Update search parameters in statistic tab',
  props<{ params: SearchParams }>(),
);
const UpdateVacancyWithDataAction = createAction(
  '[Vacancy Profiles Page] Update vacancy with partial data',
  props<{ data: VacancyEditPayload }>(),
);

const actions = {
  AddCommentAction,
  AddToShortListAction,
  ChangeMultipleShortListStatusAction,
  ChangeSimpleCardViewStateAction,
  ChangeVacancyStatusAction,
  CleanNotifiedProfilesAction,
  CleanSearchParamsAction,
  DeleteCommentAction,
  DeleteHistoryEventAction,
  DeleteVacancyAction,
  DownloadProfilesAction,
  EditActiveVacancyAction,
  GetNotificationRecipientsAction,
  GetProfilesForNotifiedDialogAction,
  GetVacancyHistoryAction,
  GetVacancyHistoryOnInitAction,
  ManageProfilesToAnotherVacancyAction,
  MergeMultipleProfilesAction,
  MergeTwoProfilesAction,
  NavigateToProfilesFromVacancyAction,
  OpenShortListCalendarAction,
  RemoveFromShortListAction,
  ResendShortlistForSecurityCheckAction,
  SelectAllProfilesAction,
  SendNotificationAction,
  ToggleInfoPanelAction,
  UpdatePaginationParamsAction,
  UpdateSearchParamsAction,
  UpdateShortListAction,
  UpdateSortingParamsAction,
  UpdateStatisticSearchParamsAction,
  UpdateVacancyWithDataAction,
};

const vacancyProfilesUserActions = union(actions);

export type VacancyProfilesUserActionsType = typeof vacancyProfilesUserActions;

export const VacancyProfilesUserActions = actions;
