import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { anyPass, complement, includes, is, isEmpty, isNil, none, pluck } from 'ramda';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  imports: [MatInput, TranslateModule],
  selector: 'app-mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSearchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class MultipleSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  public clearOnFocus = true;
  @Input()
  public isNotFullListLoaded = false;
  @Input()
  public resetOnBlur = true;
  @Input()
  public placeholderLabel: string;
  @Input()
  public noEntriesFoundLabel = 'shared.multiple-select-search.mat-search-select.not-found';
  @Input()
  public previousSelectedValues?: (string | number)[];
  @Output()
  public changeEvent = new EventEmitter<string>();
  @ViewChild('searchSelectInput', { read: ElementRef, static: true })
  public searchSelectInput: ElementRef;
  public options?: QueryList<MatOption>;
  private value?: string;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MatSelect) public readonly matSelect: MatSelect,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.matSelect.openedChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((opened) => {
      if (opened) {
        this.focus();
      } else if (this.resetOnBlur) {
        this.reset();
      }
      this.options = this.matSelect.options;
      this.options.changes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        // eslint-disable-next-line no-underscore-dangle
        const keyManager = this.matSelect._keyManager;
        if (keyManager && this.matSelect.panelOpen) {
          setTimeout(() => {
            keyManager.setFirstItemActive();
          });
        }
      });
    });

    this.changeEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
    this.initMultipleHandling();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: (val: unknown) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: () => void = () => {};

  public handleKeydown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  public writeValue(value: string) {
    const valueChanged = value !== this.value;
    if (valueChanged) {
      this.value = value;
      this.changeEvent.emit(value);
      (this.searchSelectInput.nativeElement as HTMLInputElement).value = value;
    }
  }

  public onInputChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.processNewValue(value);
  }

  public onBlur(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.writeValue(value);
    this.onTouched();
  }

  public registerOnChange(fn: () => unknown) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => unknown) {
    this.onTouched = fn;
  }

  public focus() {
    if (!this.searchSelectInput?.nativeElement) {
      return;
    }
    const panel: HTMLElement = this.matSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    (this.searchSelectInput.nativeElement as HTMLInputElement).focus();

    panel.scrollTop = scrollTop;
    if (this.clearOnFocus) {
      this.processNewValue('');
    }
  }

  public reset() {
    if (!this.searchSelectInput) {
      return;
    }
    (this.searchSelectInput.nativeElement as HTMLInputElement).value = '';
    this.processNewValue('');
  }

  private initMultipleHandling() {
    this.matSelect.valueChange
      .pipe(
        filter(() => this.matSelect.multiple),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((values: (string | number)[]) => {
        let restoreSelectedValues = false;
        if (
          (none(anyPass([isNil, isEmpty]))([this.value, this.previousSelectedValues]) &&
            is(Array, this.previousSelectedValues)) ||
          this.isNotFullListLoaded
        ) {
          if (anyPass([isNil, complement(is(Array))])(values)) {
            values = [];
          }

          const optionValues: (string | number)[] = pluck(
            'value',
            this.matSelect.options as unknown as MatOption[],
          );
          this.previousSelectedValues.forEach((previousValue: string | number) => {
            if (!includes(previousValue, [...values, ...optionValues])) {
              values.push(previousValue);
              restoreSelectedValues = true;
            }
          });
        }

        if (restoreSelectedValues) {
          // eslint-disable-next-line no-underscore-dangle
          this.matSelect._onChange(values);
        }
        this.previousSelectedValues = values;
      });
  }

  private processNewValue(value: string): void {
    const valueChanged = value !== this.value;
    if (valueChanged) {
      this.value = value;
      this.onChange(value);
      this.changeEvent.emit(value);
    }
  }
}
