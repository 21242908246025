import { FormControl } from '@angular/forms';
import { isEmpty, isNil } from 'ramda';

export function latinSymbolsValidator(control: FormControl<string>) {
  const value: string = control.value;
  const latinSymbolExp = new RegExp(/[^a-zA-Z\s-]+/g);

  return isNil(value) || isEmpty(value) || isEmpty(value.trim()) || !latinSymbolExp.test(value)
    ? null
    : { latinSymbolsOnly: { value } };
}
