<h2 mat-dialog-title class="c-tsp-popup__title c-new-directory-dialog__title">
  {{ popupTitle$ | async | translate }}
</h2>
<mat-dialog-content class="c-tsp-mat-dialog-content c-new-directory-dialog__content">
  @if (isLoading$ | async) {
    <div class="c-tsp-popup__loader">
      <app-spinner class="c-tsp-popup__spinner"> </app-spinner>
    </div>
  }
  @switch (directoryName$ | async) {
    @case ('bid-statuses') {
      <directories-bid-status-form
        [directoryItem]="directoryItem$ | async | castToBidStatus"
        [statusesCustomFields]="statusesCustomFields$ | async"
        (formChange)="storeFormData($event)"
        (showWarningMessage)="showWarningMessage($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-bid-status-form>
    }
    @case ('cities') {
      <directories-city-form
        [directoryItem]="directoryItem$ | async | castToCity"
        [countries]="countries$ | async"
        [regions]="regions$ | async"
        [locale]="userLocale$ | async"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      ></directories-city-form>
    }
    @case ('mail-attachments') {
      <directories-mail-attachment-form
        [directoryItem]="directoryItem$ | async | castToNotificationTemplate"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-mail-attachment-form>
    }
    @case ('mail-templates') {
      <directories-mail-template-form
        [directoryItem]="directoryItem$ | async | castToNotificationTemplate"
        [attachments]="mailAttachments$ | async"
        [locale]="userLocale$ | async"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-mail-template-form>
    }
    @case ('notification-templates') {
      <directories-mail-template-form
        [directoryItem]="directoryItem$ | async | castToNotificationTemplate"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-mail-template-form>
    }
    @case ('rejectReasons') {
      <directories-reject-reason-form
        [directoryItem]="directoryItem$ | async | castToRejectReason"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-reject-reason-form>
    }
    @case ('skills') {
      <directories-skill-form
        [directoryItem]="directoryItem$ | async | castToSkill"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-skill-form>
    }
    @case ('specialities') {
      <directories-speciality-form
        [directoryItem]="directoryItem$ | async | castToSpeciality"
        [userLocale]="userLocale$ | async"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-speciality-form>
    }
    @case ('tags') {
      <directories-tag-form
        [directoryItem]="directoryItem$ | async | castToTag"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-tag-form>
    }
    @default {
      <directories-custom-field-form
        [directoryItem]="directoryItem$ | async | castToCustomField"
        (formChange)="storeFormData($event)"
        (formPristineState)="changeFormPristineState($event)"
      >
      </directories-custom-field-form>
    }
  }
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form c-new-directory-dialog__actions">
  @if (data.mode === 'EDIT' && !hideDeleteButton) {
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close c-new-directory-dialog__button-delete"
      (click)="openDeletePopup()"
    >
      <span translate>add-new-entity.new-directory.delete.button</span>
    </button>
  }
  <div>
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
      (click)="openClosePopup()"
    >
      <span translate>add-new-entity.new-directory.close.button</span>
    </button>
    <button
      mat-button
      class="c-tsp-mat-popup-form__button"
      [ngClass]="
        childFormHasError
          ? 'c-tsp-mat-popup-form__button--disabled'
          : 'c-tsp-mat-popup-form__button--create'
      "
      (click)="submit()"
    >
      <span translate>add-new-entity.new-directory.save.button</span>
    </button>
  </div>
</mat-dialog-actions>
