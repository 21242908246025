import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFileName',
  standalone: true,
})
export class GetFileNamePipe implements PipeTransform {
  public transform(filePath: string): string {
    const file = filePath.substring(filePath.lastIndexOf('/') + 1);
    const extension = file.substring(file.lastIndexOf('.'));
    let name = file.substring(0, file.lastIndexOf('.'));

    if (name.length > 20) {
      const chars = name.split('');
      chars.splice(3, file.length - 10, '...');
      name = chars.join('');
    }

    return name + extension;
  }
}
