import { convertSymfonyBidToShortList } from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import {
  FullProfile,
  MatchProfileResponse,
  Profile,
  PublicSourceData,
  ShortList,
  ShortListCreateResponse,
  ShortListUpdateResponse,
  Skill,
  UnaryOperator,
} from '@app-shared/models';
import {
  adjust,
  equals,
  find,
  findIndex,
  isNil,
  lensProp,
  map,
  mergeRight,
  not,
  objOf,
  pathEq,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  reject,
  set,
  update,
  when,
} from 'ramda';

export function adjustSkillWeightInProfile(
  skill: Skill,
  profile: Profile,
  skillType: 'skills' | 'softSkills',
): Profile {
  const modifiedSkillIndex = findIndex(propEq(skill.id, 'id'), profile[skillType]);
  return pipe(
    prop(skillType),
    adjust(modifiedSkillIndex, set(lensProp('weight'), skill.weight)) as UnaryOperator<
      Skill[],
      Skill[]
    >,
    objOf(skillType),
    mergeRight(profile),
  )(profile) as Profile;
}
export function removeProfileFromListById(id: string, profiles: Profile[]): Profile[] {
  return reject(propEq(id, 'id') as UnaryOperator<Profile, boolean>, profiles);
}
export function removeProfileFromListByLongListId(id: number, profiles: Profile[]): Profile[] {
  return reject(pathEq(id, ['applies', 'id']) as UnaryOperator<Profile, boolean>, profiles);
}
export function removeShortListFromProfile(id: string, profiles: Profile[]): Profile[] {
  const profile: Profile = find(propEq(id, 'id') as UnaryOperator<Profile, boolean>, profiles);
  const profileIndex = findIndex(propEq(id, 'id'), profiles);
  const newProfile = set(lensProp('applies'), null, profile);
  return update(profileIndex, newProfile, profiles);
}
export function removeShortListFromPublicProfileCandidates(
  bidId: number,
  profiles: Profile[],
): Profile[] {
  const profile: Profile = find(
    propSatisfies(pipe(find(pathEq(bidId, ['applies', 'id'])), isNil, not), 'candidatesData'),
    profiles,
  );
  const candidates: Profile[] = propOr([], 'candidatesData', profile);
  const candidate = find(pathEq(bidId, ['applies', 'id']), candidates) as Profile;
  const candidateIndex = findIndex(pathEq(bidId, ['applies', 'id']), candidates);
  const newCandidate = set(lensProp('applies'), null, candidate);
  const newCandidates = update(candidateIndex, newCandidate, candidates);

  const profileIndex = findIndex(equals(profile), profiles);
  const newProfile = set(lensProp('candidatesData'), newCandidates, profile);
  return update(profileIndex, newProfile, profiles);
}
export function setCreatedShortListToProfile(
  id: string,
  bidData: Partial<ShortListCreateResponse>,
  profiles: Profile[],
) {
  const profile = find(propEq(id, 'id'), profiles) as Profile;
  const profileIndex = findIndex(propEq(id, 'id'), profiles);

  const applies = convertSymfonyBidToShortList(bidData, true);

  const newProfile = mergeRight(profile, { applies, isPrivate: true });
  return update(profileIndex, newProfile, profiles);
}
export function updateApplyInProfilePreview(
  bidData: Partial<ShortListUpdateResponse> | Partial<ShortList>,
  profiles: Profile[],
  convert = false,
) {
  const profile: Profile = find(
    pathEq(bidData.id, ['applies', 'id']) as UnaryOperator<Profile, boolean>,
    profiles,
  );

  if (!profile) {
    return profiles;
  }

  const applies = convert
    ? convertSymfonyBidToShortList(
        bidData as Partial<ShortListUpdateResponse>,
        false,
        profile.applies,
      )
    : bidData;

  const profileIndex = findIndex(pathEq(bidData.id, ['applies', 'id']), profiles);
  const modifiedDeveloper = mergeRight(profile, { applies }) as Profile;
  return update(profileIndex, modifiedDeveloper, profiles);
}
export function matchPublicProfileInList(
  data: MatchProfileResponse,
  profiles: Profile[],
  id: string,
): Profile[] {
  const profile = find(propEq(id, 'id'), profiles) as Profile;
  const profileIndex = findIndex(propEq(id, 'id'), profiles);

  const modifiedProfile = matchPublicProfile(data, profile);

  return update(profileIndex, modifiedProfile, profiles);
}
export function matchPublicProfile(
  data: MatchProfileResponse,
  profile: Profile | FullProfile,
): Profile | FullProfile {
  return pipe(
    propOr([], 'externalData'),
    map(
      when(
        propEq(data.publicProfileId, 'id'),
        set(lensProp('matched'), data.isMatch) as UnaryOperator<PublicSourceData, PublicSourceData>,
      ),
    ) as UnaryOperator<PublicSourceData[], PublicSourceData[]>,
    (modifiedData) => set(lensProp('externalData'), modifiedData, profile),
  )(profile);
}
