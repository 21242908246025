import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import {
  getIntegrationCustomFieldId,
  getIntegrationCustomFieldName,
} from '@app-integration/kernel/helpers';
import { OWL_DATE_TIME_FORMATS } from '@app-shared/helpers';
import {
  DictionaryItem,
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  ShortListDialogForm,
  UnaryOperator,
} from '@app-shared/models';
import { VacancyService } from '@app-shared/services/vacancy/vacancy.service';
import {
  DateTimeAdapter,
  NativeDateTimeAdapter,
  OWL_DATE_TIME_FORMATS as OWL_DATE_TIME_FORMATS_PROVIDER,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { ShortListDialogStore } from '@tsp-components/profile-short-list';
import { RichEditorComponent } from '@tsp-components/rich-editor';
import { DateTime } from 'luxon';
import { find, isNil, map, mergeAll, pathEq, pipe, propOr, reject, replace } from 'ramda';
import { Subject, combineLatest, startWith, takeUntil } from 'rxjs';

export const kernelOfferApproveControls = [
  'availableBudget',
  'budgetExcess',
  'candidateOfferPosition',
  'candidateOfferSalary',
  'formingOffer',
  'isFixedRate',
  'motivationSystem',
  'offerRecruiterComment',
  'plannedPosition',
  'positionChanged',
  'workStartingAt',
];
@Component({
  imports: [
    CdkTextareaAutosize,
    MatCheckbox,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    RichEditorComponent,

    TranslatePipe,
    TranslateDirective,
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS_PROVIDER, useValue: OWL_DATE_TIME_FORMATS },
  ],
  selector: 'short-list-dialog-approve',
  templateUrl: './short-list-dialog-approve.component.html',
  styleUrls: ['./short-list-dialog-approve.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogApproveComponent implements OnInit, OnDestroy {
  @Input()
  public currencies: DictionaryItem[];
  @Input()
  public customFields: DirectoryFieldType[];
  @Input()
  public form: FormGroup<Partial<ShortListDialogForm>>;
  @Input()
  public fieldValues: DirectoryCustomFieldResponse[];
  @Input()
  public shortListProjectId: number;
  public offerApprovalReasonSelected = false;
  public offerApprovalRequired = false;
  public vacancyFieldValues: DirectoryCustomFieldResponse[];
  public vacancyLoading = false;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly vacancyService: VacancyService,
    private readonly shortListDialogStore: ShortListDialogStore,
  ) {}

  public ngOnInit(): void {
    this.handleVacancyData();
    this.watchFormingOfferReasons();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get allowedSalary(): number | null {
    if (!this.form.get('budgetExcess').value) {
      const value = this.getVacancyFieldValue('availableBudget');
      return value ? pipe(replace(',', '.'), parseFloat)(value) : null;
    }
    return null;
  }
  public get currentLocalDate(): Date {
    return DateTime.local().startOf('hour').toJSDate();
  }

  public formingOfferClick(event: MouseEvent) {
    if (this.offerApprovalRequired || this.offerApprovalReasonSelected) {
      event.preventDefault();
    }
  }

  private handleVacancyData() {
    this.vacancyLoading = true;
    this.vacancyService
      .getVacancy(this.shortListProjectId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (vacancy) => {
          this.vacancyFieldValues = propOr([], 'fieldValues', vacancy);
          this.shortListDialogStore.setVacancyFieldValues(this.vacancyFieldValues);
          const fieldId = getIntegrationCustomFieldId('offerApprovalRequired', 'vacancy');
          this.offerApprovalRequired = this.getVacancyFieldValueById(fieldId)?.bool as boolean;
          if (this.offerApprovalRequired) {
            this.form.get('formingOffer').setValue(true);
          }
          this.setApproveOfferValues(this.fieldValues, 'shortList');
          this.setApproveOfferValues(this.vacancyFieldValues, 'vacancy');
        },
        error: (err) => console.warn('Unable to load vacancy due to', err),
        complete: () => (this.vacancyLoading = false),
      });
  }
  private watchFormingOfferReasons() {
    combineLatest([
      this.form.get('budgetExcess').valueChanges.pipe(startWith(false)),
      this.form.get('positionChanged').valueChanges.pipe(startWith(false)),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([budgetExcess, positionChanged]) => {
        if (budgetExcess || positionChanged) {
          this.offerApprovalReasonSelected = true;
          this.form.get('formingOffer').setValue(true);
        } else {
          this.offerApprovalReasonSelected = false;
        }
        if (!positionChanged) {
          const value = this.getVacancyFieldValue('plannedPosition');
          this.form.patchValue({ candidateOfferPosition: value });
        }
      });
  }
  private getVacancyFieldValue(name: string): string {
    const id = getIntegrationCustomFieldId(name, 'vacancy');

    if (!this.vacancyFieldValues?.length) {
      return null;
    }

    return this.vacancyFieldValues.find((fieldValue) => fieldValue.type.id === id)?.value as string;
  }
  private setApproveOfferValues(
    data: DirectoryCustomFieldResponse[],
    idSource: 'vacancy' | 'shortList',
  ) {
    const values = pipe(
      map((fieldValue: DirectoryCustomFieldResponse): Record<string, number | string> => {
        const id = fieldValue?.type?.id;
        const name = getIntegrationCustomFieldName(id, idSource);
        const value = fieldValue.value as string;

        switch (name) {
          case 'availableBudget': {
            const currency = replace(/[\s\d.,]/g, '', value);
            const salary = pipe(replace(',', '.'), parseFloat)(value);
            return { budgetCurrency: currency, candidateOfferSalary: salary };
          }
          case 'plannedPosition':
            return { candidateOfferPosition: value };
          case 'motivationSystem':
            return { motivationSystem: value };
          default:
            return { [name]: name ? value : null };
        }
      }),
      mergeAll as UnaryOperator<Record<string, number | string>[], Record<string, number | string>>,
      reject(isNil) as UnaryOperator<
        Record<string, number | string>,
        Record<string, number | string>
      >,
    )(data || []);

    this.form.patchValue(values);
  }
  private getVacancyFieldValueById(id: number): DirectoryCustomFieldResponse {
    return find(pathEq(id, ['type', 'id']))(
      this.vacancyFieldValues || [],
    ) as DirectoryCustomFieldResponse;
  }
}
