import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFormControl',
  standalone: true,
})
export class GetFormControlPipe implements PipeTransform {
  public transform(form: UntypedFormGroup, name: string): UntypedFormControl | null {
    if (!form) {
      return null;
    }

    const control = form.get(name);

    if (!control) {
      return null;
    }

    return control as UntypedFormControl;
  }
}
