import { Pipe, PipeTransform } from '@angular/core';
import { unless, filter, propSatisfies, isEmpty, isNil, anyPass, test } from 'ramda';
import { AllowedOptionType, UnaryOperator } from '@app-shared/models';

@Pipe({
  name: 'filterOptionsByKeyword',
  standalone: true,
})
export class FilterOptionsByKeywordPipe implements PipeTransform {
  public transform(
    value: AllowedOptionType[],
    keyword: string,
    searchFromBeginning = true,
  ): AllowedOptionType[] {
    if (!value) {
      return null;
    }

    const regexpPrefix = searchFromBeginning ? '^' : '';
    const regexp = new RegExp(`${regexpPrefix}${keyword}`, 'ig');

    return unless(
      () => anyPass([isNil, isEmpty])(keyword),
      filter(propSatisfies(test(regexp), 'name') as UnaryOperator<AllowedOptionType, boolean>),
    )(value);
  }
}
