import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { State } from '@app-shared/reducers';
import { Store, Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

export function genericSimpleResolver(action: Action<string>): ResolveFn<boolean> {
  return (): Observable<boolean> => {
    const store$: Store<State> = inject(Store<State>);

    store$.dispatch(action);

    return of(true);
  };
}
