/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { RecruiterService } from '@app-shared/services/recruiter/recruiter.service';

import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import { SuccessNotificationAction } from '@app-shared/actions/internal-notification.actions';

import { TeammatesSystemActions } from '@app-core/teammates/actions/teammates.system.actions';

@Injectable()
export class NewEntityRecruiterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly recruiterService: RecruiterService,
  ) {}

  public getRecruiterDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.RequestRecruiterDetailsOnInitAction),
      exhaustMap(({ id }) =>
        this.recruiterService.getRecruiterById(id).pipe(
          map((recruiter) =>
            AddNewEntitySystemActions.RequestRecruiterDetailsOnInitSuccessAction({ recruiter }),
          ),
          catchError(() =>
            of(AddNewEntitySystemActions.RequestRecruiterDetailsOnInitFailedAction()),
          ),
        ),
      ),
    ),
  );

  public saveNewRecruiter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CreateNewRecruiterAction),
      exhaustMap(({ recruiter }) =>
        this.recruiterService.createRecruiter(recruiter).pipe(
          mergeMap(() => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.saved.recruiter',
            }),
            AddNewEntitySystemActions.SaveNewRecruiterSuccessAction(),
          ]),
          catchError(() => of(AddNewEntitySystemActions.SaveNewRecruiterFailedAction())),
        ),
      ),
    ),
  );

  public updateRecruiter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.UpdateRecruiterAction),
      exhaustMap(({ recruiter }) =>
        this.recruiterService.updateRecruiter(recruiter).pipe(
          mergeMap((recruiterInfo) => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.updated.recruiter',
            }),
            TeammatesSystemActions.UpdateRecruiterSuccessAction({ recruiter: recruiterInfo }),
          ]),
          catchError(() => of(TeammatesSystemActions.UpdateRecruiterFailedAction())),
        ),
      ),
    ),
  );
}
