/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-undef */
import { Injectable } from '@angular/core';
import { environment } from '@app-environment/environment';
import { CurrentUser, ExtendedWindow } from '@app-shared/models';
import { datadogRum } from '@datadog/browser-rum';
import { path } from 'ramda';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class ScriptsInjectionService {
  private readonly window: ExtendedWindow & typeof globalThis;

  constructor(windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow as ExtendedWindow & typeof globalThis;
  }

  public initDatadogMonitor(appVersion: string) {
    datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: 'datadoghq.eu',
      service: 'search-console',
      env: environment.production ? 'prod' : 'stage',
      version: appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: environment.datadog.privacyLevel,
      allowedTracingUrls: [
        /https:\/\/.*\.talentscan\.com/,
        /https:\/\/.*\.talentsearch\.com/,
        /https:\/\/.*\.kernel\.com/,
        /https:\/\/.*\.aog-experts\.com/,
      ],
    });

    datadogRum.startSessionReplayRecording();
  }
  public setDatadogUserSession(data: CurrentUser) {
    if (!this.window) {
      return;
    }
    datadogRum.setUser({
      id: data.id.toString(),
      name: data.name,
      email: data.email,
      roles: data.roles,
      spaceId: data.portalId,
      spaceName: data.portalName,
      subscription: path(['subscription', 'name'], data),
      subscriptionStatus: path(['subscription', 'active'], data) ? 'active' : 'inactive',
      locale: data.locale,
    });
  }

  public injectGoogleMaps() {
    if ((this.window.google as Record<string, unknown>)?.maps) {
      return;
    }

    void this.insertScriptIntoHtml(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsId}&libraries=places&language=en`,
    );
  }

  private insertScriptIntoHtml(src: string, defer = true): Promise<Event> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = defer;
    script.src = src;
    const firstExistedScript = document.getElementsByTagName('script')[0];
    firstExistedScript.parentNode.insertBefore(script, firstExistedScript);
    return new Promise((resolve) => (script.onload = resolve));
  }
}
