import { Pipe, PipeTransform } from '@angular/core';
import { Tag, Skill } from '@app-shared/models';

@Pipe({
  name: 'castTagType',
  standalone: true,
})
export class CastTagTypePipe implements PipeTransform {
  public transform(value: Skill[] | Tag[]): Tag[] {
    return value as Tag[];
  }
}
