/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { InternalNotificationsService } from '@app-shared/services/internal-notifications/internal-notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class InternalNotificationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: InternalNotificationsService,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  public errorNotificationMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternalNotificationActions.ErrorNotificationAction),
        tap((error) => this.notificationService.showNotification('error', error)),
      ),
    { dispatch: false },
  );

  public infoNotificationMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternalNotificationActions.InfoNotificationAction),
        tap((message) => this.notificationService.showNotification('info', message)),
      ),
    { dispatch: false },
  );

  public infoNotificationMessageWithAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternalNotificationActions.InfoNotificationAndActivityAction),
        tap(({ message, action }) =>
          this.notificationService.showNotification('infoWithAction', { message }, action),
        ),
      ),
    { dispatch: false },
  );

  public warningNotificationMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternalNotificationActions.WarningNotificationAction),
        tap((warning) => this.notificationService.showNotification('warning', warning)),
      ),
    { dispatch: false },
  );

  public successNotificationMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternalNotificationActions.SuccessNotificationAction),
        tap((success) => this.notificationService.showNotification('success', success)),
      ),
    { dispatch: false },
  );

  public successNotificationMessageWithAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternalNotificationActions.SuccessNotificationAndActivityAction),
        tap(({ message, action }) =>
          this.notificationService.showNotification('successWithAction', { message }, action),
        ),
      ),
    { dispatch: false },
  );
}
