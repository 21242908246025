import { intersection, isEmpty, pipe, not } from 'ramda';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkRoles',
  standalone: true,
})
export class CheckRolesPipe implements PipeTransform {
  public transform(userRoles: string[] = [], possibleRoles: string[] = []): boolean {
    return pipe(intersection(possibleRoles), isEmpty, not)(userRoles);
  }
}
