import { Profile, ProfileSuggestion } from '@app-shared/models';

import profilesList from './profiles/profiles-list.json';
import profileSuggestions from './profiles/profile-suggestions.json';

export const mockedXRayLinks = [
  {
    source: 'github',
    type: 'GitHub',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:github.com "overview" (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2) -intitle:"at master" -inurl:"tab" -inurl:"jobs." -inurl:"articles"',
    withSynonyms: true,
    range: '2-months',
  },
  {
    source: 'github',
    type: 'GitHub',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:github.com "overview" (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2) -intitle:"at master" -inurl:"tab" -inurl:"jobs." -inurl:"articles"',
    withSynonyms: true,
    range: '12-months',
  },
  {
    source: 'github',
    type: 'GitHub',
    url: 'http://www.google.com/search?q=site:github.com "overview" (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2) -intitle:"at master" -inurl:"tab" -inurl:"jobs." -inurl:"articles"',
    withSynonyms: true,
    range: 'all-time',
  },
  {
    source: 'github',
    type: 'GitHub',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:github.com "overview" (%22Angular%22) AND (Kyiv) -intitle:"at master" -inurl:"tab" -inurl:"jobs." -inurl:"articles"',
    withSynonyms: false,
    range: '2-months',
  },
  {
    source: 'github',
    type: 'GitHub',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:github.com "overview" (%22Angular%22) AND (Kyiv) -intitle:"at master" -inurl:"tab" -inurl:"jobs." -inurl:"articles"',
    withSynonyms: false,
    range: '12-months',
  },
  {
    source: 'github',
    type: 'GitHub',
    url: 'http://www.google.com/search?q=site:github.com "overview" (%22Angular%22) AND (Kyiv) -intitle:"at master" -inurl:"tab" -inurl:"jobs." -inurl:"articles"',
    withSynonyms: false,
    range: 'all-time',
  },
  {
    source: 'linkedin',
    type: 'LinkedIn',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:*.linkedin.com/in/* (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2) -intitle:"profiles" -inurl:"dir/"',
    withSynonyms: true,
    range: '2-months',
  },
  {
    source: 'linkedin',
    type: 'LinkedIn',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:*.linkedin.com/in/* (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2) -intitle:"profiles" -inurl:"dir/"',
    withSynonyms: true,
    range: '12-months',
  },
  {
    source: 'linkedin',
    type: 'LinkedIn',
    url: 'http://www.google.com/search?q=site:*.linkedin.com/in/* (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2) -intitle:"profiles" -inurl:"dir/"',
    withSynonyms: true,
    range: 'all-time',
  },
  {
    source: 'linkedin',
    type: 'LinkedIn',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:*.linkedin.com/in/* (%22Angular%22) AND (Kyiv) -intitle:"profiles" -inurl:"dir/"',
    withSynonyms: false,
    range: '2-months',
  },
  {
    source: 'linkedin',
    type: 'LinkedIn',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:*.linkedin.com/in/* (%22Angular%22) AND (Kyiv) -intitle:"profiles" -inurl:"dir/"',
    withSynonyms: false,
    range: '12-months',
  },
  {
    source: 'linkedin',
    type: 'LinkedIn',
    url: 'http://www.google.com/search?q=site:*.linkedin.com/in/* (%22Angular%22) AND (Kyiv) -intitle:"profiles" -inurl:"dir/"',
    withSynonyms: false,
    range: 'all-time',
  },
  {
    source: 'rabotaua',
    type: 'Rabota.ua',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:rabota.ua/cv/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '2-months',
  },
  {
    source: 'rabotaua',
    type: 'Rabota.ua',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:rabota.ua/cv/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '12-months',
  },
  {
    source: 'rabotaua',
    type: 'Rabota.ua',
    url: 'http://www.google.com/search?q=site:rabota.ua/cv/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: 'all-time',
  },
  {
    source: 'rabotaua',
    type: 'Rabota.ua',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:rabota.ua/cv/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '2-months',
  },
  {
    source: 'rabotaua',
    type: 'Rabota.ua',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:rabota.ua/cv/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '12-months',
  },
  {
    source: 'rabotaua',
    type: 'Rabota.ua',
    url: 'http://www.google.com/search?q=site:rabota.ua/cv/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: 'all-time',
  },
  {
    source: 'stackoverflow',
    type: 'Stack Overflow',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:stackoverflow.com/users (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '2-months',
  },
  {
    source: 'stackoverflow',
    type: 'Stack Overflow',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:stackoverflow.com/users (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '12-months',
  },
  {
    source: 'stackoverflow',
    type: 'Stack Overflow',
    url: 'http://www.google.com/search?q=site:stackoverflow.com/users (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: 'all-time',
  },
  {
    source: 'stackoverflow',
    type: 'Stack Overflow',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:stackoverflow.com/users (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '2-months',
  },
  {
    source: 'stackoverflow',
    type: 'Stack Overflow',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:stackoverflow.com/users (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '12-months',
  },
  {
    source: 'stackoverflow',
    type: 'Stack Overflow',
    url: 'http://www.google.com/search?q=site:stackoverflow.com/users (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: 'all-time',
  },
  {
    source: 'workua',
    type: 'Work.ua',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:.work.ua/resumes/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '2-months',
  },
  {
    source: 'workua',
    type: 'Work.ua',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:.work.ua/resumes/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '12-months',
  },
  {
    source: 'workua',
    type: 'Work.ua',
    url: 'http://www.google.com/search?q=site:.work.ua/resumes/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: 'all-time',
  },
  {
    source: 'workua',
    type: 'Work.ua',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:.work.ua/resumes/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '2-months',
  },
  {
    source: 'workua',
    type: 'Work.ua',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:.work.ua/resumes/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '12-months',
  },
  {
    source: 'workua',
    type: 'Work.ua',
    url: 'http://www.google.com/search?q=site:.work.ua/resumes/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: 'all-time',
  },
  {
    source: 'xing',
    type: 'Xing',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:xing.com/profile/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '2-months',
  },
  {
    source: 'xing',
    type: 'Xing',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:xing.com/profile/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: '12-months',
  },
  {
    source: 'xing',
    type: 'Xing',
    url: 'http://www.google.com/search?q=site:xing.com/profile/ (%22Angular%22) AND (Kyiv%20OR%20Kiev%20OR%20Kiiv%20OR%20%D0%9A%D0%B8%D0%B5%D0%B2%20OR%20%D0%9A%D0%B8%D1%97%D0%B2)',
    withSynonyms: true,
    range: 'all-time',
  },
  {
    source: 'xing',
    type: 'Xing',
    url: 'http://www.google.com/search?tbs=qdr:m2&q=site:xing.com/profile/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '2-months',
  },
  {
    source: 'xing',
    type: 'Xing',
    url: 'http://www.google.com/search?tbs=qdr:m12&q=site:xing.com/profile/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: '12-months',
  },
  {
    source: 'xing',
    type: 'Xing',
    url: 'http://www.google.com/search?q=site:xing.com/profile/ (%22Angular%22) AND (Kyiv)',
    withSynonyms: false,
    range: 'all-time',
  },
];

export const mockedProfilesList: Profile[] = profilesList as Profile[];
export const mockedProfileSuggestions: ProfileSuggestion[] = profileSuggestions;
