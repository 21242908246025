/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { formFileData } from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import { AnalyzedAiCvData } from '@app-shared/models';
import { NewEntityService } from '@app-shared/services/new-entity/new-entity.service';

import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
@Injectable()
export class NewEntityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly newEntityService: NewEntityService,
  ) {}

  public uploadCvFromPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.UploadCvFromPopupAction),
      map(({ file }) => formFileData(file, '1')),
      mergeMap((formData) =>
        this.newEntityService.uploadFile<AnalyzedAiCvData>(formData).pipe(
          map((fileInfo) => AddNewEntitySystemActions.UploadCvSuccessAction({ file: fileInfo })),
          catchError(() => of(AddNewEntitySystemActions.UploadCvFailedAction())),
        ),
      ),
    ),
  );
}
