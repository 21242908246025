/* eslint-disable @typescript-eslint/no-explicit-any */
import { MatDialogConfig } from '@angular/material/dialog';
import { Params } from '@angular/router';
import {
  AutocompleteItem,
  ClientFull,
  DictionaryCountry,
  DictionaryDepartment,
  DictionaryEditableItem,
  DictionaryItem,
  ProfilesIncluded,
  SimpleRecruiter,
  SpaceEntity,
  Vacancy,
} from '.';

export type AllowedOptionType =
  | DictionaryDepartment
  | DictionaryItem
  | DictionaryEditableItem
  | SpaceEntity
  | DictionaryCountry
  | AutocompleteItem
  | (ClientFull & { name: string })
  | SimpleRecruiter
  | Vacancy;

export type UserClick = 'single' | 'double';

export interface CalendarPayload {
  date: {
    from: string;
    to: string;
  };
  email?: string;
  organizerEmail?: string;
  names?: string;
  devId: number | string;
  profileName: string;
  vacancyId: number;
  vacancyName?: string;
}
export type CalendarTarget = 'google' | 'office365' | 'outlook';

// eslint-disable-next-line no-shadow
export enum ComplicatedRequestTarget {
  CONTACTS = 'CONTACTS',
  COMPANIES = 'COMPANIES',
  CV = 'CV',
  EXPERIENCE = 'EXPERIENCE',
  EVERYWHERE = 'EVERYWHERE',
  LOCATION = 'LOCATION',
  MAIN_SKILL = 'MAIN_SKILL',
  NAME = 'NAME',
  PROJECTS = 'PROJECTS',
  RELATED_SKILLS = 'RELATED_SKILLS',
  TITLE = 'TITLE',
}

export type NewEntityPopupMode = 'CLONE' | 'CREATE' | 'EDIT';

export interface ComplicatedRequestObject {
  skills?: number[];
  skillsExceptions?: number[];
  tags?: number[];
  tagsExceptions?: number[];
  keywords?: string[];
  keywordsExceptions?: string[];
  target: ComplicatedRequestTarget;
}
export interface DateRange {
  dateFrom: string | null;
  dateTo: string | null;
}
export interface DialogPayload {
  componentName: string;
  dialogConfig: MatDialogConfig;
}

export interface EntitiesList<T> {
  count?: number;
  items: T[];
  included?: ProfilesIncluded;
  totalCount?: number;
}
export interface FilterCheckbox {
  controlName: string;
  className: string;
  title: string;
}

export type Modules = 'profile' | 'profiles' | 'vacancy' | 'vacancies';

export type NotificationType =
  | 'error'
  | 'info'
  | 'infoWithAction'
  | 'success'
  | 'successWithAction'
  | 'warning';
export interface PaginationParams {
  pageNumber: number;
  pageSize: number;
}
export interface ProfileDetailsTab {
  label: string;
  path: string;
  disabled?: boolean;
  showAlways?: boolean;
}
export interface ProfileDetailsDataTab {
  label: string;
  pathToCount: string[];
  value: string;
}

export type SearchParamValue = string | string[] | number | number[] | boolean;

export interface ProfilesSearchParams {
  addedBy?: number[];
  candidateStage?: number[];
  confirmed?: boolean;
  cities?: number[];
  cityTarget?: ComplicatedRequestTarget;
  complicatedRequest?: ComplicatedRequestObject[];
  countries?: number[];
  employments?: number[];
  englishLevel?: number;
  experience?: number[];
  allowSearchInForbiddenCountries?: boolean;
  excludeProcessedCandidates?: boolean;
  excludeWatchedCandidates?: boolean;
  hadContract?: boolean;
  hasCV?: boolean;
  hasEmail?: boolean;
  hasPhone?: boolean;
  hasSkype?: boolean;
  lastPositionExperience?: number[];
  lookingJob?: boolean;
  notRecruiter?: boolean;
  pageNumber?: number;
  pageSize?: number;
  rate?: string[];
  rateCard?: string[];
  rejectReason?: number;
  remoteWork?: boolean;
  showToClient?: boolean;
  specialities?: number[];
  specialitiesExceptions?: number[];
  specialityTarget?: ComplicatedRequestTarget;
  sortDirection?: 'asc' | 'desc';
  sortField?: 'createdAt' | 'updatedAt' | 'city' | 'country' | 'skill' | 'lastName' | 'relevancy';
  sources?: number[];
  sourcesExceptions?: number[];
  vacancy?: number;
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}
export interface SearchParams {
  [key: string]: SearchParamValue;
}

export interface SimpleItem {
  id: number | string;
  name: string;
}

export enum ProfilesSources {
  EXTERNAL = 'external-profiles',
  PROFILES = 'profiles',
  CANDIDATES = 'candidates',
  XRAY = 'x-ray-links',
}

export interface SortOptions {
  name: string;
  value: SortParams;
}
export interface SortParams {
  sortField?: string;
  sortDirection?: string;
}
export interface Tab {
  defaultSorting?: string;
  path: ProfilesSources;
  label: string;
}

export interface DashboardTab {
  path: string;
  label: string;
}

export interface TrackerEvent {
  category?: string;
  action?: string;
  value?: number;
  label?: string;
  name?: string;
}

export interface ExtendedWindow extends Window {
  ga: any;
  google: any;
  dataLayer: any[];
}
