<h2 mat-dialog-title
    translate>
  shared.confirmation-dialog.actions.title
</h2>
<mat-dialog-content>
  {{ data.text | translate }}
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form__button-wrapper c-tsp-mat-popup-form__button-wrapper-bid-close">
  <button class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
      mat-button
      [mat-dialog-close]="false">
    <span>
      {{ 'shared.confirmation-dialog.actions.no' | translate }}
    </span>
  </button>
  <button class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
      mat-button
      [mat-dialog-close]="true">
    <span>
      {{ 'shared.confirmation-dialog.actions.yes' | translate }}
    </span>
  </button>
</mat-dialog-actions>
