import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ElasticPublicEducation, SimpleProject } from '@app-shared/models';
import { TranslateDirective } from '@ngx-translate/core';
import { PluralizeTextPipe } from '@tsp-pipes';
import { DateTime } from 'luxon';

@Component({
  imports: [TranslateDirective, PluralizeTextPipe],
  selector: 'profile-extended-professional-info',
  templateUrl: './profile-extended-professional-info.component.html',
  styleUrls: ['./profile-extended-professional-info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileExtendedProfessionalInfoComponent {
  @Input()
  public allProfileProjects?: SimpleProject[];
  @Input()
  public allProfileEducation?: ElasticPublicEducation[];
  @Input()
  public profileSummary?: string;

  public get hasWorkHistory(): boolean {
    return this.allProfileProjects?.length > 0;
  }
  public get hasEducation(): boolean {
    return this.allProfileEducation?.length > 0;
  }

  public calcDifference(
    dateFrom: string | Date,
    dateTo: string | Date,
  ): { count: number; unit: 'year' | 'month' } {
    const startDate = DateTime.fromISO(dateFrom.toString());
    const endDate = DateTime.fromISO(dateTo.toString());
    const years = endDate.diff(startDate, 'years');
    const months = endDate.diff(startDate, 'months');

    if (years.years >= 1) {
      return { count: Math.round(years.years), unit: 'year' };
    } else {
      return { count: Math.round(months.months), unit: 'month' };
    }
  }
}
