export const interceptorRouteRegexps = [
  {
    name: 'dictionaries',
    regexp: /(^\/dictionaries|^\/v2\/dictionaries|^\/candidate-statuses|^\/reasons-reject)/,
  },
  {
    name: 'metadata',
    regexp: /(^\/metadata|^\/currentUser)/,
  },
  {
    name: 'profiles',
    regexp: /^\/profiles/,
  },
  {
    name: 'recruiters',
    regexp: /^\/recruiters/,
  },
  {
    name: 'vacancies',
    regexp: /^\/my-vacancies/,
  },
  {
    name: 'vacanciesList',
    regexp: /^\/vacancies/,
  },
  {
    name: 'turbohiring',
    regexp: /^\/?id/,
  },
];

export const interceptorInternalHeaders = [
  'force-refresh',
  'file-uploading',
  'authRequest',
  'anonymousRequest',
];
