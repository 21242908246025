@if (entityForm) {
  <form class="c-tsp-mat-form" [formGroup]="entityForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.name.label</mat-label>
        <input
          matInput
          formControlName="name"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.name.placeholder' | translate"
        />
        <mat-error>
          <span [innerHTML]="'add-new-entity.new-directory.form.name.required' | translate"></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.description.label</mat-label>
        <input
          matInput
          formControlName="description"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.description.placeholder' | translate"
        />
        <mat-error>
          <span
            [innerHTML]="'add-new-entity.new-directory.form.description.required' | translate"
          ></span>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
}
