import { Pipe, PipeTransform } from '@angular/core';
import { FullProfile, Profile } from '@app-shared/models';

@Pipe({
  name: 'castToProfile',
  standalone: true,
})
export class CastToProfilePipe implements PipeTransform {
  transform(value: FullProfile | Profile): Profile {
    return value as Profile;
  }
}
