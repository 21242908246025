/* eslint-disable no-shadow */
import { DateRange, DirectoryCustomFieldResponse, Entity } from '.';
import { DictionaryItem } from './dictionary.model';
import { SimpleUser } from './profile.model';

export interface HistoryEventPayload {
  new_data?: Partial<HistoryEventPayload>;
  old_data?: Partial<HistoryEventPayload>;
  addedBy?: Entity;
  assigned_id?: SimpleUser;
  author?: SimpleUser & {
    first_name?: string;
    last_name?: string;
    fullName?: string;
  };
  comment?: string | { comment: string };
  contacts?: string;
  created_at?: string;
  deadlineDate?: string;
  fileType?: string;
  file?: {
    type: string;
  };

  id?: number;
  interview_at?: string;
  profile?: Entity & Record<string, unknown>;
  profileName?: string;
  project?: { title: string } & Entity;
  project_id?: number;
  project_name?: string;
  recruiter?: SimpleUser & { fullName?: string };
  fieldValues?: DirectoryCustomFieldResponse[];
  source?: DictionaryItem;
  status?: DictionaryItem;
  salary?: number;
  startDate?: string;
  updated_by?: SimpleUser & {
    first_name?: string;
    last_name?: string;
  };
  user_id?: SimpleUser;
}

export interface HistoryEvent {
  createdAt: string;
  entityId: number | string;
  id: string;
  payload: HistoryEventPayload;
  portalId: number;
  scope: TimelineScope;
  relatedEntities: Array<{ entityId: number | string; scope: HistoryScope }>;
  type: TimelineEventType;
}

export enum HistoryEventSource {
  LINKEDIN = 'linkedin',
  GITHUB = 'github',
  STACKOVERFLOW = 'stackoverflow',
  WORK_UA = 'work_ua',
  RABOTA_UA = 'rabota_ua',
  XING = 'xing',
  DRIBBLE = 'dribble',
  MANUALLY = 'manually',
  MY_TALENTS = 'my_talents',
  AURA = 'ai',
}
export type TimelineScope = 'profile' | 'recruiter' | 'vacancy';

export interface HistoryList {
  count: number;
  items: HistoryEvent[];
}

export interface HistoryParams extends DateRange {
  scope: HistoryScope;
  scopeEntityId: number | string;
}

export enum HistoryScope {
  PROFILE = 'profile',
  RECRUITER = 'recruiter',
  VACANCY = 'vacancy',
}

export interface HistoryState {
  isLoading: boolean;
  items: HistoryEvent[];
  total: number;
  query: DateRange;
}

export type TimelineEventType =
  | 'add_candidate_to_vacancy'
  | 'add_comment_to_profile'
  | 'add_comment_to_shortlist'
  | 'add_comment_to_vacancy'
  | 'book_profile'
  | 'candidate_apply_on_vacancy'
  | 'candidate_hired'
  | 'candidate_rejected'
  | 'candidate_status_update'
  | 'change_recruitment_team'
  | 'change_vacancy_assignment'
  | 'change_vacancy_status'
  | 'change_vacancy_data'
  | 'change_vacancy_field_values'
  | 'create_profile'
  | 'create_profile_from_external'
  | 'create_vacancy'
  | 'delete_comment'
  | 'delete_profile_file'
  | 'remove_shortlist'
  | 'open_contacts_profile_page'
  | 'open_contacts_sidepanel'
  | 'open_contacts_candidates_list'
  | 'open_contacts_search_console'
  | 'open_contacts_plugin'
  | 'open_contacts'
  | 'open_profile_in_sidepanel'
  | 'get_profile_details_by_id'
  | 'edit_profile'
  | 'get_profile_details_from_plugin'
  | 'get_public_profile_by_id'
  | 'get_candidates'
  | 'get_profiles'
  | 'get_my_vacancy_by_id'
  | 'public_apply_to_vacancy'
  | 'get_vacancy_details_by_id'
  | 'unbook_profile'
  | 'update_profile'
  | 'upload_file_to_profile'
  | 'send_notifications';
