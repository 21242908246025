import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  getDirectoryDetails,
  isLoading,
} from '@app-core/add-new-entity-popup/reducers/new-entity.reducer';
import { getDirectoryName } from '@app-core/directories/reducers/directories.reducer';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { getUserLocalization } from '@app-core/root/reducer/root.reducer';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import {
  DictionariesEnum,
  DictionaryEditableItem,
  DictionaryItem,
  DictionaryRegion,
  DirectoryFieldType,
  Entity,
  Locales,
  MailAttachment,
  NewEntityPopupMode,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCountries,
  getCustomFields,
  getMailAttachments,
  getRegions,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@tsp-components/confirmation-dialog';
import { SpinnerComponent } from '@tsp-components/spinner';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CastToBidStatusPipe } from '../../pipes/cast-to-bid-status/cast-to-bid-status.pipe';
import { CastToCityPipe } from '../../pipes/cast-to-city/cast-to-city.pipe';
import { CastToCustomFieldPipe } from '../../pipes/cast-to-custom-field/cast-to-custom-field.pipe';
import { CastToNotificationTemplatePipe } from '../../pipes/cast-to-notification-template/cast-to-notification-template.pipe';
import { CastToRejectReasonPipe } from '../../pipes/cast-to-reject-reason/cast-to-reject-reason.pipe';
import { CastToSkillPipe } from '../../pipes/cast-to-skill/cast-to-skill.pipe';
import { CastToSpecialityPipe } from '../../pipes/cast-to-speciality/cast-to-speciality.pipe';
import { CastToTagPipe } from '../../pipes/cast-to-tag/cast-to-tag.pipe';
import { DirectoryBidStatusFormComponent } from '../directory-bid-status-form/directory-bid-status-form.component';
import { DirectoryCityFormComponent } from '../directory-city-form/directory-city-form.component';
import { DirectoryCustomFieldFormComponent } from '../directory-custom-field-form/directory-custom-field-form.component';
import { DirectoryMailAttachmentFormComponent } from '../directory-mail-attachment-form/directory-mail-attachment-form.component';
import { DirectoryMailTemplateFormComponent } from '../directory-mail-template-form/directory-mail-template-form.component';
import { DirectoryRejectReasonFormComponent } from '../directory-reject-reason-form/directory-reject-reason-form.component';
import { DirectorySkillFormComponent } from '../directory-skill-form/directory-skill-form.component';
import { DirectorySpecialityFormComponent } from '../directory-speciality-form/directory-speciality-form.component';
import { DirectoryTagFormComponent } from '../directory-tag-form/directory-tag-form.component';

interface DirectoryPayload {
  name?: string;
  translations?: (DictionaryItem & { locale: Locales })[];
  skill?: string | Entity;
  main_skill?: boolean;
  is_soft?: boolean;
  color?: string;
  attachedFieldTypes?: DirectoryFieldType[];
  description?: string;
  body?: string;
  subject?: string;
  type?: string;
  showInPanel?: boolean;
}

@Component({
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    SpinnerComponent,
    DirectoryBidStatusFormComponent,
    DirectoryCityFormComponent,
    DirectoryMailAttachmentFormComponent,
    DirectoryMailTemplateFormComponent,
    DirectoryRejectReasonFormComponent,
    DirectorySkillFormComponent,
    DirectorySpecialityFormComponent,
    DirectoryTagFormComponent,
    DirectoryCustomFieldFormComponent,
    MatDialogActions,
    MatButton,

    TranslatePipe,
    TranslateDirective,
    NgClass,
    AsyncPipe,
    CastToBidStatusPipe,
    CastToCityPipe,
    CastToCustomFieldPipe,
    CastToNotificationTemplatePipe,
    CastToRejectReasonPipe,
    CastToSkillPipe,
    CastToSpecialityPipe,
    CastToTagPipe,
  ],
  templateUrl: 'new-directory-dialog.component.html',
  styleUrls: ['new-directory-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class NewDirectoryDialogComponent implements OnInit, OnDestroy {
  public countries$?: Observable<DictionaryItem[]>;
  public directoryItem$?: Observable<DictionaryEditableItem>;
  public directoryName$?: Observable<DictionariesEnum>;
  public isLoading$?: Observable<boolean>;
  public popupTitle$?: Observable<string>;
  public mailAttachments$?: Observable<MailAttachment[]>;
  public regions$?: Observable<DictionaryRegion[]>;
  public statusesCustomFields$: Observable<DirectoryFieldType[]>;
  public userLocale$?: Observable<Locales>;

  public childFormHasError = false;
  private childFormIsPristine = true;
  private childFormData?: DirectoryPayload;
  private currentDirectory?: DictionariesEnum;
  private currentDirectoryItemId?: number | string;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      mode: NewEntityPopupMode;
      parentParams?: number;
    },
    private readonly store$: Store<State>,
    private readonly dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.store$.dispatch(AddNewEntitySystemActions.OpenDirectoryDialogOnInitAction());

    this.isLoading$ = this.store$.pipe(select(isLoading));

    this.directoryName$ = this.store$.pipe(
      select(getDirectoryName),
      filter(isNotNullOrUndefined),
      tap((directoryName) => {
        this.currentDirectory = directoryName;
      }),
      shareReplay(),
      takeUntil(this.ngUnsubscribe),
    );
    this.directoryItem$ = combineLatest([this.directoryName$, of(this.data.mode)]).pipe(
      filter(([directoryName, mode]) => directoryName && mode === 'EDIT'),
      tap(([directory]) => {
        this.store$.dispatch(
          AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitAction({
            id: this.data.parentParams,
            directory,
          }),
        );
      }),
      switchMap(() => this.store$.pipe(select(getDirectoryDetails))),
      filter(isNotNullOrUndefined),
      shareReplay(),
      tap((directoryItem) => {
        this.currentDirectoryItemId = directoryItem.id;
      }),
      takeUntil(this.ngUnsubscribe),
    );
    this.statusesCustomFields$ = this.store$.pipe(select(getCustomFields('statusesCustomFields')));
    this.countries$ = this.store$.pipe(select(getCountries));
    this.mailAttachments$ = this.store$.pipe(select(getMailAttachments));
    this.regions$ = this.store$.pipe(select(getRegions));
    this.userLocale$ = this.store$.pipe(
      select(getUserLocalization),
      shareReplay(),
      takeUntil(this.ngUnsubscribe),
    );
    this.popupTitle$ = combineLatest([this.directoryName$, of(this.data.mode)]).pipe(
      map(([directoryName, mode]) =>
        mode === 'EDIT'
          ? `add-new-entity.new-directory.title.update.${directoryName}`
          : `add-new-entity.new-directory.title.create.${directoryName}`,
      ),
      takeUntil(this.ngUnsubscribe),
    );
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get hideDeleteButton(): boolean {
    return [DictionariesEnum['bid-statuses'], DictionariesEnum.rejectReasons].includes(
      this.currentDirectory,
    );
  }
  public changeFormPristineState(isPristine: boolean) {
    this.childFormIsPristine = isPristine;
  }
  public isPristine(): boolean {
    return this.childFormIsPristine;
  }
  public openClosePopup() {
    if (this.isPristine()) {
      this.dialog.closeAll();
    } else {
      this.store$.dispatch(RootSystemActions.OpenConfirmationDialogForClosePopupAction());
    }
  }
  public openDeletePopup() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          text: `directories.confirmation-dialog.delete.${this.currentDirectory}`,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        if (result) {
          this.store$.dispatch(
            AddNewEntityUserActions.DeleteDirectoryItemAction({
              id: this.currentDirectoryItemId,
              name: this.currentDirectory,
            }),
          );
        }
      });
  }
  public showWarningMessage(message: string): void {
    this.store$.dispatch(InternalNotificationActions.WarningNotificationAction({ message }));
  }
  public submit() {
    if (this.childFormHasError) {
      return;
    }

    if (this.data.mode === 'EDIT') {
      const payload = {
        ...this.childFormData,
        id: this.currentDirectoryItemId,
      } as DirectoryFieldType;

      this.store$.dispatch(
        AddNewEntityUserActions.UpdateDirectoryItemAction({
          data: payload,
          name: this.currentDirectory,
        }),
      );
    } else {
      this.store$.dispatch(
        AddNewEntityUserActions.CreateNewDirectoryItemAction({
          data: this.childFormData,
          name: this.currentDirectory,
        }),
      );
    }
  }
  public storeFormData({ data, hasError }: { data: DirectoryPayload; hasError: boolean }) {
    this.childFormData = data;
    this.childFormHasError = hasError;
  }
}
