import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { AllowedOptionType, AutocompleteGroup, UnaryOperator } from '@app-shared/models';
import { DictionaryService } from '@app-shared/services/dictionary/dictionary.service';
import { FilterOptionsByKeywordPipe } from '@tsp-pipes';
import { always, anyPass, has, head, ifElse, is, isEmpty, isNil, path, when } from 'ramda';
import { BehaviorSubject, Observable, Subject, of, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SearchSelectComponent } from '../search-select/search-select.component';

@Component({
  imports: [SearchSelectComponent, ReactiveFormsModule, AsyncPipe],
  providers: [FilterOptionsByKeywordPipe],
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AutocompleteSelectComponent implements OnInit, OnDestroy {
  @Input()
  public control: UntypedFormControl;
  @Input()
  public dictionaryName?: string;
  @Input()
  public set dictionary(dict: AllowedOptionType[]) {
    this.localOptions = dict;
    if (!anyPass([isNil, isEmpty])(dict)) {
      this.searchValue$.next(null);
    }
  }
  @Input()
  public placeholder: string;
  @Input()
  public searchFromBeginning = true;
  @Input()
  public labelType: FloatLabelType;
  @Input()
  public label: string;
  @Input()
  public buttonClass = '';
  @Input()
  public customClass = '';
  @Input()
  public allowClear = true;
  @Input()
  public clearOnFocus = false;
  @Input()
  public withImage = false;
  @Input()
  public showAdditionalInfo: boolean;
  @Input()
  public showId = false;
  @Output()
  public optionSelected = new EventEmitter<number>();

  public debounceTime = 500;
  public options$: Observable<AllowedOptionType[]>;
  public readonly searchValue$: BehaviorSubject<string> = new BehaviorSubject('');
  private localOptions: AllowedOptionType[] = [];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly dictionaryService: DictionaryService,
    private readonly filterLocalDictionary: FilterOptionsByKeywordPipe,
  ) {}

  public ngOnInit(): void {
    this.options$ = this.searchValue$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      map(when(is(Object), () => '')),
      switchMap((keyword: string) =>
        this.dictionaryName
          ? this.dictionaryService
              .getAutocompleteItems(keyword, this.dictionaryName)
              .pipe(
                map(
                  ifElse(
                    anyPass([isNil, isEmpty]),
                    always([]),
                    when(pipe(head, has('groupName')), path([0, 'items'])),
                  ) as UnaryOperator<AutocompleteGroup[], AllowedOptionType[]>,
                ),
              )
          : of(this.localOptions).pipe(
              map((dictionary) =>
                this.filterLocalDictionary.transform(dictionary, keyword, this.searchFromBeginning),
              ),
            ),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSearch(keyword: string) {
    this.searchValue$.next(keyword);
  }
}
