@if (vacancyLoading) {
  <div class="c-tsp-popup__loader">
    <mat-progress-spinner mode="indeterminate" class="c-tsp-popup__spinner"> </mat-progress-spinner>
  </div>
}
<form class="c-tsp-mat-form c-short-list-dialog-approve" [formGroup]="form" novalidate>
  <div class="c-tsp-mat-form-field__row">
    <mat-checkbox
      class="c-tsp-mat-checkbox"
      formControlName="formingOffer"
      [class.mat-checkbox-disabled]="offerApprovalRequired || offerApprovalReasonSelected"
      (click)="formingOfferClick($event)"
    >
      <span translate>profile-short-list.short-list-dialog-approve.forming-offer</span>
    </mat-checkbox>
  </div>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
      [class.mat-form-field-disabled]="!form.get('positionChanged').value"
    >
      <mat-label translate
        >profile-short-list.short-list-dialog-approve.offer-position.label</mat-label
      >
      <input
        matInput
        class="c-tsp-mat-form-field-input"
        formControlName="candidateOfferPosition"
        [readonly]="!form.get('positionChanged').value"
        [placeholder]="
          'profile-short-list.short-list-dialog-approve.offer-position.label' | translate
        "
      />
    </mat-form-field>
    <mat-checkbox class="c-tsp-mat-checkbox" formControlName="positionChanged">
      <span translate>profile-short-list.short-list-dialog-approve.position-changed</span>
    </mat-checkbox>
  </div>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate
        >profile-short-list.short-list-dialog-approve.offer-salary.label</mat-label
      >
      <input
        matInput
        class="c-tsp-mat-form-field-input"
        formControlName="candidateOfferSalary"
        type="number"
        [max]="allowedSalary"
        [min]="0"
        [placeholder]="
          'profile-short-list.short-list-dialog-approve.offer-salary.label' | translate
        "
      />
      @if (form.get('candidateOfferSalary').invalid) {
        <mat-error>
          <span
            [innerHTML]="
              'profile-short-list.short-list-dialog-approve.offer-salary.invalid' | translate
            "
          ></span>
        </mat-error>
      }
    </mat-form-field>
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate>profile-short-list.short-list-dialog-approve.currency.label</mat-label>
      <mat-select
        formControlName="budgetCurrency"
        [placeholder]="'profile-short-list.short-list-dialog-approve.currency.label' | translate"
      >
        @for (currency of currencies; track currency) {
          <mat-option class="c-tsp-mat-form-field-option" [value]="currency.id">
            {{ currency.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-checkbox class="c-tsp-mat-checkbox" formControlName="budgetExcess">
      <span translate>profile-short-list.short-list-dialog-approve.budget-exceed</span>
    </mat-checkbox>
  </div>
  <div class="c-tsp-mat-form-field__row">
    <mat-checkbox class="c-tsp-mat-checkbox" formControlName="isFixedRate">
      <span translate>profile-short-list.short-list-dialog-approve.fixed-rate</span>
    </mat-checkbox>
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate
        >profile-short-list.short-list-dialog-approve.starting-date.label</mat-label
      >
      <input
        class="c-tsp-mat-form-field-input"
        matInput
        formControlName="workStartingAt"
        [owlDateTimeTrigger]="workStartingAt"
        [owlDateTime]="workStartingAt"
        [placeholder]="
          'profile-short-list.short-list-dialog-approve.starting-date.label' | translate
        "
      />
      <owl-date-time [startAt]="currentLocalDate" [firstDayOfWeek]="1" #workStartingAt>
      </owl-date-time>
    </mat-form-field>
  </div>
  <div class="c-tsp-mat-form-field__row">
    <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
      <label
        class="c-tsp-popup-multiple-select__label c-tsp-popup-multiple-select__label--description"
        translate
      >
        profile-short-list.short-list-dialog-approve.motivation.label
      </label>
      <div
        class="c-tsp-mat-form-field__row c-tsp-popup__description c-short-list-dialog-approve__rich-editor"
      >
        <div class="c-short-list-dialog-approve__rich-editor-wrapper">
          <app-rich-editor formControlName="motivationSystem" editorConfiguration="simple">
          </app-rich-editor>
        </div>
      </div>
    </div>
  </div>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate
        >profile-short-list.short-list-dialog-approve.recruiter-comment.label</mat-label
      >
      <textarea
        matInput
        formControlName="offerRecruiterComment"
        [placeholder]="
          'profile-short-list.short-list-dialog-approve.recruiter-comment.label' | translate
        "
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="5"
      >
      </textarea>
    </mat-form-field>
  </div>
</form>
