import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  DirectoryCustomFieldTypes,
  DirectoryFieldType,
  DirectoryFieldTypeForm,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    CdkTextareaAutosize,
    MatCheckbox,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-custom-field-form',
  templateUrl: './directory-custom-field-form.component.html',
  styleUrl: './directory-custom-field-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryCustomFieldFormComponent extends DirectoryEntityFormComponent {
  public currentTypeField = [
    { name: 'text', title: 'add-new-entity.new-directory.field-type.text' },
    { name: 'date', title: 'add-new-entity.new-directory.field-type.date' },
    { name: 'int', title: 'add-new-entity.new-directory.field-type.int' },
    { name: 'bool', title: 'add-new-entity.new-directory.field-type.bool' },
    { name: 'array', title: 'add-new-entity.new-directory.field-type.array' },
    { name: 'longtext', title: 'add-new-entity.new-directory.field-type.longtext' },
  ];
  public entityForm?: FormGroup<DirectoryFieldTypeForm>;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public createForm(): FormGroup<DirectoryFieldTypeForm> {
    return this.formBuilder.group<DirectoryFieldTypeForm>({
      name: new FormControl<string>(null, [Validators.required]),
      description: new FormControl<string | null>(null),
      type: new FormControl<DirectoryCustomFieldTypes>(null, [Validators.required]),
      showInPanel: new FormControl<boolean>(false),
    });
  }
  public get formDataForSymfony(): Omit<DirectoryFieldType, 'id'> {
    const formValue = this.entityForm.value;

    return {
      description: formValue.description,
      name: formValue.name,
      type: formValue.type,
      showInPanel: formValue.showInPanel,
    };
  }
  public updateFormWithData(item: DirectoryFieldType) {
    this.entityForm.setValue({
      description: item.description || null,
      name: item.name,
      type: item.type,
      showInPanel: item.showInPanel,
    });
  }
}
