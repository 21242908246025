import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil, test } from 'ramda';

@Pipe({
  name: 'pluralizeText',
  standalone: true,
})
export class PluralizeTextPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(value: number | string, word: string): string {
    if (!isNil(value)) {
      const convertedValue = test(/\D/g, value as string)
        ? (value as number)
        : parseInt(value as string, 10);
      let amount: string;
      const divisionReminder = +value >= 20 ? convertedValue % 10 : convertedValue;
      switch (true) {
        case divisionReminder === 0:
          amount = 'many';
          break;
        case divisionReminder === 1:
          amount = 'single';
          break;
        case divisionReminder < 5:
          amount = 'few';
          break;
        default:
          amount = 'many';
          break;
      }
      const translatedValue: string = this.translateService.instant(
        `shared.pipes.pluralize.${word}.${amount}`,
      );
      return `${value} ${translatedValue}`;
    }
    return word;
  }
}
