<div class="c-vacancy-selector" [ngClass]="theme">
  <div class="c-vacancy-selector__buttons">
    @if (selectedVacancy) {
      <button
        [matTooltip]="'vacancy-selector.magic-search.tooltip' | translate"
        class="btn c-vacancy-selector__button"
        (click)="searchUsingVacancyParams()"
      >
        <i class="fas fa-magic" aria-hidden="true"></i>
      </button>
    }
    @if (candidatesCount$ | async; as counts) {
      <button
        mat-button
        class="c-vacancy-selector__menu-button"
        [disabled]="counts.total === 0"
        [matTooltip]="'vacancy-selector.label.count.tooltip' | translate"
        [matMenuTriggerFor]="vacancyLinksMenu"
      >
        {{ counts.total }}
      </button>
      <mat-menu #vacancyLinksMenu="matMenu" xPosition="before" yPosition="below">
        @for (link of counts.pairs; track link) {
          <a
            class="c-vacancy-selector__menu-item"
            mat-menu-item
            [routerLink]="getInternalVacancyLink(link.url)"
          >
            <span translate>{{ link.label }}</span>
            &nbsp;
            <span>{{ link.count }}</span>
            @if (link.type === 'hired') {
              <span>
                {{ openedPositions$ | async }}
              </span>
            }
          </a>
        }
      </mat-menu>
    }
  </div>
  <div class="c-vacancy-selector__content">
    <app-autocomplete-select
      [dictionary]="availableVacancies"
      [control]="vacancyControl"
      [searchFromBeginning]="false"
      [allowClear]="false"
      [clearOnFocus]="true"
      class="c-vacancy-selector__select"
      customClass="c-tsp-mat-form-field--with-label"
      [placeholder]="'vacancy-selector.label.select.placeholder' | translate"
      [showAdditionalInfo]="true"
      labelType="always"
    >
      <button
        class="c-vacancy-selector__select-add-new-button"
        mat-button
        (click)="createNewItem()"
      >
        <span translate>vacancy-selector.label.add-new</span>
      </button>
    </app-autocomplete-select>
    @if (selectedVacancy?.clients || selectedVacancy?.createdAt) {
      <div class="c-vacancy-selector__info">
        @if (selectedVacancy?.createdAt) {
          <span class="c-vacancy-selector__info-created">
            {{ selectedVacancy?.createdAt | date: 'd/MM/yyyy' }}
          </span>
        }
        @if (selectedVacancy?.clients.length) {
          @for (client of selectedVacancy.clients; track client) {
            <span class="c-vacancy-selector__info-client">
              {{ client?.firstName }} {{ client?.lastName }}
            </span>
          }
        }
      </div>
    }
  </div>
  @if (selectedVacancy?.showInPublicWebsite) {
    <button
      [matTooltip]="'vacancy-selector.open-public.tooltip' | translate"
      class="btn c-vacancy-selector__button"
      (click)="shareVacancy()"
    >
      <img src="../../../../assets/icon-share-alt-grey.svg" alt="Open vacancy public page" />
    </button>
  }
</div>
