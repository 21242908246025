import { Pipe, PipeTransform } from '@angular/core';
import { BinaryOperator, UnaryOperator } from '@app-shared/models';
import { pipe, toUpper, concat, head, converge, drop } from 'ramda';

@Pipe({
  name: 'capitalizeFirstLetter',
  standalone: true,
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  public transform(word: string): string {
    return converge(concat as BinaryOperator<string, string, string>, [
      pipe(head as UnaryOperator<string, string>, toUpper),
      drop(1) as UnaryOperator<string, string>,
    ])(word);
  }
}
