import { has } from 'ramda';
import {
  NotificationEmailRecipient,
  ProfileNotificationEntry,
  RecipientStatus,
} from '@app-shared/models';

export const detectRecipientStatus = (
  letter: NotificationEmailRecipient | ProfileNotificationEntry['emailData'],
): RecipientStatus => {
  if (has('conversationId', letter)) {
    return RecipientStatus.SENT;
  }

  const emailData = letter;

  switch (true) {
    case emailData.unsubscribed > 0:
      return RecipientStatus.UNSUBSCRIBED;
    case emailData.spamreport > 0:
      return RecipientStatus.SPAM;
    case emailData.bounced > 0:
      return RecipientStatus.BOUNCED;
    case emailData.dropped > 0:
      return RecipientStatus.DROPPED;
    case emailData.deferred > 0:
      return RecipientStatus.DEFERRED;
    case emailData.clicked > 0:
      return RecipientStatus.CLICKED;
    case emailData.opened > 0:
      return RecipientStatus.OPENED;
    case emailData.delivered > 0:
      return RecipientStatus.DELIVERED;
    case emailData.processed > 0:
      return RecipientStatus.PROCESSED;
    default:
      return RecipientStatus.SENT;
  }
};
