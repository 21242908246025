import { NouiFormatter } from 'ng2-nouislider';

export class TimeFormatter implements NouiFormatter {
  public hasLastValue: boolean;

  constructor(hasValue = false) {
    this.hasLastValue = hasValue;
  }

  public to(value: number): string {
    if (this.hasLastValue && value === 11) {
      return '∞';
    }
    return value.toFixed(0);
  }

  public from(value: string): number {
    return Number(value);
  }
}

export const OWL_DATE_TIME_FORMATS = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
