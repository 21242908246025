<h2 mat-dialog-title class="c-tsp-popup__title" translate>
  add-new-entity.new-vacancy.{{ data.mode }}.title
</h2>
<mat-dialog-content>
  @if ((isLoading$ | async) || !formConfigs) {
    <div class="c-tsp-popup__loader">
      <app-spinner class="c-tsp-popup__spinner"> </app-spinner>
    </div>
  }
  <mat-tab-group class="c-new-vacancy-dialog__tab-group">
    @for (template of formConfigs?.tabs; track template; let i = $index) {
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            (click)="changeTemplate(template.index)"
            [matTooltip]="template.name[userLocale$ | async]"
            class="c-new-vacancy-dialog__tab"
          >
            <span class="c-new-vacancy-dialog__tab-title">{{
              template.name[userLocale$ | async]
            }}</span>
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
  <form class="c-tsp-mat-form c-new-vacancy-dialog__form" [formGroup]="newVacancyForm" novalidate>
    @if (getControlPositioning('title'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-vacancy.form.title.label</mat-label>
          <input
            matInput
            formControlName="title"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-vacancy.form.title.placeholder' | translate"
            (blur)="analyzeVacancyParams()"
          />
          <mat-error>
            <span [innerHTML]="'add-new-entity.new-vacancy.form.title.required' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>
    }
    @if (getControlPositioning('shareTo'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <label
          class="c-tsp-popup-multiple-select__label c-new-vacancy-dialog__share-label"
          translate
          >add-new-entity.new-vacancy.form.share-to.label</label
        >
        <div class="c-new-vacancy-dialog__share">
          @for (recruiter of shareToRecruiters; track recruiter) {
            <div class="c-new-vacancy-dialog__share-tiles">
              <div class="c-new-vacancy-dialog__share-tiles-item">
                <div
                  [ngClass]="{
                    'c-new-vacancy-dialog__recruiters-image': recruiter?.image,
                    'c-new-vacancy-dialog__recruiters-image-none': !recruiter?.image,
                  }"
                >
                  @if (recruiter?.image) {
                    <img
                      class="c-new-vacancy-dialog__recruiters-image"
                      appFallbackSrc="./../../../../../assets/default-profile-image.png"
                      [src]="recruiter.image"
                      alt="Recruiter Image"
                    />
                  } @else {
                    <span class="c-new-vacancy-dialog__recruiters-initials">
                      {{ recruiter | getInitials }}
                    </span>
                  }
                </div>
                <span class="c-new-vacancy-dialog__share-name">
                  {{ recruiter?.name }}
                </span>
              </div>
              <button
                class="c-new-vacancy-dialog__share-tiles-remove"
                mat-icon-button
                (click)="removeRecruiterFromSharing(recruiter)"
              >
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
          }
        </div>
        <div class="c-tsp-mat-form-field__row c-new-vacancy-dialog__share-add">
          <mat-select
            [placeholder]="'add-new-entity.new-vacancy.form.share-to.add.placeholder' | translate"
            panelClass="c-tsp-mat-select-panel"
            formControlName="shareTo"
            multiple
            (selectionChange)="sortRecruiters()"
          >
            <mat-select-trigger class="c-new-vacancy-dialog__share-add-text" translate>
              add-new-entity.new-vacancy.form.share-to.add.label
            </mat-select-trigger>
            @for (recruiter of recruiters; track trackById($index, recruiter)) {
              <mat-option
                [value]="recruiter"
                class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
              >
                <div
                  [ngClass]="{
                    'c-new-vacancy-dialog__recruiters-image': recruiter.image,
                    'c-new-vacancy-dialog__recruiters-image-none': !recruiter.image,
                  }"
                >
                  @if (recruiter.image) {
                    <img
                      class="c-new-vacancy-dialog__recruiters-image"
                      appFallbackSrc="./../../../../../assets/default-profile-image.png"
                      [src]="recruiter.image"
                      alt="Recruiter Image"
                    />
                  } @else {
                    <span class="c-new-vacancy-dialog__recruiters-initials">
                      {{ recruiter | getInitials }}
                    </span>
                  }
                </div>
                {{ recruiter.name }}
              </mat-option>
            }
          </mat-select>
        </div>
      </div>
    }
    @if (getControlPositioning('typeVacancy'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div
          class="c-tsp-mat-form-field__row c-tsp-mat-form-field__row-center c-new-vacancy-dialog__checkbox-row"
        >
          @for (control of vacancyTypeFormControls; track control; let i = $index) {
            <div formArrayName="typeVacancy">
              <mat-checkbox
                class="c-tsp-mat-checkbox c-new-vacancy-dialog__checkbox"
                [formControlName]="i"
              >
                <span translate>{{
                  dictionaries.vacancyType[i].name | translateDictionaryName: 'vacancyType' | async
                }}</span>
              </mat-checkbox>
            </div>
          }
        </div>
        @if (isSuggestedParams('type')) {
          <ats-suggested-params
            class="c-new-vacancy-dialog__suggestion c-new-vacancy-dialog__suggestion--row"
            [dictionary]="dictionaries?.vacancyType"
            [parameter]="vacancySuggestedParams.type"
            (removeSuggestion)="removeSuggestion('type')"
            (selectSuggestedItem)="selectSuggestion($event, 'typeVacancy', 'type')"
          >
          </ats-suggested-params>
        }
      </div>
    }
    @if (getControlPositioning('speciality'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
          <div class="c-new-vacancy-dialog__column">
            <a
              class="c-new-vacancy-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-specialities.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add-specialities.button' | translate }}
            </a>
            <app-autocomplete-select
              [control]="newVacancyForm | getFormControl: 'speciality'"
              class="c-new-vacancy-dialog__row--item"
              dictionaryName="specialities"
              customClass="c-tsp-mat-form-field-autocomplete c-tsp-mat-form-field--with-label c-tsp-mat-form-field"
              label="add-new-entity.new-vacancy.form.speciality.label"
              [placeholder]="'add-new-entity.new-vacancy.form.speciality.placeholder' | translate"
              labelType="always"
            >
            </app-autocomplete-select>
          </div>
          @if (isSuggestedParams('speciality')) {
            <ats-suggested-params
              class="c-new-vacancy-dialog__suggestion"
              [dictionary]="dictionaries?.specialities"
              [parameter]="vacancySuggestedParams.speciality"
              [multipleSuggestions]="true"
              (removeSuggestion)="removeSuggestion('speciality')"
              (selectSuggestedItem)="selectSuggestion($event, 'speciality', 'speciality')"
            >
            </ats-suggested-params>
          }
        </div>
      </div>
    }
    @if (getControlPositioning('city'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-popup-multiple-select c-new-vacancy-dialog__row--item"
        >
          <label class="c-tsp-popup-multiple-select__label" translate
            >add-new-entity.new-vacancy.form.location.label</label
          >
          <app-google-autocomplete
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-vacancy.form.location.placeholder' | translate"
            [cities]="dictionaries?.cities"
            [countries]="dictionaries?.countries"
            [selectedCity]="selectedCity"
            (onSelect)="updateCity($event)"
          >
          </app-google-autocomplete>
          @if (isSuggestedParams('city')) {
            <ats-suggested-params
              class="c-new-vacancy-dialog__suggestion"
              [dictionary]="dictionaries?.cities"
              [parameter]="vacancySuggestedParams.city"
              [multipleSuggestions]="true"
              (removeSuggestion)="removeSuggestion('city')"
              (selectSuggestedItem)="selectSuggestion($event, 'city', 'city')"
            >
            </ats-suggested-params>
          }
          @if (vacancyRegion) {
            <mat-hint class="c-tsp-mat-form-field-input__hint" align="start"
              >{{ vacancyRegion | convertIdToName: dictionaries.regions }}
            </mat-hint>
          }
        </div>
      </div>
    }
    @if (getControlPositioning('candidatesQuantity'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-new-vacancy-dialog__form-quantity"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-vacancy.form.candidates-quantity.label</mat-label>
          <input
            matInput
            appLimitInput
            formControlName="candidatesQuantity"
            type="number"
            class="c-tsp-mat-form-field-input"
            [regExp]="limitRegExp"
            [placeholder]="
              'add-new-entity.new-vacancy.form.candidates-quantity.placeholder' | translate
            "
          />
          <mat-error>
            <span translate>add-new-entity.new-vacancy.form.candidates-quantity.wrong</span>
          </mat-error>
        </mat-form-field>
      </div>
    }
    @if (getControlPositioning('mainSkill'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
          <div class="c-new-vacancy-dialog__column">
            <a
              class="c-new-vacancy-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-skills.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add-skills.button' | translate }}
            </a>
            <app-autocomplete-select
              [control]="newVacancyForm | getFormControl: 'mainSkill'"
              dictionaryName="mainSkills"
              class="c-new-vacancy-dialog__row--item"
              customClass="c-tsp-mat-form-field-autocomplete c-tsp-mat-form-field--with-label c-tsp-mat-form-field"
              label="add-new-entity.new-vacancy.form.main-skill.label"
              [placeholder]="'add-new-entity.new-vacancy.form.main-skill.label' | translate"
              labelType="always"
            >
            </app-autocomplete-select>
          </div>
          @if (isSuggestedParams('mainSkill')) {
            <ats-suggested-params
              class="c-new-vacancy-dialog__suggestion"
              [dictionary]="dictionaries?.mainSkills"
              [parameter]="vacancySuggestedParams.mainSkill"
              [multipleSuggestions]="true"
              (removeSuggestion)="removeSuggestion('mainSkill')"
              (selectSuggestedItem)="selectSuggestion($event, 'mainSkill', 'mainSkill')"
            >
            </ats-suggested-params>
          }
        </div>
      </div>
    }
    @if (getControlPositioning('skills'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-popup-multiple-select c-new-vacancy-dialog__row--item"
        >
          <label class="c-tsp-popup-multiple-select__label" translate
            >add-new-entity.new-vacancy.form.additional-skills.label</label
          >
          <div class="c-new-vacancy-dialog__column">
            <a
              class="c-new-vacancy-dialog__add-new-icon"
              [matTooltip]="
                'shared.services.scripts-injection.intercom.add-skills.tooltip' | translate
              "
              [href]="supportUrl$ | async"
              target="_blank"
            >
              {{ 'shared.services.scripts-injection.intercom.add-skills.button' | translate }}
            </a>
            <app-tag-input
              [autocompleteItems]="dictionaries?.skills"
              [controlValues]="currentSkills"
              [idsToExclude]="[newVacancyForm.get('mainSkill').value]"
              tagsType="skills"
              [customPlaceholder]="
                'add-new-entity.new-vacancy.form.additional-skills.placeholder' | translate
              "
              theme="popup-theme"
              (onChange)="updateSkills($event)"
            >
            </app-tag-input>
          </div>
          @if (isSuggestedParams('skills')) {
            <ats-suggested-params
              class="c-new-vacancy-dialog__suggestion"
              [dictionary]="dictionaries?.skills"
              [parameter]="vacancySuggestedParams.skills"
              [multipleSuggestions]="true"
              (removeSuggestion)="removeSuggestion('skills')"
              (selectSuggestedItem)="selectSuggestion($event, 'skills', 'skills')"
            >
            </ats-suggested-params>
          }
        </div>
      </div>
    }
    @if ((hasAccessToClients$ | async) && getControlPositioning('clients')) {
      <div
        [ngClass]="getControlPositioning('clients').width"
        [ngStyle]="{ order: getControlPositioning('clients').position }"
      >
        <label
          class="c-tsp-popup-multiple-select__label c-new-vacancy-dialog__clients-label"
          translate
          >add-new-entity.new-vacancy.form.clients.add.label</label
        >
        <div class="c-new-vacancy-dialog__clients">
          @for (client of selectedClients; track client) {
            <div class="c-new-vacancy-dialog__clients-tiles">
              <div class="c-new-vacancy-dialog__clients-tiles-item">
                <div class="c-new-vacancy-dialog__clients-image-none">
                  <span class="c-new-vacancy-dialog__clients-initials">
                    {{ client | getInitials }}
                  </span>
                </div>
                <span class="c-new-vacancy-dialog__clients-name">
                  {{ client.name }}
                </span>
              </div>
              <button
                class="c-new-vacancy-dialog__clients-tiles-remove"
                mat-icon-button
                (click)="removeSelectedClient(client)"
              >
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
          }
        </div>
        <div class="c-tsp-mat-form-field__row c-new-vacancy-dialog__clients-add">
          <app-multiple-select-search
            floatLabel="always"
            label=""
            placeholder="add-new-entity.new-vacancy.form.clients.add.placeholder"
            [control]="newVacancyForm | getFormControl: 'clients'"
            [options]="dictionaries.clients"
            triggerText="add-new-entity.new-vacancy.form.clients.add.placeholder"
            [useTrigger]="true"
          >
          </app-multiple-select-search>
        </div>
      </div>
    }
    @if (getControlPositioning('assignedRecruiter'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-new-vacancy-dialog__recruiters"
        >
          <app-autocomplete-select
            customClass="c-tsp-mat-form-field-autocomplete c-tsp-mat-form-field--with-label c-tsp-mat-form-field"
            label="add-new-entity.new-vacancy.form.assigned-recruiter.label"
            labelType="always"
            [allowClear]="false"
            [control]="newVacancyForm | getFormControl: 'assignedRecruiter'"
            [dictionary]="recruiters"
            [placeholder]="
              'add-new-entity.new-vacancy.form.assigned-recruiter.placeholder' | translate
            "
            [searchFromBeginning]="false"
            [withImage]="true"
            (optionSelected)="assignRecruiter($event)"
          >
          </app-autocomplete-select>
        </div>
      </div>
    }
    @if (getControlPositioning('postToListing'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <mat-checkbox class="c-tsp-mat-checkbox" formControlName="postToListing">
          <span translate> add-new-entity.new-vacancy.form.post-to-vacancy </span>
          <a [href]="currentUser?.vacancyUrl" target="_blank">{{ currentUser?.vacancyUrl }}</a>
        </mat-checkbox>
      </div>
    }
    @if (getControlPositioning('description'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
          <label
            class="c-tsp-popup-multiple-select__label c-tsp-popup-multiple-select__label--description c-new-vacancy-dialog__description-label"
            translate
          >
            add-new-entity.new-vacancy.form.description.label
          </label>
          <div
            class="c-tsp-mat-form-field__row c-tsp-popup__description c-new-vacancy-dialog__description"
          >
            <div class="c-new-vacancy-dialog__description-wrapper">
              <app-rich-editor
                formControlName="description"
                editorConfiguration="simple"
                (onEditorBlurred)="analyzeVacancyParams()"
              >
              </app-rich-editor>
              @if (controlHasErrorType('description', 'required')) {
                <mat-error class="c-new-vacancy-dialog__error-text">
                  <span
                    [innerHTML]="'add-new-entity.new-vacancy.form.description.required' | translate"
                  ></span>
                </mat-error>
              }
            </div>
          </div>
        </div>
      </div>
    }
    @if (getControlPositioning('durationTime'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-vacancy.form.duration.label</mat-label>
            <mat-select
              [placeholder]="'add-new-entity.new-vacancy.form.duration.placeholder' | translate"
              panelClass="c-tsp-mat-select-panel-default"
              formControlName="durationTime"
            >
              @for (duration of dictionaries?.durations; track duration) {
                <mat-option class="c-tsp-mat-form-field-option" [value]="duration.id">
                  <span translate>{{
                    duration.name | translateDictionaryName: 'duration' | async
                  }}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          @if (isSuggestedParams('duration')) {
            <ats-suggested-params
              class="c-new-vacancy-dialog__suggestion"
              [dictionary]="dictionaries?.durations"
              [parameter]="vacancySuggestedParams.duration"
              (removeSuggestion)="removeSuggestion('duration')"
              (selectSuggestedItem)="selectSuggestion($event, 'durationTime', 'duration')"
            >
            </ats-suggested-params>
          }
        </div>
      </div>
    }
    @if (getControlPositioning('insideInformation'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-vacancy.form.inside-info.label</mat-label>
          <textarea
            matInput
            formControlName="insideInformation"
            [placeholder]="'add-new-entity.new-vacancy.form.inside-info.placeholder' | translate"
            cdkTextareaAutosize="true"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          >
          </textarea>
        </mat-form-field>
      </div>
    }
    @if (getControlPositioning('rate'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-vacancy.form.rate.label</mat-label>
          <input
            matInput
            formControlName="rate"
            type="number"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-vacancy.form.rate.placeholder' | translate"
          />
        </mat-form-field>
      </div>
    }
    @if (hasAccessToSalary$ | async) {
      @if (getControlPositioning('currency'); as controlData) {
        <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-vacancy.form.currency.label</mat-label>
            <mat-select
              [placeholder]="'add-new-entity.new-vacancy.form.currency.placeholder' | translate"
              formControlName="currency"
            >
              @for (currency of currencies; track currency) {
                <mat-option class="c-tsp-mat-form-field-option" [value]="currency">
                  {{ currency }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @if (getControlPositioning('salary'); as controlData) {
        <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-vacancy.form.salary.label</mat-label>
            <input
              matInput
              formControlName="salary"
              class="c-tsp-mat-form-field-input"
              [placeholder]="'add-new-entity.new-vacancy.form.salary.placeholder' | translate"
            />
          </mat-form-field>
        </div>
      }
      @if (getControlPositioning('salaryComment'); as controlData) {
        <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
            floatLabel="always"
          >
            <mat-label translate>add-new-entity.new-vacancy.form.salaryComment.label</mat-label>
            <input
              matInput
              formControlName="salaryComment"
              class="c-tsp-mat-form-field-input"
              [placeholder]="
                'add-new-entity.new-vacancy.form.salaryComment.placeholder' | translate
              "
            />
          </mat-form-field>
        </div>
      }
    }
    @if (getControlPositioning('department'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-vacancy.form.department.label</mat-label>
          <mat-select
            [placeholder]="'add-new-entity.new-vacancy.form.department.placeholder' | translate"
            formControlName="department"
          >
            @for (item of departments$ | async; track trackById($index, item)) {
              <mat-option
                [value]="item.id"
                class="c-tsp-mat-form-field-option c-tsp-mat-multiple-select__option"
              >
                {{ item.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (data.mode === 'EDIT' && (isUserAdmin$ | async)) {
      @if (getControlPositioning('createdAt')) {
        <div
          [ngClass]="getControlPositioning('createdAt').width"
          [ngStyle]="{ order: getControlPositioning('createdAt').position }"
        >
          <div class="c-tsp-mat-form-field__row c-new-vacancy-dialog__additional-options-row">
            <mat-form-field
              class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-new-vacancy-dialog__date"
              floatLabel="always"
            >
              <mat-label class="c-tsp-popup-multiple-select__label" translate>
                add-new-entity.new-vacancy.form.created.label
              </mat-label>
              <input
                matInput
                class="c-tsp-mat-input"
                formControlName="createdAt"
                [owlDateTimeTrigger]="createdAt"
                [owlDateTime]="createdAt"
                [placeholder]="'add-new-entity.new-vacancy.form.select-date' | translate"
              />
              <owl-date-time #createdAt class="c-tsp-date-range-selector__dialog"> </owl-date-time>
              <mat-error>
                <span
                  [innerHTML]="'add-new-entity.new-vacancy.form.created.required' | translate"
                ></span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      }
    }
    @for (field of vacancyAdditionalDateFields; track field) {
      @if (data.mode === 'EDIT' && getControlPositioning(field.control); as controlData) {
        <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
          <mat-form-field
            class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-new-vacancy-dialog__date"
            floatLabel="always"
          >
            <mat-label class="c-tsp-popup-multiple-select__label" translate>
              {{ field.name }}
            </mat-label>
            <input
              matInput
              class="c-tsp-mat-input"
              [formControlName]="field.control"
              [owlDateTimeTrigger]="owlDateTimeTrigger"
              [owlDateTime]="owlDateTimeTrigger"
              [readonly]="isCloseDateFieldReadonly(field.control)"
              [placeholder]="'add-new-entity.new-vacancy.form.select-date' | translate"
            />
            <owl-date-time
              #owlDateTimeTrigger
              class="c-tsp-date-range-selector__dialog"
              [disabled]="isCloseDateFieldReadonly(field.control)"
            >
            </owl-date-time>
          </mat-form-field>
        </div>
      }
    }
    @if (
      vacancyCustomFields?.length &&
      (hasAccessToCustomFields$ | async) &&
      getControlPositioning('customFields')
    ) {
      <div
        [ngClass]="getControlPositioning('customFields').width"
        [ngStyle]="{ order: getControlPositioning('customFields').position }"
        class="c-new-vacancy-dialog__custom-fields"
      >
        <app-custom-fields-group
          class="c-new-vacancy-dialog__group"
          [fieldControls]="vacancyCustomFields"
          [fieldsDefinitions]="customFieldsDefinitions"
          [dictionaries]="dictionaries"
          formControlName="customFields"
        >
        </app-custom-fields-group>
      </div>
    }
    @if (getControlPositioning('startDate'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-date-select">
          <label class="c-tsp-popup-multiple-select__label" translate
            >add-new-entity.new-vacancy.form.start-date.label</label
          >
          <input
            class="c-tsp-mat-input"
            formControlName="startDate"
            [owlDateTimeTrigger]="vacancyDateRangeFilter"
            [owlDateTime]="vacancyDateRangeFilter"
            [placeholder]="'add-new-entity.new-vacancy.form.start-date.placeholder' | translate"
          />
          <owl-date-time
            #vacancyDateRangeFilter
            class="c-tsp-date-range-selector__dialog"
            [pickerType]="'calendar'"
          >
          </owl-date-time>
        </div>
      </div>
    }
    @if (getControlPositioning('deadlineDate'); as controlData) {
      <div [ngClass]="controlData.width" [ngStyle]="{ order: controlData.position }">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-mat-date-select">
          <label class="c-tsp-popup-multiple-select__label" translate
            >add-new-entity.new-vacancy.form.deadline-date.label</label
          >
          <input
            class="c-tsp-mat-input"
            formControlName="deadlineDate"
            [owlDateTimeTrigger]="deadlineDate"
            [owlDateTime]="deadlineDate"
            [placeholder]="'add-new-entity.new-vacancy.form.deadline-date.placeholder' | translate"
          />
          <owl-date-time
            #deadlineDate
            class="c-tsp-date-range-selector__dialog"
            [pickerType]="'calendar'"
          >
          </owl-date-time>
        </div>
      </div>
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form">
  <div class="c-tsp-mat-popup-form__item-wrapper">
    @if (data.mode === 'EDIT' && (allowDeleteVacancy$ | async)) {
      <button
        mat-button
        class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
        (click)="openDeletePopup()"
      >
        <span translate>add-new-entity.new-vacancy.delete.button</span>
      </button>
    }
  </div>
  <div class="c-new-vacancy-dialog__actions-warnings">
    @if (showRequiredMessage) {
      <span class="c-new-vacancy-dialog__bottom-message">
        <i class="fas fa-exclamation-triangle" aria-hidden="true"> </i>
        <span translate>add-new-entity.new-vacancy.required-message</span>
      </span>
    }
    @if (isCustomFieldsInvalid) {
      <span class="c-new-vacancy-dialog__bottom-message">
        <i class="fas fa-exclamation-triangle" aria-hidden="true"> </i>
        <span translate>add-new-entity.new-vacancy.custom-fields.warning</span>
        <span class="c-new-vacancy-dialog__bottom-message-strong">{{
          missedRequiredFields.join(', ')
        }}</span>
      </span>
    }
  </div>
  <div class="c-new-vacancy-dialog__actions">
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
      (click)="openClosePopup()"
    >
      <span translate>add-new-entity.new-vacancy.close.button</span>
    </button>
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
      (click)="publishVacancy(false)"
      [disabled]="!showSaveButton"
    >
      <span translate>add-new-entity.new-vacancy.{{ data.mode }}.button</span>
    </button>
    @if (data.mode === 'CREATE' && showMagicButton) {
      <button
        mat-button
        class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
        (click)="publishVacancy(true)"
        [disabled]="!showSaveButton"
      >
        <span translate>add-new-entity.new-vacancy.save-and-magic.button</span>
      </button>
    }
  </div>
</mat-dialog-actions>
