import {
  AuthForms,
  AuthPayload,
  AuthRegistration,
  Locales,
  SocialAuthTypes,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

export const AcceptCookiesAction = createAction(
  '[Auth] Accept cookies and save choice to local storage',
);
export const ChangeCurrentFormAction = createAction(
  '[Auth] Change current form',
  props<{ form: AuthForms }>(),
);
export const ChangeLocalizationAction = createAction(
  '[Auth] Change localization',
  props<{ locale: Locales }>(),
);
export const LogoutAction = createAction('[Auth] Logout from app');
export const OpenLoginPageAction = createAction(
  '[Auth] Open login page',
  props<{ email?: string }>(),
);
export const TryLoginAction = createAction(
  '[Auth] Try login with credentials',
  props<{ data: AuthPayload }>(),
);
export const ChangeFadedBackgroundStateAction = createAction(
  '[Auth] Change faded background state',
  props<{ isFade: boolean }>(),
);
export const LoginIntoAppWithGoogle = createAction(
  '[Auth] Login into application after Google successful authorization',
  props<{ token: string }>(),
);
export const TryPasswordRestoreAction = createAction(
  '[Auth] Try to password restore',
  props<{ password: string; email?: string }>(),
);
export const TryRegistrationAction = createAction(
  '[Auth] Try registration',
  props<{ data: AuthRegistration }>(),
);
export const TryResetPasswordAction = createAction(
  '[Auth] Try reset password',
  props<{ email: string }>(),
);
export const TrySocialAuthLoginAction = createAction(
  '[Auth] Try to login using social networks auth',
  props<{ authType: SocialAuthTypes }>(),
);

const actions = {
  AcceptCookiesAction,
  ChangeCurrentFormAction,
  ChangeFadedBackgroundStateAction,
  ChangeLocalizationAction,
  LoginIntoAppWithGoogle,
  LogoutAction,
  OpenLoginPageAction,
  TryLoginAction,
  TryPasswordRestoreAction,
  TryRegistrationAction,
  TryResetPasswordAction,
  TrySocialAuthLoginAction,
};

const authUserActions = union(actions);

export type AuthUserActionsType = typeof authUserActions;

export const AuthUserActions = actions;
