import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { AppVersionData } from '@app-shared/models/configuration.model';
import { State } from '@app-shared/reducers';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

export const appVersionResolver = (
  store$: Store<State>,
  appVersion: AppVersionData,
): Observable<boolean> => {
  store$.dispatch(MetadataActions.SetAppVersion({ version: appVersion.version }));

  return of(true);
};
