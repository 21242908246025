import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { emailTemplatesBodyVariables } from '@app-core/root/reducer/root.config';
import {
  DirectoryMailTemplateForm,
  Locales,
  MailAttachment,
  MailTemplate,
  MailTemplateTypes,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { MultipleSelectSearchComponent } from '@tsp-components/multiple-select-search';
import { RichEditorComponent } from '@tsp-components/rich-editor';
import { GetFormControlPipe } from '@tsp-pipes';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    GetFormControlPipe,
    MatButton,
    MatCheckbox,
    MatError,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MultipleSelectSearchComponent,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    RichEditorComponent,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-mail-template-form',
  templateUrl: './directory-mail-template-form.component.html',
  styleUrl: './directory-mail-template-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryMailTemplateFormComponent extends DirectoryEntityFormComponent {
  @Input() public attachments: MailAttachment[] = [];
  @Input() public locale: Locales;
  public bodyVariables = emailTemplatesBodyVariables;
  public entityForm?: FormGroup<DirectoryMailTemplateForm>;
  public notificationTemplatesTypes = [
    'interview',
    'questionnaire',
    'reject',
    'offer',
    'notification',
    'export',
    'testing-task',
  ];

  public predefinedContent: string | null = null;
  public predefinedSecondaryContent: string | null = null;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public addExampleToEditor(variable: string, addToMainContent: boolean) {
    if (addToMainContent) {
      this.predefinedContent = variable;
    } else {
      this.predefinedSecondaryContent = variable;
    }
  }
  public createForm(): FormGroup<DirectoryMailTemplateForm> {
    return this.formBuilder.group<DirectoryMailTemplateForm>({
      attachments: new FormControl<number[]>([]),
      body: new FormControl<string>(null, [Validators.required]),
      bodySecondary: new FormControl<string>(null),
      convertToPdf: new FormControl<boolean>(false),
      filename: new FormControl<string>(null),
      name: new FormControl<string>(null, [Validators.required]),
      subject: new FormControl<string | null>(null),
      type: new FormControl<MailTemplateTypes>(null, [Validators.required]),
    });
  }
  public get formDataForSymfony(): Omit<MailTemplate, 'id' | 'createdAt' | 'updatedAt'> {
    const formValue = this.entityForm.value;

    return {
      mailAttachments: this.selectedAttachments,
      body: formValue.body,
      bodySecondary: formValue.bodySecondary,
      convertToPdf: formValue.convertToPdf,
      filename: formValue.filename,
      name: formValue.name,
      subject: formValue.subject,
      type: formValue.type,
    };
  }
  public get selectedAttachments(): MailAttachment[] {
    if (!this.attachments || !this.entityForm.value.attachments) {
      return [];
    }
    return this.attachments.filter((item) => this.entityForm.value.attachments.includes(item.id));
  }
  public removeAttachment(attachmentId: number) {
    const attachments = this.entityForm.value.attachments.filter((item) => item !== attachmentId);
    this.entityForm.patchValue({ attachments });
  }
  public toggleFilenameControl(event: MatCheckboxChange) {
    if (event.checked) {
      this.entityForm.get('filename').enable();
      this.entityForm.get('filename').setValidators([Validators.required]);
    } else {
      this.entityForm.get('filename').disable();
      this.entityForm.get('filename').clearValidators();
    }
    this.entityForm.get('filename').updateValueAndValidity();
  }
  public updateFormWithData(item: MailTemplate) {
    this.entityForm.setValue({
      attachments: item.mailAttachments.map((attachment) => attachment.id) || [],
      body: item.body || null,
      bodySecondary: item.bodySecondary || null,
      convertToPdf: item.convertToPdf || false,
      filename: item.filename || null,
      name: item.name || null,
      subject: item.subject || null,
      type: item.type,
    });
  }
}
