import { AsyncPipe, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { DictionaryShortList, DirectoryFieldType, UnaryOperator } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { TranslateDictionaryNamePipe } from '@tsp-pipes';
import { find, includes, intersection, length, lt, pipe, pluck, propEq, without } from 'ramda';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  imports: [
    AsyncPipe,
    MatFormField,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MatTooltip,
    NgStyle,
    ReactiveFormsModule,
    TranslateDictionaryNamePipe,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'short-list-status-selector',
  templateUrl: './short-list-status-selector.component.html',
  styleUrls: ['./short-list-status-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListStatusSelectorComponent implements OnInit, OnDestroy {
  @Input()
  public set currentStatus(newStatus: number) {
    this.initControl();
    this.statusSelector.setValue(newStatus);
  }

  @Input()
  public set statuses(data: DictionaryShortList[]) {
    this.statusList = data;
    if (this.statusSelector) {
      this.statusSelector.updateValueAndValidity();
    }
  }
  @Input()
  public set isSelectorDisabled(data: boolean) {
    this.isControlDisabled = data;
    if (this.statusSelector) {
      this.toggleControlState();
    }
  }
  @Input()
  public notSelectableFieldsNames: string[];
  @Input()
  public isStatusProtected = false;

  @Output()
  public changeStatus = new EventEmitter<number>();
  public statusSelector: FormControl;
  public selectedStatusColor = '#ffffff';
  public selectedStatusName = '';
  public statusList: DictionaryShortList[] = [];
  private isControlDisabled = false;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    this.initControl();

    const appliesItem: DictionaryShortList = find(propEq('applies', 'code'))(
      this.statusList,
    ) as DictionaryShortList;
    const aiItem: DictionaryShortList = find(propEq('ai', 'code'))(
      this.statusList,
    ) as DictionaryShortList;
    if (appliesItem && this.statusSelector.value !== appliesItem.id) {
      this.statusList = without([appliesItem], this.statusList);
    }
    if (aiItem && this.statusSelector.value !== aiItem.id) {
      this.statusList = without([aiItem], this.statusList);
    }
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public isStatusNotSelectable(statusId: number): boolean {
    const status = pipe(
      find(propEq(statusId, 'id')) as UnaryOperator<DictionaryShortList[], DictionaryShortList>,
    )(this.statusList);

    return includes(status.code, ['ai', 'applies']) || this.statusHasNotSelectableFields(status);
  }
  public trackById(_index: number, element: DictionaryShortList) {
    return element.id;
  }
  private initControl() {
    if (!this.statusSelector) {
      this.statusSelector = new FormControl();
      this.toggleControlState();
      this.subscribeToSelectorChanges();
    }
  }
  private subscribeToSelectorChanges() {
    this.statusSelector.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      const status = find(propEq(value, 'id'), this.statusList) as DictionaryShortList | null;

      if (status) {
        this.selectedStatusColor = status.color || '#ffffff';
        this.selectedStatusName = status.name || '';
      } else {
        this.selectedStatusColor = '#ffffff';
        this.selectedStatusName = '';
      }
    });
  }
  private statusHasNotSelectableFields(status: DictionaryShortList) {
    if (!this.notSelectableFieldsNames) {
      return false;
    }
    return pipe(
      pluck('name') as UnaryOperator<DirectoryFieldType[], string[]>,
      intersection(this.notSelectableFieldsNames),
      length,
      lt(0),
    )(status.attachedFieldTypes);
  }
  private toggleControlState() {
    if (this.isControlDisabled) {
      this.statusSelector.disable();
    } else {
      this.statusSelector.enable();
    }
  }
}
