import { createAction, props, union } from '@ngrx/store';
import { KernelDepartmentAssignment, KernelDepartmentStructure } from '../models/kernel.model';

const CloseDepartmentAssignmentDialogAction = createAction(
  '[Kernel | Organization Structure] Open dialog on organization structure page for changing department assignments of recruiters or security groups',
  props<{ department: KernelDepartmentStructure }>(),
);
const OpenDepartmentAssignmentDialogAction = createAction(
  '[Kernel | Organization Structure] Open dialog on organization structure page for changing department assignments of recruiters or security groups',
  props<{ department: KernelDepartmentStructure }>(),
);
const RequestKernelOrganizationStructureFromResolverAction = createAction(
  '[Kernel | Organization Structure Resolver] Request organization structure from integration server',
);
const RequestKernelOrganizationStructureFailedAction = createAction(
  '[Kernel | Organization Structure | Custom Integration API] Error happened during requesting organization structure',
);
const RequestKernelOrganizationStructureSuccessAction = createAction(
  '[Kernel | Organization Structure | Custom Integration API] Organization structure saved to store',
  props<{ structure: KernelDepartmentStructure[] }>(),
);
const RequestRelatedDepartmentInfoAction = createAction(
  '[Kernel | Custom Integration API] Get related department info',
  props<{ vacancyId: number }>(),
);
const RequestRelatedDepartmentInfoFailedAction = createAction(
  '[Kernel | Custom Integration API] Error happened during getting related department info',
);
const RequestRelatedDepartmentInfoSuccessAction = createAction(
  '[Kernel | Custom Integration API] Related department info successfully received',
  props<{ info: { departmentId: string; email: string } }>(),
);
const SaveDepartmentAssignmentAction = createAction(
  '[Kernel | Organization Structure] Change assignments of recruiters and security groups for department',
  props<{ payload: KernelDepartmentAssignment }>(),
);
const SaveDepartmentAssignmentFailedAction = createAction(
  '[Kernel | Custom Integration API] Error happened on saving new assignments for department',
);
const SaveDepartmentAssignmentSuccessAction = createAction(
  '[Kernel | Custom Integration API] New assignments for departments successfully saved',
);

const actions = {
  CloseDepartmentAssignmentDialogAction,
  OpenDepartmentAssignmentDialogAction,
  RequestKernelOrganizationStructureFromResolverAction,
  RequestKernelOrganizationStructureFailedAction,
  RequestKernelOrganizationStructureSuccessAction,
  RequestRelatedDepartmentInfoAction,
  RequestRelatedDepartmentInfoFailedAction,
  RequestRelatedDepartmentInfoSuccessAction,
  SaveDepartmentAssignmentAction,
  SaveDepartmentAssignmentFailedAction,
  SaveDepartmentAssignmentSuccessAction,
};

const kernelActions = union(actions);

export type KernelActionsType = typeof kernelActions;

export const KernelActions = actions;
