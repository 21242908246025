import { environment } from '@app-environment/environment';
import { RouterStateUrl, UnaryOperator } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import {
  anyPass,
  head,
  isEmpty,
  isNil,
  nth,
  path,
  pipe,
  reject,
  split,
  test,
  toLower,
  unless,
} from 'ramda';

const getModule = (num: number): UnaryOperator<string, string> =>
  unless(
    isNil,
    pipe(
      split('('),
      head,
      split('?'),
      head,
      split('/'),
      reject(isEmpty),
      nth(num),
      unless(isNil, toLower),
    ),
  );

export const selectStore = (state: State): State => state;
export const selectRouterStore = (state: State): RouterReducerState<RouterStateUrl> => state.router;
export const getRouterState = createSelector(selectRouterStore, (s) => s?.state);
export const getCurrentPath = createSelector(getRouterState, (rs) => rs?.url);
export const getActiveModule = createSelector(getCurrentPath, getModule(1));
export const getActiveVacancySource = createSelector(getCurrentPath, getModule(3));

export const isAnySidepanelOpened = createSelector(
  selectStore,
  anyPass([path(['sidebar', 'visiblePanel']), path(['profileSidepanel', 'isSidepanelOpened'])]),
);
export const isEmptyView = createSelector(getCurrentPath, (path) => {
  return getModule(1)(path) === 'lobby';
});
export const isPopupOpened = createSelector(getCurrentPath, test(/extras:popup/g));

export const isKernelEnv: boolean = environment.integrations?.kernel;
