/// <reference types="@types/google.maps" />
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { DictionaryItem, UnaryOperator } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getCities } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  always,
  any,
  anyPass,
  find,
  ifElse,
  isEmpty,
  isNil,
  last,
  pipe,
  prop,
  propEq,
} from 'ramda';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AddNewEntityUserActions } from '../../actions/new-entity.user.actions';

@Component({
  imports: [MatInput, TranslateModule],
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class GoogleAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public cities: DictionaryItem[];
  @Input()
  public countries: DictionaryItem[];
  @Input()
  public selectedCity: number;
  @Input()
  public placeholder: string;
  @Output()
  public onSelect = new EventEmitter<{ city: number; country: number | null }>();
  @ViewChild('inputElement', { static: true })
  public inputElement: ElementRef<HTMLInputElement>;
  public inputDisabled = false;
  public autocomplete: google.maps.places.Autocomplete;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly store: Store<State>) {}

  public ngOnInit(): void {
    this.store
      .select(getCities)
      .pipe(
        filter(() => this.inputDisabled),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((cities) => {
        const city = last(cities) as DictionaryItem & { country: number };
        this.inputDisabled = false;
        this.updateValue(city.id, city.country);
      });
  }

  public ngOnChanges() {
    if (!globalThis?.google) {
      return;
    }
    this.autocomplete = new globalThis.google.maps.places.Autocomplete(
      this.inputElement.nativeElement,
      {
        types: ['(cities)'],
      },
    );
    globalThis.google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      const existedCityId = this.checkIfCityExist(place);
      if (existedCityId) {
        const country = pipe(
          find(propEq(existedCityId, 'id')),
          prop('country') as UnaryOperator<unknown, number | null>,
        )(this.cities);
        this.updateValue(existedCityId, country);
      } else {
        this.addCityToDictionary(place);
      }
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get inputValue(): string {
    return any(anyPass([isNil, isEmpty]))([this.selectedCity, this.cities])
      ? ''
      : pipe(
          find(
            propEq(this.selectedCity, 'id') as UnaryOperator<DictionaryItem, boolean>,
          ) as UnaryOperator<DictionaryItem[], DictionaryItem | undefined>,
          ifElse(isNil, always(''), prop('name') as UnaryOperator<DictionaryItem, string>),
        )(this.cities);
  }

  public checkInputChanges($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    if (anyPass([isNil, isEmpty])(value)) {
      this.onSelect.emit({ city: null, country: null });
    }
  }

  public get inputPlaceholder(): string {
    return this.placeholder ? this.placeholder : 'shared.google-autocomplete.placeholder';
  }

  private checkIfCityExist(place: google.maps.places.PlaceResult): number | null {
    return pipe(
      find(propEq(place.name, 'name')) as UnaryOperator<DictionaryItem[], DictionaryItem>,
      ifElse(isNil, always(null), prop('id')),
    )(this.cities) as number | null;
  }
  private addCityToDictionary(place: google.maps.places.PlaceResult): void {
    this.inputDisabled = true;

    this.store.dispatch(
      AddNewEntityUserActions.CreateNewCityAction({
        googlePlaceId: place.place_id,
        name: place.name,
      }),
    );
  }
  private updateValue(city: number, country: number | null = null): void {
    this.onSelect.emit({ city, country });
  }
}
