import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { UpdateTokensAction } from '@app-core/auth/actions/auth.system.actions';
import { checkAccessTokenValidity } from '@app-shared/functions/utilities/utilities';
import { AuthResponse } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { appVersion } from '@app-shared/reducers/app-feature/app-feature.reducer';
import { getTokens } from '@app-shared/reducers/auth/auth.reducer';
import { AuthService } from '@app-shared/services/auth/auth.service';
import { select, Store } from '@ngrx/store';
import { has, reduce, test } from 'ramda';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { interceptorInternalHeaders } from '../interceptor.config';

const removeInternalHeaders = (req: HttpRequest<unknown>, header: string): HttpRequest<unknown> => {
  return req.clone({
    headers: req.headers.delete(header),
  });
};

export const headerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const isFileUploading = req.headers.has('I-File-Uploading');
  const anonymousRequest = req.headers.has('I-Anonymous-Request');
  const isAuthRequest = req.headers.has('I-Auth-Request');
  const isLocalRequest = test(/assets|chrome-extension/g, req.url);

  const store = inject(Store<State>);
  const authService = inject(AuthService);

  return store.pipe(
    select(appVersion),
    first(),
    mergeMap((appVersion) => {
      const requestWithAppVersion = req.clone({
        setHeaders: {
          'x-app-version': `angular-${appVersion}`,
        },
      });

      const pureRequest = reduce(
        (request, header) => removeInternalHeaders(request, header),
        requestWithAppVersion,
        interceptorInternalHeaders,
      );

      if (anonymousRequest || isLocalRequest) {
        return next(pureRequest);
      }

      if (isAuthRequest) {
        const requestAsJson = pureRequest.clone({
          withCredentials: true,
        });
        return next(requestAsJson);
      }

      return store.pipe(
        select(getTokens),
        first(),
        mergeMap(({ token, refresh_token }) =>
          !!token && checkAccessTokenValidity(token)
            ? of({ token })
            : authService.refreshToken(refresh_token),
        ),
        mergeMap((tokens: AuthResponse) => {
          const hasRefreshToken = has('refresh_token', tokens);

          if (hasRefreshToken) {
            store.dispatch(UpdateTokensAction({ tokens }));
          }

          const requestWithCredentials = pureRequest.clone({
            setHeaders: {
              Authorization: `Bearer ${tokens.token}`,
            },
            withCredentials: true,
          });

          if (isFileUploading) {
            return next(requestWithCredentials);
          }
          const requestAsJson: HttpRequest<unknown> = requestWithCredentials.clone({
            setHeaders: {
              'Content-Type': 'application/json',
            },
          });

          return next(requestAsJson);
        }),
      );
    }),
  );
};
