import { Action, props, createAction, union } from '@ngrx/store';

export const ErrorNotificationAction = createAction(
  '[Notification] Error',
  props<{ message?: string; code?: number }>(),
);
export const InfoNotificationAction = createAction(
  '[Notification] Information',
  props<{ message?: string }>(),
);
export const InfoNotificationAndActivityAction = createAction(
  '[Notification] Information with next action',
  props<{ message?: string; action: Action }>(),
);
export const SuccessNotificationAction = createAction(
  '[Notification] Success',
  props<{ message?: string }>(),
);
export const SuccessNotificationAndActivityAction = createAction(
  '[Notification] Success with next action',
  props<{ message?: string; action: Action }>(),
);
export const WarningNotificationAction = createAction(
  '[Notification] Warning',
  props<{ message?: string }>(),
);

const actions = {
  ErrorNotificationAction,
  InfoNotificationAction,
  InfoNotificationAndActivityAction,
  SuccessNotificationAction,
  SuccessNotificationAndActivityAction,
  WarningNotificationAction,
};

const internalNotificationActions = union(actions);

export type InternalNotificationActionsType = typeof internalNotificationActions;

export const InternalNotificationActions = actions;
