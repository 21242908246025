import { ProfileSidepanelSystemActions } from '@app-core/profile-side-panel/actions/profile-sidepanel.system.actions';
import { ProfileSidepanelUserActions } from '@app-core/profile-side-panel/actions/profile-sidepanel.user.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { RouterActions } from '@app-shared/actions/router.actions';
import {
  AddCommentFailedAction,
  AddCommentSuccessfulAction,
  CreateShortListFromProfileFailedAction,
  CreateShortListFromProfileSuccessAction,
  DeleteCommentFailedAction,
  DeleteCommentSuccessfulAction,
  DeleteFileFailedAction,
  DeleteFileSuccessAction,
  GetProfileHistoryFailedAction,
  GetProfileHistorySuccessAction,
  RemoveFromShortListFailedAction,
  RemoveFromShortListSuccessAction,
  ResendShortListForSecurityCheckSuccessAction,
  ResendShortlistForSecurityCheckFailedAction,
  UpdateShortListFailedAction,
  UpdateShortListSuccessAction,
  UpdateSkillWeightFailedAction,
  UpdateSkillWeightSuccessAction,
  UpdateTagsAction,
  UpdateTagsFailedAction,
  UpdateTagsSuccessAction,
  UploadFileFailedAction,
  UploadFileSuccessAction,
} from '@app-shared/effects/profile-modification/profile-modification.actions';
import {
  addFileToProfileDetails,
  addShortListToProfileDetails,
  removeFileFromProfileDetails,
  removeShortListFromProfileDetails,
  updateShortListInProfileDetails,
} from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import { adjustSkillWeightInProfile } from '@app-shared/functions/profile-list-modifications/profile-list-modifications';
import {
  DateRange,
  FullProfile,
  HistoryState,
  Profile,
  ShortList,
  UnaryOperator,
} from '@app-shared/models';
import { getSelectedVacancyId } from '@app-shared/reducers/vacancy/vacancy.reducer';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  anyPass,
  concat,
  find,
  isEmpty,
  isNil,
  lensPath,
  mergeLeft,
  mergeRight,
  objOf,
  path,
  pathEq,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
  set,
  unless,
} from 'ramda';

export interface ProfileSidepanelState {
  activitiesTab?: string;
  currentTab?: string;
  fullInfo?: FullProfile;
  isDataChanged?: boolean;
  isFilesLoading: boolean;
  isLoading: boolean;
  isSidepanelBlocked: boolean;
  isSidepanelOpened: boolean;
  isSkillsBlocked: boolean;
  profileHistory: HistoryState;
  relatedTab?: string;
  shortInfo?: Profile;
}

export const initialState: ProfileSidepanelState = {
  isDataChanged: false,
  isFilesLoading: false,
  isLoading: false,
  isSidepanelBlocked: false,
  isSidepanelOpened: false,
  isSkillsBlocked: false,
  profileHistory: {
    isLoading: false,
    items: [],
    total: 0,
    query: {
      dateFrom: null,
      dateTo: null,
    },
  },
};

const reducer = createReducer(
  initialState,
  on(MetadataActions.CleanReducers, () => initialState),
  on(
    ProfileSidepanelUserActions.AddCommentFromSidepanelAction,
    ProfileSidepanelUserActions.AddToShortListFromSidepanelAction,
    ProfileSidepanelUserActions.DeleteCommentFromSidepanelAction,
    ProfileSidepanelSystemActions.RequestProfileDetailsFromSidepanelAction,
    ProfileSidepanelUserActions.RemoveFromShortListInSidepanelActivitiesAction,
    ProfileSidepanelUserActions.RemoveFromShortListInSidepanelVacancySelectorAction,
    ProfileSidepanelUserActions.ResendShortlistForSecurityCheckAction,
    ProfileSidepanelUserActions.UpdateShortListInSidepanelActivitiesAction,
    ProfileSidepanelUserActions.UpdateShortListInSidepanelVacancySelectorAction,
    UpdateTagsAction,
    mergeLeft({ isLoading: true } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(AddCommentSuccessfulAction, (state, { comment }) => {
    const profile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(profile)) {
      return state;
    }

    const comments = pipe(pathOr([], ['fullInfo', 'comments']), concat([comment]))(state);
    const commentsCount = comments.length;
    const fullInfo = mergeRight(profile, { comments, commentsCount } as Pick<
      FullProfile,
      'comments' | 'commentsCount'
    >) as FullProfile;
    return mergeRight(state, {
      isDataChanged: true,
      isLoading: false,
      fullInfo,
    }) as ProfileSidepanelState;
  }),
  on(
    ProfileSidepanelUserActions.ChangeActivitiesTabAction,
    ProfileSidepanelSystemActions.SetActivitiesTabAction,
    (state, { tab }) => mergeRight(state, { activitiesTab: tab }),
  ),
  on(
    ProfileSidepanelUserActions.ChangeRelatedTabAction,
    ProfileSidepanelSystemActions.SetRelatedTabAction,
    (state, { tab }) => mergeRight(state, { relatedTab: tab }),
  ),
  on(ProfileSidepanelUserActions.ChangeTabAction, (state, { tab }) =>
    mergeRight(state, { currentTab: tab }),
  ),
  on(
    ProfileSidepanelUserActions.CleanStateAction,
    ProfileSidepanelUserActions.ClosePanelAction,
    mergeLeft({
      activitiesTab: null,
      currentTab: null,
      fullInfo: null,
      isSidepanelOpened: false,
      relatedTab: null,
      shortInfo: null,
    }) as UnaryOperator<ProfileSidepanelState, ProfileSidepanelState>,
  ),
  on(
    ProfileSidepanelSystemActions.CloseSidepanelAfterUpdateSearchModelAction,
    RouterActions.OpenEditPopupAction,
    RouterActions.NavigateToVacancyProfilesAction,
    RouterActions.NavigateToProfilePageAction,
    RouterActions.NavigateToSearchConsoleAction,
    mergeLeft({ isSidepanelOpened: false } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(
    AddCommentFailedAction,
    CreateShortListFromProfileFailedAction,
    DeleteCommentFailedAction,
    RemoveFromShortListFailedAction,
    ProfileSidepanelSystemActions.RequestProfileDetailsFailedAction,
    ResendShortListForSecurityCheckSuccessAction,
    ResendShortlistForSecurityCheckFailedAction,
    UpdateTagsFailedAction,
    mergeLeft({ isLoading: false } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(CreateShortListFromProfileSuccessAction, (state, { shortList }) => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    const updateProfile = addShortListToProfileDetails(shortList, currentProfile);
    return mergeRight(state, { isDataChanged: true, isLoading: false, fullInfo: updateProfile });
  }),
  on(DeleteCommentSuccessfulAction, (state, { id }) => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }

    const leftComments = pipe(
      pathOr([], ['fullInfo', 'comments']),
      reject(propEq(id, 'id')),
    )(state);
    const commentsCount = leftComments.length;
    const fullInfo = mergeRight(currentProfile, { comments: leftComments, commentsCount });
    return mergeRight(state, {
      isDataChanged: true,
      isLoading: false,
      fullInfo,
    }) as ProfileSidepanelState;
  }),
  on(
    ProfileSidepanelUserActions.DeleteFileFromSidepanelAction,
    ProfileSidepanelUserActions.UploadFileFromSidepanelAction,
    mergeLeft({ isFilesLoading: true } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(
    DeleteFileFailedAction,
    UploadFileFailedAction,
    mergeLeft({ isFilesLoading: false } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(DeleteFileSuccessAction, (state, { fileId, fileType }) => {
    const type: 'cvs' | 'voices' | 'otherFiles' = `${
      fileType === 'other' ? 'otherFile' : fileType
    }s`;

    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }

    const profileWithoutFile = removeFileFromProfileDetails(currentProfile, fileId, type);
    return mergeRight(state, {
      isDataChanged: true,
      fullInfo: profileWithoutFile,
      isFilesLoading: false,
    });
  }),
  on(
    ProfileSidepanelUserActions.GetSidepanelProfileHistoryOnInitAction,
    set(lensPath(['profileHistory', 'isLoading']), true) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),

  on(ProfileSidepanelUserActions.GetSidepanelProfileHistoryAction, (state, { query }) =>
    pipe(
      path(['profileHistory', 'query']) as UnaryOperator<ProfileSidepanelState, DateRange>,
      mergeLeft(query) as UnaryOperator<DateRange, DateRange>,
      objOf('query'),
      mergeLeft({ isLoading: true }),
      mergeRight(state.profileHistory),
      objOf('profileHistory'),
      mergeRight(state),
    )(state),
  ),
  on(
    GetProfileHistoryFailedAction,
    set(lensPath(['profileHistory', 'isLoading']), false) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(GetProfileHistorySuccessAction, (state, { events, actionSource }) => {
    if (actionSource === 'details') {
      return state;
    }
    const { count, items } = events;
    return pipe(
      prop('profileHistory') as UnaryOperator<ProfileSidepanelState, HistoryState>,
      mergeLeft({
        total: count,
        items,
        isLoading: false,
      } as Partial<HistoryState>) as UnaryOperator<HistoryState, HistoryState>,
      objOf('profileHistory'),
      mergeRight(state),
    )(state);
  }),
  on(
    ProfileSidepanelSystemActions.RefreshSidepanelProfileAfterEditingAction,
    ProfileSidepanelSystemActions.RefreshSidepanelProfileAfterAddingToLongListAction,
    mergeLeft({
      isSidepanelBlocked: false,
      isLoading: true,
    } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(RemoveFromShortListSuccessAction, (state, { id }) => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    const updateProfile = removeShortListFromProfileDetails(id, currentProfile);
    return mergeRight(state, { isDataChanged: true, isLoading: false, fullInfo: updateProfile });
  }),
  on(
    ProfileSidepanelSystemActions.RequestProfileDetailsSuccessAction,
    (state, { profile, isRefresh }) => {
      return mergeRight(state, {
        isDataChanged: isRefresh,
        isLoading: false,
        fullInfo: profile,
        shortInfo: profile,
      });
    },
  ),
  on(ProfileSidepanelSystemActions.ResolveProfileShortInfoAction, (state, { profile }) =>
    mergeRight(state, { shortInfo: profile }),
  ),
  on(ProfileSidepanelSystemActions.SetSidepanelStateAction, (state, { tab }) =>
    mergeRight(state, {
      currentTab: tab,
      isSidepanelOpened: true,
    }),
  ),
  on(ProfileSidepanelSystemActions.ToggleSidepanelLoadingStateAction, (state, { isLoading }) =>
    mergeRight(state, { isLoading }),
  ),
  on(UpdateShortListSuccessAction, (state, { shortList }) => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    const updateProfile = updateShortListInProfileDetails(shortList, currentProfile, true);
    return mergeRight(state, { isDataChanged: true, isLoading: false, fullInfo: updateProfile });
  }),
  on(UpdateShortListFailedAction, (state, { shortList }) => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    const updateProfile = updateShortListInProfileDetails(shortList, currentProfile);
    return mergeRight(state, { isDataChanged: true, isLoading: false, fullInfo: updateProfile });
  }),
  on(UpdateTagsSuccessAction, (state, { data }) => {
    const profile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(profile)) {
      return state;
    }
    const newProfile = mergeRight(profile, { tags: data });
    return mergeRight(state, { isDataChanged: true, fullInfo: newProfile, isLoading: false });
  }),
  on(UploadFileSuccessAction, (state, { file, fileType }) => {
    const type: 'cvs' | 'voices' | 'otherFiles' = `${
      fileType === 'other' ? 'otherFile' : fileType
    }s`;
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    const profileWithNewFile = addFileToProfileDetails(currentProfile, file, type);
    return mergeRight(state, {
      isDataChanged: true,
      fullInfo: profileWithNewFile,
      isFilesLoading: false,
    });
  }),
  on(
    ProfileSidepanelUserActions.UpdateSkillWeightFromSidepanelAction,
    mergeLeft({ isSkillsBlocked: true } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
  on(UpdateSkillWeightSuccessAction, (state, { skill, skillType }) => {
    const profile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(profile)) {
      return state;
    }
    const target = skillType === 'skill' ? 'skills' : 'softSkills';
    const newProfile = adjustSkillWeightInProfile(skill, profile, target) as FullProfile;
    return mergeRight(state, {
      isDataChanged: true,
      fullInfo: newProfile,
      isSkillsBlocked: false,
    });
  }),
  on(
    UpdateSkillWeightFailedAction,
    mergeLeft({ isSkillsBlocked: false } as Partial<ProfileSidepanelState>) as UnaryOperator<
      ProfileSidepanelState,
      ProfileSidepanelState
    >,
  ),
);

export function profileSidepanelReducer(
  baseState: ProfileSidepanelState,
  action: Action,
): ProfileSidepanelState {
  return reducer(baseState, action);
}

export const selectProfileSidepanelStore =
  createFeatureSelector<ProfileSidepanelState>('profileSidepanel');

export const getActivitiesTab = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.activitiesTab,
);
export const getCurrentTab = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.currentTab,
);

export const getFilesLoading = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.isFilesLoading,
);
export const getFullDeveloperInfo = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.fullInfo,
);
export const getLoadingState = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.isLoading || false,
);
export const getPanelBlockedState = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.isSidepanelBlocked || false,
);
export const getPanelState = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.isSidepanelOpened || false,
);
export const getRelatedTab = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.relatedTab,
);
export const getSelectedVacancyShortList = createSelector(
  getFullDeveloperInfo,
  getSelectedVacancyId,
  (profile: FullProfile, id: number): ShortList | null =>
    pipe(
      propOr(null, 'processingList') as UnaryOperator<FullProfile, ShortList[] | null>,
      unless(
        anyPass([isNil, isEmpty]),
        find(pathEq(id, ['project', 'id'])) as UnaryOperator<ShortList[], ShortList>,
      ),
    )(profile) as ShortList,
);
export const getShortDeveloperInfo = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.shortInfo,
);

export const getSidepanelSkillsBlockingState = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.isSkillsBlocked || false,
);

export const getSidepanelProfileHistory = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.profileHistory,
);
export const getSidepanelProfileHistoryLoadingState = createSelector(
  getSidepanelProfileHistory,
  (history) => history?.isLoading,
);
export const getSidepanelProfileHistoryItemsList = createSelector(
  getSidepanelProfileHistory,
  (history) => history?.items,
);
export const getSidepanelProfileHistoryItemsCount = createSelector(
  getSidepanelProfileHistory,
  (history) => history?.total,
);
export const getSidepanelProfileHistoryQueryParams = createSelector(
  getSidepanelProfileHistory,
  (history) => history?.query,
);
export const isSidepanelDataMutated = createSelector(
  selectProfileSidepanelStore,
  (store) => store?.isDataChanged,
);
