import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  DeleteClientOnSymfonyFailedAction,
  DeleteClientOnSymfonySuccessAction,
  UpdateClientOnSymfonyFailedAction,
  UpdateClientOnSymfonySuccessAction,
} from '@app-core/clients-list/actions/clients-list.system.actions';
import { DirectoriesSystemActions } from '@app-core/directories/actions/directories.system.actions';
import { TeammatesSystemActions } from '@app-core/teammates/actions/teammates.system.actions';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { VacancyApiActions } from '@app-shared/actions/api-actions/vacancy-api.actions';
import { RouterActions } from '@app-shared/actions/router.actions';
import {
  AnalyzedAiCvData,
  BinaryOperator,
  ClientFull,
  DictionaryEditableItem,
  FullProfile,
  Profile,
  ProfileContacts,
  RecruiterInfo,
  Skill,
  SuggestedSkill,
  UnaryOperator,
  VacancySuggestedParams,
} from '@app-shared/models';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  always,
  append,
  converge,
  find,
  identity,
  ifElse,
  lensPath,
  lensProp,
  mergeLeft,
  mergeRight,
  objOf,
  pipe,
  prop,
  propEq,
  propOr,
  set,
  uniq,
  without,
} from 'ramda';

const AI_PARSING_TIME = 60;

export interface NewEntityState {
  clientData?: ClientFull;
  contacts?: ProfileContacts;
  currentDirectory?: string;
  cvParsingTimer?: number;
  dataFromFile?: AnalyzedAiCvData;
  developerData?: FullProfile;
  directoryData?: DictionaryEditableItem;
  initialData?: FullProfile;
  isLoading: boolean;
  isOpen: boolean;
  matchedProfileWithContacts?: { contact: string; profile: Profile }[];
  matchedProfileWithLinks?: { link: string; profile: Profile }[];
  recruiterData?: RecruiterInfo;
  vacancySuggestedParams?: VacancySuggestedParams;
}

export const initialState: NewEntityState = {
  cvParsingTimer: null,
  isLoading: false,
  isOpen: false,
};

const reducer = createReducer(
  initialState,
  on(AddNewEntitySystemActions.AnalyzeTitleAndDescriptionSuccess, (state, { parameters }) =>
    mergeRight(state, { vacancySuggestedParams: parameters }),
  ),
  on(ProfilesApiActions.CheckProfileExistenceSuccessAction, (state, { contact, profile }) => {
    const newMatchedProfileWithContacts = append(
      { contact, profile },
      state.matchedProfileWithContacts,
    );
    return mergeRight(state, {
      matchedProfileWithContacts: newMatchedProfileWithContacts,
    });
  }),
  on(ProfilesApiActions.CheckProfileExistenceLinksSuccessAction, (state, { link, profile }) => {
    const newMatchedProfileWithLinks = append({ link, profile }, state.matchedProfileWithLinks);
    return mergeRight(state, {
      matchedProfileWithLinks: newMatchedProfileWithLinks,
    });
  }),
  on(AddNewEntitySystemActions.RemoveMatchedProfileByContactAction, (state, { contact }) => {
    const matchedProfiles = state.matchedProfileWithContacts;
    if (!matchedProfiles) {
      return state;
    }
    const profileToRemove = find(propEq(contact, 'contact'), matchedProfiles);
    const newMatchedProfiles = without([profileToRemove], matchedProfiles) as {
      contact: string;
      profile: Profile;
    }[];
    return mergeRight(state, {
      matchedProfileWithContacts: newMatchedProfiles,
    });
  }),
  on(AddNewEntitySystemActions.RemoveMatchedProfileByLinkAction, (state, { link }) => {
    const matchedProfiles = state.matchedProfileWithLinks;
    if (!matchedProfiles) {
      return state;
    }
    const profileToRemove = find(propEq(link, 'link'), matchedProfiles);
    const newMatchedProfiles = without([profileToRemove], matchedProfiles) as {
      link: string;
      profile: Profile;
    }[];
    return mergeRight(state, {
      matchedProfileWithLinks: newMatchedProfiles,
    });
  }),
  on(
    AddNewEntitySystemActions.CleanNewEntityStateAction,
    DirectoriesSystemActions.CleanDirectoriesAction,
    () => initialState,
  ),
  on(
    RouterActions.CleanExtraPopupNavigationAction,
    mergeLeft({ isOpen: false } as Partial<NewEntityState>) as UnaryOperator<
      NewEntityState,
      NewEntityState
    >,
  ),
  on(
    AddNewEntitySystemActions.OpenClientDialogOnInitAction,
    AddNewEntitySystemActions.OpenCustomFieldDialogOnInitAction,
    AddNewEntitySystemActions.OpenDirectoryDialogOnInitAction,
    AddNewEntitySystemActions.OpenRecruiterDialogOnInitAction,
    AddNewEntitySystemActions.OpenTalentDialogOnInitAction,
    AddNewEntitySystemActions.OpenVacancyDialogOnInitAction,
    RouterActions.OpenCreatePopupAction,
    mergeLeft({ isOpen: true } as Partial<NewEntityState>) as UnaryOperator<
      NewEntityState,
      NewEntityState
    >,
  ),
  on(AddNewEntityUserActions.ModifyVacancySuggestionAction, (state, { name, value }) =>
    set(lensPath(['vacancySuggestedParams', name]), value, state),
  ),
  on(AddNewEntityUserActions.SelectSuggestedSkillAction, (state, { id }) => {
    const suggestedSkill = find(
      propEq(id, 'id'),
      propOr([], 'suggestedSkills', state.developerData),
    ) as SuggestedSkill;
    const propName: string = pipe(
      prop('soft'),
      ifElse(identity as UnaryOperator<boolean, boolean>, always('softSkills'), always('skills')),
    )(suggestedSkill);
    const developer = pipe(
      converge(
        mergeRight as BinaryOperator<
          { suggestedSkills: SuggestedSkill[] },
          Record<'softSkills' | 'skills', Skill[]>,
          { suggestedSkills: SuggestedSkill[]; skills: Skill[] }
        >,
        [
          pipe(propOr([], 'suggestedSkills'), without([suggestedSkill]), objOf('suggestedSkills')),
          pipe(propOr([], propName), append(suggestedSkill), uniq, objOf(propName)),
        ],
      ) as UnaryOperator<FullProfile, { suggestedSkills: SuggestedSkill[]; skills: Skill[] }>,
      mergeRight(state.developerData),
    )(state.developerData);

    return set(lensProp('developerData'), developer, state);
  }),
  on(AddNewEntitySystemActions.RequestClientDetailsOnInitSuccessAction, (state, { client }) =>
    mergeRight(state, { isLoading: false, clientData: client }),
  ),
  on(AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitSuccessAction, (state, { item }) =>
    mergeRight(state, { isLoading: false, directoryData: item }),
  ),
  on(AddNewEntitySystemActions.RequestProfileContactsSuccessAction, (state, { contacts }) =>
    mergeRight(state, { contacts }),
  ),
  on(AddNewEntitySystemActions.RequestProfileDetailsSuccessAction, (state, { profile }) =>
    mergeRight(state, { isLoading: false, developerData: profile }),
  ),
  on(AddNewEntitySystemActions.RequestRecruiterDetailsOnInitSuccessAction, (state, { recruiter }) =>
    mergeRight(state, { isLoading: false, recruiterData: recruiter }),
  ),
  on(TeammatesSystemActions.UpdateRecruiterSuccessAction, (state, { recruiter }) =>
    mergeRight(state, { isLoading: false, isOpen: false, recruiterData: recruiter }),
  ),
  on(
    AddNewEntityUserActions.UploadCvFromPopupAction,
    mergeLeft({ cvParsingTimer: AI_PARSING_TIME }),
  ),
  on(AddNewEntitySystemActions.UploadCvFailedAction, mergeLeft({ cvParsingTimer: 0 })),
  on(AddNewEntitySystemActions.UploadCvSuccessAction, (state, { file }) =>
    mergeRight(state, { cvParsingTimer: 0, dataFromFile: file }),
  ),
  on(
    AddNewEntitySystemActions.AnalyzeTitleAndDescriptionFailed,
    AddNewEntitySystemActions.DeleteDirectoryItemFailedAction,
    AddNewEntitySystemActions.DeleteProfileFromDialogFailedAction,
    AddNewEntitySystemActions.SaveNewClientFailedAction,
    AddNewEntitySystemActions.SaveNewTalentFailedAction,
    AddNewEntitySystemActions.SaveNewVacancyFailedAction,
    AddNewEntitySystemActions.UpdateDirectoryItemFailedAction,
    AddNewEntitySystemActions.UpdateTalentFailedAction,
    DeleteClientOnSymfonyFailedAction,
    TeammatesSystemActions.UpdateRecruiterFailedAction,
    UpdateClientOnSymfonyFailedAction,
    VacancyApiActions.DeleteVacancyOnSymfonyFailedAction,
    VacancyApiActions.GetVacancyDetailsSuccessAction,
    VacancyApiActions.UpdateVacancyFailedAction,
    mergeLeft({ isLoading: false } as Partial<NewEntityState>) as UnaryOperator<
      NewEntityState,
      NewEntityState
    >,
  ),
  on(
    AddNewEntitySystemActions.DeleteDirectoryItemSuccessAction,
    AddNewEntitySystemActions.DeleteProfileFromDialogSuccessAction,
    AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitFailedAction,
    AddNewEntitySystemActions.RequestProfileDetailsFailedAction,
    AddNewEntitySystemActions.RequestRecruiterDetailsOnInitFailedAction,
    AddNewEntitySystemActions.SaveNewClientSuccessAction,
    AddNewEntitySystemActions.SaveNewDirectoryItemFailedAction,
    AddNewEntitySystemActions.SaveNewDirectoryItemSuccessAction,
    AddNewEntitySystemActions.SaveNewRecruiterSuccessAction,
    AddNewEntitySystemActions.SaveNewVacancySuccessAction,
    AddNewEntitySystemActions.UpdateDirectoryItemSuccessAction,
    AddNewEntitySystemActions.UpdateTalentSuccessAction,
    DeleteClientOnSymfonySuccessAction,
    UpdateClientOnSymfonySuccessAction,
    VacancyApiActions.DeleteVacancyOnSymfonySuccessAction,
    VacancyApiActions.UpdateVacancySuccessAction,
    mergeLeft({ isLoading: false, isOpen: false } as Partial<NewEntityState>) as UnaryOperator<
      NewEntityState,
      NewEntityState
    >,
  ),
  on(
    AddNewEntitySystemActions.RequestClientDetailsOnInitAction,
    AddNewEntitySystemActions.RequestDirectoryItemDetailsOnInitAction,
    AddNewEntitySystemActions.RequestProfileDetailsOnInitAction,
    AddNewEntitySystemActions.RequestRecruiterDetailsOnInitAction,
    AddNewEntitySystemActions.RequestVacancyDetailsOnInitAction,
    AddNewEntityUserActions.CreateNewClientAction,
    AddNewEntityUserActions.CreateNewDirectoryItemAction,
    AddNewEntityUserActions.CreateNewRecruiterAction,
    AddNewEntityUserActions.CreateNewTalentAction,
    AddNewEntityUserActions.CreateNewVacancyAction,
    AddNewEntityUserActions.DeleteClientAction,
    AddNewEntityUserActions.DeleteDirectoryItemAction,
    AddNewEntityUserActions.DeleteProfileFromPopupAction,
    AddNewEntityUserActions.DeleteVacancyFromPopupAction,
    AddNewEntityUserActions.UpdateClientAction,
    AddNewEntityUserActions.UpdateDirectoryItemAction,
    AddNewEntityUserActions.UpdateRecruiterAction,
    AddNewEntityUserActions.UpdateTalentAction,
    AddNewEntityUserActions.UpdateVacancyAction,
    mergeLeft({ isLoading: true } as Partial<NewEntityState>) as UnaryOperator<
      NewEntityState,
      NewEntityState
    >,
  ),
  on(AddNewEntitySystemActions.SaveNewTalentSuccessAction, (state, { navigateToCreated }) => {
    const newState = navigateToCreated ? { isLoading: false } : { isLoading: false, isOpen: false };

    return mergeRight(state, newState);
  }),
);

export function newEntityReducer(baseState: NewEntityState | undefined, action: Action) {
  return reducer(baseState, action);
}

export const selectNewEntityStore = createFeatureSelector<NewEntityState>('newEntity');
export const getProfileContacts = createSelector(selectNewEntityStore, (store) => store?.contacts);
export const isLoading = createSelector(selectNewEntityStore, (store) => store?.isLoading);
export const getPopupState = createSelector(selectNewEntityStore, (store) => store?.isOpen);
export const getDeveloperInfo = createSelector(
  selectNewEntityStore,
  (store) => store?.developerData,
);
export const getRecruiterInfo = createSelector(
  selectNewEntityStore,
  (store) => store?.recruiterData,
);
export const getClientDetails = createSelector(selectNewEntityStore, (store) => store?.clientData);
export const getDirectoryDetails = createSelector(
  selectNewEntityStore,
  (store) => store?.directoryData,
);
export const getMatchedProfileWithContacts = createSelector(
  selectNewEntityStore,
  (store) => store?.matchedProfileWithContacts,
);
export const getDataFromFile = createSelector(selectNewEntityStore, (store) => store?.dataFromFile);
export const getMatchedProfileWithLinks = createSelector(
  selectNewEntityStore,
  (store) => store?.matchedProfileWithLinks,
);
export const getVacancySuggestedParams = createSelector(
  selectNewEntityStore,
  (store) => store?.vacancySuggestedParams,
);
export const getCvParsingTimer = createSelector(
  selectNewEntityStore,
  (store) => store?.cvParsingTimer,
);
