import { CV, DictionaryItem, DirectoryCustomFieldResponse, Entity, SimpleUser, User } from '.';
import { DictionaryShortListCodes } from './dictionary.model';
import { DirectoryFieldType } from './directory.model';

export interface LongListSuccessResponse extends Entity {
  author: number;
  developerId: string;
  projectId: number;
  sourceId: number;
}
export interface LongListErrorResponse {
  code: number;
  developerId: number;
  message: string;
}

export enum ShortListOperation {
  MOVE = 'move',
  COPY = 'copy',
}

export interface RejectReason extends DictionaryItem {
  description?: string;
}
export interface ShortList extends Entity {
  appliedAt?: string;
  author?: SimpleUser;
  authorChangeStatus?: SimpleUser | Array<unknown>;
  comment?: string | null;
  comments?: string | null | string[];
  contacts?: string | null;
  createdAt?: string;
  cvSentDate?: string;
  fieldValues?: DirectoryCustomFieldResponse[];
  hiredAt?: string;
  interviewAt?: string | null;
  interviewDate?: string;
  portal?: { id: number };
  project: DictionaryItem;
  rejectReason?: RejectReason | null;
  rejectedDate?: string;
  salary?: number;
  source?: DictionaryItem | (DictionaryItem & { code: string }) | string | string[];
  status: ShortListStatus;
  statusChangedAt?: string;
  updatedAt?: string;
  vacancyId?: string;
  relevant_ai?: boolean;
}
export interface ShortListPayload extends Entity {
  cvLink?: string;
  candidateName?: string;
  candidateSecurityId?: string;
  emails?: string[];
  developerId?: string;
  profile?: { id: string };
  fieldValues?: DirectoryCustomFieldResponse[];
  interviewAt?: string;
  reasonRejectId?: number;
  recruiterComment?: string;
  recruiterPrincipalEmail?: string;
  source_id?: number;
  sourceName?: string;
  status: ShortListStatus;
  vacancyId?: string;
  vacancyTitle?: string;
  budgetExcess?: boolean;
  formingOffer?: boolean;
  positionChanged?: boolean;
}
export interface ShortListStatus extends Entity {
  attachedFieldTypes?: DirectoryFieldType[];
  author?: Entity;
  code?: DictionaryShortListCodes;
  color?: string;
  name?: string;
  portal?: Entity;
  position?: number;
}
export interface ShortListCreateResponse extends Entity {
  author: SimpleUser;
  bidStatusDate: string;
  comment: string;
  comments: string[];
  contacts: string;
  createdAt: string;
  fieldValues: DirectoryCustomFieldResponse[];
  interviewAt: string;
  portal: { id: number };
  project: Entity & {
    title: string;
    assigned: SimpleUser;
    clients: (SimpleUser & { color?: string })[] | [];
  };
  rejectReason: RejectReason | null;
  salary: number;
  source: DictionaryItem;
  status: DictionaryItem;
}
export interface ShortListUpdateResponse extends Entity {
  author: Partial<User>;
  created_at: string;
  developer_id: string;
  fieldValues: DirectoryCustomFieldResponse[];
  project_id: number;
  project_name: string;
  rejectReason: RejectReason | null;
  relevant_ai: boolean;
  status: DictionaryItem;
  source: DictionaryItem;
  updated_at: string;
  updated_by: Partial<User>;
}
export interface ShortListSourcePayload {
  code: string;
  name: string;
}
export interface ShortListSource extends Entity, ShortListSourcePayload {}

export interface ShortListDialogData {
  customFields?: DirectoryFieldType[];
  cvs?: CV[];
  fieldValues: DirectoryCustomFieldResponse[];
  interviewAt?: Date;
  profileId?: string;
  reasonRejectId?: number;
  shortListProjectId?: number;
  showApprovalFields: boolean;
  showInterviewFields: boolean;
  showRejectionFields: boolean;
  showReviewFields: boolean;
  showManagerField: boolean;
  onlyOneManager: boolean;
  vacancyId?: number;
}
