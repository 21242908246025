import { Pipe, PipeTransform } from '@angular/core';
import {
  AllowedOptionType,
  AutocompleteItem,
  ClientShort,
  DictionaryItem,
  SimpleRecruiter,
  UnaryOperator,
  Vacancy,
} from '@app-shared/models';
import {
  adjust,
  anyPass,
  includes,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  prop,
  props,
  reject,
  when,
} from 'ramda';

type PossibleDictionaryItem =
  | DictionaryItem
  | AutocompleteItem
  | ClientShort
  | Vacancy
  | SimpleRecruiter;

@Pipe({
  name: 'convertOptionsForSearchSelect',
  standalone: true,
})
export class ConvertOptionsForSearchSelectPipe implements PipeTransform {
  public transform(dictionary: PossibleDictionaryItem[], namePath = ['name']): AllowedOptionType[] {
    return isNil(dictionary)
      ? []
      : map(
          when(
            pipe(prop('name') as UnaryOperator<PossibleDictionaryItem, string | undefined>, isNil),
            (item) => ({
              ...item,
              name: pipe(
                props(namePath) as unknown as UnaryOperator<PossibleDictionaryItem, string[]>,
                reject(anyPass([isNil, isEmpty])),
                when(
                  (res) => includes('teamName', namePath) && res.length === 3,
                  adjust(2, (el: string) => `(${el})`),
                ),
                join(' '),
              )(item),
            }),
          ) as UnaryOperator<PossibleDictionaryItem, AllowedOptionType>,
          dictionary,
        );
  }
}
