@if (isRejectReasonsLoading$ | async) {
  <div class="c-tsp-popup__loader">
    <app-spinner class="c-tsp-popup__spinner" [diameter]="30"> </app-spinner>
  </div>
}
<div
  class="c-profile-short-list"
  [ngClass]="{
    'c-profile-short-list--full': showFullList,
  }"
>
  @if (showFullList && projectName) {
    <short-list-project-info
      class="c-profile-short-list__project"
      [locale]="userLocale"
      [projectId]="projectId"
      [projectName]="projectName"
      [type]="shortListType"
    >
    </short-list-project-info>
  }
  <div class="c-profile-short-list__status-container--details">
    <div class="c-profile-short-list__status-container">
      @if (!shortListStatus) {
        <button class="c-profile-short-list__button-add-to" (click)="addToVacancy()">
          <span translate>profile-short-list.profile-short-list.add.button</span>
        </button>
      }
      @if (shortListStatus) {
        <short-list-status-selector
          #statusSelector
          class="c-profile-short-list__status-selector"
          [ngClass]="{
            'c-profile-short-list__status-selector--extended': isStatusForSendingLetters,
          }"
          [isSelectorDisabled]="isControlLocked"
          [isStatusProtected]="isStatusProtected"
          [statuses]="profileVacanciesStatuses"
          [currentStatus]="shortListStatus"
          [notSelectableFieldsNames]="notSelectableFieldsNames"
          (changeStatus)="onStatusChanged($event)"
        >
        </short-list-status-selector>
      }
      @if (isStatusProtected) {
        <div class="c-profile-short-list__controls">
          @if (isStatusSecurityCheck) {
            <button
              class="btn c-profile-short-list__button"
              [matTooltip]="'profile-short-list.profile-short-list.resend-to-security' | translate"
              (click)="resendShortlistForSecurityCheck.emit(shortList.id)"
            >
              <i
                class="fas fa-user-secret c-profile-short-list__protected-button-icon c-profile-short-list__protected-button-icon--resend"
                aria-hidden="true"
              ></i>
            </button>
          }
          <button
            class="btn c-profile-short-list__button"
            [matTooltip]="'profile-short-list.profile-short-list.reject-candidate' | translate"
            (click)="rejectCandidate()"
          >
            <i
              class="fas fa-user-slash c-profile-short-list__protected-button-icon c-profile-short-list__protected-button-icon--reject"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      }
      @if (!isControlLocked && shortList?.id) {
        <div class="c-profile-short-list__controls">
          <button
            class="btn c-profile-short-list__button"
            [matTooltip]="'profile-short-list.profile-short-list.remove.tooltip' | translate"
            (click)="removeFromShortList.emit(shortList.id)"
          >
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
          <button
            class="btn c-profile-short-list__button c-profile-short-list__button-edit"
            [matTooltip]="'profile-short-list.profile-short-list.edit.tooltip' | translate"
            (click)="openEditShortListDialog()"
          >
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </button>
          @if (isStatusForSendingLetters) {
            <short-list-send-letter-button
              [hasEmail]="profile | checkEmail"
              (sendLetter)="sendLetter.emit({ type: notificationType, shortList })"
            >
            </short-list-send-letter-button>
          }
        </div>
      }
    </div>
    @if (shortList?.interviewAt && isStatusIsDate) {
      <short-list-interview-info
        [hasEmail]="profile | checkEmail"
        [shortList]="shortList"
        [interviewAt]="shortList.interviewAt"
        (openExternalCalendar)="onOpenCalendar($event)"
        (changeInterviewDate)="onChangeInterviewDate()"
        (sendLetter)="sendLetter.emit({ type: 'interview', shortList })"
      >
      </short-list-interview-info>
    }
    @if (shortList && showInfo) {
      <profile-short-list-info
        [hasAccessToCustomFields]="hasAccessToCustomFields"
        [hasAccessToSalary]="hasAccessToSalary"
        [shortList]="shortList"
        (openCalendar)="openCalendar.emit($event)"
      >
      </profile-short-list-info>
    }
  </div>
</div>
