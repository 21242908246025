import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  DictionaryItem,
  DictionaryRegion,
  DirectoryCity,
  DirectoryCityForm,
  Locales,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { without } from 'ramda';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    MatChipGrid,
    MatChipInput,
    MatChipRemove,
    MatChipRow,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgxIntlTelInputModule,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-city-form',
  templateUrl: './directory-city-form.component.html',
  styleUrl: './directory-city-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectoryCityFormComponent extends DirectoryEntityFormComponent {
  @Input() public locale: Locales;
  @Input() public countries: DictionaryItem[] = [];
  @Input() public regions: DictionaryRegion[] = [];
  public entityForm?: FormGroup<DirectoryCityForm>;

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public createForm(): FormGroup<DirectoryCityForm> {
    return this.formBuilder.group<DirectoryCityForm>({
      enName: new FormControl<string>(null, [Validators.required]),
      ruName: new FormControl<string>(null),
      ukName: new FormControl<string>(null, [Validators.required]),
      countryId: new FormControl<number>(null, [Validators.required]),
      regionId: new FormControl<number>(null),
      synonyms: new FormControl<string[]>([]),
    });
  }
  public get formDataForSymfony(): Omit<
    DirectoryCity,
    | 'id'
    | 'name'
    | 'clientsCount'
    | 'profilesCount'
    | 'projectsCount'
    | 'countryName'
    | 'rabotaUaId'
    | 'regionName'
  > {
    return {
      enName: this.entityForm.value.enName,
      ruName: this.entityForm.value.ruName,
      ukName: this.entityForm.value.ukName,
      countryId: this.entityForm.value.countryId,
      regionId: this.entityForm.value.regionId,
      synonyms: this.entityForm.value.synonyms,
    };
  }
  public get filteredRegions(): DictionaryItem[] {
    const selectedCountryId = this.entityForm.value.countryId;

    if (!selectedCountryId) {
      return this.regions;
    }
    return this.regions.filter((region) => region.country === selectedCountryId);
  }
  public get synonyms(): string[] {
    return this.entityForm.value.synonyms;
  }
  public updateFormWithData(data: DirectoryCity): void {
    this.entityForm.patchValue(data);
  }
  public removeSynonym(keyword: string) {
    if (keyword) {
      const existedSynonyms = this.synonyms;
      this.entityForm.patchValue({ synonyms: without([keyword], existedSynonyms) });
    }
  }
  public addSynonym(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      const existedSynonyms = this.synonyms;
      this.entityForm.patchValue({ synonyms: [...existedSynonyms, value] });
    }

    event.chipInput.clear();
  }
}
