import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryItem, Skill } from '@app-shared/models';

@Pipe({
  name: 'castSkillType',
  standalone: true,
})
export class CastSkillTypePipe implements PipeTransform {
  public transform(value: Skill[] | DictionaryItem[]): Skill[] {
    return value as Skill[];
  }
}
