<div class="c-profile-skills__preview">
  <profile-dynamic-list
    class="c-profile-skills__list"
    type="skill"
    [profileId]="profile.id"
    [elements]="profileSkills | skillsSort: activeSkillsFilters : showMainSkill"
    [dictionary]="skillsDictionary"
    [skillsBlockingState]="skillsBlockingState"
    [showAllElements]="showDetailedInfo"
    [showSuggested]="showSuggested"
    [showExpandButton]="showExpandButton"
    [showSkillsTooltip]="profile.isPrivate"
    [suggestedSkills]="suggestedSkills"
    (editProfile)="editProfile.emit($event)"
  >
  </profile-dynamic-list>
</div>
