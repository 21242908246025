import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor as Interceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { isNil, reduce, test } from 'ramda';

import { environment } from '@app-environment/environment';

@Injectable({ providedIn: 'root' })
export class UrlInterceptorService implements Interceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isLocalRequest = test(/assets|chrome-extension/g, req.url);

    if (isLocalRequest) {
      return next.handle(req);
    }

    const modifiedRequest = req.clone({
      url: `${environment.apiUrl}${req.url}`,
    });

    const pureRequest = reduce(
      (request, param) => this.removeEmptyParams(request, param),
      modifiedRequest,
      modifiedRequest.params.keys(),
    );

    return next.handle(pureRequest);
  }

  private removeEmptyParams(req: HttpRequest<unknown>, param: string) {
    if (isNil(req.params.get(param))) {
      return req.clone({
        params: req.params.delete(param),
      });
    }
    return req;
  }
}
