import { Pipe, PipeTransform } from '@angular/core';
import { Locales } from '@app-shared/models';

@Pipe({
  name: 'convertLocaleToIso',
  standalone: true,
})
export class ConvertLocaleToIsoPipe implements PipeTransform {
  public transform(value: Locales): string {
    switch (value) {
      case 'uk':
        return 'uk-UA';
      case 'ru':
        return 'ru-RU';
      case 'en':
      default:
        return 'en-US';
    }
  }
}
