import { Directive, ElementRef, Renderer2, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[appLimitTagsWidth]',
  standalone: true,
})
export class LimitTagsWidthDirective implements AfterViewInit {
  @Input()
  public cssClass = 'c-profile-preview-skills__tags--show-more';
  private readonly element: HTMLElement;

  constructor(
    el: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.element = el.nativeElement;
  }

  public ngAfterViewInit() {
    if (this.element.offsetHeight > 35) {
      this.renderer.addClass(this.element, this.cssClass);
    }
  }
}
