<div>
  @if (profileSummary) {
    <div class="c-profile-extended-prof-info__list">
      <span class="c-profile-extended-prof-info__list-title" translate
        >profile-preview.profile-extended-professional-info.summary</span
      >
      <div class="c-profile-extended-prof-info__summary">
        <p class="c-profile-extended-prof-info__summary-text">{{ profileSummary }}</p>
      </div>
    </div>
  }
  @if (hasWorkHistory) {
    <div class="c-profile-extended-prof-info__list">
      <span class="c-profile-extended-prof-info__list-title" translate
        >profile-preview.profile-extended-professional-info.job-experience</span
      >
      <div class="c-profile-extended-prof-info__projects">
        @for (project of allProfileProjects; track project) {
          <div>
            <div class="c-profile-extended-prof-info__project-container">
              <div class="c-profile-extended-prof-info__project-icon">
                <i class="fa-solid fa-briefcase" aria-hidden="true"></i>
              </div>
              <div class="c-profile-extended-prof-info__project-content">
                <div class="c-profile-extended-prof-info__project-header">
                  <div>
                    <span class="c-profile-extended-prof-info__project-position">{{
                      project.position ?? project.title
                    }}</span>
                    @if (project.company) {
                      <span class="c-profile-extended-prof-info__project-at" translate
                        >profile-preview.profile-extended-professional-info.at</span
                      >
                    }
                    @if (project.company) {
                      <span class="c-profile-extended-prof-info__project-company">{{
                        project.company
                      }}</span>
                    }
                    @if (project.experience) {
                      <span class="c-profile-extended-prof-info__project-experience"
                        >({{ project.experience }})</span
                      >
                    }
                  </div>
                  @if (project.location) {
                    <div>
                      <span class="c-profile-extended-prof-info__project-location">{{
                        project.location
                      }}</span>
                    </div>
                  }
                </div>
                @if (project.description) {
                  <div class="c-profile-extended-prof-info__project-body">
                    <p class="c-profile-extended-prof-info__project-text">
                      {{ project.description }}
                    </p>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
  @if (hasEducation) {
    <div class="c-profile-extended-prof-info__list">
      <span class="c-profile-extended-prof-info__list-title" translate
        >profile-preview.profile-extended-professional-info.education</span
      >
      <div class="c-profile-extended-prof-info__education">
        @for (education of allProfileEducation; track education) {
          <div>
            <div class="c-profile-extended-prof-info__education-container">
              <div class="c-profile-extended-prof-info__education-row">
                <div class="c-profile-extended-prof-info__education-icon">
                  <i class="fa-solid fa-graduation-cap" aria-hidden="true"></i>
                </div>
                <div class="c-profile-extended-prof-info__education-content">
                  <span class="c-profile-extended-prof-info__education-school-name">{{
                    education.speciality
                  }}</span>
                  @if (education.schoolName) {
                    <span class="c-profile-extended-prof-info__education-at" translate
                      >profile-preview.profile-extended-professional-info.at</span
                    >
                  }
                  @if (education.schoolName) {
                    <span class="c-profile-extended-prof-info__education-speciality">{{
                      education.schoolName
                    }}</span>
                  }
                  @if (education.startDate || education.finishDate) {
                    <span class="c-profile-extended-prof-info__education-duration">
                      ({{
                        calcDifference(education.startDate, education.finishDate).count
                          | pluralizeText
                            : calcDifference(education.startDate, education.finishDate).unit
                      }})
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
