import { Pipe, PipeTransform } from '@angular/core';
import { innerJoin, isNil } from 'ramda';

import { SimpleRecruiter } from '@app-shared/models';
import none from 'ramda/es/none';

@Pipe({
  name: 'filterSelectedRecruiter',
  standalone: true,
})
export class FilterSelectedRecruiterPipe implements PipeTransform {
  public transform(dictionary: SimpleRecruiter[], value: number[]) {
    if (none(isNil)([dictionary, value])) {
      return innerJoin((recruiter, id) => recruiter.id === id, dictionary, value);
    }
    return [];
  }
}
