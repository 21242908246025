import {
  CalendarPayload,
  CalendarTarget,
  DateRange,
  FileType,
  ProfileContactRequestSource,
  ShortList,
  ShortListPayload,
  SkillWithNewWeight,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddCommentFromSidepanelAction = createAction(
  '[Profile Side Panel] Add comment for profile',
  props<{ comment: string; devId: string; bidId?: number }>(),
);
const AddToShortListFromSidepanelAction = createAction(
  '[Profile Side Panel] Add opened candidate to short list from sidepanel',
  props<{ shortList: Partial<ShortListPayload>; actionSource: 'sidepanel' }>(),
);
const ChangeActivitiesTabAction = createAction(
  '[Profile Side Panel] Change sub tab in the activities tab in the sidepanel',
  props<{ tab: string }>(),
);
const ChangeRelatedTabAction = createAction(
  '[Profile Side Panel] Change sub tab in the related tab in the sidepanel',
  props<{ tab: string }>(),
);
const ChangeTabAction = createAction(
  '[Profile Side Panel] Change tab in the side panel',
  props<{ tab: string }>(),
);
const ChangeVacancyAction = createAction(
  '[Profile Side Panel] Change active vacancy in selector',
  props<{ vacancyId: number }>(),
);
const CleanStateAction = createAction('[Profile Side Panel] Return profile side panel state');
const ClosePanelAction = createAction('[Profile Side Panel] Close and clean side panel');
const DeleteCommentFromSidepanelAction = createAction(
  '[Profile Side Panel] Delete comment from profile',
  props<{ id: number }>(),
);
const DeleteFileFromSidepanelAction = createAction(
  '[Profile Side Panel Files] Delete file from the sidepanel',
  props<{ devId: string; id: number; fileType: FileType }>(),
);
const GetProfileContactsAction = createAction(
  '[Profile Side Panel] Get profile contacts from sidepanel',
  props<{
    id: string;
    source: ProfileContactRequestSource;
    profileName: string;
    clickedContact: string;
  }>(),
);
const GetSidepanelProfileHistoryOnInitAction = createAction(
  '[Profile Side Panel History Tab] Initial request profile history events on tab open',
);
const GetSidepanelProfileHistoryAction = createAction(
  '[Profile Side Panel History Tab] Request profile history events',
  props<{ query: DateRange }>(),
);
const OpenShortListCalendarInSidepanelActivitiesAction = createAction(
  '[Profile Sidepanel | Activities Tab] Open calendar page for setting interview event from profile sidepanel',
  props<{ target: CalendarTarget; data: CalendarPayload; event?: string }>(),
);
const OpenShortListCalendarInSidepanelVacancySelectorAction = createAction(
  '[Profile Sidepanel | Vacancy Selector] Open calendar page for setting interview event from vacancy selector on profile sidepanel',
  props<{ target: CalendarTarget; data: CalendarPayload }>(),
);
const RemoveFromShortListInSidepanelActivitiesAction = createAction(
  '[Profile Sidepanel | Activities Tab] Remove profile from short list from profile sidepanel',
  props<{ id: number }>(),
);
const RemoveFromShortListInSidepanelVacancySelectorAction = createAction(
  '[Profile Sidepanel | Vacancy Selector] Remove profile from short list from vacancy selector in profile sidepanel',
  props<{ id: number }>(),
);
const ResendShortlistForSecurityCheckAction = createAction(
  '[Profile Sidepanel | Activities Tab] Resend shortlist for security check',
  props<{ id: number }>(),
);
const UpdateShortListInSidepanelActivitiesAction = createAction(
  '[Profile Sidepanel | Activities Tab] Update existed short list from profile sidepanel',
  props<{ previousShortList: Partial<ShortList>; shortList: Partial<ShortList> }>(),
);
const UpdateShortListInSidepanelVacancySelectorAction = createAction(
  '[Profile Sidepanel | Vacancy Selector] Update existed short list from vacancy selector in profile sidepanel',
  props<{ previousShortList: Partial<ShortList>; shortList: Partial<ShortList> }>(),
);
const UpdateSkillWeightFromSidepanelAction = createAction(
  '[Profile Side Panel] Change skill weight',
  props<{
    skill: SkillWithNewWeight;
    source: 'profilePage' | 'profileSidepanel';
    skillType: 'skill' | 'soft_skill';
  }>(),
);
const UploadFileFromSidepanelAction = createAction(
  '[Profile Side Panel Files] Upload file',
  props<{ id: string | number; file: File; fileType: FileType }>(),
);

const actions = {
  AddCommentFromSidepanelAction,
  AddToShortListFromSidepanelAction,
  ChangeActivitiesTabAction,
  ChangeRelatedTabAction,
  ChangeTabAction,
  ChangeVacancyAction,
  CleanStateAction,
  ClosePanelAction,
  DeleteCommentFromSidepanelAction,
  DeleteFileFromSidepanelAction,
  GetProfileContactsAction,
  GetSidepanelProfileHistoryOnInitAction,
  GetSidepanelProfileHistoryAction,
  OpenShortListCalendarInSidepanelActivitiesAction,
  OpenShortListCalendarInSidepanelVacancySelectorAction,
  RemoveFromShortListInSidepanelActivitiesAction,
  RemoveFromShortListInSidepanelVacancySelectorAction,
  ResendShortlistForSecurityCheckAction,
  UpdateShortListInSidepanelActivitiesAction,
  UpdateShortListInSidepanelVacancySelectorAction,
  UpdateSkillWeightFromSidepanelAction,
  UploadFileFromSidepanelAction,
};

const profileSidepanelUserActions = union(actions);

export type ProfileSidepanelUserActionsType = typeof profileSidepanelUserActions;

export const ProfileSidepanelUserActions = actions;
