import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryCandidateStage, DictionaryEditableItem } from '@app-shared/models';

@Pipe({
  name: 'castToBidStatus',
  standalone: true,
})
export class CastToBidStatusPipe implements PipeTransform {
  public transform(value: DictionaryEditableItem): DictionaryCandidateStage {
    return value as DictionaryCandidateStage;
  }
}
