import { DirectoryCountry, DirectoryCustomFieldResponse, Entity } from '@app-shared/models';

export interface CityPayload {
  city: string;
  country?: DirectoryCountry;
  countryName?: string;
  id?: number;
  isoCode?: string;
  latitude?: number;
  longitude?: number;
}
export interface ClientPayload {
  city: Entity | null;
  country: Entity | null;
  clientType: number | null;
  createUser: boolean;
  email: string | null;
  firstName: string;
  fieldValues: DirectoryCustomFieldResponse[];
  id: number;
  lastName: string | null;
  phone: string | null;
  skype: string | null;
  teamName: string | null;
}

export interface User extends Entity {
  additional_contact: string;
  city_id: number;
  country_id: number;
  created_at: string;
  email: string;
  first_name_latin: string;
  first_name: string;
  last_name_latin: string;
  last_name: string;
  payment_account: never;
  roles: string[];
  securitytoken: string;
  short_name: string;
  skype: string;
  unsubscribe_date: Date;
  updated_at: string;
}
