import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSalary',
  standalone: true,
})
export class FormatSalaryPipe implements PipeTransform {
  private readonly locale = navigator.language;

  public transform(rate: number, currency: string): string {
    return new Intl.NumberFormat(this.locale, { style: 'currency', currency }).format(rate);
  }
}
