@for (field of filteredCustomFields; track field) {
  <div
    class="c-preview-custom-fields__item"
    [ngClass]="{ 'c-preview-custom-fields__item--hidden': !isFieldsVisible && showToggler }"
  >
    @if (showCalendar && isDateField(field)) {
      <div class="c-preview-custom-fields__calendar-links">
        @for (link of calendarLinks; track link) {
          <button
            class="c-preview-custom-fields__calendar-link"
            (click)="onOpenCalendar(field.date, link.type, field.type.name)"
          >
            <i [ngClass]="link.className" aria-hidden="true"></i>
          </button>
        }
      </div>
    }
    @if (isValueALink(field)) {
      <a
        class="c-preview-custom-fields__value c-preview-custom-fields__value--link"
        [href]="getFieldValue(field)"
        rel="noopener noreferrer"
        target="_blank"
        translate
      >
        {{ field.type.name }}
      </a>
    } @else {
      <span class="c-preview-custom-fields__name">{{ field.type.name }}:</span>&nbsp;
      @if (isDateField(field)) {
        <span
          class="c-preview-custom-fields__value"
          [matTooltip]="getFieldValue(field) | formatDateTime: 'medium' | castToString"
        >
          {{ getFieldValue(field) | formatDateTime: 'short' }}
        </span>
      } @else {
        <span class="c-preview-custom-fields__value" [innerHTML]="getFieldValue(field)"></span>
      }
    }
  </div>
}
@if (showToggler) {
  <div class="c-preview-custom-fields__toggler-wrapper">
    <button
      class="c-preview-custom-fields__toggler"
      mat-button
      [disableRipple]="true"
      [matTooltip]="
        'shared.preview-custom-fields.toggler.' + (isFieldsVisible ? 'hide' : 'show') | translate
      "
      (click)="isFieldsVisible = !isFieldsVisible"
    >
      @if (isFieldsVisible) {
        <span>
          <i class="fas fa-chevron-up" aria-hidden="true"></i>
        </span>
      } @else {
        <span>
          <i class="fas fa-chevron-down" aria-hidden="true"></i>
        </span>
      }
    </button>
  </div>
}
