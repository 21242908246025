import { Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[appShowPassword]',
  standalone: true,
})
export class ShowPasswordDirective {
  private showPassword = false;
  private readonly element: HTMLElement;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
    this.setup();
  }

  public toggle(span: HTMLElement) {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.element.setAttribute('type', 'text');
      span.innerHTML = '<span class="fa fa-fw fa-eye-slash"></span>';
    } else {
      this.element.setAttribute('type', 'password');
      span.innerHTML = '<span class="fa fa-fw fa-eye"></span>';
    }
  }
  public setup() {
    const parent = this.element.parentNode;
    const span = document.createElement('span');
    span.innerHTML = '<span class="fa fa-fw fa-eye"></span>';
    span.setAttribute('style', 'cursor: pointer; position: absolute; right: 10px; top: 16px;');
    span.addEventListener('click', () => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
