import { State } from '@app-shared/reducers';
import { Store, Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

export function genericAppInitResolver(action: Action<string>) {
  return (store$: Store<State>): Observable<boolean> => {
    store$.dispatch(action);

    return of(true);
  };
}
