import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {
  DictionarySpeciality,
  DirectorySpecialityForm,
  Locales,
  UnaryOperator,
} from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { GetFormControlPipe } from '@tsp-pipes';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { find, isNil, pipe, prop, propEq, propOr, unless } from 'ramda';
import { DirectoryEntityFormComponent } from '../directory-generic-form';

@Component({
  imports: [
    GetFormControlPipe,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgxIntlTelInputModule,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'directories-speciality-form',
  templateUrl: './directory-speciality-form.component.html',
  styleUrl: './directory-speciality-form.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DirectorySpecialityFormComponent extends DirectoryEntityFormComponent {
  @Input() public set userLocale(data: Locales) {
    if (!data) {
      return;
    }
    this._userLocale = data;

    if (this.entityForm) {
      this.entityForm.get('specialityLocale').setValue(data);
    }
  }

  public locales = [
    { name: 'en', title: 'eng' },
    { name: 'ru', title: 'rus' },
    { name: 'uk', title: 'ukr' },
  ];
  public entityForm?: FormGroup<DirectorySpecialityForm>;
  private _userLocale: Locales | null = 'en';

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  public get selectedLocale(): Locales {
    return this.entityForm.get('specialityLocale').value;
  }

  public createForm(): FormGroup<DirectorySpecialityForm> {
    return this.formBuilder.group<DirectorySpecialityForm>({
      specialityLocale: new FormControl<Locales>(this._userLocale),
      en: new FormControl<string>(null, [Validators.required]),
      ru: new FormControl<string | null>(null),
      uk: new FormControl<string | null>(null),
    });
  }
  public get formDataForSymfony(): Omit<DictionarySpeciality, 'id' | 'name' | 'speciality'> {
    const formValue = this.entityForm.value;

    const setId = (locale: Locales): number =>
      pipe(
        propOr([], 'translations'),
        find(propEq(locale, 'locale')),
        unless(isNil, prop('id')) as UnaryOperator<
          { name: string; locale: Locales; id: number },
          number
        >,
      )(this.item);

    return {
      translations: [
        { name: formValue.en, locale: 'en', id: setId('en') },
        { name: formValue.ru, locale: 'ru', id: setId('ru') },
        { name: formValue.uk, locale: 'uk', id: setId('uk') },
      ],
    };
  }
  public updateFormWithData(directoryItem: DictionarySpeciality) {
    const setName = (locale: Locales) =>
      pipe(
        propOr([], 'translations'),
        find(propEq(locale, 'locale')),
        unless(isNil, prop('name')) as UnaryOperator<Record<string, Locales>, Locales>,
      )(directoryItem);

    this.entityForm.setValue({
      specialityLocale: this._userLocale,
      en: setName('en') || null,
      ru: setName('ru') || null,
      uk: setName('uk') || null,
    });
  }
}
