<h2 mat-dialog-title class="c-tsp-popup__title" translate>{{ popupTitle }}</h2>
<mat-dialog-content>
  @if (isLoading$ | async) {
    <div class="c-tsp-popup__loader">
      <app-spinner class="c-tsp-popup__spinner"> </app-spinner>
    </div>
  }
  @if (newClientForm) {
    <form class="c-tsp-mat-form c-new-client-dialog" [formGroup]="newClientForm" novalidate>
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-client.form.first-name.label</mat-label>
          <input
            matInput
            formControlName="firstName"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.first-name.placeholder' | translate"
          />
          @if (controlHasErrorType('firstName', 'required')) {
            <mat-error>
              <span
                [innerHTML]="'add-new-entity.new-client.form.first-name.required' | translate"
              ></span>
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-client.form.last-name.label</mat-label>
          <input
            matInput
            formControlName="lastName"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.last-name.placeholder' | translate"
          />
          @if (controlHasErrorType('lastName', 'required')) {
            <mat-error>
              <span
                [innerHTML]="'add-new-entity.new-client.form.last-name.required' | translate"
              ></span>
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-client.form.company-name.label</mat-label>
          <input
            matInput
            formControlName="clientCompanyName"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.company-name.placeholder' | translate"
          />
        </mat-form-field>
      </div>
      <div class="c-tsp-mat-form-field__row">
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-client.form.email.label</mat-label>
          <input
            matInput
            formControlName="email"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.email.placeholder' | translate"
            appLimitInput
            [regExp]="regExp"
          />
          @if (controlHasErrorType('email', 'emailMatch')) {
            <mat-error translate> add-new-entity.new-client.form.email.incorrect </mat-error>
          }
        </mat-form-field>
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-client.form.skype.label</mat-label>
          <input
            matInput
            formControlName="skype"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.skype.placeholder' | translate"
          />
        </mat-form-field>
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-client.form.telephone.label</mat-label>
          <input
            matInput
            formControlName="telephone"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.telephone.placeholder' | translate"
          />
          <mat-error>
            <span [innerHTML]="'add-new-entity.new-client.form.telephone.error' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="c-tsp-mat-form-field__row">
        <div
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-popup-multiple-select"
        >
          <label class="c-tsp-popup-multiple-select__label" translate
            >add-new-entity.new-client.form.city.label</label
          >
          <input formControlName="city" class="d-none" />
          <app-google-autocomplete
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-client.form.city.placeholder' | translate"
            [cities]="dictionaries?.cities"
            [countries]="dictionaries?.countries"
            [selectedCity]="selectedCity"
            (onSelect)="updateCity($event)"
          >
          </app-google-autocomplete>
        </div>
      </div>
      <div class="c-tsp-mat-form-field__row">
        <div
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-tsp-popup-multiple-select"
        >
          <mat-checkbox
            class="c-tsp-mat-checkbox c-new-client-dialog__checkbox"
            formControlName="createUser"
          >
            <span translate>add-new-entity.new-client.form.create-user.label</span>
          </mat-checkbox>
          @if (accountStatus) {
            <span class="c-new-client-dialog__checkbox-hint" translate>
              add-new-entity.new-client.form.create-user.existed-message
            </span>
          }
        </div>
      </div>
      @if ((clientCustomFields$ | async) && hasAccessToCustomFields$ | async) {
        <div class="c-tsp-mat-form-field__row">
          <app-custom-fields-group
            class="c-new-client-dialog__group"
            [fieldControls]="clientCustomFields$ | async"
            formControlName="customFields"
          >
          </app-custom-fields-group>
        </div>
      }
    </form>
  }
</mat-dialog-content>
<mat-dialog-actions class="c-tsp-mat-popup-form c-new-client-dialog__actions">
  <div class="c-tsp-mat-popup-form__item-wrapper">
    @if (data.mode === 'EDIT') {
      <button
        mat-button
        class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
        (click)="openDeletePopup()"
      >
        <span translate>add-new-entity.new-client.delete.button</span>
      </button>
    }
  </div>
  <div>
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--close"
      (click)="openClosePopup()"
    >
      <span translate>add-new-entity.new-client.close.button</span>
    </button>
    <button
      mat-button
      class="c-tsp-mat-popup-form__button c-tsp-mat-popup-form__button--create"
      [disabled]="newClientForm?.invalid"
      (click)="submit()"
    >
      <span translate>add-new-entity.new-client.save.button</span>
    </button>
  </div>
</mat-dialog-actions>
