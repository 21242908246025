import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BreezyCompany,
  DefaultNotificationRule,
  EntitiesList,
  ExternalCredentials,
  Locale,
  Locales,
  PaginationParams,
  RecruiterInfo,
  RecruiterNotificationRule,
  RecruiterNotificationRulePayload,
  RecruiterNotificationsResponse,
  RecruiterPayload,
  SearchParams,
} from '@app-shared/models';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({ providedIn: 'root' })
export class RecruiterService {
  constructor(private readonly http: HttpClient) {}

  public getAvailableLocales(): Observable<Locale[]> {
    return this.http.get<Locale[]>('/user/locales');
  }

  public getAllNotificationRules(): Observable<DefaultNotificationRule[]> {
    return this.http.get<DefaultNotificationRule[]>(`/internal-notifications/rules`);
  }
  public getRecruiterNotificationRules(
    recruiterId: number,
    paginationParams: PaginationParams,
  ): Observable<RecruiterNotificationsResponse> {
    const params = { page: paginationParams.pageNumber, size: paginationParams.pageSize };
    return this.http.get<RecruiterNotificationsResponse>(
      `/internal-notifications/recruiter-rules/${recruiterId}`,
      {
        params: HttpHelperService.serializeParams(params),
      },
    );
  }

  public deleteRecruiterNotificationRule(
    ruleId: number,
  ): Observable<RecruiterNotificationsResponse> {
    return this.http.delete<RecruiterNotificationsResponse>(
      `/internal-notifications/recruiter-rules/${ruleId}`,
    );
  }

  public updateRecruiterNotificationRule(
    rule: RecruiterNotificationRule,
  ): Observable<RecruiterNotificationRule> {
    return this.http.put<RecruiterNotificationRule>(
      `/internal-notifications/recruiter-rules/${rule.id}`,
      rule,
    );
  }

  public createRecruiterNotificationRule(
    payload: RecruiterNotificationRulePayload,
  ): Observable<RecruiterNotificationRule> {
    return this.http.post<RecruiterNotificationRule>(
      `/internal-notifications/recruiter-rules`,
      payload,
    );
  }

  public updateUserLocale(locale: number): Observable<{ locale: Locales }> {
    return this.http.post<{ locale: Locales }>('/change-localization', { locale });
  }
  public getRecruiters(
    params: PaginationParams & SearchParams,
  ): Observable<EntitiesList<RecruiterInfo> & { admin: RecruiterInfo }> {
    return this.http.get<EntitiesList<RecruiterInfo> & { admin: RecruiterInfo }>(`/teammates`, {
      params: HttpHelperService.serializeParams(params),
    });
  }
  public getRecruiterById(id: number): Observable<RecruiterInfo> {
    return this.http.get<RecruiterInfo>(`/teammates/${id}`);
  }
  public createRecruiter(data: Partial<RecruiterPayload>): Observable<RecruiterInfo> {
    return this.http.post<RecruiterInfo>(`/teammates`, data);
  }
  public deleteRecruiter(id: number): Observable<Record<string, unknown>> {
    return this.http.delete<Record<string, unknown>>(`/teammates/${id}`);
  }
  public updateRecruiter(data: Partial<RecruiterPayload>): Observable<RecruiterInfo> {
    return this.http.put<RecruiterInfo>(`/teammates`, data);
  }
  public getBreezyCompanies(payload: ExternalCredentials): Observable<BreezyCompany[]> {
    return this.http.post<BreezyCompany[]>(
      `/user/external-credentials/check-breezy-companies`,
      payload,
    );
  }
  public getExternalCredentials(): Observable<ExternalCredentials> {
    return this.http.get<ExternalCredentials>(`/user/external-credentials`);
  }
  public saveExternalCredentials(data: ExternalCredentials): Observable<ExternalCredentials> {
    return this.http.post<ExternalCredentials>(`/user/external-credentials`, data);
  }
  public updateExternalCredentials(data: ExternalCredentials): Observable<ExternalCredentials> {
    return this.http.put<ExternalCredentials>(`/user/external-credentials`, data);
  }
}
