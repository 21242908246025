/* eslint-disable @typescript-eslint/member-ordering */
import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { environment } from '@app-environment/environment';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { ScriptsActions } from '@app-shared/actions/scripts-injection.actions';
import { State } from '@app-shared/reducers';
import { appVersion } from '@app-shared/reducers/app-feature/app-feature.reducer';
import { ScriptsInjectionService } from '@app-shared/services/scripts-injection/scripts-injection.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ScriptInjectionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<State>,
    private readonly scriptsInjectionService: ScriptsInjectionService,
    private readonly clipboard: Clipboard,
  ) {}

  public copyTextToClipboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScriptsActions.CopyTextToClipboard),
      map(({ value }) => {
        const isSuccess = this.clipboard.copy(value);
        return isSuccess
          ? InternalNotificationActions.SuccessNotificationAction({
              message: 'shared.copier.message.success',
            })
          : InternalNotificationActions.ErrorNotificationAction({
              message: 'shared.copier.message.error',
            });
      }),
    ),
  );

  public initDatadog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScriptsActions.InitDatadog),
        filter(() => !!environment.datadog.applicationId),
        withLatestFrom(this.store$),
        map(([, state]) => appVersion(state)),
        tap((version) => this.scriptsInjectionService.initDatadogMonitor(version)),
      ),
    { dispatch: false },
  );

  public injectGoogleMaps$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScriptsActions.InjectGoogleMaps),
        tap(() => this.scriptsInjectionService.injectGoogleMaps()),
      ),
    { dispatch: false },
  );

  public setDatadogUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ScriptsActions.SetDatadogUserSession),
        tap(({ user }) => this.scriptsInjectionService.setDatadogUserSession(user)),
      ),
    { dispatch: false },
  );
}
