import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import {
  EnvironmentFeaturesConfiguration,
  NotificationChannel,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import {
  always,
  append,
  identity,
  includes,
  isEmpty,
  memoizeWith,
  mergeRight,
  not,
  pipe,
  propOr,
  propSatisfies,
  toString,
  when,
} from 'ramda';

export interface AppFeaturesState {
  features: EnvironmentFeaturesConfiguration;
  version: string;
}

export const initialState: AppFeaturesState = {
  features: {
    allowPushNotifications: [],
    dashboardVideos: null,
    portalsAllowedForLinkedInMessaging: [],
    shortInsideInformationInProfileCard: [],
    extensionConfig: null,
    showTrelloButton: true,
    useNodeForSendingNotifications: false,
    useNewVacanciesFeature: false,
  },
  version: '4.0.0',
};

const reducer = createReducer(
  initialState,
  on(MetadataActions.SetAppVersion, (state, { version }) => mergeRight(state, { version })),
  on(MetadataActions.SetAppFeatures, MetadataActions.SetDefaultAppFeatures, (state, { features }) =>
    mergeRight(state, { features }),
  ),
);

export function appFeatureReducer(state: AppFeaturesState, action: Action) {
  return reducer(state, action);
}

export const selectAppFeatureStore = (state: State) => state.appFeature;

export const featureSettings = createSelector(selectAppFeatureStore, (s) => s.features);

export const isPushNotificationsFeatureAllowed = createSelector(
  featureSettings,
  propSatisfies(pipe(isEmpty, not), 'allowPushNotifications') as UnaryOperator<
    EnvironmentFeaturesConfiguration,
    boolean
  >,
);

export const portalAllowedToShowInsideInfo = memoizeWith(
  pipe(toString, identity),
  (userPortalId: number) =>
    createSelector(featureSettings, (settings): boolean =>
      pipe(propOr([], 'shortInsideInformationInProfileCard'), includes(userPortalId))(settings),
    ),
);

export const allowedCommunicationChannels = memoizeWith(
  pipe(toString, identity),
  (userPortalId: number) =>
    createSelector(featureSettings, (settings): NotificationChannel[] => {
      const isLinkedinNotificationAllowed = includes(
        userPortalId,
        settings.portalsAllowedForLinkedInMessaging,
      );

      return when(
        always(isLinkedinNotificationAllowed),
        append('linkedin'),
      )(['email']) as NotificationChannel[];
    }),
);

export const isNodeIsPreferableWayForSendingNotifications = createSelector(
  featureSettings,
  (settings) => settings.useNodeForSendingNotifications || false,
);

export const appVersion = createSelector(selectAppFeatureStore, (s) => s.version);
export const getExtensionConfig = createSelector(
  selectAppFeatureStore,
  (s) => s.features.extensionConfig,
);
export const getTrelloButtonState = createSelector(
  selectAppFeatureStore,
  (s) => s.features.showTrelloButton,
);
export const getNewVacanciesFeatureState = createSelector(
  selectAppFeatureStore,
  (s) => s.features.useNewVacanciesFeature,
);
export const getStartDashboardVideos = createSelector(
  selectAppFeatureStore,
  (s) => s.features.dashboardVideos,
);
