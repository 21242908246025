import { genericResolver } from '@app-shared/resolvers';
import { KernelActions } from '../../actions/kernel.actions';
import { KernelDepartmentStructure } from '../../models/kernel.model';
import { getKernelOrganizationStructure } from '../../selectors/kernel.selectors';

export const kernelOrganizationStructureResolver = genericResolver<KernelDepartmentStructure[]>(
  getKernelOrganizationStructure,
  KernelActions.RequestKernelOrganizationStructureFromResolverAction(),
  true,
);
