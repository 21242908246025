import { Pipe, PipeTransform } from '@angular/core';
import { Skill, UnaryOperator } from '@app-shared/models';
import {
  ascend,
  descend,
  filter,
  isEmpty,
  map,
  mergeRight,
  not,
  partition,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  reject,
  sortWith,
  unless,
} from 'ramda';

@Pipe({
  name: 'skillsSort',
  standalone: true,
})
export class SkillsSortPipe implements PipeTransform {
  public transform(
    skills: Skill[],
    skillsFromSearch: number[] = [],
    showMainSkill = false,
  ): Skill[] {
    const isSelectedSkills: (skill: Skill) => boolean = pipe(
      propSatisfies((id: number) => skillsFromSearch.includes(id), 'id'),
    );

    const sortSkills: (skills: Skill[]) => Skill[] = sortWith([
      descend(propOr(0, 'weight')),
      ascend(prop('name')),
    ]);

    let mainSkill: Skill[] = showMainSkill
      ? filter(propEq(true, 'main') as UnaryOperator<Skill, boolean>, skills)
      : [];

    const [selectedSkills, simpleSkills] = pipe(
      unless(
        () => isEmpty(mainSkill),
        reject(propEq(true, 'main')) as UnaryOperator<Skill[], Skill[]>,
      ),
      partition(isSelectedSkills) as UnaryOperator<Skill[], [Skill[], Skill[]]>,
      map(sortSkills) as UnaryOperator<Skill[][], Skill[][]>,
    )(skills);

    mainSkill = map(
      unless(pipe(isSelectedSkills, not), mergeRight({ isHighlighted: true })),
      mainSkill,
    ) as Skill[];

    const highlightedSkills = map(mergeRight({ isHighlighted: true }), selectedSkills);

    return [...mainSkill, ...highlightedSkills, ...simpleSkills] as Skill[];
  }
}
