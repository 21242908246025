import { DirectoriesSystemActions } from '@app-core/directories/actions/directories.system.actions';
import { DirectoriesUserActions } from '@app-core/directories/actions/directories.user.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import {
  AllDirectories,
  DictionariesEnum,
  DictionaryEditableItem,
  DirectoryFieldType,
  DirectoryQueryParams,
} from '@app-shared/models';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export interface DirectoriesState extends AllDirectories {
  currentDirectory: DictionariesEnum | null;
  directoryQuery: DirectoryQueryParams;
  isLoading: boolean;
  items: DictionaryEditableItem[] | DirectoryFieldType[];
}

export const initialState: DirectoriesState = {
  currentDirectory: null,
  directoryQuery: {
    showEntities: true,
  },
  isLoading: false,
  items: [],
};

const reducer = createReducer(
  initialState,
  on(
    MetadataActions.CleanReducers,
    DirectoriesSystemActions.CleanDirectoriesAction,
    () => initialState,
  ),
  on(DirectoriesSystemActions.RequestDirectoryAction, (state, { name, query }) => ({
    ...state,
    currentDirectory: name,
    isLoading: true,
    directoryQuery: query,
  })),
  on(DirectoriesSystemActions.RequestFieldTypesDirectoryAction, (state, { name }) => ({
    ...state,
    currentDirectory: name,
    isLoading: true,
  })),
  on(
    DirectoriesSystemActions.RequestDirectoryFailedAction,
    DirectoriesSystemActions.UpdateCandidateStagesOrderFailedAction,
    DirectoriesSystemActions.DeleteDirectoryItemFailedAction,
    (state) => ({ ...state, isLoading: false }),
  ),
  on(
    DirectoriesSystemActions.RequestDirectorySuccessAction,
    DirectoriesSystemActions.RequestFieldTypesDirectorySuccessAction,
    (state, { items }) => ({ ...state, items, isLoading: false }),
  ),
  on(
    DirectoriesUserActions.DeleteDirectoryItemAction,
    DirectoriesUserActions.UpdateCandidateStagesOrderAction,
    (state) => ({ ...state, isLoading: true }),
  ),
  on(DirectoriesSystemActions.UpdateCandidateStagesOrderSuccessAction, (state, { directory }) => ({
    ...state,
    isLoading: false,
    'bid-statuses': directory,
  })),
  on(DirectoriesUserActions.FilterCitiesDirectoryAction, (state, { countryId, regionId }) => {
    return {
      ...state,
      isLoading: true,
      directoryQuery: { ...state.directoryQuery, countryId, regionId },
    };
  }),
);

export function directoriesReducer(state: DirectoriesState, action: Action) {
  return reducer(state, action);
}

export const selectDirectoryStore = createFeatureSelector<DirectoriesState>('directories');
export const getDirectoryName = createSelector(
  selectDirectoryStore,
  (store) => store?.currentDirectory,
);
export const isLoading = createSelector(selectDirectoryStore, (store) => store?.isLoading);
export const getDirectory = createSelector(selectDirectoryStore, (store) => store?.items);
export const getDirectoryQueryParams = createSelector(
  selectDirectoryStore,
  (store) => store?.directoryQuery,
);
