import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AllDictionaries,
  AutocompleteGroup,
  CustomForm,
  DictionariesEnum,
  DictionaryCandidateStage,
  DictionaryCity,
  DictionaryDepartment,
  DictionaryEditableItem,
  DirectoryQueryParams,
  SimpleRecruiter,
} from '@app-shared/models';
import { prop } from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  constructor(private readonly http: HttpClient) {}

  public getBunchDictionaries(
    dictionaries: DictionariesEnum[],
  ): Observable<Partial<AllDictionaries>> {
    return this.http.get<Partial<AllDictionaries>>(`/dictionaries`, { params: { dictionaries } });
  }
  public getFormConfigs(): Observable<CustomForm[]> {
    return this.http.get<CustomForm[]>('/dictionaries/form-configurations');
  }
  public getRecruiters(): Observable<SimpleRecruiter[]> {
    return this.http.get<SimpleRecruiter[]>('/dictionaries/recruiters');
  }
  public getDepartments(): Observable<DictionaryDepartment[]> {
    return this.http.get<DictionaryDepartment[]>('/dictionaries/departments');
  }
  public createNewCity(googlePlaceId: string, cityName: string): Observable<DictionaryCity> {
    return this.http.post<DictionaryCity>('/dictionaries/city', {
      googlePlaceId,
      cityName,
    });
  }
  public getAutocompleteItems(
    keyword: string,
    dictionary?: string | string[],
  ): Observable<AutocompleteGroup[]> {
    return this.http.get<AutocompleteGroup[]>('/dictionaries/matches', {
      params: HttpHelperService.serializeParams({ keyword, dictionary }),
    });
  }

  public getVacanciesAddressList(): Observable<string[]> {
    return this.http
      .get<{ address: string[] }>('/dictionaries/address-list')
      .pipe(map(prop('address')));
  }

  public getDictionaryItems(
    name: DictionariesEnum,
    query: DirectoryQueryParams = {},
  ): Observable<Partial<DictionaryEditableItem>[]> {
    return this.http.get<Partial<DictionaryEditableItem>[]>(`/dictionaries/${name}`, {
      params: HttpHelperService.serializeParams(query),
    });
  }

  public getDictionaryById(id: number, name: DictionariesEnum): Observable<DictionaryEditableItem> {
    return this.http.get<DictionaryEditableItem>(`/dictionaries/${name}/${id}`);
  }

  public saveNewDictionaryItem(
    data: Partial<DictionaryEditableItem>,
    name: DictionariesEnum,
  ): Observable<DictionaryEditableItem> {
    return this.http.post<DictionaryEditableItem>(`/dictionaries/${name}`, data);
  }

  public updateDictionaryItem(
    data: Partial<DictionaryEditableItem>,
    name: DictionariesEnum,
  ): Observable<DictionaryEditableItem> {
    // Mail-attachments and mail-templates follow proper REST API conventions
    const url = /cities|mail|rejectReasons|bid-statuses/.test(name)
      ? `/dictionaries/${name}/${data.id}`
      : `/dictionaries/${name}`;

    return this.http.put<DictionaryEditableItem>(url, data);
  }
  public deleteDictionaryItem(
    id: number | string,
    name: DictionariesEnum,
  ): Observable<Record<string, unknown>> {
    return this.http.delete<Record<string, unknown>>(`/dictionaries/${name}/${id}`);
  }

  public updateBidStatusesOrder(
    list: Pick<DictionaryCandidateStage, 'id' | 'position'>[],
  ): Observable<DictionaryCandidateStage[]> {
    return this.http.put<DictionaryCandidateStage[]>(`/dictionaries/bid-statuses/position`, list);
  }

  public replaceDictionaryItem(
    existedItemId: number,
    newItemId: number,
    name: DictionariesEnum,
  ): Observable<{ updatedCount: number }> {
    return this.http.post<{ updatedCount: number }>(`/dictionaries/${name}/replace`, {
      existedItemId,
      newItemId,
    });
  }
}
