import { Injectable } from '@angular/core';
import { NotificationType } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { propOr } from 'ramda';

@Injectable({ providedIn: 'root' })
export class InternalNotificationsService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private readonly store$: Store<State>,
  ) {}

  public showNotification(type: NotificationType, message?: { message?: string }, action?: Action) {
    const text: string = propOr('shared.services.notification.default-error', 'message', message);
    let translatedText: string;

    this.translateService.get(text).subscribe((value: string) => (translatedText = value));

    switch (type) {
      case 'success':
        this.toastr.success(translatedText);
        break;
      case 'successWithAction':
        this.toastr.success(translatedText).onTap.subscribe(() => {
          this.store$.dispatch(action);
        });
        break;
      case 'info':
        this.toastr.info(translatedText, '', { timeOut: 3000 });
        break;
      case 'infoWithAction':
        this.toastr.info(translatedText).onTap.subscribe(() => {
          this.store$.dispatch(action);
        });
        break;
      case 'warning':
        this.toastr.warning(translatedText, '', { timeOut: 6000 });
        break;
      case 'error':
        this.toastr.error(translatedText);
        break;
    }
  }
}
