import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { NewEntityPopupMode, TalentFormTabs } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getCurrentPath } from '@app-shared/reducers/router/router.reducer';
import { Store, select } from '@ngrx/store';
import { pathOr, test } from 'ramda';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getPopupState } from '../../reducers/new-entity.reducer';
import { NewClientDialogComponent } from './../new-client-dialog/new-client-dialog.component';
import { NewDirectoryDialogComponent } from './../new-directory-dialog/new-directory-dialog.component';
import { NewRecruiterDialogComponent } from './../new-recruiter-dialog/new-recruiter-dialog.component';
import { NewTalentDialogComponent } from './../new-talent-dialog/new-talent-dialog.component';
import { NewVacancyDialogComponent } from './../new-vacancy-dialog/new-vacancy-dialog.component';

type NewEntityDialog =
  | NewClientDialogComponent
  | NewRecruiterDialogComponent
  | NewTalentDialogComponent
  | NewVacancyDialogComponent
  | NewDirectoryDialogComponent;

@Component({
  selector: 'app-add-new-entity-popup',
  templateUrl: './add-new-entity-popup.component.html',
  styleUrls: ['./add-new-entity-popup.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class AddNewEntityPopupComponent implements OnInit, OnDestroy {
  public currentPath: string;
  private openedDialog: MatDialogRef<NewEntityDialog, unknown>;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private width = '812px';
  private maxWidth = '80vw';
  private timeoutId: ReturnType<typeof setTimeout>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store$: Store<State>,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    const type = this.route.snapshot.data.type as string;
    const mode = this.route.snapshot.parent.data.mode as NewEntityPopupMode;
    const parentParams =
      (this.route.snapshot.parent.params.id as number) ||
      (pathOr(null, ['queryParams', 'id'], this.route.snapshot) as string);
    const template = this.route.snapshot.params.template as TalentFormTabs;

    if (this.dialog.openDialogs.length > 0) {
      this.dialog.openDialogs.forEach((dialog) => dialog.close());
    }

    this.store$
      .pipe(select(getCurrentPath), takeUntil(this.ngUnsubscribe))
      .subscribe((path) => (this.currentPath = path));

    this.store$
      .pipe(
        select(getPopupState),
        filter((state) => this.openedDialog && !state),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.openedDialog.close();
      });

    this.timeoutId = setTimeout(() => {
      this.openDialogOnInit(type, mode, parentParams, template);
    }, 0);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearTimeout(this.timeoutId);
  }

  private openDialogOnInit(
    type: string,
    mode: NewEntityPopupMode,
    parentParams: number | string | undefined,
    template = TalentFormTabs.INITIAL,
  ) {
    let dialog: ComponentType<NewEntityDialog>;

    switch (type) {
      case 'client':
        dialog = NewClientDialogComponent;
        break;
      case 'talent':
        dialog = NewTalentDialogComponent;
        this.maxWidth = '95vw';
        this.width = 'auto';
        break;
      case 'vacancy':
        dialog = NewVacancyDialogComponent;
        this.width = '1100px';
        break;
      case 'recruiter':
        dialog = NewRecruiterDialogComponent;
        break;
      case 'custom-field':
      case 'directory':
        dialog = NewDirectoryDialogComponent;
        this.width = test(/mail|templates/gi, this.currentPath) ? '95vw' : '580px';
        break;
    }

    this.openedDialog = this.dialog.open(dialog, {
      data: { mode, parentParams, template },
      panelClass: 'c-tsp-mat-dialog-container',
      width: this.width,
      maxHeight: '98vh',
      maxWidth: this.maxWidth,
      disableClose: true,
    });

    this.openedDialog
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.cleanOutletNavigation();
      });

    this.openedDialog
      .backdropClick()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.openClosePopup();
      });
  }

  private openClosePopup() {
    if (this.openedDialog.componentInstance.isPristine()) {
      this.openedDialog.close();
    } else {
      this.store$.dispatch(RootSystemActions.OpenConfirmationDialogForClosePopupAction());
    }
  }

  private cleanOutletNavigation() {
    this.store$.dispatch(RootSystemActions.CloseConfirmationPopupAction());
  }
}
