import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { getUserLocalization } from '@app-core/root/reducer/root.reducer';
import { State } from '@app-shared/reducers';
import { getAuthState } from '@app-shared/reducers/auth/auth.reducer';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const notLoggedGuard: CanActivateFn = (): Observable<UrlTree | boolean> => {
  const store$: Store<State> = inject(Store<State>);
  const router: Router = inject(Router);

  return store$.pipe(
    select(getAuthState),
    switchMap((authState: boolean) => {
      if (!authState) {
        return of(true);
      }
      return store$.pipe(
        select(getUserLocalization),
        map((locale) => locale || 'uk'),
        map((locale) => router.parseUrl(`${locale}`)),
      );
    }),
  );
};
