@if (entityForm) {
  <form class="c-tsp-mat-form c-new-mail-template-dialog" [formGroup]="entityForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.name.label</mat-label>
        <input
          matInput
          formControlName="name"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.name.placeholder' | translate"
        />
        <mat-error>
          <span [innerHTML]="'add-new-entity.new-directory.form.name.required' | translate"></span>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.email-type.label</mat-label>
        <mat-select
          [placeholder]="'add-new-entity.new-directory.form.email-type.placeholder' | translate"
          formControlName="type"
          [required]="true"
        >
          @for (type of notificationTemplatesTypes; track type) {
            <mat-option class="c-tsp-mat-form-field-option" [value]="type">
              {{ 'add-new-entity.new-directory.mail-template-type.' + type | translate }}
            </mat-option>
          }
        </mat-select>
        <mat-error>
          <span
            [innerHTML]="'add-new-entity.new-directory.form.email-type.required' | translate"
          ></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.subject</mat-label>
        <input
          matInput
          formControlName="subject"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.subject' | translate"
        />
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row c-tsp-popup__description">
      <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
        <label class="c-tsp-popup-multiple-select__label" translate>
          add-new-entity.new-directory.form.body.label
        </label>
        <app-rich-editor
          [locale]="locale"
          [predefinedContent]="predefinedContent"
          [formControl]="entityForm | getFormControl: 'body'"
        >
        </app-rich-editor>
        <div class="c-new-mail-template-dialog__example-row">
          @for (variable of bodyVariables; track variable) {
            <button
              mat-button
              class="c-new-mail-template-dialog__example"
              (click)="addExampleToEditor(variable, true)"
            >
              {{ variable }}
            </button>
          }
        </div>
        @if (controlHasErrorType('body', 'required')) {
          <mat-error class="c-new-mail-template-dialog__error-text">
            <span
              [innerHTML]="'add-new-entity.new-directory.form.body.required' | translate"
            ></span>
          </mat-error>
        }
      </div>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <mat-checkbox
        class="c-tsp-mat-form-field c-tsp-mat-checkbox c-new-mail-template-dialog__checkbox"
        formControlName="convertToPdf"
        (change)="toggleFilenameControl($event)"
      >
        <span translate>add-new-entity.new-directory.form.convert-to-pdf.label</span>
      </mat-checkbox>
      @if (entityForm.get('convertToPdf').value) {
        <mat-form-field
          class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
          floatLabel="always"
        >
          <mat-label translate>add-new-entity.new-directory.form.filename.label</mat-label>
          <input
            matInput
            formControlName="filename"
            class="c-tsp-mat-form-field-input"
            [placeholder]="'add-new-entity.new-directory.form.filename.placeholder' | translate"
          />
          <mat-error>
            <span
              [innerHTML]="'add-new-entity.new-directory.form.filename.required' | translate"
            ></span>
          </mat-error>
        </mat-form-field>
      }
    </div>
    @if (entityForm.get('convertToPdf').value) {
      <div class="c-tsp-mat-form-field__row c-tsp-popup__description">
        <div class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label">
          <label class="c-tsp-popup-multiple-select__label" translate>
            add-new-entity.new-directory.form.body-secondary.label
          </label>
          <app-rich-editor
            [locale]="locale"
            [predefinedContent]="predefinedSecondaryContent"
            [formControl]="entityForm | getFormControl: 'bodySecondary'"
          >
          </app-rich-editor>
        </div>
      </div>
      <div class="c-new-mail-template-dialog__example-row">
        @for (variable of bodyVariables; track variable) {
          <button
            mat-button
            class="c-new-mail-template-dialog__example"
            (click)="addExampleToEditor(variable, false)"
          >
            {{ variable }}
          </button>
        }
      </div>
    }
    <div class="c-tsp-mat-form-field__row">
      <label
        class="c-tsp-popup-multiple-select__label c-new-mail-template-dialog__attachments-label"
        translate
        >add-new-entity.new-directory.form.attachments.selected</label
      >
      <div class="c-new-mail-template-dialog__attachments">
        @for (attachment of selectedAttachments; track attachment) {
          <div class="c-new-mail-template-dialog__attachments-tile">
            <span class="c-new-mail-template-dialog__attachments-name">
              {{ attachment.name }}
            </span>
            <button
              class="c-new-mail-template-dialog__attachments-tile-remove"
              mat-icon-button
              (click)="removeAttachment(attachment.id)"
            >
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
          </div>
        }
      </div>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <app-multiple-select-search
        class="c-new-mail-template-dialog__attachments-selector"
        label="add-new-entity.new-directory.form.attachments.label"
        type="attachments"
        placeholder="add-new-entity.new-directory.form.attachments.placeholder"
        placeholderLabel="add-new-entity.new-directory.form.attachments.label"
        [control]="entityForm | getFormControl: 'attachments'"
        [options]="attachments"
      >
      </app-multiple-select-search>
    </div>
  </form>
}
