import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelperService } from '@app-shared/services/http-helper/http-helper.service';
import {
  KernelDepartmentAssignment,
  KernelDepartmentStructure,
  KernelEmployee,
} from '../models/kernel.model';

@Injectable({
  providedIn: 'root',
})
export class KernelService {
  constructor(private readonly http: HttpClient) {}

  public getKernelOrganizationStructure(): Observable<KernelDepartmentStructure[]> {
    return this.http.get<KernelDepartmentStructure[]>(`/dictionaries/departments/structure`);
  }
  public getRelatedDepartment(
    vacancyId: number,
  ): Observable<{ departmentId: string; email: string }> {
    return this.http.get<{ departmentId: string; email: string }>(
      '/custom/kernel/related-department',
      { params: HttpHelperService.serializeParams({ vacancyId }) },
    );
  }
  public getManagers(subdivisionId = '', keyword = ''): Observable<KernelEmployee[]> {
    return this.http.get<KernelEmployee[]>('/custom/kernel/managers', {
      params: {
        keyword,
        subdivisionId,
      },
    });
  }
  public saveDepartmentAssignment(
    payload: KernelDepartmentAssignment,
  ): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>('/custom/kernel/assignment', payload);
  }
}
