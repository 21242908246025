<div
  class="c-profiles-list"
  [ngClass]="{
    'c-profiles-list--extended-control-bar': activeTab === 'vacancy',
    'c-profiles-list--without-pagination': !showPagination,
  }"
>
  @if (activeTab !== 'external-profiles') {
    <app-profiles-list-control-bar
      class="c-profiles-list__control-bar"
      [ngClass]="{ 'c-profiles-list__control-bar--simple': isKanban }"
      [sortingEnabled]="!isKanban"
      [sliderEnabled]="true"
      [sortOptions]="availableSortings"
      [sortParam]="activeSortParams"
      [simpleCardViewState]="simpleCardViewState"
      (onSortingChanged)="updateSorting.emit($event)"
      (onChangeSimpleCardViewState)="changeSimpleCardViewState.emit($event)"
    >
      @if (allowSelection) {
        <button
          [matTooltip]="
            activeTab === 'vacancy'
              ? ('vacancy-profiles.profiles-list.select-all.tooltip' | translate)
              : ''
          "
          class="btn c-profiles-list-control-bar__button c-profiles-list-control-bar__button--select-all"
          [ngClass]="{
            'c-profiles-list-control-bar__button--activated': selectAllState !== 'none',
          }"
          [disabled]="(loadingProfilesIds$ | async) || !totalCount"
          (click)="toggleSelectAll()"
        >
          <span class="c-profiles-list__control-bar-button-locker"></span>
          <mat-checkbox
            class="c-tsp-mat-checkbox"
            disabled
            [checked]="selectAllState === 'all'"
            [indeterminate]="selectAllState === 'partial'"
          >
          </mat-checkbox>
          <span class="c-profiles-list__control-bar-button-title">
            {{
              'vacancy-profiles.profiles-list.select-' + (allowedToSelectAll ? 'all' : 'part')
                | translate
            }}
          </span>
        </button>
      }
      <div class="c-profiles-list-control-bar__filters">
        <ng-content select="div.c-profiles-list__control-bar"></ng-content>
      </div>
    </app-profiles-list-control-bar>
  }
  <ng-content select="div.c-vacancy-profile-list__tutorial"></ng-content>

  <div class="c-profiles-list__list">
    @if (profiles?.length > 0) {
      <div class="c-profiles-list__list-container">
        @if (isKanban && isDesktopDevice) {
          @if (shortListStatuses) {
            <div class="c-profiles-list__drop-zone">
              <div class="c-profiles-list__drop-zone-wrapper" cdkDropListGroup>
                <div
                  class="c-profiles-list__drop-zone-empty"
                  [ngClass]="{ 'c-profiles-list__drop-zone-empty--visible': showEmptyStatuses }"
                >
                  @for (status of shortListStatuses; track trackById($index, status)) {
                    @if (
                      getStatusProfiles(status.id).length === 0 &&
                      !isStatusNotSelectable(status.code)
                    ) {
                      <div class="c-profiles-list__drop-zone-item" [attr.value]="status.id">
                        <div
                          class="c-profiles-list__drop-zone-container"
                          cdkDropList
                          [cdkDropListData]="getStatusProfiles(status.id)"
                          (cdkDropListDropped)="dropElement($event)"
                        >
                          <div
                            class="c-profiles-list__drop-zone-title"
                            [ngStyle]="{ 'background-color': status.color }"
                          >
                            {{ status.name }}
                          </div>
                        </div>
                      </div>
                    }
                  }
                </div>
                <div class="c-profiles-list__drop-zone-main">
                  @for (status of shortListStatuses; track trackById($index, status)) {
                    @if (getStatusProfiles(status.id).length > 0) {
                      <div class="c-profiles-list__drop-zone-item" [attr.value]="status.id">
                        <div
                          class="c-profiles-list__drop-zone-container"
                          cdkDropList
                          [cdkDropListDisabled]="(userIsRecruiterOrAdmin$ | async) === false"
                          [cdkDropListData]="getStatusProfiles(status.id)"
                          (cdkDropListDropped)="dropElement($event)"
                        >
                          <div
                            class="c-profiles-list__drop-zone-title"
                            [ngStyle]="{ 'background-color': status.color }"
                            (keydown)="selectBidStatus.emit(status.id)"
                            (click)="selectBidStatus.emit(status.id)"
                          >
                            {{ status.name }}
                          </div>
                          <cdk-virtual-scroll-viewport
                            appendOnly
                            autosize
                            class="c-profiles-list__list-virtual-scroll"
                          >
                            <div
                              *cdkVirtualFor="
                                let profile of getStatusProfiles(status.id);
                                templateCacheSize: 0;
                                trackBy: trackById
                              "
                              class="c-profiles-list__drop-zone-card"
                              cdkDrag
                              [cdkDragData]="profile"
                              (cdkDragStarted)="showEmptyStatuses = true"
                              (cdkDragEnded)="showEmptyStatuses = false"
                            >
                              <profile-preview
                                class="c-profiles-list__card c-profiles-list__card-vertical"
                                [activeTab]="activeTab"
                                [profileData]="profile"
                                [dictionaries]="dictionaries"
                                [activeSkillsFilters]="activeSkillsFilters"
                                [hasAccessToContacts]="
                                  hasAccessToContactDetails || hasAccessToSocialLinks
                                "
                                [hasAccessToCustomFields]="hasAccessToCustomFields"
                                [hasAccessToStatusCustomFields]="hasAccessToStatusCustomFields"
                                [hasAccessToSecondName]="hasAccessToSecondName"
                                [isMobileDevice]="isMobileDevice"
                                [isProfileSelected]="isProfileSelected(profile.id)"
                                [isProfileLoading]="isProfilesLoading"
                                [showTrelloButton]="showTrelloButton$ | async"
                                [simpleCardViewState]="simpleCardViewState"
                                [portalAllowedToShowInsideInfo]="
                                  portalAllowedToShowInsideInfo$ | async
                                "
                                [showApplies]="activeTab === 'vacancy' || !!selectedVacancyId"
                                [statusesCustomFields]="statusesCustomFields"
                                [vacancyId]="selectedVacancyId"
                                [userLocale]="userLocale"
                                [userIsRecruiterOrAdmin]="userIsRecruiterOrAdmin$ | async"
                                (addToList)="onAddToList($event, profile)"
                                (editProfile)="onEditProfile(profile, $event)"
                                (openCalendar)="onOpenCalendarFromShortList($event, profile)"
                                (openContacts)="onOpenContact($event, profile)"
                                (onOpenNotificationModal)="openNotificationModal.emit($event)"
                                (openProfile)="onOpenProfilePage(profile)"
                                (openSidepanel)="onOpenSidepanel($event, profile)"
                                (removeFromShortList)="onRemoveFromShortList($event, profile)"
                                (resendShortlistForSecurityCheck)="
                                  resendShortlistForSecurityCheck.emit($event)
                                "
                                (toggleSelection)="onToggleProfileSelection($event, profile.id)"
                                (trelloClick)="onTrelloClick(profile)"
                                (updateShortList)="onUpdateShortList($event)"
                              >
                              </profile-preview>
                            </div>
                          </cdk-virtual-scroll-viewport>
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
            </div>
          }
        } @else {
          <!-- Such type casting is exception to avoid creating unnecessary pipes -->
          @for (profile of $any(profiles); track trackById($index, profile)) {
            <profile-preview
              class="c-profiles-list__card"
              [activeTab]="activeTab"
              [profileData]="profile"
              [dictionaries]="dictionaries"
              [activeSkillsFilters]="activeSkillsFilters"
              [hasAccessToContacts]="hasAccessToContactDetails || hasAccessToSocialLinks"
              [hasAccessToCustomFields]="hasAccessToCustomFields"
              [hasAccessToSalary]="hasAccessToSalary"
              [hasAccessToStatusCustomFields]="hasAccessToStatusCustomFields"
              [hasAccessToProfileComments]="hasAccessToProfileComments"
              [hasAccessToSecondName]="hasAccessToSecondName"
              [isActiveSearchParams]="isActiveSearchParams"
              [isMobileDevice]="isMobileDevice"
              [isProfileSelected]="isProfileSelected(profile.id)"
              [isProfileLoading]="isProfilesLoading"
              [portalAllowedToShowInsideInfo]="portalAllowedToShowInsideInfo$ | async"
              [searchParamsForHighlight]="searchParamsForHighlight"
              [showTrelloButton]="showTrelloButton$ | async"
              [simpleCardViewState]="simpleCardViewState"
              [showApplies]="activeTab === 'vacancy' || !!selectedVacancyId"
              [statusesCustomFields]="statusesCustomFields"
              [vacancyId]="selectedVacancyId"
              [userLocale]="userLocale"
              [userIsRecruiterOrAdmin]="userIsRecruiterOrAdmin$ | async"
              (addToCompany)="addToCompany.emit($event)"
              (addToList)="onAddToList($event, profile)"
              (addToListById)="addToListById.emit($event)"
              (linkSuggestion)="linkSuggestion.emit($event)"
              (matchProfileData)="matchProfileData($event)"
              (searchMatchedCandidates)="searchMatchedCandidates.emit($event)"
              (editProfile)="onEditProfile(profile, $event)"
              (openCalendar)="onOpenCalendarFromShortList($event, profile)"
              (openContacts)="onOpenContact($event, profile)"
              (onOpenNotificationModal)="openNotificationModal.emit($event)"
              (mergeDuplicates)="mergeDuplicates.emit({ primary: profile.id, duplicates: $event })"
              (openProfile)="onOpenProfilePage(profile)"
              (openSidepanel)="onOpenSidepanel($event, profile)"
              (pasteKeywordToSearch)="pasteKeywordToSearch.emit($event)"
              (removeFromShortList)="onRemoveFromShortList($event, profile)"
              (resendShortlistForSecurityCheck)="resendShortlistForSecurityCheck.emit($event)"
              (toggleSelection)="onToggleProfileSelection($event, profile.id)"
              (trelloClick)="onTrelloClick(profile)"
              (updateShortList)="onUpdateShortList($event)"
            >
            </profile-preview>
          }
        }
        <ng-content select="div.c-profiles-list__banners"></ng-content>
      </div>
    }
    @if (isProfilesLoading) {
      <div class="c-profiles-list__spinner">
        <mat-spinner class="c-tsp-popup__spinner"></mat-spinner>
        {{ 'search-console.profiles-list.spinner-text' | translate }}
      </div>
    }
    <ng-content select="div.c-profiles-list__banners"></ng-content>

    @if (profiles?.length > 0 && showPagination && !isKanban) {
      <mat-paginator
        class="c-profiles-list__paginator"
        [length]="totalCount"
        [pageSize]="paginationParams?.pageSize"
        [pageIndex]="paginationParams?.pageNumber - 1"
        [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"
        [showFirstLastButtons]="true"
        (page)="onPaginationChanged($event)"
      >
      </mat-paginator>
    }
  </div>
</div>
