import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  isLoading as DataLoading,
  getClientDetails,
} from '@app-core/add-new-entity-popup/reducers/new-entity.reducer';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { isUserOptionAllowed } from '@app-core/root/reducer/root.reducer';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import {
  AllDictionaries,
  ClientFull,
  ClientPayload,
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  NewClientForm,
  NewEntityPopupMode,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCustomFields,
  getDictionaryLoadingState,
  selectDictionaryStore,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { emailMatchValidator, phoneValidator } from '@app-shared/validators';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@tsp-components/confirmation-dialog';
import { SpinnerComponent } from '@tsp-components/spinner';
import { LimitInputDirective } from '@tsp-directives';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  anyPass,
  equals,
  isEmpty,
  isNil,
  mergeRight,
  pathOr,
  propOr,
  filter as ramdaFilter,
} from 'ramda';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CustomFieldsGroupComponent } from '../../../custom-fields/custom-fields-group/custom-fields-group.component';
import { GoogleAutocompleteComponent } from '../google-autocomplete/google-autocomplete.component';

@Component({
  imports: [
    AsyncPipe,
    CdkScrollable,
    CustomFieldsGroupComponent,
    GoogleAutocompleteComponent,
    LimitInputDirective,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    SpinnerComponent,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-new-client-dialog',
  templateUrl: './new-client-dialog.component.html',
  styleUrls: ['./new-client-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class NewClientDialogComponent implements OnInit, OnDestroy {
  public clientCustomFields$: Observable<DirectoryFieldType[]>;
  public isLoading$?: Observable<boolean>;
  public hasAccessToCustomFields$: Observable<boolean>;
  public dictionaries?: Partial<AllDictionaries>;
  public isFormUnchanged = true;
  public newClientForm?: FormGroup<NewClientForm>;
  public regExp = new RegExp(/\s/g);
  private clientDetails?: ClientFull;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      mode: NewEntityPopupMode;
      parentParams?: number;
    },
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<State>,
    private readonly dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(AddNewEntitySystemActions.OpenClientDialogOnInitAction());

    this.isLoading$ = combineLatest([
      this.store.pipe(select(getDictionaryLoadingState)),
      this.store.pipe(select(DataLoading)),
    ]).pipe(map(([dictionaryLoading, dataLoading]) => dictionaryLoading || dataLoading));
    this.hasAccessToCustomFields$ = this.store.pipe(
      select(isUserOptionAllowed('showClientsCustomFields')),
    );
    this.clientCustomFields$ = this.store.pipe(
      select(getCustomFields('clientCustomFields')),
      filter(isNotNullOrUndefined),
      map((customFields: DirectoryFieldType[]) =>
        ramdaFilter((field) => !isNil(field.showInPanel), customFields),
      ),
      shareReplay(),
    );
    this.store
      .pipe(
        select(selectDictionaryStore),
        filter(isNotNullOrUndefined),
        tap((dictionaries) => {
          this.dictionaries = dictionaries;
          this.initGroup();
        }),
        filter(() => this.data.mode === 'EDIT'),
        tap(() =>
          this.store.dispatch(
            AddNewEntitySystemActions.RequestClientDetailsOnInitAction({
              id: this.data.parentParams,
            }),
          ),
        ),
        switchMap(() => this.store.pipe(select(getClientDetails))),
        filter(isNotNullOrUndefined),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((clientDetails: ClientFull) => {
        this.clientDetails = clientDetails;
        this.updateFormWithData(clientDetails);
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public controlHasErrorType(controlName: string, errorName: string): boolean {
    const control = this.newClientForm.get(controlName);

    if (!control) {
      return false;
    }

    return control.touched && (control.errors ? (control.errors[errorName] as boolean) : false);
  }
  public isPristine(): boolean {
    return this.isFormUnchanged || propOr(true, 'pristine', this.newClientForm);
  }
  public get accountStatus(): boolean {
    return !!this.clientDetails?.userId;
  }
  public get popupTitle(): string {
    return this.data.mode === 'EDIT'
      ? 'add-new-entity.new-client.title.update'
      : 'add-new-entity.new-client.title.create';
  }
  public get selectedCity(): number {
    return this.newClientForm.get('city').value;
  }
  public openClosePopup(): void {
    if (this.isPristine()) {
      this.dialog.closeAll();
    } else {
      this.store.dispatch(RootSystemActions.OpenConfirmationDialogForClosePopupAction());
    }
  }
  public openDeletePopup(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: { text: 'add-new-entity.new-client.confirmation-dialog.delete' },
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.store.dispatch(
            AddNewEntityUserActions.DeleteClientAction({ id: this.clientDetails.id }),
          );
        }
      });
  }
  public submit(): void {
    if (this.newClientForm.invalid) {
      this.checkFormErrors();
      return;
    }

    if (this.data.mode === 'EDIT') {
      const payload = mergeRight(this.formDataForSymfony, {
        id: this.clientDetails.id,
      });
      this.store.dispatch(AddNewEntityUserActions.UpdateClientAction({ client: payload }));
    } else {
      this.store.dispatch(
        AddNewEntityUserActions.CreateNewClientAction({ client: this.formDataForSymfony }),
      );
    }
  }
  public updateCity({ city, country }: { city: number | null; country: number | null }): void {
    this.newClientForm.patchValue({
      city,
      country,
    });
  }

  private checkFormErrors() {
    const controls = this.newClientForm.controls;
    for (const control in controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (controls.hasOwnProperty(control)) {
        const currentControl: FormControl = controls[control];
        if (!isNil(currentControl.validator) && currentControl.untouched) {
          currentControl.markAsTouched();
        }
      }
    }
  }
  private get formDataForSymfony(): Omit<ClientPayload, 'id'> {
    const formValue = this.newClientForm.value;
    return {
      city: anyPass([isNil, isEmpty])(formValue.city) ? null : { id: formValue.city },
      country: anyPass([isNil, isEmpty])(formValue.country) ? null : { id: formValue.country },
      clientType: formValue.clientType || null,
      createUser: formValue.createUser || !!this.clientDetails?.userId || false,
      fieldValues: formValue.customFields,
      email: formValue.email || null,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phone: formValue.telephone || null,
      skype: formValue.skype || null,
      teamName: formValue.clientCompanyName || null,
    };
  }
  private initGroup() {
    this.newClientForm = this.formBuilder.group<NewClientForm>({
      city: new FormControl<number>(null),
      clientCompanyName: new FormControl<string>(null),
      clientType: new FormControl<number>(null),
      createUser: new FormControl<boolean>(false),
      country: new FormControl<number>(null),
      customFields: new FormControl<DirectoryCustomFieldResponse[]>([]),
      email: new FormControl<string>(null, [emailMatchValidator]),
      firstName: new FormControl<string>(null, [Validators.required]),
      lastName: new FormControl<string>(null, [Validators.required]),
      skype: new FormControl<string>(null),
      telephone: new FormControl<string>(null, [phoneValidator]),
    });

    this.newClientForm.valueChanges
      .pipe(
        distinctUntilChanged((prevValue, currValue) => {
          if (!equals(prevValue, currValue) && this.isFormUnchanged) {
            this.isFormUnchanged = false;
          }
          return true;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }
  private updateFormWithData(clientData: ClientFull) {
    this.newClientForm.patchValue({
      city: pathOr<number | null>(null, ['city', 'id'], clientData),
      clientType: clientData.clientType || null,
      clientCompanyName: clientData.teamName || null,
      createUser: !!clientData.userId,
      customFields: clientData.fieldValues || [],
      email: clientData.email || null,
      firstName: clientData.firstName || null,
      lastName: clientData.lastName || null,
      skype: clientData.skype || null,
      telephone: clientData.phone || null,
    });
  }
}
