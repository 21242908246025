<div class="search-select">
  <input
    matInput
    class="search-select-input"
    #searchSelectInput
    (keydown)="handleKeydown($event)"
    (input)="onInputChange($event)"
    (blur)="onBlur($event)"
    [placeholder]="placeholderLabel | translate"
  />
</div>
@if (noEntriesFoundLabel && options?.length === 0) {
  <div class="search-select__not-found">
    {{ noEntriesFoundLabel | translate }}
  </div>
}
