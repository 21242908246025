import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ProfileModificationActions } from '@app-shared/effects/profile-modification/profile-modification.actions';
import { DictionaryItem, FullProfile, Profile } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getTags } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { Store, select } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { ProfileDynamicListComponent } from '@tsp-components/profile-dynamic-list';
import { TagInputComponent } from '@tsp-components/tag-input';
import { includes, isNil, map, pipe, prop, propOr, when } from 'ramda';
import { Observable } from 'rxjs';

type AllowedProfile = Profile | FullProfile;

@Component({
  imports: [
    AsyncPipe,
    MatButton,
    MatIconButton,
    MatTooltip,
    ProfileDynamicListComponent,
    ReactiveFormsModule,
    TagInputComponent,
    TranslatePipe,
  ],
  selector: 'profile-tags',
  templateUrl: './profile-tags.component.html',
  styleUrls: ['./profile-tags.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileTagsComponent {
  @Input()
  public entityData?: AllowedProfile;
  @Input()
  public activeTab?: string;
  @Input()
  public dictionary?: DictionaryItem[];
  @Input()
  public isEditable = true;
  @Input()
  public showDetailedInfo: boolean;
  @Input()
  public showExpandButton = true;
  public tags$?: Observable<DictionaryItem[]>;
  public form?: FormGroup<{ tags: FormControl<number[]> }>;
  public isFormVisible = false;

  constructor(
    private readonly store: Store<State>,
    private readonly formBuilder: FormBuilder,
  ) {}

  public get currentTags(): { tags: number[] } {
    return {
      tags: this.form.get('tags').value || [],
    };
  }
  public get profileTags(): DictionaryItem[] | null {
    return propOr(null, 'tags', this.entityData) as unknown as DictionaryItem[] | null;
  }

  public get tagButtonText(): string {
    return `profiles-common.profile-tags.button.${this.profileTags?.length > 0 ? 'edit' : 'add'}`;
  }

  public get isActiveTab(): boolean {
    return includes(this.activeTab, ['profileSidepanel', 'profilePage']);
  }

  public showForm() {
    this.isFormVisible = true;

    this.tags$ = this.store.pipe(select(getTags));

    if (this.form) {
      this.form.reset({
        tags: this.tagIds,
      });
    } else {
      this.form = this.formBuilder.group({
        tags: [this.tagIds],
      });
    }
  }

  public submitTags() {
    this.isFormVisible = false;
    const formValue: number[] = this.form.get('tags').value;

    if (isNil(formValue)) {
      return;
    }

    const profilePayload: {
      devId: string;
      data: number[];
      currentTab: string;
      actionSource: 'details' | 'sidepanel';
    } = {
      devId: (this.entityData as FullProfile).id,
      data: formValue,
      currentTab: this.activeTab,
      actionSource: this.activeTab === 'profilePage' ? 'details' : 'sidepanel',
    };
    this.store.dispatch(ProfileModificationActions.UpdateTagsAction(profilePayload));
  }
  public updateTags(newTags: { tags?: number[] }) {
    this.form.patchValue(newTags);
  }

  private get tagIds(): number[] {
    return pipe(
      prop('tags'),
      when(isNil, () => []),
      map(prop('id')),
    )(this.entityData) as number[];
  }
}
