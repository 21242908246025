import { ProfileContactRequestSource } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const GetProfileContactsAction = createAction(
  '[Shared Profiles List] Get profile contacts',
  props<{
    id: string;
    source: ProfileContactRequestSource;
    profileName: string;
    clickedContact: string;
  }>(),
);

const ToggleProfileSelectionStateAction = createAction(
  '[Shared Profiles List] Toggle profile card selection state',
  props<{ id: string; toggleState: boolean }>(),
);
const UnselectAllAction = createAction('[Shared Profiles List] Unselect all profiles');

const actions = {
  GetProfileContactsAction,
  ToggleProfileSelectionStateAction,
  UnselectAllAction,
};

const profilesListActions = union(actions);

export type ProfilesListActionsType = typeof profilesListActions;

export const ProfilesListActions = actions;
