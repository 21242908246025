import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { State } from '@app-shared/reducers';
import { MemoizedSelector, Store, select, Action } from '@ngrx/store';
import { isNil } from 'ramda';
import { Observable, filter, take, tap } from 'rxjs';

export function genericResolver<T>(
  selector: MemoizedSelector<State, T>,
  action: Action<string>,
  withFilter = false,
): ResolveFn<T> {
  return (): Observable<T> => {
    const store$: Store<State> = inject(Store<State>);

    return store$.pipe(
      select(selector),
      tap((data) => {
        if (isNil(data)) {
          store$.dispatch(action);
        }
      }),
      filter((data: T) => (withFilter ? !isNil(data) : true)),
      take(1),
    );
  };
}
