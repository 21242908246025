import { DictionariesEnum } from '@app-shared/models';
import { isKernelEnv } from '@app-shared/reducers/router/router.reducer';

export const localClientTypeDictionary = [
  {
    id: 1,
    name: 'B2B',
  },
  {
    id: 2,
    name: 'B2C',
  },
];

export const addNewClientDictionaries = [
  DictionariesEnum.cities,
  DictionariesEnum.clientType,
  DictionariesEnum.countries,
];

export const addNewDirectoryDictionaries: DictionariesEnum[] = [
  DictionariesEnum.countries,
  DictionariesEnum['mail-attachments'],
  DictionariesEnum.regions,
];

export const addNewTalentDictionaries = [
  DictionariesEnum.cities,
  DictionariesEnum.countries,
  DictionariesEnum.currencies,
  DictionariesEnum.englishLevels,
  DictionariesEnum.experiences,
  DictionariesEnum.mainSkills,
  DictionariesEnum.skills,
  DictionariesEnum.softSkills,
  DictionariesEnum.specialities,
];

const defaultAddNewVacancyDictionaries = [
  DictionariesEnum.cities,
  DictionariesEnum.clients,
  DictionariesEnum.countries,
  DictionariesEnum.durations,
  DictionariesEnum.mainSkills,
  DictionariesEnum.skills,
  DictionariesEnum.specialities,
  DictionariesEnum.vacancyStatuses,
  DictionariesEnum.vacancyType,
];
export const addNewVacancyDictionaries = isKernelEnv
  ? [
      ...defaultAddNewVacancyDictionaries,
      DictionariesEnum.businesses,
      DictionariesEnum.securityGroups,
      DictionariesEnum.vacancyCategories,
    ]
  : defaultAddNewVacancyDictionaries;
