import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatDateTime',
  standalone: true,
})
export class FormatDateTimePipe implements PipeTransform {
  private readonly mediumFormat = 'dd.LL.yyyy HH:mm';
  private readonly mediumWithSecFormat = 'dd LLL yyyy, HH:mm:ss';
  private readonly longerFormat = 'ccc dd LLL yyyy, HH:mm:ss';
  private readonly shortFormat = 'dd LLL yyyy';
  private readonly timeOnly = 'HH:mm:ss';
  private readonly localeId: string;

  constructor(translateService: TranslateService) {
    this.localeId = translateService.currentLang;
  }

  public transform(value: string | number, type: string, additionalFormating?: string) {
    if (value) {
      let date: DateTime;
      switch (additionalFormating) {
        case 'fromMills':
          date = DateTime.fromMillis(value as number);
          break;
        case 'fromSeconds':
          date = DateTime.fromSeconds(value as number);
          break;
        default:
          date = DateTime.fromISO(value as string);
      }
      if (date.isValid) {
        switch (type) {
          case 'longer':
            return date.toFormat(this.longerFormat);
          case 'medium':
            return date.toFormat(this.mediumFormat);
          case 'short':
            return date.toFormat(this.shortFormat);
          case 'time':
            return date.toFormat(this.timeOnly);
          case 'days':
            return date.setLocale(this.localeId).toRelativeCalendar({ unit: 'days' });
          case 'relative':
            return date.setLocale(this.localeId).toRelative();
          default:
            return date.toFormat(this.mediumWithSecFormat);
        }
      } else {
        return value;
      }
    }
    return value;
  }
}
