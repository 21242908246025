import { Pipe, PipeTransform } from '@angular/core';
import { includes, propOr } from 'ramda';
import { DictionaryItem, TotalCounts } from '@app-shared/models';

type OptionsTypes = string | string[] | number[] | TotalCounts | DictionaryItem[];

@Pipe({
  name: 'filterOptions',
  standalone: true,
})
export class FilterOptionsPipe implements PipeTransform {
  public transform(value: OptionsTypes, pipeParam?: string, additionalArgs?: string | number) {
    if (pipeParam === 'profilesFilters') {
      return includes(additionalArgs, value as string[]);
    } else if (pipeParam === 'profileCountsVisibility') {
      const tabName = additionalArgs as string;
      const counts = value as TotalCounts;
      return propOr(0, tabName, counts);
    }
    return value;
  }
}
