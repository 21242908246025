import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  EntitiesList,
  Entity,
  FullProfile,
  MatchProfileResponse,
  Profile,
  ProfileContactData,
  ProfileContactRequestSource,
  ProfileDetailsRequestSource,
  ProfileExternalSearchSources,
  ProfileRequest,
  ProfilesSearchParams,
  ProfilesSources,
  SearchParams,
  XRayLink,
} from '@app-shared/models';
import { isNil, reject } from 'ramda';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHelperService } from '../http-helper/http-helper.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  public unbookProfile(profileId: string): Observable<Record<string, unknown>> {
    return this.http.delete<Record<string, unknown>>(`/profile/${profileId}/booked`);
  }
  public bookProfile(profileId: string, payload: Entity): Observable<Record<string, unknown>> {
    return this.http.post<Record<string, unknown>>(`/profile/${profileId}/booked`, payload);
  }

  public getProfiles(
    source: ProfilesSources,
    params: ProfilesSearchParams = {},
  ): Observable<EntitiesList<Profile> | null> {
    return this.http.get<EntitiesList<Profile>>(`/profiles/${source}`, {
      params: HttpHelperService.serializeParams(params),
    });
  }

  public getExternalProfiles(
    query: ProfilesSearchParams,
    source: ProfileExternalSearchSources,
  ): Observable<{ profiles: Profile[]; totalItems: number }> {
    return this.http.get<{ profiles: Profile[]; totalItems: number }>(
      `/profiles/external-sources/${source}`,
      {
        params: HttpHelperService.serializeParams(query),
      },
    );
  }

  public getXRayLinks(params: ProfilesSearchParams = {}): Observable<XRayLink[] | null> {
    return this.http.get<XRayLink[]>(`/profiles/x-ray-links`, {
      params: HttpHelperService.serializeParams(params),
    });
  }

  public getVacancyProfiles(params: SearchParams = {}): Observable<EntitiesList<Profile> | null> {
    const projectId = params.projectId as number;

    return this.http.get<EntitiesList<Profile>>(`/candidates/${projectId}`, {
      params: HttpHelperService.serializeParams(params),
    });
  }

  public getProfileById(id: string, source: ProfileDetailsRequestSource): Observable<FullProfile> {
    return this.http.get<FullProfile>(`/profile/${id}/details`, { params: { source } }).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 410 && (err.error as Record<string, unknown>).message) {
          void this.router.navigate([
            '/',
            'uk',
            'profile',
            (err.error as Record<string, unknown>).message,
            'details',
          ]);
        }
        throw err as unknown as Error;
      }),
    );
  }

  public getProfileContact(
    id: string,
    source: ProfileContactRequestSource,
  ): Observable<ProfileContactData> {
    return this.http.get<ProfileContactData>(`/profile/${id}/contacts`, { params: { source } });
  }

  public getCounts<T>(
    params: ProfilesSearchParams,
    type: 'total-counts' | 'external-counts',
    source?: ProfilesSources,
  ): Observable<T> {
    let requestSource = '';
    if (source === ProfilesSources.EXTERNAL) {
      requestSource = `/${ProfilesSources.PROFILES}`;
    } else if (source) {
      requestSource = `/${source}`;
    }
    return this.http.get<T>(`/profiles/${type}${requestSource}`, {
      params: HttpHelperService.serializeParams(params),
    });
  }

  public checkProfileExistenceByKeyword(
    keyword: string,
    devId?: string,
  ): Observable<Profile | boolean> {
    return this.http.get<Profile>(`/profiles/check-existence`, {
      params: reject(isNil, { keyword, devId }),
    });
  }

  public createProfile(
    data: Partial<ProfileRequest> & {
      cvMimetype: string | null;
      urlToCv: string | null;
      textContent: string | null;
    },
  ): Observable<Profile> {
    return this.http.post<Profile>('/profile', data);
  }
  public updateProfile(
    devId: string,
    data: Partial<ProfileRequest> | number[],
    targetProperty: string,
  ): Observable<Profile> {
    return this.http.put<Profile>(`/profile/${devId}/${targetProperty}`, data);
  }
  public getIdsForParams(
    source: ProfilesSources,
    params: ProfilesSearchParams,
  ): Observable<string[]> {
    return this.http.get<string[]>(`/profiles/${source}/ids`, {
      params: HttpHelperService.serializeParams(params),
    });
  }
  public getIdsForParamsFromVacancyPage(params: SearchParams): Observable<string[]> {
    const projectId = params.projectId as number;

    return this.http.get<string[]>(`/candidates/${projectId}/ids`, {
      params: HttpHelperService.serializeParams(params),
    });
  }
  public deleteEntity(
    entity: 'profile' | 'file' | 'comment',
    id: string | number,
  ): Observable<Record<string, unknown>> {
    return this.http.delete<Record<string, unknown>>(`/entity/${entity}/${id}`);
  }
  public matchProfile(
    isMatch: boolean,
    id: string,
    publicProfileId: string,
  ): Observable<MatchProfileResponse> {
    return this.http.post<MatchProfileResponse>(`/profile/${id}/match`, {
      isMatch,
      publicProfileId,
    });
  }
  public mergeTwoProfiles(
    ids: string[],
  ): Observable<{ primaryDeveloper: string; duplicateDeveloper: string }> {
    return this.http.post<{ primaryDeveloper: string; duplicateDeveloper: string }>(
      '/profile/merge',
      ids,
    );
  }
  public deleteProfiles(ids: string[]): Observable<void> {
    return this.http.delete<void>('/profiles', { body: { ids } });
  }

  public linkSuggestion(ids: string[]): Observable<void> {
    return this.http.post<void>('/local/merge-profiles', ids);
  }
  public mergeProfileDuplicates(data: { primary: string; duplicates: string[] }) {
    return this.http.post('/profile/merge-multiple', data);
  }

  public removeReminderAboutProfile(id: string): Observable<{ result: boolean }> {
    return this.http.delete<{ result: boolean }>(`/profile/${id}/remove-reminder`);
  }
  public setupReminderAboutProfile(
    id: string,
    date: string,
    message: string,
  ): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>(`/profile/${id}/set-reminder`, {
      date,
      message,
    });
  }
}
