import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatTooltip } from '@angular/material/tooltip';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  getRecruiterInfo,
  isLoading,
} from '@app-core/add-new-entity-popup/reducers/new-entity.reducer';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { isNotNullOrUndefined } from '@app-shared/functions/utilities/utilities';
import {
  DictionaryDepartment,
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  NewEntityPopupMode,
  NewRecruiterForm,
  RecruiterInfo,
  RecruiterPayload,
  SimpleRecruiter,
  TeammateUserType,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getCustomFields,
  getDepartments,
  getDictionaryLoadingState,
  getRecruiters,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import {
  PermissionPanelState,
  RecruiterAccessItem,
  recruiterAccesses,
  teammateUserTypes,
} from '@app-shared/services/recruiter/recruiter.config';
import { emailMatchValidator, lettersValidator, phoneValidator } from '@app-shared/validators';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { MultipleSelectSearchComponent } from '@tsp-components/multiple-select-search';
import { SpinnerComponent } from '@tsp-components/spinner';
import { TeammateTypeComponent } from '@tsp-components/teammate-type';
import { LimitInputDirective } from '@tsp-directives';
import { GetFormControlPipe } from '@tsp-pipes';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  T,
  always,
  complement,
  cond,
  equals,
  forEach,
  identity,
  innerJoin,
  isNil,
  length,
  mergeRight,
  objOf,
  pathOr,
  pick,
  pickBy,
  pipe,
  pluck,
  prop,
  propOr,
  filter as ramdaFilter,
  values,
  without,
} from 'ramda';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { CustomFieldsGroupComponent } from '../../../custom-fields/custom-fields-group/custom-fields-group.component';

@Component({
  imports: [
    AsyncPipe,
    CdkScrollable,
    CustomFieldsGroupComponent,
    GetFormControlPipe,
    LimitInputDirective,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatFormField,
    MatIconButton,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    MatTooltip,
    MultipleSelectSearchComponent,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    SpinnerComponent,
    TeammateTypeComponent,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'app-new-recruiter-dialog',
  templateUrl: './new-recruiter-dialog.component.html',
  styleUrls: ['./new-recruiter-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
})
export class NewRecruiterDialogComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;
  public recruiterCustomFields$?: Observable<DirectoryFieldType[]>;
  public departments: DictionaryDepartment[];
  public permissionPanelState = PermissionPanelState;
  public recruiterAccesses = recruiterAccesses;
  public newRecruiterForm: FormGroup<NewRecruiterForm>;
  public fullRecruiter: RecruiterInfo;
  public regExp = new RegExp(/\s/g);
  public teammateUserTypes = teammateUserTypes;
  public teammates: SimpleRecruiter[];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      mode: NewEntityPopupMode;
      parentParams?: string | number;
    },
    private readonly formBuilder: FormBuilder,
    private readonly store$: Store<State>,
    private readonly dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.store$.dispatch(AddNewEntitySystemActions.OpenRecruiterDialogOnInitAction());

    this.isLoading$ = combineLatest([
      this.store$.pipe(select(getDictionaryLoadingState)),
      this.store$.pipe(select(isLoading)),
    ]).pipe(
      map(([dictionaryLoading, dataLoading]) => dictionaryLoading || dataLoading),
      takeUntil(this.ngUnsubscribe),
    );

    this.store$
      .pipe(
        select(getRecruiterInfo),
        tap(() => {
          this.initForm();
        }),
        filter(() => this.data.mode === 'EDIT'),
        tap((data) => {
          const id = parseInt(this.data.parentParams as string, 10);
          if (!data || data?.id !== id) {
            this.store$.dispatch(
              AddNewEntitySystemActions.RequestRecruiterDetailsOnInitAction({ id }),
            );
          }
        }),
        filter(isNotNullOrUndefined),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((fullRecruiter: RecruiterInfo) => {
        this.fullRecruiter = fullRecruiter;
        this.updateFormWithData(fullRecruiter);
      });

    this.recruiterCustomFields$ = this.store$.pipe(
      select(getCustomFields('recruiterCustomFields')),
      filter(isNotNullOrUndefined),
      map((customFields) => ramdaFilter((field) => !isNil(field.showInPanel), customFields)),
    );

    this.store$
      .pipe(select(getDepartments), filter(isNotNullOrUndefined))
      .subscribe((departments) => (this.departments = departments));

    this.store$
      .pipe(select(getRecruiters), filter(isNotNullOrUndefined))
      .subscribe((recruiters) => (this.teammates = recruiters));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public isPristine(): boolean {
    return propOr(true, 'pristine', this.newRecruiterForm);
  }
  public controlHasErrorType(controlName: string, errorName: string): boolean {
    const control = this.newRecruiterForm.get(controlName);

    if (!control) {
      return false;
    }

    return control.touched && (control.errors ? (control.errors[errorName] as boolean) : false);
  }
  public get formIsInvalid(): boolean {
    return this.newRecruiterForm.invalid;
  }
  public get popupTitle(): string {
    return this.data.mode === 'EDIT'
      ? 'add-new-entity.new-recruiter.title.update'
      : 'add-new-entity.new-recruiter.title.create';
  }
  public get selectedDepartments(): DictionaryDepartment[] {
    const ids = this.newRecruiterForm.get('recruiterDepartmentAccess').value;
    return innerJoin((department, id) => department.id === id, this.departments, ids);
  }
  public removeSelectedEntity(
    id: string | number,
    entitiesName: 'recruiterDepartmentAccess' | 'subordinates',
  ) {
    const control = this.newRecruiterForm.get(entitiesName);
    control.setValue(without([id], control.value as string[]));
  }

  public getPanelState(access: RecruiterAccessItem) {
    const permissions = pluck('name', access.subPermissions);
    return pipe(
      pick(permissions) as UnaryOperator<unknown, { [key: string]: boolean }>,
      values as UnaryOperator<{ [key: string]: boolean }, boolean[]>,
      ramdaFilter(identity as UnaryOperator<boolean, boolean>) as UnaryOperator<
        boolean[],
        boolean[]
      >,
      length,
      cond([
        [equals(0), always(PermissionPanelState.NONE)],
        [equals(permissions.length), always(PermissionPanelState.ALL)],
        [T, always(PermissionPanelState.PARTIAL)],
      ]) as UnaryOperator<number, PermissionPanelState>,
    )(this.newRecruiterForm.value);
  }
  public onPanelCheckboxClick(event: Event) {
    event.stopPropagation();
  }
  public permissionPanelStateChanged(
    checked: boolean,
    permissions: { name: string; title: string }[],
  ) {
    const controls = this.newRecruiterForm.controls;

    forEach(
      (permission: { name: string; title: string }) =>
        (controls[permission.name] as FormControl).setValue(checked),
      permissions,
    );
  }
  public onUserTypeSelected(userType: TeammateUserType) {
    this.newRecruiterForm.patchValue(userType.accesses);
  }
  public openClosePopup() {
    if (this.isPristine()) {
      this.dialog.closeAll();
    } else {
      this.store$.dispatch(RootSystemActions.OpenConfirmationDialogForClosePopupAction());
    }
  }
  public submit() {
    if (this.newRecruiterForm.invalid) {
      return;
    }

    if (this.data.mode === 'EDIT') {
      this.store$.dispatch(
        AddNewEntityUserActions.UpdateRecruiterAction({
          recruiter: { ...this.formData, id: this.fullRecruiter.id },
        }),
      );
    } else {
      this.store$.dispatch(
        AddNewEntityUserActions.CreateNewRecruiterAction({ recruiter: this.formData }),
      );
    }
  }

  private initForm() {
    this.newRecruiterForm = this.formBuilder.group({
      customFields: new FormControl<DirectoryCustomFieldResponse[]>([]),
      email: new FormControl<string>(null, [Validators.required, emailMatchValidator]),
      firstName: new FormControl<string>(null, [Validators.required, lettersValidator]),
      lastName: new FormControl<string>(null, [Validators.required, lettersValidator]),
      skype: new FormControl<string>(null),
      telephone: new FormControl<string>(null, [phoneValidator]),
      recruiterDepartmentAccess: new FormControl<string[]>([]),
      subordinates: new FormControl<number[]>([]),
      // Recruiter accesses
      accessToCandidates: new FormControl<boolean>(true),
      accessToOthers: new FormControl<boolean>(true),
      accessToVacancies: new FormControl<boolean>({ value: true, disabled: true }),
      clientsPage: new FormControl<boolean>(true),
      dashboardPage: new FormControl<boolean>(true),
      deleteComments: new FormControl<boolean>(true),
      deleteVacancy: new FormControl<boolean>(true),
      editVacancyCloseDate: new FormControl<boolean>(true),
      editVacancyInformation: new FormControl<boolean>(true),
      searchConsolePage: new FormControl<boolean>(true),
      showCandidateStatusCustomFields: new FormControl<boolean>(true),
      showClientsContactsDetails: new FormControl<boolean>(true),
      showClientsCustomFields: new FormControl<boolean>(true),
      showProfileContactDetails: new FormControl<boolean>(true),
      showProfileCustomFields: new FormControl<boolean>(true),
      showProfileSecondName: new FormControl<boolean>(true),
      showProfileSocialLinks: new FormControl<boolean>(true),
      showSalary: new FormControl<boolean>(true),
      showVacancyCustomFields: new FormControl<boolean>(true),
      teammatesPage: new FormControl<boolean>(true),
      vacancyAiTab: new FormControl<boolean>(true),
      vacancyNotificationsTab: new FormControl<boolean>(true),
      vacancyStaticTab: new FormControl<boolean>(true),
      viewPortalClients: new FormControl<boolean>(true),
      viewPortalProfiles: new FormControl<boolean>(true),
      viewPortalProjects: new FormControl<boolean>(true),
      viewProfilesComments: new FormControl<boolean>(true),
      viewProfilesFiles: new FormControl<boolean>(true),
      viewProfilesNotifications: new FormControl<boolean>(true),
      viewProfilesShortlists: new FormControl<boolean>(true),
      viewVacancyComments: new FormControl<boolean>(true),
    });
  }
  private get formData(): Partial<RecruiterPayload> {
    const formValue = this.newRecruiterForm.value;
    const formData = {
      email: formValue.email,
      fieldValues: formValue.customFields,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      skype: formValue.skype,
      telephone: formValue.telephone,
      recruiterDepartment: formValue.recruiterDepartmentAccess,
      recruiterAccess: {
        id: pathOr(null, ['recruiterAccess', 'id'], this.fullRecruiter),
        viewPortalProfiles: formValue.viewPortalProfiles,
        viewPortalClients: formValue.viewPortalClients,
        viewPortalProjects: formValue.viewPortalProjects,
        clientsPage: formValue.clientsPage,
        dashboardPage: formValue.dashboardPage,
        deleteComments: formValue.deleteComments,
        deleteVacancy: formValue.deleteVacancy,
        editVacancyCloseDate: formValue.editVacancyCloseDate,
        editVacancyInformation: formValue.editVacancyInformation,
        searchConsolePage: formValue.searchConsolePage,
        teammatesPage: formValue.teammatesPage,
        vacancyAiTab: formValue.vacancyAiTab,
        vacancyNotificationsTab: formValue.vacancyNotificationsTab,
        vacancyStaticTab: formValue.vacancyStaticTab,
        viewProfilesComments: formValue.viewProfilesComments,
        viewProfilesFiles: formValue.viewProfilesFiles,
        viewProfilesNotifications: formValue.viewProfilesNotifications,
        viewProfilesShortlists: formValue.viewProfilesShortlists,
        viewVacancyComments: formValue.viewVacancyComments,
        showCandidateStatusCustomFields: formValue.showCandidateStatusCustomFields,
        showClientsContactsDetails: formValue.showClientsContactsDetails,
        showClientsCustomFields: formValue.showClientsCustomFields,
        showProfileContactDetails: formValue.showProfileContactDetails,
        showProfileCustomFields: formValue.showProfileCustomFields,
        showVacancyCustomFields: formValue.showVacancyCustomFields,
        showProfileSecondName: formValue.showProfileSecondName,
        showProfileSocialLinks: formValue.showProfileSocialLinks,
        showSalary: formValue.showSalary,
      },
      subordinates: formValue.subordinates,
    };

    return pipe(
      prop('recruiterAccess'),
      pickBy(complement(isNil)),
      objOf('recruiterAccess'),
      mergeRight(formData),
    )(formData);
  }
  private updateFormWithData(recruiterData: RecruiterInfo) {
    const getAccess = (access: string) =>
      pathOr(null, ['recruiterAccess', access], recruiterData) as boolean | null;

    this.newRecruiterForm.patchValue({
      customFields: recruiterData.fieldValues || [],
      email: recruiterData.email,
      firstName: recruiterData.firstName,
      lastName: recruiterData.lastName,
      skype: recruiterData.skype || null,
      telephone: recruiterData.telephone || null,
      subordinates: recruiterData.subordinates?.map(prop('id')) || [],
      recruiterDepartmentAccess: recruiterData.recruiterDepartmentAccess?.map(({ id }) => id) || [],
      viewPortalProfiles: getAccess('viewPortalProfiles'),
      viewPortalClients: getAccess('viewPortalClients'),
      viewPortalProjects: getAccess('viewPortalProjects'),
      clientsPage: getAccess('clientsPage'),
      dashboardPage: getAccess('dashboardPage'),
      deleteComments: getAccess('deleteComments'),
      deleteVacancy: getAccess('deleteVacancy'),
      editVacancyCloseDate: getAccess('editVacancyCloseDate'),
      editVacancyInformation: getAccess('editVacancyInformation'),
      searchConsolePage: getAccess('searchConsolePage'),
      teammatesPage: getAccess('teammatesPage'),
      vacancyNotificationsTab: getAccess('vacancyNotificationsTab'),
      vacancyStaticTab: getAccess('vacancyStaticTab'),
      viewProfilesComments: getAccess('viewProfilesComments'),
      viewProfilesFiles: getAccess('viewProfilesFiles'),
      viewProfilesNotifications: getAccess('viewProfilesNotifications'),
      viewProfilesShortlists: getAccess('viewProfilesShortlists'),
      viewVacancyComments: getAccess('viewVacancyComments'),
      showCandidateStatusCustomFields: getAccess('showCandidateStatusCustomFields'),
      showClientsContactsDetails: getAccess('showClientsContactsDetails'),
      showClientsCustomFields: getAccess('showClientsCustomFields'),
      showProfileContactDetails: getAccess('showProfileContactDetails'),
      showProfileCustomFields: getAccess('showProfileCustomFields'),
      showVacancyCustomFields: getAccess('showVacancyCustomFields'),
      showSalary: getAccess('showSalary'),
      showProfileSecondName: getAccess('showProfileSecondName'),
      showProfileSocialLinks: getAccess('showProfileSocialLinks'),
    });
  }
}
