import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Locales } from '@app-shared/models';

@Component({
  imports: [RouterLink],
  selector: 'short-list-project-info',
  templateUrl: './short-list-project-info.component.html',
  styleUrls: ['./short-list-project-info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListProjectInfoComponent {
  @Input()
  public locale: Locales = 'uk';
  @Input()
  public projectName: string;
  @Input()
  public projectId: number;
  @Input()
  public type: string;
}
