export interface SubscriptionPlan {
  name: SubscriptionPackagesEnum;
  prices: {
    active?: boolean;
    period?: 'month' | 'year';
    default?: number;
    price: number;
    trialDiscount?: number;
    unit?: 'user';
  }[];
  needDemo: boolean;
  hint?: string;
  canHaveDiscount?: boolean;
  features: {
    flow: 'column' | 'row wrap';
    items: string[];
    title: 'you-got' | 'for-request';
  }[];
}

export enum SubscriptionPackagesEnum {
  team = 'team',
  professional = 'professional',
  plugin = 'plugin',
}
