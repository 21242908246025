import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { OWL_DATE_TIME_FORMATS } from '@app-shared/helpers';
import { ShortListDialogData, ShortListDialogForm, ShortListFormValue } from '@app-shared/models';
import {
  DateTimeAdapter,
  NativeDateTimeAdapter,
  OWL_DATE_TIME_FORMATS as OWL_DATE_TIME_FORMATS_PROVIDER,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';

@Component({
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,

    TranslatePipe,
    TranslateDirective,
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS_PROVIDER, useValue: OWL_DATE_TIME_FORMATS },
  ],
  selector: 'short-list-dialog-interview',
  templateUrl: './short-list-dialog-interview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogInterviewComponent implements OnInit, OnDestroy {
  @Input()
  public data: ShortListDialogData;
  @Output()
  public formChanged = new EventEmitter<ShortListFormValue>();
  @Output()
  public formStatusChanged = new EventEmitter<FormControlStatus>();
  public form: FormGroup<Pick<ShortListDialogForm, 'interviewAt'>>;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder) {}
  public ngOnInit(): void {
    this.form = this.formBuilder.group<Pick<ShortListDialogForm, 'interviewAt'>>({
      interviewAt: new FormControl(this.data.interviewAt, [Validators.required]),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.formChanged.emit(value));
    this.form.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => this.formStatusChanged.emit(status));
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public get interviewStartTime(): Date {
    const currentDateTime = this.form.get('interviewAt').value as unknown as string;
    return currentDateTime
      ? DateTime.fromISO(currentDateTime).toJSDate()
      : DateTime.local().startOf('hour').toJSDate();
  }
}
