import { createFeatureSelector, createSelector } from '@ngrx/store';
import { kernelFeatureKey, KernelState } from '../reducers/kernel.reducer';

export const selectKernelState = createFeatureSelector<KernelState>(kernelFeatureKey);

export const getKernelDataLoadingState = createSelector(
  selectKernelState,
  (store) => store?.isLoading,
);

export const getKernelOrganizationStructure = createSelector(
  selectKernelState,
  (store) => store?.structure,
);

export const getRelatedDepartmentInfo = createSelector(
  selectKernelState,
  (s) => s?.relatedDepartmentInfo,
);
