<div>
  <app-search-select
    [elementDisabled]="control.disabled"
    [formControl]="control"
    [customClass]="customClass"
    [buttonClass]="buttonClass"
    [label]="label"
    [labelType]="labelType"
    [placeholder]="placeholder"
    [options]="options$ | async"
    [allowClear]="allowClear"
    [clearOnFocus]="clearOnFocus"
    [showAdditionalInfo]="showAdditionalInfo"
    [showId]="showId"
    [withImage]="withImage"
    (optionSelected)="optionSelected.emit($event)"
    (onSearch)="onSearch($event)"
  >
    <ng-content></ng-content>
  </app-search-select>
</div>
