import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SystemService } from '@app-shared/services/system/system.service';
import { test } from 'ramda';
import { catchError, mergeMap, retry, timer } from 'rxjs';

let totalCount = 0;

export const networkErrorRetryInterceptor: HttpInterceptorFn = (req, next) => {
  const systemService = inject(SystemService);

  return next(req).pipe(
    retry({
      delay: (err: HttpErrorResponse) => {
        const isExtensionRequest = test(/chrome-extension/g, req.url);
        if (isExtensionRequest) {
          throw err as unknown as Error;
        }

        if (err.status === 0 && totalCount < 5) {
          totalCount += 1;

          return systemService.getServerStatus().pipe(
            catchError(() => {
              throw err as unknown as Error;
            }),
            mergeMap(() => timer(500)),
          );
        }

        throw err as unknown as Error;
      },
    }),
  );
};
