<form class="c-tsp-mat-form" [formGroup]="form" novalidate>
  <mat-selection-list
    class="c-tsp-mat-form-field__row c-short-list-dialog-review__options"
    formControlName="cvLink"
    [multiple]="false"
  >
    <div mat-subheader translate>profile-short-list.short-list-dialog.cv.title</div>
    @for (file of cvs; track trackById($index, file); let last = $last) {
      <mat-list-option [value]="file.filePath">
        <span class="c-short-list-dialog-review__file">
          <span
            mat-list-icon
            class="c-short-list-dialog-review__file-icon"
            [matTooltip]="'Preview' | translate"
            (click)="showCvPreview($event, file)"
          >
            <i
              class="fas"
              [ngClass]="getCvType(file) + ' fa-file-' + getCvType(file)"
              aria-hidden="true"
            ></i>
          </span>
          <span mat-line>{{ getCvName(file) }}</span>
          <span class="c-short-list-dialog-review__file-date" mat-line>
            {{ file.createdAt | formatDateTime: 'short' }}
          </span>
        </span>
        @if (!last) {
          <mat-divider></mat-divider>
        }
      </mat-list-option>
    }
    @if (!cvs?.length) {
      <mat-list-option disabled>
        <span translate>profile-short-list.short-list-dialog.cv.empty</span>
      </mat-list-option>
    }
  </mat-selection-list>
  <div
    class="c-short-list-dialog-review__file-preview-wrapper"
    [ngClass]="{ 'c-short-list-dialog-review__file-preview-wrapper--visible': !!expandedElement }"
    (keyup)="hideCvPreview()"
    (click)="hideCvPreview()"
  >
    <div class="c-short-list-dialog-review__file-preview">
      <span
        class="c-short-list-dialog-review__file-preview--close"
        (keyup)="hideCvPreview()"
        (click)="hideCvPreview()"
      >
        <i class="fas fa-times" aria-hidden="true"></i>
      </span>
      @if (expandedElement) {
        <app-file-preview fileType="cvs" [url]="expandedElement.filePath"> </app-file-preview>
      }
    </div>
  </div>
  @if (fileUploading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  <dropzone
    class="c-short-list-dialog-review__dropzone"
    [config]="dropzoneConfig"
    [disabled]="fileUploading"
    [message]="'add-new-entity.new-talent.dropzone.message' | translate"
    (addedFile)="onFileAdded($event)"
  >
  </dropzone>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-label translate>profile-short-list.short-list-dialog.comment.label</mat-label>
      <textarea
        matInput
        formControlName="recruiterComment"
        [placeholder]="'profile-short-list.short-list-dialog.comment.label' | translate"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
      >
      </textarea>
    </mat-form-field>
  </div>
</form>
