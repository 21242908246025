import { CurrentUser } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const CopyTextToClipboard = createAction(
  '[Scripts Injection] Copy text data into clipboard',
  props<{ value: string }>(),
);
const InitDatadog = createAction('[Scripts Injection] Init Datadog monitor service');

const InjectGoogleMaps = createAction('[Scripts Injection] Inject Google Maps script');
const InjectGoogleTagManager = createAction('[Scripts Injection] Inject Google Tag Manager script');

const SetDatadogUserSession = createAction(
  '[Scripts Injection] Set logged user data to datadog session',
  props<{ user: CurrentUser }>(),
);

const actions = {
  CopyTextToClipboard,
  InitDatadog,
  InjectGoogleMaps,
  InjectGoogleTagManager,
  SetDatadogUserSession,
};

const scriptsActions = union(actions);

export type ScriptsActionsType = typeof scriptsActions;

export const ScriptsActions = actions;
