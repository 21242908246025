<mat-form-field [floatLabel]="labelType" [ngClass]="matFormClass">
  @if (labelType === 'always') {
    <mat-label translate>{{ label }}</mat-label>
  }
  <input
    matInput
    [ngClass]="customInputClass"
    [placeholder]="inputPlaceholder | translate"
    [matAutocomplete]="auto"
    [formControl]="searchInputControl"
    (focus)="onFocus()"
    (blur)="onBlur()"
    #input
  />
  @if (!elementDisabled && inputValue && allowClear) {
    <button
      matSuffix
      mat-icon-button
      [ngClass]="customButtonClass"
      (click)="clearInput($event)"
      [matTooltip]="'shared.search-select.tooltip' | translate"
    >
      <i class="fas fa-times" aria-hidden="true"> </i>
    </button>
  }
  @if (!allowClear) {
    <i matSuffix class="fas fa-sort-down arrow" aria-hidden="true"> </i>
  }
  <mat-autocomplete
    #auto="matAutocomplete"
    [class]="customModifierClass"
    (optionSelected)="onSelectionChange($event.option.value)"
  >
    @for (option of options; track option) {
      <mat-option
        class="c-tsp-mat-multiple-select__option"
        [ngClass]="customOptionClass"
        [value]="option"
        [title]="option.name"
      >
        @if (withImage && option | castToRecruiter; as optionRecruiter) {
          @if (withImage) {
            <div
              [ngClass]="{
                'c-search-select__image': optionRecruiter.image,
                'c-search-select__image-none': !optionRecruiter.image,
              }"
            >
              @if (optionRecruiter.image) {
                <img
                  class="c-search-select__image"
                  appFallbackSrc="./../../../../../assets/default-profile-image.png"
                  [src]="optionRecruiter.image"
                  alt="Image"
                />
              } @else {
                <span class="c-search-select__initials">
                  {{ optionRecruiter | getInitials }}
                </span>
              }
            </div>
          }
        }
        <div class="c-search-select__option">
          {{ option.name }} {{ getOptionId(option) }}
          @if (showAdditionalInfo && (option | castToVacancy).createdAt) {
            <div class="c-search-select__info">
              @if (option | castToVacancy; as optionVacancy) {
                @if (optionVacancy.createdAt) {
                  <span class="c-search-select__info-created">
                    {{ optionVacancy.createdAt | date: 'd/MM/yyyy' }}
                  </span>
                }
                @if (optionVacancy.numbersOfTalent > 1) {
                  <span
                    class="c-search-select__info-talents"
                    [matTooltip]="
                      'shared.comment.autocomplete-select.search-select.candidates.tooltip'
                        | translate
                    "
                    matTooltipPosition="above"
                  >
                    {{ optionVacancy.numbersOfTalent }}
                  </span>
                }
              }
            </div>
          }
        </div>
      </mat-option>
    }
  </mat-autocomplete>
  @if (options?.length === 0 && isInputFocused) {
    <div class="nothing-found-container">
      <span class="nothing-found-container-text" translate>
        shared.search-select.nothing-found
      </span>
      <ng-content class="nothing-found-container-action"></ng-content>
    </div>
  }
</mat-form-field>
