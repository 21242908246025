import { NewEntityState } from '@app-core/add-new-entity-popup/reducers/new-entity.reducer';
import { ClientsListState } from '@app-core/clients-list/reducers/clients-list.reducer';
import { DashboardState } from '@app-core/dashboard/reducers/dashboard.reducer';
import { DirectoriesState } from '@app-core/directories/reducers/directories.reducer';
import { NotificationSettingsState } from '@app-core/notification-settings/reducers/notification-settings.reducer';
import { ProfileDetailsState } from '@app-core/profile-details/reducers/profile-details.reducer';
import { RecruiterDetailsState } from '@app-core/recruiter-details/reducers/recruiter-details.reducer';
import { RootState } from '@app-core/root/reducer/root.reducer';
import { SearchConsoleState } from '@app-core/search-console/reducers/search-console.reducer';
import { SidebarState } from '@app-core/sidebar/reducers/sidebar.reducer';
import { SpaceSettingsState } from '@app-core/space-settings/reducers/space-settings.reducer';
import { SubscriptionState } from '@app-core/subscription/reducers/subscription.reducer';
import { TeammatesState } from '@app-core/teammates/reducers/teammates.reducer';
import { VacanciesListState } from '@app-core/vacancies-list/reducers/vacancies-list.reducer';
import { VacancyProfilesState } from '@app-core/vacancy-profiles/reducer/vacancy-profiles.reducer';
import { BinaryOperator, RouterStateUrl, UnaryOperator } from '@app-shared/models';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import {
  any,
  applySpec,
  converge,
  F,
  identity,
  ifElse,
  isNil,
  mergeRight,
  path,
  pathOr,
  pipe,
  prop,
  props,
  values,
} from 'ramda';
import { appFeatureReducer, AppFeaturesState } from './app-feature/app-feature.reducer';
import { authReducer, AuthState } from './auth/auth.reducer';
import { DictionariesState, dictionaryReducer } from './dictionary/dictionary.reducer';
import { selectionReducer, SelectionState } from './selection/selection.reducer';
import { vacancyReducer, VacancyState } from './vacancy/vacancy.reducer';

export interface State {
  appFeature: AppFeaturesState;
  auth: AuthState;
  clientsList?: ClientsListState;
  currentUser?: RootState;
  dashboard?: DashboardState;
  dictionaries: DictionariesState;
  directories?: DirectoriesState;
  newEntity?: NewEntityState;
  notificationSettings?: NotificationSettingsState;
  spaceSettingsDetails?: SpaceSettingsState;
  profileDetails?: ProfileDetailsState;
  recruiterDetails?: RecruiterDetailsState;
  router: RouterReducerState<RouterStateUrl>;
  searchConsole?: SearchConsoleState;
  selection: SelectionState;
  sidebar?: SidebarState;
  subscription?: SubscriptionState;
  teammates?: TeammatesState;
  vacanciesList?: VacanciesListState;
  vacancy: VacancyState;
  vacancyProfiles?: VacancyProfilesState;
}

export const reducers = {
  appFeature: appFeatureReducer,
  auth: authReducer,
  dictionaries: dictionaryReducer,
  router: routerReducer,
  selection: selectionReducer,
  vacancy: vacancyReducer,
};

export const appLoadingState = createSelector(
  (state: State) => state,
  pipe(
    applySpec({
      clientsList: pathOr(false, ['clientsList', 'isLoading']),
      currentUser: pathOr(false, ['currentUser', 'isLoading']),
      dashboard: pathOr(false, ['dashboard', 'isLoading']),
      dictionaries: pipe(
        prop('dictionaries') as UnaryOperator<State, DictionariesState>,
        props([
          'isClientsLoading',
          'isDictionariesLoading',
          'isRecruitersLoading',
          'isRejectReasonsLoading',
        ]) as UnaryOperator<DictionariesState, boolean[]>,
        any(identity as UnaryOperator<boolean, boolean>) as UnaryOperator<boolean[], boolean>,
      ),
      directories: pathOr(false, ['directories', 'isLoading']),
      newEntity: pathOr(false, ['newEntity', 'isLoading']),
      profileDetails: pipe(
        prop('profileDetails') as UnaryOperator<State, ProfileDetailsState | undefined>,
        ifElse(
          isNil,
          F,
          pipe(
            converge(
              mergeRight as BinaryOperator<
                Record<string, boolean>,
                Record<string, boolean>,
                Record<string, boolean>
              >,
              [
                applySpec({
                  isFilesLoading: prop('isFilesLoading'),
                  isProfileLoading: prop('isProfileLoading'),
                  historyEventsLoading: path(['profileHistory', 'isLoading']),
                }) as UnaryOperator<ProfileDetailsState, Record<string, boolean>>,
                prop('loadingDeveloperData'),
              ],
            ),
            values,
            any(identity as UnaryOperator<boolean, boolean>),
          ),
        ),
      ),
      searchConsole: pipe(
        prop('searchConsole') as UnaryOperator<State, SearchConsoleState>,
        ifElse(
          isNil,
          F,
          pipe(
            props(['isCountsLoading', 'isProfilesLoading']) as UnaryOperator<
              SearchConsoleState,
              boolean[]
            >,
            any(identity as UnaryOperator<boolean, boolean>),
          ),
        ),
      ),
      spaceSettingsDetails: pathOr(false, ['spaceSettingsDetails', 'isLoading']),
      recruiterDetails: pathOr(false, ['recruiterDetails', 'isLoading']),
      sidebar: pathOr(false, ['sidebar', 'isLoading']),
      vacanciesList: pathOr(false, ['vacanciesList', 'isLoading']),
      vacancyProfiles: pipe(
        prop('vacancyProfiles') as UnaryOperator<State, VacancyProfilesState>,
        ifElse(
          isNil,
          F,
          pipe(
            props(['isActivitiesLoading', 'isProfilesLoading']) as UnaryOperator<
              VacancyProfilesState,
              boolean[]
            >,
            any(identity as UnaryOperator<boolean, boolean>),
          ),
        ),
      ),
      teammates: pathOr(false, ['teammates', 'isLoading']),
    }) as UnaryOperator<State, Record<string, boolean>>,
    values as UnaryOperator<Record<string, boolean>, boolean[]>,
    any(identity as UnaryOperator<boolean, boolean>) as UnaryOperator<boolean[], boolean>,
  ),
);
