import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentFeaturesConfiguration } from '@app-shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private readonly http: HttpClient) {}

  public getAppConfiguration(): Observable<EnvironmentFeaturesConfiguration> {
    return this.http.get<EnvironmentFeaturesConfiguration>('/angular-app-config', {
      headers: { anonymousRequest: '1' },
    });
  }

  public getServerStatus(): Observable<{ version: string; build: string }> {
    return this.http.get<{ version: string; build: string }>('/version', {
      headers: { anonymousRequest: '1' },
    });
  }
}
