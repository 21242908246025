import { VacancyApiActions } from '@app-shared/actions/api-actions/vacancy-api.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { RouterActions } from '@app-shared/actions/router.actions';
import {
  ClearVacanciesStoreAfterEditingAction,
  SaveSelectedVacancyToRecruiterProfileAction,
} from '@app-shared/components/vacancy-selector/actions/vacancy-selector.system.actions';
import { SelectVacancyAction } from '@app-shared/components/vacancy-selector/actions/vacancy-selector.user.actions';
import { UnaryOperator, Vacancy, VacancyDetails, VacancyDictionary } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { GetVacanciesListAction } from '@app-shared/resolvers/vacancies-simple-list/vacancies-simple-list-resolve.actions';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import {
  applySpec,
  concat,
  equals,
  ifElse,
  isNil,
  lt,
  map,
  mergeLeft,
  mergeRight,
  pick,
  pipe,
  pluck,
  propOr,
  reject,
  sum,
  toPairs,
  toString,
  unless,
  values,
} from 'ramda';

export interface VacancyState {
  details?: VacancyDetails;
  list?: VacancyDictionary[];
  selected?: number;
  selectedDetails?: VacancyDetails;
  isLoading: boolean;
}

export const initialState: VacancyState = {
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(MetadataActions.CleanReducers, () => initialState),
  on(ClearVacanciesStoreAfterEditingAction, mergeLeft({ list: null })),
  on(GetVacanciesListAction, mergeLeft({ isLoading: true })),
  on(VacancyApiActions.GetSimpleVacanciesListFailedAction, mergeLeft({ isLoading: false })),
  on(VacancyApiActions.GetSimpleVacanciesListSuccessAction, (state, { vacanciesList }) =>
    mergeRight(state, { list: vacanciesList, isLoading: false }),
  ),
  on(VacancyApiActions.GetSelectedVacancyDetailsSuccessAction, (state, { vacancyDetails }) =>
    mergeRight(state, { selectedDetails: vacancyDetails }),
  ),
  on(VacancyApiActions.GetVacancyDetailsSuccessAction, (state, { vacancyDetails }) =>
    mergeRight(state, { details: vacancyDetails }),
  ),
  on(
    RouterActions.NavigateToVacancyProfilesAction,
    mergeLeft({
      selected: null,
      details: null,
      list: null,
    }),
  ),
  on(SelectVacancyAction, SaveSelectedVacancyToRecruiterProfileAction, (state, { id }) =>
    mergeRight(state, { selected: isNil(id) ? null : id }),
  ),
);

export function vacancyReducer(baseState: VacancyState, action: Action): VacancyState {
  return reducer(baseState, action);
}

export const selectVacancyStore = (state: State) => state.vacancy;
export const getLoadingState = createSelector(selectVacancyStore, (s) => s.isLoading);
export const getVacanciesList = createSelector(selectVacancyStore, (s) => s.list);
export const getVacancyDetails = createSelector(selectVacancyStore, (s) => s.details);
export const getSelectedVacancyId = createSelector(selectVacancyStore, (s) => s.selected);
export const getSelectedVacancyDetails = createSelector(
  selectVacancyStore,
  (s) => s.selectedDetails,
);
export const getSelectedVacancySkills = createSelector(
  getSelectedVacancyDetails,
  pipe(propOr([], 'skills'), pluck('id')) as UnaryOperator<
    VacancyDetails | null,
    number[] | undefined
  >,
);
export const getSelectedVacancyCandidatesCount = createSelector(
  getSelectedVacancyDetails,
  unless(
    isNil,
    pipe(
      pick(['longList', 'ai', 'inbox', 'processing', 'hired', 'notAccepted']),
      reject(equals(0)),
      applySpec({
        total: pipe(values, sum),
        pairs: pipe(
          toPairs as UnaryOperator<Partial<Vacancy>, [string, number][]>,
          map(([key, count]) => {
            let url = [];
            let label = '';

            switch (key) {
              case 'longList':
                url = ['long_list'];
                label = 'long-list';
                break;
              case 'inbox':
                url = ['applies'];
                label = 'applies';
                break;
              case 'notAccepted':
                url = ['not_accepted'];
                label = 'not-accepted';
                break;
              default:
                url = [key];
                label = key;
            }

            return {
              count,
              label: `vacancy-selector.label.links.${label}`,
              type: key,
              url,
            };
          }),
        ),
      }),
    ),
  ) as UnaryOperator<
    VacancyDetails | null,
    { total: number; pairs: { label: string; count: number; type: string; url: string[] }[] } | null
  >,
);
export const getSelectedVacancyPositionCount = createSelector(
  getSelectedVacancyDetails,
  unless(
    isNil,
    pipe(
      propOr(1, 'numbersOfTalent'),
      ifElse(lt(1), pipe(toString, concat('/ ')), () => ''),
    ),
  ) as UnaryOperator<VacancyDetails | null, string>,
);
