import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { DashboardVideos, DictionaryItem, Locales } from '.';
export interface EnvironmentFeaturesConfiguration {
  readonly allowPushNotifications: number[];
  readonly dashboardVideos: DashboardVideos;
  readonly extensionConfig: { url: string; warningLabel: { [key in Locales]: string } };
  readonly portalsAllowedForLinkedInMessaging: number[];
  readonly shortInsideInformationInProfileCard: number[];
  readonly useNewVacanciesFeature: boolean;
  readonly useNodeForSendingNotifications: boolean;
  readonly showTrelloButton: boolean;
}

export interface EnvironmentConfiguration {
  apiUrl: string;
  angularSiteUrl: string;
  supportUrl: string;
  production: boolean;
  hmr: boolean;
  googleMapsId: string;
  googleSocialAuthId: string;
  chromePluginId: string;
  gtmId: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
  firebaseWebPushKey: string;
  defaultFeatureConfiguration: EnvironmentFeaturesConfiguration;
  datadog: {
    applicationId: string;
    clientToken: string;
    privacyLevel: DefaultPrivacyLevel;
  };
  integrations: {
    kernel: boolean;
    customFields: {
      shortList: Array<DictionaryItem & { vacancyFieldId?: number }>;
      vacancy: Array<DictionaryItem & { vacancyFieldId?: number }>;
    };
    specificShortListStatuses: { [key: string]: number };
  };
  stripe?: {
    demoUrl: string;
    professional: {
      month: string;
      year: string;
      promoCode: string;
    };
    team: {
      month: string;
      year: string;
    };
  };
}

export interface AppVersionData {
  version: string;
}
