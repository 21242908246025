<div class="c-profile-candidates">
  <div class="c-profile-candidates__preview" (click)="isVisible = !isVisible">
    <i class="fas fa-info-circle c-profile-candidates__preview-icon" aria-hidden="true"> </i>
    <span translate>profile-preview.profile-candidates.found</span>
    <div class="c-profile-candidates__preview-count">
      @if (candidates.length > 3) {
        <span class="c-profile-candidates__image c-profile-candidates__image--small">
          +{{ candidates.length - 3 }}
        </span>
      }
      @for (candidate of candidates | slice: 0 : 3; track trackById(i, candidate); let i = $index) {
        @if (candidate.image) {
          <img
            class="c-profile-candidates__image c-profile-candidates__image--small"
            [ngStyle]="{ 'z-index': 5 - i }"
            [src]="candidate.image"
            appFallbackSrc="./../../../../assets/default-profile-image.png"
            alt="Image"
          />
        }
        @if (!candidate.image) {
          <span
            class="c-profile-candidates__image c-profile-candidates__image--small c-profile-candidates__image--default"
            [ngStyle]="{ 'z-index': 5 - i }"
          >
            <i class="far fa-user-circle" aria-hidden="true"></i>
          </span>
        }
      }
    </div>
  </div>
  <div
    class="c-profile-candidates__wrapper"
    [ngClass]="{ 'c-profile-candidates__wrapper--visible': isVisible }"
  >
    <button class="c-profile-candidates__close" mat-button translate (click)="isVisible = false">
      <span translate>profile-preview.profile-candidates.close</span>
    </button>
    <span class="c-profile-candidates__title">
      <i class="fas fa-info-circle c-profile-candidates__title-icon" aria-hidden="true"> </i>
      <span translate>profile-preview.profile-candidates.info</span>
    </span>
    @for (candidate of candidates; track trackById(i, candidate); let i = $index) {
      <div class="c-profile-candidates__item" [ngStyle]="i > 1 && !showAll && { display: 'none' }">
        @if (candidate.image) {
          <img
            class="c-profile-candidates__image"
            [src]="candidate.image"
            appFallbackSrc="./../../../../assets/default-profile-image.png"
            alt="Profile image"
          />
        } @else {
          <span class="c-profile-candidates__image">
            {{ candidate | getInitials }}
          </span>
        }
        <div class="c-profile-candidates__info">
          <div class="c-profile-candidates--row">
            <div class="c-profile-candidates__info-name" [matTooltip]="candidate.name">
              {{ candidate.name }}
            </div>
            <a
              class="c-profile-candidates__info-name-open-button"
              [matTooltip]="'profile-preview.profile-candidates.open' | translate"
              [routerLink]="['/', userLocale, 'profile', candidate.id, 'details']"
              target="_blank"
            >
              <img
                class="c-profile-candidates__info-name-open-button-icon"
                src="../../../../assets/icon-share-alt-grey.svg"
                alt="Open"
              />
            </a>
            @if (isCandidateOnVacancy(candidate)) {
              <span class="c-profile-candidates__info-added" translate
                >profile-preview.profile-candidates.added</span
              >
            } @else {
              <div
                class="c-profile-candidates__info-add-button-wrapper"
                [matTooltip]="
                  vacancyId ? '' : ('profile-preview.profile-candidates.select-vacancy' | translate)
                "
              >
                <button
                  class="c-profile-candidates__info-add-button"
                  translate
                  [disabled]="!vacancyId"
                  (click)="addToList.emit(candidate.id)"
                >
                  profile-preview.profile-candidates.add-to-vacancy
                </button>
              </div>
            }
          </div>
          @if (candidate.position) {
            <span class="c-profile-candidates__info-company" [matTooltip]="candidate.position">{{
              candidate.position
            }}</span>
          }
        </div>
      </div>
    }
    @if (candidates.length > 2) {
      <button class="c-profile-candidates__show-all" mat-button (click)="showAll = !showAll">
        {{ 'profile-preview.profile-candidates.' + (showAll ? 'hide' : 'show-all') | translate }}
      </button>
    }
    @if (candidates.length > 1) {
      <div class="c-profile-candidates__footer">
        <button
          class="c-profile-candidates__footer-button c-profile-candidates__footer-continue-button"
          mat-button
          (click)="searchMatchedCandidates.emit()"
        >
          <span translate>profile-preview.profile-candidates.merge</span>
        </button>
      </div>
    }
  </div>
</div>
