import {
  AnalyzedAiCvData,
  ClientFull,
  DictionariesEnum,
  DictionaryItem,
  DictionaryShortListBoard,
  DirectoryFieldType,
  FullProfile,
  Profile,
  ProfileContacts,
  RecruiterInfo,
  VacancySuggestedParams,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AnalyzeTitleAndDescriptionSuccess = createAction(
  '[New Entity Popup] Vacancy suggested params saved to store',
  props<{ parameters: VacancySuggestedParams }>(),
);
const AnalyzeTitleAndDescriptionFailed = createAction(
  '[New Entity Popup] Request for parsing vacancy title and description was not successful',
);
const CleanNewEntityStateAction = createAction(
  '[Module Cleaner Guard] Clean new entity module state',
);
const DeleteCustomFieldFailedAction = createAction('[New Entity Popup] Custom field delete failed');
const DeleteCustomFieldSuccessAction = createAction(
  '[New Entity Popup] Custom field successfully deleted',
);
const DeleteDirectoryItemFailedAction = createAction(
  '[New Entity Popup] Directory item delete failed',
);
const DeleteDirectoryItemSuccessAction = createAction(
  '[New Entity Popup] Directory item successfully deleted',
);
const DeleteProfileFromDialogFailedAction = createAction(
  '[New Entity Popup] Profile delete failed',
);
const DeleteProfileFromDialogSuccessAction = createAction(
  '[New Entity Popup] Profile successfully deleted',
);
const GetShortListBoardByIdFailedAction = createAction(
  '[New Entity Popup] Request for short list board by id had failed',
);
const GetShortListBoardByIdSuccessAction = createAction(
  '[New Entity Popup] Short list board stored in the state',
  props<{ board: DictionaryShortListBoard }>(),
);
const OpenClientDialogOnInitAction = createAction('[New Entity Popup] Open Add New Client dialog');
const OpenCustomFieldDialogOnInitAction = createAction(
  '[New Entity Popup] Open Add New Custom Field dialog',
);
const OpenDirectoryDialogOnInitAction = createAction(
  '[New Entity Popup] Open Add New Directory dialog',
);
const OpenRecruiterDialogOnInitAction = createAction(
  '[New Entity Popup] Open Add New Recruiter dialog',
);
const OpenTalentDialogOnInitAction = createAction('[New Entity Popup] Open Add New Talent dialog');
const OpenVacancyDialogOnInitAction = createAction(
  '[New Entity Popup] Open Add New Vacancy dialog',
);
const RefreshProfilesListAfterAddToVacancyAction = createAction(
  '[Search Console] Refresh profiles list after adding profile to vacancy',
);
const RefreshProfilesListAfterCreateProfileAction = createAction(
  '[Search Console] Refresh profiles list after creating profile',
);
const RemoveMatchedProfileByContactAction = createAction(
  '[New Entity Popup] Remove matched profile by contact',
  props<{ contact: string }>(),
);
const RemoveMatchedProfileByLinkAction = createAction(
  '[New Entity Popup] Remove matched profile by link',
  props<{ link: string }>(),
);

const RequestProfileContactsSuccessAction = createAction(
  '[New Entity Popup] Profiles contacts saved to store',
  props<{ contacts: ProfileContacts }>(),
);
const RequestProfileDetailsOnInitAction = createAction(
  '[New Entity Popup] Request profile details for editing',
  props<{ id: string }>(),
);
const RequestProfileDetailsFailedAction = createAction(
  '[New Entity Popup] Error happened during retrieving profile details for editing',
);
const RequestProfileDetailsSuccessAction = createAction(
  '[New Entity Popup] Profiles details saved to store',
  props<{ profile: FullProfile }>(),
);
const RequestVacancyDetailsOnInitAction = createAction(
  '[New Entity Popup] Request vacancy details for editing',
  props<{ id: number }>(),
);
const RequestClientDetailsOnInitAction = createAction(
  '[New Entity Popup] Request client details for editing',
  props<{ id: number }>(),
);
const RequestClientDetailsOnInitSuccessAction = createAction(
  '[New Entity Popup] Client details saved to store',
  props<{ client: ClientFull }>(),
);
const RequestDirectoryItemDetailsOnInitAction = createAction(
  '[New Entity Popup] Request directory details for editing',
  props<{ id: number; directory: DictionariesEnum }>(),
);
const RequestDirectoryItemDetailsOnInitSuccessAction = createAction(
  '[New Entity Popup] Directory details saved to store',
  props<{ item: DictionaryItem | DirectoryFieldType }>(),
);
const RequestDirectoryItemDetailsOnInitFailedAction = createAction(
  '[New Entity Popup] Request directory details for editing was not successful',
);
const RequestRecruiterDetailsOnInitAction = createAction(
  '[New Entity Popup] Request recruiter details for editing',
  props<{ id: number }>(),
);
const RequestRecruiterDetailsOnInitSuccessAction = createAction(
  '[New Entity Popup] Recruiter details saved to store',
  props<{ recruiter: RecruiterInfo }>(),
);
const RequestRecruiterDetailsOnInitFailedAction = createAction(
  '[New Entity Popup] Request recruiter details for editing was not successful',
);
const SaveNewCityFailedAction = createAction('[New Entity Popup] Saving city to DB failed');
const SaveNewCitySuccessAction = createAction(
  '[New Entity Popup] New city saved to DB',
  props<{ city: DictionaryItem & { country: number } }>(),
);
const SaveNewClientSuccessAction = createAction(
  '[New Entity Popup] New client saved to DB',
  props<{ client: ClientFull }>(),
);
const SaveNewClientFailedAction = createAction('[New Entity Popup] Saving client to DB failed');
const SaveNewCustomFieldSuccessAction = createAction(
  '[New Entity Popup] New custom field saved to DB',
);
const SaveNewCustomFieldFailedAction = createAction(
  '[New Entity Popup] Saving new custom field to DB failed',
);
const SaveNewDirectoryItemSuccessAction = createAction(
  '[New Entity Popup] New directory item saved to DB',
);
const SaveNewDirectoryItemFailedAction = createAction(
  '[New Entity Popup] Saving new directory item to DB failed',
);
const SaveNewTalentSuccessAction = createAction(
  '[New Entity Popup] New talent saved to DB',
  props<{ profile: Profile; vacancy?: number; status?: number; navigateToCreated: boolean }>(),
);
const SaveNewTalentFailedAction = createAction('[New Entity Popup] Saving talent to DB failed');
const SaveNewVacancySuccessAction = createAction(
  '[New Entity Popup] New vacancy saved to DB',
  props<{ id: number }>(),
);
const SaveNewVacancyFailedAction = createAction('[New Entity Popup] Saving vacancy to DB failed');
const SaveNewRecruiterSuccessAction = createAction('[New Entity Popup] New recruiter saved to DB');
const SaveNewRecruiterFailedAction = createAction(
  '[New Entity Popup] Saving recruiter to DB failed',
);
const UpdateCustomFieldFailedAction = createAction('[New Entity Popup] Custom field update failed');
const UpdateCustomFieldSuccessAction = createAction(
  '[New Entity Popup] Custom field successfully updated',
);
const UpdateDirectoryItemFailedAction = createAction(
  '[New Entity Popup] Directory item update failed',
);
const UpdateDirectoryItemSuccessAction = createAction(
  '[New Entity Popup] Directory item successfully updated',
);
const UpdateTalentSuccessAction = createAction(
  '[New Entity Popup] Modified talent saved to DB',
  props<{ profile: FullProfile }>(),
);
const UpdateTalentFailedAction = createAction(
  '[New Entity Popup] Saving modified talent to DB failed',
);
const UploadCvSuccessAction = createAction(
  '[New Entity Popup] File with CV successfully uploaded',
  props<{ file: AnalyzedAiCvData }>(),
);
const UploadCvFailedAction = createAction('[New Entity Popup] Uploading CV to server failed');
const UploadFileToCreatedProfileAction = createAction(
  '[New Entity Popup] Upload CV to newly created profile',
  props<{ id: string; file: File; navigateToCreated?: boolean; textContent: string }>(),
);
const UploadFileToCreatedProfileFailedAction = createAction(
  '[New Entity Popup] Error happened during uploading file',
);
const UploadFileToCreatedProfileSuccessAction = createAction(
  '[New Entity Popup] CV successfully attached to newly created profile',
);

const actions = {
  AnalyzeTitleAndDescriptionSuccess,
  AnalyzeTitleAndDescriptionFailed,
  CleanNewEntityStateAction,
  DeleteCustomFieldFailedAction,
  DeleteCustomFieldSuccessAction,
  DeleteDirectoryItemFailedAction,
  DeleteDirectoryItemSuccessAction,
  DeleteProfileFromDialogFailedAction,
  DeleteProfileFromDialogSuccessAction,
  GetShortListBoardByIdFailedAction,
  GetShortListBoardByIdSuccessAction,
  OpenClientDialogOnInitAction,
  OpenCustomFieldDialogOnInitAction,
  OpenDirectoryDialogOnInitAction,
  OpenRecruiterDialogOnInitAction,
  OpenTalentDialogOnInitAction,
  OpenVacancyDialogOnInitAction,
  RefreshProfilesListAfterCreateProfileAction,
  RefreshProfilesListAfterAddToVacancyAction,
  RemoveMatchedProfileByContactAction,
  RemoveMatchedProfileByLinkAction,
  RequestClientDetailsOnInitAction,
  RequestClientDetailsOnInitSuccessAction,
  RequestDirectoryItemDetailsOnInitAction,
  RequestDirectoryItemDetailsOnInitFailedAction,
  RequestDirectoryItemDetailsOnInitSuccessAction,
  RequestProfileContactsSuccessAction,
  RequestProfileDetailsOnInitAction,
  RequestProfileDetailsFailedAction,
  RequestProfileDetailsSuccessAction,
  RequestRecruiterDetailsOnInitAction,
  RequestRecruiterDetailsOnInitFailedAction,
  RequestRecruiterDetailsOnInitSuccessAction,
  RequestVacancyDetailsOnInitAction,
  SaveNewCityFailedAction,
  SaveNewCitySuccessAction,
  SaveNewClientFailedAction,
  SaveNewClientSuccessAction,
  SaveNewCustomFieldFailedAction,
  SaveNewCustomFieldSuccessAction,
  SaveNewDirectoryItemFailedAction,
  SaveNewDirectoryItemSuccessAction,
  SaveNewRecruiterFailedAction,
  SaveNewRecruiterSuccessAction,
  SaveNewTalentFailedAction,
  SaveNewTalentSuccessAction,
  SaveNewVacancyFailedAction,
  SaveNewVacancySuccessAction,
  UpdateCustomFieldFailedAction,
  UpdateCustomFieldSuccessAction,
  UpdateDirectoryItemFailedAction,
  UpdateDirectoryItemSuccessAction,
  UpdateTalentFailedAction,
  UpdateTalentSuccessAction,
  UploadFileToCreatedProfileAction,
  UploadFileToCreatedProfileFailedAction,
  UploadFileToCreatedProfileSuccessAction,
  UploadCvFailedAction,
  UploadCvSuccessAction,
};

const addNewEntitySystemActions = union(actions);

export type AddNewEntitySystemActionsType = typeof addNewEntitySystemActions;

export const AddNewEntitySystemActions = actions;
