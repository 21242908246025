import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { propOr } from 'ramda';

import {
  dictionaryDefaultBidStatuses,
  dictionaryDurations,
  profileIncludedSources,
  vacancyDefaultBoardNames,
  vacancyStatuses,
  vacancyWorkTypes,
} from '@app-shared/models';

@Pipe({
  name: 'translateDictionaryName',
  pure: false,
  standalone: true,
})
export class TranslateDictionaryNamePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(name: string, dictionaryName: string): Observable<string> {
    const dictionary = this.getDictionary(dictionaryName);
    const translateKey: string | null = propOr(name, name, dictionary);

    if (!translateKey) {
      return of(name);
    }

    return this.translateService.get(translateKey) as Observable<string>;
  }
  private getDictionary(dictionaryName: string) {
    switch (dictionaryName) {
      case 'bidStatus':
        return dictionaryDefaultBidStatuses;
      case 'duration':
        return dictionaryDurations;
      case 'shortList':
        return vacancyDefaultBoardNames;
      case 'source':
        return profileIncludedSources;
      case 'vacancyType':
        return vacancyWorkTypes;
      case 'vacancyStatus':
        return vacancyStatuses;
      default:
        return null;
    }
  }
}
