@if (entityForm) {
  <form class="c-tsp-mat-form" [formGroup]="entityForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.name.label</mat-label>
        <input
          matInput
          formControlName="name"
          class="c-tsp-mat-form-field-input"
          [placeholder]="'add-new-entity.new-directory.form.name.placeholder' | translate"
        />
        <mat-error>
          <span [innerHTML]="'add-new-entity.new-directory.form.name.required' | translate"></span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <div class="c-new-directory-dialog__color-select">
        <label class="c-new-directory-dialog__color-select-label" id="color-selection" translate
          >add-new-entity.new-directory.form.color-selection</label
        >
        <mat-radio-group
          aria-labelledby="color-selection"
          class="c-new-directory-dialog__color-select-column"
          formControlName="color"
        >
          <div class="c-new-directory-dialog__color-select-group">
            @for (color of candidateStagesColor; track color) {
              <mat-radio-button
                class="c-new-directory-dialog__color-select-radio-button"
                [value]="color"
              >
                <span
                  class="c-new-directory-dialog__color-select-preview"
                  [ngStyle]="{ 'background-color': color }"
                >
                </span>
              </mat-radio-button>
            }
            <span class="c-new-directory-dialog__color-picker--hint" translate
              >add-new-entity.new-directory.form.color-or</span
            >
            <mat-select
              class="c-new-directory-dialog__color-picker"
              cpOutputFormat="hex"
              [style.background]="selectedColor"
              [colorPicker]="selectedColor"
              (colorPickerChange)="entityForm.get('color')?.setValue($event)"
            >
            </mat-select>
          </div>
          @if (entityForm.get('color').touched && entityForm.get('color').invalid) {
            <mat-error class="c-new-directory-dialog__color-select-error">
              <span
                [innerHTML]="'add-new-entity.new-directory.form.color.required' | translate"
              ></span>
            </mat-error>
          }
        </mat-radio-group>
      </div>
    </div>
    <div class="c-tsp-mat-form-field__row">
      <app-directory-custom-fields
        [currentTypeField]="currentTypeField"
        [statusesCustomFields]="statusesCustomFields"
        formControlName="fieldTypes"
        (fieldExistsWarning)="showWarningMessage.emit($event)"
      >
      </app-directory-custom-fields>
    </div>
  </form>
}
