<form class="c-tsp-mat-form" [formGroup]="form" novalidate>
  <div class="c-tsp-mat-form-field__row">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
      floatLabel="always"
    >
      <mat-select
        formControlName="reasonRejectId"
        [placeholder]="'profile-short-list.short-list-reject-dialog.reason.placeholder' | translate"
      >
        @for (reason of rejectReasons; track reason) {
          <mat-option [value]="reason.id" [title]="reason.name">
            {{ reason.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>
