<div class="c-profile-placeholder">
  <div class="c-profile-placeholder__personal">
    <div class="c-profile-placeholder__personal-info">
      <div class="c-profile-placeholder__personal-info-image">
      </div>
      <div class="c-profile-placeholder__personal-info-main">
        <div class="c-profile-placeholder__personal-info-main-content"></div>
        <div class="c-profile-placeholder__personal-info-main-content"></div>
        <div class="c-profile-placeholder__personal-info-main-content"></div>
      </div>
    </div>
    <div class="c-profile-placeholder__counts"></div>
  </div>
  <div class="c-profile-placeholder__professional">
    <div class="c-profile-placeholder__professional-block">
      <span class="c-profile-placeholder__professional-block-title"></span>
      <span class="c-profile-placeholder__professional-block-tag c-profile-placeholder__professional-block-tag--short"></span>
      <span class="c-profile-placeholder__professional-block-tag c-profile-placeholder__professional-block-tag--short"></span>
    </div>
    <div class="c-profile-placeholder__professional-block">
      <span class="c-profile-placeholder__professional-block-title"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
    </div>
    <div class="c-profile-placeholder__professional-block">
      <span class="c-profile-placeholder__professional-block-title"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
      <span class="c-profile-placeholder__professional-block-tag"></span>
    </div>
    <div class="c-profile-placeholder__professional-block">
      <span class="c-profile-placeholder__professional-block-title"></span>
      <span class="c-profile-placeholder__professional-block-tag c-profile-placeholder__professional-block-tag--long"></span>
    </div>
  </div>
</div>
