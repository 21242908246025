import { Pipe, PipeTransform } from '@angular/core';
import { is, isNil } from 'ramda';

@Pipe({
  name: 'castToString',
  standalone: true,
})
export class CastToStringPipe implements PipeTransform {
  public transform(value: unknown): string {
    if (isNil(value)) {
      return '';
    }
    if (is(String, value)) {
      return value;
    }
    if (is(Date, value)) {
      return (value as unknown as Date).toUTCString();
    }
    if (is(Object, value)) {
      return JSON.stringify(value as Record<string, unknown>);
    }
    return JSON.stringify(value);
  }
}
