<div class="c-post-login-questions">
  <div class="c-post-login-questions__container">
    <div class="c-post-login-questions__header">
      <div class="c-post-login-questions__header-logo">
        <img src="../../../../../assets/logo-with-name.png" alt="logo" />
      </div>
    </div>
    <div
      class="c-post-login-questions__content"
      [ngClass]="{ 'c-post-login-questions__content--centralized': isLastStep }"
    >
      <div *ngIf="!isLastStep; then greetingTitle; else doneTitle"></div>
      <ng-template #greetingTitle>
        <div class="c-post-login-questions__greeting">
          <div class="c-post-login-questions__greeting-title">
            <img
              src="../../../../../assets/lobby/smiling-face-with-smiling-eyes.png"
              alt="Smiling face"
            />
            <span
              >{{ 'lobby.post-login.greeting-title' | translate
              }}{{ (userName$ | async) ? ', ' + (userName$ | async) : '' }}</span
            >
          </div>
          <div class="c-post-login-questions__greeting-subtitle" translate>
            lobby.post-login.greeting-subtitle
          </div>
        </div>
      </ng-template>
      <ng-template #doneTitle>
        <div class="c-post-login-questions__done">
          <div class="c-post-login-questions__done-title">
            <span translate>lobby.post-login.done-title</span>
            <img src="../../../../../assets/lobby/party-popper.png" alt="Party popper" />
          </div>
          <div class="c-post-login-questions__done-subtitle" translate>
            lobby.post-login.done-subtitle
          </div>
        </div>
      </ng-template>
      <div class="c-post-login-questions__questionnaire">
        <mat-stepper
          class="c-post-login-questions-stepper"
          #stepper
          (selectionChange)="checkIsLastPage($event)"
        >
          <div *ngFor="let step of steps; index as i; last as last">
            <mat-step [stepControl]="form" [optional]="false">
              <form
                class="c-post-login-questions-stepper__form"
                [formGroup]="form"
                (ngSubmit)="submit()"
              >
                <div class="c-post-login-questions-stepper__question">
                  <p class="c-post-login-questions-stepper__question-text">
                    {{ getTranslateKey(step, 'question') | translate }}
                  </p>
                </div>

                <div *ngIf="step.type === 'select'" class="c-post-login-questions-stepper__options">
                  <mat-form-field appearance="fill" class="c-post-login-questions-stepper__select">
                    <mat-select
                      #select
                      panelClass="c-tsp-mat-select-panel-questions"
                      [formControlName]="step.question"
                      [placeholder]="'lobby.post-login.choice-answer' | translate"
                    >
                      <mat-option
                        *ngFor="let option of step.options; index as i"
                        class="c-post-login-questions-stepper__select-option"
                        [value]="option"
                      >
                        {{ getTranslateKey(step, 'options', i) | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  *ngIf="step.type === 'multiselect'"
                  class="c-post-login-questions-stepper__options"
                >
                  <mat-button-toggle-group
                    class="c-post-login-questions-stepper__multiselect"
                    [hideSingleSelectionIndicator]="true"
                    [hideMultipleSelectionIndicator]="true"
                    [formControlName]="step.question"
                    multiple
                  >
                    <div *ngFor="let option of step.options; index as i">
                      <mat-button-toggle
                        class="c-post-login-questions-stepper__multiselect-option"
                        [value]="option"
                      >
                        {{ getTranslateKey(step, 'options', i) | translate }}
                      </mat-button-toggle>
                    </div>
                  </mat-button-toggle-group>
                </div>
                <div *ngIf="step.type === 'text'" class="c-post-login-questions-stepper__text">
                  <mat-form-field
                    class="c-post-login-questions-stepper__text-field"
                    floatLabel="auto"
                  >
                    <input
                      matInput
                      [formControlName]="step.question"
                      type="text"
                      [placeholder]="'lobby.post-login.text.input-placeholder' | translate"
                    />
                  </mat-form-field>
                </div>

                <div class="c-post-login-questions-stepper__footer">
                  <button
                    class="c-post-login-questions-stepper__footer-continue-button"
                    [ngClass]="{
                      'c-post-login-questions-stepper__footer-continue-button--disabled':
                        !isControlValid(step.question)
                    }"
                    [disabled]="!isControlValid(step.question)"
                    type="button"
                    matStepperNext
                    translate
                  >
                    lobby.post-login.continue
                  </button>
                  <p class="c-post-login-questions-stepper__footer-page-counter">
                    {{ i + 1 }}/{{ steps.length }}
                  </p>
                </div>
              </form>
            </mat-step>
          </div>

          <mat-step [formGroup]="form">
            <div class="c-post-login-questions-stepper__footer">
              <button
                class="c-post-login-questions-stepper__footer-continue-button"
                type="submit"
                (click)="submit()"
                translate
              >
                lobby.post-login.start-trial
              </button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>
