import { Pipe, PipeTransform } from '@angular/core';
import { allPass, is, isEmpty, isNil } from 'ramda';

@Pipe({
  name: 'castToNumber',
  standalone: true,
})
export class CastToNumberPipe implements PipeTransform {
  transform(value: unknown): number | null {
    if (isNil(value)) {
      return 0;
    }
    if (is(Number, value)) {
      return value;
    }
    if (is(String, value)) {
      return parseInt(value, 10);
    }
    if (value === true) {
      return 1;
    }
    if (value === false) {
      return 0;
    }
    if (is(Date, value)) {
      const date = value as unknown as Date;

      return date.getTime();
    }
    if (allPass([is(Array), isEmpty])(value as unknown[])) {
      return 0;
    }
    if (is(Array, value as unknown[])) {
      const firstValue: string | number = value[0];

      return is(String, firstValue) ? parseInt(firstValue, 10) : firstValue;
    }
    if (allPass([is(Object), isEmpty])(value as Record<string, unknown>)) {
      return 0;
    }
    return 0;
  }
}
