import {
  Comment,
  DateRange,
  DictionaryItem,
  DictionaryShortList,
  FilePayload,
  FileType,
  HistoryList,
  MatchProfileResponse,
  ShortList,
  ShortListCreateResponse,
  ShortListUpdateResponse,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddCommentSuccessfulAction = createAction(
  '[Profile Modification Effects | Profiles API] Added comment to profile',
  props<{ comment: Comment }>(),
);
const AddCommentFailedAction = createAction(
  '[Profile Modification Effects | Profiles API] Add comment to profile fail',
);
const CreateShortListFromProfileFailedAction = createAction(
  '[Profile Modification Effects | Shortlist API] Error happened during creating the short list',
);
const CreateShortListFromProfileSuccessAction = createAction(
  '[Profile Modification Effects | Shortlist API] Short list was successfully created',
  props<{
    shortList: ShortListCreateResponse;
    statuses: DictionaryShortList[];
  }>(),
);
const DeleteCommentSuccessfulAction = createAction(
  '[Profile Modification Effects | Profiles API] Delete comment from profile',
  props<{ id: number }>(),
);
const DeleteCommentFailedAction = createAction(
  '[Profile Modification Effects | Profile Modification Effects |Profiles API] Delete comment from profile fail',
);
const DeleteFileFailedAction = createAction(
  '[Profile Modification Effects | Profiles API] Deleting file failed',
);
const DeleteFileSuccessAction = createAction(
  '[Profile Modification Effects | Profiles API] Deleting file succeed',
  props<{ devId: string; fileId: number; fileType: FileType }>(),
);
const GetProfileHistoryAction = createAction(
  '[Profile Modification Effects] Get profile history action',
  props<{
    query: DateRange;
    id: string;
    actionSource: 'details' | 'sidepanel';
  }>(),
);
const GetProfileHistoryFailedAction = createAction(
  '[Profile Modification Effects | History Events API] Error happened during retrieving history events',
);
const GetProfileHistorySuccessAction = createAction(
  '[Profile Modification Effects | History Events API] Profile history events saved to store',
  props<{ events: HistoryList; actionSource: 'details' | 'sidepanel' }>(),
);
const MatchProfileDataAction = createAction(
  '[Profile Modification Effects] Match profile data',
  props<{ isMatch: boolean; id: string; publicProfileId: string }>(),
);
const MatchProfileDataFailedAction = createAction(
  '[Profile Modification Effects] Error happened during matching profile data',
);
const MatchProfileDataSuccessAction = createAction(
  '[Profile Modification Effects] Profile data was successfully matched',
  props<{ data: MatchProfileResponse; id: string }>(),
);
const MergeProfileDuplicatesAction = createAction(
  '[Profile Modification Effects] Merge profile duplicates',
  props<{ primary: string; duplicates: string[] }>(),
);
const MergeProfileDuplicatesFailedAction = createAction(
  '[Profile Modification Effects] Error happened during merging profile duplicates',
);
const MergeProfileDuplicatesSuccessAction = createAction(
  '[Profile Modification Effects] All profile duplicates were successfully merged',
  props<{ id: string }>(),
);
const RemoveFromShortListFailedAction = createAction(
  '[Profile Modification Effects | Shortlist API] Error happened during removing profile from short list',
);
const RemoveFromShortListSuccessAction = createAction(
  '[Profile Modification Effects | Shortlist API] Profile successfully removed from short list',
  props<{ id: number }>(),
);
const ResendShortlistForSecurityCheckFailedAction = createAction(
  '[Profile Modification Effects | Shortlist API] Resend short list for security check failed',
);
const ResendShortListForSecurityCheckSuccessAction = createAction(
  '[Profile Modification Effects | Shortlist API] Resend short list for security check success',
);
const UpdateShortListFailedAction = createAction(
  '[Profile Modification Effects | Shortlist API] Error happened during updating short list',
  props<{ shortList: Partial<ShortList> }>(),
);
const UpdateShortListSuccessAction = createAction(
  '[Profile Modification Effects | Shortlist API] Short list successfully updated',
  props<{
    shortList: ShortListUpdateResponse;
    vacancyId?: number;
  }>(),
);
const UpdateTagsAction = createAction(
  '[Profile Details | Profile Sidepanel] Edit profile tags list',
  props<{
    devId: string;
    data: number[];
    currentTab: string;
    actionSource: 'sidepanel' | 'details';
  }>(),
);
const UpdateTagsFailedAction = createAction(
  '[Profile Modification Effects | Profile API] Profile tags list updated successfully',
);
const UpdateTagsSuccessAction = createAction(
  '[Profile Modification Effects | Profile API] Profile tags list updated successfully',
  props<{ data: DictionaryItem[] }>(),
);
const UploadFileFailedAction = createAction(
  '[Profile Modification Effects | Profile API] Uploading file to server failed',
);
const UploadFileSuccessAction = createAction(
  '[Profile Modification Effects | Profile API] File successfully uploaded',
  props<{ file: FilePayload; id: string | number; fileType: FileType }>(),
);

const actions = {
  AddCommentSuccessfulAction,
  AddCommentFailedAction,
  CreateShortListFromProfileFailedAction,
  CreateShortListFromProfileSuccessAction,
  DeleteCommentSuccessfulAction,
  DeleteCommentFailedAction,
  DeleteFileFailedAction,
  DeleteFileSuccessAction,
  GetProfileHistoryAction,
  GetProfileHistoryFailedAction,
  GetProfileHistorySuccessAction,
  MatchProfileDataAction,
  MatchProfileDataFailedAction,
  MatchProfileDataSuccessAction,
  MergeProfileDuplicatesAction,
  MergeProfileDuplicatesFailedAction,
  MergeProfileDuplicatesSuccessAction,
  RemoveFromShortListFailedAction,
  RemoveFromShortListSuccessAction,
  ResendShortlistForSecurityCheckFailedAction,
  ResendShortListForSecurityCheckSuccessAction,
  UpdateShortListFailedAction,
  UpdateShortListSuccessAction,
  UpdateTagsAction,
  UpdateTagsFailedAction,
  UpdateTagsSuccessAction,
  UploadFileFailedAction,
  UploadFileSuccessAction,
};
const profileModificationActions = union(actions);

export type ProfileModificationActionsType = typeof profileModificationActions;

export const ProfileModificationActions = actions;
