<div class="c-profile-skill-element">
  <span
    class="c-profile-skill-element__skill"
    [ngClass]="{
      'c-profile-skill-element__skill--highlighted': skill.isHighlighted,
      'c-profile-skill-element__skill--main': skill.main,
    }"
    [matTooltip]="showTooltip ? (tooltipForSkill | translate) : ''"
  >
    <span class="c-profile-skill-element__label">
      {{ skillName }}
    </span>
    @if (skillSource) {
      <div [matTooltip]="sourceTooltip | titlecase">
        @switch (skillSource) {
          @case ('rabota_ua') {
            <span class="c-profile-skill-element__source rabota_ua"> </span>
          }
          @case ('work_ua') {
            <span class="c-profile-skill-element__source work_ua"> </span>
          }
          @case ('djinni') {
            <span class="c-profile-skill-element__source djinni"> </span>
          }
          @case ('other') {
            <span class="c-profile-skill-element__source">
              <i class="fas fa-globe" aria-hidden="true"></i>
            </span>
          }
          @default {
            <span class="c-profile-skill-element__source">
              <i class="fab" [ngClass]="'fa-' + skillSource" aria-hidden="true"></i>
            </span>
          }
        }
      </div>
    }
    @if (sourcesLength > 1) {
      <span class="c-profile-skill-element__source-count"> +{{ sourcesLength - 1 }} </span>
    }
  </span>
</div>
