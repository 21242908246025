import { Routes } from '@angular/router';
import { customFieldsResolver } from '@app-core/custom-fields/resolvers/custom-fields.resolve';
import { TalentFormTabs } from '@app-shared/models';
import { isKernelEnv } from '@app-shared/reducers/router/router.reducer';
import {
  candidateStatusesCustomFieldsResolver,
  departmentsResolver,
  dictionariesResolver,
  formConfigsResolver,
  recruitersResolver,
  shortListSourcesResolver,
  shortListStatusesResolver,
  vacanciesAddressListResolver,
  vacanciesSimpleListResolver,
} from '@app-shared/resolvers';
import { AddNewEntityPopupComponent } from './components';
import {
  NewEntityClientEffects,
  NewEntityDirectoryEffects,
  NewEntityEffects,
  NewEntityProfileEffects,
  NewEntityRecruiterEffects,
  NewEntityVacancyEffects,
} from './effects';
import { shortListBoardsResolver } from './resolvers/short-list-boards/short-list-boards.resolve';

const kernelResolvers = isKernelEnv
  ? {
      vacancy: {
        addressList: vacanciesAddressListResolver,
      },
    }
  : { vacancy: {} };

export const routes: Routes = [
  {
    path: 'talent',
    data: {
      type: 'talent',
      dictionaryResolver: 'newTalent',
    },
    resolve: {
      dictionaries: dictionariesResolver,
      shortListStatuses: shortListStatusesResolver,
      shortListSources: shortListSourcesResolver,
      customFields: customFieldsResolver,
      vacanciesSimpleList: vacanciesSimpleListResolver,
    },
    children: [
      { path: ':template', component: AddNewEntityPopupComponent, children: [] },
      { path: '', pathMatch: 'full', redirectTo: TalentFormTabs.INITIAL },
    ],
  },
  {
    path: 'simpleTalent',
    data: {
      type: 'talent',
      dictionaryResolver: 'newTalent',
    },
    component: AddNewEntityPopupComponent,
    resolve: {
      dictionaries: dictionariesResolver,
      shortListStatuses: shortListStatusesResolver,
      vacanciesSimpleList: vacanciesSimpleListResolver,
    },
  },
  {
    path: 'client',
    data: {
      type: 'client',
      dictionaryResolver: 'newClient',
    },
    component: AddNewEntityPopupComponent,
    resolve: {
      dictionaries: dictionariesResolver,
      customFields: customFieldsResolver,
    },
  },
  {
    path: 'vacancy',
    data: {
      type: 'vacancy',
      dictionaryResolver: 'newVacancy',
    },
    component: AddNewEntityPopupComponent,
    resolve: {
      dictionaries: dictionariesResolver,
      departments: departmentsResolver,
      recruiters: recruitersResolver,
      shortListBoards: shortListBoardsResolver,
      customFields: customFieldsResolver,
      formConfigs: formConfigsResolver,
      ...kernelResolvers.vacancy,
    },
  },
  {
    path: 'custom-field',
    data: {
      type: 'custom-field',
      dictionaryResolver: 'newDirectory',
    },
    component: AddNewEntityPopupComponent,
    resolve: {
      dictionaries: dictionariesResolver,
    },
  },
  {
    path: 'directory',
    data: {
      type: 'directory',
      dictionaryResolver: 'newDirectory',
    },
    component: AddNewEntityPopupComponent,
    resolve: {
      dictionaries: dictionariesResolver,
      statusesCustomFields: candidateStatusesCustomFieldsResolver,
    },
  },
  {
    path: 'recruiter',
    data: {
      type: 'recruiter',
      dictionaryResolver: 'newRecruiter',
    },
    component: AddNewEntityPopupComponent,
    resolve: {
      customFields: customFieldsResolver,
      departments: departmentsResolver,
      recruiters: recruitersResolver,
    },
  },
];

export const newEntityEffects = [
  NewEntityClientEffects,
  NewEntityDirectoryEffects,
  NewEntityEffects,
  NewEntityProfileEffects,
  NewEntityRecruiterEffects,
  NewEntityVacancyEffects,
];

export { newEntityReducer } from './reducers/new-entity.reducer';
