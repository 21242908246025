import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEditableItem, DictionaryRejectReason } from '@app-shared/models';

@Pipe({
  name: 'castToRejectReason',
  standalone: true,
})
export class CastToRejectReasonPipe implements PipeTransform {
  public transform(value: DictionaryEditableItem): DictionaryRejectReason {
    return value as DictionaryRejectReason;
  }
}
