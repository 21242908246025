import { Params } from '@angular/router';
import { AuthSystemActions } from '@app-core/auth/actions/auth.system.actions';
import { AuthUserActions } from '@app-core/auth/actions/auth.user.actions';
import { SetLocalizationFromUrl } from '@app-core/auth/resolvers/localization/localization-resolver.actions';
import {
  AuthForms,
  Locales,
  NewcomerData,
  SubscriptionPackagesEnum,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { isNil, mergeRight, pick, test } from 'ramda';

export interface AuthState {
  currentForm: AuthForms | null;
  errorMessage?: string | null;
  newcomerData?: NewcomerData;
  isAuthorized: boolean | null;
  isCookiesAccepted: boolean | null;
  isLoading: boolean;
  locale: Locales;
  passwordToken?: string;
  pricePackage?: SubscriptionPackagesEnum;
  queryParams?: Params;
  refresh_token: string | null;
  requestedUrl?: string | null;
  showSuccessMessage: boolean;
  token: string | null;
  useSessionStorage: boolean;
}

export const initialState: AuthState = {
  currentForm: AuthForms.LOGIN,
  isAuthorized: null,
  isCookiesAccepted: null,
  isLoading: false,
  locale: 'uk',
  refresh_token: null,
  showSuccessMessage: false,
  token: null,
  useSessionStorage: true,
};

const reducer = createReducer(
  initialState,
  on(AuthUserActions.ChangeCurrentFormAction, (state, { form }) =>
    mergeRight(state, { currentForm: form, showSuccessMessage: false }),
  ),
  on(AuthSystemActions.CleanAuthStateAction, (state, { form }) => {
    return mergeRight(state, { currentForm: form });
  }),
  on(AuthUserActions.ChangeFadedBackgroundStateAction, (state, { isFade }) =>
    mergeRight(state, {
      newcomerData: { fadeBackground: isFade },
    }),
  ),
  on(
    AuthSystemActions.LoginFailedAction,
    AuthSystemActions.RegistrationFailedAction,
    (state, { message, status }) =>
      mergeRight(state, {
        isAuthorized: false,
        isLoading: false,
        errorMessage: test(/^4/, `${status}`) ? message : null,
      }),
  ),
  on(AuthSystemActions.LoginSuccessAction, (state, { tokens }) =>
    mergeRight(state, {
      isAuthorized: true,
      isLoading: false,
      token: tokens.token,
      refresh_token: tokens.refresh_token,
      useSessionStorage: tokens.useSessionStorage,
    }),
  ),
  on(AuthUserActions.LogoutAction, (state) => mergeRight(state, { isAuthorized: false })),
  on(
    AuthSystemActions.RefreshTokenAfterChangeSpaceSuccessAction,
    AuthSystemActions.UpdateTokensAction,
    (state, { tokens }) =>
      mergeRight(state, {
        token: tokens.token,
        refresh_token: tokens.refresh_token,
      }),
  ),
  on(
    AuthSystemActions.RegistrationSuccessAction,
    AuthSystemActions.ResetPasswordFailedAction,
    AuthSystemActions.RequestPasswordRestoreFailedAction,
    AuthSystemActions.RequestPasswordRestoreSuccessAction,
    (state) => mergeRight(state, { isLoading: false }),
  ),
  on(AuthSystemActions.ResetPasswordSuccessAction, (state) =>
    mergeRight(state, { isLoading: false, showSuccessMessage: true }),
  ),
  on(AuthSystemActions.RestoreTokenFromStorageAction, (state, { tokens }) => {
    const { token, refresh_token, useSessionStorage } = tokens;
    const isAuthorized = !isNil(token);
    const authState = isAuthorized
      ? { isAuthorized, token, refresh_token, useSessionStorage }
      : { isAuthorized };
    return mergeRight(state, authState as Partial<AuthState>) as AuthState;
  }),
  on(AuthSystemActions.SaveRequestedUrlAction, (state, { url }) =>
    mergeRight(state, { requestedUrl: url }),
  ),
  on(AuthSystemActions.SetCookiesAcceptanceStateAction, (state, action) =>
    mergeRight(state, { isCookiesAccepted: action.state }),
  ),
  on(AuthSystemActions.SetInitialLoginStateAction, (state, { email, referUrl, fadeBackground }) => {
    const newcomerData = {
      email: email || null,
      fadeBackground: fadeBackground || null,
    };
    return mergeRight(state, {
      currentForm: AuthForms.LOGIN,
      errorMessage: null,
      requestedUrl: referUrl || null,
      newcomerData,
      showSuccessMessage: !!email,
    });
  }),
  on(
    AuthSystemActions.SetInitialRegistrationStateAction,
    (state, { registrationRef, pricePackage, email, phone, fullname, fadeBackground }) => {
      const isFromPlugin = registrationRef === 'plugin_install';
      const plan = pricePackage || SubscriptionPackagesEnum.team;

      const newcomerData: NewcomerData = {
        fadeBackground: fadeBackground || null,
        fullName: fullname || null,
        email: email || null,
        phone: phone || null,
        isFromPlugin,
      };
      return mergeRight(state, {
        currentForm: AuthForms.REGISTRATION,
        errorMessage: null,
        pricePackage: plan,
        newcomerData,
      });
    },
  ),
  on(AuthSystemActions.SetInitialPackagesStateAction, (state, { form }) => {
    return mergeRight(state, {
      currentForm: form,
    });
  }),
  on(AuthSystemActions.SetInitialResetPasswordStateAction, (state, { email, fadeBackground }) =>
    mergeRight(state, {
      currentForm: AuthForms.RESET_PASSWORD,
      newcomerData: { email, fadeBackground },
    }),
  ),
  on(AuthSystemActions.SetInitialRestorePasswordStateAction, (state, { passwordToken, email }) =>
    mergeRight(state, {
      currentForm: AuthForms.RESTORE_PASSWORD,
      passwordToken,
      newcomerData: { fadeBackground: true, email: email || null },
    }),
  ),
  on(AuthSystemActions.SetInitialInviteRecruiterStateAction, (state, { passwordToken, email }) =>
    mergeRight(state, {
      currentForm: AuthForms.INVITE_RECRUITER,
      passwordToken,
      newcomerData: { fadeBackground: true, email: email || null },
    }),
  ),
  on(AuthSystemActions.SetInitialTutorialStateAction, (state) =>
    mergeRight(state, { currentForm: null }),
  ),
  on(SetLocalizationFromUrl, (state, { locale }) => mergeRight(state, { locale })),
  on(
    AuthUserActions.TryLoginAction,
    AuthUserActions.TryPasswordRestoreAction,
    AuthUserActions.TryRegistrationAction,
    AuthUserActions.TryResetPasswordAction,
    (state) => mergeRight(state, { isLoading: true }),
  ),
);

export function authReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}

export const selectAuthStore = (state: State) => state.auth;
export const getAuthState = createSelector(selectAuthStore, (s) => s.isAuthorized);
export const getNewcomerData = createSelector(selectAuthStore, (s) => s.newcomerData || null);
export const showSuccessMessage = createSelector(selectAuthStore, (s) => s.showSuccessMessage);
export const getCurrentForm = createSelector(selectAuthStore, (s) => s.currentForm);
export const getAuthLoadingState = createSelector(selectAuthStore, (s) => s.isLoading);
export const getRefreshToken = createSelector(selectAuthStore, (s) => s.refresh_token);
export const getPasswordToken = createSelector(selectAuthStore, (s) => s.passwordToken || null);

export const getTokens = createSelector(
  selectAuthStore,
  pick(['token', 'refresh_token']) as UnaryOperator<
    AuthState,
    { token: string; refresh_token: string }
  >,
);
export const getRequestedUrl = createSelector(selectAuthStore, (s) => s.requestedUrl || null);
export const isUseSessionStorage = createSelector(selectAuthStore, (s) => s.useSessionStorage);
export const getPricePackage = createSelector(selectAuthStore, (s) => s.pricePackage || null);
export const getLandingPageLocale = createSelector(selectAuthStore, (s) => s.locale);
export const getErrorMessage = createSelector(selectAuthStore, (s) => s.errorMessage);
export const getIsCookiesAccepted = createSelector(selectAuthStore, (s) => s.isCookiesAccepted);
