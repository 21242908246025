export interface Metadata {
  availableFilters: {
    [key: string]: string[];
  };
  availableSortings: {
    [key: string]: string[];
  };
}

export type Locales = 'en' | 'ru' | 'uk';
