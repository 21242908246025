import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { environment } from '@app-environment/environment';
import { isNil, reduce, test } from 'ramda';
import { Observable } from 'rxjs';

const removeEmptyParams = (req: HttpRequest<unknown>, param: string): HttpRequest<unknown> => {
  if (isNil(req.params.get(param))) {
    return req.clone({
      params: req.params.delete(param),
    });
  }
  return req;
};

export const urlInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const isLocalRequest = test(/assets|chrome-extension/g, req.url);

  if (isLocalRequest) {
    return next(req);
  }

  const modifiedRequest = req.clone({
    url: `${environment.apiUrl}${req.url}`,
  });

  const pureRequest = reduce(
    (request, param) => removeEmptyParams(request, param),
    modifiedRequest,
    modifiedRequest.params.keys(),
  );

  return next(pureRequest);
};
