import {
  AuthForms,
  AuthPayload,
  AuthResponse,
  RegistrationRefererType,
  SubscriptionPackagesEnum,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

export const CheckInvitationTokenAction = createAction(
  '[Auth] Check invitation token if it still valid',
  props<{ token: string; email: string }>(),
);

export const CheckTokenIntoStorageAction = createAction(
  '[Auth] Check stored tokens into session or local storage',
);
export const CleanAuthStateAction = createAction(
  '[Auth] Clean auth state',
  props<{ form: AuthForms }>(),
);
export const GetCookiesAcceptanceStateAction = createAction(
  '[Auth] Get is cookies accepted from local storage',
);
export const InjectGoogleTagManagerAction = createAction(
  '[Auth] Inject Google Tag Manager module on login page',
);
export const LoginFailedAction = createAction(
  '[Auth] Error happened during login to system',
  props<{ message?: string; status?: number }>(),
);
export const LoginSuccessAction = createAction(
  '[Auth] User logged to system successfully',
  props<{ tokens: AuthResponse; path?: string }>(),
);
export const RefreshTokenAfterLoadAction = createAction(
  '[Auth] Refresh token after restoring from storage',
  props<{ refreshToken: string }>(),
);
export const RefreshTokenAfterChangeSpaceFailedAction = createAction(
  '[Auth] Error happened during refreshing token after changing space',
);
export const RefreshTokenAfterChangeSpaceSuccessAction = createAction(
  '[Auth] New token retrieved from the server',
  props<{ tokens: AuthResponse }>(),
);
export const RegistrationFailedAction = createAction(
  '[Auth] Error happened during registration to system',
  props<{ message?: string; status?: number }>(),
);
export const RegistrationSuccessAction = createAction(
  '[Auth] User registered to system successfully',
);
export const ReloadPageAfterChangeSpaceSuccessAction = createAction(
  '[Auth] Reload app after changing the space',
);
export const ResetPasswordFailedAction = createAction(
  '[Auth] Error happened during reset password',
);
export const ResetPasswordSuccessAction = createAction('[Auth] User reset password successfully');
export const RestoreTokenFromStorageAction = createAction(
  '[Auth] Restore tokens from storage',
  props<{ tokens: AuthResponse }>(),
);
export const RequestPasswordRestoreFailedAction = createAction(
  '[Auth] Error happened during requesting password restore',
);
export const RequestPasswordRestoreSuccessAction = createAction('[Auth] Password restore complete');
export const SaveRequestedUrlAction = createAction(
  '[Auth] Save requested url before redirecting to login page',
  props<{ url: string }>(),
);
export const SetCookiesAcceptanceStateAction = createAction(
  '[Auth] Store cookies acceptance state local storage',
  props<{ state: boolean | null }>(),
);
export const SetInitialLoginStateAction = createAction(
  '[Auth] Set initial state for login page',
  props<{ email?: string | null; referUrl?: string; fadeBackground?: boolean }>(),
);
export const SetInitialPackagesStateAction = createAction(
  '[Auth] Set initial state for packages page',
  props<{
    email?: string;
    form: AuthForms;
    phone?: string;
    fullname?: string;
  }>(),
);
export const SetInitialRegistrationStateAction = createAction(
  '[Auth] Set initial state for registration page',
  props<{
    registrationRef?: RegistrationRefererType;
    pricePackage?: SubscriptionPackagesEnum;
    email?: string;
    phone?: string;
    fullname?: string;
    fadeBackground?: boolean;
  }>(),
);
export const SetInitialResetPasswordStateAction = createAction(
  '[Auth] Set initial state for resetting password page',
  props<{ email: string; fadeBackground?: boolean }>(),
);
export const SetInitialRestorePasswordStateAction = createAction(
  '[Auth] Set initial state for restore password page',
  props<{ passwordToken: string; email?: string }>(),
);
export const SetInitialInviteRecruiterStateAction = createAction(
  '[Auth] Set initial state for invite recruiter page',
  props<{ passwordToken: string; email?: string }>(),
);
export const SetInitialTutorialStateAction = createAction(
  '[Auth] Set initial state for tutorial page',
);
export const TryLoginAfterPasswordRestoreAction = createAction(
  '[Auth] Try to login after password restore',
  props<{ data: AuthPayload }>(),
);
export const UpdateTokensAction = createAction(
  '[Auth] Update tokens after refresh',
  props<{ tokens: AuthResponse }>(),
);

const actions = {
  CheckInvitationTokenAction,
  SetCookiesAcceptanceStateAction,
  CheckTokenIntoStorageAction,
  CleanAuthStateAction,
  GetCookiesAcceptanceStateAction,
  InjectGoogleTagManagerAction,
  LoginFailedAction,
  LoginSuccessAction,
  RefreshTokenAfterLoadAction,
  RefreshTokenAfterChangeSpaceFailedAction,
  RefreshTokenAfterChangeSpaceSuccessAction,
  RegistrationFailedAction,
  RegistrationSuccessAction,
  ReloadPageAfterChangeSpaceSuccessAction,
  ResetPasswordFailedAction,
  ResetPasswordSuccessAction,
  RestoreTokenFromStorageAction,
  RequestPasswordRestoreFailedAction,
  RequestPasswordRestoreSuccessAction,
  SaveRequestedUrlAction,
  SetInitialLoginStateAction,
  SetInitialPackagesStateAction,
  SetInitialRegistrationStateAction,
  SetInitialResetPasswordStateAction,
  SetInitialRestorePasswordStateAction,
  SetInitialInviteRecruiterStateAction,
  SetInitialTutorialStateAction,
  TryLoginAfterPasswordRestoreAction,
  UpdateTokensAction,
};

const authSystemActions = union(actions);

export type AuthSystemActionsType = typeof authSystemActions;

export const AuthSystemActions = actions;
