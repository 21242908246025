import { DictionaryDepartment } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const GetDepartmentAction = createAction('[Departments Resolver] Request list of department');
const GetDepartmentFailedAction = createAction(
  '[Departments Resolver] Request list of department had failed',
);
const GetDepartmentSuccessAction = createAction(
  '[Departments Resolver] Departments stored in the state',
  props<{ departments: DictionaryDepartment[] }>(),
);

const actions = {
  GetDepartmentAction,
  GetDepartmentFailedAction,
  GetDepartmentSuccessAction,
};

const departmentsResolveActions = union(actions);

export type DepartmentsResolveActionsType = typeof departmentsResolveActions;

export const DepartmentsResolveActions = actions;
