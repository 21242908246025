import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [MatButton, NgClass, MatTooltip, TranslateModule],
  selector: 'app-trello-button',
  templateUrl: './trello-button.component.html',
  styleUrls: ['./trello-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TrelloButtonComponent {
  @Input()
  public showOnlyIcon = false;
  @Output()
  public onClick = new EventEmitter<void>();
}
