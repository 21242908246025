import { DictionariesEnum, Locales } from '.';

type FormTargets = 'vacancyDialog' | 'profileDialog';

export interface CustomForm {
  tabs: CustomDialogTab[];
  target: FormTargets;
}
export interface CustomDialogTab {
  controls: CustomFormControl[];
  index: number;
  name: { [key in Locales]: string };
}
export interface CustomFormControl {
  fields?: CustomFormFieldSettings[];
  name: string;
  position: number;
  readonly?: boolean;
  width: string;
}

export interface CustomFormFieldSettings {
  id: number;
  dictionaryName?:
    | DictionariesEnum.businesses
    | DictionariesEnum.securityGroups
    | DictionariesEnum.vacancyCategories;
  fieldType?: 'selector' | 'richEditor' | 'textarea';
  position: number;
  required?: boolean;
  requiredForPublic?: boolean;
  readonly?: boolean;
  width: string;
}
