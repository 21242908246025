import { NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { getProfileDataSourceName } from '@app-shared/functions/utilities/utilities';
import { ProfileExternalDataSources, Skill } from '@app-shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { length, path } from 'ramda';

@Component({
  imports: [MatTooltip, NgClass, TitleCasePipe, TranslateModule],
  selector: 'profile-skill-element',
  templateUrl: './profile-skill-element.component.html',
  styleUrls: ['./profile-skill-element.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileSkillElementComponent {
  @Input()
  public profileId: string;
  @Input()
  public skill: Skill;
  @Input()
  public skillName: string;
  @Input()
  public showTooltip = true;

  public get tooltipForSkill(): string {
    if (this.skill.main && this.skill.isHighlighted) {
      return 'profiles-common.profile-skill-element.tooltip.match-skill-and-search-request';
    }
    if (this.skill.main && this.skill.weight >= 0.1) {
      return 'profiles-common.profile-skill-element.tooltip.found-evidence';
    }
    if (this.skill.main) {
      return 'profiles-common.profile-skill-element.tooltip.match-main-skill';
    }
    if (this.skill.isHighlighted) {
      return 'profiles-common.profile-skill-element.tooltip.match-search-request';
    }
    if (this.skill.weight >= 0.1) {
      return 'profiles-common.profile-skill-element.tooltip.found-confirmation';
    }
    return '';
  }
  public get skillSource(): string {
    const source: string = path(['sources', '0'], this.skill);
    return source === 'stackoverflow' ? 'stack-overflow' : source;
  }
  public get sourceTooltip(): string {
    const source = path(['sources', '0'], this.skill);
    const name = getProfileDataSourceName(source as ProfileExternalDataSources);
    const date = DateTime.fromISO(this.skill.date).toRelativeCalendar({ unit: 'days' });

    return `${name}${name && date ? ' | ' : ''}${date}`;
  }
  public get sourcesLength(): number {
    return length(this.skill.sources);
  }
}
