import { AllDictionaries, DictionariesEnum, DictionaryItem } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

export const RequestBunchDictionariesAction = createAction(
  '[Dictionaries Resolver] Request bunch of dictionaries',
  props<{ dictionaries: DictionariesEnum[] }>(),
);
export const RequestBunchDictionariesFailedAction = createAction(
  '[Dictionaries Resolver] Request bunch of dictionaries failed',
);
export const RequestBunchDictionariesSuccessAction = createAction(
  '[Dictionaries Resolver] Bunch of dictionaries stored in the state',
  props<{ dictionaries: Partial<AllDictionaries> }>(),
);
export const RequestRejectReasonsAction = createAction(
  '[Dictionaries Resolver] Request reject reasons',
);
export const RequestRejectReasonsFailedAction = createAction(
  '[Dictionaries Resolver] Request reject reasons failed',
);
export const RequestRejectReasonsSuccessAction = createAction(
  '[Dictionaries Resolver] Reject reasons stored to state',
  props<{ reasons: DictionaryItem[] }>(),
);

const actions = {
  RequestBunchDictionariesAction,
  RequestBunchDictionariesFailedAction,
  RequestBunchDictionariesSuccessAction,
  RequestRejectReasonsAction,
  RequestRejectReasonsFailedAction,
  RequestRejectReasonsSuccessAction,
};

const dictionariesResolveActions = union(actions);

export type DictionariesResolveActionsType = typeof dictionariesResolveActions;

export const DictionariesResolveActions = actions;
