import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor as Interceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateTokensAction } from '@app-core/auth/actions/auth.system.actions';
import { checkAccessTokenValidity } from '@app-shared/functions/utilities/utilities';
import { interceptorInternalHeaders } from '@app-shared/interceptors/interceptor.config';
import { AuthResponse } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { appVersion } from '@app-shared/reducers/app-feature/app-feature.reducer';
import { getTokens } from '@app-shared/reducers/auth/auth.reducer';
import { AuthService } from '@app-shared/services/auth/auth.service';
import { select, Store } from '@ngrx/store';
import { has, reduce, test } from 'ramda';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HeaderInterceptor implements Interceptor {
  private appVersion: string;

  constructor(
    private readonly store: Store<State>,
    private readonly authService: AuthService,
  ) {
    this.store.pipe(select(appVersion)).subscribe((version) => (this.appVersion = version));
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isFileUploading = req.headers.has('file-uploading');
    const anonymousRequest = req.headers.has('anonymousRequest');
    const isAuthRequest = req.headers.has('authRequest');
    const isLocalRequest = test(/assets|chrome-extension/g, req.url);

    const requestWithAppVersion = req.clone({
      setHeaders: {
        'x-app-version': `angular-${this.appVersion}`,
      },
    });

    const pureRequest = reduce(
      (request, header) => this.removeInternalHeaders(request, header),
      requestWithAppVersion,
      interceptorInternalHeaders,
    );

    if (anonymousRequest || isLocalRequest) {
      return next.handle(pureRequest);
    }

    if (isAuthRequest) {
      const requestAsJson = pureRequest.clone({
        withCredentials: true,
      });
      return next.handle(requestAsJson);
    }

    return this.store.pipe(
      select(getTokens),
      first(),
      mergeMap(({ token, refresh_token }) =>
        checkAccessTokenValidity(token)
          ? of({ token })
          : this.authService.refreshToken(refresh_token),
      ),
      mergeMap((tokens: AuthResponse) => {
        const hasRefreshToken = has('refresh_token', tokens);
        if (hasRefreshToken) {
          this.store.dispatch(UpdateTokensAction({ tokens }));
        }

        const requestWithCredentials = pureRequest.clone({
          setHeaders: {
            Authorization: `Bearer ${tokens.token}`,
          },
          withCredentials: true,
        });

        if (isFileUploading) {
          return next.handle(requestWithCredentials);
        }
        const requestAsJson: HttpRequest<unknown> = requestWithCredentials.clone({
          setHeaders: {
            'Content-Type': 'application/json',
          },
        });

        return next.handle(requestAsJson);
      }),
    );
  }

  private removeInternalHeaders(req: HttpRequest<unknown>, header: string): HttpRequest<unknown> {
    return req.clone({
      headers: req.headers.delete(header),
    });
  }
}
