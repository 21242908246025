import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WindowRefService {
  // public only for testing purpose
  public isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public get nativeDocument(): Document | null {
    return this.isBrowser ? window.document : null;
  }

  public get nativeNavigator(): Navigator | null {
    return this.isBrowser ? navigator : null;
  }

  public get nativeWindow(): Window | null {
    return this.isBrowser ? window : null;
  }

  public notifyParentWindow(data: { type: string; data: unknown }) {
    if (!this.isBrowser) {
      return;
    }
    this.nativeWindow.parent.frames.postMessage(data, { targetOrigin: '*' });
  }
}
