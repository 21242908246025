/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import { ProfileDetailsSystemActions } from '@app-core/profile-details/actions/profile-details.system.actions';
import {
  CleanSelectionStateAction,
  RefreshProfilesListAfterDeleteTalentAction,
  RefreshProfilesListAfterEditTalentAction,
} from '@app-core/search-console/actions/search-console.system.actions';
import { getSource } from '@app-core/search-console/reducers/search-console.reducer';
import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { NotifyTrackersActions } from '@app-shared/actions/notify-trackers.actions';
import { RouterActions } from '@app-shared/actions/router.actions';
import { SourcesForShortList } from '@app-shared/enums/selection-states.enum';
import { formFileData } from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import {
  ContactLink,
  Entity,
  FilePayload,
  FullProfile,
  Profile,
  ProfileContactEntry,
  ProfileContactRequestSource,
  ProfileContacts,
  ProfileDetailsRequestSource,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getActiveModule } from '@app-shared/reducers/router/router.reducer';
import { NewEntityService } from '@app-shared/services/new-entity/new-entity.service';
import { ProfileService } from '@app-shared/services/profile/profile.service';
import { ShortListService } from '@app-shared/services/short-list/short-list.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { VacancySelectorSystemActions } from '@tsp-components/vacancy-selector';
import {
  always,
  append,
  isEmpty,
  isNil,
  mapObjIndexed,
  mergeAll,
  pipe,
  prop,
  propOr,
  reject,
  unless,
  values,
  when,
} from 'ramda';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class NewEntityProfileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<State>,
    private readonly newEntityService: NewEntityService,
    private readonly profileService: ProfileService,
    private readonly shortListService: ShortListService,
    private readonly translateService: TranslateService,
  ) {}

  public addProfileToShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.SaveNewTalentSuccessAction),
      filter(({ vacancy }) => !isNil(vacancy)),
      withLatestFrom(this.store$),
      map(([{ profile, vacancy, status }, state]) => ({
        shortList: {
          status: { id: status },
          profile: { id: profile.id },
          project: { id: vacancy },
        },
        source: SourcesForShortList[getSource(state)] || SourcesForShortList.candidates,
      })),
      switchMap(({ shortList, source }) =>
        this.shortListService.createShortList(shortList, source).pipe(
          mergeMap(() => [
            VacancySelectorSystemActions.UpdateVacancyDataAction(),
            AddNewEntitySystemActions.RefreshProfilesListAfterAddToVacancyAction(),
            NotifyTrackersActions.TrackCreateShortListAction({
              category: 'ShortList',
              action: 'addTo',
              value: 7,
              label: '',
            }),
          ]),
        ),
      ),
    ),
  );

  public checkProfileExistence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CheckProfileExistenceAction),
      filter(({ keyword }) => !isEmpty(keyword)),
      concatMap(({ devId, keyword, source }) =>
        this.profileService.checkProfileExistenceByKeyword(keyword, devId).pipe(
          filter((response) => !!response),
          map((profile: Profile) =>
            source === 'link'
              ? ProfilesApiActions.CheckProfileExistenceLinksSuccessAction({
                  link: keyword,
                  profile,
                })
              : ProfilesApiActions.CheckProfileExistenceSuccessAction({
                  contact: keyword,
                  profile,
                }),
          ),
        ),
      ),
    ),
  );

  public deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.DeleteProfileFromPopupAction),
      exhaustMap(({ id }) =>
        this.profileService.deleteEntity('profile', id).pipe(
          mergeMap(() => [
            InternalNotificationActions.SuccessNotificationAction({
              message: 'add-new-entity.effects.deleted.profile',
            }),
            AddNewEntitySystemActions.DeleteProfileFromDialogSuccessAction(),
          ]),
          catchError(() => of(AddNewEntitySystemActions.DeleteProfileFromDialogFailedAction())),
        ),
      ),
    ),
  );

  public getProfileDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.RequestProfileDetailsOnInitAction),
      exhaustMap(({ id }) =>
        combineLatest([
          this.profileService.getProfileById(id, ProfileDetailsRequestSource.EDIT),
          this.profileService.getProfileContact(id, ProfileContactRequestSource.EDIT).pipe(
            map((contactData) => ({
              mainContacts: pipe(
                prop('mainContacts'),
                mapObjIndexed((val: ProfileContactEntry[], key) => ({
                  [key]: reject((sl: ProfileContactEntry) => sl.public, val),
                })),
                values,
                mergeAll as UnaryOperator<
                  { [key: string]: ProfileContactEntry[] }[],
                  { [key: string]: ProfileContactEntry[] }
                >,
              )(contactData.contacts),
              socialLinks: pipe(
                propOr([], 'socialLinks') as UnaryOperator<
                  ProfileContacts,
                  Array<Entity & ContactLink & { public?: boolean }>
                >,
                reject((sl: Entity & ContactLink & { public?: boolean }) => sl.public),
              )(contactData.contacts),
            })),
          ),
        ]).pipe(
          concatMap(([profile, contacts]: [FullProfile, ProfileContacts]) => [
            AddNewEntitySystemActions.RequestProfileDetailsSuccessAction({ profile }),
            AddNewEntitySystemActions.RequestProfileContactsSuccessAction({ contacts }),
          ]),
          catchError(() => of(AddNewEntitySystemActions.RequestProfileDetailsFailedAction())),
        ),
      ),
    ),
  );

  public refreshTalentDataOnUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.UpdateTalentSuccessAction),
      withLatestFrom(this.store$),
      map(([, state]) => getActiveModule(state)),
      mergeMap((currentModule) => {
        let mainUpdateAction: Action[] = [];
        switch (currentModule) {
          case 'profiles':
            mainUpdateAction = [
              RefreshProfilesListAfterEditTalentAction(),
              CleanSelectionStateAction(),
            ];
            break;
          case 'vacancy':
            mainUpdateAction = [
              VacancyProfilesSystemActions.RefreshVacancyProfilesAfterEditTalentAction(),
              CleanSelectionStateAction(),
            ];
            break;
          case 'profile':
            mainUpdateAction = [
              ProfileDetailsSystemActions.RefreshProfileDetailsAfterEditingAction(),
            ];
            break;
        }

        return mainUpdateAction;
      }),
    ),
  );

  public refreshTalentDataOnDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.DeleteProfileFromDialogSuccessAction),
      withLatestFrom(this.store$),
      map(([, state]) => ({
        currentModule: getActiveModule(state),
      })),
      mergeMap(({ currentModule }) => {
        switch (currentModule) {
          case 'candidates':
          case 'profiles':
            return [RefreshProfilesListAfterDeleteTalentAction(), CleanSelectionStateAction()];
          case 'vacancy':
            return [
              VacancyProfilesSystemActions.RefreshVacancyProfilesAfterDeleteTalentAction(),
              CleanSelectionStateAction(),
            ];
          case 'profile':
            return [RouterActions.NavigateToSearchConsoleAction({ params: {} })];
          default:
            return [];
        }
      }),
    ),
  );

  public saveNewTalent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CreateNewTalentAction),
      exhaustMap(
        ({ talent, cvMimetype, urlToCv, textContent, vacancy, status, navigateToCreated }) =>
          this.profileService.createProfile({ ...talent, cvMimetype, urlToCv, textContent }).pipe(
            mergeMap((newTalentEntity) => {
              const id = newTalentEntity.id;
              const addedMessage = this.translateService.instant(
                'add-new-entity.effects.created.profile-added',
                { id: vacancy?.id, name: vacancy?.name },
              );
              const successMessage = navigateToCreated
                ? InternalNotificationActions.SuccessNotificationAction({
                    message: 'add-new-entity.effects.created.profile',
                  })
                : InternalNotificationActions.SuccessNotificationAndActivityAction({
                    message: 'add-new-entity.effects.created.profile-click',
                    action: RouterActions.NavigateToProfilePageAction({ id }),
                  });
              const defaultActions: Action[] = [
                successMessage,
                AddNewEntitySystemActions.SaveNewTalentSuccessAction({
                  profile: newTalentEntity,
                  vacancy: vacancy?.id || null,
                  status,
                  navigateToCreated,
                }),
              ];
              return pipe(
                unless(
                  always(isNil(vacancy)),
                  append(
                    InternalNotificationActions.SuccessNotificationAction({
                      message: addedMessage,
                    }),
                  ),
                ),
                when(
                  always(isNil(vacancy) && !navigateToCreated),
                  append(AddNewEntitySystemActions.RefreshProfilesListAfterCreateProfileAction()),
                ),
                when(
                  always(navigateToCreated),
                  append(RouterActions.NavigateToProfilePageAction({ id })),
                ),
              )(defaultActions) as Action[];
            }),
            catchError(() => of(AddNewEntitySystemActions.SaveNewTalentFailedAction())),
          ),
      ),
    ),
  );

  public updateTalent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.UpdateTalentAction),
      exhaustMap(({ profile }) =>
        this.profileService.updateProfile(profile.id, profile, 'details').pipe(
          mergeMap((updateTalentEntity: FullProfile) => [
            InternalNotificationActions.SuccessNotificationAndActivityAction({
              message: 'add-new-entity.effects.updated.profile-click',
              action: RouterActions.NavigateToProfilePageAction({ id: updateTalentEntity.id }),
            }),
            AddNewEntitySystemActions.UpdateTalentSuccessAction({ profile: updateTalentEntity }),
          ]),
          catchError(() => of(AddNewEntitySystemActions.UpdateTalentFailedAction())),
        ),
      ),
    ),
  );

  public uploadCvToCreatedProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.UploadFileToCreatedProfileAction),
      map(({ id, file, navigateToCreated, textContent }) => ({
        formData: formFileData(file, '0', 'cv', id, textContent),
        id,
        navigateToCreated,
      })),
      exhaustMap(({ formData, id, navigateToCreated }) =>
        this.newEntityService.uploadFile<FilePayload>(formData).pipe(
          mergeMap(
            () =>
              when(
                always(navigateToCreated),
                append(RouterActions.NavigateToProfilePageAction({ id })),
              )([AddNewEntitySystemActions.UploadFileToCreatedProfileSuccessAction()]) as Action[],
          ),
          catchError(() => of(AddNewEntitySystemActions.UploadFileToCreatedProfileFailedAction())),
        ),
      ),
    ),
  );
}
