@if (form) {
  <div class="c-vacancies-list-control-bar__filter--row">
    @if (managers$ | async; as managers) {
      <mat-form-field
        floatLabel="auto"
        class="c-tsp-mat-form-field c-tsp-mat-form-field--filter c-vacancies-list-control-bar__filter-control"
        [ngClass]="{ 'c-vacancies-list-control-bar__filter--visible': showOtherFilters }"
      >
        <mat-select
          [formControl]="form | getFormControl: 'managers'"
          multiple
          panelClass="c-tsp-mat-select-panel"
          [placeholder]="'shared.models.manager.placeholder' | translate"
          (selectionChange)="filtersChanged.emit()"
        >
          <app-mat-select-search
            placeholderLabel="profile-short-list.short-list-dialog.manager.search.label"
            [clearOnFocus]="false"
            [resetOnBlur]="false"
            [formControl]="searchValueControl"
            [isNotFullListLoaded]="true"
            [previousSelectedValues]="managersControlValue"
            (changeEvent)="searchValue$.next($event)"
          >
          </app-mat-select-search>
          @for (manager of managers; track manager) {
            <mat-option
              class="c-tsp-mat-multiple-select__option"
              [disabled]="!manager.email"
              [matTooltip]="
                manager.email
                  ? ''
                  : ('profile-short-list.short-list-dialog.manager.no-email' | translate)
              "
              [value]="manager.email"
            >
              {{ manager.fullName }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    @if (sortedDepartments) {
      <mat-form-field
        floatLabel="auto"
        class="c-tsp-mat-form-field c-tsp-mat-form-field--filter c-vacancies-list-control-bar__filter-control"
        [ngClass]="{ 'c-vacancies-list-control-bar__filter--visible': showOtherFilters }"
      >
        <mat-select
          [formControl]="form | getFormControl: 'departmentId'"
          panelClass="c-tsp-mat-select-panel"
          [placeholder]="'shared.models.department.placeholder' | translate"
          (selectionChange)="filtersChanged.emit()"
        >
          <app-mat-select-search
            placeholderLabel="notification-settings.vacancy-search.placeholder"
            [clearOnFocus]="false"
            [resetOnBlur]="false"
            (changeEvent)="sortDepartments($event)"
          >
          </app-mat-select-search>
          @for (department of sortedDepartments; track department) {
            <mat-option class="c-tsp-mat-multiple-select__option" [value]="department.id">
              {{ department.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    <mat-form-field
      floatLabel="always"
      class="c-tsp-mat-form-field c-tsp-mat-form-field--filter c-vacancies-list-control-bar__filter-control"
      [ngClass]="{ 'c-vacancies-list-control-bar__filter--visible': showOtherFilters }"
    >
      <mat-label></mat-label>
      <input
        matInput
        [formControl]="form | getFormControl: 'address'"
        class="c-tsp-mat-form-field-input"
        [placeholder]="'shared.models.address.placeholder' | translate"
        (change)="filtersChanged.emit()"
      />
    </mat-form-field>
  </div>
}
