import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEditableItem, DirectoryTag } from '@app-shared/models';

@Pipe({
  name: 'castToTag',
  standalone: true,
})
export class CastToTagPipe implements PipeTransform {
  public transform(value: DictionaryEditableItem): DirectoryTag {
    return value as DirectoryTag;
  }
}
