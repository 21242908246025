import { ProfileDetailsSystemActions } from '@app-core/profile-details/actions/profile-details.system.actions';
import { ProfileDetailsUserActions } from '@app-core/profile-details/actions/profile-details.user.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import {
  AddCommentFailedAction,
  AddCommentSuccessfulAction,
  CreateShortListFromProfileFailedAction,
  CreateShortListFromProfileSuccessAction,
  DeleteCommentFailedAction,
  DeleteCommentSuccessfulAction,
  DeleteFileFailedAction,
  DeleteFileSuccessAction,
  GetProfileHistoryFailedAction,
  GetProfileHistorySuccessAction,
  ProfileModificationActions,
  RemoveFromShortListFailedAction,
  RemoveFromShortListSuccessAction,
  UpdateShortListFailedAction,
  UpdateShortListSuccessAction,
  UpdateSkillWeightFailedAction,
  UpdateSkillWeightSuccessAction,
  UpdateTagsAction,
  UpdateTagsFailedAction,
  UpdateTagsSuccessAction,
  UploadFileFailedAction,
  UploadFileSuccessAction,
} from '@app-shared/effects/profile-modification/profile-modification.actions';
import {
  addFileToProfileDetails,
  addShortListToProfileDetails,
  removeFileFromProfileDetails,
  removeShortListFromProfileDetails,
  updateShortListInProfileDetails,
} from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import {
  adjustSkillWeightInProfile,
  matchPublicProfile,
} from '@app-shared/functions/profile-list-modifications/profile-list-modifications';
import {
  DateRange,
  FullProfile,
  HistoryState,
  Profile,
  ShortList,
  UnaryOperator,
} from '@app-shared/models';
import { getSelectedVacancyId } from '@app-shared/reducers/vacancy/vacancy.reducer';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  anyPass,
  concat,
  find,
  isEmpty,
  isNil,
  lensPath,
  mergeLeft,
  mergeRight,
  objOf,
  path,
  pathEq,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
  set,
  unless,
} from 'ramda';

export interface ProfileDetailsState {
  activeProfileTab?: string;
  fullInfo?: FullProfile;
  isFilesLoading: boolean;
  isProfileLoading: boolean;
  isSkillsBlocked: boolean;
  loadingDeveloperData?: {
    certificates: false;
    education: false;
    profile: false;
    symfonyProjects: false;
  };
  profileHistory: HistoryState;
  selectedVacancyId?: number;
  shortInfo?: Profile;
  isRightPanelOpened: boolean;
}

export const initialState: ProfileDetailsState = {
  isProfileLoading: false,
  isFilesLoading: false,
  isRightPanelOpened: false,
  isSkillsBlocked: false,
  profileHistory: {
    isLoading: false,
    items: [],
    total: 0,
    query: {
      dateFrom: null,
      dateTo: null,
    },
  },
};

const reducer = createReducer(
  initialState,
  on(MetadataActions.CleanReducers, () => initialState),
  on(
    ProfileDetailsUserActions.AddCommentFromDetailsAction,
    ProfileDetailsUserActions.AddToShortListFromDetailsAction,
    ProfileDetailsUserActions.UnbookProfileFromDetailsAction,
    ProfileDetailsUserActions.BookProfileFromDetailsAction,
    ProfileDetailsUserActions.DeleteCommentFromDetailsAction,
    ProfileDetailsUserActions.LinkSuggestionAction,
    ProfileDetailsSystemActions.RefreshProfileDetailsAfterEditingAction,
    ProfileDetailsSystemActions.RefreshProfileDetailsAfterAddingToLongListAction,
    ProfileDetailsUserActions.RemoveFromShortListInDetailsActivitiesAction,
    ProfileDetailsUserActions.RemoveFromShortListInDetailsSidepanelAction,
    ProfileDetailsUserActions.ResendShortlistForSecurityCheckAction,
    ProfileDetailsSystemActions.RequestProfileDetailsFromResolverAction,
    ProfileDetailsSystemActions.ToggleProfileDetailsLoadingStateAction,
    ProfileDetailsUserActions.UpdateShortListInDetailsActivitiesAction,
    ProfileModificationActions.MatchProfileDataAction,
    ProfileModificationActions.MergeProfileDuplicatesAction,
    UpdateTagsAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      mergeRight(state, { isProfileLoading: true }),
  ),
  on(
    ProfileDetailsSystemActions.CleanProfileDetailsAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      mergeRight(state, {
        fullInfo: null,
        shortInfo: null,
      }),
  ),
  on(
    ProfileDetailsSystemActions.SetInitialStateAction,
    (state: ProfileDetailsState, { tab }): ProfileDetailsState =>
      mergeRight(state, { activeProfileTab: tab }),
  ),
  on(
    ProfileDetailsUserActions.CloseRightPanelAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      mergeRight(state, { isRightPanelOpened: false }),
  ),
  on(
    AddCommentFailedAction,
    CreateShortListFromProfileFailedAction,
    DeleteCommentFailedAction,
    RemoveFromShortListFailedAction,
    ProfileDetailsSystemActions.UnbookProfileFromDetailsFailedAction,
    ProfileDetailsSystemActions.BookProfileFromDetailsFailedAction,
    ProfileDetailsSystemActions.RequestProfileDetailsFailedAction,
    ProfileDetailsSystemActions.LinkSuggestionFailedAction,
    ProfileDetailsSystemActions.LinkSuggestionSuccessAction,
    ProfileModificationActions.MatchProfileDataFailedAction,
    ProfileModificationActions.MergeProfileDuplicatesFailedAction,
    ProfileModificationActions.ResendShortListForSecurityCheckSuccessAction,
    ProfileModificationActions.ResendShortlistForSecurityCheckFailedAction,
    UpdateShortListFailedAction,
    UpdateTagsFailedAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      mergeRight(state, { isProfileLoading: false }),
  ),
  on(AddCommentSuccessfulAction, (state: ProfileDetailsState, { comment }): ProfileDetailsState => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    return pipe(
      pathOr([], ['fullInfo', 'comments']),
      concat([comment]),
      objOf('comments'),
      mergeRight(currentProfile),
      objOf('fullInfo'),
      mergeRight({ isProfileLoading: false }),
      mergeRight(state),
    )(state);
  }),
  on(
    CreateShortListFromProfileSuccessAction,
    (state: ProfileDetailsState, { shortList }): ProfileDetailsState => {
      const currentProfile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(currentProfile)) {
        return state;
      }
      const updateProfile = addShortListToProfileDetails(shortList, currentProfile);
      return mergeRight(state, {
        isProfileLoading: false,
        fullInfo: updateProfile,
      });
    },
  ),
  on(DeleteCommentSuccessfulAction, (state: ProfileDetailsState, { id }): ProfileDetailsState => {
    const currentProfile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(currentProfile)) {
      return state;
    }
    const leftComments = pipe(
      pathOr([], ['fullInfo', 'comments']),
      reject(propEq(id, 'id')),
    )(state);
    const fullInfo = mergeRight(currentProfile, { comments: leftComments });

    return mergeRight(state, { isProfileLoading: false, fullInfo });
  }),
  on(
    ProfileDetailsUserActions.DeleteFileFromDetailsAction,
    ProfileDetailsUserActions.UploadFileFromDetailsAction,
    (state) => mergeRight(state, { isFilesLoading: true }),
  ),
  on(
    DeleteFileFailedAction,
    UploadFileFailedAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      mergeRight(state, { isFilesLoading: false }),
  ),
  on(
    DeleteFileSuccessAction,
    (state: ProfileDetailsState, { fileId, fileType }): ProfileDetailsState => {
      const type: 'cvs' | 'voices' | 'otherFiles' = `${
        fileType === 'other' ? 'otherFile' : fileType
      }s`;

      const currentProfile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(currentProfile)) {
        return state;
      }

      const profileWithoutFile = removeFileFromProfileDetails(currentProfile, fileId, type);
      return mergeRight(state, {
        fullInfo: profileWithoutFile,
        isFilesLoading: false,
      });
    },
  ),
  on(
    ProfileDetailsUserActions.DeleteHistoryEventAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      set(lensPath(['profileHistory', 'isLoading']), true, state),
  ),
  on(
    ProfileDetailsUserActions.GetProfileHistoryFromDetailsAction,
    ProfileDetailsUserActions.GetProfileHistoryFromDetailsOnInitAction,
    (state: ProfileDetailsState, { query }): ProfileDetailsState =>
      pipe(
        path(['profileHistory', 'query']) as UnaryOperator<ProfileDetailsState, DateRange>,
        mergeLeft(query) as UnaryOperator<DateRange, DateRange>,
        objOf('query'),
        mergeLeft({ isLoading: true }),
        mergeRight(state.profileHistory),
        objOf('profileHistory'),
        mergeRight(state),
      )(state),
  ),
  on(ProfileModificationActions.MatchProfileDataSuccessAction, (state, { data }) => {
    const profile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(profile)) {
      return mergeRight(state, { isProfileLoading: false });
    }
    const newProfile = matchPublicProfile(data, profile) as FullProfile;
    return mergeRight(state, { isProfileLoading: false, fullInfo: newProfile });
  }),
  on(
    ProfileDetailsSystemActions.DeleteHistoryEventFailedAction,
    GetProfileHistoryFailedAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      set(lensPath(['profileHistory', 'isLoading']), false, state),
  ),
  on(
    GetProfileHistorySuccessAction,
    (state: ProfileDetailsState, { events, actionSource }): ProfileDetailsState => {
      if (actionSource === 'sidepanel') {
        return state;
      }
      const { count, items } = events;
      return pipe(
        prop('profileHistory') as UnaryOperator<ProfileDetailsState, HistoryState>,
        mergeLeft({
          total: count,
          items,
          isLoading: false,
        } as Partial<HistoryState>) as UnaryOperator<HistoryState, HistoryState>,
        objOf('profileHistory') as UnaryOperator<HistoryState, Partial<ProfileDetailsState>>,
        mergeRight(state) as UnaryOperator<Partial<ProfileDetailsState>, ProfileDetailsState>,
      )(state);
    },
  ),
  on(
    ProfileDetailsUserActions.OpenRightPanelAction,
    (state: ProfileDetailsState): ProfileDetailsState =>
      mergeRight(state, { isRightPanelOpened: true }),
  ),
  on(
    RemoveFromShortListSuccessAction,
    (state: ProfileDetailsState, { id }): ProfileDetailsState => {
      const currentProfile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(currentProfile)) {
        return state;
      }
      const updateProfile = removeShortListFromProfileDetails(id, currentProfile);
      return mergeRight(state, {
        isProfileLoading: false,
        fullInfo: updateProfile,
      });
    },
  ),
  on(
    ProfileDetailsSystemActions.RequestProfileDetailsSuccessAction,
    (state: ProfileDetailsState, { profile }): ProfileDetailsState => {
      return mergeRight(state, {
        isProfileLoading: false,
        fullInfo: profile,
        shortInfo: state.shortInfo,
      });
    },
  ),
  on(
    UpdateShortListSuccessAction,
    (state: ProfileDetailsState, { shortList }): ProfileDetailsState => {
      const currentProfile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(currentProfile)) {
        return state;
      }
      const updateProfile = updateShortListInProfileDetails(shortList, currentProfile, true);
      return mergeRight(state, {
        isProfileLoading: false,
        fullInfo: updateProfile,
      });
    },
  ),
  on(
    UpdateShortListFailedAction,
    (state: ProfileDetailsState, { shortList }): ProfileDetailsState => {
      const currentProfile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(currentProfile)) {
        return state;
      }
      const updateProfile = updateShortListInProfileDetails(shortList, currentProfile);
      return mergeRight(state, {
        isProfileLoading: false,
        fullInfo: updateProfile,
      });
    },
  ),
  on(
    ProfileDetailsUserActions.UpdateSkillWeightAction,
    mergeLeft({ isSkillsBlocked: true }) as UnaryOperator<ProfileDetailsState, ProfileDetailsState>,
  ),
  on(
    ProfileDetailsSystemActions.UpdateSkillsInDetailsAction,
    (state: ProfileDetailsState, { data }): ProfileDetailsState => {
      const profile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(profile)) {
        return state;
      }
      const newProfile = mergeRight(profile, { skills: data });
      return mergeRight(state, { fullInfo: newProfile });
    },
  ),
  on(
    ProfileDetailsSystemActions.UpdateSoftSkillsInDetailsAction,
    (state: ProfileDetailsState, { data }): ProfileDetailsState => {
      const profile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(profile)) {
        return state;
      }
      const newProfile = mergeRight(profile, { softSkills: data });
      return mergeRight(state, { fullInfo: newProfile });
    },
  ),
  on(UpdateTagsSuccessAction, (state: ProfileDetailsState, { data }): ProfileDetailsState => {
    const profile = state.fullInfo;
    if (anyPass([isNil, isEmpty])(profile)) {
      return state;
    }
    const newProfile = mergeRight(profile, { tags: data });
    return mergeRight(state, { isProfileLoading: false, fullInfo: newProfile });
  }),
  on(
    UpdateSkillWeightFailedAction,
    mergeLeft({ isSkillsBlocked: false }) as UnaryOperator<
      ProfileDetailsState,
      ProfileDetailsState
    >,
  ),
  on(
    UpdateSkillWeightSuccessAction,
    (state: ProfileDetailsState, { skill, skillType }): ProfileDetailsState => {
      const profile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(profile)) {
        return state;
      }
      const target = skillType === 'skill' ? 'skills' : 'softSkills';
      const newProfile = adjustSkillWeightInProfile(skill, profile, target) as FullProfile;
      return mergeRight(state, { fullInfo: newProfile, isSkillsBlocked: false });
    },
  ),
  on(
    UploadFileSuccessAction,
    (state: ProfileDetailsState, { file, fileType }): ProfileDetailsState => {
      const profile = state.fullInfo;
      if (anyPass([isNil, isEmpty])(profile)) {
        return state;
      }

      const type: 'cvs' | 'voices' | 'otherFiles' = `${
        fileType === 'other' ? 'otherFile' : fileType
      }s`;
      const profileWithNewFile = addFileToProfileDetails(profile, file, type);
      return mergeRight(state, {
        fullInfo: profileWithNewFile,
        isFilesLoading: false,
      });
    },
  ),
);

export const profileDetailsReducer = (
  baseState: ProfileDetailsState,
  action: Action,
): ProfileDetailsState => reducer(baseState, action);

export const selectProfileDetailsStore =
  createFeatureSelector<ProfileDetailsState>('profileDetails');
export const getProfileLoading = createSelector(
  selectProfileDetailsStore,
  (store) => store?.isProfileLoading,
);
export const getActiveProfileTab = createSelector(
  selectProfileDetailsStore,
  (store) => store?.activeProfileTab,
);
export const getFullDeveloperInfo = createSelector(
  selectProfileDetailsStore,
  (store) => store?.fullInfo,
);
export const getDeveloperComments = createSelector(
  getFullDeveloperInfo,
  (store) => store?.comments,
);
export const getFilesLoading = createSelector(
  selectProfileDetailsStore,
  (store) => store?.isFilesLoading,
);
export const getRightPanelState = createSelector(
  selectProfileDetailsStore,
  (store) => store?.isRightPanelOpened,
);
export const getSelectedVacancyShortList = createSelector(
  getFullDeveloperInfo,
  getSelectedVacancyId,
  (profile: FullProfile, id: number): ShortList | null =>
    pipe(
      propOr(null, 'processingList') as UnaryOperator<FullProfile, ShortList[] | null>,
      unless(
        anyPass([isNil, isEmpty]),
        find(pathEq(id, ['project', 'id'])) as UnaryOperator<ShortList[], ShortList>,
      ),
    )(profile) as ShortList,
);
export const getProfileDetailsSkillsBlockingState = createSelector(
  selectProfileDetailsStore,
  (store) => store?.isSkillsBlocked,
);
export const getProfileHistory = createSelector(
  selectProfileDetailsStore,
  (store) => store?.profileHistory,
);
export const getProfileHistoryLoadingState = createSelector(
  getProfileHistory,
  (history) => history?.isLoading,
);
export const getProfileHistoryItemsList = createSelector(
  getProfileHistory,
  (history) => history?.items,
);
export const getProfileHistoryItemsCount = createSelector(
  getProfileHistory,
  (history) => history?.total,
);
export const getProfileHistoryQueryParams = createSelector(
  getProfileHistory,
  (history) => history?.query,
);
