import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { getIntegrationCustomFieldId } from '@app-integration/kernel/helpers';
import {
  AllDictionaries,
  BinaryOperator,
  CustomFormFieldSettings,
  DictionariesEnum,
  DictionaryItem,
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  UnaryOperator,
} from '@app-shared/models';
import { GetFormControlPipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import {
  applySpec,
  converge,
  find,
  head,
  identity,
  isNil,
  map,
  mapObjIndexed,
  mergeAll,
  mergeLeft,
  mergeRight,
  objOf,
  omit,
  path,
  pathEq,
  pick,
  pipe,
  prop,
  propEq,
  reject,
  toString,
  values,
  when,
} from 'ramda';
import { Subject, interval } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { CustomFieldControlComponent } from '../custom-field-control/custom-field-control.component';

@Component({
  imports: [CustomFieldControlComponent, GetFormControlPipe, NgClass, NgStyle, ReactiveFormsModule],
  selector: 'app-custom-fields-group',
  templateUrl: './custom-fields-group.component.html',
  styleUrls: ['./custom-fields-group.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomFieldsGroupComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CustomFieldsGroupComponent implements ControlValueAccessor, OnDestroy {
  @Input()
  public customCssClass: string;
  @Input()
  public fieldsDefinitions?: CustomFormFieldSettings[];
  @Input()
  public dictionaries?: Pick<
    AllDictionaries,
    | DictionariesEnum.businesses
    | DictionariesEnum.securityGroups
    | DictionariesEnum.vacancyCategories
  >;
  @Input()
  public set fieldControls(data: DirectoryFieldType[]) {
    if (!data) {
      return;
    }
    this.customControls = data;
    this.initForm();
  }
  public customForm: UntypedFormGroup;
  public customControls: DirectoryFieldType[] = [];
  public debounceTime = 250;
  private originalValue: DirectoryCustomFieldResponse[];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: (val: unknown) => void = () => {};

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getControl(controlId: number) {
    return this.customForm.get(`${controlId}`);
  }
  public getControlProperty(controlId: number, property: keyof DirectoryFieldType): string {
    return pipe(find(propEq(controlId, 'id')), prop(property))(this.customControls) as string;
  }
  public trackById(_index: number, element: DirectoryFieldType) {
    return element.id;
  }

  public getControlSetting(controlId: number): CustomFormFieldSettings {
    return pipe(find(propEq(controlId, 'id')))(
      this.fieldsDefinitions || [],
    ) as CustomFormFieldSettings;
  }

  public getDictionary(controlId: number): DictionaryItem[] | null {
    const controlSetting = this.getControlSetting(controlId);

    if (controlSetting?.fieldType !== 'selector' || !controlSetting?.dictionaryName) {
      return null;
    }

    return this.dictionaries?.[controlSetting.dictionaryName] || null;
  }

  public getIsControlVisible(controlId: number): boolean {
    if (!this.fieldsDefinitions?.length) {
      return true;
    }
    return !!find(propEq(controlId, 'id'))(this.fieldsDefinitions);
  }

  public getControlWidthClass(controlId: number): string {
    const predefinedWidth = this.getControlSetting(controlId)?.width;

    if (predefinedWidth) {
      return predefinedWidth;
    }

    return this.getControlProperty(controlId, 'type') === 'longtext' ? 'col-12' : 'col-6';
  }

  // Kernel custom logic
  public securityGroupChanged(value: DictionaryItem & { eStuffId?: string }) {
    const securityGroupId = getIntegrationCustomFieldId('securityGroupId', 'vacancy') || '';
    this.customForm.get(securityGroupId.toString()).setValue(value.eStuffId);
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched() {}

  public writeValue(value: DirectoryCustomFieldResponse[]) {
    this.originalValue = value;

    const updatedValue: Record<string, unknown> = pipe(
      map(
        pipe(
          converge(
            mergeRight as BinaryOperator<
              DirectoryCustomFieldResponse,
              { id: number },
              DirectoryCustomFieldResponse
            >,
            [
              identity,
              applySpec({
                id: path(['type', 'id']),
              }) as UnaryOperator<DirectoryCustomFieldResponse, { id: number }>,
            ],
          ) as UnaryOperator<DirectoryCustomFieldResponse, DirectoryCustomFieldResponse>,
          omit(['type']),
          converge(objOf as BinaryOperator<string, string, Record<string, unknown>>, [
            prop('id'),
            pipe(omit(['id']), values, reject(isNil), head as UnaryOperator<string[], string>),
          ]) as UnaryOperator<DirectoryCustomFieldResponse, Record<string, unknown>>,
        ),
      ) as UnaryOperator<DirectoryCustomFieldResponse[], Record<string, unknown>[]>,
      mergeAll as UnaryOperator<Record<string, unknown>[], Record<string, unknown>>,
    )(value);

    // clear old values
    this.customForm.reset();
    this.customForm.patchValue(updatedValue);
  }

  private initForm() {
    this.customForm = pipe(
      map(
        pipe(prop('id'), toString, (name: string) => ({
          [name]: new FormControl(null, []),
        })),
      ),
      mergeAll,
      (controls) => new FormGroup(controls),
    )(this.customControls);

    this.customForm.valueChanges
      .pipe(
        debounce(() => interval(this.debounceTime)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((value: Record<string, string>) => {
        const convertedValue = this.convertFormValuesToSymfonyPayload(value);
        this.onChange(convertedValue);
      });
  }
  private convertFormValuesToSymfonyPayload(
    data: Record<string, string>,
  ): DirectoryCustomFieldResponse[] {
    return pipe(
      mapObjIndexed((value: string, key: string) => {
        if (!value && !this.isControlChanged(key)) {
          return null;
        }
        const parsedKey = parseInt(key, 10);
        const customFieldDefinition = pipe(
          find(propEq(parsedKey, 'id')),
          pick(['id', 'name', 'type']),
        )(this.customControls) as Pick<DirectoryFieldType, 'id' | 'name' | 'type'>;
        const correctValue =
          customFieldDefinition.type === 'date'
            ? DateTime.fromISO(value, { setZone: true, zone: 'utc' }).toISO()
            : value;

        return pipe(
          find(pathEq(parsedKey, ['type', 'id'])),
          when(isNil, () => ({ type: customFieldDefinition })),
          mergeLeft({ value: correctValue }),
        )(this.originalValue) as unknown as DirectoryCustomFieldResponse;
      }) as UnaryOperator<
        { [key: string]: string },
        { [key: string]: DirectoryCustomFieldResponse }
      >,
      values,
      reject(isNil) as UnaryOperator<
        DirectoryCustomFieldResponse[],
        DirectoryCustomFieldResponse[]
      >,
    )(data);
  }

  private isControlChanged(key: string): boolean {
    return this.customForm.get(key)?.dirty;
  }
}
