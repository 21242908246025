<div class="c-directory-custom-fields" [formGroup]="form">
  <span class="c-directory-custom-fields__hint" translate
    >add-new-entity.directory-custom-fields.add.manually</span
  >
  <div class="c-tsp-mat-form-field__row c-directory-custom-fields__wrapper">
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-directory-custom-fields--name"
      floatLabel="always"
    >
      <mat-label translate>add-new-entity.directory-custom-fields.name.label</mat-label>
      <input
        matInput
        class="c-tsp-mat-form-field-input"
        formControlName="name"
        [placeholder]="'add-new-entity.directory-custom-fields.name.placeholder' | translate"
      />
    </mat-form-field>
    <mat-form-field
      class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-directory-custom-fields--type"
      floatLabel="always"
    >
      <mat-label translate>add-new-entity.new-directory.form.type.label</mat-label>
      <mat-select
        panelClass="c-tsp-mat-select-panel-default"
        [placeholder]="'add-new-entity.new-directory.form.type.placeholder' | translate"
        formControlName="type"
      >
        @for (type of currentTypeField; track type) {
          <mat-option class="c-tsp-mat-form-field-option" [value]="type.name">
            {{ type.title | translate }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button mat-button class="c-directory-custom-fields__button" (click)="addField()">
      <span translate>add-new-entity.directory-custom-fields.add.button</span>
    </button>
  </div>
  @if (statusesCustomFields?.length) {
    <span class="c-directory-custom-fields__hint" translate
      >add-new-entity.directory-custom-fields.add.existed</span
    >
  }
  @if (statusesCustomFields?.length) {
    <div class="c-tsp-mat-form-field__row c-directory-custom-fields__wrapper">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-directory-custom-fields--type"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.directory-custom-fields.name.label</mat-label>
        <mat-select
          panelClass="c-tsp-mat-select-panel"
          [placeholder]="'add-new-entity.directory-custom-fields.choose' | translate"
          formControlName="selectedField"
        >
          @for (field of statusesCustomFields; track field) {
            <mat-option class="c-tsp-mat-form-field-option" [value]="field">
              {{ field.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <button
        mat-button
        class="c-directory-custom-fields__button"
        (click)="addField(form.get('selectedField').value)"
      >
        <span translate>add-new-entity.directory-custom-fields.add.button</span>
      </button>
    </div>
  }
  @if (customFields?.length) {
    <span class="c-directory-custom-fields__label" translate
      >add-new-entity.directory-custom-fields.added</span
    >
  }
  <div class="c-directory-custom-fields__wrapper">
    @for (field of customFields; track field) {
      <span class="c-directory-custom-fields__item">
        <span class="c-directory-custom-fields__item-name">{{ field?.name }}</span>
        <button
          class="c-directory-custom-fields__item-remove"
          mat-icon-button
          (click)="removeField(field)"
        >
          <i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </span>
    }
  </div>
</div>
