import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import {
  getIntegrationCustomFieldId,
  getIntegrationCustomFieldName,
  getVacancyCustomFieldValue,
} from '@app-integration/kernel/helpers';
import { KernelEmployee } from '@app-integration/kernel/models/kernel.model';
import { KernelService } from '@app-integration/kernel/services/kernel.service';
import { DictionaryDepartment, DictionaryItem } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getDepartments } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { getVacancyDetails } from '@app-shared/reducers/vacancy/vacancy.reducer';
import { Store, select } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { MultipleSearchComponent } from '@tsp-components/multiple-select-search';
import { anyPass, equals, find, pluck, propEq, propSatisfies, test } from 'ramda';
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  imports: [
    AsyncPipe,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MatTooltip,
    MultipleSearchComponent,
    ReactiveFormsModule,
    TranslatePipe,
  ],
  selector: 'kernel-custom-field-selector',
  templateUrl: './kernel-custom-field-selector.component.html',
  styleUrls: ['./kernel-custom-field-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class KernelCustomFieldSelectorComponent implements OnInit, OnDestroy {
  @Input()
  public controlName: string;
  @Input()
  public set controlId(id: number) {
    this.customFieldName = getIntegrationCustomFieldName(id, 'vacancy');
  }
  @Input()
  public description: string;
  @Input()
  public internalControl: FormControl<string | string[]>;
  @Input()
  public isReadonly?: boolean;
  @Input()
  public dictionary: DictionaryItem[];
  @Output()
  public onChange = new EventEmitter<string>();
  @Output()
  public securityGroupChanged = new EventEmitter<DictionaryItem>();

  public departments$?: Observable<DictionaryDepartment[]>;
  public managersByVacancy?: KernelEmployee[];
  public customFieldName: string;
  public searchDepartmentsControl = new FormControl<string>(null);
  public searchManagersControl = new FormControl<string>(null);
  public securityCategories = ['A', 'B1', 'B2', 'C'];
  public vacancyDepartmentId?: string;
  private departments: DictionaryDepartment[] = [];
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly store$: Store<State>,
    private readonly kernelService: KernelService,
    private readonly changeDetection: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    switch (this.customFieldName) {
      case 'managers': {
        const departmentFieldId = getIntegrationCustomFieldId('departmentId', 'vacancy');
        const managersFieldId = getIntegrationCustomFieldId('managers', 'vacancy');
        this.searchManagersControl.valueChanges
          .pipe(
            switchMap((keyword) =>
              this.kernelService.getManagers(this.vacancyDepartmentId, keyword),
            ),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((managers) => {
            this.managersByVacancy = managers;
            this.changeDetection.markForCheck();
          });

        this.store$
          .pipe(select(getVacancyDetails), takeUntil(this.ngUnsubscribe))
          .subscribe((vacancyDetails) => {
            const email = getVacancyCustomFieldValue(managersFieldId, vacancyDetails);
            this.vacancyDepartmentId = getVacancyCustomFieldValue(
              departmentFieldId,
              vacancyDetails,
            );
            this.searchManagersControl.setValue(email);
          });
        break;
      }
      case 'departmentId': {
        this.departments$ = combineLatest([
          this.searchDepartmentsControl.valueChanges.pipe(startWith(''), debounceTime(300)),
          this.store$.pipe(
            select(getDepartments),
            tap((departments) => (this.departments = departments)),
          ),
        ]).pipe(
          map(([keyword, departments]) => {
            const kr = new RegExp(keyword, 'gi');

            return departments.filter((department) => {
              return anyPass([
                propSatisfies(test(kr), 'name'),
                propSatisfies(test(kr), 'id'),
                propSatisfies(test(kr), 'parentsChain'),
              ])(department);
            });
          }),
        );
        break;
      }
      default:
        break;
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get selectedDepartmentData(): string | null {
    if (!this.selectedDepartment) {
      return null;
    }

    return `${this.selectedDepartment.name} (${this.selectedDepartment.id})`;
  }
  public get selectedDepartmentParents(): string | null {
    if (!this.selectedDepartment) {
      return null;
    }

    return this.selectedDepartment.parentsChain;
  }
  public get selectorItems(): string[] | null {
    switch (this.customFieldName) {
      case 'securityGroups':
      case 'business':
      case 'vacancyCategory':
        return pluck('name', this.dictionary);
      case 'securityCategories':
        return this.securityCategories;
      default:
        return null;
    }
  }
  public selectionChange(value: string) {
    if (equals(this.customFieldName, 'securityGroups')) {
      const securityGroup = find(propEq(value, 'name'), this.dictionary) as DictionaryItem;
      this.securityGroupChanged.emit(securityGroup);
    }
    this.onChange.emit(value);
  }

  public trackByItem(_index: number, element: string) {
    return element;
  }
  public trackById(
    _index: number,
    element: KernelEmployee | DictionaryDepartment | DictionaryItem,
  ) {
    return element.id;
  }

  private get selectedDepartment(): DictionaryDepartment | undefined {
    if (!this.internalControl?.value) {
      return null;
    }

    return find((d) => d.id === this.internalControl.value, this.departments);
  }
}
