import { DictionariesEnum } from '@app-shared/models';
import { DictionariesState } from '@app-shared/reducers/dictionary/dictionary.reducer';
import {
  addNewClientDictionaries,
  addNewDirectoryDictionaries,
  addNewTalentDictionaries,
  addNewVacancyDictionaries,
} from '@app-shared/services/profile/new-entity.config';
import {
  profileDetailsDictionaries,
  profileDictionaries,
} from '@app-shared/services/profile/profile.config';
import {
  vacanciesListDictionaries,
  vacancyProfileDictionaries,
} from '@app-shared/services/vacancy/vacancy.config';
import { filter, isNil, pipe, prop } from 'ramda';

export function getModuleRelatedDictionaries(moduleName: string): DictionariesEnum[] {
  switch (moduleName) {
    case 'kernelStructure':
      return [DictionariesEnum.securityGroups, DictionariesEnum.recruiters];
    case 'profileDetails':
      return profileDetailsDictionaries;
    case 'newClient':
      return addNewClientDictionaries;
    case 'newVacancy':
      return addNewVacancyDictionaries;
    case 'newDirectory':
      return addNewDirectoryDictionaries;
    case 'newTalent':
      return addNewTalentDictionaries;
    case 'vacanciesList':
      return vacanciesListDictionaries;
    case 'vacancyProfiles':
      return vacancyProfileDictionaries;
    case 'newCity':
      return [DictionariesEnum.countries, DictionariesEnum.regions];
    case 'searchConsole':
    default:
      return profileDictionaries;
  }
}

export function checkUnresolvedDictionaries(
  store: DictionariesState,
  dictionaryType: string,
): DictionariesEnum[] {
  const dictionariesList = getModuleRelatedDictionaries(dictionaryType);

  return filter(
    (dictionaryName: DictionariesEnum): boolean => pipe(prop(dictionaryName), isNil)(store),
    dictionariesList,
  );
}
