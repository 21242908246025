import { createAction, props, union } from '@ngrx/store';
import { TrackerEvent } from '@app-shared/models';

const TrackActivateTrialAction = createAction(
  '[Notify Tracker] Activate trial period',
  props<TrackerEvent>(),
);
const TrackAddCommentToProfileAction = createAction(
  '[Notify Tracker] Add comment to selected profile',
  props<TrackerEvent>(),
);
const TrackAddToLongListAction = createAction(
  '[Notify Tracker] Add Profile to long list event',
  props<TrackerEvent>(),
);
const TrackChangeSkillWeightAction = createAction(
  '[Notify Tracker] Change skill event',
  props<TrackerEvent>(),
);
const TrackCreateShortListAction = createAction(
  '[Notify Tracker] Create short list event',
  props<TrackerEvent>(),
);
const TrackCreateVacancyAction = createAction(
  '[Notify Tracker] Create vacancy event',
  props<TrackerEvent>(),
);
const TrackInitApplicationAction = createAction(
  '[Notify Tracker] Init application',
  props<TrackerEvent>(),
);
const TrackInitProfileDetailsPageAction = createAction(
  '[Notify Tracker] Visit Profile Details page',
  props<TrackerEvent>(),
);
const TrackInitProfilesListPageAction = createAction(
  '[Notify Tracker] Visit Profiles List page',
  props<TrackerEvent>(),
);
const TrackInitVacanciesListPageAction = createAction(
  '[Notify Tracker] Visit Vacancies List page',
  props<TrackerEvent>(),
);
const TrackInitVacancyProfilesPageAction = createAction(
  '[Notify Tracker] Visit Vacancy Profiles page',
  props<TrackerEvent>(),
);
const TrackSearchProfilesAction = createAction(
  '[Notify Tracker] Search profiles using Search Console',
  props<TrackerEvent>(),
);
const TrackSentNotificationsAction = createAction(
  '[Notify Tracker] Sent notification event',
  props<TrackerEvent>(),
);
const TrackUpdateProfileSkillsAction = createAction(
  '[Notify Tracker] Update skills for profile event',
  props<TrackerEvent>(),
);
const TrackUpdateProfileTagsAction = createAction(
  '[Notify Tracker] Update tags for profile event',
  props<TrackerEvent>(),
);
const TrackUpdateVacancyAction = createAction(
  '[Notify Tracker] Update vacancy event',
  props<TrackerEvent>(),
);

const actions = {
  TrackActivateTrialAction,
  TrackAddCommentToProfileAction,
  TrackAddToLongListAction,
  TrackChangeSkillWeightAction,
  TrackCreateShortListAction,
  TrackCreateVacancyAction,
  TrackInitApplicationAction,
  TrackInitProfileDetailsPageAction,
  TrackInitProfilesListPageAction,
  TrackInitVacanciesListPageAction,
  TrackInitVacancyProfilesPageAction,
  TrackSearchProfilesAction,
  TrackSentNotificationsAction,
  TrackUpdateProfileSkillsAction,
  TrackUpdateProfileTagsAction,
  TrackUpdateVacancyAction,
};

const notifyTrackersActions = union(actions);

export type NotifyTrackersActionsType = typeof notifyTrackersActions;

export const NotifyTrackersActions = actions;
