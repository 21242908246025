import { DirectoryCustomFieldResponse, Entity } from '.';

export interface ClientShort extends Entity {
  clientType: number;
  color?: string;
  createdAt: string;
  email?: string;
  firstName: string;
  lastName: string;
  name?: string;
  phone?: string;
  skype?: string;
  teamName?: string;
  userId?: number | null;
}
export interface ClientFull extends ClientShort {
  authors: Entity[];
  city: string | null;
  country: string | null;
  cvSendCandidates?: number;
  customFields: DirectoryCustomFieldResponse[];
  fieldValues: DirectoryCustomFieldResponse[];
  fullName: string;
  hiredCandidates: number;
  interviewCandidates: number;
  location: string;
  vacanciesCount: number;
}
export interface ClientsList {
  items: ClientShort[];
  count: number;
}
