@if (entityForm) {
  <form class="c-tsp-mat-form" [formGroup]="entityForm" novalidate>
    <div class="c-tsp-mat-form-field__row">
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label c-new-directory-dialog__locale-select"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.language.label</mat-label>
        <mat-select formControlName="specialityLocale">
          @for (locale of locales; track locale) {
            <mat-option class="c-tsp-mat-form-field-option" [value]="locale.name">
              {{ locale.title }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="c-tsp-mat-form-field c-tsp-mat-form-field--with-label"
        floatLabel="always"
      >
        <mat-label translate>add-new-entity.new-directory.form.name.label</mat-label>
        <input
          matInput
          class="c-tsp-mat-form-field-input"
          [formControl]="entityForm | getFormControl: selectedLocale"
          [placeholder]="'add-new-entity.new-directory.form.name.placeholder' | translate"
        />
      </mat-form-field>
    </div>
  </form>
}
