import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import uk from '@angular/common/locales/uk';
import { APP_INITIALIZER, enableProdMode, LOCALE_ID, Provider } from '@angular/core';
import { VAPID_KEY } from '@angular/fire/compat/messaging';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import {
  AppMetadataEffects,
  AuthEffects,
  InternalNotificationEffects,
  RouterEffects,
  ScriptInjectionEffects,
} from '@app-shared/effects';
import {
  headerInterceptor,
  localDataInterceptor,
  networkErrorInterceptor,
  networkErrorRetryInterceptor,
  urlInterceptor,
} from '@app-shared/interceptors';
import { AppVersionData } from '@app-shared/models/configuration.model';
import { reducers, State } from '@app-shared/reducers';
import {
  appFeaturesResolver,
  appVersionResolver,
  authTokenResolver,
  datadogMonitorResolver,
} from '@app-shared/resolvers';
import { CustomRouterStateSerializer } from '@app-shared/serializers/router-state/custom-router-state-serializer';
import { provideEffects } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  provideRouterStore,
  RouterStateSerializer,
} from '@ngrx/router-store';
import { provideStore, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routes } from 'app/routes';
import { provideToastr } from 'ngx-toastr';
import { map } from 'rxjs';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

registerLocaleData(uk);

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

const appVersionFactory = (store: Store<State>, httpClient: HttpClient) => () => {
  return httpClient
    .get('./assets/settings/version.json')
    .pipe(map((appVersion: AppVersionData) => appVersionResolver(store, appVersion)));
};
const appFeaturesFactory = (store: Store<State>) => () => appFeaturesResolver(store);
const authStateFactory = (store: Store<State>) => () => authTokenResolver(store);
const datadogMonitorFactory = (store: Store<State>) => () => datadogMonitorResolver(store);

const appProviders: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: appVersionFactory,
    deps: [Store<State>, HttpClient],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appFeaturesFactory,
    deps: [Store<State>],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: datadogMonitorFactory,
    deps: [Store<State>],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: authStateFactory,
    deps: [Store<State>],
    multi: true,
  },
  {
    provide: RouterStateSerializer,
    useClass: CustomRouterStateSerializer,
  },
  {
    provide: VAPID_KEY,
    useValue: environment.firebaseWebPushKey,
  },
  {
    provide: LOCALE_ID,
    useValue: 'uk',
  },
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideStore(reducers),
    provideRouterStore({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
    provideStoreDevtools({ connectInZone: true, logOnly: environment.production }),
    provideEffects(
      AppMetadataEffects,
      AuthEffects,
      InternalNotificationEffects,
      RouterEffects,
      ScriptInjectionEffects,
    ),
    provideTranslateService({
      defaultLanguage: 'uk',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    ...appProviders,
    provideAnimations(),
    provideAnimationsAsync(),
    provideToastr({
      enableHtml: true,
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr c-notification',
    }),
    provideHttpClient(
      withInterceptors([
        localDataInterceptor,
        urlInterceptor,
        headerInterceptor,
        networkErrorInterceptor,
        networkErrorRetryInterceptor,
      ]),
    ),
  ],
}).catch((err) => console.error(err));
