@if (data.showManagerField) {
  <short-list-dialog-managerial [data]="data" [form]="form"> </short-list-dialog-managerial>
}

@if (data.showApprovalFields) {
  <short-list-dialog-approve
    [currencies]="currencies"
    [customFields]="data.customFields"
    [fieldValues]="data.fieldValues"
    [form]="form"
    [shortListProjectId]="data.shortListProjectId"
  >
  </short-list-dialog-approve>
}

@if (data.showReviewFields) {
  <short-list-dialog-review [form]="form" [cvs]="data.cvs" [profileId]="data.profileId">
  </short-list-dialog-review>
}
