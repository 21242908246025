import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DictionaryItem, Skill, Tag, TalentFormTabs } from '@app-shared/models';
import { TranslatePipe } from '@ngx-translate/core';
import { ConvertIdToNamePipe } from '@tsp-pipes';
import { anyPass, isEmpty, isNil } from 'ramda';
import { CastSkillTypePipe } from '../pipes/cast-skill-type/cast-skill-type.pipe';
import { CastTagTypePipe } from '../pipes/cast-tag-type/cast-tag-type.pipe';
import { ProfileSkillElementComponent } from '../profile-skill-element/profile-skill-element.component';

@Component({
  imports: [
    CastSkillTypePipe,
    CastTagTypePipe,
    ConvertIdToNamePipe,
    MatTooltip,
    NgClass,
    ProfileSkillElementComponent,

    TranslatePipe,
  ],
  selector: 'profile-dynamic-list',
  templateUrl: './profile-dynamic-list.component.html',
  styleUrls: ['./profile-dynamic-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileDynamicListComponent {
  @Input()
  public dictionary: DictionaryItem[];
  @Input()
  public type: string;
  @Input()
  public elements: Skill[] | Tag[];
  @Input()
  public profileId: string;
  @Input()
  public showDetailedInfo: boolean;
  @Input()
  public showExpandButton: boolean;
  @Input()
  public showSkillsTooltip: boolean;
  @Input()
  public showAllElements = false;
  @Input()
  public showSuggested = false;
  @Input()
  public skillsBlockingState = false;
  @Input()
  public suggestedSkills: number;
  @Output() public editProfile = new EventEmitter<TalentFormTabs>();

  public editDetails() {
    this.editProfile.emit(TalentFormTabs.DETAILS);
  }
  public trackById(_index: number, element: Skill | Tag) {
    return element.id;
  }

  public toggleFullList(showAll: boolean) {
    this.showAllElements = showAll;
  }

  public get isEmpty(): boolean {
    return anyPass([isNil, isEmpty])(this.elements);
  }
}
