<div class="c-profile-short-list-project">
  <span class="c-profile-short-list-project__name">
    {{ projectName }}
  </span>
  <a class="c-profile-short-list-project__link"
      [routerLink]="['/', { outlets: { primary: [locale, 'vacancy', projectId, type], extras: null } }]">
    <i class="fas fa-external-link-alt"
        aria-hidden="true"></i>
  </a>
</div>
