import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DictionaryItem, Profile, Skill, TalentFormTabs, UnaryOperator } from '@app-shared/models';
import { ProfileDynamicListComponent } from '@tsp-components/profile-dynamic-list';
import { SkillsSortPipe } from '@tsp-pipes';
import {
  allPass,
  always,
  anyPass,
  isEmpty,
  isNil,
  mergeRight,
  pipe,
  prepend,
  prop,
  propOr,
  propSatisfies,
  reject,
  uniqBy,
  when,
} from 'ramda';

@Component({
  imports: [ProfileDynamicListComponent, SkillsSortPipe],
  selector: 'profile-skills',
  templateUrl: './profile-skills.component.html',
  styleUrls: ['./profile-skills.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileSkillsComponent {
  @Input()
  public profile: Profile;
  @Input()
  public skillsDictionary: DictionaryItem[];
  @Input()
  public activeSkillsFilters: number[];
  @Input()
  public skillType: 'skill' | 'soft_skill' = 'skill';
  @Input()
  public skillsBlockingState = false;
  @Input()
  public showDetailedInfo: boolean;
  @Input()
  public showExpandButton = true;
  @Input()
  public showMainSkill = false;
  @Input()
  public showSuggested = false;
  @Input()
  public suggestedSkills: number;
  @Output() public editProfile: EventEmitter<TalentFormTabs> = new EventEmitter();

  public get profileSkills(): Skill[] {
    const type = this.skillType === 'skill' ? 'skills' : 'softSkills';

    return pipe(
      propOr([], type) as UnaryOperator<Profile, Skill[]>,
      reject(propSatisfies(isNil, 'id') as UnaryOperator<Skill, boolean>) as UnaryOperator<
        Skill[],
        Skill[]
      >,
      when(
        allPass([
          always(this.showMainSkill),
          () => this.skillType === 'skill',
          () => !anyPass([isNil, isEmpty])(this.profile.mainSkill),
        ]),
        prepend(mergeRight(this.profile.mainSkill, { main: true })),
      ),
      uniqBy(prop('id')) as UnaryOperator<Skill[], Skill[]>,
    )(this.profile);
  }
}
