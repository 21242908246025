import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentPayload, CommentResponse } from '@app-shared/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewEntityService {
  constructor(private readonly http: HttpClient) {}

  public createComment(target: string, payload: CommentPayload): Observable<CommentResponse> {
    return this.http.post<CommentResponse>(`/entity/comment/${target}`, payload);
  }
  public uploadMailAttachment(formData: FormData): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(`/dictionaries/mail-attachments/upload`, formData, {
      headers: { 'file-uploading': 'true' },
    });
  }
  public uploadFile<T>(formData: FormData): Observable<T> {
    return this.http.post<T>(`/entity/file`, formData, {
      headers: { 'file-uploading': 'true' },
    });
  }
}
