import { ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  MatChip,
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent,
  MatChipRemove,
} from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { TagSelectorTheme } from '@app-shared/enums/selection-states.enum';
import {
  AutocompleteGroup,
  AutocompleteItem,
  DictionaryItem,
  UnaryOperator,
} from '@app-shared/models';
import { DictionaryService } from '@app-shared/services/dictionary/dictionary.service';
import { TranslatePipe } from '@ngx-translate/core';
import { AutocompleteItemHighlightPipe, ShowSynonymsPipe } from '@tsp-pipes';
import {
  anyPass,
  differenceWith,
  drop,
  flatten,
  groupBy,
  has,
  head,
  ifElse,
  innerJoin,
  is,
  isEmpty,
  isNil,
  mapObjIndexed,
  mergeRight,
  omit,
  path,
  pipe,
  pluck,
  prop,
  propOr,
  props,
  map as ramdaMap,
  reject,
  test,
  values,
  when,
  without,
} from 'ramda';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

const keywordLengthLimit = 50;

interface SearchValue {
  keywords?: string[];
  keywordsExceptions?: string[];
  skills?: number[];
  skillsExceptions?: number[];
  tags?: number[];
  tagsExceptions?: number[];
}

interface Tag {
  id: number | string;
  name: string;
  tag?: string;
}
@Component({
  imports: [
    AsyncPipe,
    AutocompleteItemHighlightPipe,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatChip,
    MatChipGrid,
    MatChipInput,
    MatChipRemove,
    MatFormField,
    MatOption,
    NgClass,
    ReactiveFormsModule,
    ShowSynonymsPipe,
    TranslatePipe,
  ],
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TagInputComponent implements OnInit {
  @Input()
  public allowedExceptions = false;
  @Input()
  public tagsType = 'keywords';
  @Input()
  public set autocompleteItems(items: DictionaryItem[]) {
    this.normalDictionary = items ? [...items] : [];
    this.exceptionDictionary = this.allowedExceptions
      ? ramdaMap((item: DictionaryItem) => mergeRight(item, { name: `!${item.name}` }), items)
      : [];
    this.dictionary = this.normalDictionary;
  }
  @Input()
  public idsToExclude?: number[];
  @Input()
  public set theme(themeName: TagSelectorTheme) {
    this.innerThemeValue = themeName;
  }
  @Input()
  public set controlValues(data: SearchValue) {
    this.setValue(data);
  }
  @Input()
  public customPlaceholder: string;
  @Output()
  public onChange = new EventEmitter<SearchValue>();
  @Output()
  public closeAndSearch = new EventEmitter<void>();
  @ViewChild('auto', { static: true }) public matAutocomplete: MatAutocomplete;
  @ViewChild('tagInput', { static: true }) public tagInput: ElementRef<HTMLInputElement>;

  public innerThemeValue: TagSelectorTheme = 'dark-theme';
  public dictionaryItems$: Observable<AutocompleteItem[]>;
  public dictionary: DictionaryItem[];
  public separatorKeysCodes: number[] = [ENTER];
  public tagControl = new UntypedFormControl();
  public searchInputControl = new UntypedFormControl('');
  public selectedTags: (Tag | string)[] = [];
  private normalDictionary: DictionaryItem[];
  private exceptionDictionary: DictionaryItem[];

  constructor(private readonly dictionaryService: DictionaryService) {}

  public ngOnInit(): void {
    this.dictionaryItems$ = this.tagControl.valueChanges.pipe(
      startWith(''),
      filter(() => this.tagsType !== 'keywords'),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((keyword: string) =>
        this.dictionaryService.getAutocompleteItems(keyword, this.tagsType),
      ),
      map(
        ifElse(
          anyPass([isNil, isEmpty]),
          () => [],
          when(
            pipe(head, has('groupName')),
            pipe(path([0, 'items']), (val: DictionaryItem[]) =>
              differenceWith(
                (item: DictionaryItem, id: number) => item.id === id,
                val,
              )(this.idsToExclude || []),
            ),
          ),
        ) as UnaryOperator<AutocompleteGroup[], AutocompleteItem[]>,
      ),
    );
  }

  public trackById: TrackByFunction<Tag | string> = (
    _index: number,
    tag: Tag | string,
  ): number | string => {
    // TODO: Remove `as unknown` after Ramda definitions update
    return propOr(null, 'id', tag) as unknown as string | number;
  };

  public get secondaryPlaceholder(): string {
    return this.customPlaceholder || `shared.tag-input.search-by.${this.tagsType}`;
  }
  public addTag(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen && this.tagsType === 'keywords') {
      const value =
        event.value.length > keywordLengthLimit
          ? `${event.value.slice(0, keywordLengthLimit)}...`
          : event.value;

      if (value) {
        this.updateControls(value);
      }
    }
  }
  public getTagName(tag: Tag | string): string {
    if (this.tagsType === 'keywords') {
      return tag as string;
    }
    return pipe(
      props(['tag', 'name']) as UnaryOperator<Tag, string[]>,
      reject(anyPass([isNil, isEmpty])),
      head,
    )(tag as Tag) as string;
  }
  public onInputKeyPress(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'Enter') {
      this.closeAndSearch.emit();
    }
  }
  public removeTag(tag: Tag | string): void {
    this.selectedTags = without([tag], this.selectedTags);
    const parsedValues = this.decomposeTags(this.selectedTags);
    this.onChange.emit(parsedValues);
    this.tagControl.setValue(null);
  }
  public selectTag(event: MatAutocompleteSelectedEvent): void {
    this.updateControls(event.option.value as Tag);
  }

  private decomposeTags(tags: (Tag | string)[]): SearchValue {
    if (this.tagsType === 'keywords') {
      return { keywords: this.selectedTags } as SearchValue;
    }
    const convertTags = (tag: {
      id: number | string;
      name: string;
    }): { name: string; value: string | number } => {
      let name: string;
      let value: string | number;
      switch (true) {
        case is(String, tag.id):
          name = test(/^!/, tag.name) ? 'keywordsExceptions' : 'keywords';
          value = test(/^!/, tag.id) ? drop(1, tag.id) : tag.id;
          break;
        case test(/^!/, tag.name):
          name = `${this.tagsType}Exceptions`;
          value = tag.id;
          break;
        default:
          name = this.tagsType;
          value = tag.id;
      }
      return {
        name,
        value,
      };
    };

    return isEmpty(tags)
      ? { [this.tagsType]: [] }
      : pipe(
          ramdaMap(convertTags) as UnaryOperator<Tag[], { name: string; value: string | number }[]>,
          groupBy(
            prop('name') as UnaryOperator<{ name: string; value: string | number }, string>,
          ) as UnaryOperator<
            { name: string; value: string | number }[],
            Record<string, { name: string; value: string | number }[]>
          >,
          mapObjIndexed(pluck('value')) as UnaryOperator<
            Record<string, { name: string; value: string | number }[]>,
            SearchValue
          >,
        )(tags as Tag[]);
  }
  private generateTags(
    ids: number[] | string[],
    propertyName: string,
  ): (DictionaryItem | string)[] | { id: string; name: string }[] {
    switch (propertyName) {
      case 'skills':
      case 'tags':
      case 'skillsExceptions':
      case 'tagsExceptions': {
        const dictionary = test(/Exceptions$/, propertyName)
          ? this.exceptionDictionary
          : this.normalDictionary;

        return innerJoin(
          (dictionaryItem: DictionaryItem, id) => dictionaryItem.id === id,
          dictionary,
          ids as number[],
        );
      }
      case 'keywords':
        return ids as string[];
      case 'keywordsExceptions':
        return ramdaMap((id: string) => ({ id, name: `!${id}` }), ids as string[]);
      default:
        return [];
    }
  }
  private setValue(newData: SearchValue) {
    const newTags = pipe(
      omit(['target']) as UnaryOperator<SearchValue, SearchValue>,
      reject(anyPass([isEmpty, isNil])) as UnaryOperator<SearchValue, SearchValue>,
      mapObjIndexed((v: string[] | number[], k: string) => this.generateTags(v, k)),
      values,
      flatten,
    )(newData) as DictionaryItem[];
    this.selectedTags = newTags;
  }
  private updateControls(tag: Tag | string) {
    this.selectedTags.push(tag);
    this.tagInput.nativeElement.value = '';
    const parsedValues = this.decomposeTags(this.selectedTags);
    this.onChange.emit(parsedValues);
    this.tagControl.setValue(null);
  }
}
