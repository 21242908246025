import { Pipe, PipeTransform } from '@angular/core';
import { KernelEmployee } from '@app-integration/kernel/models/kernel.model';
import {
  AllowedOptionType,
  ClientShort,
  CurrentUser,
  Profile,
  ProfileSuggestion,
  RecruiterInfo,
  SimpleUser,
  UnaryOperator,
} from '@app-shared/models';
import {
  ifElse,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  propOr,
  props,
  reject,
  slice,
  split,
  take,
  when,
} from 'ramda';

type AllowedEntities =
  | Partial<CurrentUser>
  | Profile
  | ProfileSuggestion
  | ClientShort
  | RecruiterInfo
  | SimpleUser
  | AllowedOptionType
  | KernelEmployee;

@Pipe({
  name: 'getInitials',
  standalone: true,
})
export class GetInitialsPipe implements PipeTransform {
  public transform(profile: AllowedEntities): string {
    return pipe(
      props(['firstName', 'lastName']) as UnaryOperator<AllowedEntities, string[]>,
      when(
        pipe(reject(isNil), isEmpty),
        pipe(() => propOr('', 'name', profile), split(' ')),
      ),
      map(ifElse(isNil, () => '', take(1))),
      join(''),
      slice(0, 2) as UnaryOperator<string, string>,
    )(profile);
  }
}
