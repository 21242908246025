import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { isEmpty, isNil, not, pipe, trim, unless } from 'ramda';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface SelectionRectangle {
  left: number;
  top: number;
}

@Directive({
  selector: '[appTextSelect]',
  standalone: true,
})
export class TextSelectDirective implements OnInit, OnDestroy {
  @Input()
  public textSelectionDisabled = false;
  @Output()
  public pasteKeywordToSearch = new EventEmitter<{
    action: 'add' | 'exclude';
    keyword: string;
  }>();

  private addButtonListener: () => void;
  private excludeButtonListener: () => void;
  private mouseupListener: () => void;
  private selectionListener: () => void;

  private hasSelection = false;
  private controlsElement: HTMLElement;
  private selectedText: string;
  private readonly element: HTMLElement;
  private readonly onSelection = new Subject<void>();
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.element = el.nativeElement;
  }

  public ngOnInit(): void {
    if (this.textSelectionDisabled) {
      return;
    }
    this.addListeners();

    this.onSelection.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.hasSelection) {
        this.processSelection();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.textSelectionDisabled) {
      return;
    }
    this.excludeButtonListener();
    this.addButtonListener();
    this.mouseupListener();
    this.selectionListener();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private processSelection(): void {
    const selection = document.getSelection();
    if (this.hasSelection) {
      this.hasSelection = false;
      this.renderer.setStyle(this.controlsElement, 'display', 'none');
    }
    if (!selection.rangeCount || !this.isTextSelected(selection.toString())) {
      return;
    }

    const range = selection.getRangeAt(0);
    const rangeContainer = this.getRangeContainer(range);
    if (this.element.contains(rangeContainer)) {
      const viewportRectangle = range.getBoundingClientRect();
      const localRectangle = this.viewportToHost(viewportRectangle);
      const requiredStyles = {
        display: 'block',
        left: `${localRectangle.left - 30}px`,
        top: `${localRectangle.top - 30}px`,
      };

      this.selectedText = selection.toString().trim();
      Object.keys(requiredStyles).forEach((newStyle) => {
        this.renderer.setStyle(this.controlsElement, newStyle, requiredStyles[newStyle]);
      });
      this.hasSelection = true;
    }
  }

  private getRangeContainer(range: Range): Node {
    let container = range.commonAncestorContainer;
    while (container.nodeType !== Node.ELEMENT_NODE) {
      container = container.parentNode;
    }
    return container;
  }

  private viewportToHost(viewportRectangle: SelectionRectangle): SelectionRectangle {
    const hostRectangle = this.element.getBoundingClientRect();
    return {
      left: viewportRectangle.left - hostRectangle.left,
      top: viewportRectangle.top - hostRectangle.top,
    };
  }

  private isTextSelected(text: string): boolean {
    return unless(isNil, pipe(trim, isEmpty, not))(text) as boolean;
  }

  private addListeners() {
    this.controlsElement = this.element.querySelector(
      '.c-profile-preview__selection-controls-wrapper',
    );
    const addButton = this.controlsElement.querySelector(
      '.c-profile-preview__selection-controls-item--add',
    );
    const excludeButton = this.controlsElement.querySelector(
      '.c-profile-preview__selection-controls-item--exclude',
    );

    this.addButtonListener = this.renderer.listen(addButton, 'click', (event: MouseEvent) =>
      this.handleButtonClick(event, 'add'),
    );
    this.excludeButtonListener = this.renderer.listen(excludeButton, 'click', (event: MouseEvent) =>
      this.handleButtonClick(event, 'exclude'),
    );
    this.mouseupListener = this.renderer.listen(this.element, 'mouseup', this.handleMouseup);
    this.selectionListener = this.renderer.listen(
      document,
      'selectionchange',
      this.handleSelectionchange,
    );
  }
  private readonly handleButtonClick = (event: MouseEvent, action: 'add' | 'exclude'): void => {
    event.stopPropagation();
    document.getSelection().removeAllRanges();
    this.pasteKeywordToSearch.emit({ action, keyword: this.selectedText });
  };

  private readonly handleMouseup = (): void => {
    this.processSelection();
  };

  private readonly handleSelectionchange = (): void => {
    this.onSelection.next();
  };
}
