/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { VacanciesListSystemActions } from '@app-core/vacancies-list/actions/vacancies-list.system.actions';
import { VacanciesListUserActions } from '@app-core/vacancies-list/actions/vacancies-list.user.actions';
import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';
import { VacancyApiActions } from '@app-shared/actions/api-actions/vacancy-api.actions';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { NotifyTrackersActions } from '@app-shared/actions/notify-trackers.actions';
import { RouterActions } from '@app-shared/actions/router.actions';
import { SearchParams, VacancyDetails } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getActiveModule } from '@app-shared/reducers/router/router.reducer';
import { VacancyService } from '@app-shared/services/vacancy/vacancy.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { VacancySelectorSystemActions } from '@tsp-components/vacancy-selector';
import { path } from 'ramda';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class NewEntityVacancyEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<State>,
    private readonly vacancyService: VacancyService,
  ) {}

  public analyzeVacancyTitleAndDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.AnalyzeTitleAndDescription),
      switchMap(({ title, description }) =>
        this.vacancyService.analyzeVacancyTitleAndDescription(title, description).pipe(
          map((parameters) =>
            AddNewEntitySystemActions.AnalyzeTitleAndDescriptionSuccess({ parameters }),
          ),
          catchError(() => of(AddNewEntitySystemActions.AnalyzeTitleAndDescriptionFailed())),
        ),
      ),
    ),
  );

  public deleteVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.DeleteVacancyFromPopupAction),
      exhaustMap(({ id }) =>
        this.vacancyService.deleteVacancy(id).pipe(
          mergeMap(() => [
            InternalNotificationActions.SuccessNotificationAction({
              message: 'add-new-entity.effects.deleted.vacancy',
            }),
            VacancyApiActions.DeleteVacancyOnSymfonySuccessAction({ id }),
            RootSystemActions.UnselectVacancyAfterDeletingAction({ vacancyId: id }),
            NotifyTrackersActions.TrackUpdateProfileTagsAction({
              category: 'Vacancy',
              action: `delete`,
            }),
            VacanciesListSystemActions.RefreshVacanciesListAction(),
          ]),
          catchError(() => of(VacancyApiActions.DeleteVacancyOnSymfonyFailedAction())),
        ),
      ),
    ),
  );

  public refreshVacancyAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VacancyApiActions.DeleteVacancyOnSymfonySuccessAction),
      withLatestFrom(this.store$),
      map(([, state]) => ({
        currentModule: getActiveModule(state),
      })),
      mergeMap(({ currentModule }) => {
        let actions: Action[];
        switch (currentModule) {
          case 'vacancies':
            actions = [
              VacanciesListSystemActions.RefreshVacanciesListAction(),
              VacancySelectorSystemActions.ClearVacanciesStoreAfterEditingAction(),
            ];
            break;
          case 'vacancy':
            actions = [
              RouterActions.NavigateToVacanciesListAction({ params: {} }),
              VacancySelectorSystemActions.ClearVacanciesStoreAfterEditingAction(),
            ];
            break;
          default:
            actions = [VacancySelectorSystemActions.RefreshVacanciesAfterEditingAction({})];
        }
        return actions;
      }),
    ),
  );

  public refreshVacancyAfterEditing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AddNewEntitySystemActions.SaveNewVacancySuccessAction,
        VacancyApiActions.UpdateVacancySuccessAction,
      ),
      withLatestFrom(this.store$),
      map(([action, state]) => ({
        ...action,
        currentModule: getActiveModule(state),
      })),
      mergeMap(({ currentModule, id }) => {
        let actions: Action[];
        switch (currentModule) {
          case 'vacancies':
            actions = [
              VacanciesListSystemActions.RefreshVacanciesListAction(),
              VacancySelectorSystemActions.ClearVacanciesStoreAfterEditingAction(),
            ];
            break;
          case 'vacancy':
            actions = [
              VacancyProfilesSystemActions.RefreshVacancyDetailsAfterEditingAction({
                vacancyId: id,
              }),
              VacancySelectorSystemActions.ClearVacanciesStoreAfterEditingAction(),
            ];
            break;
          default:
            actions = [VacancySelectorSystemActions.RefreshVacanciesAfterEditingAction({})];
        }
        return actions;
      }),
    ),
  );

  public requestVacancyDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.RequestVacancyDetailsOnInitAction),
      exhaustMap(({ id }) =>
        this.vacancyService.getVacancy(id).pipe(
          map((vacancyPayload) =>
            VacancyApiActions.GetVacancyDetailsSuccessAction({
              vacancyDetails: vacancyPayload,
            }),
          ),
          catchError(() => of(VacancyApiActions.GetVacancyDetailsFailedAction({ id }))),
        ),
      ),
    ),
  );

  public saveNewVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CreateNewVacancyAction),
      exhaustMap(({ data, goToSearch }) =>
        this.vacancyService.saveNewVacancy(data).pipe(
          mergeMap((newVacancy: VacancyDetails) => {
            const specialityId: number = path(['speciality', 'id'], data);
            let searchParams: SearchParams = {};
            if (specialityId) {
              searchParams = { specialities: [specialityId] };
            }
            const actionsList = [
              InternalNotificationActions.SuccessNotificationAndActivityAction({
                message: 'add-new-entity.effects.created.vacancy',
                action: RouterActions.NavigateToVacancyProfilesAction({
                  path: ['vacancy', newVacancy.id, 'long_list'],
                }),
              }),
              AddNewEntitySystemActions.SaveNewVacancySuccessAction({ id: newVacancy.id }),
              NotifyTrackersActions.TrackCreateVacancyAction({
                category: 'Vacancy',
                action: `create`,
              }),
            ];
            if (goToSearch) {
              return [
                ...actionsList,
                VacanciesListUserActions.NavigateToProfilesFromVacancyAction({
                  id: newVacancy.id,
                  searchParams,
                }),
              ];
            }
            return actionsList;
          }),
          catchError(() => of(AddNewEntitySystemActions.SaveNewVacancyFailedAction())),
        ),
      ),
    ),
  );

  public updateVacancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.UpdateVacancyAction),
      exhaustMap(({ vacancy }) =>
        this.vacancyService.updateVacancy(vacancy).pipe(
          mergeMap((updateVacancyEntity) => [
            InternalNotificationActions.SuccessNotificationAndActivityAction({
              message: 'add-new-entity.effects.updated.vacancy',
              action: RouterActions.NavigateToVacancyProfilesAction({
                path: ['vacancy', updateVacancyEntity.id, 'long_list'],
              }),
            }),
            VacancyApiActions.UpdateVacancySuccessAction({
              id: updateVacancyEntity.id,
            }),
            NotifyTrackersActions.TrackUpdateProfileTagsAction({
              category: 'Vacancy',
              action: `update`,
            }),
          ]),
          catchError(() => of(VacancyApiActions.UpdateVacancyFailedAction({ id: vacancy.id }))),
        ),
      ),
    ),
  );
}
