@if (contacts?.length) {
  <div class="c-available-contacts">
    @for (contact of contacts | slice: 0 : contactsVisibilityLimit; track contact) {
      <div class="c-available-contacts__item-wrapper">
        @if (contact.count > 1) {
          <span class="c-available-contacts__item-badge">{{ contact.count }}</span>
        }
        @switch (contact.name) {
          @case ('email') {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fas fa-envelope" aria-hidden="true"> </i>
            </span>
          }
          @case ('phone') {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fas fa-phone" aria-hidden="true"></i>
            </span>
          }
          @case ('skype') {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fab fa-skype" aria-hidden="true"></i>
            </span>
          }
          @case ('telegram') {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fab fa-telegram-plane" aria-hidden="true"></i>
            </span>
          }
          @case ('stackoverflow') {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fab fa-stack-overflow" aria-hidden="true"></i>
            </span>
          }
          @case ('rabota_ua') {
            <span class="c-available-contacts__item rabota_ua" (click)="showContacts(contact)">
            </span>
          }
          @case ('work_ua') {
            <span class="c-available-contacts__item work_ua" (click)="showContacts(contact)">
            </span>
          }
          @case ('djinni') {
            <span class="c-available-contacts__item djinni" (click)="showContacts(contact)"> </span>
          }
          @case ('hh') {
            <span class="c-available-contacts__item hh" (click)="showContacts(contact)"> </span>
          }
          @case ('grc') {
            <span class="c-available-contacts__item grc" (click)="showContacts(contact)"> </span>
          }
          @case ('other') {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fas fa-globe" aria-hidden="true"></i>
            </span>
          }
          @default {
            <span class="c-available-contacts__item" (click)="showContacts(contact)">
              <i class="fab" [ngClass]="'fa-' + contact.name" aria-hidden="true"></i>
            </span>
          }
        }
      </div>
    }
    <button
      class="c-available-contacts__item"
      [matTooltip]="'profiles-common.profile-available-contacts.tooltip' | translate"
      (click)="showContacts({ name: 'other' })"
    >
      <i class="fas fa-ellipsis-h c-available-contacts__item-button" aria-hidden="true"> </i>
    </button>
  </div>
}
