import { createAction, props } from '@ngrx/store';

export const ClearVacanciesStoreAfterEditingAction = createAction(
  '[New Entity Effects] Clear vacancies in My Vacancies store after editing',
);
export const RefreshVacanciesAfterEditingAction = createAction(
  '[New Entity Effects] Refresh vacancies in My Vacancies selector after editing',
  props<{ keyword?: string }>(),
);
export const SaveSelectedVacancyToRecruiterProfileAction = createAction(
  '[Vacancy Effects] Save selected vacancy to recruiter profile',
  props<{ id: number }>(),
);
export const SaveSelectedVacancyToRecruiterProfileSuccessAction = createAction(
  '[Vacancy Effects] Successfully saved selected vacancy to recruiter profile',
);
export const UpdateVacancyDataAction = createAction(
  '[Profile Short List Effects] Update current vacancy data',
);
