import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEditableItem } from '@app-shared/models';

@Pipe({
  name: 'excludeOptionFromList',
  standalone: true,
})
export class ExcludeOptionFromListPipe implements PipeTransform {
  public transform(value: DictionaryEditableItem[], id: number | string): DictionaryEditableItem[] {
    return value.filter((item) => item.id !== id);
  }
}
