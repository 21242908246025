import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UnaryOperator } from '@app-shared/models/operator.model';
import { TranslateModule } from '@ngx-translate/core';
import { SafeUrlPipe } from '@tsp-pipes';
import { includes, last, pipe, split, toLower } from 'ramda';

@Component({
  imports: [MatButton, MatTooltip, TranslateModule],
  providers: [SafeUrlPipe],
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FilePreviewComponent {
  @ViewChild('filePreviewElement')
  public filePreviewElement?: ElementRef<HTMLIFrameElement>;
  @Input()
  public fileType: 'cvs' | 'testing-tasks' | 'voices' | 'otherFiles';
  @Input()
  public iframeHeight: string | number = '100%';
  @Input()
  public showRefreshButton = false;
  @Input()
  public set url(url: string) {
    this.fileUrl = url;
    this.setSrc();
  }

  public fileUrl: string;
  public isLoaded?: boolean = undefined;
  public safeSrc?: SafeResourceUrl;
  public previewerType = 'object';

  constructor(private readonly safeUrlPipe: SafeUrlPipe) {}

  public setSrc() {
    let src: string = this.fileUrl;

    const officeExtensions = [
      'doc',
      'docx',
      'dot',
      'dotm',
      'dotx',
      'odt',
      'odp',
      'ods',
      'ppt',
      'pptm',
      'pptx',
      'xls',
      'xlsm',
      'xlsx',
    ];

    const fileExtension: string = pipe(
      split('.'),
      last as UnaryOperator<string[], string>,
      toLower,
    )(this.fileUrl);

    if (this.fileType === 'voices') {
      this.previewerType = 'audio';
    } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
      this.previewerType = 'image';
    } else if (includes(fileExtension, officeExtensions)) {
      src = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(this.fileUrl)}`;
    } else if (fileExtension != 'pdf') {
      this.previewerType = 'iframe';
      src = `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(this.fileUrl)}`;
    }

    this.safeSrc = this.safeUrlPipe.transform(src);
  }
}
