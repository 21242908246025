import {
  BinaryOperator,
  CV,
  FilePayload,
  FullProfile,
  ShortList,
  ShortListCreateResponse,
  ShortListUpdateResponse,
  UnaryOperator,
} from '@app-shared/models';
import {
  add,
  adjust,
  allPass,
  applySpec,
  concat,
  converge,
  eqBy,
  findIndex,
  flip,
  ifElse,
  init,
  isNil,
  join,
  last,
  mergeRight,
  objOf,
  omit,
  path,
  pipe,
  prepend,
  prop,
  propEq,
  propOr,
  reject,
  split,
  subtract,
  toLower,
  unless,
} from 'ramda';

export function addFileToProfileDetails(
  profile: FullProfile,
  file: FilePayload,
  type: 'cvs' | 'voices' | 'otherFiles',
): FullProfile {
  const countName: 'cvsLength' | 'voicesLength' | 'otherFilesLength' = `${type}Length`;
  const cv = omit(['mimeType'], file);
  const countObject = pipe(prop(countName), add(1), objOf(countName))(profile);

  return pipe(
    prop(type),
    prepend(cv),
    objOf(type),
    mergeRight(countObject),
    mergeRight(profile),
  )(profile) as FullProfile;
}
export function addShortListToProfileDetails(
  bid: ShortListCreateResponse,
  profile: FullProfile,
): FullProfile {
  const shortList = convertSymfonyBidToShortList(bid, true);
  const newList = prepend(shortList, profile.processingList);
  return mergeRight(profile, {
    processingCount: newList.length,
    processingList: newList,
    isPrivate: true,
  });
}
export function convertSymfonyBidToShortList(
  bidData: Partial<ShortListCreateResponse | ShortListUpdateResponse>,
  isCreation: boolean,
  oldBid?: ShortList,
): ShortList {
  return isCreation
    ? (applySpec({
        id: prop('id'),
        interviewAt: prop('interviewAt'),
        project: {
          id: path(['project', 'id']),
          name: path(['project', 'title']),
        },
        salary: prop('salary'),
        source: prop('source'),
        status: prop('status'),
      })(bidData as ShortListCreateResponse) as ShortList)
    : (pipe(
        applySpec({
          authorChangeStatus: {
            firstName: path(['updated_by', 'first_name']),
            lastName: path(['updated_by', 'last_name']),
            id: path(['updated_by', 'id']),
          },
          fieldValues: propOr([], 'fieldValues'),
          id: prop('id'),
          interviewAt: propOr(null, 'interview_at'),
          project: {
            id: prop('project_id'),
            name: prop('project_name'),
          },
          rejectReason: propOr(null, 'rejectReason'),
          salary: propOr(null, 'salary'),
          source: ifElse(
            allPass([
              () => !isNil(oldBid),
              pipe(prop('source'), (source) => eqBy(prop('id'), oldBid.source, source)),
            ]),
            () => oldBid.source,
            propOr(null, 'source'),
          ),
          status: prop('status'),
          statusChangedAt: prop('updated_at'),
        }) as UnaryOperator<ShortListUpdateResponse, Partial<ShortList>>,
        unless(() => isNil(oldBid), mergeRight(oldBid)),
      )(bidData as ShortListUpdateResponse) as ShortList);
}
export function removeFileFromProfileDetails(
  profile: FullProfile,
  fileId: number,
  type: 'cvs' | 'voices' | 'otherFiles',
): FullProfile {
  const countName: 'cvsLength' | 'voicesLength' | 'otherFilesLength' = `${type}Length`;
  const countObject = pipe(prop(countName), flip(subtract)(1), objOf(countName))(profile);
  return pipe(
    prop(type) as UnaryOperator<FullProfile, CV[]>,
    reject(propEq(fileId, 'id')),
    objOf(type),
    mergeRight(countObject),
    mergeRight(profile),
  )(profile) as FullProfile;
}
export function removeShortListFromProfileDetails(
  shortListId: number,
  profile: FullProfile,
): FullProfile {
  const newList = reject(
    propEq(shortListId, 'id') as UnaryOperator<ShortList, boolean>,
    profile.processingList,
  );
  return mergeRight(profile, { processingCount: newList.length, processingList: newList });
}
export function updateShortListInProfileDetails(
  bid: ShortListUpdateResponse | Partial<ShortList>,
  profile: FullProfile,
  convert = false,
): FullProfile {
  const processingList: ShortList[] = propOr([], 'processingList', profile);
  const index = findIndex(propEq(bid.id, 'id'))(processingList);
  const newShortList = convert
    ? convertSymfonyBidToShortList(bid as ShortListUpdateResponse, false, processingList[index])
    : (bid as Partial<ShortList>);
  const newList = adjust(index, (shl) => ({ ...shl, ...newShortList }), processingList);
  return { ...profile, processingList: newList };
}

export function formFileData(
  file: File,
  toParsing: string,
  type?: string,
  id?: string | number,
  textContent = '',
): FormData {
  const name: string = pipe(
    split('.'),
    converge(concat as BinaryOperator<string, string, string>, [
      pipe(init as UnaryOperator<string[], string[]>, join('.')),
      pipe(last, toLower, concat('.')),
    ]),
  )(file.name);
  const sanitizedFile = new File([file], name, { type: file.type });
  const formData: FormData = new FormData();
  formData.append('file', sanitizedFile, sanitizedFile.name);
  formData.append('type', type || 'cv');
  formData.append('toParsing', toParsing);
  formData.append('textContent', textContent);

  if (id) {
    formData.append('id', id.toString());
  }
  return formData;
}
