<div class="c-rich-editor">
  <div class="main-container">
    <div class="editor-container editor-container_classic-editor" #editorContainerElement>
      <div class="editor-container__editor">
        <div #editorElement>
          @if (isLayoutReady) {
            <ckeditor
              [editor]="Editor"
              [config]="config"
              [data]="initialData"
              (change)="onChangeValue($event)"
            />
          }
        </div>
      </div>
    </div>
  </div>
</div>
