import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, join, pipe, pluck, unless } from 'ramda';
import { AutocompleteItem } from '@app-shared/models';

@Pipe({
  name: 'showSynonyms',
  standalone: true,
})
export class ShowSynonymsPipe implements PipeTransform {
  public transform({ name, synonyms }: AutocompleteItem): string[] {
    if (!synonyms) {
      return [`<span class="show-synonyms-pipe__title">${name}</span>`];
    }

    const synonymsList = pipe(pluck('name'), unless(isEmpty, join(', ')))(synonyms) as string;

    if (isEmpty(synonymsList)) {
      return [`<span class="show-synonyms-pipe__title">${name}</span>`];
    }
    return [
      `<span class="show-synonyms-pipe__title">${name}</span>`,
      `<span class="show-synonyms-pipe__synonyms">(incl. ${synonymsList})</span>`,
    ];
  }
}
