import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { DictionaryItem } from '@app-shared/models';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { CastToNumberPipe, CastToNumericArrayPipe, ConvertIdToNamePipe } from '@tsp-pipes';

@Component({
  imports: [
    CastToNumberPipe,
    CastToNumericArrayPipe,
    ConvertIdToNamePipe,
    MatIconButton,
    MatTooltip,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'ats-suggested-params',
  templateUrl: './suggested-params.component.html',
  styleUrls: ['./suggested-params.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SuggestedParamsComponent {
  @Input() public parameter?: number | number[] | null;
  @Input() public dictionary?: DictionaryItem[];
  @Input() public multipleSuggestions = false;
  @Output() public selectSuggestedItem? = new EventEmitter<number>();
  @Output() public removeSuggestion = new EventEmitter<void>();

  public onSelectSuggestion(): void {
    const item = this.parameter as number;

    this.selectSuggestedItem.emit(item);
  }
}
