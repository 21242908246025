import { createAction, props, union } from '@ngrx/store';

import { VacancyDetails, VacancyDictionary } from '@app-shared/models';

const DeleteVacancyOnSymfonySuccessAction = createAction(
  '[Vacancy API | Symfony] Vacancy successfully deleted',
  props<{ id: number }>(),
);
const DeleteVacancyOnSymfonyFailedAction = createAction(
  '[Vacancy API | Symfony] Deleting vacancy failed',
);
const GetSimpleVacanciesListFailedAction = createAction(
  '[Vacancy API] Request for simple vacancies failed',
);
const GetSimpleVacanciesListSuccessAction = createAction(
  '[Vacancy API] Simple vacancies saved to store',
  props<{ vacanciesList: VacancyDictionary[] }>(),
);
const GetSelectedVacancyDetailsSuccessAction = createAction(
  '[Vacancy API] Selected vacancy details saved to store',
  props<{ vacancyDetails: VacancyDetails }>(),
);
const GetSelectedVacancyDetailsFailedAction = createAction(
  '[Vacancy API] Request for selected vacancy details failed',
  props<{ id: number }>(),
);
const GetVacancyDetailsSuccessAction = createAction(
  '[Vacancy API] Vacancy details saved to store',
  props<{ vacancyDetails: VacancyDetails }>(),
);
const GetVacancyDetailsFailedAction = createAction(
  '[Vacancy API] Request vacancy details failed',
  props<{ id: number }>(),
);
const UpdateVacancySuccessAction = createAction(
  '[Vacancy API] Vacancy successfully updated',
  props<{ id: number }>(),
);
const UpdateVacancyFailedAction = createAction(
  '[Vacancy API] Vacancy data update failed',
  props<{ id: number }>(),
);

const actions = {
  DeleteVacancyOnSymfonySuccessAction,
  DeleteVacancyOnSymfonyFailedAction,
  GetSelectedVacancyDetailsSuccessAction,
  GetSelectedVacancyDetailsFailedAction,
  GetSimpleVacanciesListFailedAction,
  GetSimpleVacanciesListSuccessAction,
  GetVacancyDetailsSuccessAction,
  GetVacancyDetailsFailedAction,
  UpdateVacancySuccessAction,
  UpdateVacancyFailedAction,
};

const vacancyApiActions = union(actions);

export type VacancyApiActionsType = typeof vacancyApiActions;

export const VacancyApiActions = actions;
