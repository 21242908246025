import { EnvironmentConfiguration } from '@app-shared/models';

export const environment: EnvironmentConfiguration = {
  apiUrl: 'https://stage-service-recruiting.kernel.ua',
  angularSiteUrl: 'https://stage-recruiting.kernel.ua',
  supportUrl: 'mailto:support+kernel.stage@talentsearch.pro',
  production: false,
  hmr: false,
  googleMapsId: 'AIzaSyDbNGKli052pIJNxd_9GFkZXFKav1UMeaQ',
  googleSocialAuthId: '304832995511-3hliv9l7e8dtornu5kb9jb11h1uat6gb',
  chromePluginId: 'dkibcckknogagiomaahgbjapmeojlmjd',
  gtmId: 'GTM-K9JWL5Z',
  firebase: {
    apiKey: 'AIzaSyDK_0Ne48AU5Xa-JF6XRp7-leCqX1RIoeM',
    authDomain: 'push-notifications-k-stage.firebaseapp.com',
    projectId: 'push-notifications-k-stage',
    storageBucket: 'push-notifications-k-stage.appspot.com',
    messagingSenderId: '1002993875478',
    appId: '1:1002993875478:web:1d2a2564139d239e371337',
  },
  firebaseWebPushKey:
    'BGCjG9Wt-7yN-wa2wPpCT0wWIjvFafL9EdFoRGfUDV6kxRUOUPKvFQaUHEZ4liey537BBjy_hJQ0QycB9ksCUv8',
  defaultFeatureConfiguration: {
    allowPushNotifications: [],
    dashboardVideos: { isVisible: false, list: [] },
    portalsAllowedForLinkedInMessaging: [],
    shortInsideInformationInProfileCard: [],
    showTrelloButton: true,
    useNewVacanciesFeature: false,
    useNodeForSendingNotifications: true,
    extensionConfig: {
      url: 'https://chrome.google.com/webstore/detail/dkibcckknogagiomaahgbjapmeojlmjd?hl=en&utm_source=tspapp&utm_medium=button&utm_content=left',
      warningLabel: {
        uk: 'Chrome плагін не встановлено. Вам це потрібно для роботи з профілями з Linkedin та інших джерел',
        en: 'Chrome extension is not installed. You need it to work with profiles from Linkedin and other sources',
        ru: 'Chrome плагин не установлен. Вам это нужно для работы с профилями из Linkedin и других источников',
      },
    },
  },
  datadog: {
    applicationId: 'ffaa7ace-8ff3-4c73-9b7e-760415db1caf',
    clientToken: 'pub74b7eccc80b5f311767de31cc79aabf0',
    privacyLevel: 'mask-user-input',
  },
  integrations: {
    kernel: true,
    customFields: {
      vacancy: [
        { id: 159, name: 'departmentId' },
        { id: 160, name: 'departmentName' },
        { id: 175, name: 'workAddress' },
        { id: 185, name: 'initiatorName' },
        { id: 186, name: 'initiatorTitle' },
        { id: 188, name: 'availableBudget' },
        { id: 190, name: 'motivationSystem' },
        { id: 240, name: 'plannedPosition' },
        { id: 241, name: 'responsibilities' },
        { id: 244, name: 'securityGroups' },
        { id: 245, name: 'securityCategories' },
        { id: 249, name: 'securityGroupId' },
        { id: 289, name: 'managers' },
        { id: 301, name: 'offerApprovalRequired' },
        { id: 337, name: 'vacancyGroupGuid' },
        { id: 352, name: 'business' },
        { id: 353, name: 'vacancyCategory' },
        { id: 354, name: 'publicDescriptionOffer' },
        { id: 355, name: 'publicDescriptionRequirements' },
        { id: 356, name: 'publicDescriptionExperience' },
        { id: 357, name: 'publicDescriptionAdditionalInformation' },
        { id: 358, name: 'publicKernelEntity' },
      ],
      shortList: [
        { id: 302, name: 'budgetExcess' },
        { id: 303, name: 'candidateOfferPosition', vacancyFieldId: 240 },
        { id: 304, name: 'candidateOfferSalary', vacancyFieldId: 188 },
        { id: 305, name: 'formingOffer' },
        { id: 306, name: 'motivationSystem', vacancyFieldId: 190 },
        { id: 307, name: 'offerRecruiterComment' },
        { id: 308, name: 'positionChanged' },
        { id: 309, name: 'workStartingAt' },
        { id: 314, name: 'isFixedRate' },
      ],
    },
    specificShortListStatuses: {
      recruiterReject: 28816,
    },
  },
};
