import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DictionaryEditableItem } from '@app-shared/models';
import { isNil } from 'ramda';
import { Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';

@Component({
  template: '',
})
export abstract class DirectoryEntityFormComponent implements OnInit, OnDestroy {
  @Input() public set directoryItem(data: DictionaryEditableItem) {
    if (!data) {
      return;
    }

    if (!this.entityForm) {
      this.initForm();
    }

    this.item = data;
    this.updateFormWithData(data);
  }
  @Output() public readonly formChange = new EventEmitter<{
    data: Record<string, unknown>;
    hasError: boolean;
  }>();
  @Output() public readonly formPristineState = new EventEmitter<boolean>();
  public entityForm?: FormGroup;
  public item?: DictionaryEditableItem;
  public readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public createForm(): FormGroup {
    throw new Error('Method not implemented.');
  }
  public initForm() {
    if (this.entityForm) {
      return;
    }

    this.entityForm = this.createForm();

    this.entityForm.statusChanges
      .pipe(
        map(() => this.entityForm.pristine),
        distinctUntilChanged(),
        filter((isPristine) => !isPristine),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((e) => {
        this.formPristineState.emit(e);
      });

    this.entityForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.entityForm.invalid) {
        this.checkFormErrors();
      }

      this.formChange.emit({
        data: this.formDataForSymfony,
        hasError: this.entityForm.invalid,
      });
    });
  }
  public checkFormErrors() {
    const controls = this.entityForm.controls;
    for (const control in controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (controls.hasOwnProperty(control)) {
        const currentControl = controls[control] as FormControl;
        if (!isNil(currentControl.validator) && currentControl.untouched) {
          currentControl.markAsTouched();
        }
      }
    }
  }
  public controlHasErrorType(controlName: string, errorName: string): boolean {
    const control = this.entityForm.get(controlName);

    if (!control) {
      return false;
    }

    return control.touched && (control.errors ? (control.errors[errorName] as boolean) : false);
  }
  public get formDataForSymfony(): Record<string, unknown> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateFormWithData(_item: DictionaryEditableItem) {
    throw new Error('Method not implemented.');
  }
}
