import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import {
  getIsSubscriptionExpired,
  getUserLocalization,
  isUserCanActivateTrial,
} from '@app-core/root/reducer/root.reducer';
import { State } from '@app-shared/reducers';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

export const expiredSubscriptionActivateGuard: CanActivateFn = (): Observable<
  UrlTree | boolean
> => {
  const store$: Store<State> = inject(Store<State>);
  const router: Router = inject(Router);

  const currentUrl = router.getCurrentNavigation().finalUrl.toString();

  return combineLatest([
    store$.pipe(select(getIsSubscriptionExpired)),
    store$.pipe(select(getUserLocalization)),
    store$.pipe(select(isUserCanActivateTrial)),
  ]).pipe(
    map(([isExpired, userLocale, canActivateTrial]) => {
      const isAllowedTab = /\/logout|\/login|\/start$|start\(extras:popup\/pricing/g.test(
        currentUrl,
      );
      if (isAllowedTab || (!canActivateTrial && !isExpired)) {
        return true;
      }
      return router.createUrlTree([
        {
          outlets: {
            primary: [userLocale, 'start'],
            extras: ['popup', 'pricing'],
          },
        },
      ]);
    }),
    catchError(() => of(false)),
    take(1),
  );
};
