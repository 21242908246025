import { ClientsList, DictionaryItem, SearchParams, Vacancy } from '@app-shared/models';
import { createAction, props } from '@ngrx/store';

export const DeleteClientOnSymfonySuccessAction = createAction(
  '[Client API | Symfony] Client successfully deleted',
);
export const DeleteClientOnSymfonyFailedAction = createAction(
  '[Client API | Symfony] Deleting client failed',
);
export const GetActiveClientDetailsSuccessAction = createAction(
  '[Client API] Active client details saved to store',
  props<{ vacancy: Vacancy }>(),
);
export const GetClientsWithNewParamsAction = createAction(
  '[Clients List Effects] Request new clients list using updated params',
);
export const GetSelectedClientDetailsSuccessAction = createAction(
  '[Client API] Selected client details saved to store',
  props<{ vacancy: Vacancy }>(),
);
export const GetSimpleClientsListFailedAction = createAction(
  '[Client API] Request simple clients list failed',
);
export const GetSimpleClientsListSuccessAction = createAction(
  '[Client API] Simple clients saved to store',
  props<{ clients: DictionaryItem[] }>(),
);
export const GetSymfonyClientDetailsFailedAction = createAction(
  '[Client API | Symfony] Request client details failed',
  props<{ id: number }>(),
);
export const LoadClientsAction = createAction('[Clients List Init] Request clients on start');
export const ReturnClientsListInitialStateAction = createAction(
  '[Clients List Cleaner Guard] Return clients list store to initial state',
);

export const RequestClientsListSuccessAction = createAction(
  '[Client API] Detailed clients list saved to store',
  props<{ response: ClientsList }>(),
);
export const RequestClientsListFailedAction = createAction(
  '[Client API] Request detailed clients list failed',
);
export const SetClientsListInitialStateAction = createAction(
  '[Clients List Resolver] Set initial state from query params',
  props<{ searchParams: SearchParams }>(),
);
export const UpdateClientOnSymfonySuccessAction = createAction(
  '[Client API | Symfony] Client successfully updated',
);
export const UpdateClientOnSymfonyFailedAction = createAction(
  '[Client API | Symfony] Client data update failed',
);
