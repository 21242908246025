import { FormControl } from '@angular/forms';
import { isEmpty, isNil } from 'ramda';

export function numberValidator(
  control: FormControl<number>,
): { numberOnly: { value: number } } | null {
  const value = control.value;

  const numberRegExp = new RegExp(/^\d+(\.?|,?)\d*$/g);

  return isNil(value) || isEmpty(value) || numberRegExp.test(value.toString())
    ? null
    : { numberOnly: { value } };
}
