import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { getUserIsAdmin, isUserOptionAllowed } from '@app-core/root/reducer/root.reducer';
import { environment } from '@app-environment/environment';
import {
  CalendarPayload,
  CalendarTarget,
  DictionaryShortList,
  DirectoryFieldType,
  Locales,
  MailTemplateTypes,
  Profile,
  ShortList,
  ShortListPayload,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import {
  getDictionaryLoadingState,
  getRejectReasons,
  getShortListStatuses,
} from '@app-shared/reducers/dictionary/dictionary.reducer';
import { RequestRejectReasonsAction } from '@app-shared/resolvers/dictionaries/dictionaries-resolve.actions';
import {
  ShortListDialogFields,
  ShortListModificationService,
} from '@app-shared/services/short-list/short-list-modification.service';
import { FIELD_FOR_SEND_NOTIFICATION } from '@app-shared/services/short-list/short-list-utilities';
import { Store, select } from '@ngrx/store';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { SpinnerComponent } from '@tsp-components/spinner';
import { CheckEmailPipe } from '@tsp-pipes';
import { DateTime } from 'luxon';
import {
  anyPass,
  find,
  includes,
  isEmpty,
  isNil,
  path,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  filter as ramdaFilter,
  reject,
} from 'ramda';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SendLetterButtonComponent } from '../send-letter-button/send-letter-button.component';
import { ShortListInfoComponent } from '../short-list-info/short-list-info.component';
import { ShortListInterviewInfoComponent } from '../short-list-interview-info/short-list-interview-info.component';
import { ShortListProjectInfoComponent } from '../short-list-project-info/short-list-project-info.component';
import { ShortListStatusSelectorComponent as ShortListStatusSelectorComponent_1 } from '../short-list-status-selector/short-list-status-selector.component';
import { ShortListEditDialogComponent } from './../short-list-edit-dialog/short-list-edit-dialog.component';
import { ShortListStatusSelectorComponent } from './../short-list-status-selector/short-list-status-selector.component';

@Component({
  imports: [
    AsyncPipe,
    CheckEmailPipe,
    MatTooltip,
    NgClass,
    SendLetterButtonComponent,
    ShortListInfoComponent,
    ShortListInterviewInfoComponent,
    ShortListProjectInfoComponent,
    ShortListStatusSelectorComponent_1,
    SpinnerComponent,

    TranslatePipe,
    TranslateDirective,
  ],
  selector: 'profile-short-list',
  templateUrl: './profile-short-list.component.html',
  styleUrls: ['./profile-short-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileShortListComponent implements OnInit, OnDestroy {
  @Input()
  public hasAccessToCustomFields: boolean;
  @Input()
  public statusesCustomFields?: DirectoryFieldType[];
  @Input()
  public profile?: Profile;
  @Input()
  public shortList?: ShortList;
  @Input()
  public userLocale?: Locales = 'uk';
  @Input()
  public showFullList = false;
  @Input()
  public disableActions = false;
  @Input()
  public showInfo = true;
  @ViewChild('statusSelector')
  public statusSelector?: ShortListStatusSelectorComponent;
  @Output()
  public addToCompany: EventEmitter<void> = new EventEmitter();
  @Output()
  public addToList: EventEmitter<Partial<ShortListPayload>> = new EventEmitter();
  @Output()
  public openCalendar: EventEmitter<{
    payload: Partial<CalendarPayload>;
    target: CalendarTarget;
    event?: string;
  }> = new EventEmitter();
  @Output()
  public removeFromShortList: EventEmitter<number> = new EventEmitter();
  @Output()
  public resendShortlistForSecurityCheck: EventEmitter<number> = new EventEmitter();
  @Output()
  public sendLetter: EventEmitter<{ type: MailTemplateTypes; shortList: ShortList }> =
    new EventEmitter();
  @Output()
  public updateShortList: EventEmitter<{
    previousShortList: Partial<ShortList>;
    shortList: Partial<ShortList>;
  }> = new EventEmitter();
  public hasAccessToSalary: boolean;
  public isRejectReasonsLoading$: Observable<boolean>;
  public profileVacanciesStatuses?: DictionaryShortList[];
  public notSelectableFieldsNames = ['_manually_not_allowed'];

  private isAdmin = false;
  private allStatuses?: DictionaryShortList[];
  private hasAccessToStatusCustomFields: boolean;
  private dateTypeStatuses?: number[];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store<State>,
    private readonly dialog: MatDialog,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly shortListModificationService: ShortListModificationService,
  ) {}

  public ngOnInit(): void {
    this.store
      .pipe(select(getShortListStatuses), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((data = []) => {
        this.profileVacanciesStatuses = data;
        this.allStatuses = reject(anyPass([propEq('ai', 'code'), propEq('applies', 'code')]), data);

        this.dateTypeStatuses = pipe(
          ramdaFilter(
            anyPass([
              propEq('date', 'type') as UnaryOperator<DictionaryShortList, boolean>,
              pipe(
                propOr([], 'attachedFieldTypes'),
                pluck('name'),
                includes(ShortListDialogFields.INTERVIEW) as UnaryOperator<string[], boolean>,
              ),
            ]),
          ) as UnaryOperator<DictionaryShortList[], DictionaryShortList[]>,
          pluck('id') as UnaryOperator<DictionaryShortList[], number[]>,
        )(this.allStatuses);

        this.changeDetection.markForCheck();
      });
    combineLatest([
      this.store.pipe(select(isUserOptionAllowed('showCandidateStatusCustomFields'))),
      this.store.pipe(select(isUserOptionAllowed('showSalary'))),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([customFields, salary]) => {
        this.hasAccessToStatusCustomFields = customFields;
        this.hasAccessToSalary = salary;
      });
    this.isRejectReasonsLoading$ = this.store.pipe(select(getDictionaryLoadingState));

    this.store
      .pipe(select(getUserIsAdmin), takeUntil(this.ngUnsubscribe))
      .subscribe((isAdmin) => (this.isAdmin = isAdmin));
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  public addToVacancy(): void {
    if (!this.profile.isPrivate) {
      this.addToCompany.emit();
      return;
    }
    const longListStatus = pipe(
      find(propEq('long_list', 'code')) as UnaryOperator<
        DictionaryShortList[],
        DictionaryShortList
      >,
      prop('id'),
    )(this.profileVacanciesStatuses);

    this.onStatusChanged(longListStatus);
  }
  public get isControlLocked(): boolean {
    return this.disableActions || this.isStatusProtected;
  }
  public get isStatusIsDate(): boolean {
    return includes(this.shortListStatus, this.dateTypeStatuses);
  }
  public get isStatusProtected(): boolean {
    if (!this.shortList || this.isAdmin) {
      return false;
    }

    const dictionaryStatus = this.allStatuses.find((status) => status.id === this.shortListStatus);

    return !!dictionaryStatus?.attachedFieldTypes?.find((f) => f.name === '_k2_protected');
  }
  public get isStatusSecurityCheck(): boolean {
    if (!this.shortList) {
      return false;
    }

    const dictionaryStatus = this.allStatuses.find((status) => status.id === this.shortListStatus);

    return !!dictionaryStatus?.attachedFieldTypes?.find(
      (f) => f.name === '_k2_personal_data_check',
    );
  }
  public get projectId(): number {
    return this.shortList.project.id;
  }
  public get projectName(): string {
    return `#${this.shortList.project.id} ${this.shortList.project.name}`;
  }
  public get shortListStatus(): number | null {
    return path(['status', 'id'], this.shortList);
  }
  public get shortListType(): string {
    const processingCodes = ['custom', 'interview_planned', 'sent_to_review'];
    const shortListCode = pipe(
      find(propEq(this.shortListStatus, 'id')) as UnaryOperator<
        DictionaryShortList[],
        DictionaryShortList
      >,
      prop('code'),
    )(this.profileVacanciesStatuses) as string;
    return includes(shortListCode, processingCodes) ? 'processing' : shortListCode;
  }
  public editShortList(bidData: Partial<ShortList>) {
    if (!this.shortList?.id) {
      this.addToList.emit(bidData);
    } else {
      this.shortListModificationService
        .updateShortList({
          shortList: bidData,
          profile: this.profile,
          vacancyId: this.shortList.project.id,
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: ShortList | boolean) => {
          if (result) {
            this.updateShortList.emit({
              previousShortList: this.shortList,
              shortList: { ...(result as ShortList), id: this.shortList.id },
            });
          } else {
            this.returnOriginalStatus();
          }
        });
    }
  }
  public openEditShortListDialog() {
    this.store
      .pipe(
        select(getRejectReasons),
        tap((rejectReasons) => {
          if (anyPass([isNil, isEmpty])(rejectReasons)) {
            this.store.dispatch(RequestRejectReasonsAction());
          }
        }),
        filter((rejectReasons) => !anyPass([isNil, isEmpty])(rejectReasons)),
        switchMap((rejectReasons) =>
          this.dialog
            .open(ShortListEditDialogComponent, {
              autoFocus: false,
              data: {
                hasAccessToSalary: this.hasAccessToSalary,
                hasAccessToStatusCustomFields: this.hasAccessToStatusCustomFields,
                shortList: this.shortList,
                statuses: this.allStatuses,
                statusesCustomFields: ramdaFilter(
                  prop('showInPanel') as UnaryOperator<DirectoryFieldType, boolean>,
                )(this.statusesCustomFields),
                rejectReasons,
              },
              panelClass: 'c-tsp-mat-dialog-container',
              width: '600px',
            })
            .afterClosed(),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: ShortListPayload) => {
        if (!isNil(data)) {
          this.updateShortList.emit({
            previousShortList: this.shortList,
            shortList: { ...data, id: this.shortList.id },
          });
        }
      });
  }
  public onChangeInterviewDate() {
    this.onStatusChanged(this.shortListStatus);
  }
  public onOpenCalendar(target: CalendarTarget) {
    const dateFormat = target === 'office365' ? `yy-MM-dd'T'TT` : `yyyyLLdd'T'HHmmss`;

    const payload: Partial<CalendarPayload> = {
      date: {
        from: DateTime.fromISO(this.shortList.interviewAt).toFormat(dateFormat),
        to: DateTime.fromISO(this.shortList.interviewAt).plus({ hours: 1 }).toFormat(dateFormat),
      },
      vacancyId: this.projectId,
    };

    this.openCalendar.emit({ payload, target });
  }
  public onStatusChanged(status: number) {
    const shortListStatus = find(
      propEq(status, 'id'),
      this.profileVacanciesStatuses,
    ) as DictionaryShortList;

    this.editShortList({
      id: this.shortList?.id,
      status: shortListStatus,
    });
  }
  public rejectCandidate() {
    const rejectStatus =
      this.allStatuses.find(
        (status) =>
          status.id === environment.integrations.specificShortListStatuses.recruiterReject,
      ) || this.allStatuses.find((status) => status.code === 'not_accepted');

    if (!rejectStatus) {
      return;
    }

    this.editShortList({
      id: this.shortList?.id,
      status: rejectStatus,
    });
  }
  public get isStatusForSendingLetters(): boolean {
    if (!this.shortList) {
      return false;
    }

    const dictionaryStatus = this.allStatuses.find((status) => status.id === this.shortListStatus);

    return !!dictionaryStatus?.attachedFieldTypes?.find((f) =>
      f.name.startsWith(FIELD_FOR_SEND_NOTIFICATION),
    );
  }
  public get notificationType(): MailTemplateTypes {
    if (!this.shortList || !this.isStatusForSendingLetters) {
      return null;
    }

    const dictionaryStatus = this.allStatuses.find((status) => status.id === this.shortListStatus);

    const notificationCustomField = dictionaryStatus?.attachedFieldTypes?.find((f) =>
      f.name.startsWith(FIELD_FOR_SEND_NOTIFICATION),
    );

    return notificationCustomField?.name.replace(
      `${FIELD_FOR_SEND_NOTIFICATION}_`,
      '',
    ) as MailTemplateTypes;
  }
  private returnOriginalStatus() {
    this.statusSelector.statusSelector.setValue(this.shortListStatus);
  }
}
