import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  GetShortListBoardsAction,
  GetShortListBoardsFailedAction,
  GetShortListBoardsSuccessAction,
} from '@app-core/add-new-entity-popup/resolvers/short-list-boards/short-list-boards-resolve.actions';
import { CustomFieldsSystemActions } from '@app-core/custom-fields/actions/custom-fields.system.actions';
import { CustomFieldsUserActions } from '@app-core/custom-fields/actions/custom-fields.user.actions';
import { DirectoriesSystemActions } from '@app-core/directories/actions/directories.system.actions';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { RootUserActions } from '@app-core/root/actions/root.user.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import {
  AllDictionaries,
  CustomForm,
  DictionariesEnum,
  DictionaryCustomFieldTypes,
  DictionaryItem,
  DictionaryRegion,
  DictionaryShortListBoard,
  DictionaryShortListSource,
  DirectoryFieldType,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { DepartmentsResolveActions } from '@app-shared/resolvers/departments/departments-resolve.actions';
import { DictionariesResolveActions } from '@app-shared/resolvers/dictionaries/dictionaries-resolve.actions';
import {
  GetFormConfigsAction,
  GetFormConfigsFailedAction,
  GetFormConfigsSuccessAction,
} from '@app-shared/resolvers/form-configs/form-configs-resolve.actions';
import {
  GetRecruitersAction,
  GetRecruitersFailedAction,
  GetRecruitersSuccessAction,
} from '@app-shared/resolvers/recruiters/recruiters-resolve.actions';
import {
  GetShortListSourcesAction,
  GetShortListSourcesFailedAction,
  GetShortListSourcesSuccessAction,
} from '@app-shared/resolvers/short-list-sources/short-list-sources-resolve.actions';
import {
  GetShortListStatusesAction,
  GetShortListStatusesFailedAction,
  GetShortListStatusesSuccessAction,
} from '@app-shared/resolvers/short-list-statuses/short-list-statuses-resolve.actions';
import { addNewVacancyDictionaries } from '@app-shared/services/profile/new-entity.config';
import {
  profileDetailsDictionaries,
  profileDictionaries,
} from '@app-shared/services/profile/profile.config';
import {
  vacanciesListDictionaries,
  vacancyProfileDictionaries,
} from '@app-shared/services/vacancy/vacancy.config';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { anyPass, identity, join, memoizeWith, omit, pick, pickAll, prop, propOr } from 'ramda';

export type DictionariesState = AllDictionaries & {
  [key in DictionaryCustomFieldTypes]?: DirectoryFieldType[];
} & {
  addressList?: string[];
  isDepartmentsLoading?: boolean;
  isCustomFieldsLoading?: boolean;
  isDictionariesLoading?: boolean;
  isRecruitersLoading?: boolean;
  isRejectReasonsLoading?: boolean;
  formConfigs?: CustomForm[];
  selectedBoard?: DictionaryShortListBoard;
};

export const initialState: DictionariesState = {};

const reducer = createReducer<DictionariesState>(
  initialState,
  on(
    MetadataActions.CleanReducers,
    RootSystemActions.ChangeLocalizationSuccessAction,
    DirectoriesSystemActions.RefreshDirectoryAction,
    () => initialState,
  ),
  on(DepartmentsResolveActions.GetDepartmentAction, (state) => ({
    ...state,
    isDepartmentsLoading: true,
  })),
  on(DepartmentsResolveActions.GetDepartmentFailedAction, (state) => ({
    ...state,
    isDepartmentsLoading: false,
  })),
  on(
    DepartmentsResolveActions.GetDepartmentSuccessAction,
    (state, { departments }) =>
      ({ ...state, isDepartmentsLoading: false, departments }) as DictionariesState,
  ),
  on(GetRecruitersAction, (state) => ({ ...state, isRecruitersLoading: true })),
  on(GetRecruitersFailedAction, (state) => ({ ...state, isRecruitersLoading: false })),
  on(GetRecruitersSuccessAction, (state, { recruiters }) => ({
    ...state,
    isRecruitersLoading: false,
    recruiters,
  })),
  on(
    AddNewEntityUserActions.GetShortListBoardByIdAction,
    GetShortListBoardsAction,
    GetShortListSourcesAction,
    GetShortListStatusesAction,
    DictionariesResolveActions.RequestBunchDictionariesAction,
    RootUserActions.AddNewShortListSourceAction,
    RootSystemActions.RequestVacanciesAddressListAction,
    (state) => ({ ...state, isDictionariesLoading: true }),
  ),
  on(
    CustomFieldsUserActions.GetClientCustomFieldsAction,
    CustomFieldsUserActions.GetProfileCustomFieldsAction,
    CustomFieldsUserActions.GetProjectCustomFieldsAction,
    CustomFieldsUserActions.GetRecruiterCustomFieldsAction,
    (state) => ({ ...state, isCustomFieldsLoading: true }),
  ),
  on(
    AddNewEntitySystemActions.GetShortListBoardByIdFailedAction,
    GetShortListBoardsFailedAction,
    GetShortListSourcesFailedAction,
    GetShortListStatusesFailedAction,
    DictionariesResolveActions.RequestBunchDictionariesFailedAction,
    RootSystemActions.AddNewSourceFailedAction,
    RootSystemActions.RequestVacanciesAddressListFailedAction,
    (state) => ({ ...state, isDictionariesLoading: false }),
  ),
  on(
    CustomFieldsSystemActions.GetClientCustomFieldsFailedAction,
    CustomFieldsSystemActions.GetProfileCustomFieldsFailedAction,
    CustomFieldsSystemActions.GetProjectCustomFieldsFailedAction,
    CustomFieldsSystemActions.GetRecruiterCustomFieldsFailedAction,
    (state) => ({ ...state, isCustomFieldsLoading: false }),
  ),
  on(GetShortListBoardsSuccessAction, (state, { boards }) => ({
    ...state,
    isDictionariesLoading: false,
    shortListBoards: boards,
  })),
  on(AddNewEntitySystemActions.GetShortListBoardByIdSuccessAction, (state, { board }) => ({
    ...state,
    isDictionariesLoading: false,
    selectedBoard: board,
  })),
  on(
    CustomFieldsSystemActions.GetClientCustomFieldsCompleteAction,
    (state, { clientCustomFields }) => ({
      ...state,
      isCustomFieldsLoading: false,
      clientCustomFields,
    }),
  ),
  on(
    CustomFieldsSystemActions.GetProfileCustomFieldsCompleteAction,
    (state, { profileCustomFields }) => ({
      ...state,
      isCustomFieldsLoading: false,
      profileCustomFields,
    }),
  ),
  on(
    CustomFieldsSystemActions.GetProjectCustomFieldsCompleteAction,
    (state, { projectCustomFields }) => ({
      ...state,
      isCustomFieldsLoading: false,
      projectCustomFields,
    }),
  ),
  on(
    CustomFieldsSystemActions.GetRecruiterCustomFieldsCompleteAction,
    (state, { recruiterCustomFields }) => ({
      ...state,
      isCustomFieldsLoading: false,
      recruiterCustomFields,
    }),
  ),
  on(
    CustomFieldsSystemActions.GetStatusesCustomFieldsCompleteAction,
    (state, { statusesCustomFields }) => ({ ...state, statusesCustomFields }),
  ),
  on(GetShortListStatusesSuccessAction, (state, { statuses }) => ({
    ...state,
    isDictionariesLoading: false,
    shortListStatuses: statuses,
  })),
  on(GetShortListSourcesSuccessAction, (state, { sources }) => ({
    ...state,
    isDictionariesLoading: false,
    shortListSources: sources,
  })),
  on(RootSystemActions.AddNewSourceSuccessAction, (state, { source }) => ({
    ...state,
    isDictionariesLoading: false,
    shortListSources: [source, ...state.shortListSources],
  })),
  on(
    DictionariesResolveActions.RequestBunchDictionariesSuccessAction,
    (state, { dictionaries }) => ({ ...state, isDictionariesLoading: false, ...dictionaries }),
  ),
  on(DictionariesResolveActions.RequestRejectReasonsAction, (state) => ({
    ...state,
    isRejectReasonsLoading: true,
  })),
  on(DictionariesResolveActions.RequestRejectReasonsFailedAction, (state) => ({
    ...state,
    isRejectReasonsLoading: false,
  })),
  on(DictionariesResolveActions.RequestRejectReasonsSuccessAction, (state, { reasons }) => ({
    ...state,
    isRejectReasonsLoading: false,
    rejectReasons: reasons,
  })),
  on(GetFormConfigsAction, (state) => ({ ...state, isDictionariesLoading: true })),
  on(GetFormConfigsFailedAction, (state) => ({ ...state, isDictionariesLoading: false })),
  on(GetFormConfigsSuccessAction, (state, { configs }) => ({
    ...state,
    isDictionariesLoading: false,
    formConfigs: configs,
  })),
  on(AddNewEntitySystemActions.SaveNewClientSuccessAction, (state) =>
    omit([DictionariesEnum.clients], state),
  ),
  on(
    DirectoriesSystemActions.UpdateCandidateStagesOrderSuccessAction,
    (state, { directory }) => ({ ...state, shortListStatuses: directory }) as DictionariesState,
  ),
  on(RootSystemActions.RequestVacanciesAddressListSuccessAction, (state, { addressList }) => ({
    ...state,
    addressList,
    isDictionariesLoading: false,
  })),
  on(
    AddNewEntitySystemActions.SaveNewCitySuccessAction,
    (state, { city }) => ({ ...state, cities: [...state.cities, city] }) as DictionariesState,
  ),
);

export function dictionaryReducer(baseState: DictionariesState | undefined, action: Action) {
  return reducer(baseState, action);
}

export const selectDictionaryStore = (state: State) => state.dictionaries;

export const isDictionariesLoading = createSelector(
  selectDictionaryStore,
  (s) => s.isDictionariesLoading,
);

export const getDictionaryLoadingState = createSelector(
  selectDictionaryStore,
  anyPass([
    prop('isCustomFieldsLoading'),
    prop('isDictionariesLoading'),
    prop('isRecruitersLoading'),
    prop('isRejectReasonsLoading'),
  ]) as UnaryOperator<DictionariesState, boolean>,
);
export const selectModuleRelatedDictionaries = memoizeWith(join('-'), (dictionaries: string[]) =>
  createSelector(selectDictionaryStore, (state: DictionariesState) => pickAll(dictionaries, state)),
);
export const getCities = createSelector(selectDictionaryStore, (s) => s.cities);
export const getCitiesRelatedDictionaries = createSelector(selectDictionaryStore, (s) =>
  pick([DictionariesEnum.countries, DictionariesEnum.regions], s),
);
export const getCountries = createSelector(selectDictionaryStore, (s) => s.countries);
export const getDepartments = createSelector(selectDictionaryStore, (s) => s.departments);
export const getMailAttachments = createSelector(
  selectDictionaryStore,
  (s) => s['mail-attachments'],
);
export const getProfilesDetailsDictionaries = createSelector(
  selectDictionaryStore,
  pick(profileDetailsDictionaries),
);
export const getProfilesFiltersDictionaries = createSelector(
  selectDictionaryStore,
  pick(profileDictionaries),
);
export const getRecruiters = createSelector(selectDictionaryStore, (s) => s.recruiters);
export const getRegions = createSelector(
  selectDictionaryStore,
  (s) => s.regions as DictionaryRegion[],
);
export const getRejectReasons = createSelector(selectDictionaryStore, (s) => s.rejectReasons);
export const getSecurityGroups = createSelector(selectDictionaryStore, (s) => s.securityGroups);
export const getShortListBoards = createSelector(selectDictionaryStore, (s) => s.shortListBoards);
export const getSelectedShortListBoard = createSelector(
  selectDictionaryStore,
  (s) => s.selectedBoard,
);
export const getCustomFields = memoizeWith(identity, (property: DictionaryCustomFieldTypes) =>
  createSelector(selectDictionaryStore, (dictionaries: DictionariesState) =>
    prop(property, dictionaries),
  ),
);
export const getShortListStatuses = createSelector(
  selectDictionaryStore,
  (s) => s.shortListStatuses,
);
export const getShortListSources = createSelector(
  selectDictionaryStore,
  (s) => s.shortListSources as DictionaryShortListSource[],
);
export const getSkills = createSelector(selectDictionaryStore, ((
  dictionariesState: DictionariesState,
  skillType: 'skills' | 'softSkills' = 'skills',
) => propOr([], skillType, dictionariesState)) as UnaryOperator<
  DictionariesState,
  DictionaryItem[] | null
>);
export const getTags = createSelector(selectDictionaryStore, (s) => s.tags || []);
export const getVacanciesListDictionaries = createSelector(
  selectDictionaryStore,
  pick(vacanciesListDictionaries),
);
export const getVacancyEditingDictionaries = createSelector(
  selectDictionaryStore,
  pick(addNewVacancyDictionaries),
);
export const getVacancyProfilesDictionaries = createSelector(
  selectDictionaryStore,
  pick(vacancyProfileDictionaries),
);
export const getVacancyStatuses = createSelector(selectDictionaryStore, (s) => s.vacancyStatuses);
export const getVacancyExperiences = createSelector(selectDictionaryStore, (s) => s.experiences);
export const getFormConfigs = createSelector(selectDictionaryStore, (s) => s.formConfigs);
export const getVacanciesAddressList = createSelector(
  selectDictionaryStore,
  (store) => store?.addressList,
);
