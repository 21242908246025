@if (userType) {
  <div
    (click)="typeSelected.emit(userType)"
    class="c-teammate-type c-teammate-type--{{ theme }}"
    [ngClass]="{ 'c-teammate-type__selected': isSelected }"
  >
    <img
      src="../../../../assets/teammates/teammate-{{ userType.key }}.png"
      class="c-teammate-type__image"
      alt="Teammate type"
    />
    {{ 'teammates.teammates-type.' + userType.key + '.name' | translate }}
    @if (showCount) {
      <span class="c-teammate-type__count">({{ accessesCount }})</span>
    }
    <img
      src="../../../../assets/teammates/grey-info.svg"
      class="c-teammate-type__info"
      [matTooltip]="'teammates.teammates-type.' + userType.key + '.info' | translate"
      alt="Info"
    />
  </div>
}
