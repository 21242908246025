/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { AddNewEntitySystemActions } from '@app-core/add-new-entity-popup/actions/new-entity.system.actions';
import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import {
  DeleteClientOnSymfonyFailedAction,
  DeleteClientOnSymfonySuccessAction,
  UpdateClientOnSymfonyFailedAction,
  UpdateClientOnSymfonySuccessAction,
} from '@app-core/clients-list/actions/clients-list.system.actions';
import { SuccessNotificationAction } from '@app-shared/actions/internal-notification.actions';
import { NotifyTrackersActions } from '@app-shared/actions/notify-trackers.actions';
import { ClientService } from '@app-shared/services/client/client.service';

@Injectable()
export class NewEntityClientEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly clientService: ClientService,
  ) {}

  public deleteClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.DeleteClientAction),
      exhaustMap(({ id }) =>
        this.clientService.deleteClient(id).pipe(
          mergeMap(() => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.deleted.client',
            }),
            DeleteClientOnSymfonySuccessAction(),
            NotifyTrackersActions.TrackUpdateProfileTagsAction({
              category: 'Client',
              action: `delete`,
            }),
          ]),
          catchError(() => of(DeleteClientOnSymfonyFailedAction())),
        ),
      ),
    ),
  );

  public getClientDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntitySystemActions.RequestClientDetailsOnInitAction),
      exhaustMap(({ id }) =>
        this.clientService
          .getClientByDevId(id)
          .pipe(
            map((client) =>
              AddNewEntitySystemActions.RequestClientDetailsOnInitSuccessAction({ client }),
            ),
          ),
      ),
    ),
  );

  public saveNewClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.CreateNewClientAction),
      exhaustMap(({ client }) =>
        this.clientService.saveNewClient(client).pipe(
          mergeMap((newClientEntity) => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.saved.client',
            }),
            AddNewEntitySystemActions.SaveNewClientSuccessAction({ client: newClientEntity }),
          ]),
          catchError(() => of(AddNewEntitySystemActions.SaveNewClientFailedAction())),
        ),
      ),
    ),
  );

  public updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddNewEntityUserActions.UpdateClientAction),
      exhaustMap(({ client }) =>
        this.clientService.updateClient(client).pipe(
          mergeMap(() => [
            SuccessNotificationAction({
              message: 'add-new-entity.effects.updated.client',
            }),
            UpdateClientOnSymfonySuccessAction(),
            NotifyTrackersActions.TrackUpdateProfileTagsAction({
              category: 'Client',
              action: `update`,
            }),
          ]),
          catchError(() => of(UpdateClientOnSymfonyFailedAction())),
        ),
      ),
    ),
  );
}
