import { FormControl } from '@angular/forms';
import { isEmpty, isNil } from 'ramda';

export function phoneValidator(control: FormControl<string>) {
  const value: string = control.value;
  const numberRegExp = new RegExp(/^\+?[\d\s()-]+(?!.)/g);

  return isNil(value) || isEmpty(value) || isEmpty(value.trim()) || numberRegExp.test(value)
    ? null
    : { phoneMatch: { value } };
}
