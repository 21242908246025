@if (profileTags || isActiveTab) {
  <div class="c-profile-tags">
    @if (isFormVisible) {
      <form [formGroup]="form" (submit)="submitTags()">
        <app-tag-input
          class="c-profile-skills__tag-input"
          [autocompleteItems]="tags$ | async"
          [controlValues]="currentTags"
          tagsType="tags"
          customPlaceholder="Tags"
          theme="light-theme"
          (onChange)="updateTags($event)"
        >
        </app-tag-input>
        <div class="text-right">
          <button class="c-profile-tags__item" mat-button (click)="isFormVisible = false">
            <i class="fas fa-window-close" aria-hidden="true"> </i>
          </button>
          <button class="c-profile-tags__item" mat-button color="primary" type="submit">
            <i class="fas fa-check-square" aria-hidden="true"> </i>
          </button>
        </div>
      </form>
    } @else {
      <div class="c-profile-tags__preview">
        @if (isEditable) {
          <button
            class="c-profile-preview__add-button c-profile-tags__add-button"
            mat-icon-button
            [title]="tagButtonText | translate"
            (click)="showForm()"
          >
            <i class="fas fa-plus-square" aria-hidden="true"> </i>
          </button>
        }
        <div
          class="c-profile-tags__label"
          [matTooltip]="'profiles-common.profile-tags.label.profile' | translate"
        >
          <i class="fas fa-tags" aria-hidden="true"></i>
        </div>
        <profile-dynamic-list
          class="c-profile-tags__list"
          type="tag"
          [dictionary]="dictionary"
          [showAllElements]="showDetailedInfo"
          [showExpandButton]="showExpandButton"
          [elements]="profileTags"
        >
        </profile-dynamic-list>
      </div>
    }
  </div>
}
