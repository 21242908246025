import { createAction, props, union } from '@ngrx/store';

const NavigateToExternalPageFromStartAction = createAction(
  '[Start Dashboard] Navigate to external page from start page',
  props<{ path: string }>(),
);
const NavigateToFunctionalityAction = createAction(
  '[Start Dashboard] Navigate to external or internal functionality',
  props<{ openInNewPage: boolean; path: string }>(),
);
const actions = { NavigateToExternalPageFromStartAction, NavigateToFunctionalityAction };

const startDashboardActions = union(actions);

export type RouterActionsType = typeof startDashboardActions;

export const StartDashboardActions = actions;
