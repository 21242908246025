import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import {
  DictionaryItem,
  ShortListDialogData,
  ShortListDialogForm,
  ShortListFormValue,
} from '@app-shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  imports: [ReactiveFormsModule, MatFormField, MatSelect, MatOption, TranslateModule],
  selector: 'short-list-dialog-reject',
  templateUrl: './short-list-dialog-reject.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ShortListDialogRejectComponent implements OnInit, OnDestroy {
  @Input()
  public rejectReasons?: DictionaryItem[];
  @Input()
  public data: ShortListDialogData;
  @Output()
  public formChanged = new EventEmitter<ShortListFormValue>();
  @Output()
  public formStatusChanged = new EventEmitter<FormControlStatus>();
  public form: FormGroup<Pick<ShortListDialogForm, 'reasonRejectId'>>;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder) {}
  public ngOnInit(): void {
    this.form = this.formBuilder.group<Pick<ShortListDialogForm, 'reasonRejectId'>>({
      reasonRejectId: new FormControl(this.data.reasonRejectId, [Validators.required]),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.formChanged.emit(value));
    this.form.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => this.formStatusChanged.emit(status));
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
