import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateDirective } from '@ngx-translate/core';
import { PluralizeTextPipe, SafeHtmlPipe } from '@tsp-pipes';
import { concat, head, match, pipe, replace, test } from 'ramda';

@Component({
  imports: [NgStyle, NgClass, TranslateDirective, PluralizeTextPipe, SafeHtmlPipe],
  selector: 'profile-last-comment',
  templateUrl: './profile-last-comment.component.html',
  styleUrls: ['./profile-last-comment.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileLastCommentComponent {
  @Input()
  public lastComment: string;
  @Input()
  public commentsLength: number;
  @Input()
  public actionOpenSidePanel = true;
  @Output()
  public onCommentClick = new EventEmitter();

  public get commentColor(): string {
    const commentHasColor = test(/^<!--comment-color:\w*-->/, this.lastComment);

    if (commentHasColor) {
      return pipe(
        match(/^<!--comment-color:\w*-->/),
        head,
        replace('<!--comment-color:', ''),
        replace('-->', ''),
        concat('#'),
      )(this.lastComment);
    }

    return '#ffffff';
  }
}
