import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShortListDialogComponent } from '@tsp-components/profile-short-list';
import {
  DirectoryCustomFieldResponse,
  DirectoryFieldType,
  Profile,
  ShortList,
  ShortListPayload,
  UnaryOperator,
} from '@app-shared/models';
import { DateTime } from 'luxon';
import { any, filter, identity, omit, pathOr, pipe, prop, propOr, values } from 'ramda';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  getExtendedDataForNode,
  getFieldsToShow,
  getProfileShortList,
  getSourceId,
  unionFieldValues,
} from './short-list-utilities';

export enum ShortListDialogFields {
  INTERVIEW = '_interview',
  REJECT = '_reject',
  REVIEW = '_k2_send_to_review',
  APPROVE = '_k2_approve_final_candidate',
}

export interface ShortListDialogResult {
  cvLink?: string[];
  emails: string[];
  fieldValues: DirectoryCustomFieldResponse[];
  interviewAt?: Date;
  reasonRejectId?: number;
  recruiterComment?: string;
  budgetExcess?: boolean;
  formingOffer?: boolean;
  positionChanged?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ShortListModificationService {
  constructor(private readonly dialog: MatDialog) {}

  public updateShortList = ({
    shortList,
    profile,
    vacancyId,
  }: {
    shortList: Partial<ShortList>;
    profile?: Profile;
    vacancyId?: number;
  }): Observable<Partial<ShortListPayload> | boolean> => {
    const attachedFieldTypes = pathOr(
      [],
      ['status', 'attachedFieldTypes'],
      shortList,
    ) as DirectoryFieldType[];
    const fieldsToShow = getFieldsToShow(attachedFieldTypes);
    const filteredAttachedFieldTypes = filter(
      prop('showInPanel') as UnaryOperator<DirectoryFieldType, boolean>,
    )(attachedFieldTypes);
    const sourceId = profile ? getSourceId(profile, shortList.id) : null;

    const shouldOpenDialog =
      filteredAttachedFieldTypes.length ||
      pipe(
        omit(['onlyOneManager']) as UnaryOperator<
          { [key: string]: boolean },
          { [key: string]: boolean }
        >,
        values,
        any(identity as UnaryOperator<boolean, boolean>),
      )(fieldsToShow);

    if (shouldOpenDialog) {
      const profileShortList = getProfileShortList(profile, shortList.id);
      const interviewAt: string = pathOr(null, ['applies', 'interviewAt'], profile);
      const dialogData = {
        shortListProjectId: pathOr(null, ['project', 'id'], profileShortList),
        fieldValues: propOr([], 'fieldValues', profileShortList),
        cvs: propOr([], 'cvs', profile),
        interviewAt: fieldsToShow.showInterviewFields && interviewAt ? new Date(interviewAt) : null,
        customFields: filteredAttachedFieldTypes,
        profileId: profile?.id,
        reasonRejectId: pathOr(null, ['applies', 'rejectReason', 'id'], profile),
        vacancyId: vacancyId,
        ...fieldsToShow,
      };

      return this.dialog
        .open(ShortListDialogComponent, {
          data: dialogData,
          panelClass: 'c-tsp-mat-dialog-container',
          width: '750px',
          minWidth: fieldsToShow.showManagerField ? '75vw' : '750px',
        })
        .afterClosed()
        .pipe(
          map((data: ShortListDialogResult) => {
            if (!data) {
              return false;
            }
            return {
              ...shortList,
              fieldValues: unionFieldValues(attachedFieldTypes, data, profile, shortList.id),
              id: shortList.id || null,
              interviewAt: data.interviewAt
                ? DateTime.fromJSDate(data.interviewAt).toUTC().toISO()
                : null,
              reasonRejectId: propOr(null, 'reasonRejectId', data) as unknown as number,
              source_id: sourceId,
              status: shortList.status,
              ...getExtendedDataForNode(attachedFieldTypes, profile, data),
            };
          }),
        );
    }

    const data = {
      ...shortList,
      fieldValues: unionFieldValues(attachedFieldTypes, shortList, profile, shortList.id),
      interviewAt: null,
      reasonRejectId: null,
      source_id: sourceId,
      ...getExtendedDataForNode(attachedFieldTypes, profile),
    };

    return of(data);
  };
}
