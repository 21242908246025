import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryEditableItem, DirectoryFieldType } from '@app-shared/models';

@Pipe({
  name: 'castToCustomField',
  standalone: true,
})
export class CastToCustomFieldPipe implements PipeTransform {
  public transform(value: DictionaryEditableItem): DirectoryFieldType {
    return value as DirectoryFieldType;
  }
}
