@if (customControls) {
  <div [formGroup]="customForm" class="c-custom-fields-group">
    @for (control of customControls; track trackById($index, control)) {
      @if (getIsControlVisible(control.id)) {
        <app-custom-field-control
          class="c-custom-fields-group__control"
          [ngClass]="getControlWidthClass(control.id)"
          [ngStyle]="{ order: getControlSetting(control.id)?.position }"
          [formControl]="customForm | getFormControl: control.id.toString()"
          [controlName]="getControlProperty(control.id, 'name')"
          [controlType]="getControlProperty(control.id, 'type')"
          [controlId]="control.id"
          [customCssClass]="customCssClass"
          [description]="getControlProperty(control.id, 'description')"
          [fieldType]="getControlSetting(control.id)?.fieldType"
          [isRequired]="getControlSetting(control.id)?.required"
          [isReadonly]="getControlSetting(control.id)?.readonly"
          [dictionary]="getDictionary(control.id)"
          (securityGroupChanged)="securityGroupChanged($event)"
        >
        </app-custom-field-control>
      }
    }
  </div>
}
