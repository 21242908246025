<div class="c-kernel-organization-unit">
  @if (department.hasChildren) {
    <button mat-icon-button (click)="toggleNode.emit()">
      @if (isExpanded) {
        <span class="c-kernel-structure__tree-leaf-icon">
          <i class="fa-solid fa-chevron-down" aria-hidden="true"> </i>
        </span>
      } @else {
        <span class="c-kernel-structure__tree-leaf-icon">
          <i class="fa-solid fa-chevron-right" aria-hidden="true"> </i>
        </span>
      }
    </button>
  } @else {
    <button mat-icon-button disabled></button>
  }
  <div class="c-kernel-organization-unit__data">
    <div class="c-kernel-organization-unit__info">
      <span
        class="c-kernel-organization-unit__name"
        [ngClass]="{ 'c-kernel-organization-unit__name--empty': !hasEmployees }"
      >
        {{ department.name }}
      </span>
      <span class="c-kernel-organization-unit__name-department">[{{ department.id }}]</span>
      @if (hasEmployees) {
        <span class="c-kernel-organization-unit__name-users">
          <i class="fa-solid fa-people-roof" aria-hidden="true"> </i>
          <span class="c-kernel-organization-unit__name-users-count"
            >{{ totalEmployeesCount }}
          </span>
        </span>
      }
    </div>
    @if (hasAssignedRecruiters) {
      <div class="c-kernel-organization-unit__recruiters">
        @for (recruiter of department.recruiters; track recruiter) {
          <span
            [ngClass]="{
              'c-kernel-organization-unit__recruiters-admin':
                recruiter.recruiterAccessLevel === 'platform_admin',
              'c-kernel-organization-unit__recruiters-manager':
                recruiter.recruiterAccessLevel === 'group_admin',
              'c-kernel-organization-unit__recruiters-user':
                recruiter.recruiterAccessLevel === 'standard',
            }"
          >
            <i
              class="fa-solid"
              [ngClass]="{
                'fa-user-gear': recruiter.recruiterAccessLevel === 'platform_admin',
                'fa-user-tie': recruiter.recruiterAccessLevel === 'group_admin',
                'fa-user': recruiter.recruiterAccessLevel === 'standard',
              }"
              aria-hidden="true"
            >
            </i>
            <span>
              {{ recruiter.fullName }}
            </span>
          </span>
        }
      </div>
    }
    @if (hasSecurityGroups) {
      <div class="c-kernel-organization-unit__security">
        @for (group of department.securityGroups; track group) {
          <span
            class="c-kernel-organization-unit__security-group"
            [ngClass]="{
              'c-kernel-organization-unit__security-group--A': group.securityLevel === 'A',
              'c-kernel-organization-unit__security-group--B':
                group.securityLevel === 'B1' || group.securityLevel === 'B2',
              'c-kernel-organization-unit__security-group--C': group.securityLevel === 'C',
            }"
          >
            <i
              class="c-kernel-organization-unit__security-group-icon fa-solid fa-shield"
              aria-hidden="true"
            ></i>
            <span>
              {{ group.securityGroupName }}
            </span>
            <span class="c-kernel-organization-unit__security-group-category">{{
              group.securityLevel
            }}</span>
          </span>
        }
      </div>
    }
  </div>
  <button
    mat-icon-button
    class="c-kernel-organization-unit__action-icon c-kernel-organization-unit__action-icon--add-person"
    (click)="changeAssignments.emit()"
  >
    <i class="fa-solid fa-user-plus" aria-hidden="true"></i>
  </button>
</div>
