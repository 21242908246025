import { createAction, props } from '@ngrx/store';
import { ClientFull } from '@app-shared/models';

export const GetVacanciesListAction = createAction(
  '[Vacancies List Resolver] Request vacancies list from server',
  props<{ keyword?: string }>(),
);
export const GetVacanciesListFailedAction = createAction(
  '[Vacancies List Resolver] Request for vacancies list failed',
  props<{ message?: string }>(),
);
export const GetVacanciesListSuccessAction = createAction(
  '[Vacancies List Resolver] Simple list of vacancies stored to state',
  props<{ clients: ClientFull[] }>(),
);
