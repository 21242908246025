import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';
import { VacancyProfilesUserActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.user.actions';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { VacancyApiActions } from '@app-shared/actions/api-actions/vacancy-api.actions';
import { MetadataActions } from '@app-shared/actions/page-metadata.actions';
import { ProfileModificationActions } from '@app-shared/effects/profile-modification/profile-modification.actions';
import {
  matchPublicProfileInList,
  removeProfileFromListById,
  removeProfileFromListByLongListId,
  updateApplyInProfilePreview,
} from '@app-shared/functions/profile-list-modifications/profile-list-modifications';
import {
  Comment,
  DateRange,
  GroupNotificationEntry,
  HistoryState,
  NotificationEmailRecipient,
  PaginationParams,
  Profile,
  ProfilesIncluded,
  SearchParams,
  SortParams,
  UnaryOperator,
  VacancyActivities,
  VacancyComment,
  VacancyCounts,
  VacancyDetails,
} from '@app-shared/models';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import {
  anyPass,
  concat,
  ifElse,
  isEmpty,
  isNil,
  lensPath,
  mergeLeft,
  mergeRight,
  objOf,
  path,
  pathOr,
  pick,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  reject,
  set,
  unless,
} from 'ramda';

const defaultPaginationParams = {
  pageNumber: 1,
  pageSize: 10,
};

export interface VacancyProfilesState {
  activities?: VacancyActivities | null;
  errorMessage?: string;
  isActivitiesLoading: boolean;
  isInfoPanelOpened: boolean;
  isProfilesLoading: boolean;
  isVacancyLoading: boolean;
  items?: Profile[] | GroupNotificationEntry[];
  notificationRecipients: {
    isLoading: boolean;
    items: NotificationEmailRecipient[];
  };
  paginationParams?: PaginationParams;
  profilesIncluded?: ProfilesIncluded;
  searchParams?: SearchParams;
  selectedIds?: number[] | string[];
  sortParams?: SortParams;
  source?: string;
  statisticSearchParams?: SearchParams;
  totalCount?: number;
  vacancyId: number | null;
  vacancyHistory: HistoryState;
  vacancyDetails?: VacancyDetails;
}

export const initialState: VacancyProfilesState = {
  errorMessage: 'Please specify search parameters',
  isActivitiesLoading: false,
  isInfoPanelOpened: false,
  isProfilesLoading: false,
  isVacancyLoading: false,
  notificationRecipients: {
    isLoading: false,
    items: [],
  },
  paginationParams: defaultPaginationParams,
  statisticSearchParams: {
    startDate: DateTime.local().minus({ days: 7 }).startOf('day').toUTC().toString(),
    endDate: DateTime.local().endOf('day').toUTC().toString(),
  },
  vacancyId: null,
  vacancyHistory: {
    isLoading: false,
    items: [],
    total: 0,
    query: {
      dateTo: null,
      dateFrom: null,
    },
  },
};

const reducer = createReducer(
  initialState,

  on(MetadataActions.CleanReducers, () => initialState),
  on(
    VacancyProfilesSystemActions.AddCommentFailedAction,
    VacancyProfilesSystemActions.DeleteCommentFailedAction,
    VacancyProfilesSystemActions.UpdateVacancyFailedAction,
    mergeLeft({ isVacancyLoading: false }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(VacancyProfilesSystemActions.AddCommentSuccessfulAction, (state, { comment }) => {
    return pipe(
      pathOr([], ['vacancyDetails', 'comments']),
      concat([comment]),
      objOf('comments'),
      mergeRight(state.vacancyDetails) as UnaryOperator<
        { comments: VacancyComment[] },
        VacancyDetails
      >,
      objOf('vacancyDetails'),
      mergeRight({ isVacancyLoading: false }),
      mergeRight(state),
    )(state);
  }),
  on(VacancyProfilesSystemActions.CleanProfilesListAction, (state) =>
    mergeRight(state, {
      activities: null,
      items: null,
      totalCount: 0,
      searchParams: { projectId: state.vacancyId },
      sortParams: { sortField: 'createdAt', sortDirection: 'asc' },
    }),
  ),
  on(
    ProfileModificationActions.MatchProfileDataFailedAction,
    VacancyProfilesSystemActions.CreateShortListInVacancyPageFailedAction,
    VacancyProfilesSystemActions.GetNotificationsListFailedAction,
    VacancyProfilesSystemActions.RemoveFromShortListInVacancyPageFailedAction,
    VacancyProfilesSystemActions.RemoveFromShortListInVacancyPageSuccessAction,
    VacancyProfilesSystemActions.UpdateShortListInVacancyPageFailedAction,
    VacancyProfilesSystemActions.ManageProfilesToAnotherVacancyFailedAction,
    VacancyProfilesSystemActions.MergeMultipleProfilesFailedAction,
    VacancyProfilesSystemActions.MergeTwoProfilesFailedAction,
    VacancyProfilesSystemActions.ChangeMultipleShortListStatusCompleteAction,
    VacancyProfilesSystemActions.ChangeMultipleShortListStatusFailedAction,
    VacancyProfilesSystemActions.ResendShortListForSecurityCheckFailedAction,
    VacancyProfilesSystemActions.ResendShortListForSecurityCheckSuccessAction,
    mergeLeft({ isProfilesLoading: false }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(
    ProfileModificationActions.MatchProfileDataAction,
    VacancyProfilesSystemActions.GetProfilesForTabAction,
    VacancyProfilesSystemActions.RefreshVacancyProfilesAfterClosingSidepanelAction,
    VacancyProfilesSystemActions.RefreshVacancyProfilesAfterDeleteTalentAction,
    VacancyProfilesSystemActions.RefreshVacancyProfilesAfterEditTalentAction,
    VacancyProfilesUserActions.ChangeMultipleShortListStatusAction,
    VacancyProfilesUserActions.ResendShortlistForSecurityCheckAction,
    mergeLeft({ isProfilesLoading: true }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(
    VacancyProfilesSystemActions.CreateShortListInVacancyPageSuccessAction,
    (state, { profileId }) => {
      const profiles = state.items as Profile[];
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles = removeProfileFromListById(profileId, profiles);
      return mergeRight(state, { isProfilesLoading: false, items: newProfiles });
    },
  ),
  on(VacancyProfilesSystemActions.DeleteCommentSuccessfulAction, (state, { id }) => {
    const comments = reject(
      propEq(id, 'id') as UnaryOperator<Comment, boolean>,
      state.vacancyDetails.comments,
    );
    const vacancyDetails = mergeRight(state.vacancyDetails, { comments });
    return mergeRight(state, { isVacancyLoading: false, vacancyDetails });
  }),
  on(VacancyProfilesSystemActions.GetNotificationsListSuccessAction, (state, { notifications }) =>
    mergeRight(state, {
      items: notifications,
      totalCount: notifications.length,
      isProfilesLoading: false,
    }),
  ),
  on(
    VacancyProfilesSystemActions.GetVacancyActivitiesAction,
    mergeLeft({ isActivitiesLoading: true }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(
    VacancyProfilesSystemActions.GetVacancyActivitiesFailedAction,
    mergeLeft({ isActivitiesLoading: false }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(VacancyProfilesSystemActions.GetVacancyActivitiesSuccessAction, (state, { activities }) =>
    mergeRight(state, {
      activities,
      isActivitiesLoading: false,
    }),
  ),
  on(
    VacancyProfilesSystemActions.DeleteHistoryEventFailedAction,
    VacancyProfilesSystemActions.GetVacancyHistoryFailedAction,
    (state) => set(lensPath(['vacancyHistory', 'isLoading']), false, state),
  ),
  on(VacancyProfilesSystemActions.GetVacancyHistorySuccessAction, (state, { count, items }) =>
    pipe(
      prop('vacancyHistory') as UnaryOperator<VacancyProfilesState, HistoryState>,
      mergeLeft({ total: count, items, isLoading: false }),
      objOf('vacancyHistory'),
      mergeRight(state) as UnaryOperator<Partial<VacancyProfilesState>, VacancyProfilesState>,
    )(state),
  ),
  on(
    VacancyProfilesSystemActions.RefreshVacancyDetailsAfterEditingAction,
    mergeLeft({ isVacancyLoading: true }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(
    VacancyProfilesSystemActions.RemoveProfileFromVacancyProfilesByLonglistAction,
    (state, { id }) => {
      const profiles = state.items as Profile[];
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles = removeProfileFromListByLongListId(id, profiles);
      return mergeRight(state, { items: newProfiles });
    },
  ),
  on(
    VacancyProfilesSystemActions.ReturnInitialStateAction,
    mergeLeft({
      activities: null,
      paginationParams: defaultPaginationParams,
      items: null,
      searchParams: null,
      source: null,
      totalCount: 0,
      vacancyId: null,
    }) as UnaryOperator<VacancyProfilesState, VacancyProfilesState>,
  ),
  on(
    VacancyProfilesSystemActions.SetInitialStateAction,
    (state, { vacancyId, paginationParams, sortParams, searchParams, source }) => {
      const newSearchParams = mergeRight(searchParams, {
        projectId: vacancyId,
      });
      const vacancyState = {
        source,
        paginationParams,
        sortParams,
        searchParams: newSearchParams,
        vacancyId,
      };
      return mergeRight(state, vacancyState);
    },
  ),
  on(VacancyProfilesSystemActions.SetVacancyIdFromRouteAction, (state, { vacancyId }) => ({
    ...state,
    vacancyId,
  })),
  on(VacancyProfilesSystemActions.ToggleProfilesLoadingStateAction, (state) => {
    const currentState = state.isProfilesLoading;
    return mergeRight(state, { isProfilesLoading: !currentState });
  }),
  on(
    VacancyProfilesSystemActions.UpdateShortListInVacancyPageSuccessAction,
    (state, { shortList }) => {
      const profiles = state.items as Profile[];
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles = updateApplyInProfilePreview(shortList, profiles, true);
      return mergeRight(state, { isProfilesLoading: false, items: newProfiles });
    },
  ),
  on(
    VacancyProfilesSystemActions.UpdateShortListInVacancyPageFailedAction,
    (state, { shortList }) => {
      const profiles = state.items as Profile[];
      if (anyPass([isNil, isEmpty])(profiles)) {
        return state;
      }
      const newProfiles = updateApplyInProfilePreview(shortList, profiles);
      return mergeRight(state, { isProfilesLoading: false, items: newProfiles });
    },
  ),
  on(ProfileModificationActions.MatchProfileDataSuccessAction, (state, { data, id }) => {
    const profiles = state.items as Profile[];
    if (anyPass([isNil, isEmpty])(profiles)) {
      return mergeRight(state, { isProfilesLoading: false });
    }
    const newProfiles = matchPublicProfileInList(data, profiles, id);
    return mergeRight(state, { isProfilesLoading: false, items: newProfiles });
  }),

  on(
    VacancyProfilesUserActions.AddCommentAction,
    VacancyProfilesUserActions.DeleteCommentAction,
    VacancyProfilesUserActions.UpdateVacancyWithDataAction,
    VacancyProfilesUserActions.ChangeVacancyStatusAction,
    (state) => mergeRight(state, { isVacancyLoading: true }),
  ),
  on(
    VacancyProfilesSystemActions.GetProfilesAfterUpdatingSearchParamsAction,
    VacancyProfilesUserActions.AddToShortListAction,
    VacancyProfilesUserActions.RemoveFromShortListAction,
    VacancyProfilesUserActions.UpdateShortListAction,
    VacancyProfilesUserActions.ManageProfilesToAnotherVacancyAction,
    VacancyProfilesUserActions.MergeMultipleProfilesAction,
    VacancyProfilesUserActions.MergeTwoProfilesAction,
    mergeLeft({ isProfilesLoading: true }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(VacancyProfilesUserActions.CleanNotifiedProfilesAction, (state) =>
    mergeRight(state, {
      notificationRecipients: { isLoading: false, items: [] },
    }),
  ),
  on(VacancyProfilesUserActions.CleanSearchParamsAction, (state) => {
    const newParams = mergeRight(state.searchParams, {
      bidSourceId: null,
      bidStatusId: null,
      bidAuthorId: null,
      tagId: null,
      keyword: null,
      bidCustomStatusIds: null,
      bidCreatedFrom: null,
      bidCreatedTo: null,
      dateRange: null,
      hasEmail: null,
      hasPhone: null,
      hasSkype: null,
    });
    return mergeRight(state, { searchParams: newParams });
  }),
  on(VacancyProfilesUserActions.GetNotificationRecipientsAction, (state) =>
    set(lensPath(['notificationRecipients', 'isLoading']), true, state),
  ),
  on(VacancyProfilesSystemActions.GetNotificationRecipientsFailedAction, (state) =>
    set(lensPath(['notificationRecipients', 'isLoading']), false, state),
  ),
  on(VacancyProfilesSystemActions.GetNotificationRecipientsSuccessAction, (state, { recipients }) =>
    mergeRight(state, {
      notificationRecipients: { isLoading: false, items: recipients },
    }),
  ),
  on(
    VacancyProfilesUserActions.DeleteHistoryEventAction,
    VacancyProfilesUserActions.GetVacancyHistoryOnInitAction,
    (state) => set(lensPath(['vacancyHistory', 'isLoading']), true, state),
  ),
  on(VacancyProfilesUserActions.GetVacancyHistoryAction, (state, { params }) => {
    return pipe(
      path(['vacancyHistory', 'query']) as UnaryOperator<VacancyProfilesState, DateRange>,
      mergeLeft(params) as UnaryOperator<DateRange, DateRange>,
      objOf('query'),
      mergeLeft({ isLoading: true }),
      mergeRight(state.vacancyHistory),
      objOf('vacancyHistory'),
      mergeRight(state) as UnaryOperator<Partial<VacancyProfilesState>, VacancyProfilesState>,
    )(state);
  }),
  on(VacancyProfilesUserActions.ToggleInfoPanelAction, (state, { isOpen }) =>
    mergeRight(state, { isInfoPanelOpened: isOpen }),
  ),
  on(VacancyProfilesUserActions.UpdatePaginationParamsAction, (state, { params }) =>
    mergeRight(state, { paginationParams: params }),
  ),
  on(VacancyProfilesUserActions.UpdateSortingParamsAction, (state, { params }) => {
    const paginationParams = mergeRight(state.paginationParams, { pageNumber: 1 });
    return mergeRight(state, { paginationParams, sortParams: params, isProfilesLoading: true });
  }),
  on(VacancyProfilesUserActions.UpdateSearchParamsAction, (state, { params }) => {
    const paginationParams = mergeRight(state.paginationParams, { pageNumber: 1 });
    const searchParams = {
      projectId: state.searchParams.projectId,
      ...params,
    };
    return mergeRight(state, { paginationParams, searchParams, isProfilesLoading: true });
  }),
  on(VacancyProfilesUserActions.UpdateStatisticSearchParamsAction, (state, { params }) =>
    mergeRight(state, { statisticSearchParams: params, isActivitiesLoading: true }),
  ),

  on(
    ProfilesApiActions.RequestVacancyProfilesFailedAction,
    mergeLeft({ isProfilesLoading: false }) as UnaryOperator<
      VacancyProfilesState,
      VacancyProfilesState
    >,
  ),
  on(
    ProfilesApiActions.RequestVacancyProfilesSuccessAction,
    (state: VacancyProfilesState, { items, totalCount, included }) =>
      mergeRight(state, {
        items,
        totalCount,
        profilesIncluded: included,
        isProfilesLoading: false,
      }),
  ),
  on(VacancyApiActions.GetVacancyDetailsSuccessAction, (state, { vacancyDetails }) =>
    mergeRight(state, { vacancyDetails, isVacancyLoading: false }),
  ),
);

export function vacancyProfilesReducer(
  baseState: VacancyProfilesState,
  action: Action,
): VacancyProfilesState {
  return reducer(baseState, action);
}

export const selectVacancyProfilesStore =
  createFeatureSelector<VacancyProfilesState>('vacancyProfiles');
export const getSourceVacancy = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.source,
);
export const getProfilesAndCount = createSelector(
  selectVacancyProfilesStore,
  (store: VacancyProfilesState) => ({
    items: prop('items', store) || [],
    totalCount: prop('totalCount', store) || 0,
  }),
);

export const getVacancyId = createSelector(selectVacancyProfilesStore, (state) => state.vacancyId);

export const getActivitiesLoading = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.isActivitiesLoading,
);
export const getInfoPanelState = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.isInfoPanelOpened,
);
export const getVacancyActivities = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.activities,
);
export const getProfilesLoading = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.isProfilesLoading,
);
export const getSearchParameters = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.searchParams,
);
export const getStatisticSearchParameters = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.statisticSearchParams,
);
export const getPaginationState = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.paginationParams,
);
export const getSortingState = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.sortParams || {},
);
export const getVacancyDetails = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.vacancyDetails,
);
export const getVacancyLoading = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.isVacancyLoading,
);
export const profilesIncluded = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.profilesIncluded,
);
export const notificationRecipientsStore = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.notificationRecipients,
);
export const getNotificationRecipients = createSelector(
  notificationRecipientsStore,
  (notificationStore) => notificationStore?.items,
);
export const getNotifiedProfilesLoadingState = createSelector(
  notificationRecipientsStore,
  (notificationStore) => notificationStore?.isLoading,
);

export const getVacancyHistory = createSelector(
  selectVacancyProfilesStore,
  (store) => store?.vacancyHistory,
);
export const getVacancyCreatedDate = createSelector(
  getVacancyDetails,
  (vacancy) => vacancy?.createdAt,
);
export const getVacancySkills = createSelector(
  getVacancyDetails,
  pipe(
    propOr([], 'skills'),
    ifElse(isNil, () => [], pluck('id')),
  ) as UnaryOperator<VacancyDetails | null, number[]>,
);
export const getVacancyStatusesCounts = createSelector(
  getVacancyDetails,
  unless(
    anyPass([isNil, isEmpty]),
    pick(['ai', 'hired', 'inbox', 'longList', 'notAccepted', 'notified', 'processing']),
  ) as UnaryOperator<VacancyDetails | null, VacancyCounts | null>,
);
export const getVacancyHistoryLoadingState = createSelector(
  getVacancyHistory,
  (historyStore) => historyStore?.isLoading,
);
export const getVacancyHistoryItemsList = createSelector(
  getVacancyHistory,
  (historyStore) => historyStore?.items,
);
export const getVacancyHistoryItemsCount = createSelector(
  getVacancyHistory,
  (historyStore) => historyStore?.total,
);
export const getVacancyHistoryQueryParams = createSelector(
  getVacancyHistory,
  getStatisticSearchParameters,
  (historyStateSlice: HistoryState, statisticRangeQuery: SearchParams | null) => ({
    dateTo: prop('endDate', statisticRangeQuery) as string | null,
    dateFrom: prop('startDate', statisticRangeQuery) as string | null,
    ...(prop('query', historyStateSlice) as Partial<PaginationParams & SortParams>),
  }),
);
