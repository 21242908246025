import {
  DictionariesEnum,
  DictionaryCandidateStage,
  DictionaryEditableItem,
  DirectoryFieldType,
  DirectoryQueryParams,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const CleanDirectoriesAction = createAction('[Directories] Clean state directories');

const DeleteDirectoryItemFailedAction = createAction(
  '[Directories Items List] Try to delete directory from list failed',
);
const DeleteDirectoryItemSuccessAction = createAction(
  '[Directories Items List] Directory Item successfully removed',
);
const RefreshDirectoryAction = createAction('[New Entity Effects] Refresh current directory list');
const RequestDirectoryAction = createAction(
  '[Directories] Request directory',
  props<{ name: DictionariesEnum; query: DirectoryQueryParams }>(),
);
const RequestDirectoryFailedAction = createAction('[Directories] Request directory failed');
const RequestDirectorySuccessAction = createAction(
  '[Directories] Directory items stored in the state',
  props<{ items: DictionaryEditableItem[] }>(),
);
const RequestFieldTypesDirectoryAction = createAction(
  '[Directories | Field Types] Request field types directory',
  props<{ name: DictionariesEnum }>(),
);
const RequestFieldTypesDirectoryFailedAction = createAction(
  '[Directories | Field Types] Request field types directory failed',
);
const RequestFieldTypesDirectorySuccessAction = createAction(
  '[Directories] Field types stored in the state',
  props<{ items: DirectoryFieldType[] }>(),
);
const ReplaceDirectoryItemFailedAction = createAction(
  '[Directories Items List] Error happened during directory item replacement',
);
const ReplaceDirectoryItemSuccessAction = createAction(
  '[Directories Items List] Directory Item successfully replaced',
);
const UpdateCandidateStagesOrderFailedAction = createAction(
  '[Directories] Error happened during updating candidate stages order',
);
const UpdateCandidateStagesOrderSuccessAction = createAction(
  '[Directories] New order for candidate stages successfully saved',
  props<{ directory: DictionaryCandidateStage[] }>(),
);

const actions = {
  CleanDirectoriesAction,
  DeleteDirectoryItemFailedAction,
  DeleteDirectoryItemSuccessAction,
  RefreshDirectoryAction,
  RequestDirectoryAction,
  RequestDirectoryFailedAction,
  RequestDirectorySuccessAction,
  RequestFieldTypesDirectoryAction,
  RequestFieldTypesDirectoryFailedAction,
  RequestFieldTypesDirectorySuccessAction,
  ReplaceDirectoryItemFailedAction,
  ReplaceDirectoryItemSuccessAction,
  UpdateCandidateStagesOrderFailedAction,
  UpdateCandidateStagesOrderSuccessAction,
};

const directoriesSystemActions = union(actions);

export type DirectoriesSystemActionsType = typeof directoriesSystemActions;

export const DirectoriesSystemActions = actions;
