import { Directive, ElementRef, Renderer2, Input, DoCheck } from '@angular/core';
import {
  forEach,
  mapObjIndexed,
  pipe,
  replace,
  split,
  test,
  map,
  when,
  includes,
  join,
} from 'ramda';

@Directive({
  selector: '[appHighlightSearch]',
  standalone: true,
})
export class HighlightSearchWordsDirective implements DoCheck {
  @Input()
  public searchParams: { [key: string]: string[] } | null;
  private element: HTMLElement;
  private readonly specSymbols = ['+', '*'];

  constructor(
    public readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.element = el.nativeElement;
  }

  public ngDoCheck(): void {
    if (this.searchParams && this.element) {
      let value = this.element.innerHTML;

      mapObjIndexed((val: string[], key) =>
        forEach((param: string) => {
          const toHighlight =
            !test(this.safeRegExp(`${key}">${param}`), value) &&
            test(this.safeRegExp(param), value);
          if (!toHighlight) {
            return;
          }
          const elem = `<span class="search-highlight-element search-highlight-element--${key}">${param}</span>`;
          value = replace(this.safeRegExp(param), elem, value);
        }, val),
      )(this.searchParams);

      if (value) {
        this.renderer.setProperty(this.element, 'innerHTML', value);
      }
    }
  }
  public safeRegExp(str: string): RegExp {
    return pipe(
      split(''),
      map(
        when(
          (symb) => includes(symb, this.specSymbols),
          (symb) => replace(symb, `\\${symb}`, symb),
        ),
      ),
      join(''),
      (val) => new RegExp(val, 'gi'),
    )(str);
  }
}
