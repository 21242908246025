import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpInterceptor as Interceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mockedUser } from '@app-shared/mocks';
import { isNil } from 'ramda';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalDataResolverService implements Interceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const localData = this.checkTarget(req.url);

    if (req.method !== 'GET' || isNil(localData)) {
      return next.handle(req);
    }

    return of(new HttpResponse({ status: 200, body: localData }));
  }

  private checkTarget(url: string): unknown {
    if (url === '/local/currentUser') {
      return mockedUser;
    }

    return null;
  }
}
