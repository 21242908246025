import { NgClass, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { UnaryOperator } from '@app-shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { inc, mapObjIndexed, mergeLeft, pipe, propOr, reduce, values } from 'ramda';

@Component({
  imports: [MatTooltip, NgClass, SlicePipe, TranslateModule],
  selector: 'profile-available-contacts',
  templateUrl: './profile-available-contacts.component.html',
  styleUrls: ['./profile-available-contacts.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProfileAvailableContactsComponent {
  @Input()
  public set availableContacts(list: string[]) {
    this.contacts = pipe(
      reduce((obj, el) => mergeLeft({ [el]: inc(propOr(0, el)(obj)) })(obj), {}) as UnaryOperator<
        string[],
        { [key: string]: number }
      >,
      mapObjIndexed((val, key) => ({ name: key, count: val })),
      values,
    )(list);
  }
  @Input()
  public contactsVisibilityLimit = 4;
  @Output()
  public showContactDialog: EventEmitter<string> = new EventEmitter();
  public contacts: { name: string; count: number }[];

  public showContacts(clickedContact?: { name: string; count?: number }) {
    this.showContactDialog.emit(clickedContact.name);
  }
}
