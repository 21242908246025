import { EntitiesList, RecruiterInfo } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const CleanTeammatesAction = createAction(
  '[Recruiter Cleaner Guard] Clean all related to recruiter data',
);
const DeleteRecruiterFailedAction = createAction(
  '[Recruiter Details | Recruiters API] Error happened during delete recruiter',
);
const DeleteRecruiterSuccessAction = createAction(
  '[Recruiter Details | Recruiters API] Recruiter was deleted',
);
const ResetRecruiterPasswordFailedAction = createAction(
  '[Recruiter Details] Error happened during resetting recruiter password',
);
const ResetRecruiterPasswordSuccessAction = createAction(
  '[Recruiter Details] Recruiter password was reset',
);
const RequestTeammatesFromResolverAction = createAction(
  '[Recruiter Details Resolver] Request full recruiter details',
);
const RequestTeammatesFailedAction = createAction(
  '[Recruiter Details | Recruiters API] Error happened during requesting Recruiter details',
);
const RequestTeammatesSuccessAction = createAction(
  '[Recruiter Details | Recruiters API] Recruiter details saved to store',
  props<{ recruiters: EntitiesList<RecruiterInfo> & { admin: RecruiterInfo } }>(),
);
const UpdateRecruiterFailedAction = createAction(
  '[Recruiter Details | Recruiters API] Error happened during update recruiter',
);
const UpdateRecruiterSuccessAction = createAction(
  '[Recruiter Details | Recruiters API] Recruiter details updated in store',
  props<{ recruiter: RecruiterInfo }>(),
);

const actions = {
  CleanTeammatesAction,
  DeleteRecruiterFailedAction,
  DeleteRecruiterSuccessAction,
  ResetRecruiterPasswordFailedAction,
  ResetRecruiterPasswordSuccessAction,
  RequestTeammatesFromResolverAction,
  RequestTeammatesFailedAction,
  RequestTeammatesSuccessAction,
  UpdateRecruiterFailedAction,
  UpdateRecruiterSuccessAction,
};

const teammatesSystemActions = union(actions);

export type TeammatesSystemActionsType = typeof teammatesSystemActions;

export const TeammatesSystemActions = actions;
