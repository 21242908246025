import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecruiterAccesses, TeammateUserType, UnaryOperator } from '@app-shared/models';
import {
  possibleTeammateAccesses,
  teammateUserTypes,
} from '@app-shared/services/recruiter/recruiter.config';
import { TranslateModule } from '@ngx-translate/core';
import { equals, find, head, is, isNil, omit, pick, pipe, prop, sum, values, when } from 'ramda';

@Component({
  imports: [NgClass, MatTooltipModule, TranslateModule],
  selector: 'app-teammate-type',
  templateUrl: './teammate-type.component.html',
  styleUrls: ['./teammate-type.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TeammateTypeComponent {
  @Input()
  public theme: 'dialog' | 'list';
  @Input()
  public recruiterAccess: RecruiterAccesses;
  @Input()
  public showCount = false;
  @Input()
  public userType: TeammateUserType;
  @Output()
  public typeSelected = new EventEmitter<TeammateUserType>();

  public get isSelected(): boolean {
    return this.getIsSelected(this.userType) || this.getDefaultTypeIfNoSelected();
  }

  public get accessesCount(): number {
    if (!this.showCount) {
      return 0;
    }
    return pipe(
      when(is(Array), head),
      omit(['id']) as UnaryOperator<
        { [key: string]: boolean | number },
        { [key: string]: boolean }
      >,
      values as UnaryOperator<{ [key: string]: boolean }, number[]>,
      sum,
    )(this.recruiterAccess);
  }

  private getIsSelected(userType: TeammateUserType) {
    const userAccesses = pick(possibleTeammateAccesses)(this.recruiterAccess);
    return pipe(
      prop('accesses'),
      pick(possibleTeammateAccesses) as UnaryOperator<
        RecruiterAccesses & { [key: string]: boolean },
        RecruiterAccesses
      >,
      equals(userAccesses),
    )(userType);
  }

  private getDefaultTypeIfNoSelected(): boolean {
    if (!this.userType.default) {
      return false;
    }
    const noSelected = pipe(
      find((el: TeammateUserType) => this.getIsSelected(el)),
      isNil,
    )(teammateUserTypes);
    return noSelected;
  }
}
